import { CipoFroalaOptions } from 'src/app/shared/components/froala/interfaces';
import { environment } from 'src/environments/environment';
import { FROALA_TOOLBAR_BIG, FROALA_TABLE_CELL_STYLES, FROALA_TABLE_STYLES, FROALA_FONTS } from './consts';
import FroalaEditor from 'froala-editor';

type FroalaConfigType = {
  basic: CipoFroalaOptions;
};

export const FroalaConfig: FroalaConfigType = {
  basic: {
    key: environment.froalaKey,
    attribution: false,
    scaytAutoload: true,
    scaytCustomerId: environment.scaytCustomerId,
    scaytOptions: {
      enableOnTouchDevices: false,
      enableGrayt: true,
      localization: 'en',
      extraModules: 'ui',
      spellcheckLang: 'en_US',
      contextMenuSections: 'probleminfo|suggest|moresuggest|control|options',
      contextMenuForMisspelledOnly: false,
      moreSuggestionsCount: '10',
      suggestionsCount: '10',
      ignoreClasses: ['parse-variables', 'tag-user'],
    },
    fontFamilySelection: true,
    fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '24', '26', '30', '36', '48', '60', '72', '96'],
    fontFamily: FROALA_FONTS,
    toolbarInline: false,
    lineHeights: {
      '1.0': '1.0',
      '1.15': '1.15',
      '1.5': '1.5',
      '2.0': '2.0',
      '3.0': '3.0',
    },
    placeholderText: 'Enter Text Here',
    htmlAllowedAttrs: [...FroalaEditor.DEFAULTS.htmlAllowedAttrs, 'key'],
    toolbarButtons: FROALA_TOOLBAR_BIG.toolbarButtons,
    toolbarButtonsXS: FROALA_TOOLBAR_BIG.toolbarButtonsXS,
    toolbarSticky: false,
    heightMin: 200,
    heightMax: 400,
    inlineStyles: {
      'All Caps': 'text-transform: uppercase;',
    },
    events: {},
    inlineClasses: {
      pagebreakAfter: 'Page Break After',
      pagebreakBefore: 'Page Break Before',
    },
    tableStyles: FROALA_TABLE_STYLES,
    tableCellStyles: FROALA_TABLE_CELL_STYLES,
  },
} as const;
