import { Component, Inject, Input, OnInit, Output, effect, inject, signal } from '@angular/core';
import { PeopleModel } from '../permissions/permissions.model';
import { PermissionsService } from '../permissions/permissions.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SelectPeopleParameters } from './select-people-dialog.model';
import { CipoFieldTypes, CipoListControl } from 'src/app/shared/components/fields/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CipoSelectComponent } from 'src/app/shared/components/fields/cipo-select/cipo-select.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Permission } from 'src/app/models/permission';

const keySeparator = '_';

@Component({
  selector: 'app-select-people-dialog',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    CipoSelectComponent,
    FlexLayoutModule,
    MatButtonModule,
    MatButtonToggleModule,
  ],
  providers: [PermissionsService],
  templateUrl: './select-people-dialog.component.html',
})
export class SelectPeopleDialogComponent {
  permissionsService = inject(PermissionsService);
  translate = inject(TranslateService);

  notContractModule: boolean = false;
  folderId: number;
  fileId: number;
  fileParentFolderId: number;
  entityInstanceId: number;
  allow = true;
  listControl = new CipoListControl([], {
    label: 'fileFolderPermissions.selectPeople',
    multiple: true,
    type: CipoFieldTypes.Select,
    options: [],
  });

  constructor(
    public dialogRef: MatDialogRef<SelectPeopleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectPeopleParameters,
  ) {
    this.folderId = data.folderId;
    this.fileId = data.fileId;
    this.fileParentFolderId = data.fileParentFolderId;
    this.entityInstanceId = data.entityInstanceId;
    this.notContractModule = data.notContractModule;

    this.loadAvailablePeople(this.allow);
  }

  loadAvailablePeople(allow: boolean) {
    this.listControl.setValue([]);
    this.permissionsService
          .getAvailableUserRoles(this.folderId, this.fileId, this.fileParentFolderId, this.entityInstanceId, allow)
          .subscribe(list => {
            const options = list.map(l => ({ key: `${l.id}${keySeparator}${l.isUser}`, value: l.name }));
            this.listControl = new CipoListControl([], {
              label: 'fileFolderPermissions.selectPeople',
              multiple: true,
              type: CipoFieldTypes.Select,
              options,
            });
          });
  }

  returnPeople() {
    const list: PeopleModel[] = (this.listControl.value as string[])
      .map(l => l.split(keySeparator))
      .map(arr => ({
        id: Number(arr[0]),
        isUser: arr[1].toLowerCase() === 'true',
        isNew: true,
        name: this.listControl.fieldData.options.find(p => p.key == arr.join(keySeparator))?.value ?? '',
        permissions: [{ permissionId: Permission.Read, permission: Permission[Permission.Read], allow: !this.notContractModule && this.allow, deny: !this.allow }],
      }));
    this.dialogRef.close(list);
  }

  closeDialog() {
    this.dialogRef.close([]);
  }
}
