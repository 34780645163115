import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedListComponent } from 'src/app/shared/components/paged-list/paged-list.component';
import { AccessLogModel } from '../../../models/module/accessLog';
import { GridViewModel, OrderType, SearchViewModel, SortModel } from "../../../models/module/grid";
import { AccessLogTableService } from './access-log-table.service';

@Component({
  selector: 'app-access-log-table',
  templateUrl: './access-log-table.component.html',
  styleUrls: ['./access-log-table.component.scss']
})
export class AccessLogTableComponent extends PagedListComponent<AccessLogModel> {
  
  @Input('entityinstance') entityInstance: any;

  displayedColumns = ["user", "state", "accessOn"];

  constructor(private accessLogService: AccessLogTableService) {
    super();
  }

  override getData(pagedRequest: SearchViewModel): Observable<GridViewModel<AccessLogModel[]>> {
    return this.accessLogService.getAccessLog(pagedRequest, this.entityInstance.entityInstanceId, this.entityInstance.contractId);
  }
}