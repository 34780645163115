<app-layout
    [menuitems]="menuItems"
    [activemenuitemid]="selectedMenu?.id"
    [menufolderautocollapse]="true"
    [context]="context"
    (onmenuitemclick)="menuClick($event)"
    (onlogoclick)="goToHome()">
    <div header-content class="headerItems">
        <mat-icon>mail</mat-icon>
        <app-account-menu></app-account-menu>
    </div>
    <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
</app-layout>
