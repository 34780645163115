import { cipo } from 'cipo';

cipo.controller('sendEmailController',
    function ($scope, $q, URI, Model, Form, Message, $mdDialog, emailData, $window, $timeout, entityInstanceId, contractId, title, sendURI, actionId, roleId) {
        $scope.contractId = contractId;
        $scope.actionId = actionId;
        $scope.sendURI = sendURI;
        $scope.title = title;
        $scope.entityInstanceId = entityInstanceId;
        $scope.soloConv = {
            sender: emailData.sender || {},
            to: (emailData.tos || []).filter((e) => e.autoSet),
            cc: (emailData.ccs || []).filter((e) => e.autoSet),
            subject: emailData.subject,
            content: emailData.templateHTML || '',
            attachments: [],
            loading: true,
            roleId: roleId
        };

        $scope.init = function() {
            $scope.form = new Form($scope.soloConv);
            $scope.form.initializing = true;

            var formDescriptionObject = {
                    to: {
                        label: 'To', type: 'contact', options: $scope.recipientsArray, validation: { required: true }, onChange: function(field) { $scope.getRecipientsNotOnContract(field); }
                    },
                    cc: {
                        label: 'CC', type: 'contact', options: $scope.recipientsArray, onChange: function(field) { $scope.getRecipientsNotOnContract(field); }
                    },
                    subject: { label: 'Subject', type: 'text' },
                    content: {
                        label: "", type: 'editor', options: {
                            scaytAutoload: false
                        }
                    },
                    attachments: { label: "", type: 'attachment' }
                };

            var formTemplateObject = [
                { to: 100 },
                { cc: 100 },
                { subject: 100 },
                { content: 100 },
                { attachments: 100 }
            ];

            $scope.form.set_Description(formDescriptionObject);
            $scope.form.setTemplate('grid', formTemplateObject);
            $scope.form.initializing = false;
            $scope.form.set_Data($scope.soloConv);
            $scope.soloConv.loading = false;
        }

        $scope.addExtraEmails = function(){
            if ($scope.soloConv.to && $scope.soloConv.to.length) {
                for (var i = 0; i < $scope.soloConv.to.length; i++) {
                    var existing = $scope.recipientsArray.filter((r) => r.id == $scope.soloConv.to[i].id && r.isContact == $scope.soloConv.to[i].isContact);
                    if (!existing || existing.length == 0)
                        $scope.recipientsArray.push($scope.soloConv.to[i]);
                }
            }

            if ($scope.soloConv.cc && $scope.soloConv.cc.length){
                for (var i = 0; i < $scope.soloConv.cc.length; i++) {
                    var existing = $scope.recipientsArray.filter((r) => r.id == $scope.soloConv.cc[i].id && r.isContact == $scope.soloConv.cc[i].isContact);
                    if (!existing || existing.length == 0)
                        $scope.recipientsArray.push($scope.soloConv.cc[i]);
                }
            }
        }

        $scope.getRecipients = function(){
            var p = $q.defer();
            var params = {
                url: {
                    contractId: $scope.contractId
                },
                urltype: 'obj'
            };

            var dataURL = URI.EMAIL.RECIPIENTS_DICT;
            Model[dataURL.method](dataURL.toString(), params)
                .then(function (recipients) {
                    $scope.recipientsArray = recipients;
                    $scope.addExtraEmails();
                    $scope.init();
                    p.resolve();
                }).catch(function (e) {
                    p.reject(e);
                })

            return p.promise;
        }

        $scope.getRecipientsNotOnContract = function(field) {
            if (field._value && Object.prototype.toString.call(field._value) == '[object Array]' && field._value.length) {
                var notPerContractEmails = field._value.filter(r => !r.assignedOnContract && !r.isContact).map(r => r.name ?? r.email).join(", ");
                if (notPerContractEmails) {
                    field.warning = "User(s) " + notPerContractEmails + " are not assigned to this Contract. They will only see this message in their external mailbox";
                }
                else {
                    field.warning = null;
                }
            }
            else {
                field.warning = null;
            }
        }

        $scope.getRecipients();

        $scope.printMe = function (conversation, contentOnly) {
            var email = conversation;

            if ($("#section-to-print").length > -1) {
                $("#section-to-print").remove();
            }

            var div = $('<div />').appendTo('body');
            div.attr('id', 'section-to-print');

            $scope.messageToPrint = contentOnly
                ? $('#print-content-only').html(email.content)
                : email;

            $timeout(function () {
                if (contentOnly) {
                    $("#section-to-print").html($("#print-content-only").html());
                } else {
                    $("#section-to-print").html($("#print-message").html());
                }

                $window.print();
            }, 400);
        }

        $scope.send = function (conv) {
            
            $scope.form.validate();

            var p = $q.defer();
            if ($scope.form.isValid) {
                var params = {
                    url: {
                        entityInstanceId: $scope.entityInstanceId,
                        actionId: $scope.actionId,
                        contractId: $scope.contractId
                    },
                    urltype: 'obj',
                    body: conv
                };
    
                Model[$scope.sendURI.method]($scope.sendURI.toString(), params)
                    .then(function () {
                        Message.info('Email send successfully');

                        $mdDialog.cancel({ msg: 'send' })
                    }).catch(function (e) {
                        p.reject(e);
                    })
            }
            else {
                p.reject();
            }

            return p.promise;
        }

        $scope.buildRecipientDisplayString = function (recipients, separatingChar = ', ') {
            var result = "";
            var names = [];

            if (Array.isArray(recipients) && recipients.length > 0) {

                for (var i = 0; i < recipients.length; i++) {
                    var recipient = recipients[i];
                    var value = recipient.firstName.length > 0
                        ? `${recipient.firstName} ${recipient.lastName}`
                        : recipient.email;

                    if (value.trim().length > 0) {
                        names.push(value);
                    }
                }

                result = names.join(separatingChar);
            }

            return result;
        }

        $scope.showAsDiv = function (conv) {
            $mdDialog.hide(conv);
        }

        $scope.cancel = function () {
            $mdDialog.cancel({ msg: 'cancel' });
        }
    });
