import { cipo } from 'cipo';

cipo.directive("formLayout", function ($compile, $http, $templateCache, $timeout) {
    var set_Template = function (template) {
        var base = "/ng/views/directives/system/form/";
        var map = {
            flow: 'flow.html',
            grid: 'grid.html',
            positiondata: 'positiondata.html'
        }

        return $http.get(base + map[template], { cache: $templateCache });
    }

    return {
        restrict: "E",
        replace: true,
        scope: {
            "formdata": "=",
            "currency": "=?",
            "shareddata": "=?",
        },
        link: function ($scope, $el, $attrs) {
            var el = $el;
            var childScope = $scope.$new();

            $scope.shareddata = $scope.shareddata ?? {};

            // find weather field and the weather date field
            if ($scope.formdata && $scope.formdata.fieldsList) {
                var fieldIds = Object.keys($scope.formdata.fieldsList);
                fieldIds.forEach(fieldId => {
                    if ($scope.formdata.fieldsList[fieldId].weatherDateId) {
                        $scope.shareddata.weatherFieldId = Number(fieldId);
                        $scope.shareddata.weatherDateFieldId = Number($scope.formdata.fieldsList[fieldId].weatherDateId);
                    }
                });
            }

            // calculate the max number of columns
            $scope.colsMax = 0;
            if ($scope.formdata && $scope.formdata.pattern) {
                for (var r = 0; r < $scope.formdata.pattern.length; r++) {
                    var row = $scope.formdata.pattern[r];
                    var cols = 0;
                    for (var c = 0; c < row.length; c++) {
                        var col = row[c];
                        cols += col.colspan * 1;
                    }
                    if (cols > $scope.colsMax) {
                        $scope.colsMax = cols;
                    }
                }
            }

            $scope.$watch(function () {
                return $scope.formdata.display;
            }, function (n, o) {
                if (typeof n != 'undefined') {
                    set_Template(n)
                        .then(function (r) {
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }

                            childScope = $scope.$new();

                            $(el).html($compile(r.data)(childScope));
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                }
            });
        }
    }
});
