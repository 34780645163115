import { NgModule } from '@angular/core';

import { FroalaHeightPipe } from '../pipes/form-builder/froala-height.pipe';
import { CastToNumberPipe, CastToStringPipe, ToArrayPipe } from '../pipes/form-builder/input-value.pipe';

@NgModule({
  imports: [FroalaHeightPipe, ToArrayPipe, CastToNumberPipe, CastToStringPipe],
  exports: [FroalaHeightPipe, ToArrayPipe, CastToNumberPipe, CastToStringPipe],
})
export class FormBuilderPipesModule {}
