import { DisplayType, FieldTypeEnum, KeyValueFormattingModel, KeyValueRestrictionModel } from '../fields/enums';

export interface DataListFields {
  id?: number;
  systemName: string;
  name: string;
  hasMultipleValues: boolean;
  dataSourceId?: number;
  relationId?: number;
  typeId?: FieldTypeEnum;
  displayTypeId?: DisplayType;
  label: string;
  position?: number;
  priority?: number;
  width?: number;
  printable: boolean;
  showThumbnail: boolean;
  isPrimarySort: boolean;
  isDescending: boolean;
  isFilter: boolean;
  isPayment: boolean;
  options: string;
  inUse: boolean;
  formattings: KeyValueFormattingModel[];
  restrictions: KeyValueRestrictionModel[];
}
