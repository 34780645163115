import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import {
  FormattingType,
  FormattingValueType,
  KeyValueFormattingModel,
  KeyValueRestrictionModel,
  RestrictionType,
} from '../../models/module/fields/enums';
import { Currency } from '../../models/system/currency';

@Pipe({ name: 'itvNumberWithFormattings' })
export class ItvNumberWithFormattingsPipe implements PipeTransform {
  constructor(private itvNumberPipe: ItvNumberPipe) {}

  transform(
    value: number | string | null | undefined,
    formattings: KeyValueFormattingModel[] = [],
    restrictions: KeyValueRestrictionModel[] = [],
    currency: Currency | null | undefined = undefined,
    percentageSymbol: string = '%',
  ): string {
    const numberFormatting = (formattings || []).find(f => f.key == FormattingType.Number);
    const decimalFormatting = (formattings || []).find(f => f.key == FormattingType.Decimals);
    const showComma = !!(restrictions || []).find(f => f.key == RestrictionType.ShowThousandSeparator);
    const showCurrency = numberFormatting && numberFormatting.value == FormattingValueType.Money;
    const showPercentage = numberFormatting && numberFormatting.value == FormattingValueType.Percentage;

    let decimals = decimalFormatting ? decimalFormatting.value : 0;

    return this.itvNumberPipe.transform(
      value,
      decimals,
      showComma,
      showCurrency,
      showPercentage,
      currency,
      percentageSymbol,
    );
  }
}

@Pipe({ name: 'itvNumber' })
export class ItvNumberPipe implements PipeTransform {
  private currencySymbol = '$';
  private leftSideDefault = true;
  private noSpaceDefault = true;

  constructor(
    private decimalPipe: DecimalPipe,
  ) {}

  transform(
    value: number | string | null | undefined,
    decimals: number = 0,
    showComma: boolean = true,
    showCurrency: boolean = false,
    showPercentage: boolean = false,
    currency: Currency | null | undefined = undefined,
    percentageSymbol: string = '%',
  ): string {
    if (value === undefined || value === null) {
      return '';
    } 
    
    let newValue = value;
    if (showCurrency && value && value.toString().includes(currency?.symbol ??  this.currencySymbol)) {
      newValue = value.toString().replace(currency?.symbol ??  this.currencySymbol, '');
    } else if (showPercentage && value && value.toString().includes(percentageSymbol)) {
      newValue = value.toString().replace(percentageSymbol, '');
    }

    const numberValue: number = typeof newValue == 'string' ? Number((newValue as any)?.toString()?.replace(',', '')) : newValue;
    if (numberValue === undefined || numberValue === null || isNaN(numberValue)) {
      return value?.toString();
    }

    const numberValueAbs: number = Math.abs(numberValue);
    
    if (showCurrency && (currency === null || currency === undefined)) {
      currency = { symbol: this.currencySymbol } as Currency;
    }
    
    let ret = '';
    if (!showComma && !decimals) {
      ret = numberValueAbs.toFixed(0);
    } else if (showComma && !decimals) {
      ret = this.decimalPipe.transform(numberValueAbs, '1.0-0');
    } else if (!showComma && decimals) {
      ret = numberValueAbs.toFixed(decimals);
    } else if (showComma && decimals) {
      ret = this.decimalPipe.transform(numberValueAbs, `1.${decimals}-${decimals}`);
    }

    var prefixNegative = numberValue < 0 ? '-' : '';

    if (showPercentage) {
      return `${prefixNegative}${ret}${percentageSymbol}`;
    }

    if (showCurrency) {
      var leftSide = currency.leftSide ?? this.leftSideDefault;
      var noSpace = currency.noSpace ?? this.noSpaceDefault;

      var prefixSpace = !noSpace && leftSide ? ' ' : '';
      var suffixSpace = !noSpace && !leftSide ? ' ' : '';
      var prefixCurrency = leftSide ? currency.symbol : '';
      var suffixCurrency = !leftSide ? currency.symbol : '';

      if (noSpace) {
        return `${prefixNegative}${prefixCurrency}${ret}${suffixCurrency}`;
      }

      return `${prefixCurrency}${prefixSpace}${prefixNegative}${ret}${suffixSpace}${suffixCurrency}`;
    }

    return `${prefixNegative}${ret}`;
  }
}
