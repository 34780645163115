import { Component, Inject, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { TenantSettingRow, TenantSettingValue, TenantSettingsEnum } from '../tenant-settings.model';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { TenantSettingsService } from 'src/app/module/instance/tenant-settings/tenant-settings.service';
import { KeyValueType } from '../../../../models/common';

@Component({
  selector: 'app-tenant-setting-modal',
  standalone: true,
  imports: [MaterialModule, TranslateModule, MatDialogModule, FlexLayoutModule],
  providers: [TenantSettingsService],
  templateUrl: './tenant-setting-modal.component.html',
  styleUrls: ['./tenant-setting-modal.component.scss'],
})
export class TenantSettingModalComponent {
  tenantSettingsService = inject(TenantSettingsService);
  translate = inject(TranslateService);
  selectOptions: KeyValueType<any>[] = [];
  valueControl = new FormControl<TenantSettingValue>(null, Validators.required);

  constructor(
    public dialogRef: MatDialogRef<TenantSettingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public tenantSetting: TenantSettingRow,
  ) {
    const value = tenantSetting.type === 'select' ? tenantSetting.rawValue?.toString() : tenantSetting.rawValue;
    this.valueControl.setValue(value);
    this.updateControl();
  }

  updateControl(): void {
    switch (this.tenantSetting.type) {
      case 'rangePercent':
        this.valueControl.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        break;
      case 'select':
        this.tenantSettingsService
          .getSettingOptions(this.tenantSetting.id)
          .subscribe(options => (this.selectOptions = options));
        break;
      default:
        break;
    }
  }

  onSave(): void {
    const value = this.valueControl.value;
    this.dialogRef.close(this.tenantSetting.type === 'boolean' ? +value : value);
  }
}
