import { Injectable } from '@angular/core';
import { EITransactionInstanceModel } from '../../../../models/module/entityInstance';
import { HttpService } from '../../../../shared/services/http.service';

@Injectable()
export class ActivityItemService {
  constructor(private httpService: HttpService) {}

  getActivityItem(moduleId: number, actionInstanceId: number, contractId: number, noFields: boolean) {
    const params = this.httpService.buildHttpParams({ actionInstanceId, contractId, noFields });
    const url = `_api/ModuleInstance/GetTransitionInstanceInformations`;
    return this.httpService.get<EITransactionInstanceModel>('_api/ModuleInstance/GetTransitionInstanceInformations', {
      httpOptions: { headers: { moduleId: moduleId.toString() }, params },
      useLoader: false,
    });
  }
}
