import { cipo } from 'cipo';

cipo.directive("contractsdonutchart", function ($window, URI, userService, $http, Message, $timeout) {
    return {
        restrict: "EA",
        scope: {
        },
        templateUrl: "/ng/views/directives/system/contractsdonutchart.html",
        link: function ($scope, element) {
            var data = [2, 4, 8, 10];

            var width = 200;
            var height = 200;
            var radius = Math.min(width, height) / 2;

            $timeout(function () {
                //def svg
                var svg = d3.select("#" + element[0].id)
                    .append("svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", `0 0 200 200`);
                var g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

                var color = d3.scaleOrdinal(["#98abc5",
                    "#8a89a6",
                    "#7b6888",
                    "#6b486b",
                    "#a05d56",
                    "#d0743c",
                    "#ff8c00",
                    "#e34d01",
                    "#ccff05",
                    "#3e7eca",
                    "#aa0092",
                    "#b32e4f"]);

                //make donut
                var pie = d3.pie();

                var path = d3.arc()
                    .innerRadius(radius - 60)
                    .outerRadius(radius - 30);

                var outerArc = d3.arc()
                    .innerRadius(radius * 0.9)
                    .outerRadius(radius * 0.9);

                var label = d3.arc()
                    .outerRadius(radius - 60)
                    .innerRadius(radius - 60);


                var arc = g.selectAll(".arc")
                    .data(pie(data))
                    .enter()
                    .append("g")
                    .attr("class", "arc");

                arc.append("path")
                    .attr("d", path)
                    .attr("fill", function (d, i) { return color(i); });

                arc.append("text")
                    .attr("transform", function (d, i) {
                        var pos = outerArc.centroid(d);
                        pos[0] = radius * (midAngle(d) < Math.PI ? 1.1 : -1.1);

                        var percent = (d.endAngle - d.startAngle) / (2 * Math.PI) * 100
                        if (percent < 3) {
                            pos[1] += i * 15
                        }
                        return "translate(" + pos + ")";
                    })
                    .text(function (d) { return d.value; })
                    .attr("fill", function (d, i) { return color(i); })
                    .attr("dx", function (d) {
                        var ac = midAngle(d) < Math.PI ? -30 : 14.2
                        return ac
                    })
                    .attr("dy", -1);


                function midAngle(d) {
                    return d.startAngle + (d.endAngle - d.startAngle) / 2;
                }

                var polyline = g.selectAll("polyline")
                    .data(pie(data), function (d) {
                        return d.value;
                    })
                    .enter()
                    .append("polyline")
                    .attr("points", function (d, i) {
                        var pos = outerArc.centroid(d);
                        pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
                        var o = outerArc.centroid(d)
                        var percent = (d.endAngle - d.startAngle) / (2 * Math.PI) * 100
                        if (percent < 3) {
                            //console.log(percent)
                            o[1]
                            pos[1] += i * 15
                        }
                        //return [label.centroid(d),[o[0],0[1]] , pos];
                        return [label.centroid(d), [o[0], pos[1]], pos];
                    })
                    .style("fill", "none")
                    //.attr('stroke','grey')
                    .attr("stroke", function (d, i) { return color(i); })
                    .style("stroke-width", "1px");

                svg.heigth = 0;
            })

        }
    }
});
