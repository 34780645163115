import { cipo } from 'cipo';

cipo.controller('editHeaderFooterController',
    function ($scope, MODAL, HeaderOrFooter, operations, module, $q, obj, $mdDialog, $timeout) {

        $scope.modalInfo = MODAL;
        $scope.headerOrFooter = new HeaderOrFooter(obj);
        $scope.operations = operations || {};

        $scope.canAdd = !!$scope.operations.Create && !(obj || {}).id;
        $scope.canEdit = !!$scope.operations.Update && !!(obj || {}).id;
        $scope.headerOrFooter.form.editMode = $scope.canAdd || $scope.canEdit;

        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.headerOrFooter.selectedRoleId = $scope.selectedRole?.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };


        $scope.disabledBtn = false;
        $scope.actionBtnDisabled = false;
        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            if (!$scope.closingModal) {
                $scope.headerOrFooter.form.restore_Data();
                $scope.headerOrFooter.restoreTemplates();

                $scope.headerOrFooter.tabWarning = false;
                $scope.headerOrFooter.hightlight = false;
                $scope.disabledBtn = false;
                $scope.selectedIndex = $scope.navToIndex;
            } else {
                $scope.close(true);
            }

        }
        $scope.$on('tab-change', function (event, tabEvent) {
            if (!$scope.headerOrFooter.form.dirty && !$scope.headerOrFooter.checkTemplatesDirty()) {
                tabEvent.accept();
            } else {
                // capture the index of the tab cliked
                $scope.navToIndex = tabEvent.index;
                // set the warning params
                $scope.headerOrFooter.hightlight = true;
                $scope.headerOrFooter.tabWarning = true;

                $scope.disabledBtn = true;
                // prevent tab change
                tabEvent.reject();
            }

            
        });

        $scope.updateTabInfo = function () {
            $scope.headerOrFooter.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $scope.close(true);
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.headerOrFooter.tabWarning = false;
            $scope.headerOrFooter.hightlight = false;
            $scope.disabledBtn = false;
            $scope.closingModal = false;
        };
        $scope.isModified = false;

        $scope.save = function (isAdd) {
            var p = $q.defer();
            $scope.disabledBtn = true;
            $scope.headerOrFooter.tabWarning = false;
            $scope.headerOrFooter.hightlight = false;
            $scope.message = "Saving...";
            var action = $scope.selectedIndex == 0 ? $scope.headerOrFooter.save(isAdd) : $scope.headerOrFooter.sync_Templates(isAdd);
            action
                .then(function (result) {
                    $scope.isModified = true;
                    p.resolve();
                    $scope.disabledBtn = false;
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
            return p.promise;
        };



        $scope.close = function (isWarning) {
            if ((!$scope.headerOrFooter.form.dirty && !$scope.headerOrFooter.checkTemplatesDirty()) || isWarning)
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            else {
                $scope.headerOrFooter.hightlight = true;
                $scope.headerOrFooter.tabWarning = true;
                $scope.closingModal = true;
            }
        };
        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.save,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction
        }
    });
