import { GridsterModule } from 'angular-gridster2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../shared/modules/material.module';
import { DataCardViewModule } from '../../instance/data-card/data-card-view.module';
import { DataCardSettingDialogComponent } from './data-card-setting-dialog/data-card-setting-dialog.component';
import { ScreenFieldFormattingModelComponent } from './data-card-setting-part/data-card-setting-part.component';
import { DataCardSettingsComponent } from './data-card-settings/data-card-settings.component';
import { DataCardSettingsService } from './data-card-settings/data-card-settings.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    GridsterModule,
    DataCardViewModule,
  ],
  providers: [DataCardSettingsService],
  declarations: [DataCardSettingsComponent, DataCardSettingDialogComponent, ScreenFieldFormattingModelComponent],
  exports: [DataCardSettingsComponent, DataCardSettingDialogComponent, ScreenFieldFormattingModelComponent],
})
export class DataCardSettingsModule { }
