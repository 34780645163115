import { cipo } from 'cipo';

cipo.controller('genericClassController',
    function ($scope, $q, $http, $mdDialog, MODAL, item, options) {
        

        $scope.modalInfo = MODAL;
        $scope.disableBtns = false;
        $scope.item = item;

        var editedItems = [];

        $scope.options = options;

        var cancelWithReload = false;

        $scope.save = function (isAdd) {
            $scope.item.save()
                .then(function (r) {
                    editedItems.push(angular.copy(item.properties));
                    if (!isAdd) $mdDialog.hide(editedItems);
                    else {
                        $scope.item.init();
                        cancelWithReload = true;
                    }
                })
        }

        $scope.cancel = function () {
            if (cancelWithReload) $mdDialog.hide(editedItems);
            else $mdDialog.cancel();
        };

    });
