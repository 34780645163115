import { Directive, ElementRef, HostListener, forwardRef, input, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilsService } from '../../../services';

@Directive({
  selector: '[thousandSeparator]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThousandSeparatorDirective),
      multi: true,
    },
  ],
})
export class ThousandSeparatorDirective implements ControlValueAccessor {
  private el: HTMLInputElement;
  decimals = input<number>(null);
  thousandSeparator = input<boolean>(false);

  utilsService = inject(UtilsService);

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.el.value = value ? this.formatNumber(value) : value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.disabled = isDisabled;
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  // Event listener for input changes
  @HostListener('input', ['$event.target.value'])
  onInput(val: string): void {
    const endsWithDot = val.endsWith(this.utilsService.localeDecimalSeparator) && this.decimals();
    const value = this.utilsService.revertLocaleNumber(val).toString();

    // Handle minus sign
    if (val === '-') {
      this.onChange(null);
      return;
    }

    if (!value || isNaN(this.parseNumber(value))) {
      this.onChange(null);
      this.el.value = null;
      return;
    }
    const parsedValue = this.parseNumber(value);
    this.onChange(parsedValue);
    this.el.value = `${this.formatNumber(parsedValue)}${endsWithDot ? this.utilsService.localeDecimalSeparator : ''}`;
  }

  // Format number with thousand separators
  private formatNumber(value: number): string {
    if (!this.thousandSeparator()) {
      return value.toString();
    }
    return this.utilsService.formatLocaleNumber(value);
  }

  // Parse number by removing thousand separators
  private parseNumber(value: string): number {
    const numberValue = parseFloat(value);
    const fixedValue = numberValue.toFixed(this.decimals() ?? 0);
    return parseFloat(fixedValue);
  }

  // Placeholder methods for ControlValueAccessor
  private onChange = (_: any) => {};
  private onTouched = () => {};
}
