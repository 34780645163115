<app-data-list-command (applyfilters)="search($event.criteria)" [hidefilter]="true" [hideReload]="true">
    <div fxLayoutAlign="end center">
        <button mat-icon-button (click)="getMappedData()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    @if (tableColumns) {
        <app-data-list
            class="data-list"
            [tabledata]="tableData"
            [loading]="loading"
            (rowclicked)="openSettingModal($event)"
            [tablecolumns]="tableColumns"
            [tableoptions]="tableOptions"></app-data-list>
    }
</app-data-list-command>
