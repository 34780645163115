import { cipo } from 'cipo';
import moment from 'moment';

cipo.directive("managerfilters", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "manager": "="
        },
        templateUrl: "/ng/views/directives/filters/managerfilters.html",
        controller: function ($scope, FILTERS, URI, Message, userService, $timeout, MAX_NR_VISIBLE_SELECT_ITEMS) {
            $scope.Filters = FILTERS();

            // $scope.manager.filtersLookup = {};

            // $scope.cols = 12;

            var createLookup = function (options) {
                var ret = {};
                for (var i = 0; i < options.length; i++) {
                    ret[options[i].key] = options[i];
                }
                return ret;
            }

            var initFilters = function () {
                $scope.filters = { overallLogic: $scope.manager.globalFilterInfo ? $scope.manager.globalFilterInfo.overallLogic : FILTERS().logic[0].key };
                var item, filters = [];
                var columns = $scope.manager.layoutCols[$scope.manager.defaultColumns || 1];
                for (var i = 0; i < columns.length; i++) {

                    if (columns[i].typeId || columns[i].fieldTypeId) {

                        if (!columns[i].typeId) columns[i].typeId = columns[i].fieldTypeId;

                        item = {};
                        var displayTypeId;
                        if (!columns[i].displayTypeId) {
                            if (columns[i].typeId == 3) displayTypeId = 5;
                            else if (columns[i].typeId == 10) displayTypeId = 3;
                            else displayTypeId = 1;
                        } else displayTypeId = columns[i].displayTypeId;
                        var jointId = (columns[i].typeId || columns[i].fieldTypeId).toString() + displayTypeId.toString();
                        item = {
                            jointId: jointId,
                            dataSourceId: columns[i].dataSourceId,
                            relationId: columns[i].relationId,
                            dataSource: [],
                            field: columns[i].id ? columns[i].id.toString() : columns[i].replacementFor.toString(),
                            fieldName: columns[i].label || columns[i].label,
                            options: { format: columns[i].options },
                            operator: FILTERS().operatorsDict[jointId] ? FILTERS().operatorsDict[jointId][0].key : null,
                            defaultOperator: FILTERS().operatorsDict[jointId] ? FILTERS().operatorsDict[jointId][0].key : null,
                            operators: FILTERS().operatorsDict[jointId] || [],
                            value: null,
                            defaultValue: null,
                            type: $scope.Filters.filterTypeMapping[jointId],
                            typeId: columns[i].typeId,

                        }

                        if (item.type == "select") {
                            item.value = $scope.Filters.checkboxSourceDict[0].key;
                            item.defaultValue = $scope.Filters.checkboxSourceDict[0].key;

                        }
                        if (item.type == "multiselect") {
                            item.value = [];
                            item.defaultValue = [];
                        }


                        //
                        if ($scope.manager.globalFilterInfo && $scope.manager.globalFilterInfo.filters[item.field]) {
                            for (var key in $scope.manager.globalFilterInfo.filters[item.field]) {
                                if ($scope.manager.globalFilterInfo.filters[item.field].hasOwnProperty(key)) {
                                    if (key != 'value' || key == 'value' && item.typeId != '4')
                                        item[key] = $scope.manager.globalFilterInfo.filters[item.field][key];
                                    else {
                                        if (Object.prototype.toString.call($scope.manager.globalFilterInfo.filters[item.field][key]) != '[object Array]')
                                            item[key] = moment.utc($scope.manager.globalFilterInfo.filters[item.field][key]);
                                        else {
                                            for (var j = 0; j < $scope.manager.globalFilterInfo.filters[item.field][key].length; j++) {
                                                $scope.manager.globalFilterInfo.filters[item.field][key][j] = moment.utc($scope.manager.globalFilterInfo.filters[item.field][key][j]);
                                            }
                                            item[key] = $scope.manager.globalFilterInfo.filters[item.field][key];
                                        }



                                    }
                                }
                            }

                                if ((item.dataSource || []).length) item.dataSourceLookup = createLookup(item.dataSource);

                            }

                        filters.push(item);
                    }


                    }

                $scope.filters.filters = filters;
            }

            if ($scope.manager.currentFilters) $scope.filters = angular.copy($scope.manager.currentFilters)
            else initFilters();



            $scope.resetFilter = function (f) {
                f.value = f.defaultValue;
                f.operator = f.defaultOperator;
            }

                $scope.resetFilters = function () {
                    // Clear filters
                    for (var i = 0; i < $scope.filters.filters.length; i++) {
                        $scope.resetFilter($scope.filters.filters[i]);
                    }
                    // delete filters
                    if ($scope.manager.dataParams.filter) delete $scope.manager.dataParams.filter;
                    // Clear custom filters and from dataParams and UrlParams
                    for (var i = 0; i < ($scope.manager.customFilters || []).length; i++) {
                        $scope.manager.customFilters[i].value = false;

                        if ($scope.manager.dataParams && $scope.manager.dataParams.hasOwnProperty($scope.manager.customFilters[i].key)) {
                            $scope.manager.dataParams[$scope.manager.customFilters[i].key] = false;
                        }
                        if ($scope.manager.urlParams && $scope.manager.urlParams.hasOwnProperty($scope.manager.customFilters[i].key)) {
                            $scope.manager.urlParams[$scope.manager.customFilters[i].key] = false;
                        }
                    }
                    $scope.manager.hasFilters = false;
                    $scope.manager.currentFilters = null;
                    $scope.manager.globalFilterInfo = null;
                    $scope.manager.loadPage(null, null, true);
                }

            for (var i = 0; i < $scope.filters.filters.length; i++) {
                (function (index) {
                    Object.defineProperty($scope.filters.filters[index], 'hasValue', {
                        get: function () {
                            var hasValue = false;
                            if ($scope.filters.filters[index].type == "select") {
                                hasValue = $scope.filters.filters[index].value !== 0 ? true : false;

                            } else if ($scope.filters.filters[index].type == "multiselect") {
                                hasValue = $scope.filters.filters[index].value.length ? true : false;
                            } else if ($scope.filters.filters[index].type == "datetimepicker") {
                                hasValue = false;
                                if (Object.prototype.toString.call($scope.filters.filters[index].value) == '[object Array]') {
                                    if ($scope.filters.filters[index].value[0] && $scope.filters.filters[index].value[1]) hasValue = true;
                                }
                                else if ($scope.filters.filters[index].value) hasValue = true;
                            } else {
                                hasValue = $scope.filters.filters[index].value ? true : false;
                            }

                            return hasValue;

                        }
                    });
                }(i));
            }


            $scope.dateTime_reinit = function (f) {
                f.value = null;
                if (f.operator == 40) f.value = [null, null];
            }


            // select 

                $scope.selectOpen = function (f, searchTerm) {
                    if (f.dataSourceId || f.relationId) {
                        $scope.getDataSources(f, searchTerm);
                    } else {
                        $timeout(function () {
                            f.focusSearch = true;
                            $timeout(function () {
                                f.focusSearch = false;
                            }, 300);
                        }, 300);
                    }
                }

                $scope.changeOptionsPage = function (f, page) {
                    f.optionsPage = page;
                    $scope.getDataSources(f);
                }

                $scope.getDataSources = function (f, searchTerm) {
                    var field = f;
                    field.loading = true;
                    field.currentValue = field.value ? field.value.toString() : field.value;
                    var value = field.value && Object.prototype.toString.call(field.value) != '[object Array]' ? [field.value] : field.value;
                    var urlData = field.typeId == 10 ? URI.FIELD_DEFINITION.DATASOURCE_RELATION_FILTER : URI.FIELD_DEFINITION.DATASOURCE_LIST;
                    var bodyParams = {
                        selectedIds: value || [],
                        contractId: userService.system.userdata.contractId,
                        search: {
                            criteria: searchTerm || "",
                            isCurrent: false, 
                            page: field.optionsPage || 1, 
                            pagesize: MAX_NR_VISIBLE_SELECT_ITEMS
                        }
                    }
                    if (field.typeId == 10) {
                        bodyParams.relationId = field.relationId;
                        bodyParams.fieldId = field.field;
                    }
                    else bodyParams.id = field.dataSourceId;
                    $scope.manager[urlData.method](urlData, {
                        body: bodyParams
                    }, { headers: { moduleId: $scope.manager.moduleId } })
                        .then(function (r) {
                            //console.error("select", field, r);
                            field.isLoaded = true;
                            $timeout(function () {
                                field.focusSearch = true;
                                var options = (r || {}).data || [];
                                var optionsLookup = options.length ? createLookup(options) : {};
                                if (field.optionsPage > 1) {
                                    field.dataSource = [...field.dataSource, ...options];
                                    field.dataSourceLookup = {...field.dataSourceLookup, ...optionsLookup};
                                } 
                                else {
                                    field.dataSource = options;
                                    field.dataSourceLookup = optionsLookup;
                                }
                                field.optionsShowLoadMore = (r || {}).records > 0 && ((r || {}).records || 0) > (field.dataSource || []).length;
                            }, 0);

                    }).catch(function (e) {
                        Message.dberror(e);
                    }).finally(function () {
                        $timeout(function () {
                            field.loading = false;
                            $timeout(function () {
                                field.focusSearch = false;
                            }, 300);
                        }, 1);
                    })
            }

                $scope.dataSourceSearch = function (event, f, searchTerm) {
                    var field = f;
                    event.stopPropagation();
                    if (event.keyCode != 13) {
                        if (field.timeout) $timeout.cancel(field.timeout);
                        field.timeout = $timeout(function () {
                            $scope.getDataSources(f, searchTerm);
                            $timeout.cancel(field.timeout);
                        }, 1000);
                    } else {
                        $scope.getDataSources(f, searchTerm);
                    }
                }

                // 
                Object.defineProperty($scope, 'isAnyFiltersSelected', {
                    get: function () {
                        var applyDisabled = true;
                        for (var i = 0; i < $scope.filters.filters.length; i++) {
                            if ($scope.filters.filters[i].hasValue) applyDisabled = false;
                            break;

                        }
                        return applyDisabled;
                    }
                });

            $scope.applyFilters = function () {

                    $scope.manager.globalFilterInfo = null;

                var filters = { logic: $scope.filters.overallLogic, filters: [] };

                    for (var i = 0; i < $scope.filters.filters.length; i++) {
                        if ($scope.filters.filters[i].hasValue) {
                            // 
                            if ($scope.filters.filters[i].type == "multiselect") {
                                // multiselect
                                var multiselectFilters = [];
                                for (var j = 0; j < $scope.filters.filters[i].value.length; j++) {
                                    multiselectFilters.push({
                                        logic: 1,
                                        filters: [{
                                            field: $scope.filters.filters[i].field,
                                            operator: $scope.filters.filters[i].operator,
                                            options: ($scope.filters.filters[i].options || {}).format,
                                            value: $scope.filters.filters[i].dataSourceLookup[$scope.filters.filters[i].value[j]].value,
                                            fieldTypeId: $scope.filters.filters[i].typeId
                                        }]

                                })
                            }

                            filters.filters.push({ logic: 1, filters: multiselectFilters });

                            } else {

                                var value;
                                if (Object.prototype.toString.call($scope.filters.filters[i].value) == '[object Array]') {
                                    // is date and between operator
                                    value = [];
                                    for (var j = 0; j < $scope.filters.filters[i].value.length; j++) {
                                        value.push(($scope.filters.filters[i].value[j]).format($scope.filters.filters[i].options.format));
                                    }
                                } else value = $scope.filters.filters[i].value;

                            filters.filters.push({
                                logic: $scope.filters.overallLogic,
                                filters: [{
                                    field: $scope.filters.filters[i].field,
                                    operator: $scope.filters.filters[i].operator,
                                    options: ($scope.filters.filters[i].options || {}).format,
                                    value: value,
                                    fieldTypeId: $scope.filters.filters[i].typeId
                                }]

                            })
                        }
                    }
                }

                    // Check if we have any filters
                    if (filters.filters.length || ($scope.manager.customFilters && $scope.manager.customFilters.filter(f => f.value).length > 0)) {
                        $scope.manager.dataParams.filter = filters;
                        $scope.manager.hasFilters = true;
                        $scope.manager.currentFilters = $scope.filters;
                        $scope.manager.loadPage(null, null, true);
                    }
                    // We don't have filters, so we need to reset them
                    else {
                        $scope.resetFilters();
                    }

                    // Close filters
                    $scope.manager.closeFilters();

                    return filters;
                }
            }
        }
    });
    