import { cipo } from 'cipo';

cipo.factory("GenericClass", function (Model, Form, $q, Message) {
    // use prototype.formFields, prototype.formPattern prototype.urls {create: "", edit: "", delete: ""}
    // assumes id is named id
    // resetPropertiesOnAddNew = { property: defaultValue ... }
    var GenericClass = Model.extend(function (obj, urlParams, headerParams) {
        var self = this;
        self.properties = {};
        self.urlParams = urlParams || {};
        self.headerParams = headerParams || {};
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }
        if (!obj.id && self.formFields) self.createForm();
    });


    GenericClass.prototype.init = function () {
        var self = this;
        for (var key in self.resetPropertiesOnAddNew) {
            if (self.resetPropertiesOnAddNew.hasOwnProperty(key)) {
                self.properties[key] = self.resetPropertiesOnAddNew[key];
            }
        }
        self.createForm();            
    }

    GenericClass.prototype.createForm = function () {
        var self = this;
        if (self.formFields) {
            self.form = new Form(self.properties);
            self.form.initializing = true;
            self.form.set_Description(self.formFields(self));
            if (self.formPattern) self.form.setTemplate('grid', self.formPattern(self));
            self.form.initializing = false;
            self.form.store_Data();

            self.form.fieldsList[Object.keys(self.form.fieldsList)[0]].setFocus();
        } else {
            Message.warning('No fields set on the form');
        }
        
    }

    GenericClass.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var valid = true;
        if (self.form) {
            self.form.validate();
            valid = self.form.isValid;
        }
        if (valid) {
            if (self.form) self.form.loading = true;
            var dataURL = self.properties.id ? self.urls.edit(self) : self.urls.create(self);
            self[dataURL.method](dataURL, { url: self.urlParams, urltype: 'obj', body: self.properties }, { headers: self.headerParams || {}})
                .then(function (r) {
                    if (r) self.properties.id = r;
                    Message.info('Item saved successfully');
                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    if (self.form) {
                        self.form.loading = false;
                        self.form.catch(e);
                    }
                    else Message.dberror(e);
                })
        } else {
            p.reject();
        }
        

        return p.promise;
    }

    GenericClass.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting item...";
        self.deleting = true;

        self[self.urls.delete(self).method](self.urls.delete(self), { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (result) {
                Message.info('Item deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete item. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }


    return GenericClass;
});
