import { cipo } from 'cipo';

cipo.factory("SOVCategory", function (Model, Form, URI, $q, Message) {
    var SOVCategory = Model.extend(function (obj) {
        var self = this;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self[key] = obj[key];
                }
            }
        }
    });

    SOVCategory.prototype.get_Items = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOVI_SEARCH;
        var params = { categoryId: self.key, contractId: self.contractId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.items = r;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }


    

    return SOVCategory;
});
