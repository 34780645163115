<gridster [options]="gristerOptions" class="gridsterHoverable">
    <gridster-item *ngFor="let part of card?.parts" [item]="part">
        <app-data-card-view-part [part]="part"></app-data-card-view-part>
    </gridster-item>
</gridster>
<ng-container *ngIf="actionsFiltered.length">
    <button
        mat-icon-button
        [matMenuTriggerFor]="cardActionsMenu"
        aria-label="Actions"
        class="card-actions"
        (click)="cardActionsMenuClick($event)">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #cardActionsMenu="matMenu">
        <button mat-menu-item *ngFor="let action of actionsFiltered" (click)="cardActionClick(action)">
            <mat-icon *ngIf="action.matIcon">{{ action.matIcon }}</mat-icon>
            <span>{{ action.name }}</span>
        </button>
    </mat-menu>
</ng-container>
