import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { MimeTypesEnum } from 'src/app/models/module/fields/main';

export const ThumbnailsDict = {
  [MimeTypesEnum.PDF]: 'src/assets/icons/file-extensions/pdf.svg',
  [MimeTypesEnum.DOCX]: 'src/assets/icons/file-extensions/docx.svg',
  [MimeTypesEnum.XLSX]: 'src/assets/icons/file-extensions/xlsx.svg',
  [MimeTypesEnum.PPTX]: 'src/assets/icons/file-extensions/pptx.svg',
  [MimeTypesEnum.DOC]: 'src/assets/icons/file-extensions/docx.svg',
  [MimeTypesEnum.XLS]: 'src/assets/icons/file-extensions/xlsx.svg',
  [MimeTypesEnum.PPT]: 'src/assets/icons/file-extensions/pptx.svg',
  [MimeTypesEnum.ZIP]: 'src/assets/icons/file-extensions/zip.svg',
  [MimeTypesEnum.EMAIL]: 'src/assets/icons/file-extensions/email.svg',
  [MimeTypesEnum.PNG]: 'src/assets/icons/file-extensions/image.svg',
  [MimeTypesEnum.JPEG]: 'src/assets/icons/file-extensions/image.svg',
  default: 'src/assets/icons/file-extensions/unknown.svg',
} as const;

const DEFAULT_MIME_TYPE: string = 'image/png';

@Component({
  selector: 'cipo-img-base64',
  template: `
    <img
      [src]="src()"
      [width]="width()"
      [height]="height()"
      class="cipo-base64"
      [ngClass]="{ 'width--auto': !width(), 'height--auto': !height() }"
    />
  `,
  standalone: true,
  imports: [CommonModule],
})
export class CipoImgBase64 {
  readonly base64 = input<string>();
  readonly placeholder? = input<string>();
  readonly mimetype? = input<string, string>(DEFAULT_MIME_TYPE, {
    transform: mimetype => mimetype ?? DEFAULT_MIME_TYPE,
  });
  readonly width = input<number>();
  readonly height? = input<number>();
  readonly src = computed(() => {
    const base64 = this.base64();

    if (base64) {
      return `data:${this.mimetype()};base64, ${base64}`;
    } else if (this.placeholder()) {
      return this.placeholder();
    } else {
      return ThumbnailsDict[this.mimetype()] ?? ThumbnailsDict.default;
    }
  });
}
