import { cipo } from 'cipo';

cipo.factory('authService', ['localStorageService', 'AADB2C','$injector',
    function (localStorageService, AADB2C,$injector, )
    {
        var ASFactory = {};

        ASFactory.login = async function ()
        {
            let angularMsalService = $injector.get('msalAuthService');
            return await angularMsalService.loginRedirect();
        };

        ASFactory.logout = function ()
        {
            localStorageService.clearAll();

            let angularMsalService = $injector.get('msalAuthService');
            angularMsalService.logout();
        };

        ASFactory.passwordChange = function ()
        {
            window.location.href = AADB2C[location.host].POLICY_URLS.PASSWORD_CHANGE;
        };

        ASFactory.profileEdit = function ()
        {
            window.location.href = AADB2C[location.host].POLICY_URLS.PROFILE_EDIT;
        };

        ASFactory.isLoggedIn = function ()
        {
            const idToken = localStorage.getItem('msal.idtoken');
            
            return !!idToken;
        };

        return ASFactory;
    }]);

