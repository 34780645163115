import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-address-autocomplete',
  templateUrl: './demo-address-autocomplete.component.html',
  styleUrls: ['./demo-address-autocomplete.component.scss']
})
export class DemoAddressAutocompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
