import { Role } from '../../models/module/main';
import { MenuItem } from '../../system/menu/menu.component';

export interface MenuState {
  isOpen: boolean;
  menuItems: MenuItem[];
  activeItem?: MenuItem;
  activeModuleRoles?: Role[];
}

export const menuStateInitial: MenuState = {
  isOpen: false,
  menuItems: [],
  activeItem: undefined,
  activeModuleRoles: [],
};
