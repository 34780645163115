import { cipo } from 'cipo';

cipo.factory("Maps", function (Model, authService, userService, Permissions) { //add more services here, as needed
    var Maps = Model.extend(function (obj) {
        var self = this;
        self.loading = true;
        self.properties = {
            entityName: "",
            entityId: null,
            moduleId: null,
            iconId: null,
            layouts: [],
            fields: []
        };
    });
    //examples
    //add your model logic here
});