import { cipo } from 'cipo';

cipo.directive("screendetail", function (STATES_INFO, MODAL) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "screen": "=",
            "operations": "=",
            "hasactions": "=",
            "module": "="

        },
        templateUrl: "/ng/views/directives/tabdetails/screendetail.html",
        controller: function ($scope, ACTIONS, Message) {
            // $scope.list = $scope.options.listKey ? $scope.parent[$scope.options.listKey] : [];
            $scope.modalInfo = MODAL;
            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };
            $scope.propFieldsOptions = {
                loadingFlag: 'isPropFieldsLoaded',
                listKey: "propFields",
                listItemId: "fieldId",
                listItemUsed: "isUsedOnScreen",
                listItemUsedText: "on form",
                rowClass: function (item) {
                    // console.log('item', item);
                    if (item.properties.isContractSystemField == true)
                        return "propField isSystemContract";

                    return item.properties.fieldTypeId == 20 || item.properties.fieldTypeId == 21 ? "propField isRelation" : "propField";
                },
                actionsBeforeDefault: [
                    {
                        setProperties: ACTIONS.ADD_FIELD,
                        // name: "Rename",
                        disabled: function (item, parent) {
                            if (item.properties.isUsedOnScreen) return true;
                            else return false;
                        },
                        condition: function () {
                            return true;
                        },
                        click: function (item) {
                            $scope.screen.addPropFieldToDashboard(item, true);
                        }
                    }
                ],
                editAction: {
                    setProperties: ACTIONS.DETAILS,
                    name: "Edit Details",
                    click: function (item) {
                        $scope.screen.addPropFieldToDashboard(item);
                    },
                    condition: function (item) {
                        return item.properties.fieldTypeId == 20 || item.properties.fieldTypeId == 21 || item.properties.isContractSystemField == true ? false : true;
                    }
                },
                disableDeleteCondition: function (item) { if (item.properties.isUsedOnScreen || item.properties.fieldSystemName) return true; else return false; },
                showDelete: function (item) { if (item.properties.fieldTypeId == 20 || item.properties.fieldTypeId == 21) return false; else return true; },
                title: "Fields",
                parent: $scope.screen,
                // deleteCondition: function (item) { if (item.properties.anyFieldInUse || item.properties.default) return false; else return true; },
                subtitle: {
                    text: "Type: ", content: function (item) {
                        var ret;
                        if (item.properties.isRelation && item.properties.fieldTypeId!=11) ret = "Relation";
                        else if (item.properties.isFormula) ret = "Formula";
                        else ret = $scope.screen.fieldTypeLookup[item.properties.fieldTypeId];
                        return ret;
                    }
                },
                addButton: {
                    condition: function (item) {
                        return false;
                    }
                },
                save_or_delete: "propField_Action",
                //detailsIcon: 'mdi mdi-table-edit',
                //hasDetails: true,
                //type: "screen",
                operations: $scope.operations

            }

            // table

        }

    }
});
