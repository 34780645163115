import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScheduledEmailsComponent } from './scheduled-emails.component';
import { ScheduledEmailService } from './scheduled-emails.service';
import { NewScheduleDialogComponent } from './new-schedule-dialog/new-schedule-dialog.component';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';

@NgModule({
  declarations: [NewScheduleDialogComponent, ScheduledEmailsComponent],
  exports: [ScheduledEmailsComponent, NewScheduleDialogComponent],
  imports: [CommonModule, SharedComponentsModule],
  providers: [ScheduledEmailService],
})
export class ScheduledEmailsModule {}
