import { Component, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { AddFieldComponent } from './add-field/add-field.component';
import { CipoFieldTypes, CipoControl } from '../../shared/components/fields/common';
import { CipoFieldsModule } from '../../shared/modules/cipo-fields.module';
import { CipoFormModule } from '../../shared/modules/forms.module';
import { demoFields, DemoFieldData } from '../data/demo-cipo-fields';

@Component({
  selector: 'app-demo-cipo-fields',
  standalone: true,
  imports: [CipoFormModule, CipoFieldsModule, AddFieldComponent],
  templateUrl: './demo-cipo-fields.component.html',
})
export class DemoCipoFieldsComponent {
  dialog = inject(MatDialog);

  form = signal(new FormGroup<{ [x: string]: CipoControl }>({}));
  FIELD_TYPES = CipoFieldTypes;

  constructor() {
    demoFields.forEach(field => this.addFormControl(field));
  }

  logForm() {
    console.log(this.form().value);
  }

  changeRequired(checked: boolean) {
    this.form.update(form => {
      for (const controlName in form.controls) {
        const control = form.controls[controlName];
        control.fieldData.required = checked;
        control.setValidators(checked ? Validators.required : null);
        control.updateValueAndValidity();
      }
      return form;
    });
  }

  changeReadonly(checked: boolean) {
    this.form.update(form => {
      for (const controlName in form.controls) {
        form.controls[controlName].fieldData.readonly = checked;
        if (form.controls[controlName].fieldData.type === CipoFieldTypes.Checkbox) {
          if (checked) {
            form.controls[controlName].disable();
          } else {
            form.controls[controlName].enable();
          }
        }
      }
      return form;
    });
  }

  addField() {
    this.dialog
      .open(AddFieldComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        width: '60vw',
        data: 'test',
      })
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe((result: DemoFieldData) => this.addFormControl(result));
  }

  addFormControl(controlData: DemoFieldData) {
    const { name, defaultValue, ...props } = controlData;
    const control = new CipoControl(defaultValue, props);
    if (props.required) {
      control.setValidators(Validators.required);
    }
    if (props.type === CipoFieldTypes.Checkbox && props.required) {
      control.setValidators(Validators.requiredTrue);
    }
    this.form().addControl(name, control);
  }
}
