import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, debounceTime, switchMap } from 'rxjs';
import { AddressModel } from './models/address.model';
import { Position } from './models/position.model';
import { AddressService } from './services/address.service';
import { DEBOUNCE_TIMES } from 'src/app/shared/consts';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit {

  mapsOpened: boolean = false;

  @Output()
  onAddressSelected = new EventEmitter<AddressModel>()

  addressFormControl: FormControl = new FormControl();

  constructor(
    private addressService: AddressService,
    private el: ElementRef) { }

  keyUp$: Subject<string> = new Subject<string>();
  addressResults$: Observable<any>;
  selectedPosition: Position;

  ngOnInit(): void {
    this.addressResults$ = this.keyUp$
      .pipe(
        debounceTime(DEBOUNCE_TIMES.default),
        switchMap(x => this.addressService.getAddressSuggestions(x)),
      );
  }

  onKey(event: any) { // without type info
    this.keyUp$.next(event.target.value);
  }

  openMaps() {
    this.mapsOpened = !this.mapsOpened && this.selectedPosition !== null && this.selectedPosition !== undefined;
  }

  onOptionSelected($event) {
    this.selectedPosition = $event.option.value.position as Position;
  }

  getAddressText(option) {
    return option?.address?.freeformAddress ? option.address.freeformAddress : option;
  }

  addressChanged() {
    this.onAddressSelected.emit(AddressModel.Default());
  }

  handleCoordinatesChanged(coordinates) {
    this.addressService.getAddressFromCoordinates(coordinates[1], coordinates[0]).subscribe(x => {
      const address = x.addresses[0]
      if(address !== null && address?.address?.street === undefined) {
        this.addressFormControl.setValue(address?.address?.freeformAddress + ` (${address.position})`)
      } else {
        this.addressFormControl.setValue(address.address.freeformAddress)
      }
    })
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const clickedInside = this.el.nativeElement.contains(event.target);
    if (!clickedInside) {
      // Clicked outside the component
      this.mapsOpened = false;
    }
  }
}
