import { cipo } from 'cipo';

cipo.factory("Workday", function ($q, Model, URI, Message, Form, Dictionaries) {
    var Workday = Model.extend(function (obj) {

        var self = this;
        self.properties = {
            id: null,
            name: "",
            shifts: [],
            isUsed: false
        }
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }
        self.form = new Form(self.properties);
        self.form.initializing = true;
        self.form.loading = true;
        // if (id) self.get_data();
        Dictionaries.ScheduleShifts()
            .then(function (r) {
                var shiftsDict = r;
                self.form.set_Description({
                    name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 64 } },
                    shifts: { label: 'Shifts', type: 'select', multiple: true, options: shiftsDict }
                });

                self.form.setTemplate('grid', [
                    { name: 50, shifts: 50 }
                ]);

            })
            .catch(function (e) { })
            .finally(function () {
                self.form.initializing = false;
                self.form.loading = false;
            })
        
    });

    Workday.prototype.get_data = function () {
        var self = this;
        var dataURL = URI.SCHEDULE_DAY.GET;
        self.form.loading = true;
        self[dataURL.method](dataURL, { url: {id: self.properties.id}, urltype: 'obj' })
            .then(function (r) {
                for (var key in r) {
                    if (r.hasOwnProperty(key)) {
                        self.properties[key] = r[key];
                    }
                }
                self.form.set_Data(self.properties);
                self.form.store_Data();

            })
            .catch(function (e) { })
            .finally(function () {
                self.form.loading = false;
            })
    }

    Workday.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deleting = true;
        var dataURL = URI.SCHEDULE_DAY.DELETE;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info('Workday deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject();
            })
            .finally(function () {
                self.deleting = false;
            })
        return p.promise;
    }

    Workday.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = !self.properties.id ? URI.SCHEDULE_DAY.CREATE : URI.SCHEDULE_DAY.UPDATE;
        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            self[dataURL.method](dataURL, self.properties)
                .then(function (r) {
                    Message.info('Workday saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    Message.dberror(e);
                    p.reject();
                })
                .finally(function () {
                    self.form.loading = false;
                })
        }
        else {
            self.form.loading = false;
        }
        return p.promise;
    }

    return Workday;
});
