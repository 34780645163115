<table matSort mat-table [dataSource]="pdfMarkups" class="mat-elevation-z0 pdfmarkup-table"
    (matSortChange)="sortData($event)">

    <!-- name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">{{ 'PDF_MARKUPS.DOC_TABLE.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>

    <!-- created by Column -->
    <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">{{ 'PDF_MARKUPS.DOC_TABLE.CREATEDBY' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.createdBy}}</td>
    </ng-container>

    <!-- created on Column -->
    <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">{{ 'PDF_MARKUPS.DOC_TABLE.CREATEDON' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.createdOn | itvDateTime}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pdf-markup-element" (click)="openPopup(row)">
    </tr>

</table>