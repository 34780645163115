import { on } from '@ngrx/store';
import { produce } from 'immer';
import { CoreState, StoreReducer } from '../interfaces';
import { contextActions } from './context.actions';

export const contextReducers: StoreReducer<CoreState>[] = [
  on(contextActions.set, (state, { context }) =>
    produce(state, draft => {
      draft.context.program = context.program;
      draft.context.project = context.project;
      draft.context.contract = context.contract;
    }),
  ),
];
