import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { DisplayType, FieldTypeEnum, SystemFieldsEnum } from '../../../../models/module/fields/enums';
import { FieldModel, ScreenFieldModel } from '../../../../models/module/fields/main';
import { DataCard, DataCardCustomField, DataCardCustomFields, DataCardViewPart } from '../../../../models/module/main';
import { ScreenModel } from '../../../../models/module/screen';
import { DataCardSettingDialogComponent } from '../data-card-setting-dialog/data-card-setting-dialog.component';
import { DataCardSettingsService } from './data-card-settings.service';

@Component({
  selector: 'app-data-card-settings',
  templateUrl: './data-card-settings.component.html',
  styleUrls: ['./data-card-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCardSettingsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private settingsService: DataCardSettingsService,
    private ref: ChangeDetectorRef,
  ) {}

  @Input() screen: ScreenModel;

  @Input('savedata') set saveDataExternally(value: boolean) {
    if (value) {
      this.save();
    }
  }

  @Output('ondatasaved') onDataSaved = new EventEmitter<void>();
  @Output('onchanged') onChanged = new EventEmitter<boolean>();

  fieldSearch: string;
  fields: ScreenFieldModel[];
  excludeFieldTypes = [
    FieldTypeEnum.Attachment,
    FieldTypeEnum.User,
    FieldTypeEnum.Table,
    FieldTypeEnum.SubModule,
    FieldTypeEnum.Formula,
    FieldTypeEnum.Relation,
    FieldTypeEnum.Weather,
    FieldTypeEnum.Annotation,
  ];

  get filteredFields(): ScreenFieldModel[] {
    if (!this.fieldSearch) {
      return this.fields;
    }
    return this.fields.filter(f => (f.label || '').toLowerCase().includes(this.fieldSearch.toLowerCase()));
  }

  gridsterItems: DataCardViewPart[] = [];
  previewCard: DataCard = {
    parts: [],
  };
  changed = false;

  gristerOptions: GridsterConfig = {
    gridType: 'scrollVertical',
    compactType: 'none',
    margin: 10,
    outerMargin: true,
    outerHeight: 500,
    mobileBreakpoint: 300,
    minCols: 12,
    maxCols: 12,
    minRows: 20,
    maxRows: 60,
    maxItemCols: 20,
    minItemCols: 1,
    maxItemRows: 12,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    fixedColWidth: 250,
    fixedRowHeight: 250,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    draggable: {
      delayStart: 0,
      enabled: true,
    },
    resizable: {
      delayStart: 0,
      enabled: true,
    },
    swap: true,
    pushItems: true,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: 'always',
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: true,
    itemChangeCallback: (item: GridsterItem) => {
      this.partsChanged(true);
    },
  };

  customFields = DataCardCustomFields;

  ngOnInit(): void {
    this.ensureTranslationIsLoaded()
      .pipe(
        switchMap(() => this.getFields()),
        switchMap(() => this.getScreenFields()),
        finalize(() => this.ref.markForCheck()),
      )
      .subscribe();
  }

  getFieldTypeDescription(field: ScreenFieldModel): string {
    return field.typeId ? this.translate.instant('MODULE.FIELD_TYPE.' + FieldTypeEnum[field.typeId]) : '';
  }

  isFieldAdded(field: ScreenFieldModel): boolean {
    return this.gridsterItems.some(i => i.field.id === field.id);
  }

  addGridsterItem(field: ScreenFieldModel): void {
    const id = `new-${this.gridsterItems.length + 1}`;
    this.gridsterItems.push(new DataCardViewPart(id, { ...field }, true));
  }

  addField(event: Event, field: ScreenFieldModel): void {
    event?.stopPropagation();
    this.addGridsterItem(field);
    this.partsChanged(true);
  }

  removeField(event: Event, field: ScreenFieldModel): void {
    event?.stopPropagation();
    this.gridsterItems = this.gridsterItems.filter(i => i.field.id !== field.id);
    this.partsChanged(true);
  }

  getItemTypeDescription(item: DataCardViewPart): string {
    const typeName = item.field.typeId
      ? this.translate.instant('MODULE.FIELD_TYPE.' + FieldTypeEnum[item.field.typeId])
      : '';
    const displayName = this.translate.instant('MODULE.DISPLAY_TYPE.' + DisplayType[item.displayTypeId]);

    if (typeName == displayName) {
      return displayName;
    }

    return `${typeName} ${displayName}`;
  }

  getItemTitle(item: DataCardViewPart): string | null {
    if (item.field.label == item.field.name) {
      return `Field: ${item.field.name}`;
    }

    return `Field: ${item.field.name}\r\nLabel: ${item.field.label}`;
  }

  removeItem(event: Event, item: DataCardViewPart) {
    event.stopPropagation();
    this.gridsterItems = this.gridsterItems.filter(i => i.id !== item.id);
    this.partsChanged(true);
  }

  editItem(event: Event, item: DataCardViewPart) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(DataCardSettingDialogComponent, {
      panelClass: 'cipo-dialog',
      data: item,
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.partsChanged(true);
    });
  }

  addCustomField(customField: DataCardCustomField) {
    const item = this.mapFieldToScreenField(customField);
    item.label = this.getCustomFieldLabel(customField);
    item.name = item.label;
    this.addGridsterItem(item);
  }

  getCustomFieldLabel(customField: DataCardCustomField) {
    return this.translate.instant('MODULE.DISPLAY_TYPE.' + DisplayType[customField.displayTypeId]);
  }

  isCustomFieldEnabled(customField: DataCardCustomField) {
    return (
      customField.multiple ||
      (!customField.multiple && !this.gridsterItems.some(i => i.field.displayTypeId === customField.displayTypeId))
    );
  }

  partsChanged(changed: boolean): void {
    this.changed = changed;
    this.previewCard = {
      parts: [...this.gridsterItems],
    };
    this.onChanged.emit(changed);
  }

  private ensureTranslationIsLoaded() {
    return this.translate.get('MODULE.DISPLAY_TYPE');
  }

  private getFields() {
    // gets the list of the field definition for the screen
    const fieldsReq = this.settingsService.getFields(this.screen);
    // gets the state name field
    const fieldStateReq = this.settingsService.getField(SystemFieldsEnum.state_name);

    return zip(fieldsReq, fieldStateReq).pipe(
      tap(([fields, fieldState]) => {
        this.fields = [...(fields.data || []), this.changeTypeForStateField(fieldState)]
          .map(this.mapFieldToScreenField)
          .filter(f => !this.excludeFieldTypes.includes(f.typeId));
        this.fields.sort((a, b) => a.label.localeCompare(b.label));
      }),
    );
  }

  private getScreenFields() {
    return this.settingsService.getScreenFields(this.screen).pipe(
      tap(sf => {
        (sf?.data || []).forEach(f => this.addGridsterItem(f));
        this.partsChanged(false);
      }),
    );
  }

  private changeTypeForStateField(f: FieldModel): FieldModel {
    f.displayTypeId = DisplayType.Status;
    return f;
  }

  private mapFieldToScreenField(f: FieldModel): ScreenFieldModel {
    return {
      id: f.id,
      name: f.name,
      label: f.label,
      typeId: f.typeId || FieldTypeEnum.Text,
      displayTypeId: f.displayTypeId || DisplayType.Text,
      formattings: f.formattings || [],
      restrictions: f.restrictions || [],
    } as ScreenFieldModel;
  }

  private save() {
    const screenFields = (this.gridsterItems || []).map(i => ({
      ...i.field,
      x: i.x,
      y: i.y,
      cols: i.cols,
      rows: i.rows,
    }));
    this.settingsService.syncScreenFields(this.screen, screenFields).subscribe(_ => {
      this.gridsterItems = [];
      this.getScreenFields().subscribe(_ => {
        this.onDataSaved.emit();
      });
    });
  }
}
