import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { ScreenFieldModel } from '../../../../models/module/fields/main';
import { DataListViewAction } from '../../../../models/module/main/dataListViewAction';
import { CalendarTile, CalendarViewMode } from '../calendar.model';

export const CalendarDialogMorePrefix = 'calendar-dialog-more';
export const CalendarDialogMoreInnerWidth: number = 250;
export const CalendarDialogMoreOuterWidth: number = 300;
export const CalendarDialogMoreInnerHeight: number = 236;
export const CalendarDialogMoreOuterHeight: number = 345;

@Component({
  selector: 'app-calendar-dialog-more',
  templateUrl: './calendar-dialog-more.component.html',
  styleUrls: ['./calendar-dialog-more.component.scss']
})
export class CalendarDialogMoreComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    tile: CalendarTile,
    screenFields: ScreenFieldModel[],
    actions: DataListViewAction[],
  }) { }

  @HostBinding('style.min-width.px') minWidth = CalendarDialogMoreInnerWidth;
  @HostBinding('style.max-width.px') maxWidth = CalendarDialogMoreInnerWidth;

  get moreContentMaxHeight(): number {
    return CalendarDialogMoreInnerHeight;
  }

  get datetime(): string {
    const format = this.data.tile.viewMode == CalendarViewMode.Year ? 'MMMM, YYYY' : 'LL';
    return moment(this.data.tile.date).format(format);
  }
}