import { MenuPermissionViewModel } from "../permission";
import { MenuType } from "./menuType";

export interface MenuModel {
    id?: number;
    name: string;
    code?: string;
    iconId?: number;
    color?: string;
    position?: number;
    parentId?: number;
    typeId?: MenuType;
    moduleId?: number;
    perContract?: boolean;
    url?: string;
    email?: string;
    manageWorkflowGroups?: boolean;
    children?: MenuModel[];
    operations?: MenuPermissionViewModel[];
    openInNewTab?: boolean;
    hidden?: boolean;
}