import { WeekDaysEnum } from 'src/app/models/common';

export enum SendMailToEnum {
  BIC_ROLE = 2,
  DOCUMENT_INITIATOR = 3,
}

export enum JobTypesEnum {
  EMAIL_SCHEDULE = 1,
}

export enum ScheduleFrequencyEnum {
  DAILY = 1,
  WEEKLY = 2,
  MANUAL = 3,
}

export type ScheduledEmailModule = {
  key: number;
  value: string;
};

export type ScheduledEmailAdditionalRoles = {
  isUsed: boolean;
  isDisabled: boolean;
  key: number;
  value: string;
  name: string;
};

export interface ScheduledEmail {
  id?: number;
  createdOn?: string;
  isEnabled?: boolean;
  scheduleFrequency: ScheduleFrequencyEnum;
  dayOfWeek?: WeekDaysEnum;
  timeOfDay: string;
  startDate: string;
  sendTo: SendMailToEnum[];
  additionalRecipients: number[];
  moduleIds: number[];
  jobName: string;
  description: string;
  jobType: JobTypesEnum;
  sendNow?: boolean;
  jobAttributes: {
    id?: number;
    subject: string;
    emailBody: string;
  };
}

export interface ScheduledEmailsResponse {
  data: ScheduledEmail[];
  records: number;
}
