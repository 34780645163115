import { cipo } from 'cipo';
import moment from 'moment';

cipo.controller('holidaysController',
    function ($scope, $state, Manager, userService, $mdDialog, URI, rememberManagerService, ACTIONS, Message) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        $scope.permissionsId = userService.getPermissionsIdList($state.current.code);
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        var sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }

        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.HOLIDAY.SEARCH,
            otherParams: {
                ShowDisabled: true
            },
            dataWrapper: 'data',
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.editHoliday(row.id);
            },
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    icon: 'mdi mdi-calendar-plus',
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.editHoliday();
                    }
                },
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editHoliday(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.saveOrganization(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    // displayOnRow: $scope.operations.Delete ? true : false,
                    condition: function (row) {
                        if (row.operations.Delete)
                            return true;
                        else return false;
                    },
                    conditionOnTop: function (rows) {
                        if ($scope.operations.Delete)
                            return true;
                        else return false;
                    },
                    // alwaysOnTop: false,
                    click: function (rows) {
                        $scope.delete(sendList(rows));
                    }
                },
            ],
            parseData: function (data) {
                    var data = data || [];

                    for (var i = 0; i < data.length; i++) {
                        data[i].date = moment(data[i].date).format(userService.formats.date);
                        data[i].isWorkDay = data[i].isWorkDay ? '<i class="mdi mdi-check"></i>' : "-"; 
                    }

                    return data;
                },
        });

        $scope.manager.set_Columns([
            { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 40, fieldTypeId: 1 },
            { name: 'date', label: 'Date', type: 'text', onTablet: true, onPhone: true, width: 30 },
            { name: 'isWorkDay', label: 'Working Day', type: 'checkbox', onTablet: true, onPhone: true, width: 30 }
        ]);

        $scope.manager.loading = true;
        $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
            function() {
                $scope.manager.loading = false;
            },
            function() {
                $scope.manager.loadPage()
                .finally(function () {
                    $scope.manager.loading = false;
                });
            }
        );

        $scope.editHoliday = function (id, ev) {
            $mdDialog.show({
                locals: { id: id || null, operations: $scope.operations, module: $scope.module },
                controller: 'editHolidayController',
                templateUrl: '/ng/views/admin/modals/editHoliday.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function () {
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                });
        }

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.delete = function (list) {
            var list = list || [];
            if (list.length) {
                //var d = dialogs.confirm('Activating Roles', 'Are you sure you want to activate the selected Role(s)?');
                $mdDialog.show(confirm('Deleting Holiday', 'Are you sure you want to delete the selected Holiday?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.HOLIDAY.DELETE.method](URI.HOLIDAY.DELETE, { body: { list: list } })
                            .then(function () {
                                Message.info('Holiday deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        // cancel pressed
                    });

            } else {
                Message.info('Please select the item(s) to delete');
            }
            //$mdDialog.show(confirm('Deleting Holiday', 'Are you sure you want to delete the selected Holiday?'))
            //    .then(function () {
            //        $scope.manager.loading = true;
            //        $scope.manager[URI.HOLIDAY.DELETE.method](URI.HOLIDAY.DELETE, { url: { id: id }, urltype: 'obj' })
            //            .then(function () {
            //                Message.info('Holiday deleted successfully');
            //            })
            //            .catch(function (e) {
            //                if (e.data.ids) {
            //                    messageContent = $scope.processError(e.data.ids, "deleted");
            //                    var d = dialogs.notify("Deleting Holidays", messageContent);
            //                } else {
            //                    Message.dberror(e, $scope.manager.rows, 'id', 'name');
            //                }

            //                //$scope.manager.loading = false;
            //                //Message.dberror(e);
            //            })
            //            .finally(function () {
            //                $scope.manager.page = 1;
            //                $scope.manager.loadPage();
            //            })
            //    })
            //    .catch(function () {
            //        console.log('cancel pressed');
            //    });
        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.permissionsId = [...userService.getPermissionsIdList($state.current.code)];
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });



    });
