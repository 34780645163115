import { cipo } from 'cipo';

cipo.factory("Financial", function (Model, Form, URI, Message, userService, $q, Dictionaries, WEEKDAYS, Permissions) {
    var Financial = Model.extend(function (id) {
        var self = this;

        self.tabWarning = false;
        self.highlight = false;

        
        var weekdaysDict = WEEKDAYS;

        self.init(false, id ? false : true);
        if (id) {
            self.properties.id = id;
            
        }
        self.form = new Form(self.properties);
        self.form.loading = true;
        self.form.set_Description({
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
            description: { label: 'Description', type: 'textarea', validation: { required: true, maxChars: 4000 } },
            anot1: { label: "", annotation: 'Create new payments in past periods:', type: 'annotation' },
            pastPeriodsPaymentAllowedOn: { label: 'Number of periods:', type: 'text' } ,
            allPeriods: { label: 'All periods', type: 'checkbox' },
            anot: { label: "", annotation: '<h3>Schedule periods consist of</h3>', type: 'annotation' },
            intervalsPerPeriod: { label: 'Intervals per period', type: 'number', validation: { required: true } },
            intervalKey: { label: 'Interval type', type: 'select', options: [], validation: { required: true } },
            contractEndDateIsHardStop: { label: 'Last period ends on contract completion date', type: 'checkbox' },
            //periodEndIsWorkday: { label: 'Period end is workday', type: 'checkbox' },
            //periodIntervalLastDay: { label: 'Period interval last day', type: 'text' }
        });
        Dictionaries.Intervals()
            .then(function (r) {
                //intervals = r;
                self.form.set_Description({ intervalKey: { label: 'Period type', type: 'select', options: r, validation: { required: true } } }, true);
                if (id) self.get_Data();
                else {
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                    self.form.loading = false;
                }
            })
            .catch(function (e) { console.error(e) });

        self.form.setTemplate('grid', [
            { name: 100 },
            { description: 100 },
            { anot1: 100 },
            { pastPeriodsPaymentAllowedOn: 20, allPeriods: 30, contractEndDateIsHardStop: 50 },
            { anot: 100 },
            { intervalKey: 50, intervalsPerPeriod: 50 },
            
        ]);


        // Period should always end on a work day
        
        var weekRadio = [
            { key: 1, value: "Select day" },
            { key: 0, value: "Last day of period" }
        ];
        
        var daysDict = [];
        for (var i = 1; i < 29; i++) {
            daysDict.push({ key: i, value: i });
        }

        self.special = {
            workdayRadio: { peiwNumeric: { label: 'Period ends on', type: 'radio', options: weekRadio } },
            weekDay: { periodIntervalLastDay: { label: 'Day of the week', type: 'select', options: weekdaysDict } },
            monthDay: { periodIntervalLastDay: { label: 'Day of the month', type: 'select', options: daysDict } },
            periodEnd: { periodEndIsWorkday: { label: 'Period should always end on a work day', type: 'checkbox' } },
            
        }

        
        
    });

    Financial.prototype.init = function (isAdd, isNew) {
        var self = this;
        self.properties = {
            id: null,
            description: "",
            name: "",
            anot: "",
            anot1: "",
            allPeriods: false,
            contractEndDateIsHardStop: false,
            intervalKey: null,
            intervalsPerPeriod: null,
            periodEndIsWorkday: false,
            periodIntervalLastDay: null,
            pastPeriodsPaymentAllowedOn: 0,
            peiwNumeric: this.periodIntervalLastDay ? 1 : 0,
            isUsed: false,
            permissions: isAdd|| isNew ? [1,2]: [2]
        };

        if (isAdd) {
            self.form.set_Data(self.properties);
            self.form.store_Data();

        }
        if (isNew || isAdd) {

            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    }

    Financial.prototype.set_subForm = function(p) {
        var self = this;
        // self.properties.intervalsPerPeriod = null;
        
        self.form.fieldsList.intervalsPerPeriod._clearErrors();
        self.validateInterval();
        self.periodForm = new Form(self.properties);
        var objToSet = {};
        var grid = [];
        switch (p) {
            case 1:
                objToSet = self.special.periodEnd;
                grid = [{ periodEndIsWorkday: 100 }];
                break;
            case 2:
                objToSet.peiwNumeric = self.special.workdayRadio.peiwNumeric;
                objToSet.periodIntervalLastDay = self.special.weekDay.periodIntervalLastDay;
                objToSet.periodEndIsWorkday = self.special.periodEnd.periodEndIsWorkday;
                grid = [{ peiwNumeric: 50, periodIntervalLastDay: 50 }, { periodEndIsWorkday: 100 }];
                break;
            case 3:
                objToSet.periodIntervalLastDay = self.special.monthDay.periodIntervalLastDay;
                objToSet.peiwNumeric = self.special.workdayRadio.peiwNumeric;
                objToSet.periodEndIsWorkday = self.special.periodEnd.periodEndIsWorkday;
                grid = [{ peiwNumeric: 50, periodIntervalLastDay: 50 }, { periodEndIsWorkday: 100 }];
                break;
            case 4:
                objToSet = self.special.periodEnd;
                grid = [{ periodEndIsWorkday: 100 }];
                break;
            default:
                // console.error("invalid period id!!");
        }

        self.periodForm.set_Description(objToSet);
        self.periodForm.setTemplate('grid', grid);
        if ((self.properties.intervalKey == -2 || self.properties.intervalKey == -3) && self.properties.peiwNumeric == 0) {
            self.periodForm.fieldsList.periodIntervalLastDay.editMode = false;
        }
        if (self.properties.id && !self.operations.Update) {
            self.periodForm.editMode = false;
        }
    }

    Financial.prototype.validateInterval = function () {
        var self = this;
        var p = $q.defer();
        self.form.fieldsList.intervalsPerPeriod._clearErrors();
        if (self.properties.peiwNumeric !=0 && !self.properties.periodIntervalLastDay) {
            self.periodForm.setFieldInvalid('periodIntervalLastDay', 'A day must be selected');
        }
            
        if (self.properties.intervalKey)
            switch (self.properties.intervalKey) {
                case -1:
                    if (self.properties.intervalsPerPeriod < 1 || self.properties.intervalsPerPeriod > 365 || self.properties.intervalsPerPeriod != parseInt(self.properties.intervalsPerPeriod, 10)) {
                        self.form.setFieldInvalid('intervalsPerPeriod', 'The interval must be a number between 1 - 365');
                        
                    }
                    break;
                case -2:
                    if (self.properties.intervalsPerPeriod < 1 || self.properties.intervalsPerPeriod > 52 || self.properties.intervalsPerPeriod != parseInt(self.properties.intervalsPerPeriod, 10)) {
                        self.form.setFieldInvalid('intervalsPerPeriod', 'The interval must be a number between 1 - 52');
                    }
                    break
                case -3:
                    if (self.properties.intervalsPerPeriod < 1 || self.properties.intervalsPerPeriod > 12 || self.properties.intervalsPerPeriod != parseInt(self.properties.intervalsPerPeriod, 10)) {
                        self.form.setFieldInvalid('intervalsPerPeriod', 'The interval must be a number between 1 - 12');
                    }
                    break;
                case -4:
                    if (self.properties.intervalsPerPeriod < 1 || self.properties.intervalsPerPeriod > 4 || self.properties.intervalsPerPeriod != parseInt(self.properties.intervalsPerPeriod, 10)) {
                        self.form.setFieldInvalid('intervalsPerPeriod', 'The interval must be a number between 1 - 4');
                    }
                    break;
                default:
                    // console.error("invalid period id!!");
            }
        
    }

    Financial.prototype.get_Data = function () {
        var self = this;
        var dataURL = URI.FINANCIAL_SCHEDULE.GET;
        self.form.loading = true;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                self.operations = {};
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                }
                if (r.pastPeriodsPaymentAllowedOn == -1) {
                    self.properties.pastPeriodsPaymentAllowedOn = 0;
                    self.properties.allPeriods = true;
                }
                // self.properties.permissions = [1, 2, 3];
                if ((self.properties.permissions || []).length) {
                    for (var j = 0; j < self.properties.permissions.length; j++) {
                        Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                    }
                }
                if (!self.operations.Update) {
                    self.form.editMode = false;
                    // self.periodForm.editMode = false;
                }
                // peiwNumeric: this.periodIntervalLastDay ? 1 : 0,
                self.properties.peiwNumeric = self.properties.periodIntervalLastDay ? 1 : 0,
                //self.set_subForm(parseInt(self.properties.intervalKey));
                self.form.set_Data(self.properties);
                self.form.store_Data();
                
                    
            })
            .catch(function (e) { Message.dberror(e) })
            .finally(function () { self.form.loading = false; })
    }


    Financial.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();
        

            self.form.validate();
            if (self.form.isValid && self.properties.intervalKey)
                self.validateInterval();
            var temp = angular.copy(self.properties);
            //temp.periodEndIsWorkday = temp.peiwNumeric == 1 ? true : false;
            delete temp.anot;
            delete temp.anot1;
            if (temp.allPeriods) temp.pastPeriodsPaymentAllowedOn = -1;
            delete temp.allPeriods;
            delete temp.peiwNumeric;

        //self.periodForm.validate();
        if (self.form.isValid && (!self.periodForm.fieldsList.periodIntervalLastDay || (self.periodForm.fieldsList.periodIntervalLastDay && self.periodForm.fieldsList.periodIntervalLastDay._isValid))) {
            self.form.loading = true;
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: temp
            };
            var dataURL = self.properties.id ? URI.FINANCIAL_SCHEDULE.UPDATE : URI.FINANCIAL_SCHEDULE.CREATE;
            self[dataURL.method](dataURL, params)
                .then(function (r) {
                    p.resolve();
                    if (!self.properties.id) {
                        self.properties.id = r;
                        self.get_Data();
                        return;
                    }
                    if (isAdd) {
                        self.init(isAdd);
                        
                    }
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                    
                })
                .catch(function (e) { self.form.catch(e); p.reject(e); })
                .finally(function () { self.form.loading = false; })
        }
        else {
            p.reject();
        }
        return p.promise;

    }

    return Financial;
});
