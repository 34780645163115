import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LegacyIcon } from '../../../legacy/constants';

@Component({
  selector: 'itv-module-icon-list',
  templateUrl: './module-icon-list.component.html',
  styleUrls: ['./module-icon-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleIconListComponent {
  @Input() icons: LegacyIcon[];
  @Input() selected: number;
  @Output('selectedchange') selectedChange = new EventEmitter<number>();

  get allIcons(): LegacyIcon[] {
    return this.icons.filter(i => !!i.name);
  }

  iconClick(id: number) {
    this.selected = id;
    this.selectedChange.emit(id);
  }
}
