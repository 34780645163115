import { cipo } from 'cipo';

cipo.directive("impersonateCheck", function () {
    return {
        restrict: "AE",
        replace: true,
        template: "<div>\
                <div ng-if='impersonateActive' style='display: block; background: #f8ac59; padding: 10px; color: #fff; font-size: 14px;'>\
                    You are viewing as <strong>{{user.firstName}} {{user.lastName}}</strong> │ \
                    <a style='position: relative; top: 1px; cursor: hand; cursor: pointer' ng-click='stopImpersonation()'>Cancel Impersonation&nbsp;<i class='fa fa-times-circle'></i></a>\
                </div></div>",
        
        controller: function ($rootScope, $scope, userService) {
            $scope.impersonateActive = false;
            $scope.$watch(function () {
                return (userService.impersonate && typeof userService.impersonate.active != "undefined") ?
                    userService.impersonate.active : false;
            }, function (n, o) {
                $scope.impersonateActive = n;
            });
        }
    }

});