import { Component, signal } from '@angular/core';
import { ContextInfo } from '../../../system/context/context.model';
import { DemoContextInfo, DemoContextInfoProgramSelected, DemoContextInfoProjectSelected } from '../../data/demo-context';

@Component({
  selector: 'app-demo-context',
  templateUrl: './demo-context.component.html',
  styleUrls: ['./demo-context.component.scss'],
})
export class DemoContextComponent {
  context = signal<ContextInfo>(DemoContextInfo);
  contextProjectSelected = signal<ContextInfo>(DemoContextInfoProjectSelected);
  contextProgramSelected = signal<ContextInfo>(DemoContextInfoProgramSelected);
}
