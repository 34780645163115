import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { WeatherFieldModel } from 'src/app/models/module/fields/main/weatherField.model';

@Injectable()
export class WeatherTableService {
  route: string = '_api/weatherField';
  constructor(private httpService: HttpService) {}

  getContractWeatherDataByTime(
    weatherTableId: number,
    entityInstanceId: number,
    associatedDate: Date,
    time: Date,
    fieldInstanceId: number,
    contractId: number,
  ) {
    const associatedDateLocaleString =
      associatedDate === undefined || associatedDate === null ? null : new Date(associatedDate).toLocaleDateString();
    const body = {
      weatherTableId,
      entityInstanceId,
      associatedDate: associatedDateLocaleString,
      time,
      fieldInstanceId,
      contractId,
    };
    return this.httpService.post<WeatherFieldModel>(`${this.route}/GetContractWeatherDataByTime`, body);
  }

  getWeatherDataIndependent(associatedDate: Date, time: Date, contractId: number) {
    const associatedDateLocaleString =
      associatedDate === undefined || associatedDate === null ? null : new Date(associatedDate).toLocaleDateString();
    const body = {
      associatedDate: associatedDateLocaleString,
      time,
      contractId,
    };
    return this.httpService.post<WeatherFieldModel>(`${this.route}/GetWeatherDataIndependent`, body);
  }

  getAllWeatherDataIndependent(associatedDate: Date, time: string[], contractId: number) {
    const associatedDateLocaleString =
      associatedDate === undefined || associatedDate === null ? null : new Date(associatedDate).toLocaleDateString();
    const body = {
      associatedDate: associatedDateLocaleString,
      times: time,
      contractId,
    };
    return this.httpService.post<WeatherFieldModel[]>(`${this.route}/GetWeatherDataIndependent`, body);
  }

  getContractWeatherDataByDateAndTime(associatedDate: Date, time: Date, contractId: number) {
    const associatedDateLocaleString =
      associatedDate === undefined || associatedDate === null ? null : new Date(associatedDate).toLocaleDateString();
    const body = {
      associatedDate: associatedDateLocaleString,
      time,
      contractId,
    };
    return this.httpService.post<WeatherFieldModel>(`${this.route}/GetContractWeatherDataByDateAndTime`, body);
  }

  createTimeFieldInstance(weatherTableId: number, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId, entityInstanceId });
    return this.httpService.post<any>(`${this.route}/CreateTimeFieldInstance`, {}, { httpOptions: { params } });
  }

  getTimeFieldInstances(
    weatherTableId: number,
    entityInstanceId: number,
    actionInstanceId: number,
    associatedDate: Date,
  ) {
    const associatedDateLocaleString =
      associatedDate === undefined || associatedDate === null ? null : new Date(associatedDate).toLocaleDateString();
    const body = {
      weatherTableId,
      entityInstanceId,
      actionInstanceId,
      associatedDate: associatedDateLocaleString,
    };
    return this.httpService.post<WeatherFieldModel[]>(`${this.route}/GetTimeFieldInstances`, body);
  }

  getTableTimeFieldId(weatherTableId: number, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId, entityInstanceId });
    return this.httpService.get<number>(`${this.route}/GetTableTimeFieldId`, { httpOptions: { params } });
  }

  getWeatherTableAssociatedDate(weatherTableId: number, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId, entityInstanceId });
    return this.httpService.get<{ date: Date; fieldId: number }>(`${this.route}/GetWeatherTableAssociatedDate`, {
      httpOptions: { params },
    });
  }

  getWeatherTableAssociatedDateFieldId(weatherTableId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId });
    return this.httpService.get<number>(`${this.route}/GetWeatherTableAssociatedDateFieldId`, {
      httpOptions: { params },
    });
  }
}
