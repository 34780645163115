import { cipo } from 'cipo';

cipo.controller('confController',
    function ($scope, $state, compProfile, URI, Upload, $timeout, userService, fileService) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        Object.defineProperty($scope, 'tenantId', {
            get: function () {
                return userService.system.userdata.tenantId;
            }
        });
        Object.defineProperty($scope, 'userService', {
            get: function () {
                //.log('userService', userService)
                return userService;
            }
        });
        $scope.itvStyle = {};
        if ($scope.tenantId == 1) {
            $scope.itvStyle = { "background": "#b3ecf1" }
        }
        $scope.compProfile = new compProfile($scope.tenantId);
        $scope.companyNameEdit = false;
        $scope.toggleEditCompanyName = function () {
            $scope.companyNameEdit = !$scope.companyNameEdit;
            // form.validate();
        };
        $scope.uploadFiles = function (file, errFiles) {
            $scope.f = file;
            $scope.errFile = errFiles && errFiles[0];

            fileService.setMimeTypeFromExt(file);

            if (file) {
                $scope.isUploading = true;
                var urlParamString = '?mimeType=' + file.mimeTypeQueryString;
                file.upload = Upload.upload({
                    url: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_COMPANY_LOGO.toString() + urlParamString,
                    method: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_COMPANY_LOGO.method.toString(),
                    data: { file: file }
                });

                file.upload.then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                        userService.Reload_Tenant_Logos();
                        $scope.isUploading = false;
                    });
                }, function (response) {
                    if (response.status > 0)
                        $scope.errorMsg = response.status + ': ' + response.data;
                    $scope.isUploading = false;
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
            }
        }
        // edit
        $scope.toggleEdit = function (form) {
            form.editMode = !form.editMode;
            $scope.disabledBtn = true;
            form.validate();
        };

        $scope.resetForm = function (form) {
            form.editMode = !form.editMode;
            form.restore_Data();
            $scope.disabledBtn = false;
        }
        $scope.save = function (form) {
            $scope.disabledBtn = false;
            $scope.compProfile.save_Data(form);
        }

        $scope.infoCards = [
            {
                title: 'Contact Information',
                form: $scope.compProfile.formSet1
            },
            {
                title: 'Company Details',
                form: $scope.compProfile.formSet2
            }
        ];


    });
