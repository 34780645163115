<div class="week-names-tiles"
     data-testid="week-names">
    <div *ngIf="showWeekNumber"
         class="week-number-tile">
    </div>
    <div *ngFor="let dayOfWeekName of getDayOfWeekNames()"
         class="week-name-tile"
         data-testid="week-name"
         [class.week-name-workingdays-only]="!showWeekends"
         [class.week-name-workingdays-only-with-week-number]="showWeekNumber">
        {{dayOfWeekName}}
    </div>
</div>
<div class="days-tiles"
     data-testid="day-tiles">
    <ng-container *ngFor="let tile of tiles; index as i">
        <div *ngIf="showWeekNumber && (i % (showWeekends ? 7 : 5) == 0)"
             data-testid="week-number"
             class="week-number-tile">{{tile.week}}</div>
        <div #tileRef
             class="day-tile"
             data-testid="day-tile"
             [class.day-tile-workingdays-only]="!showWeekends"
             [class.day-tile-workingdays-only-with-week-number]="showWeekNumber"
             [class.day-tile-6weeks]="weeks == 6">
            <div>
                <button mat-button
                        class="day-tile-title day-tile-button"
                        data-testid="day-tile-number"
                        *ngIf="!tile.isToday">
                    {{tile.text}}
                </button>
                <button mat-raised-button
                        class="day-tile-title day-tile-button"
                        data-testid="day-tile-number-today"
                        color="primary"
                        *ngIf="tile.isToday">
                    {{tile.text}}
                </button>
            </div>
            <ng-container *ngIf="isLoading">
                <div class="day-tile-loading"
                     data-testid="day-tile-loading">
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '12px', height: '12px', margin: 0}"></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '30px', height: '12px', margin: 0}"></ngx-skeleton-loader>
                    </div>
                    <div class="desc">
                        <ngx-skeleton-loader [theme]="{height: '12px', margin: 0}"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <app-calendar-doc-mini *ngFor="let doc of showTileDocs(tile.docs)"
                                       [doc]="doc"
                                       [screenFields]="screenFields"
                                       [actions]="actions">
                </app-calendar-doc-mini>
                <div class="day-tile-more"
                     data-testid="day-tile-doc-more"
                     *ngIf="getMoreTileDocsNumber(tile.docs) as n">
                    <button mat-button
                            class="more day-tile-button"
                            data-testid="day-tile-doc-more-button"
                            #btMore
                            (click)="more(tile, btMore)">
                        {{ 'MODULE_INSTANCE_CALENDAR.N_MORE' | translate: {n:n} }}
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>