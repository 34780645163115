import { Component, NgModule } from '@angular/core';
import { LegacyRoutes } from './constants/routes.constant';

@Component({
  selector: 'app-legacy',
  template: '',
})
export class LegacyComponent {
  constructor(private legacyRoutes: LegacyRoutes) {}
}

@NgModule({
  imports: [],
  declarations: [LegacyComponent],
  exports: [LegacyComponent],
  providers: [LegacyRoutes],
})
export class LegacyModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
