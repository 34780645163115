import { Injectable, inject } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FieldsService } from '../../../shared/components/fields/common';
import { MOCK_FORM_FIELDS } from '../../data/demo-form-builder.const';
import { TableFieldService } from '../../../module/definition/table-field/common/table-field.service';
import { DemoTableFields } from '../../data/table-field.const';

@Injectable({
  providedIn: 'root',
})
export class DemoFormBuilderService {
  private fieldsService = inject(FieldsService);
  getFormData() {
    return of(MOCK_FORM_FIELDS).pipe(
      map(fields => this.fieldsService.mapFormFieldModels(fields)),
      map(mappedFields => this.fieldsService.mapFieldsToFormItems(mappedFields)),
    );
  }

  tableFieldService = inject(TableFieldService);
  getTableFields() {
    return of(DemoTableFields).pipe(map(fields => this.tableFieldService.mapTableFields(fields)));
  }
}
