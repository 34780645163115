import { cipo } from 'cipo';

var fn = null;

cipo.controller('officeController',
function ($scope, $sce, $location, $timeout, Message, officeService) {
    $scope.wopisrc = '';
    $scope.ttl = 0;
    $scope.accesstoken = '';

    document.querySelector('div.index_view_container').style.display = 'none';
    document.querySelector('div.loaderOverlayWrapperGlobal').style.display = 'block';

    var search = $location.search();
    var searchData = search && search.f ? officeService.officeDecodeData(search.f) : null;

    if (fn) $timeout.cancel(fn);
    fn = $timeout(function() {
        officeService.discovery(searchData)
            .then(function(discoData) {
                $scope.wopisrc = $sce.trustAsResourceUrl(discoData.wopiSrc);
                $scope.ttl = discoData.ttl;
                $scope.accesstoken = localStorage.getItem('msal.idtoken');

                document.querySelector('div.loaderOverlayWrapperGlobal').style.display = 'none';

                $timeout(function() { 
                    officeService.load(discoData, searchData); 
                }, 100);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            });
    }, 1000);
});
