import { DestroyRef, Directive, ElementRef } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, fromEvent } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Directive({
  selector: '[appendColor]',
})
export class AppendColorDirective {
  theme$ = toObservable(this.userService.theme$).pipe(takeUntilDestroyed());
  themeMode$ = toObservable(this.userService.themeMode$).pipe(takeUntilDestroyed());
  constructor(
    private el: ElementRef,
    private userService: UserService,
    private destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit() {
    const element = this.el.nativeElement as HTMLInputElement;
    let themeElementRef = document.querySelector<HTMLElement>(
      `.${this.userService.theme$()}-theme.${this.userService.themeMode$()}-mode`,
    );

    combineLatest([this.theme$, this.themeMode$])
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1))
      .subscribe(([theme, themeMode]) => {
        themeElementRef = document.querySelector(`.${theme}-theme.${themeMode}-mode`);
        const computedStyles = getComputedStyle(themeElementRef);
        element.value = computedStyles.getPropertyValue(`--${element.id}`);
      });

    fromEvent(element, 'input')
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        const color = (event.target as HTMLInputElement).value;
        navigator.clipboard.writeText(color);
        themeElementRef.style.setProperty(`--${element.id}`, color);
      });
  }
}
