@if (fieldData().readonly) {
    <cipo-text-view [config]="formControl().fieldData" [type]="fieldData().type" [value]="formControl().value" />
} @else {
    <mat-form-field class="full-width" appearance="outline">
        @if (fieldData().label) {
            <mat-label>{{ fieldData().label | translate }}</mat-label>
        }
        <input
            matInput
            [owlDateTimeTrigger]="datePicker"
            [owlDateTime]="datePicker"
            [selectMode]="fieldData().selectMode ?? 'single'"
            [min]="fieldData().minDate"
            [max]="maxDate()"
            readonly
            [formControl]="formControl()" />
        <mat-hint>{{ fieldData().hint | translate }}</mat-hint>
        <mat-error>
            {{ formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate) }}
        </mat-error>
        <div fxLayout matSuffix>
            @if (formControl().value) {
                <button mat-icon-button (click)="formControl().setValue(null)">
                    <mat-icon>clear</mat-icon>
                </button>
            }
            <button mat-icon-button [owlDateTimeTrigger]="datePicker">
                <mat-icon>{{ fieldData().pickerType === "time" ? "schedule" : "calendar_month" }}</mat-icon>
            </button>
            @if (fieldData().fieldDescription) {
                <button mat-icon-button [title]="fieldData().fieldDescription | translate">
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                </button>
            }
        </div>
        <owl-date-time
            #datePicker
            [pickerType]="fieldData().pickerType ? dateTimeTypeConverter[fieldData().pickerType] : 'calendar'"
            [hour12Timer]="fieldData().hour12Timer ?? true"
            (afterPickerClosed)="resetMaxDate()"
            (afterPickerClosed)="formatValue($event)"
            (dateSelected)="changed($event)"></owl-date-time>
    </mat-form-field>
}
