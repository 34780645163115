import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { DemoMenuItem, DemoMenuItems } from '../../demo.menu';
import { DemoContextInfo } from '../../data/demo-context';
import { ContextService } from '../../../system/context/context.service';
import { DemoContextService } from '../context/demo-context.service';

@Component({
  selector: 'app-demo-layout',
  templateUrl: './demo-layout.component.html',
  styleUrls: ['./demo-layout.component.scss'],
  providers: [{ provide: ContextService, useClass: DemoContextService }],
})
export class DemoLayoutComponent implements OnInit {
  private baseUrl = '/demo';

  menuItems: DemoMenuItem[];
  selectedPortal: ComponentPortal<any>;
  selectedMenu: DemoMenuItem;
  context = DemoContextInfo;
  theme$ = this.userService.theme$;
  themeMode$ = this.userService.themeMode$;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.menuItems = DemoMenuItems;
    this.setParent(this.menuItems);

    const menu = this.findMenuFromLocation();
    if (menu) {
      menu.active = true;
      this.selectMenu(menu);
    }
  }

  goToHome() {
    window.location.href = this.baseUrl;
  }

  menuClick(menu: DemoMenuItem) {
    this.selectMenu(menu);
    this.setMenuOnLocation(menu);
  }

  selectMenu(menu: DemoMenuItem) {
    this.selectedMenu = menu;
    if (menu.component) {
      this.selectedPortal = new ComponentPortal(menu.component);
    }
  }

  findMenuFromLocation(): DemoMenuItem {
    const url = new URLSearchParams(window.location.search);
    const urlParamMain = url.get('m');
    if (!urlParamMain) {
      return undefined;
    }

    const main = this.findMenuItem(this.menuItems, urlParamMain);

    const urlParamSub = url.get('s');
    if (urlParamSub && main.children && main.children.length) {
      const sub = this.findMenuItem(main.children, urlParamSub);

      const urlParamThird = url.get('t');
      if (urlParamThird && sub.children && sub.children.length) {
        const third = this.findMenuItem(sub.children, urlParamThird);
        return third;
      }

      return sub;
    }

    return main;
  }

  setMenuOnLocation(menu: DemoMenuItem) {
    let m = undefined,
      s = undefined,
      t = undefined;
    if (menu.level === 1) {
      m = menu;
    } else if (menu.level === 2) {
      s = menu;
      m = menu.parent;
    } else if (menu.level === 3) {
      t = menu;
      s = menu.parent;
      m = menu.parent.parent;
    }

    const url = new URL(window.origin + this.baseUrl);
    url.searchParams.set('m', this.formatMenuName(m.name));
    if (s) {
      url.searchParams.set('s', this.formatMenuName(s.name));
    }
    if (t) {
      url.searchParams.set('t', this.formatMenuName(t.name));
    }

    history.pushState({}, '', url);
  }

  findMenuItem(menuItems: DemoMenuItem[], name: string) {
    return menuItems.find(m => this.formatMenuName(m.name) == this.formatMenuName(name));
  }

  setParent(menuItems: DemoMenuItem[], parent?: DemoMenuItem, level?: number): void {
    if (!menuItems || menuItems.length === 0) {
      return;
    }
    for (const menuItem of menuItems) {
      menuItem.parent = parent;
      menuItem.level = (level || 0) + 1;

      this.setParent(menuItem.children, menuItem, (level || 0) + 1);
    }
  }

  findById(menuItems: DemoMenuItem[], id: number): DemoMenuItem {
    if (!menuItems || menuItems.length === 0) {
      return undefined;
    }
    for (const menuItem of menuItems) {
      if (menuItem.id === id) {
        return menuItem;
      }

      const sub = this.findById(menuItem.children, id);
      if (sub) {
        return sub;
      }
    }
    return undefined;
  }

  formatMenuName(name: string) {
    return name.toLowerCase().replaceAll(' ', '-');
  }
}
