import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContextInfo, ContextTree } from '../context/context.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() menuOpened: boolean;
  @Input() context: ContextInfo;
  @Input() userId: number;
  @Input() useOnlyContract: boolean;
  @Input() refresh: boolean;

  @Output() onMenuClick = new EventEmitter();
  @Output() onLogoClick = new EventEmitter();
  @Output() contextChange = new EventEmitter();
  @Output() contextData = new EventEmitter<ContextTree>();

  public menuClick() {
    this.onMenuClick.emit();
  }

  public logoClick() {
    this.onLogoClick.emit();
  }
}
