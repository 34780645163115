import { cipo } from 'cipo';

cipo.factory("WorkflowAddMod", function ($q, Model, Form, URI, ModuleDictionaries, Message) {
    var WorkflowAddMod = Model.extend(function (moduleId, availableWorkflowGroups) {
        var self = this;
        self.moduleId = moduleId;
        self.properties = { 
            id: null,
            workflowGroupId: null,
            name: ""
        };
        self.availableWorkflowGroups = availableWorkflowGroups;
        self.createForm();
    });

    WorkflowAddMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        // self.form.initializing = true;
        self.form.loading = true;

        var form = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 150 } },
            workflowGroupId: { label: 'Workflow Group', type: 'select', validation: { required: true }, options: self.availableWorkflowGroups }
        }

        self.form.set_Description(form);

        var grid = [
            { name: 50, workflowGroupId: 50 }
        ]

        self.form.setTemplate('grid', grid);

        self.form.set_Data(self.properties);
        self.form.store_Data();
    }

    WorkflowAddMod.prototype.add = function () {
        var self = this;
        self.form.loading = true;
        var p = $q.defer();
        var dataURL = URI.WORKFLOW.ADD;
        self[dataURL.method](dataURL, { body: self.properties }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.form.store_Data();
                p.resolve();
                Message.info("Workflow added successfully");
            })
            .catch(function (e) { console.error(e); p.reject(); Message.dberror(e); })
            .finally(function () { self.form.loading = false; });
        return p.promise;
    }

    return WorkflowAddMod;
});