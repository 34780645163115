import { cipo } from 'cipo';

cipo.factory('rememberManagerService', ['userService', 
    function (userService) {
        var rememberManagerService = {};

        rememberManagerService.entityInstanceId = null;
        rememberManagerService.entityTemplateId = null;
        rememberManagerService.tenantId = 0;
        rememberManagerService.contractId = 0;
        rememberManagerService.moduleId = 0;
        rememberManagerService.timestamp = null;
        rememberManagerService.manager = {};

        rememberManagerService.exists = function () {
            return rememberManagerService.entityInstanceId ||
                rememberManagerService.entityTemplateId;
        };

        rememberManagerService.reset = function () {
            rememberManagerService.entityInstanceId = null;
            rememberManagerService.entityTemplateId = null;
        };

        rememberManagerService.saveInstanceId = function (entityInstanceId) {
            rememberManagerService.entityInstanceId = entityInstanceId * 1;
            rememberManagerService.timestamp = new Date().getTime();
        };

        rememberManagerService.saveTemplateId = function (entityTemplateId) {
            rememberManagerService.entityInstanceId = null;
            rememberManagerService.entityTemplateId = entityTemplateId * 1;
        };

        rememberManagerService.canRestore = function (moduleId) {
            return rememberManagerService.tenantId == userService.tenantId &&
                rememberManagerService.contractId == userService.system.userdata.contractId &&
                rememberManagerService.moduleId == moduleId * 1;
        };
        
        rememberManagerService.get = function () {
            return rememberManagerService.manager;
        };

        rememberManagerService.set = function (manager, moduleId) {
            rememberManagerService.tenantId = userService.tenantId;
            rememberManagerService.contractId = userService.system.userdata.contractId;
            rememberManagerService.moduleId = moduleId * 1;
            rememberManagerService.manager = manager;
            rememberManagerService.timestamp = null;
        };

        rememberManagerService.restore = function (manager, moduleId, restoredCallback, notRestoredCallback) {
            if (rememberManagerService.canRestore(moduleId)) {
                manager = rememberManagerService.get();
                if (rememberManagerService.exists()) {
                    manager.parseEntityInstance(rememberManagerService.entityInstanceId, rememberManagerService.entityTemplateId);
                    manager.getFormulasValues();
                }
                restoredCallback && restoredCallback();
            } else {
                notRestoredCallback && notRestoredCallback();
                rememberManagerService.set(manager, moduleId);
            }
            return manager;
        }
        
        return rememberManagerService;
    }]);
