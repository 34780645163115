import { cipo } from 'cipo';

cipo.controller('SaveOrganizationController',
    function ($scope, $location, $state, Organization, id, Message, operations, module, Manager, $mdDialog, MODAL, URI, ACTIONS, userService, $q, $timeout) {
        $scope.id = (typeof id != 'undefined' && id > 0) ? parseInt(id) : 0;

        $scope.organization = new Organization($scope.id);
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.organization.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        
        $scope.modalInfo = MODAL;

        
        $scope.isModified = false;
        $scope.$on('tab-change', function (event, tabEvent) {
            //console.error(tabEvent);
            if (!$scope.organization.form.dirty) {
                tabEvent.accept();
            } else {
                // capture the index of the tab cliked
                $scope.navToIndex = tabEvent.index;
                // set the warning params
                $scope.organization.hightlight = true;
                $scope.organization.tabWarning = true;

                
                // prevent tab change
                tabEvent.reject();
            }
        });
        $scope.revertTabInfo = function () {
            if (!$scope.closingModal) {
                $scope.organization.form.restore_Data();
                $scope.organization.tabWarning = false;
                $scope.organization.hightlight = false;
                
                $scope.selectedIndex = $scope.navToIndex;
            }
            else {
                $scope.close(true);
            }
        }
        $scope.updateTabInfo = function () {
            $scope.organization.hightlight = false;
            
            $scope.organization.save()
                .then(function (r) {
                    $scope.organization.tabWarning = false;
                    if (!$scope.closingModal) {
                        $scope.selectedIndex = $scope.navToIndex;
                    }
                    else {
                        $scope.close();
                    }
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    
                })
        }

        $scope.cancelAction = function () {
            $scope.organization.tabWarning = false;
            $scope.organization.hightlight = false;
            
            if ($scope.closingModal) {
                $scope.closingModal = false;
            }
        }

        $scope.save = function (isAdd) {
            $scope.organization.save(isAdd)
                .then(function (r) {
                    if (isAdd) $location.search({ sid: -1 });
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {

                })
            
        }

        if ($scope.id > 0) {
            var perm = userService.getOperationsFor("U");
            $scope.manager = new Manager({
                objectsPerPage: 20,
                dataURL: URI.ORGANIZATION.USERS,
                dataWrapper: "data",
                options: {
                    multiselect: false
                },
                noFilters: true,
                urlParams: {
                    orgId: $scope.id
                },
                rowOnClick: function (row) {
                    if (perm.Read)
                        $state.go('tenant.ausers', { sid: row.inviteId });
                },
                parseData: function (data, columns) {
                    var data = data || [], retval = [];
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].logo) data[i]._email = '<img class="tableSmallTmb" src="data:'
                            + data[i].mimeType + ';base64,' + data[i].logo + '" />' + data[i].email;
                        else data[i]._email = '<i class="tableIcon noProfilePic"></i>' + data[i].email;
                        retval.push(data[i]);
                    }
                    return retval;
                },
                leftActions: [],
                actions: [
                    {
                        setProperties: ACTIONS.DETAILS,
                        displayOnRow: perm.Read ? true : false,
                        click: function (row) {
                            if (perm.Read)
                                $state.go('tenant.ausers', { sid: row.inviteId });
                        }
                    }
                ]
            });
            var statusLookup = {
                2: { name: 'Registered', class: 'basic' },
                1: { name: 'Pending', class: 'warning' },
                0: { name: 'Deactivated', class: 'deactivated' }
            }
            $scope.manager.set_Columns([
                { name: '_email', label: 'Email', type: 'checkbox',onPhone: true, onTablet: true, replacementFor: 'email'},
                { name: 'firstName', label: 'First Name', onPhone: true, onTablet: true, type: 'text' },
                { name: 'lastName', label: 'Last Name', onPhone: true, onTablet: true, type: 'text' },
                { name: 'status', label: 'Status', onPhone: true, onTablet: true, type: 'status', lookupArr: statusLookup }
            ]);

            $scope.manager.loadPage();
        }
        
        $scope.close = function (isWarning) {
            if (!$scope.organization.form.dirty || isWarning) {
                if (!$scope.organization.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            }
            else {
                $scope.organization.hightlight = true;
                $scope.organization.tabWarning = true;
                $scope.closingModal = true;
            }
        }

        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.save,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction,
        }
    });
