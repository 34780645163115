import { Pipe, PipeTransform } from '@angular/core';
import { AttachmentModel, MimeTypesEnum } from '../../../models/module/fields/main';

@Pipe({
  name: 'imageSource',
  standalone: true,
})
export class ImageSourcePipe implements PipeTransform {
  transform(attachment: AttachmentModel): string {
    switch (attachment.mimeType) {
      case MimeTypesEnum.PNG:
      case MimeTypesEnum.JPEG:
        if (attachment.thumbnail50) {
          return `data:${attachment.mimeType};base64,${attachment.thumbnail50}`;
        }
        return 'src/assets/icons/file-extensions/unknown.svg';
      case MimeTypesEnum.PDF:
        return 'src/assets/icons/file-extensions/pdf.svg';
      case MimeTypesEnum.DOCX:
        return 'src/assets/icons/file-extensions/docx.svg';
      case MimeTypesEnum.XLSX:
        return 'src/assets/icons/file-extensions/xlsx.svg';
      case MimeTypesEnum.PPTX:
        return 'src/assets/icons/file-extensions/pptx.svg';
      case MimeTypesEnum.ZIP:
        return 'src/assets/icons/file-extensions/zip.svg';
      case MimeTypesEnum.EMAIL:
        return 'src/assets/icons/file-extensions/email.svg';
      default:
        return 'src/assets/icons/file-extensions/unknown.svg';
    }
  }
}

export const ThumbnailsDict = {
  [MimeTypesEnum.PDF]: 'src/assets/icons/file-extensions/pdf.svg',
  [MimeTypesEnum.DOCX]: 'src/assets/icons/file-extensions/docx.svg',
  [MimeTypesEnum.XLSX]: 'src/assets/icons/file-extensions/xlsx.svg',
  [MimeTypesEnum.PPTX]: 'src/assets/icons/file-extensions/pptx.svg',
  [MimeTypesEnum.ZIP]: 'src/assets/icons/file-extensions/zip.svg',
  [MimeTypesEnum.EMAIL]: 'src/assets/icons/file-extensions/email.svg',
  default: 'src/assets/icons/file-extensions/unknown.svg',
} as const;
