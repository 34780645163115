export enum FieldTypesEnum {
  // Base
  Text = 1,
  Multiline = 2,
  Editor = 3,
  Select = 4,
  Radio = 5,
  Checkbox = 6,
  Number = 7,
  Percent = 8,
  Currency = 9,
  DateTime = 10,
  Date = 11,
  Time = 12,
  Line = 13,

  // Advanced
  Attachment = 14,
  // User = 15,
  Annotation = 16,
  Relation = 17,
  Table = 18,
  Weather = 19,
}
