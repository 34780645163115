import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { PipesModule } from '../../../modules/pipes.module';
import { DisplayFormats, TableColumn, TableRow } from '../common';

@Component({
  selector: 'cipo-table-cell',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgxSkeletonLoaderModule, PipesModule, MatIconModule],
  templateUrl: './table-cell.component.html',
})
export class TableCellComponent<T = TableRow> {
  column = input.required<TableColumn>();
  row = input.required<T>();
  noDataPlaceholder = input<string, string>('-', {
    transform: value => value || '-',
  });

  displayFormat = DisplayFormats;
}
