<div class="iconsWrapper" style="max-height: 150px">
    <label class="uneditableFormLabel">{{ "MODULE_ICON_LIST.SELECT_ICON" | translate }}</label>
    <div>
        <div
            *ngFor="let icon of allIcons"
            class="gridLike"
            [class.selected]="(selected || 0) == icon.key"
            [attr.aria-label]="icon.name"
            (click)="iconClick(icon.key)">
            <itv-module-icon [iconid]="icon.key" [color]="selected == icon.key ? 'white' : 'black'"></itv-module-icon>
        </div>
    </div>
</div>
