import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';

cipo.factory('InviteProcess', function (Model, $q) {
    var InviteProcess = Model.extend(function (invitationCode) {
        this.invitationCode = invitationCode || '';
        this.invitationData = null;
        this.errorMessage = null;
    });

    InviteProcess.prototype.checkCode = function () {
        var self = this;
        var p = $q.defer();

        this.get(environment.baseApiUrl + '_api/invite/validate',
        {
            url: { code: self.invitationCode },
            urltype: 'obj'
        }).then(function (r) {
            self.invitationData = r;
            p.resolve(r);
        }).catch(function (e) {
            if (e.data.message) self.errorMessage = e.data.message;
            p.reject(e);
        });

        return p.promise;
    }

    InviteProcess.prototype.associate = function () {
        var self = this;
        var p = $q.defer();

        this.post(environment.baseApiUrl + '_api/invite/associate', self.invitationData)
            .then(function (r) {
                //console.error('associate successful', r);
                p.resolve(r);
            }).catch(function (e) {
                console.error('associate rejected', e);
                p.reject(e);
            });

        return p.promise;
    }

    return InviteProcess;
});
