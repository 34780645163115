import { KeyValueFormattingModel, KeyValueRestrictionModel } from "../enums";

export enum DataListType {
  Module = 1,
  Relation = 2,
  Card = 3,
  Calendar = 4,
}

export interface DataListFieldPropertiesModel {
  dataListFieldId?: number;
  dataListId?: number;
  id?: number;
  label: string;
  name: string;
  position?: number;
  priority?: number;
  isPrimarySort: boolean;
  isDescending: boolean;
  showThumbnail: boolean;
  width?: number;
  printable: boolean;
  hasMultipleValues: boolean;
  typeId?: number;
  displayTypeId?: number;
  formattings: KeyValueFormattingModel[];
  restrictions: KeyValueRestrictionModel[];
}