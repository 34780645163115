import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { KeyValueRestrictionModel, RestrictionType, RestrictionValueType } from '../../models/module/fields/enums';
import { UserService } from '../services/user.service';

@Pipe({ name: 'itvDateTimeWithRestrictions' })
export class ItvDateTimeWithRestrictionsPipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) { }

  transform(value: string | Date, restrictions: KeyValueRestrictionModel[] = [], defaultValue: string = ''): string {
    const datetimeFormatting = (restrictions || []).find(f => f.key == RestrictionType.DateTime);
    if (!datetimeFormatting) {
      return defaultValue;
    }

    // when formatting with restriction, only datetime restriction handles timezone
    let handleTimezone = false;

    let format = undefined;
    switch (datetimeFormatting.value) {
      case RestrictionValueType.Date:
        format = this.userService.formats.date;
        break;
      case RestrictionValueType.DateTime:
        format = this.userService.formats.datetime;
        handleTimezone = true;
        break;
      case RestrictionValueType.Time:
        format = this.userService.formats.time;
        break;
      default:
        handleTimezone = true;
        return defaultValue;
    }

    return this.dateCustomPipe.transform(value, format, handleTimezone, defaultValue);
  }
}

@Pipe({ name: 'itvDateTimeCustomFormat' })
export class ItvDateTimeCustomFormatPipe implements PipeTransform {
  transform(value: string | Date, format: string, handleTimezone: boolean = true, defaultValue: string = ''): string {
    if (!value) {
      return defaultValue;
    }

    if (typeof (value) == 'string') {
      const hasTimezone = value.endsWith('Z');
      if (handleTimezone && !hasTimezone) {
        value = value + 'Z';
      }
      else if (!handleTimezone && hasTimezone) {
        value = value.slice(0, -1);
      }
    }
    
    return moment(value).format(format);
  }
}

@Pipe({ name: 'itvDate' })
export class ItvDatePipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) { }

  transform(value: string | Date, handleTimezone: boolean = false, defaultValue: string = ''): string {
    return this.dateCustomPipe.transform(value, this.userService.formats.date, handleTimezone, defaultValue);
  }
}

@Pipe({ name: 'itvTime' })
export class ItvTimePipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) { }

  transform(value: string | Date, handleTimezone: boolean = false, defaultValue: string = ''): string {
    return this.dateCustomPipe.transform(value, this.userService.formats.time, handleTimezone, defaultValue);
  }
}

@Pipe({ name: 'itvDateTime' })
export class ItvDateTimePipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) { }

  transform(value: string | Date, handleTimezone: boolean = true, defaultValue: string = ''): string {
    return this.dateCustomPipe.transform(value, this.userService.formats.datetime, handleTimezone, defaultValue);
  }
}