import { on } from '@ngrx/store';
import { produce } from 'immer';
import { CoreState, StoreReducer } from '../interfaces';
import { tenantActions } from './tenant.actions';

export const tenantReducers: StoreReducer<CoreState>[] = [
  on(tenantActions.set, (state, { tenant }) =>
    produce(state, draft => {
      draft.tenant = {...tenant};
    }),
  ),
  on(tenantActions.updateValue, (state, { id, value }) =>
    produce(state, draft => {
      const setting = draft.tenant.settings.find(s => s.id === id);
      setting.value = value;
    }),
  ),
];
