import { cipo } from 'cipo';

cipo.factory('moduleService', ['$http', '$q', 'URI', 'Model', function ($http, $q, URI, Model) {

    var moduleService = {};

    moduleService.myDriveName = "";
    moduleService.projectFilesName = "";

    moduleService.getFileModuleNames = function () {

        var p = $q.defer();
        $http[URI.MY_CIPO_DRIVE.FILE_MODULE_NAME.method](URI.MY_CIPO_DRIVE.FILE_MODULE_NAME.toString())
            .then(function (r) {
                if (r && r.data) {
                    moduleService.projectFilesName = r.data.projectFilesName;
                    moduleService.myDriveName = r.data.myDriveName;
                }
                p.resolve(true);
            })
            .catch(function (e) { console.error(e); p.reject(); });

        return p.promise;
    }

    moduleService.getStartScreens = function (moduleId, workflowId, contractId) {

        var p = $q.defer();
        Model[URI.MODULE_GRID.START_SCREENS.method](URI.MODULE_GRID.START_SCREENS, { url: { workflowId: workflowId, contractId: contractId }, urltype: 'obj'}, { headers: { moduleId: moduleId } })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) { console.error(e); p.reject(); });

        return p.promise;
    }

    return moduleService;
}]);