import { cipo } from 'cipo';

cipo.factory("PermissionMod", function ($q, Model, URI, Message, Form) {
    var PermissionMod = Model.extend(function (moduleId, operationsDict, rolesDict, obj) {

        var self = this;
        //displays the form
        self.editable = false;
        self.deleteConfirm = false;
        self.moduleId = moduleId;

        //form models
        self.formModels = {
            rolesIds: [],
            operationsIds: [],
            
            name: ""
        }

        //
        self.properties = {
            id: null,
            name: "",
            operations: [],
            operationNames: "",
            roles: []
        }

        // form sources
        self.operationsDict = operationsDict || [];
        self.rolesDict = rolesDict || [];

        if (obj) {
            self.name = obj.name;
            self.formModels.name = obj.name;
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            if (obj.operations && obj.operations.length) {
                for (var i = 0; i < obj.operations.length; i++) {
                    self.formModels.operationsIds.push(obj.operations[i].key);
                    self.properties.operationNames += obj.operations[i].value;
                    if (i < obj.operations.length - 1) self.properties.operationNames += ', ';
                }
            }
            if (obj.roles && obj.roles.length) {
                for (var i = 0; i < self.properties.roles.length; i++) {
                    self.formModels.rolesIds.push(obj.roles[i].key);
                }
            }
        }

        if (!self.properties.id) self.createForm();

    });

    PermissionMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.formModels);
        self.form.initializing = true;

        self.form.set_Description({
            name: { label: 'Permission Name', type: 'text', validation: { required: true, maxChars: 100 } },
            rolesIds: { label: 'Select Roles', type: 'select', multiple: true, options: angular.copy(self.rolesDict), validation: { required: true } },
            operationsIds: { label: 'Select Operations', type: 'select', multiple: true, options: angular.copy(self.operationsDict), validation: { required: true } }
        });

        self.form.setTemplate('grid', [
            { name: 100 },
            { rolesIds: 50, operationsIds: 50 }
        ]);
        self.form.initializing = false;
        self.form.store_Data();
    }


    PermissionMod.prototype.cancelEdit = function () {
        // restore the initial values
        var self = this;
        self.form.restore_Data();
        self.editable = false;
    }

    PermissionMod.prototype.lookupIds = function (idsArray, dict) {
        var objArray = [];
        //console.error('lookupIds', idsArray, dict )
        for (var i = 0; i < dict.length; i++) {
            if (idsArray.indexOf(dict[i].key) != -1) {
                objArray.push(dict[i]);
            }
        }
        return objArray;
        //console.error('result', objArray)
    }

    PermissionMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            self.properties.name = self.formModels.name;
            self.properties.roles = self.lookupIds(self.formModels.rolesIds, self.rolesDict);
            self.properties.operations = self.lookupIds(self.formModels.operationsIds, self.operationsDict);
            //console.error('self.properties', self.properties)
            // var system = self.moduleType == 0 ? true : false;
            var urlData = self.properties.id ? URI.PERMISSIONS.UPDATE : URI.PERMISSIONS.CREATE;

            self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.moduleId } })
                .then(function () {
                    Message.info('Permission saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.loading = false;
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    
                });

        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    PermissionMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting permission...";
        self.deleting = true;
        self[URI.PERMISSIONS.DELETE.method](URI.PERMISSIONS.DELETE, { list: [self.properties.id] }, { headers: { moduleId: self.moduleId } })
            .then(function () {
                Message.info('Permission deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete permission. Try again?";
                p.reject(e);
            })

        return p.promise;
    }

    return PermissionMod;
});
