import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { AccessLogTableComponent } from './access-log-table/access-log-table.component';
import { ActivityItemComponent } from './activity/activity-item/activity-item.component';
import { ActivityListComponent } from './activity/activity-list/activity-list.component';
import { CalendarViewComponent } from './calendar/calendar-view/calendar-view.component';
import { DataCardsViewComponent } from './data-card/data-cards-view/data-cards-view.component';
import { OpenDocumentsTableComponent } from './open-documents-table/open-documents-table.component';
import { PdfMarkupTableComponent } from './pdf-markup-table/pdf-markup-table.component';
import { ScheduledEmailsComponent } from './scheduled-emails/scheduled-emails.component';
import { TenantSettingsComponent } from 'src/app/module/instance/tenant-settings/tenant-settings.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { ModuleParameterComponent } from './module-parameter/module-parameter.component';
import { AssignmentsComponent } from './assignments/assignments.component';

export const Ng1ModuleInstance = angular
  .module('ng1module.module-instance', [])
  .directive('appDataCardsView', downgradeComponent({ component: DataCardsViewComponent }) as angular.IDirectiveFactory)
  .directive('appCalendarView', downgradeComponent({ component: CalendarViewComponent }) as angular.IDirectiveFactory)
  .directive('appPdfMarkupTable', downgradeComponent({ component: PdfMarkupTableComponent }) as angular.IDirectiveFactory)
  .directive('appAccessLogTable', downgradeComponent({ component: AccessLogTableComponent }) as angular.IDirectiveFactory)
  .directive('cipoAssignments', downgradeComponent({ component: AssignmentsComponent }) as angular.IDirectiveFactory)
  .directive('appModuleParameter', downgradeComponent({ component: ModuleParameterComponent }) as angular.IDirectiveFactory)
  .directive('appTenantSettings', downgradeComponent({ component: TenantSettingsComponent }) as angular.IDirectiveFactory)
  .directive('appOpenDocumentsTable', downgradeComponent({ component: OpenDocumentsTableComponent }) as angular.IDirectiveFactory)
  .directive('appEntityInstanceActivityList', downgradeComponent({ component: ActivityListComponent }) as angular.IDirectiveFactory)
  .directive('appEntityInstanceActivityItem', downgradeComponent({ component: ActivityItemComponent }) as angular.IDirectiveFactory)
  .directive('appScheduledEmails', downgradeComponent({ component: ScheduledEmailsComponent }) as angular.IDirectiveFactory)
  .directive('appHolidays', downgradeComponent({ component: HolidaysComponent }) as angular.IDirectiveFactory)
  .name;
