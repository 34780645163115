import { cipo } from 'cipo';

cipo.controller('restoreItemsController',
    function ($scope, MODAL, $mdDialog, Message, entityInstanceId, items, options, userService, Model) {
        $scope.modalInfo = MODAL;
        $scope.items = items;
        $scope.items.restoreToNew = 0;
        $scope.entityInstanceId = entityInstanceId;

        $scope.restoreOptions = [
            { name: "Append as version(s) to existing file", value: 0 },
            { name: "Restore as a new file", value: 1 },
        ]

        $scope.move = function () {
            var toRestore = angular.copy($scope.items);
            toRestore.restoreToNew = $scope.items.restoreToNew ? true:false;
            $scope.disabledBtn = true;
            Model[options.url.method](options.url, { url: { entityInstanceId: $scope.entityInstanceId }, urltype: 'obj', body: toRestore })
                .then(function (r) {
                    $mdDialog.hide();
                    Message.info("Items restored successfully");
                })
                .catch(function (e) { console.error(e); Message.dberror(e); })
                .finally(function () { $scope.disabledBtn = false; });
        }
        $scope.closeModal = function () {
            /*if (isModified) $mdDialog.hide();
            else $mdDialog.cancel(); */
            $mdDialog.cancel();
        };
    });
