import { cipo } from 'cipo';

cipo.factory("Model", function ($q, $http, $log) {
    // this is the constructor
    var Model = function () { };

    // methods
    Model.prototype.request = function (method, u, p, c) {
        var c = c || {};
        var p = p || {};
        var u = (Object.prototype.toString.call(u) == '[object Object]') ? u.toString() : u;

        // parameter can be int/string, array or object.
        // assigning p to params.url (url parameters) and params.body (request body parameters)
        var config = {};
        var params = { body: {}, url: [], };
        params.urltype = p.urltype || 'array';
        if (p.url || p.body) {
            params.url = p.url || [];
            params.body = p.body || {};
        } else {
            if (Object.prototype.toString.call(p) == '[object Object]') params.body = p;
            else if (Object.prototype.toString.call(p) == '[object Array]') params.url = p;
            else params.url.push(p);
        }

        var data, url = u;

        // if we have url parameters, adding them to url
        var first = true;
        angular.forEach(params.url, function (el, key) {
            var ele = el != null && typeof el != 'undefined';
            var els = ele ? decodeURI(encodeURIComponent(el.toString())) : '';
            if (params.urltype == 'obj') {
                if (first && ele) {
                    url += '?' + key + '=' + els;
                    first = false;
                } else {
                    if (ele) url += '&' + key + '=' + els;
                }
            }
            else url += '/' + els;
        });

        // setting parameters forms for different request types
        if (method == 'get') {
            data = { params: params.body };
            for (var key in c) {
                if (c.hasOwnProperty(key)) {
                    data[key] = c[key];
                }
            }
            if (!data.header)
                data['header'] = {};
            if (!data.header['Content-Type'])
                data.header['Content-Type'] = 'application/json; charset=UTF-8';
        } else {
            data = params.body;
            config = c;
        }

        // making the request call
        var deferred = $q.defer();
        $http[method](url, data, config).then(function (result) {
            deferred.resolve(result.data);
        }).catch(function (data, status, headers, config) {
            deferred.reject(data);
            $log.error(data, status, headers, config);
        });

        return deferred.promise;
    }

    Model.prototype.get = function (u, p, c) {
        return this.request('get', u, p, c);
    }

    Model.prototype.put = function (u, p, c) {
        return this.request('put', u, p, c);
    }

    Model.prototype.post = function (u, p, c) {
        return this.request('post', u, p, c);
    }

    Model.prototype.delete = function (u, p, c) {
        return this.request('delete', u, p, c);
    }

    Model.prototype.extend = function (construct) {
        var Child = construct;
        var Parent = function () { };
        Parent.prototype = this;
        Child.prototype = new Parent();
        Child.prototype.constructor = Child;

        return Child;
    }

    return new Model;
})
