import { cipo } from 'cipo';

cipo.directive("tableconfiguration", function () {
    return {
        restrict: "E",
        scope: {
            "table": "=",
            "fieldsdict": "=",
            "operations": "=",
        },
        templateUrl: "/ng/views/directives/tabdetails/tableconfiguration.html",
        controller: function ($scope) {
            //$scope.list = new List($scope.listdata);
            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };
            $scope.table.process_FieldsDict($scope.fieldsdict);
        }

    }
});
