
<h3>Context with programs, projects and contracts, where a contract is selected by default</h3>
<cipo-context-view [context]="context()"></cipo-context-view>

<br/>

<h3>Context with programs, projects and contracts, where a project is selected by default</h3>
<cipo-context-view [context]="contextProjectSelected()"></cipo-context-view>

<br/>

<h3>Context with programs, projects and contracts, where a program is selected by default</h3>
<cipo-context-view [context]="contextProgramSelected()"></cipo-context-view>

<br/>

<h3>Context used in places where only contracts are used</h3>
<cipo-context-view [context]="context()" [useOnlyContract]="true"></cipo-context-view>

<br/>

<h3>Context with one contract, full tree shown</h3>
<cipo-context-view [userId]="1"></cipo-context-view>

<br/>

<h3>Context with one contract, only contract is shown</h3>
<cipo-context-view [userId]="1" [useOnlyContract]="true"></cipo-context-view>

<br/>

<h3>Context with no contract</h3>
<cipo-context-view [userId]="2"></cipo-context-view>
