import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject, map, merge, of, switchMap, takeUntil } from 'rxjs';
import { GridViewModel, OrderType, SearchViewModel, SortModel } from '../../../models/module/grid';

@Component({
  template: '',
})
export abstract class PagedListComponent<T> implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource$: Observable<T[]> = of([]);
  searchValue$ = new BehaviorSubject<string>("");
  private destroy$ = new Subject<void>();
  totalCount?: number = 0;
  pageSize = 20;

  ngOnInit(): void {
    this.totalCount = 0;
  }

  abstract getData(pagedRequest: SearchViewModel): Observable<GridViewModel<T[]>>;

  ngAfterViewInit(): void {
    this.dataSource$ = merge(this.sort.sortChange, this.paginator?.page ?? new EventEmitter<PageEvent>(), this.searchValue$).pipe(
      switchMap(_ => {
        return this.getData({
          sort: this.sort.active
            ? [
              {
                field: this.sort.active,
                order: this.sort.direction === 'asc' ? OrderType.Ascending : OrderType.Descending,
              } as SortModel,
            ]
            : null,
          page: (this.paginator?.pageIndex ?? 0) + 1,
          pagesize: this.paginator?.pageSize ?? this.pageSize,
          criteria: this.searchValue$.value
        } as SearchViewModel)
      }
      ),
      map(res => {
        this.totalCount = res.records;
        if (this.paginator) {
          this.paginator.length = res.records;
        }
        return res.data;
      }),
      takeUntil(this.destroy$)
    );
  }

  search(searchValue: string = '') {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.searchValue$.next(searchValue);
  }

  refresh() {
    this.search(this.searchValue$.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}