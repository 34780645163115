export enum ModuleParamaterEnum {
    agency_response_days = 1
}

export type ModuleParameter = {
    id: number;
    moduleId: number;
    moduleName: string;
    entityInstanceId: number;
    value: number;
    moduleValue: number;
    moduleOnMenu: boolean;
}