export const DIALOG_SIZE = {
  sm: {
    width: '30vw',
    minHeight: '30vh',
    maxHeight: '80vh',
  },
  md: {
    width: '50vw',
    minHeight: '50vh',
    maxHeight: '80vh',
  },
  lg: {
    width: '70vw',
    minHeight: '70vh',
    maxHeight: '80vh',
  },
  xl: {
    width: '95vw',
    minHeight: '95vh',
    maxHeight: '95vh',
  },
  full: {
    width: '100vw',
    minHeight: '100vh',
    maxHeight: '100vh',
  },
};
