import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Shift", function ($q, Model, URI, Message, Form, userService) {
    var Shift = Model.extend(function (obj) {

        var self = this;
        
        self.properties = {
            id: null,
            name: "",
            timeStart: null,
            timeEnd: null,
            isAccountingShift: false
        }
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }
        
        
        self.form = new Form(self.properties);
        self.form.initializing = true;
        // if (id) self.get_data();
        self.form.set_Description({
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 64 } },
            timeStart: { label: 'Start Time', type: 'timepicker', options: { format: userService.formats.time }, validation: { required: true } },
            timeEnd: { label: 'End Time', type: 'timepicker', options: { format: userService.formats.time }, validation: { required: true } },
            isAccountingShift: { label: 'Accounting Shift', type: 'checkbox' }
        });

        self.form.setTemplate('grid', [
            { name: 33, timeStart: 33, timeEnd: 33  },
            { isAccountingShift: 100 }
        ]);
        self.form.initializing = false;
    });

    Shift.prototype.get_data = function () {
        var self = this;
        var dataURL = URI.SCHEDULE_SHIFT.GET;
        self.form.loading = true;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                r.timeStart = '1970-01-02T' + r.timeStart + 'Z';
                r.timeStart = moment(r.timeStart);
                r.timeEnd = '1970-01-02T' + r.timeEnd + 'Z';
                r.timeEnd = moment(r.timeEnd);
                for (var key in r) {
                    if (r.hasOwnProperty(key)) {
                        self.properties[key] = r[key];
                    }
                }
                self.form.set_Data(self.properties);
                self.form.store_Data();

            })
            .catch(function (e) { })
            .finally(function () {
                self.form.loading = false;
            })
    }
    
    Shift.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deleting = true;
        var dataURL = URI.SCHEDULE_SHIFT.DELETE;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info('Shift deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject();
            })
            .finally(function () {
                self.deleting = false;
            })
        return p.promise;
    }

    Shift.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            var temp = angular.copy(self.properties);
            temp.timeStart = temp.timeStart.set({ s: 30 }).utc().format("HH:mm:ss");
            temp.timeEnd = temp.timeEnd.set({ s: 30 }).utc().format("HH:mm:ss");
            var dataURL = !self.properties.id ? URI.SCHEDULE_SHIFT.CREATE : URI.SCHEDULE_SHIFT.UPDATE;

            self[dataURL.method](dataURL, temp)
                .then(function (r) {
                    p.resolve();
                    Message.info('Shift saved successfully');
                })
                .catch(function (e) {
                    p.reject();
                    Message.dberror(e);
                })
                .finally(function () {
                    self.form.loading = false;
                })
        }
        else {
            self.form.loading = false;
        }
        return p.promise;
    }



    return Shift;
});
