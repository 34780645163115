import { cipo } from 'cipo';
import moment from 'moment';
import { FROALA_TOOLBAR_SMALL } from 'src/app/shared/components/froala/common';

cipo.factory("WorkflowScreen", function (Model, Form, Manager, EntityFieldsLayout,
    $q, userService, URI, FIELDS_INFO, FIELDS_CONFIG, SET_FIELDS_GRIDSTER, $mdDialog, ACTIONS, Message, AggregateOperators) {
    //constructor extending Model
    var WorkflowScreen = Model.extend(function (obj) {
        // properties
        var obj = obj || {};
        var self = this;

        this.moduleId = obj.moduleId || 0;
        this.selectedRoleId = obj.selectedRoleId || null;
        this.entityInstanceId = obj.entityInstanceId || 0;
        this.contractId = obj.contractId ? obj.contractId : userService.system.userdata.contractId ? userService.system.userdata.contractId : 0;
        this.fromEntityInstanceId = obj.fromEntityInstanceId || 0;
        this.preloaded = obj.preloaded || null;
        this.propertiesKey = obj.propertiesKey || "id";
        this.screenId = obj.screenId;
        this.actionInstanceId = obj.actionInstanceId;
        this.currency = obj.currency || null;

        this.transitionId = obj.transitionId || 0;
        this.actionId = obj.actionId || null;
        this.isStartScreen = obj.isStartScreen || null;
        this.data = this.preloaded ? obj.data : {};
        this.properties = {};
        this.loaded = false;
        this.hasSigners = false;
        this.fieldsBeingRecalculated = [];

        this.emails = { emails: [] };
        this.roleToExcludeIds = { roleToExcludeIds: [], assignee: null };

        this.onFormInit = obj.onFormInit || function (data) { };
        this.onFormChange = obj.onFormChange || function (fieldId, newValue, data, fields) { };

        Object.defineProperty(this, 'valuesDictionary', {
            get: function () {
                var dictionary = [];
                for (var key in self.properties) {
                    if (self.properties.hasOwnProperty(key) && !key.includes('_aggregateValue')) {
                        var value = self.properties[key];
                        // multiple fields
                        if (Object.prototype.toString.call(value) == '[object Array]') {
                            var value = angular.copy(self.properties[key]);
                            if (!value[value.length - 1]) value.pop();

                            if (self.form.fieldsList[key] && self.form.fieldsList[key].type == "attachment" && !self.form.fieldsList[key].hasMultipleValues) {
                                value = (value || [])[0] || null;
                            }
                        }

                        if (typeof self.form.fieldsList[key] != 'undefined') {
                            if (self.form.fieldsList[key].type == "datetimepicker") {
                                if (!moment.isMoment(value))
                                    value = null;
                                else {
                                    // set format based on restriction
                                    var format = userService.formats.datetime;
                                    if (self.form.fieldsList[key].restrictionsLookup[5] == 5) format = userService.formats.time;
                                    if (self.form.fieldsList[key].restrictionsLookup[5] == 3) format = userService.formats.date;

                                    // set value
                                    if (self.form.fieldsList[key].restrictionsLookup[5] == 3) {
                                        value = value.format(format);
                                        value = value + ' 12:00 AM';
                                    }
                                    else value = moment.utc(value).format(format);
                                }
                                // value = value ? value.utc().format(self.form.fieldsList[key].options.format) : null;
                            }


                            if (self.form.fieldsList[key].type === "table"
                                && self.form.fieldsList[key].options && self.form.fieldsList[key].options.length
                                && value && value.length) {

                                // remove null lines on save
                                var endValue = [];
                                for (var i = 0; i < value.length; i++) {
                                    for (var tableKey in value[i]) {
                                        if (value[i].hasOwnProperty(tableKey)) {
                                            if (value[i][tableKey] && !(Object.prototype.toString.call(value[i][tableKey]) == '[object Array]' && !value[i][tableKey].length)) {
                                                endValue.push(value[i]);
                                                break;
                                            }
                                        }
                                    }
                                }

                                value = endValue;

                                for (var i = 0; i < value.length; i++) {
                                    for (var j = 0; j < self.form.fieldsList[key].options.length; j++) {
                                        // get rid of nulls inside the arrays
                                        if (self.form.fieldsList[key].options[j].hasMultipleValues)
                                            if ((value[i][self.form.fieldsList[key].options[j].id] || []).length) {
                                                var lastIndex = value[i][self.form.fieldsList[key].options[j].id].length - 1;
                                                if (!value[i][self.form.fieldsList[key].options[j].id][lastIndex])
                                                    value[i][self.form.fieldsList[key].options[j].id].pop();
                                            }
                                        // if single attachment
                                        if (self.form.fieldsList[key].options[j].typeId == 5
                                            && !self.form.fieldsList[key].options[j].hasMultipleValues) {
                                            value[i][self.form.fieldsList[key].options[j].id] = (value[i][self.form.fieldsList[key].options[j].id] || [])[0] || null;
                                        }

                                        if (FIELDS_INFO[self.form.fieldsList[key].options[j].displayTypeId].type === "datepicker")
                                            value[i][self.form.fieldsList[key].options[j].name] =
                                                value[i][self.form.fieldsList[key].options[j].name] ?
                                                    value[i][self.form.fieldsList[key].options[j].name].format('YYYY-MM-DD[T12:00:00Z]') : null;

                                        if (FIELDS_INFO[self.form.fieldsList[key].options[j].displayTypeId].type === "datetimepiker")
                                            value[i][self.form.fieldsList[key].options[j].name] =
                                                value[i][self.form.fieldsList[key].options[j].name] ?
                                                    value[i][self.form.fieldsList[key].options[j].name].format('YYYY-MM-DD[T]HH:mm:ss[Z]') : null;

                                        if (FIELDS_INFO[self.form.fieldsList[key].options[j].displayTypeId].type === "timepicker")
                                            value[i][self.form.fieldsList[key].options[j].name] =
                                                value[i][self.form.fieldsList[key].options[j].name] ?
                                                    value[i][self.form.fieldsList[key].options[j].name].format('YYYY-MM-DD[T]HH:mm:ss[Z]') : null;
                                    }
                                }
                            }

                            if (self.form.fieldsList[key].type === "submodule" && value.length) {
                                var submoduleValue = [];
                                for (var i = 0; i < value.length; i++) {
                                    submoduleValue.push(value[i].valuesDictionary);
                                }

                                value = submoduleValue;
                            }
                        }
                        dictionary.push({ id: parseInt(key), value: value });
                    }
                }
                return dictionary;
            }
        });

        this.typeList = FIELDS_INFO;
    });

    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }

    WorkflowScreen.prototype.init = function (isDraft) {
        var self = this;
        var p = $q.defer();
        var all = [self.get_Data()];
        if (!(self.rolesDict || []).length) all.push(self.get_RolesDict());
        $q.all(all)
            .then(function () {
                self.loaded = true;
                if (self.data.emailsDataSource && self.data.emailsDataSource.length) {
                    self.setupNotificationForm();
                }
                if (self.rolesDict.length || self.data.manualAssignUsers && self.data.manualAssignUsers.length) {
                    self.setupExclusionForm();
                }

                if (self.data.fields && self.data.fields.length) {
                    var editMode;
                    if (self.data.approvalId) editMode = false;
                    if (isDraft) editMode = true;

                    if (!self.form && !self.data.transitionLeavingDraft)
                        self.setupForm(editMode);
                }

                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowScreen.prototype.get_RolesDict = function () {
        var self = this;
        var p = $q.defer();
        self.rolesDict = [];
        self.rolesDictLookup = {};

        if (!self.preloaded) {
            var params = {
                moduleId: self.moduleId,
                contractId: self.contractId,
                excludeRoleId: self.selectedRoleId
            };
            var dataURL = URI.MODULE.MODULE_ROLES_DICT;
            self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    self.rolesDict = r;
                    if (r && r.length)
                        for (var i = 0; i < r.length; i++)
                            self.rolesDictLookup[r[i].key] = r[i];
                    p.resolve();
                })
                .catch(function (e) { console.error(e) });
        } else {
            p.resolve();
        }

        return p.promise;
    }

    WorkflowScreen.prototype.setupForm = function (editable) {
        var self = this;
        self.form = new Form();
        self.form.entityInstanceId = self.entityInstanceId;
        self.form.isDraftState = self.isDraftState;
        var editMode;
        // var editMode = typeof editMode == 'undefined' ? self.data.isDraftState : editMode;
        if (typeof editable != 'undefined') editMode = editable;
        else if (!self.isStartScreen) editMode = true;
        else editMode = self.data.isDraftState;

        self.form.initializing = true;
        self.form.editMode = editMode;
        self.get_Properties();
        self.form.set_Data(self.properties);

        self.form.display = 'positiondata';
        self.form.pattern = (new EntityFieldsLayout(self.data.fields ? self.data.fields : [], self.propertiesKey)).table;

        if (!editMode && self.actionInstanceId) {
            // remove rows that have all cells with object null
            self.form.pattern = self.form.pattern.filter(r => r.some(c => c.object != null))

            // when view and screen from action, mode reset colspan and rowspan if row has only one cell
            self.form.pattern.forEach((r, i) => {
                var cellsWithData = r.filter(c => c.object != null);
                if (cellsWithData.length == 1) {
                    var cell = cellsWithData[0];
                    cell.colspan = 12;
                    cell.rowspan = 1;
                    self.form.pattern[i] = [cell];
                }
            });
        }

        self.form.set_Description(self.formDescription());
        self.form.initializing = false;

        self.form.onChange = self.onFormChange;
        self.onFormInit(self.data);
    }

    WorkflowScreen.prototype.setupApprovalForm = function () {
        var self = this;
        self.approvalContent = {
            comment: ""
        }
        self.approvalForm = new Form(self.approvalContent);
        // self.approvalForm.initializing = true;
        self.approvalForm.set_Description(
            { comment: { label: 'Comment', type: 'editor' } });
    }

    WorkflowScreen.prototype.loadManagers = function (relations) {
        var self = this;
        self.relations = relations || self.relations;
        for (var i = 0; i < self.relations.length; i++) {
            self.loadManager(self.relations[i]);
        }
    }

    WorkflowScreen.prototype.loadManager = function (relation) {
        var self = this;
        var dataURL = !relation.isPayment ? URI.MODULE_GRID.SEARCH_CHILDREN : URI.MODULE.PP_GRID_SEARCH;
        var columnsURL = !relation.isPayment ? URI.MODULE_GRID.FIELDS : URI.MODULE.PP_GRID_FIELDS;
        relation.manager = new Manager({
            objectsPerPage: 20,
            dataURL: dataURL,
            urlParams: {
                contractId: self.contractId,
                parentEntityInstanceId: self.entityInstanceId,
                // moduleId: relation.relatedModuleId,
                relationId: relation.relationId
            },
            moduleId: relation.relatedModuleId,
            gridClass: 'defaultGrid',
            isOnModal: true,
            options: {
                multiselect: false
            },
            layout: relation.isPayment ? 2 : 1,
            rowOnClick: function (row) {
                if (!relation.isPayment && relation.canOpen) self.editManagerItem(row.entity_instance_id, relation.relatedModuleId, relation, row.screen_id);
                else if (!relation.canOpen) Message.warning('You do not have permissions to view the details of this item.');
                else return;
            },
            rowClass: function (row) {
                if (row._is_draft) return "draftRow";
            },
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        relation.manager.refresh();
                    }
                }
            ],
            dataWrapper: 'data',
            parseData: function (data, columns) {


                var data = data || [], retval = [], columnsLookup = {}, stateNameId, columns = columns || [];
                if (columns) {
                    for (var j = 0; j < columns.length; j++) {
                        columnsLookup[columns[j].replacementFor] = columns[j];
                        if (columnsLookup[columns[j].replacementFor].fieldName == 'state_name') stateNameId = columns[j].replacementFor;
                    }
                }

                for (var i = 0; i < data.length; i++) {
                    data[i]._is_draft = data[i].is_draft;
                    data[i]._in_my_court = data[i].in_my_court;

                    for (var key in data[i]) {
                        if (data[i].hasOwnProperty(key) && typeof columnsLookup[key] != 'undefined') {
                            if (columnsLookup[key].typeId == 3)
                                data[i][key] = ([true, false].indexOf(data[i][key]) != -1) ? (data[i][key] ? "Yes" : "No") : data[i][key];
                            else if (columnsLookup[key].typeId == 4 && data[i][key]) {
                                var format = userService.formats.datetime;
                                if (columnsLookup[key].restrictionsLookup[5] == 5) format = userService.formats.time;
                                if (columnsLookup[key].restrictionsLookup[5] == 3) format = userService.formats.date;

                                if (columnsLookup[key].restrictionsLookup[5] == 3) data[i][key] = moment.utc((addZ(data[i][key]))).format(format);
                                else data[i][key] = moment(addZ(data[i][key])).format(format);
                            }
                            else data[i][key] = (data[i][key] || "").toString();
                        }
                    }

                    data[i].permissions = {};
                    // map permissions
                    if (data[i].permission_list) {
                        data[i].permission_list = data[i].permission_list.split(",");
                        for (var j = 0; j < data[i].permission_list.length; j++) {
                            Object.assign(data[i].permissions, Permissions[data[i].permission_list[j]]);
                        }
                    }

                    if (stateNameId && data[i][stateNameId])
                        data[i][stateNameId] = "<span class='ellipsedText' style='display: block; text-align: center; text-transform: uppercase; border-radius: 3px; color: #fff; background-color:"
                            + (data[i].is_approval ? "#ffab00" : data[i]["state_color"]) + "; color:"
                            + (data[i].is_approval ? "#fff" : data[i]["state_text_color"]) + ";'>"
                            + data[i][stateNameId] + "</span>";

                    retval.push(data[i]);
                }

                return retval;
            },
        });

        var bottomFormulasLookup = {};

        var setBottomFormulas = function () {
            self.AggregateOperators = [];
            relation.bottomFormulas = [];
            bottomFormulasLookup = {};

            for (var key in AggregateOperators) {
                if (AggregateOperators.hasOwnProperty(key)) {
                    self.AggregateOperators.push({ key: parseInt(key), value: AggregateOperators[key] });
                    var op = {
                        key: parseInt(key), value: AggregateOperators[key], fields: {}, fieldIds: []
                    };
                    relation.bottomFormulas.push(op);
                    bottomFormulasLookup[key] = op;
                }
            }
        }

        var urlData = URI.MODULE_GRID.GET_FORMULAS;

        self[urlData.method](urlData, { url: { contractId: self.contractId, current: true }, urltype: 'obj' },
            { headers: { moduleId: relation.relatedModuleId } });

        self[columnsURL.method](columnsURL.toString(),
            { url: { relationId: relation.relationId, contractId: self.contractId, workflowId: userService.system.workflowId }, urltype: 'obj' },
            { headers: { moduleId: relation.relatedModuleId } })
            .then(function (result) {
                var r = result.fields || [];
                // self.initiator = result.userIsInitiator ? result.userIsInitiator : false;
                var columns = [];

                var currentFieldsLookup = {};

                if (r && r.length) {
                    var widthBits = 0;
                    for (var i = 0; i < r.length; i++) {
                        if (!r[i].isFilter) {
                            r[i].width = r[i].width || 1;
                            widthBits += parseInt(r[i].width);
                        }
                    }
                    for (var i = 0; i < r.length; i++) {
                        if (!r[i].isSystem || r[i].name == "Name") {
                            var dataSourceLookup = {};
                            if (r[i].dataSourceValues) {
                                for (var j = 0; j < r[i].dataSourceValues.length; j++) {
                                    var value = r[i].dataSourceValues[j]['value'] ? r[i].dataSourceValues[j]['value'] : "";
                                    dataSourceLookup[r[i].dataSourceValues[j]['key'].toString()] = value;
                                }
                            }
                            //set flags
                            if ((1 & r[i].priority) != 0) r[i].onPhone = true;
                            else r[i].onPhone = false;
                            if ((2 & r[i].priority) != 0) r[i].onTablet = true;
                            else r[i].onTablet = false;

                            r[i].restrictionsLookup = {};
                            if ((r[i].restrictions || []).length) {
                                for (var j = 0; j < r[i].restrictions.length; j++) {
                                    r[i].restrictionsLookup[r[i].restrictions[j].key] = r[i].restrictions[j].value || true;
                                }
                            }

                            var column = {
                                width: 100 / widthBits * r[i].width,
                                onPhone: r[i].onPhone,
                                onTablet: r[i].onTablet,
                                id: r[i].id,
                                name: r[i].id > 0 ? r[i].id : r[i].name,
                                fieldName: r[i].name,
                                replacementFor: r[i].id > 0 ? r[i].id : r[i].name,
                                label: r[i].label,
                                type: 'checkbox',
                                typeId: r[i].typeId,
                                displayTypeId: r[i].displayTypeId,
                                dataSourceId: r[i].dataSourceId || null,
                                fieldTypeId: r[i].fieldTypeId,
                                options: r[i].options || "",
                                restrictionsLookup: r[i].restrictionsLookup,
                                isFilter: r[i].isFilter,
                                relationId: r[i].relationId || null,
                                isPrimarySort: r[i].isPrimarySort,
                                // valueLookup: dataSourceLookup,
                                // dataSourceValues: r[i].dataSourceValues
                            }

                            columns.push(column);

                            currentFieldsLookup[column.replacementFor] = column;
                        }
                    }
                }
                if (relation.isPayment) {
                    var cols = {
                        1: columns,
                        2: columns
                    }
                } else {
                    cols = columns;
                }
                relation.manager.set_Screens(result.startScreens);
                relation.manager.set_Columns(cols);

                setBottomFormulas();

                // bottom formulas
                if ((result.bottomFormulas || []).length) {
                    for (var i = 0; i < result.bottomFormulas.length; i++) {
                        if (result.bottomFormulas[i].operator != 5) {
                            bottomFormulasLookup[result.bottomFormulas[i].operator].fields[result.bottomFormulas[i].fieldId] = result.bottomFormulas[i];
                            bottomFormulasLookup[result.bottomFormulas[i].operator].fieldIds.push(result.bottomFormulas[i].fieldId);
                        }
                    }

                    for (var index = 0; index < relation.bottomFormulas.length; index++) {
                        (function (i) {
                            Object.defineProperty(relation.bottomFormulas[i], "visibleOnTablet", {
                                get: function () {
                                    var visible = false;
                                    for (var key in relation.bottomFormulas[i].fields) {
                                        if (relation.bottomFormulas[i].fields.hasOwnProperty(key))
                                            if ((currentFieldsLookup[key] || {}).onTablet) {
                                                visible = true;
                                                break;
                                            }
                                    }
                                    return visible;
                                }
                            })

                            Object.defineProperty(relation.bottomFormulas[i], "visibleOnPhone", {
                                get: function () {
                                    var visible = false;
                                    for (var key in relation.bottomFormulas[i].fields) {
                                        if (relation.bottomFormulas[i].fields.hasOwnProperty(key))
                                            if ((currentFieldsLookup[key] || {}).onPhone) {
                                                visible = true;
                                                break;
                                            }
                                    }
                                    return visible;
                                }
                            })

                        })(index);
                    }

                    relation.manager.bottomFormulas = relation.bottomFormulas;
                }
                else {
                    relation.manager.bottomFormulas = [];
                }
                relation.manager.topFormulas = result.topFormulas;

                relation.manager.loading = true;
                relation.manager.loadPage()
                    .finally(function () {
                        relation.manager.loading = false;
                    });
            })
            .catch(function (e) {
                relation.manager.loading = false;
            });
    }

    WorkflowScreen.prototype.loadManagerModule = function (moduleId) {
        var self = this;
        self.children ||= [];
        var child = {};

        var moduleCode = userService.getModuleIdentifierById(moduleId);
        var dataURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
            URI[moduleCode.toUpperCase()].GRID_SEARCH : URI.MODULE_GRID.SEARCH;
        var columnsURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
            URI[moduleCode.toUpperCase()].GRID_FIELDS : URI.MODULE_GRID.FIELDS;

        child.manager = new Manager({
            objectsPerPage: 20,
            dataURL: dataURL,
            urlParams: {
                contractId: self.contractId,
                parentEntityInstanceId: self.entityInstanceId
            },
            moduleId: moduleId,
            gridClass: 'defaultGrid',
            isOnModal: true,
            options: {
                multiselect: false
            },
            layout: 1,
            rowOnClick: function (row) {
                self.editManagerItem(row.entity_instance_id, moduleId);
            },
            rowClass: function (row) {
                if (row._is_draft) return "draftRow";
            },
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        child.manager.refresh();
                    }
                }
            ],
            dataWrapper: 'data',
            parseData: function (data, columns) {
                var data = data || [], retval = [], columnsLookup = {}, stateNameId, columns = columns || [];
                if (columns) {
                    for (var j = 0; j < columns.length; j++) {
                        columnsLookup[columns[j].replacementFor] = columns[j];
                        if (columnsLookup[columns[j].replacementFor].fieldName == 'state_name') stateNameId = columns[j].replacementFor;
                    }
                }

                for (var i = 0; i < data.length; i++) {
                    data[i]._is_draft = data[i].is_draft;
                    data[i]._in_my_court = data[i].in_my_court;

                    for (var key in data[i]) {
                        if (data[i].hasOwnProperty(key) && typeof columnsLookup[key] != 'undefined') {
                            if (columnsLookup[key].typeId == 3)
                                data[i][key] = ([true, false].indexOf(data[i][key]) != -1) ? (data[i][key] ? "Yes" : "No") : data[i][key];
                            else if (columnsLookup[key].typeId == 4 && data[i][key]) {
                                var format = userService.formats.datetime;
                                if (columnsLookup[key].restrictionsLookup[5] == 5) format = userService.formats.time;
                                if (columnsLookup[key].restrictionsLookup[5] == 3) format = userService.formats.date;

                                if (columnsLookup[key].restrictionsLookup[5] == 3) data[i][key] = moment.utc((addZ(data[i][key]))).format(format);
                                else data[i][key] = moment(addZ(data[i][key])).format(format);
                            }
                            else data[i][key] = (data[i][key] || "").toString();
                        }
                    }

                    data[i].permissions = {};
                    // map permissions
                    if (data[i].permission_list) {
                        data[i].permission_list = data[i].permission_list.split(",");
                        for (var j = 0; j < data[i].permission_list.length; j++) {
                            Object.assign(data[i].permissions, Permissions[data[i].permission_list[j]]);
                        }
                    }

                    if (stateNameId && data[i][stateNameId])
                        data[i][stateNameId] = "<span class='ellipsedText' style='display: block; text-align: center; text-transform: uppercase; border-radius: 3px; color: #fff; background-color:"
                            + (data[i].is_approval ? "#ffab00" : data[i]["state_color"]) + "; color:"
                            + (data[i].is_approval ? "#fff" : data[i]["state_text_color"]) + ";'>"
                            + data[i][stateNameId] + "</span>";

                    retval.push(data[i]);
                }

                return retval;
            },
        });

        var bottomFormulasLookup = {};

        var setBottomFormulas = function () {
            self.AggregateOperators = [];
            child.bottomFormulas = [];
            bottomFormulasLookup = {};

            for (var key in AggregateOperators) {
                if (AggregateOperators.hasOwnProperty(key)) {
                    self.AggregateOperators.push({ key: parseInt(key), value: AggregateOperators[key] });
                    var op = {
                        key: parseInt(key), value: AggregateOperators[key], fields: {}, fieldIds: []
                    };
                    child.bottomFormulas.push(op);
                    bottomFormulasLookup[key] = op;
                }
            }
        }

        var urlData = URI.MODULE_GRID.GET_FORMULAS;

        self[urlData.method](urlData, { url: { contractId: self.contractId, current: true }, urltype: 'obj' },
            { headers: { moduleId: moduleId } });

        self[columnsURL.method](columnsURL.toString(),
            { url: { contractId: self.contractId, workflowId: userService.system.workflowId, entityInstanceId: self.entityInstanceId }, urltype: 'obj' },
            { headers: { moduleId: moduleId } })
            .then(function (result) {
                var r = result.fields || [];
                var columns = [];

                var currentFieldsLookup = {};

                if (r && r.length) {
                    var widthBits = 0;
                    for (var i = 0; i < r.length; i++) {
                        if (!r[i].isFilter) {
                            r[i].width = r[i].width || 1;
                            widthBits += parseInt(r[i].width);
                        }
                    }
                    for (var i = 0; i < r.length; i++) {
                        if (!r[i].isSystem || r[i].name == "Name") {
                            var dataSourceLookup = {};
                            if (r[i].dataSourceValues) {
                                for (var j = 0; j < r[i].dataSourceValues.length; j++) {
                                    var value = r[i].dataSourceValues[j]['value'] ? r[i].dataSourceValues[j]['value'] : "";
                                    dataSourceLookup[r[i].dataSourceValues[j]['key'].toString()] = value;
                                }
                            }
                            //set flags
                            if ((1 & r[i].priority) != 0) r[i].onPhone = true;
                            else r[i].onPhone = false;
                            if ((2 & r[i].priority) != 0) r[i].onTablet = true;
                            else r[i].onTablet = false;

                            r[i].restrictionsLookup = {};
                            if ((r[i].restrictions || []).length) {
                                for (var j = 0; j < r[i].restrictions.length; j++) {
                                    r[i].restrictionsLookup[r[i].restrictions[j].key] = r[i].restrictions[j].value || true;
                                }
                            }

                            var column = {
                                width: 100 / widthBits * r[i].width,
                                onPhone: r[i].onPhone,
                                onTablet: r[i].onTablet,
                                id: r[i].id,
                                name: r[i].id > 0 ? r[i].id : r[i].name,
                                fieldName: r[i].name,
                                replacementFor: r[i].id > 0 ? r[i].id : r[i].name,
                                label: r[i].label,
                                type: 'checkbox',
                                typeId: r[i].typeId,
                                displayTypeId: r[i].displayTypeId,
                                dataSourceId: r[i].dataSourceId || null,
                                fieldTypeId: r[i].fieldTypeId,
                                options: r[i].options || "",
                                restrictionsLookup: r[i].restrictionsLookup,
                                isFilter: r[i].isFilter,
                                relationId: r[i].relationId || null,
                                isPrimarySort: r[i].isPrimarySort,
                            }

                            columns.push(column);
                            currentFieldsLookup[column.replacementFor] = column;
                        }
                    }
                }
                child.manager.set_Screens(result.startScreens);
                child.manager.set_Columns(columns);

                setBottomFormulas();

                // bottom formulas
                if ((result.bottomFormulas || []).length) {
                    for (var i = 0; i < result.bottomFormulas.length; i++) {
                        if (result.bottomFormulas[i].operator != 5) {
                            bottomFormulasLookup[result.bottomFormulas[i].operator].fields[result.bottomFormulas[i].fieldId] = result.bottomFormulas[i];
                            bottomFormulasLookup[result.bottomFormulas[i].operator].fieldIds.push(result.bottomFormulas[i].fieldId);
                        }
                    }

                    for (var index = 0; index < child.bottomFormulas.length; index++) {
                        (function (i) {
                            Object.defineProperty(child.bottomFormulas[i], "visibleOnTablet", {
                                get: function () {
                                    var visible = false;
                                    for (var key in child.bottomFormulas[i].fields) {
                                        if (child.bottomFormulas[i].fields.hasOwnProperty(key))
                                            if ((currentFieldsLookup[key] || {}).onTablet) {
                                                visible = true;
                                                break;
                                            }
                                    }
                                    return visible;
                                }
                            })

                            Object.defineProperty(child.bottomFormulas[i], "visibleOnPhone", {
                                get: function () {
                                    var visible = false;
                                    for (var key in child.bottomFormulas[i].fields) {
                                        if (child.bottomFormulas[i].fields.hasOwnProperty(key))
                                            if ((currentFieldsLookup[key] || {}).onPhone) {
                                                visible = true;
                                                break;
                                            }
                                    }
                                    return visible;
                                }
                            })

                        })(index);
                    }

                    child.manager.bottomFormulas = child.bottomFormulas;
                }
                else {
                    child.manager.bottomFormulas = [];
                }
                child.manager.topFormulas = result.topFormulas;

                child.manager.loading = true;
                child.manager.loadPage()
                    .finally(function () {
                        child.manager.loading = false;
                    });
            })
            .catch(function (e) {
                child.manager.loading = false;
            });

        self.children.push(child);
    }

    WorkflowScreen.prototype.editManagerItem = function (itemId, moduleId, relation, screenId, ev) {
        var self = this;
        // var itemId = (typeof itemId != 'undefined') ? parseInt(itemId) : 0;
        var item = {
            itemId: (typeof itemId != 'undefined') ? parseInt(itemId) : 0,
            fromItem: 0,
            relationId: relation?.relationId
        }
        var module = { moduleId: moduleId, perContract: true };
        $mdDialog.show({
            locals: { item: item, contractId: null, module: module, smallerFullscreen: true, screenId: screenId || self.screenId },
            controller: 'SaveWorkflowEntityInstanceController',
            templateUrl: '/ng/views/dynamics/modals/saveWorkflowEntityInstance.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            escapeToClose: false,
            multiple: true,
            clickOutsideToClose: false
        })
            .then(function (result) {
                if (relation) {
                    relation.manager.page = 1;
                    relation.manager.loadPage();
                }

                if (self.children && self.children.length) {
                    var child = self.children.find((f) => f.manager.moduleId == moduleId);
                    if (child) {
                        child.manager.page = 1;
                        child.manager.loadPage();
                    }
                }

                // Set workflow id with old value
                userService.system.workflowId = userService.system.oldWorkflowId;
            }, function () {
                // Set workflow id with old value
                userService.system.workflowId = userService.system.oldWorkflowId;
            });
    }

    WorkflowScreen.prototype.setupNotificationForm = function () {
        var self = this;
        var showSendTo = !self.data.hideRolesNotify && self.data.emailsDataSource && self.data.emailsDataSource.length > 0;
        var showIncludeAttachments = (self.data.hasFields || self.isStartScreen) && showSendTo;
        self.emails = { emails: self.data.emails, includeAttachments: self.data.isEmailAction || false };
        self.notificationForm = new Form(self.emails);
        self.notificationForm.set_Description({
            emails: {
                label: 'Send notification to',
                type: 'select',
                multiple: true,
                options: self.data.emailsDataSource,
                visible: showSendTo,
                onChange: function (data, oldValue, newValue) {
                    self.onChange(data, oldValue, newValue);
                }
            },
            includeAttachments: { label: 'Include Attachments', type: 'checkbox', visible: showIncludeAttachments }
        });

        if (showSendTo) {
            var notificationTemplate = [{ emails: 100 }];
            if (showIncludeAttachments) {
                notificationTemplate.push({ includeAttachments: 100 });
            }

            self.notificationForm.setTemplate('grid', notificationTemplate);
        }

        return;
    }

    WorkflowScreen.prototype.onChange = function (data, oldValue, newValue) {
        var self = this;
        var options = data.options || [];
        oldValue = oldValue || [];
        newValue = newValue || [];

        oldValue = [...self.emails.emails];
        self.emails.emails = newValue;

        // Check if we have a difference
        var addedValues = newValue.filter((v) => !oldValue.includes(v));
        var removedValues = oldValue.filter((v) => !newValue.includes(v));
        if (!addedValues.length && !removedValues.length) {
            return;
        }

        addedValues.forEach(valueChangedKey => {
            self.updateEmails(valueChangedKey, options, newValue, false);
        });
        removedValues.forEach(valueChangedKey => {
            self.updateEmails(valueChangedKey, options, newValue, true);
        });
    }

    WorkflowScreen.prototype.updateEmails = function (valueChangedKey, options, newValue, isRemoved) {
        var self = this;

        // Get value changed from options
        var valueChanged = options.find((v) => v.key == valueChangedKey);
        // If we changed a group, then we need to check or unchecked users from that group
        if (!valueChanged || !valueChanged.isGroup) {
            return;
        }

        // Get users that we need to add or remove
        var users = options.filter((v) => !v.isGroup && v.roleIds.includes(parseInt(valueChangedKey)));
        var usersKey = users.map(function (v) { return v.key; });
        // exlude the key
        if (isRemoved) {
            // First we need to remove only users that has only current role or all roles are unchecked
            usersKey = self.filterByAllRoles(users, valueChangedKey, newValue);
            // Remove values that we don't need
            self.emails.emails = self.emails.emails.filter((e) => !usersKey.includes(e));
        }
        else {
            // Add values that we need
            for (var i = 0; i < usersKey.length; i++) {
                // Don't add it again if already exists
                if (!self.emails.emails.includes(usersKey[i]))
                    self.emails.emails.push(usersKey[i]);
            }
        }
    }

    WorkflowScreen.prototype.filterByAllRoles = function (users, removedRole, values) {
        var newUsers = [];

        for (var i = 0; i < users.length; i++) {
            // If we only have this role that is deleted, then we need to remove the user too
            if (users[i].roleIds.length == 1 && users[i].roleIds[0] == removedRole)
                newUsers.push(users[i].key);
            else if (users[i].roleIds.length > 1) {
                // Get other roles that user has to check if all of them are removed
                var otherRoles = users[i].roleIds.filter((r) => r != removedRole);
                var count = 0;
                for (var j = 0; j < otherRoles.length; j++) {
                    if (!values.includes(otherRoles[j].toString()))
                        count++;
                }
                if (count == otherRoles.length)
                    newUsers.push(users[i].key);
            }
        }

        return newUsers;
    }

    WorkflowScreen.prototype.createFormatingsLookup = function (field) {
        field.formattingsLookup = {};
        // formatting mapping
        if ((field.formattings || []).length) {
            for (var i = 0; i < field.formattings.length; i++) {
                field.formattingsLookup[field.formattings[i].key] = typeof (field.formattings[i].value) != 'undefined' ? field.formattings[i].value : true;
            }
        }
    }

    WorkflowScreen.prototype.setupExclusionForm = function (excludeRoleId) {
        var self = this;
        var showSendTo = !self.data.hideRolesNotify && self.data.emailsDataSource && self.data.emailsDataSource.length > 0;

        if (showSendTo) {
            if (!self.manualAssignUsersLookup && self.data.manualAssignUsers && self.data.manualAssignUsers.length) {
                self.manualAssignUsersLookup = {};
                for (var i = 0; i < self.data.manualAssignUsers.length; i++) {
                    self.data.manualAssignUsers[i].keys = self.data.manualAssignUsers[i].key + '|' + self.data.manualAssignUsers[i].roleId;
                    self.manualAssignUsersLookup[self.data.manualAssignUsers[i].keys] = self.data.manualAssignUsers[i];
                }
            }

            if (excludeRoleId) {
                // We need to remove excluded role
                var index = self.rolesDict.findIndex(function (el) {
                    return el.key == excludeRoleId;
                });
                if (index)
                    self.rolesDict.splice(index, 1);

                if (self.data.roleToExcludeIds && self.data.roleToExcludeIds.length > 0) {
                    var indexIds = self.data.roleToExcludeIds.indexOf(excludeRoleId);
                    if (indexIds != -1)
                        self.data.roleToExcludeIds.splice(indexIds, 1);
                }
            }

            self.roleToExcludeIds = { roleToExcludeIds: self.data.roleToExcludeIds, assignee: "" };
            self.exclusionForm = new Form(self.roleToExcludeIds);
            var formContent = { roleToExcludeIds: { label: 'Exclude roles', type: 'select', multiple: true, options: self.rolesDict } };
            if (self.data.manualAssignUsers && self.data.manualAssignUsers.length && self.data.canChangeAssignment)
                formContent.assignee = { label: 'Change assignment', type: 'select', optionsKeyProperty: "keys", options: self.data.manualAssignUsers };
            self.exclusionForm.set_Description(formContent);
            self.exclusionForm.setTemplate('grid', [
                { roleToExcludeIds: formContent.assignee ? 50 : 100, assignee: 50 }
            ]);
        }

        return;
    }

    WorkflowScreen.prototype.get_Properties = function () {
        var self = this;
        var properties = {};
        try {
            if (self.data.fields.length) {
                for (var i = 0; i < self.data.fields.length; i++) {
                    if (self.data.fields[i].displayTypeId && FIELDS_CONFIG().fieldTypes[self.data.fields[i].typeId]) {
                        var empty = FIELDS_INFO[self.data.fields[i].displayTypeId].class == 'checkbox' ?
                            false : FIELDS_INFO[self.data.fields[i].displayTypeId].class == 'attachment' ? [] : null;

                        var value = null;

                        var actualValue = self.presetValuesLookup ? self.presetValuesLookup[self.data.fields[i][self.propertiesKey]] : self.data.fields[i].value;
                        var oldValue = self.data.fields[i].oldValue;
                        var aggregateValue = self.data.fields[i].aggregateValue;
                        var aggregateOldValue = self.data.fields[i].aggregateOldValue;

                        // create formattings lookup
                        self.createFormatingsLookup(self.data.fields[i]);

                        var isFieldTypeNumber = self.data.fields[i].formattingsLookup[2] === 1;

                        if (self.form.editMode) {
                            if (!self.data.fields[i].hasMultipleValues) {
                                // if number, parse
                                if (self.data.fields[i].typeId == 2 && isFieldTypeNumber)
                                    value = typeof actualValue != 'undefined' && actualValue != null ? parseFloat(actualValue) : null;

                                // if single attachment, convert to array
                                else if (self.data.fields[i].typeId == 5) {
                                    value = typeof actualValue != 'undefined' && actualValue != null ? [actualValue] : [];
                                    oldValue = typeof oldValue != 'undefined' && oldValue != null ? [oldValue] : [];
                                }
                                // anything else
                                else {
                                    value = typeof actualValue != 'undefined' && actualValue != null ? actualValue : null;
                                    oldValue = typeof oldValue != 'undefined' && oldValue != null ? oldValue : null;
                                }
                            } else {
                                // if number and multiple value, parse values
                                if (self.data.fields[i].typeId == 2 && isFieldTypeNumber && (actualValue || []).length) {
                                    if (Array.isArray(actualValue)) {
                                        for (var j = 0; j < actualValue.length; j++) {
                                            actualValue[j] = parseFloat(actualValue[j]);
                                        }
                                    }
                                    else {
                                        actualValue = parseFloat(actualValue);
                                    }
                                }
                                if (!(actualValue || []).length) {
                                    value = [];
                                    if (self.data.fields[i].displayTypeId != 3) value.push(null);
                                } else {
                                    value = actualValue;
                                }
                            }
                        }

                        self.data.fields[i].restrictionsLookup = {};
                        // restrictions mapping
                        if ((self.data.fields[i].restrictions || []).length) {
                            for (var j = 0; j < self.data.fields[i].restrictions.length; j++) {
                                self.data.fields[i].restrictionsLookup[self.data.fields[i].restrictions[j].key] = typeof (self.data.fields[i].restrictions[j].value) != 'undefined' ? self.data.fields[i].restrictions[j].value : true;
                            }
                        }

                        // date parsing
                        if (self.data.fields[i].typeId == 4) {
                            if (self.data.fields[i].restrictionsLookup[5] == 3) value = actualValue ? moment.utc((addZ(actualValue))) : null;
                            else value = actualValue ? moment(addZ(actualValue)) : null;
                        }

                        properties[self.data.fields[i][self.propertiesKey].toString()] = value || actualValue;

                        if (oldValue) {
                            properties[self.data.fields[i][self.propertiesKey].toString() + "_old"] = oldValue;
                        }

                        if (aggregateValue) {
                            properties[self.data.fields[i][self.propertiesKey].toString() + "_aggregateValue"] = aggregateValue;
                        }

                        if (aggregateOldValue) {
                            properties[self.data.fields[i][self.propertiesKey].toString() + "_aggregateOldValue"] = aggregateOldValue;
                        }
                    }
                }
            }
        }
        catch (e) {
            console.error(e);
        }

        self.properties = properties;

        return;
    }

    WorkflowScreen.prototype.formDescription = function () {
        var self = this;
        var description = {};
        var fields = self.data.fields;
        // self.relations = [];
        if (self.data.fields.length) {
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];

                if (field.displayTypeId && FIELDS_CONFIG().fieldTypes[field.typeId]) {
                    description[field[self.propertiesKey].toString()] = new function () {
                        // properties
                        SET_FIELDS_GRIDSTER(field);

                        this.id = field.id;
                        this.fieldId = field.id;
                        this.name = field.name || field.id;
                        this.systemFieldName = field.systemFieldName;
                        this.label = field.label;
                        this.type = field.type;
                        this.typeId = field.typeId;
                        this.parentFieldDataSourceId = field.parentFieldDataSourceId;
                        this.parentFieldId = field.parentFieldId;
                        this.childFieldIds = field.childFieldIds;
                        this.updateModelOnBlur = false;
                        this.validation = {};
                        this.formattings = field.formattings;
                        this.restrictions = field.restrictions;
                        this.expression = field.expression;
                        this.actionInstanceId = field.actionInstanceId;
                        this.previousActionInstanceId = field.previousActionInstanceId;
                        this.usedOnWeather = field.usedOnWeather;
                        this.weatherDateId = field.weatherDateId;

                        if (self.presetValuesLookup && self.presetValuesLookup[field.id + '_' + 'dataSource']) field.dataSources = self.presetValuesLookup[field.id + '_' + 'dataSource'];

                        this.dataSources = field.dataSources || [];

                        if (self.isPresetConfig && field.fieldOrigin == 2) {
                            this.editMode = false;
                            this.info = 'This field can only have values when creating documents';
                        }

                        this.hasMultipleValues = field.hasMultipleValues;

                        // default value allocation
                        if (typeof field.defaultValue != 'undefined') {
                            if (this.type === "datepicker" || this.type === "datetimepicker" || this.type === "timepicker")
                                this.defaultValue = moment(field.defaultValue);
                            else
                                if (field.typeId == 2) {
                                    this.defaultValue = field.defaultValue ? parseFloat(field.defaultValue) : null;

                                }
                                else this.defaultValue = field.defaultValue;
                        }

                        if (field.typeId == 2) {
                            if ((field.multipleDefaultValue || []).length)
                                for (var i = 0; i < field.multipleDefaultValue.length; i++) {
                                    field.multipleDefaultValue[i] = parseFloat(field.multipleDefaultValue[i]);
                                }
                        }
                        this.multipleDefaultValue = field.multipleDefaultValue;
                        this.dataSourceId = field.dataSourceId;
                        this.relationId = field.relationId;
                        this.hintsTagg = field.hintsTagg;
                        // editor
                        if (this.type == "editor") {
                            if (!this.options) this.options = {};
                            // set froala editor height
                            if (field.rows) this.options.heightMin = field.rows * 76 - 50 > 100 ? field.rows * 76 - 50 : 100;
                            if (field.rows) this.options.heightMax = field.rows * 76 > 400 ? field.rows * 76 : 400;
                            this.options.toolbarButtons = FROALA_TOOLBAR_SMALL.toolbarButtons;
                            this.options.toolbarButtonsXS = FROALA_TOOLBAR_SMALL.toolbarButtonsXS;
                        }

                        // validation
                        // new stuff

                        this.formattingsLookup = field.formattingsLookup;
                        this.restrictionsLookup = field.restrictionsLookup;

                        // required or needs to be checked
                        if (field.restrictionsLookup[3] || field.restrictionsLookup[2])
                            this.validation['required'] = true;
                        // readonly
                        if (field.restrictionsLookup[1])
                            this.editMode = false;

                        // datetime parsing
                        if (this.type == "datetimepicker") {
                            if (this.restrictionsLookup[5] == 3)
                                this.options = { format: userService.formats.date };
                            else if (this.restrictionsLookup[5] == 5)
                                this.options = { format: userService.formats.time };
                            else this.options = { format: userService.formats.datetime };
                        }

                        this.multiple = field.hasMultipleValues;
                        if (typeof field.dataSourceValues != 'undefined')
                            this.options = field.dataSourceValues;

                        if ((field.dataSources || []).length)
                            this.options = field.dataSources;

                        // annotation parsing
                        if (this.type == "annotation")
                            this.annotation = field.annotation;

                        // submodule
                        if (this.type === "submodule") {
                            this.WorkflowScreenPrototype = self.__proto__;
                            this.SubModuleId = field.dataSource || null;
                            this.relationId = field.relationId || null;
                            this.parentEntityInstanceId = self.entityInstanceId;
                            this.options = field.dataSourceModuleFields;
                        }

                        // table
                        if (this.type === "table") {
                            //  this.options = field.dataSourceFields;
                        }

                        // selects with parents, set list on children, set initial filter on children
                        if (field.parentFieldId && field.parentFieldDataSourceId) {
                            this.parentFieldListId = field.parentFieldDataSourceId;
                            // get parent value value
                            var _v = self.form.data[field.parentFieldId];
                            // process value
                            if (!_v) _v = [];
                            if (Object.prototype.toString.call(_v) != '[object Array]') _v = [_v];
                            // set value as parent element
                            this.parentListElementIds = _v;
                        }

                        // selects with children (these are the parents)
                        if (field.isParent && field.childFieldIds && field.childFieldIds.length) {
                            this.childFieldIds = field.childFieldIds;
                            this.isParent = field.isParent;
                            // selects
                            if (this.type === "select") {
                                this.onClose = function (f) {
                                    f.onSelectClose();

                                    // get parent field value. if it is null, make it empty array. of it is not null and not array, make it array
                                    var _v = f._value === null || typeof f._value === 'undefined' ? [] : f._value;
                                    if (!_v) _v = [];
                                    if (Object.prototype.toString.call(_v) != '[object Array]') _v = [_v];

                                    // get children from field through f that is from the onClose method on the field template
                                    var _childFieldIds = f.childFieldIds;

                                    // loop through all children if any
                                    for (var i = 0; i < _childFieldIds.length; i++) {
                                        // get child field description
                                        var _scopedChildField = self.form.fieldsList[_childFieldIds[i]] || null;
                                        if (_scopedChildField) {
                                            // set loading on child field
                                            _scopedChildField.loading = true;
                                            // set child field value to null
                                            self.form.data[_childFieldIds[i]] = null;
                                            // reset child parent element for filter
                                            _scopedChildField.parentListElementIds = _v;

                                            // get all children of scoped child field and set their values and parent list element to null
                                            if (_scopedChildField.isParent && _scopedChildField.childFieldIds && _scopedChildField.childFieldIds.length) {
                                                var _childFieldChildFieldIds = _scopedChildField.childFieldIds;

                                                for (var j = 0; j < _childFieldChildFieldIds.length; j++) {
                                                    var _scopedChildFieldChildField = self.form.fieldsList[_childFieldChildFieldIds[j]] || null;
                                                    if (_scopedChildFieldChildField) {
                                                        _scopedChildFieldChildField.loading = true;
                                                        self.form.data[_childFieldChildFieldIds[j]] = null;
                                                        _scopedChildFieldChildField.parentListElementId = null;
                                                        _scopedChildFieldChildField.loading = false;
                                                    }
                                                }
                                            }

                                            _scopedChildField.loading = false;
                                        }
                                    }
                                };
                            }
                        }

                        // triggers
                        if (field.triggeredFields && field.triggeredFields.length) {
                            this.triggered = field.triggeredFields;
                            // selects
                            if (this.type === "select" || this.type === "multiselect") {
                                this.onClose = function (f) {
                                    onChangeTriggeredFields(self, f);
                                };
                            }
                            else if (this.type === "number" || this.type === "datetimepicker") {
                                this.onChange = function (f) {
                                    onChangeTriggeredFields(self, f);
                                };
                            }
                        }
                        // This field is triggered only by formula
                        else if (triggeredDataSource(self, field.id)) {
                            this.onChange = function (f) {
                                self.getEmailsDatasource(f);
                            };
                        }

                        // If this field is triggered by other fields and is a formula, we need to set modifiedByUser property when user is changing the value to not rewrite it automatically
                        var thisFieldIsTriggerd = fields.find(el => el.triggeredFields && el.triggeredFields.find(t => t == this.id));
                        if (thisFieldIsTriggerd && (this.typeId == 9 || this.typeId == 2 || this.typeId == 4)) {
                            this.onChange = this.onChange
                                ? function (f) {
                                    onChangeTriggeredFields(self, f);
                                    onChangeModifiedByUser(f);
                                }
                                : function (f) {
                                    onChangeModifiedByUser(f);
                                };
                        }

                        // If we have a formula field, add function to recreateFormulaField
                        if (this.typeId == 9 || this.typeId == 2 || this.typeId == 4) {
                            this.recreateFormulaField = function (f) {
                                f.modifiedByUser = false;
                                self.setFormulaFieldValue(self, f.fieldId, self.getValues(self));
                            }
                        }
                    };
                }
            }
        }

        return description;
    }

    // Get if current field is not triggered by conditional to refresh emails datasource
    var triggeredDataSource = function (self, id) {
        self.data.conditionalFieldIds && self.data.conditionalFieldIds.indexOf(id) !== -1;
    }

    var onChangeModifiedByUser = function (f) {
        if (f.typeId == 4 && !f.valueSetFromTrigger) {
            f.modifiedByUser = true;
        }
        else if (f.typeId == 4 && f.valueSetFromTrigger) {
            f.valueSetFromTrigger = false;
        }
        else {
            f.modifiedByUser = true;
        }
    }

    var onChangeTriggeredFields = function (self, f) {
        self.getFormulaFieldValue(f);

        // In case this field is triggered by formula and datasource
        if (triggeredDataSource(self, f.id || f.fieldId))
            self.getEmailsDatasource(f);
    }

    WorkflowScreen.prototype.anyFieldBeingRecalculated = function (f) {
        return this.fieldsBeingRecalculated && this.fieldsBeingRecalculated.length;
    }

    WorkflowScreen.prototype.addFieldToRecalculate = function (f) {
        if (!f?.triggered) {
            return;
        }

        var self = this;
        self.fieldsBeingRecalculated = self.fieldsBeingRecalculated || [];

        for (var i = 0; i < f.triggered.length; i++) {
            var fieldId = f.triggered[i];
            var exists = self.fieldsBeingRecalculated.some(id => id === fieldId);
            if (!exists && self.form.fieldsList[f.triggered[i]] && !self.form.fieldsList[f.triggered[i]].modifiedByUser) {
                self.fieldsBeingRecalculated.push(f.triggered[i]);
            }
            self.addFieldToRecalculate(self.form.fieldsList[f.triggered[i]]);
        }
    }

    WorkflowScreen.prototype.removeFieldToRecalculate = function (fieldId) {
        var self = this;
        var indexToRemove = self.fieldsBeingRecalculated.findIndex(id => id === fieldId);
        if (indexToRemove !== -1) {
            self.fieldsBeingRecalculated.splice(indexToRemove, 1);
        }
    }

    WorkflowScreen.prototype.getFormulaFieldValue = function (f) {
        var self = this;
        var _triggered = f.triggered;

        // detect all the fields that should be recalculated
        self.addFieldToRecalculate(f);

        for (var i = 0; i < _triggered.length; i++) {
            if (self.form.fieldsList[_triggered[i]] && !self.form.fieldsList[_triggered[i]].modifiedByUser) {
                self.setFormulaFieldValue(self, _triggered[i], self.getValues(self));
            }
        }
    }

    WorkflowScreen.prototype.setFormulaFieldValue = function (self, fieldId, fieldValues) {
        // Set field as loading
        self.form.fieldsList[fieldId].loading = true;

        // Call method to get value for each trigger field
        var dataURL = URI.MODULE.GET_FORMULA_VALUE;

        self[dataURL.method](dataURL, { url: { contractId: self.contractId, fieldId: fieldId }, urltype: 'obj', body: fieldValues })
            .then(function (r) {
                if (r) {
                    // we need to convert value as moment for datetime    
                    if (self.form.fieldsList[r.key].typeId == 4) {
                        var format = userService.formats.datetime;
                        if (self.form.fieldsList[r.key].restrictionsLookup[5] == 5) format = userService.formats.time;
                        if (self.form.fieldsList[r.key].restrictionsLookup[5] == 3) format = userService.formats.date;

                        // This bit is for onChange for datetime to not set modifiedByUser in this situation
                        self.form.fieldsList[r.key].valueSetFromTrigger = true;
                        // Set new value from autogenerate
                        if (self.form.fieldsList[r.key].restrictionsLookup[5] == 3) self.form.data[r.key] = moment.utc(addZ(r.value))
                        else self.form.data[r.key] = moment(addZ(r.value))
                    }
                    else {
                        self.form.data[r.key] = r.value;
                    }

                    self.form.fieldsList[r.key].loading = false;

                    // if this field trigger another field, we need to trigger value for the other
                    if (self.form.fieldsList[r.key].triggered)
                        onChangeTriggeredFields(self, self.form.fieldsList[r.key]);
                }
            }).catch(function (e) {
                if (fieldId) {
                    self.form.fieldsList[fieldId].loading = false;
                }
            }).finally(function () {
                self.removeFieldToRecalculate(fieldId)
            });
    }

    WorkflowScreen.prototype.getValues = function (self) {
        var fieldValues = [];
        if (self.form.data) {
            for (var key in self.form.data) {
                fieldValues.push({ id: parseInt(key), value: self.form.data[key] });
            }
        }

        return fieldValues;
    }

    WorkflowScreen.prototype.getEmailsDatasource = function (f) {

        var self = this;
        // Set field as loading
        self.form.fieldsList[f.fieldId || f.id].loading = true;

        // Get field values
        var fieldValues = self.getValues(self);

        var dataURL = URI.MODULE.GET_EMAILS_DATASOURCE;
        self[dataURL.method](dataURL, { url: { contractId: self.contractId, entityInstanceId: self.entityInstanceId, transitionId: self.transitionId }, urltype: 'obj', body: fieldValues })
            .then(function (r) {
                if (r) {
                    self.data.emailsDataSource = r.emailsDataSource;
                    self.data.emails = r.emails;

                    self.setupNotificationForm();

                    self.form.fieldsList[f.fieldId || f.id].loading = false;
                }
            }).catch(function (e) {
                self.form.fieldsList[f.fieldId || f.id].loading = false;
                Message.error('Field datasource could not be retrieved');
            });
    }

    WorkflowScreen.prototype.get_fields = function (isSimulateDraft) {
        var self = this;
        var p = $q.defer();
        var urlParams = {
            entityInstanceId: self.entityInstanceId === 0 ? self.fromEntityInstanceId : self.entityInstanceId,
            // transitionId: self.transitionId,
            contractId: self.contractId,
            excludeRoleId: self.selectedRoleId,
            screenId: self.screenId,
            workflowId: userService.system.workflowId
        };
        if (!isSimulateDraft) {
            if (self.actionId) urlParams.actionId = self.actionId;
            else urlParams.transitionId = self.transitionId;
        } else {
            urlParams.actionId = -1;
            urlParams.transitionId = 0;
        }

        self.get_tagg_users_dict()
            .then(function (taggUsers) {
                var moduleCode = userService.getModuleIdentifierById(self.moduleId);
                var moduleURL = moduleCode && typeof URI[moduleCode.toUpperCase()] != "undefined" && typeof URI[moduleCode.toUpperCase()].GET_FIELDS != "undefined"
                    ? URI[moduleCode.toUpperCase()].GET_FIELDS
                    : URI.MODULE.GET_FIELDS;

                var dataURL = !self.fromEntityInstanceId ? moduleURL : URI.MODULE.GET_DUPLICATE_FIELDS;
                self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
                    .then(function (r) {

                        if (r && r.fields && taggUsers) {
                            for (var i = 0; i < r.fields.length; i++) {
                                if (r.fields[i].displayTypeId == 9)
                                    r.fields[i].hintsTagg = taggUsers;
                            }
                        }
                        if (r.currency) {
                            self.currency = r.currency;
                        }

                        p.resolve(r);
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                        console.error(e);
                        p.reject(e);
                    });

            })

        return p.promise;
    }

    WorkflowScreen.prototype.get_tagg_users_dict = function () {
        var self = this;
        var dataURL = URI.MODULE.TAGG_USERS_DICT;
        var urlParams = {
            contractId: self.contractId || 0
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowScreen.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        self.relations = [];
        if (!self.preloaded) {
            var properties = {
                moduleId: self.moduleId,
                entityInstanceId: self.entityInstanceId === 0 ? self.fromEntityInstanceId : self.entityInstanceId,
                transitionId: self.transitionId,
                contractId: self.contractId
            };
            self.manualAssignUsersLookup = {};

            self.get_fields()
                .then(function (result) {

                    self.data = result;
                    if (!self.entityInstanceId && self.fromEntityInstanceId) {
                        self.data.isDraftState = true;
                        self.data.isRevision = false;
                        self.data.canSign = false;
                        self.data.revision = 0;
                        self.data.revisions = [];
                        if ((self.data.fields || []).length) {
                            for (var i = 0; i < self.data.fields.length; i++) {
                                if (self.data.fields[i].isRelation && self.data.fields[i].displayTypeId != 11 ||
                                    self.data.fields[i].displayTypeId == 12 || self.data.fields[i].isFormula ||
                                    self.data.fields[i].hasFormula) self.data.fields[i].value = null;

                            }
                        }

                    }

                    if (self.data.manualAssignUsers && self.data.manualAssignUsers.length) {
                        for (var i = 0; i < self.data.manualAssignUsers.length; i++) {
                            self.data.manualAssignUsers[i].keys = self.data.manualAssignUsers[i].key + '|' + self.data.manualAssignUsers[i].roleId;
                            self.manualAssignUsersLookup[self.data.manualAssignUsers[i].keys] = self.data.manualAssignUsers[i];
                        }
                    }
                    if (self.data.assignee) {
                        self.data.assignee.keys = self.data.assignee.key + '|' + self.data.assignee.roleId;
                    }
                    if (self.data.signers && self.data.signers.length) {
                        self.hasSigners = true;
                    }
                    // relations special
                    if (self.data.relations) {
                        for (var i = 0; i < self.data.relations.length; i++) {
                            if (self.data.relations[i].records > 0) self.relations.push(self.data.relations[i]);
                        }
                        self.loadManagers();
                    }

                    if (self.data && self.data.fields.length) {
                        self.data.fieldsIdMap = {};
                        for (var i = 0; i < self.data.fields.length; i++) {
                            self.data.fieldsIdMap[self.data.fields[i].fieldId] = self.data.fields[i].id;
                        }
                    }

                    p.resolve();
                })
                .catch(function (e) { p.reject(e); Message.dberror(e); })
                .finally(function () {
                    // 
                });
        } else {
            p.resolve();
        }

        return p.promise;
    }

    return WorkflowScreen;
})
