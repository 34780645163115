import { cipo } from 'cipo';

cipo.directive("heading", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "module": "=",
            "global": "=",
            "percontract": "="
        },
        templateUrl: "/ng/views/directives/system/heading.html",
        controller: function ($scope, userService, Menu, $state, Dictionaries, $timeout, $mdDialog, Message, $document, $window) {
            if (!$scope.module) {
                return;
            }

            Object.defineProperty($scope, 'userService', {
                get: function () {
                    return userService;
                }
            });

            const tenantIdentifier = userService.getTenantIdentifierById(userService.tenantId);
            
            if ($("#section-to-print").length != 0) {
                $("#section-to-print").remove();
            }

            // make sure user can edit module configuration
            Object.defineProperty($scope, 'moduleOperations', {
                get: function () { return userService.getOperationsFor("M"); }
            });

            //userService.system.modules[module.code]
            Object.defineProperty($scope, 'currentModule', {
                get: function () {
                    return (userService.system.modules || {})[($scope.module || {}).code]; }
            });

            if (!$scope.module.roles) $scope.module.roles = [{key: 0, value: "No roles found"}];
            Dictionaries.RoleUsers({ id: $scope.module.moduleId, permissionId: 1, contractId: userService.system.userdata.contractId })
                .then(function (r) {
                    $scope.module.roles = r;
                })
                .catch(function (e) { Message.dberror(e); })

            if (!$scope.currentModule) {
                $state.go("tenant.404", { tenantIdentifier: tenantIdentifier });
            }

            $document[0].title = 'CIPO® Software - ' + ($scope.currentModule || {}).name;
            
            if (($scope.module || {}).perContract || $scope.percontract) {
                $scope.openMenu = function ($mdMenu, ev) {
                    // originatorEv = ev;
                    // console.log('clicked', $mdMenu)
                    $timeout(function () {
                        $scope.focusSearch = true;
                        $timeout(function () {
                            $scope.focusSearch = false;

                        }, 300);
                    }, 300);
                    $mdMenu.open(ev);
                };

                $scope.$watch(function () {
                    return userService.isLoadingMenu;
                },
                    function (n, o) {
                        if (!n && $scope.module && typeof userService.getOperationsFor($scope.module.code).Read != 'undefined') {
                            // all good
                        } else if (!n) {
                            $state.go("tenant.404", { tenantIdentifier: tenantIdentifier });
                        }
                    }
                );
            }

            $scope.sendMail = function (emailId) {
                $scope.copyClipboard(emailId);
                $window.open("mailto:" + emailId, "_self");
            };

            $scope.copyClipboard = function (emailId) {
                // create temp element
                var copyElement = document.createElement("span");
                copyElement.appendChild(document.createTextNode(emailId));
                copyElement.id = 'tempCopyToClipboard';
                angular.element(document.body.append(copyElement));

                // select the text
                var range = document.createRange();
                range.selectNode(copyElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                // copy & cleanup
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                copyElement.remove();

                Message.info('Email copied to clipboard.');
            }

            $scope.editModule = function (module) {
                if ($scope.moduleOperations.Read || $scope.moduleOperations.Update) {
                    var obj = module || $scope.module;
                    obj.isConfig = true;
                    var moduleObj = {
                        abbreviation: obj.code,
                        disabled: false,
                        iconId: obj.iconId,
                        id: obj.moduleId,
                        isSystem: obj.typeId == 3 ? true : false,
                        name: obj.name,
                        perContract: obj.perContract,
                        color: obj.color
                    };
                    $mdDialog.show({
                        locals: { obj: moduleObj, simplifiedVersion: false, operations: $scope.moduleOperations, module: obj },
                        controller: 'editNModuleController',
                        templateUrl: '/ng/views/admin/modals/editNModule.html',
                        parent: angular.element(document.body),
                        targetEvent: null,
                        fullscreen: true,
                        focusOnOpen: false,
                        escapeToClose: false,
                        multiple: true,
                        clickOutsideToClose: false,
                        hasBackdrop: false,
                    })
                    .then(function (result) {
                        obj.code = result.abbr;
                        Menu.triggerRefresh()
                            .then(function () {
                                $document[0].title = 'CIPO® Software - ' + $scope.currentModule.name;
                            })
                            .catch(function () {
                            })
                        userService.isReloadGrid = result.isReloadGrid;
                        userService.isReloadModuleInformation = result.isReloadModuleInformation || false;
                        Dictionaries.RoleUsers({ id: obj.moduleId, permissionId: 1, contractId: userService.system.userdata.contractId })
                            .then(function (r) {
                                obj.roles = r;
                            })
                            .then(function () { })
                    }, function (isReloadGrid) {
                        
                        Menu.triggerRefresh()
                            .then(function () {
                                if (!$scope.currentModule) {
                                    $state.go("tenant.404", { tenantIdentifier: tenantIdentifier });
                                } else {
                                    userService.isReloadGrid = isReloadGrid;
                                }
                                Dictionaries.RoleUsers({ id: obj.moduleId, permissionId: 1, contractId: userService.system.userdata.contractId })
                                    .then(function (r) {
                                        obj.roles = r;
                                    })
                                    .then(function () { })
                            })
                            .catch(function () {
                            })
                    });
                }
            };
        }
    }
});