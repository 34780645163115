import { Component, signal, inject } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { CipoAttachmentComponent } from '../../../shared/components/fields/cipo-attachment/cipo-attachment.component';
import { CipoAttachmentConfig, CipoAttachmentControl } from '../../../shared/components/fields/common';
import { AttachmentModel } from '../../../models/module/fields/main';
import { DemoAttachments, DemoFieldsWithAttachment } from '../../data/demo-cipo-fields';

@Component({
  selector: 'app-demo-cipo-attachment',
  standalone: true,
  imports: [CipoAttachmentComponent, KeyValuePipe, FlexLayoutModule],
  templateUrl: './demo-cipo-attachment.component.html',
})
export class DemoCipoAttachmentComponent {
  fb = inject(FormBuilder);
  form = signal(this.fb.group<{ [x: string]: CipoAttachmentControl }>({}));
  basicAttachment = signal<AttachmentModel[]>([]);
  readonlyConfig = signal<CipoAttachmentConfig>({ showMultipleSave: true, readonly: true });

  constructor() {
    DemoFieldsWithAttachment.forEach(field => {
      const { name, defaultValue, ...props } = field;
      const control = new CipoAttachmentControl(defaultValue, props);
      if (props.required) {
        control.setValidators(Validators.required);
      }
      this.form().addControl(field.name, control);
    });

    this.basicAttachment.set(DemoAttachments);
  }
}
