import { cipo } from 'cipo';

cipo.controller('moveItemsController',
    function ($scope, MODAL, $http, items, entityInstanceId, topLevelEntityInstanceId, rootModuleId, isProjectFiles, globalFolders, info, Name, $mdDialog, Message, $q, userService, URI) {

        const CONTRACT_MODULE_ID = -1;
        const PROJECT_MODULE_ID = -10;
        const PROGRAM_MODULE_ID = -23;
        const RECYCLE_BIN_NODE_ID = -10;

        $scope.disabledBtn = false;
        $scope.message = "Upload";
        $scope.modalInfo = MODAL;
        $scope.modalInfo.modalName = info.modalName;
        $scope.modalInfo.saveButtonText = info.saveButtonText;
        $scope.modalInfo.modalAction = info.modalAction;
        $scope.modalInfo.canUseRoot = info.canUseRoot;
        $scope.modalInfo.displayAddFolder = info.displayAddFolder;
        $scope.currentEntityInstanceId = entityInstanceId;
        $scope.topLevelEntityInstanceId = topLevelEntityInstanceId;
        $scope.rootModuleId = rootModuleId;
        $scope.initialLoad = false;
        $scope.globalFolders = globalFolders;

        $scope.isProjectFiles = isProjectFiles;

        // This is a hack, for treecontrol, we want to search folders and is working only with: filter-expression="filterFolder" to search in children too
        // The other option is to use: filter-expression="{ folderPath: filterFolder }" here will search only parents and will show children, but it will not search in expanded children, the fix was with this hack
        // If users search by 0 or 1, that will search in other properties like hasChildren if folder has children
        $scope.wantedProperties = ['id', 'parentId', 'name', 'hasChildren', 'folderPath', 'itemModuleId', 'parents', 'parentsEIString',
            'entityInstanceId', 'contractEntityInstanceId', 'projectEntityInstanceId', 'programEntityInstanceId', 'parentEntityInstanceId', 
            'iconId', 'iconColor', 'rootModuleId', 'global'];

        var urlsData = info.urlsData || null;
        var otherParams = info.otherParams || null;
        var bodyParams = info.bodyParams || null;

        var initiallySelectedFolder = info.selectedFolder || null;

        var toMove = items;
        var foldersIds = [];

        if (toMove) {
            for (var i = 0; i < toMove.foldersId.length; i++) {
                foldersIds.push(toMove.foldersId[i]);
            }
        }

        // to block the source folder if moving
        $scope.sourceFolderId = info.sourceFolderId || null;

        $scope.treeSetup = {
            treeOptions: {
                isSelectable: function (node) {
                    return !node.unselectable && node.id != $scope.sourceFolderId;
                },
                nodeChildren: "folders",
                dirSelectable: true,
                isLeaf: function (node) { return false; },
                multiSelection: false,
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                }
            },
            orderby: ""
        };

        $scope.getEntityInstanceIdFromDatalist = function (element) {
            if (element.itemModuleId) {
                if (element.itemModuleId == CONTRACT_MODULE_ID) {
                    return element.contractEntityInstanceId || element.entityInstanceId;
                }
                else if (element.itemModuleId == PROJECT_MODULE_ID) {
                    return element.projectEntityInstanceId || element.entityInstanceId;
                }
                else if (element.itemModuleId == PROGRAM_MODULE_ID) {
                    return element.programEntityInstanceId || element.entityInstanceId;
                }
            }

            return null;
        }

        $scope.addFolder = function () {
            $scope.disabledBtn = true;
            var newFolder = { name: "", parentId: $scope.selectedFolder ? $scope.selectedFolder.id : -1, isFolder: true };
            if (otherParams)
                for (var key in otherParams) {
                    if (otherParams.hasOwnProperty(key)) {
                        newFolder[key] = otherParams[key];
                    }
                }
            $scope.newFolder = new Name(newFolder);
        }

        $scope.cancelFolder = function () {
            $scope.newFolder = {};
            $scope.disabledBtn = false;
        }

        $scope.saveFolder = function () {
            $scope.newFolder.form.validate();
            if ($scope.newFolder.form.isValid) {
                $scope.newFolder.form.loading = true;
                $scope.newFolder[urlsData.folders.addFolderURL.method](urlsData.folders.addFolderURL, $scope.newFolder.properties)
                    .then(function (r) {
                        $scope.getChildren($scope.selectedFolder ? $scope.selectedFolder : $scope.root);
                        $scope.selectedFolderId = r;
                        $scope.cancelFolder();
                        Message.info("Folder added successfully");
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                        if ($scope.newFolder && $scope.newFolder.form) $scope.newFolder.form.loading = false;
                    })
                    .finally(function () { });
            }
        }

        $scope.expandedFolders = [];
        var lookupFolders = {};
        $scope.root = { id: isProjectFiles ? $scope.currentEntityInstanceId : -1, entityInstanceId: $scope.currentEntityInstanceId, folders: [], isLoadingChildren: true, itemModuleId: $scope.rootModuleId };
        $scope.folderTree = [];

        var extractFolders = function (data, unselectable) {
            var folders = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].id != -2 && !data[i].itemModuleId) {
                    if (!lookupFolders[$scope.computeFolderId(data[i])]) {
                        lookupFolders[$scope.computeFolderId(data[i])] = data[i];
                    }
                    else {
                        // if the folders was aready in, we replace with the new version, to keep it referenced but not loose info
                        data[i].folders = lookupFolders[$scope.computeFolderId(data[i])].folders;
                        data[i].expanded = lookupFolders[$scope.computeFolderId(data[i])].expanded;
                        if ($scope.expandedFolders.length) {
                            for (var j = 0; j < $scope.expandedFolders.length; j++) {
                                if ($scope.expandedFolders[j].id == data[i].id) {
                                    var toReplace = $scope.expandedFolders[j];
                                    $scope.expandedFolders.splice($scope.expandedFolders.indexOf(toReplace), 1, data[i]);
                                    break;
                                }
                            }
                        }

                        lookupFolders[$scope.computeFolderId(data[i])] = data[i];
                    }

                    if (unselectable) {
                        data[i].unselectable = unselectable;
                    }
                    else {
                        if (foldersIds.indexOf(data[i].id) != -1) data[i].unselectable = true;
                    }

                    // Remove unwanted properties
                    for (var key in data[i]) {
                        if ($scope.wantedProperties.indexOf(key) === -1) {
                            delete data[i][key];
                        }
                    }

                    if (data[i].id !== RECYCLE_BIN_NODE_ID) {
                        folders.push(data[i]);
                    }
                }

                if (data[i].itemModuleId == CONTRACT_MODULE_ID) {
                    data[i].iconId = userService.system.modules['CT'].iconId;
                    data[i].iconColor = userService.system.modules['CT'].color;
                }
                else if (data[i].itemModuleId == PROJECT_MODULE_ID) {
                    data[i].iconId = userService.system.modules['P'].iconId;
                    data[i].iconColor = userService.system.modules['P'].color;
                }
                else if (data[i].itemModuleId == PROGRAM_MODULE_ID) {
                    data[i].iconId = userService.system.modules['PRG'].iconId;
                    data[i].iconColor = userService.system.modules['PRG'].color;
                }
            }
            return folders;
        }

        $scope.getChildren = function (folder, status) {
            var p = $q.defer();

            if (folder.itemModuleId) {
                $scope.currentEntityInstanceId = $scope.getEntityInstanceIdFromDatalist(folder) || folder.id;
            }

            if (status) {
                 folder.expanded = status;
                 $scope.expandedFolders.push(folder);
            }
            if (!folder.expanded && folder.id != -1 && !status) {
                $scope.expandedFolders.push(folder);
                folder.expanded = true;
            }

            if (status || typeof status == 'undefined') {
                folder.loadingChildren = true;
                var urlString = '?isFolders=true';
                var body = { id: $scope.isProjectFiles 
                                    ? !$scope.initialLoad 
                                            ? $scope.topLevelEntityInstanceId 
                                            : folder.id 
                                    : folder.id };

                if (otherParams) {
                    for (var key in otherParams) {
                        if (key == 'entityInstanceId') {
                            urlString += '&' + key + '=' + (!$scope.initialLoad ? $scope.topLevelEntityInstanceId : folder.entityInstanceId ?? $scope.currentEntityInstanceId);
                        }
                        else if (otherParams.hasOwnProperty(key)) {
                            urlString += '&' + key + '=' + otherParams[key];
                        }
                    }
                }

                if (bodyParams) {
                    for (var key in bodyParams) {
                        if (bodyParams.hasOwnProperty(key)) {
                            body[key] = bodyParams[key];
                        }
                    }
                }

                // we need to add more parameters in case we need global
                if ($scope.globalFolders) {
                    if (!urlString.match('rootModuleId')) {
                        urlString += '&rootModuleId=' + $scope.rootModuleId;
                    }

                    // set body isGlobal to true
                    body['isGlobal'] = true;
                }

                var callURL = $scope.globalFolders
                                    ? URI.PROJECT_FILE_MANAGER.SEARCH_GLOBAL
                                    : urlsData.tree.url;

                $http[callURL.method](callURL.toString() + urlString, body)
                    .then(function (r) {
                        if (folder.id == -1 || folder.id == $scope.currentEntityInstanceId && !$scope.initialLoad) {
                            $scope.folderTree = extractFolders(r.data.data);
                            $scope.initialLoad = true;
                        }
                        else {
                            folder.folders = extractFolders(r.data.data, folder.unselectable);
                        }
                        if ($scope.selectedFolderId) {
                            $scope.selectedFolder = lookupFolders[$scope.selectedFolderId];
                            $scope.selectedFolderId = null;
                        }

                        p.resolve(folder.id);
                    })
                    .catch(function (e) { console.error(e); Message.dberror(e); p.reject(); })
                    .finally(function () { folder.loadingChildren = false; });

                return p.promise;
            }


        }
        // [-1, 10, 13]

        var loopGetChildren = function (id) {

            if (!initiallySelectedFolder.parents) {
                Message.warning("The selected folder is missing path information and can't be located.");
                return;
            }

            if (!id) {
                // root folders are in
                if (initiallySelectedFolder.parents.length > 1) {
                    // selected folder is not a root folder
                    $scope.getChildren(lookupFolders[$scope.computeFolderId(initiallySelectedFolder.parents[1])])
                        .then(function () {
                            loopGetChildren(initiallySelectedFolder.parents[1]);
                        })
                } else $scope.selectedFolder = lookupFolders[$scope.computeFolderId(initiallySelectedFolder)];

            } else {
                // we get the next level of children
                var x = initiallySelectedFolder.parents.indexOf(id);

                if (initiallySelectedFolder.parents[x + 1]) {
                    $scope.getChildren(lookupFolders[$scope.computeFolderId(initiallySelectedFolder.parents[x + 1])])
                        .then(function () {
                            loopGetChildren(initiallySelectedFolder.parents[x + 1]);
                        })
                } else $scope.selectedFolder = lookupFolders[$scope.computeFolderId(initiallySelectedFolder)];
            }
        }

        $scope.getChildren($scope.root)
            .then(function (r) {
                if (initiallySelectedFolder)
                    loopGetChildren();
            })

        var otherParamsString = "";
        if (otherParams) {
            otherParamsString = "?";
            for (var key in otherParams) {
                if (otherParams.hasOwnProperty(key)) {

                    // We need entityInstanceId = 0 for initial search, but after we need current entityInstanceId if user choose a program, project, contract
                    // For this case, when we do the move, we need initial entityInstanceId to know where the folder/files were
                    if (key == 'entityInstanceId' && !otherParams[key]) {
                        otherParams[key] = $scope.currentEntityInstanceId;
                    }

                    otherParamsString += key + "=" + otherParams[key] + '&';
                }
            }
            
            otherParamsString = otherParamsString.substring(0, otherParamsString.length - 1);
        }

        $scope.modalAction = function () {
            $scope[info.modalAction]();
        }

        $scope.selectDestinationFolder = function () {
            $mdDialog.hide($scope.selectedFolder);
        }

        $scope.move = function () {
            toMove.destinationFolderId = $scope.selectedFolder ? $scope.selectedFolder.id : -1;
            toMove.isDestinationEntityInstance = $scope.selectedFolder?.itemModuleId !== null && $scope.selectedFolder?.itemModuleId !== undefined || false;
            toMove.entityInstanceId = $scope.currentEntityInstanceId;
            toMove.destinationEntityInstanceId = $scope.selectedFolder?.itemModuleId !== null && $scope.selectedFolder?.itemModuleId !== undefined 
                                                        ? $scope.selectedFolder?.id 
                                                        : $scope.selectedFolder?.parentEntityInstanceId;
            toMove.rootModuleId = $scope.selectedFolder?.rootModuleId ?? $scope.selectedFolder?.itemModuleId ?? $scope.rootModuleId;
            $scope.disabledBtn = true;
            $http[urlsData.folders.moveURL.method](urlsData.folders.moveURL.toString() + otherParamsString, toMove)
                .then(function (r) {
                    $mdDialog.hide(toMove.destinationFolderId);
                    Message.info("Items moved successfully");
                })
                .catch(function (e) { console.error(e); Message.dberror(e); })
                .finally(function () { $scope.disabledBtn = false; });
        }

        $scope.closeModal = function () {
            $mdDialog.cancel();
        };

        $scope.computeFolderId = function (folder, parentsEIString) {
            return folder.itemModuleId || folder.isEntityInstance ? folder.id + "_1" : (folder.parentsEIString || parentsEIString || '') + '|' + folder.id.toString();
        }
    });