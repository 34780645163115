import { cipo } from 'cipo';

cipo.factory("FolderName", function ( Model, Form ) {
    var FolderName = Model.extend(function (obj) {
        var self = this;
        self.properties = {
            folderName: obj? obj : null
        };
        
        self.form = new Form(self.properties);

        self.form.set_Description({
            folderName: { label: 'Folder Name', type: 'text', validation: { required: true, minChars: 1, maxChars: 255 } }
        });

        self.form.setTemplate('grid', [
            { folderName: 100 }
        ]);
    });



    return FolderName;
});
