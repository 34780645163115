import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
    year = (new Date()).getFullYear();
}
