import { cipo } from 'cipo';

cipo.controller('loginController', ['$timeout', '$scope', '$state', 'Login', 'userService', 'authService', 'AADB2C','$rootScope',
        function ($timeout, $scope, $state, Login, userService, authService, AADB2C,$rootScope)
        {
            $scope.loginObject = new Login();

        $scope.login = function ()
        {
            authService.login().then(x => {
                $state.go('main')
            });
        };
        
        $scope.loginObject.form.onSubmitForm = function ()
        {
            $scope.login();
        };

        // This triggers the login button automatically to call Azure B2C Sign In policy. It's used to bypass CIPO's Login page that is only used to setup MSAL context configured in cipo.module.js.
        // CIPO's Login is necessary because MSAL needs to create context (register a callback) before redirecting to Azure B2C Sign In policy. The callback will receive the response from Azure B2C policy.
        $timeout(function ()
        {
            if(!authService.isLoggedIn()) {
                angular.element('#login-button').triggerHandler('click');
            }
        }, 0);

        $scope.$on("$destroy", function ()
        {
            // remove MCAfee stuff
            var element = $(document).find('#mfesecure-ts-image');
            element.remove();
        });
    }

]);
