import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { TenantState } from './tenant.model';

const get = (state: AppState) => state.core.tenant;
const getSettingValue = (id: number) =>
  createSelector(get, (tenant: TenantState) => (tenant.settings || []).find(s => s.id === id)?.value);
const getSettingsValue = (ids: number[]) =>
  createSelector(get, (tenant: TenantState) => (tenant.settings || []).filter(s => ids.includes(s.id)));
export const tenantSelectors = {
  get,
  getSettingValue,
  getSettingsValue,
};
