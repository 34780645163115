import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray',
  standalone: true,
})
export class ToArrayPipe implements PipeTransform {
  transform(value: any | any[]): any[] {
    if (typeof value === 'object') {
      return value;
    } else {
      return [value];
    }
  }
}

@Pipe({
  name: 'castToNumber',
  standalone: true,
})
export class CastToNumberPipe implements PipeTransform {
  /**
   * use this pipe when your variable is an union type, but you know for sure it will be a number
   */
  transform(value: any): number {
    return value;
  }
}

@Pipe({
  name: 'castToString',
  standalone: true,
})
export class CastToStringPipe implements PipeTransform {
  /**
   * use this pipe when your variable is an union type, but you know for sure it will be a string
   */
  transform(value: any): string {
    return value;
  }
}
