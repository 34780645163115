import { ContextInfo } from "../../system/context/context.model";

export interface ContextState extends ContextInfo {
}

export const contextStateInitial: ContextState = {
  program: undefined,
  project: undefined,
  contract: undefined,
};
