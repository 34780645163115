import { cipo } from 'cipo';

cipo.directive("gridsettings", function (ACTIONS) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "module": "=",
            "operations": "=",
            "noformulas": "=",
            "hasprint": "="

        },
        templateUrl: "/ng/views/directives/tabdetails/gridsettings.html",
        controller: function ($scope, ACTIONS, Message, MODAL) {

            $scope.searchFields = {filter: ""};

            $scope.modalInfo = MODAL;
            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };
            // grid settings

            $scope.showItem = function (item) {
                var ret = false;
                if (!$scope.searchFields.filter) ret = true;
                else if (item.label.toLowerCase().indexOf($scope.searchFields.filter.toLowerCase()) != -1
                    || item.name.toLowerCase().indexOf($scope.searchFields.filter.toLowerCase()) != -1) ret = true;
                return ret;
            }

            $scope.set_options = function (visibleOnGrid) {
                return {
                    beforeDrop: function (e) {
                        $scope.module.gridFields.processNode(e);
                        return true;
                    },
                    dropped: function (e) {
                        if (!e.dest.nodesScope.nodropEnabled) {
                            $scope.module.gridFields.processDrop(e);
                            return true;
                        }
                        else return false;
                    },
                    nodropEnabled: visibleOnGrid ? false : true
                }
            }

            $scope.rzOptions = {
                onResizeEnded: function (column, newWidth) {
                    // console.error('col resized', column, newWidth, $scope.module.gridFields);
                }
            }
            $scope.togglePhone = function (item) {
                if (item.onPhone != 0) item.onPhone = 0;
                else item.onPhone = 1;
                $scope.module.gridFields.sync();
            }
            $scope.toggleTablet = function (item) {
                if (item.onTablet != 0) item.onTablet = 0;
                else item.onTablet = 2;
                $scope.module.gridFields.sync()
                    .then(function () {
                        // $scope.module.inGridFields.get_List();
                    })
                    .catch(function (e) { })
                // item.onTablet = !item.onTablet;
            }

            $scope.togglePrint = function (item) {
                item.printable = !item.printable;
                $scope.module.gridFields.sync()
                    .then(function () {
                        // $scope.module.inGridFields.get_List();
                    })
                    .catch(function (e) { })
                // item.onTablet = !item.onTablet;
            }
            $scope.tableView = 'desktopView';
            $scope.toggleView = function (view) {
                $scope.tableView = view;
            }
            $scope.removeColumn = function (item) {
                $scope.module.gridFields.removeItem(item, item.isDefault);
                

            }
            $scope.testTable = function (column) {
                // console.error('col resized', column, $scope.module.inGridFields);
            }


        }

    }
});
