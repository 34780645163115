import { cipo } from 'cipo';

cipo.factory("GlobalGraphModel", function () {
    var GlobalGraphModel = function (element) {
        this.element = element;
    }

    GlobalGraphModel.prototype.create = function () {
        var globalTransitionsGraph = new mxGraph(this.element);

        //globalTransitionsGraph properties
        globalTransitionsGraph.setAllowDanglingEdges(false);
        globalTransitionsGraph.setPanning(false);
        globalTransitionsGraph.setCellsEditable(false);
        globalTransitionsGraph.setConnectable(false);
        globalTransitionsGraph.setPortsEnabled(false);
        globalTransitionsGraph.setCellsSelectable(true);
        //globalTransitionsGraph.setEnabled(false)
        globalTransitionsGraph.setCellsMovable(false);
        

        var globalTransitionsParent = globalTransitionsGraph.getDefaultParent();

        //mxEvent.disableContextMenu(this.element);
        mxConstraintHandler.prototype.highlightColor = '#8da5ed';

        //GLOBAL TRANSITION
        var GlobalTransition = function (src, target, obj) {
            this.properties = {
                parent: globalTransitionsParent,
                sourceVertex: src,
                targetVertex: target
            };
            if (Object.prototype.toString.call(obj) === "[object Object]") {
                for (var key in obj) {
                    this.properties[key] = obj[key];
                }
            }
        };
        GlobalTransition.prototype.draw = function () {
            var origin = globalTransitionsGraph.insertVertex(globalTransitionsGraph, null, this.properties.sourceVertex.value, 10, y, 150, 50);
            var destination = globalTransitionsGraph.insertVertex(globalTransitionsGraph, null, this.properties.targetVertex.value, 300, y, 150, 50);
            globalTransitionsGraph.insertEdge(this.properties.parent, null, this.properties.name, origin, destination);
            y += 90;
        };
        

        var vertexStyle = [];
        //for states
        vertexStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
        vertexStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
        vertexStyle[mxConstants.STYLE_STROKECOLOR] = 'gray';
        vertexStyle[mxConstants.STYLE_ROUNDED] = false;
        vertexStyle[mxConstants.STYLE_FILLCOLOR] = '#808080';
        vertexStyle[mxConstants.STYLE_GRADIENTCOLOR] = null;
        vertexStyle[mxConstants.STYLE_FONTCOLOR] = '#774400';
        vertexStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
        vertexStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
        vertexStyle[mxConstants.STYLE_FONTSIZE] = '12';
        vertexStyle[mxConstants.STYLE_FONTSTYLE] = 1;
        vertexStyle[mxConstants.STYLE_STROKEWIDTH] = 2;
        mxConstants.VERTEX_SELECTION_COLOR = '#000000';
        mxConstants.HANDLE_FILLCOLOR = '#8da5ed';
        mxConstants.HANDLE_STROKECOLOR = '#8da5ed';

        //for edges
        var edgeStyle = [];
        edgeStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
        edgeStyle[mxConstants.STYLE_STROKECOLOR] = '#686868';
        edgeStyle[mxConstants.STYLE_EDGE] = mxEdgeStyle.OrthConnector;
        mxGraph.prototype.defaultLoopStyle = mxEdgeStyle.OrthConnector;
        edgeStyle[mxConstants.STYLE_ORTHOGONAL_LOOP] = 1;
        edgeStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
        edgeStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_TOP;
        edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC;
        edgeStyle[mxConstants.STYLE_FONTSIZE] = '12';
        edgeStyle[mxConstants.STYLE_FONTSTYLE] = 1;
        edgeStyle[mxConstants.STYLE_STROKEWIDTH] = 3;
        edgeStyle[mxConstants.CONNECT_TARGET_COLOR] = '#8da5ed';
        mxConstants.EDGE_SELECTION_COLOR = '#000000';
        mxConstants.DROP_TARGET_COLOR = '#8da5ed';
        mxConstants.CONNECT_HANDLE_FILLCOLOR = '#8da5ed';
        mxConstants.OUTLINE_HANDLE_STROKECOLOR = '#8da5ed';

        //for conditional transitions
        var rhombStyle = [];
        rhombStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RHOMBUS;
        rhombStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RhombusPerimeter;
        rhombStyle[mxConstants.STYLE_STROKECOLOR] = 'gray';
        rhombStyle[mxConstants.STYLE_FONTCOLOR] = 'gray';
        rhombStyle[mxConstants.STYLE_FILLCOLOR] = '#EEEEEE';
        rhombStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
        rhombStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
        rhombStyle[mxConstants.STYLE_FONTSIZE] = '8';

        //graph.getStylesheet().putCellStyle('rhombStyle', rhombStyle);
        globalTransitionsGraph.getStylesheet().putDefaultVertexStyle(vertexStyle);
        globalTransitionsGraph.getStylesheet().putDefaultEdgeStyle(edgeStyle);

        
        
        var json;
        var temp_state = [];
        var temp_transition = [];
        var y = 10;

        
        var keyboard = false;
        var enableKeyboard = function () {
            if (keyboard == true) {

                //undo ctrl+z
                $(document).keydown(function (e) {
                    if (e.which === 90 && e.ctrlKey) {
                        undoManager.undo();
                    }
                });
            }
        };
        enableKeyboard();

        var lookup_state = {
            state: [],
            id: [],
            obj: []
        };

        var lookup_transition = {
            transition: [],
            id: [],
            obj: []

        };

        var lookup_rhomb = {
            rhomb: [],
            id: [],
            obj: []
        };



        

        // Adds mouse wheel handling for zoom
        /*mxEvent.addMouseWheelListener(function (evt, up) {
            if (up) {
                graph.zoomIn();
            }
            else {
                graph.zoomOut();
            }
            mxEvent.consume(evt);
        });*/

        var jsonParser = function (json) {
            for (var i = 0; i < json.length; i++) {
                temp_state.push(json[i]);
                for (var j = 0; j < (json[i].transitions).length; j++) {
                    temp_transition.push(json[i].transitions[j]);
                }
            }
        };

        
        var drawTransitions = function () {
            //if(temp_transition.isGlobal == false){
            for (var t = 0; t < temp_transition.length; t++) {
                //if(temp_transition.isGlobal == false){
                for (var s = 0; s < temp_state.length; s++) {
                    if (temp_transition[t].stateId == temp_state[s].id) {
                        var src = lookup_state.state[s];
                    }
                    if (temp_transition[t].nextStateId == temp_state[s].id) {
                        var target = lookup_state.state[s];
                    }
                }
                var transition = new Transition(src, target, temp_transition[t]);
                transition.draw();
                lookup_transition.obj.push(transition);
                //}
                /*
                if(temp_transition.isGlobal == true){
                    for(var s = 0; s < (temp_state).length; s++){
                        if(temp_transition[t].stateId == temp_state[s].id){
                            var src = lookup_state.obj[s];
                        }
                        if(temp_transition[t].nextStateId == temp_state[s].id){
                            var target = lookup_state.obj[s];
                        }
                    }
                    var gblTransition = new globalTransition(src, target, temp_transition[t]);
                    gblTransition.draw();
                    lookup_transition.obj.push(gblTransition);
                }*/
            }

        };

        var rebuildJson = function (stateObjects) {
            var newJson = [];
            for (var i = 0; i < stateObjects.length; i++) {
                newJson.push(stateObjects[i]);
            }
        };

        json = [{
            "id": 248,
            "typeId": 92,
            "name": "Draft",
            "color": "#808080",
            "textColor": "#fffefe",
            "default": true,
            "type": "0",
            "roleIds": [],
            "ballInCourtRoleIds": [],
            "transitions": [{
                "id": 266,
                "name": "To save",
                "default": false,
                "stateId": 248,
                "nextStateId": 249,
                "screenId": 299,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }, {
                "id": 264,
                "name": "Start",
                "default": true,
                "stateId": 248,
                "nextStateId": 248,
                "screenId": 299,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 1
            }],
            "position": 0,
            "signTransitions": []
        }, {
            "id": 251,
            "typeId": 114,
            "name": "Ion",
            "color": "#7755c0",
            "textColor": "#ffffff",
            "default": false,
            "type": "2",
            "templateNotificationId": 157,
            "roleIds": [7],
            "ballInCourtRoleIds": [],
            "transitions": [{
                "id": 269,
                "name": "Gheorghe",
                "default": false,
                "stateId": 251,
                "nextStateId": 251,
                "screenId": 307,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }, {
                "id": 270,
                "name": "Bortolomeu, approves",
                "default": false,
                "stateId": 251,
                "nextStateId": 263,
                "screenId": 303,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }, {
                "id": 272,
                "name": "Doru",
                "default": false,
                "stateId": 251,
                "nextStateId": 251,
                "screenId": 303,
                "roleIds": [7],
                "roleToExcludeIds": [],
                "position": 0
            }, {
                "id": 294,
                "name": "Stay in Ion",
                "default": false,
                "stateId": 251,
                "nextStateId": 251,
                "screenId": 307,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }, {
                "id": 303,
                "name": "To revise",
                "default": false,
                "stateId": 251,
                "nextStateId": 276,
                "screenId": 307,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }],
            "position": 1,
            "signTransitions": []
        }, {
            "id": 263,
            "typeId": 93,
            "name": "Approve",
            "color": "#e5e8ac",
            "textColor": "#000000",
            "default": false,
            "type": "101",
            "roleIds": [],
            "ballInCourtRoleIds": [],
            "transitions": [],
            "position": 2,
            "signTransitions": []
        }, {
            "id": 276,
            "typeId": 556,
            "name": "Revise",
            "color": "#de2424",
            "textColor": "#ffffff",
            "default": false,
            "type": "100",
            "roleIds": [],
            "ballInCourtRoleIds": [7],
            "transitions": [{
                "id": 304,
                "name": "To new revision",
                "default": false,
                "stateId": 276,
                "nextStateId": 248,
                "screenId": 307,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }, {
                "id": 339,
                "name": "vcvvv",
                "default": false,
                "stateId": 276,
                "nextStateId": 276,
                "screenId": 307,
                "roleIds": [7, 509],
                "roleToExcludeIds": [],
                "position": 0
            }],
            "position": 3,
            "signTransitions": []
        }, {
            "id": 310,
            "typeId": 114,
            "name": "abc",
            "color": "#26239f",
            "textColor": "#ffffff",
            "default": false,
            "type": "2",
            "roleIds": [],
            "ballInCourtRoleIds": [],
            "transitions": [],
            "position": 4,
            "signTransitions": []
        }, {
            "id": 311,
            "typeId": 557,
            "name": "Rejects stuff",
            "color": "#000000",
            "textColor": "#ffffff",
            "default": false,
            "type": "102",
            "roleIds": [],
            "ballInCourtRoleIds": [],
            "transitions": [],
            "position": 5,
            "signTransitions": []
        }, {
            "id": 249,
            "typeId": 114,
            "name": "To save",
            "color": "#b18fc2",
            "textColor": "#ffffff",
            "default": false,
            "type": "2",
            "templateNotificationId": 157,
            "roleIds": [7],
            "ballInCourtRoleIds": [],
            "transitions": [{
                "id": 268,
                "name": "To Ion",
                "default": false,
                "stateId": 249,
                "nextStateId": 251,
                "screenId": 326,
                "roleIds": [7],
                "roleToExcludeIds": [],
                "position": 0
            }],
            "position": 6,
            "signTransitions": []
        }];



        jsonParser(json);
                    
        drawTransitions();
        
        rebuildJson(lookup_state.obj);

        // get mxgrpah info from selected cell
        graph.addListener(mxEvent.CLICK, function (sender, evt) {
            // console.log(graph.getSelectionCell());
        });

        var transitionCheck = function (source, target) {
            var draft = 0;
            var open = 2;
            var revise = 100;
            var approved = 101;
            var rejected = 102;
            var global = 103;
            if (source == draft && (target == draft || target == open || target == revise || target == approved || target == rejected)) {
                return true;
            }
            if (source == open && (target == open || target == revise || target == approved || target == rejected)) {
                return true;
            }
            if (source == revise && (target == draft || target == revise || target == approved || target == rejected)) {
                return true;
            }
            if (source == global && (target == open || target == revise || target == approved || target == rejected)) {
                return true;
            }
        };


        graph.getModel().beginUpdate();
        try {

        }
        finally {
            // Updates the display
            graph.getModel().endUpdate();
        }
    }

    return GlobalGraphModel;

});
