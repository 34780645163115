import { cipo } from 'cipo';

cipo.directive("contractshalfdonutchart", function ($window, URI, userService, $http, Message, $timeout) {
    return {
        restrict: "EA",
        scope: {
        },
        templateUrl: "/ng/views/directives/system/contractshalfdonutchart.html",
        link: function ($scope, element) {
            var data = [2, 4, 8, 10];
            var width = 200,
                height = 200,
                radius = Math.min(width, height) / 2;
            var anglesRange = 0.5 * Math.PI;
            var color = d3.scaleOrdinal(['#4daf4a', '#377eb8', '#ff7f00', '#984ea3', '#e41a1c']);

            $timeout(function () {
                var svg = d3.select("#" + element[0].id)
                    .append("svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", `0 0 200 200`);
                var g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

                // Generate the pie
                var pie = d3.pie()
                    .startAngle(anglesRange * -1)
                    .endAngle(anglesRange);

                // Generate the arcs
                var arc = d3.arc()
                    .innerRadius(radius - 30)
                    .outerRadius(radius);

                var outerArc = d3.arc()
                    .innerRadius(radius * 0.9)
                    .outerRadius(radius * 0.9);

                //Generate groups
                var arcs = g.selectAll("arc")
                    .data(pie(data))
                    .enter()
                    .append("g")
                    .attr("class", "arc");

                //Draw arc paths
                arcs.append("path")
                    .attr("fill", function (d, i) {
                        return color(i);
                    })
                    .attr("d", arc);

                var string = "0.04%"
                g.append("text")
                    .attr("transform", function () {
                        var pos = (string.length * -3) + ", 0";
                        return "translate(" + pos + ")";
                    })
                    .text(function () { return string });

                svg.heigth = 0;
            }, 1);
        }
    }
});
