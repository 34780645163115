<div class="doc-mini"
     data-testid="doc-mini"
     (click)="openDoc($event)">
      <div class="state"
           data-testid="doc-mini-state"
           [style.background-color]="doc.stateColor">
      </div>
      <div class="time"
           data-testid="doc-mini-time">
            {{doc.time}}
      </div>
      <div class="desc"
           data-testid="doc-mini-desc">
            {{doc.desc}}
      </div>
</div>