export interface ScreenFieldFormattingModel {
  id?: number;
  label?: string;
  showColon?: boolean;
  size?: number;
  labelValueAlignment?: LabelValueAlignmentType;
  fontStyle: FontStyleType;
  fontSize: number;
  fontColor: string;
  alignHorizontal: HorizontalAlignType;
  alignVertical: VerticalAlignType;
}

export enum FontStyleType {
  None = 0,
  Bold = 1,
  Italic = 2,
  Underlined = 4,
  
  BoldItalic = Bold | Italic,
  BoldUnderlined = Bold | Underlined,
  ItalicUnderlined = Italic | Underlined,
  BoldItalicUnderlined = Bold | Italic | Underlined,
}

export enum HorizontalAlignType {
  Left = 1,
  Center = 2,
  Right = 3,
}

export enum VerticalAlignType {
  Bottom = 1,
  Center = 2,
  Top = 3,
}

export enum LabelValueAlignmentType {
  Split = 1,
  Join = 2,
  Vertical = 3,
}
