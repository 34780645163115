import { KeyValueModel } from '../../../../models/common';
import { FormFieldValueType, FieldDataSourceModel } from '../../../../models/module/fields/main';
import { CipoFieldTypes } from './fields.model';

export type MimeTypesDataSourceModel = KeyValueModel<number, string>;

export type FormattingsModel = {
  isCurrency?: boolean;
  suffix?: string;
  multiline?: boolean;
};

export type RestrictionModel = {
  readonly?: boolean;
  required?: boolean;
  decimals?: number;
  dateType?: 'date' | 'time' | 'dateTime';
  mimeTypes?: number[];
  mimeTypesDataSource?: MimeTypesDataSourceModel[];
  showThousandSeparator?: boolean;
  maxLength?: number;
};

export type MappedFieldModel = RestrictionModel &
  FormattingsModel & {
    id: number;
    type?: CipoFieldTypes;
    name?: string;
    label?: string;
    value?: FormFieldValueType;
    multiple?: boolean;
    dataSourceId?: number;
    dataSources?: FieldDataSourceModel[];
    mimeTypesDataSource?: MimeTypesDataSourceModel[];
    position?: {
      x: number;
      y: number;
      cols: number;
      rows: number;
    };
  };
