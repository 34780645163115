<mat-form-field class="search-field" appearance="fill">
    <mat-label>{{ "ACCESS_LOG.DOC_TABLE.SEARCH" | translate }}</mat-label>
    <input matInput type="text" [ngModel]="searchValue$.value" (keydown.enter)="search($any($event.target).value)" />
    <button *ngIf="searchValue$.value" matSuffix mat-icon-button aria-label="Clear" (click)="search()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<table matSort mat-table [dataSource]="dataSource$" class="cipo-table">
    <!-- user Column -->
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="user">{{ "ACCESS_LOG.DOC_TABLE.USER" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.user }}</td>
    </ng-container>

    <!-- state Column -->
    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="state">
            {{ "ACCESS_LOG.DOC_TABLE.STATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <span class="status" [ngStyle]="{ color: element.stateTextColor, 'background-color': element.stateColor }">
                {{ element.state }}
            </span>
        </td>
    </ng-container>

    <!-- access on Column -->
    <ng-container matColumnDef="accessOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="accessOn">
            {{ "ACCESS_LOG.DOC_TABLE.ACCESSON" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.accessOn | itvDateTime }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="access-log-element"></tr>
</table>

<mat-paginator
    [style.display]="totalCount && totalCount > pageSize ? '' : 'none'"
    [length]="totalCount"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"></mat-paginator>
