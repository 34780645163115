import { cipo } from 'cipo';

cipo.directive("fieldconfig", function (ModuleDictionaries, Dictionaries) {
    return {
        restrict: "E",
        scope: {
            "parent": "=",
            "operations": "=",
        },
        templateUrl: "/ng/views/directives/tabdetails/fieldconfig.html",
        controller: function ($scope) {
            //$scope.list = new List($scope.listdata);

            // Set this to true to not display all informations for formula field
            $scope.parent.currentField.properties.onlyFormula = true;
            $scope.parent.currentField.properties.moduleId = $scope.parent.module.id;

            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };

            $scope.checkNull = function (r) {
                if (!r.value) r.value = 0;
            }

            

            $scope.$watch(function () { return $scope.parent.currentField.properties.displayTypeId; }, function (n, o) {

                    $scope.dataSourcesDict = $scope.parent.currentField.properties.fieldOrigin == 2 ? ModuleDictionaries.dataSources.modules.data :
                        $scope.parent.currentField.properties.displayTypeId == 3 ? ModuleDictionaries.dataSources.selectsDataSources.data : ModuleDictionaries.dataSources.dataSources.data;
                    $scope.dataSourcesLookup = ModuleDictionaries.dataSources.selectsDataSources.lookup;
                    if (n && o && n != o && $scope.parent.currentField.properties.dataSourceId && $scope.dataSourcesLookup[$scope.parent.currentField.properties.dataSourceId].parentName) {
                        if ($scope.parent.currentField.properties.fieldOrigin != 2) {
                            $scope.parent.currentField.properties.dataSourceId = null;
                            $scope.parent.currentField.properties.multipleDefaultValue = [];
                            $scope.parent.currentField.properties.defaultValue = null;
                        }
                }
                    if($scope.parent.currentField.properties.typeId == 11) {
                        Dictionaries.ModuleFieldsByType({ type: 4, multiple: 0 }, { moduleId: $scope.parent.currentField.properties.moduleId })
                            .then(function (r) {
                                $scope.weatherDateDict = r;
                            })
                    }
                });

            // $scope.currentField = $scope.istable ? $scope.form.currentField.currentTableField : $scope.form.currentField;

            $scope.cancelField = function () {
                $scope.parent.currentField = null;
                if ($scope.parent.tableId) {
                    $('#tableFieldCreateWrapper').appendTo('#tableFieldCreateBase');
                    $('#tableFieldCreateWrapper').addClass('hide');
                    $scope.parent.getFields();

                } else {
                    if ($scope.parent.tableConfig) $scope.parent.tableConfig = null;
                }
                
            }

            $scope.saveField = function (field, cat) {
                $scope.parent.saveField(field, cat)
                    .then(function () {
                        if (!$scope.parent.currentField) $scope.cancelField();
                    })
                    .catch(function () {})
            }

            $scope.sortableOptions = {
                handle: '> span>.listHandle',
                'ui-floating': true
            }


            //$scope.createTableField = function (f, catDetails) {
            //    $('#tableFieldCreateWrapper').appendTo('body');
            //    $('#tableFieldCreateWrapper').removeClass('hide');
            //    $scope.form.createTableField(f, catDetails);
            //}

            $scope.$watch(function () { return $scope.parent.currentField; }, function (n, o) {
                
                if (n && $scope.parent.tableId) {
                    $('#tableFieldCreateWrapper').appendTo('body');
                    $('#tableFieldCreateWrapper').removeClass('hide');
                } 
            });
            
        }

    }
});
