import { cipo } from 'cipo';

cipo.controller('editTransitionScreenController',
    function ($scope, Message, MODAL, WorkflowScreen, URI, info, transition, contractId, entityInstanceId, selectedRoleId, dataURL, isPayment, $q, $mdDialog, userService) {

        $scope.modalInfo = MODAL;
        $scope.transition = transition;
        $scope.disabledBtn = false;
        $scope.actionBtnDisabled = false;
        $scope.screen = new WorkflowScreen({contractId: contractId});
        $scope.screen.rolesDict = info.rolesDict;

        Object.defineProperty($scope, 'nonBindingSignature', {
            get: function () {
                return (userService.system.userdata.signature || {}).signatureText }
        });

        $scope.screen.data = {
            fields: info.fields,
            emails: info.emails,
            roleToExcludeIds: info.roleToExcludeIds,
            emailsDataSource: info.emailsDataSource,
            manualAssignUsers: info.manualAssignUsers || [],
            isEmailAction: info.isEmailAction || false,
            hideRolesNotify: info.hideRolesNotify || false,
            requiresNonBindingSignature: info.requiresNonBindingSignature || false,
            hasFields: info.hasFields || false,
            includeAttachments: ((info.hasFields || false) || self.isStartScreen) && (info.hideRolesNotify || false)
        };
        if ($scope.screen.data.emailsDataSource && $scope.screen.data.emailsDataSource.length) {
            $scope.screen.setupNotificationForm();
        }
        if ($scope.screen.rolesDict.length) {
            $scope.screen.setupExclusionForm(selectedRoleId);
        }
        if ($scope.screen.data.fields && $scope.screen.data.fields.length) {
            $scope.screen.setupForm();
        }

        $scope.revertTabInfo = function () {
            $mdDialog.cancel();
        };

        $scope.updateTabInfo = function () {
            $scope.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $mdDialog.hide();
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;
        };

        $scope.save = function () {
            var p = $q.defer();
            var greenLight = false;
            if ($scope.screen.form) {
                $scope.screen.form.validate();
                greenLight = $scope.screen.form.isValid;
            } else greenLight = true;
            if (greenLight) {
                $scope.disabledBtn = true;
                $scope.tabWarning = false;
                $scope.hightlight = false;
                $scope.message = "Transition started...";
                if ($scope.screen.form) 
                    $scope.screen.form.loading = true;
                else if ($scope.screen.exclusionForm) 
                    $scope.screen.exclusionForm.loading = true;

                var params = { entityInstanceId: entityInstanceId, transitionId: transition.id, contractId: contractId, roleId: info.roleId, workflowId: userService.system.workflowId };

                if (transition.isComment) params.actionId = transition.id;
                else params.transitionId = transition.id;

                    if (isPayment) params.paymentPeriodId = 0;
                    if (info.saveParams) {
                        for (var key in info.saveParams) {
                            if (info.saveParams.hasOwnProperty(key)) {
                                params[key] = info.saveParams[key];
                            }
                        }
                    }
                    $scope.screen[dataURL.method](dataURL, {
                        url: params, urltype: 'obj',
                        body: {
                            values: $scope.screen.valuesDictionary,
                            emails: $scope.screen.emails.emails.filter((e) => !Number(e)),
                            groups: $scope.screen.emails.emails.filter((e) => Number(e)),
                            includeAttachments: $scope.screen.emails.includeAttachments,
                            roleToExcludeIds: $scope.screen.roleToExcludeIds.roleToExcludeIds,
                            manualAssignUser: $scope.screen.roleToExcludeIds.assignee ? $scope.screen.manualAssignUsersLookup[$scope.screen.roleToExcludeIds.assignee] : null
                        }
                    })
                        .then(function (r) {
                            Message.info('Transition successful');
                            p.resolve(r);
                            $mdDialog.hide(r);
                        })
                        .catch(function (e) {
                            console.error(e);
                            p.reject(e);
                            if ($scope.screen.form) $scope.screen.form.catch(e);
                            else $scope.screen.exclusionForm.catch(e);

                    })
                    .finally(function () {
                        if ($scope.screen.form) 
                            $scope.screen.form.loading = false;
                        else if ($scope.screen.exclusionForm)
                            $scope.screen.exclusionForm.loading = false;
                        $scope.disabledBtn = false;
                        $scope.message = "";
                    });
            }
            else {
                p.reject();
            }
            
            return p.promise;
        };

        $scope.close = function () {
            if (!$scope.screen.form || ($scope.screen.form && !$scope.screen.form.dirty))
                $mdDialog.cancel();
            else {
                $scope.hightlight = true;
                $scope.tabWarning = true;
            }
        };

        $scope.$watch(function () {
            return userService.system.userdata.signature;
        },
        function (n, o) {
            // if we didn't have a signature, but now we have
            if (n && !o && !$scope.screen.form && (!$scope.screen.notificationForm || $scope.screen.data.hideRolesNotify)) {
                $scope.save();
            }
        });
    });
