import { cipo } from 'cipo';

cipo.controller('editSovWbsController',
    function ($scope, Message, MODAL, Name, contractId, sovEntityInstanceId, $q, $mdDialog, $http, URI) {
        $scope.modalInfo = MODAL;
        $scope.loading = true;
        $scope.disabledBtn = true;
        $scope.actionBtnDisabled = false;
        $scope.contractId = contractId;
        $scope.sovEntityInstanceId = sovEntityInstanceId;
        $scope.elements = [];
        $scope.isModified = false;
        $scope.treeConfig = {
            beforeDrop: function (e) {
                return true;
            },
            dropped: function (e) {
                $scope.syncPositioning(e);
            }
        };

        // Expand/Collapse
        // $scope.expandAll = true;
        // $scope.expandCollapseLabel = 'Collapse All';
        // $scope.toggleExpandCollapse = function () {
        //     $scope.expandAll = !$scope.expandAll;
        //     $scope.expandCollapseLabel = $scope.expandAll
        //         ? 'Collapse All'
        //         : 'Expand All';

        //     var event = `angular-ui-tree:${($scope.expandAll ? 'expand' : 'collapse')}-all`;
        //     $scope.$broadcast(event);
        // }
        $scope.toggle = function (scope) {
            scope.toggle();
        }

        // Init
        $http[URI.WBS.GET.method](`${URI.WBS.GET}?contractId=${$scope.contractId}&sovEntityInstanceId=${$scope.sovEntityInstanceId}`)
            .then(function (res) {
                $scope.elements = res.data;
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                $scope.loading = false;
                $scope.disabledBtn = false;
            });

        // Create
        $scope.newElement = null;
        $scope.toggleAddElement = function () {
            if ($scope.newElement) {
                $scope.newElement = null;
            } else {
                $scope.newElement = new Name({ name: "" });
                $scope.newElement.form.setTemplate('grid', [{ name: 100 }]);
            }
            $scope.disabledBtn = !$scope.disabledBtn;
        }

        $scope.addElement = function () {
            $scope.message = "Saving element...";
            $scope.newElement.form.loading = true;
            $scope.newElement.form.validate();
            if ($scope.newElement.form.isValid) {

                $http[URI.WBS.CREATE_NODE.method](`${URI.WBS.CREATE_NODE}?contractId=${$scope.contractId}&sovEntityInstanceId=${$scope.sovEntityInstanceId}`, $scope.newElement.properties)
                    .then(function (res) {
                        Message.info('Category saved successfully');
                        $scope.newElement.form.loading = false;
                        delete $scope.newElement.form;
                        $scope.newElement.properties.id = res.data;
                        $scope.elements.splice(0, 0, $scope.newElement.properties);
                        $scope.newElement = null;
                        $scope.disabledBtn = false;
                    })
                    .catch(function (e) {
                        console.error(e);
                        $scope.newElement.form.catch(e);

                    })
                    .finally(function () {
                        $scope.message = "";
                        if ($scope.newElement && $scope.newElement.form)
                            $scope.newElement.form.loading = false;
                    });
            }
            else {
                $scope.newElement.form.loading = false;
            }
        }

        // Update
        $scope.toggleRenameForm = function (element) {
            if (element.form) {
                delete element.form;
                $scope.disabledBtn = false;
            } else {
                $scope.disabledBtn = true;
                element.form = new Name({ name: element.name });
                element.form.form.setTemplate('grid', [{ name: 100 }]);
            }
        }

        $scope.renameElement = function (element) {
            $scope.message = "Updating element...";
            element.form.form.loading = true;

            element.form.form.validate();
            if (element.form.form.isValid) {

                var temp = angular.copy(element);
                temp.name = temp.form.properties.name;
                delete temp.form;
                $http[URI.WBS.RENAME_NODE.method](`${URI.WBS.RENAME_NODE}?contractId=${$scope.contractId}&id=${element.id}&name=${temp.name}`)
                    .then(function (res) {
                        Message.info('Element updated successfully');
                        element.name = temp.name;
                        delete element.form;

                        $scope.disabledBtn = false;
                        $scope.isModified = true;
                    })
                    .catch(function (e) {
                        console.error(e);
                        element.form.form.catch(e);
                        element.form.form.loading = false;

                    })
                    .finally(function () {
                        $scope.message = "";
                    });
            }
            else {
                element.form.form.loading = false;
            }
        }

        $scope.syncPositioning = function (e) {
            var uri = URI.WBS.SYNC_POSITIONING;
            $http[uri.method](`${uri}?contractId=${$scope.contractId}&sovEntityInstanceId=${$scope.sovEntityInstanceId}`, $scope.elements)
                .then(function () {
                    $scope.isModified = true;
                })
                .catch(function (e) {
                    console.error(e);
                    // Message.dberror(e);
                    Message.error('Failed to update WBS positioning');
                });
        }

        // Delete
        $scope.toggleDeleteForm = function (element) {
            element.deleteConfirm = !element.deleteConfirm;
            $scope.disabledBtn = !$scope.disabledBtn;
        }

        $scope.deleteElement = function (scope, element) {
            element.deleting = true;
            $scope.message = "Deleting element...";
            var uri = URI.WBS.DELETE_NODE;

            $http[uri.method](`${uri}?contractId=${$scope.contractId}&sovEntityInstanceId=${$scope.sovEntityInstanceId}&id=${element.id}`)
                .then(function (result) {
                    Message.info('Element deleted successfully');
                    scope.remove();
                    $scope.disabledBtn = false;
                })
                .catch(function (e) {
                    console.error(e);
                    element.deleting = false;
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.message = "";
                });
        }

        // Modal
        $scope.close = function () {
            $mdDialog.cancel($scope.isModified);
        };
    });
