import { FieldModel } from '../../../models/module/fields/main';
import { DisplayType, FieldTypeEnum } from '../fields/enums';

export const DataCardDisplayTypeValues = new Map<FieldTypeEnum, DisplayType[]>([
  [
    FieldTypeEnum.Number,
    [
      DisplayType.Text,
      DisplayType.IndicatorBasedOnValue,
      DisplayType.IndicatorUp,
      DisplayType.IndicatorDown,
      DisplayType.PercentDurationProgress,
      DisplayType.PercentAmountProgress,
    ],
  ],
]);

export interface DataCardCustomField extends FieldModel {
  multiple: boolean;
}

export const LineField: DataCardCustomField = {
  id: -71,
  typeId: FieldTypeEnum.Graphic,
  displayTypeId: DisplayType.Line,
  multiple: true,
} as DataCardCustomField;

export const DataCardCustomFields: DataCardCustomField[] = [LineField];
