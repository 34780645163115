<app-data-list-command (applyfilters)="filterTable($event)" [filters]="filters">
    @if (canCreate()) {
        <button mat-flat-button color="primary" (click)="addEditEntry()">
            <mat-icon>add</mat-icon>
            {{ "SCHEDULED_EMAILS.actions.addNew" | translate }}
        </button>
    }
    <app-data-list
        class="data-list"
        [loading]="isLoading"
        [tablecolumns]="tableColumns"
        [tabledata]="tableData"
        [tableoptions]="options"
        (paginationupdated)="updatePagination($event)"
        (actionselected)="performAction($event)"
        (rowclicked)="addEditEntry($event)"></app-data-list>
</app-data-list-command>
