import { Injectable } from "@angular/core";

@Injectable()
export class LegacyIcons {
    list: LegacyIcon[] = [
        {
            key: 0,
            name: "crop_din",
        },
        {
            key: 1,
            name: "people",
        },
        {
            key: 2,
            name: "settings",
        },
        {
            key: 3,
            name: "mail",
        },
        {
            key: 4,
            name: "place",
        },
        {
            key: 5,
            name: "flag",
        },
        {
            key: 6,
            name: "folder",
        },
        {
            key: 7,
            name: "folder_open",
        },
        {
            key: 8,
            name: "public",
        },
        {
            key: 9,
            name: "pets",
        },
        {
            key: 10,
            name: "monitor",
        },
        {
            key: 11,
            name: "image",
        },
        {
            key: 12,
            name: "contacts",
        },
        {
            key: 13,
            name: "grid_view",
        },
        {
            key: 14,
            name: "view_headline",
        },
        {
            key: 15,
            name: "person",
        },
        {
            key: 16,
            name: "trending_up",
        },
        {
            key: 17,
            name: "explore",
        },
        {
            key: 18,
            name: "account_tree",
        },
        {
            key: 19,
            name: "sensors",
        },
        {
            key: 20,
            name: "task_alt",
        },
        {
            key: 21,
            name: "fact_check",
        },
        {
            key: 22,
            name: "flare",
        },
        {
            key: 23,
            name: "admin_panel_settings",
        },
        {
            key: 24,
            name: "build",
        },
        {
            key: 25,
            name: "music_note",
        },
        {
            key: 26,
            name: "assignment_turned_in",
        },
        {
            key: 27,
            name: "support",
        },
        {
            key: 28,
            name: "adjust",
        },
        {
            key: 29,
            name: "nature",
        },
        {
            key: 30,
            name: "build_circle",
        },
        {
            key: 31,
            name: "flight",
        },
        {
            key: 32,
            name: "location_city",
        },
        {
            key: 33,
            name: "format_paint",
        },
        {
            key: 34,
            name: "assignment",
        },
        {
            key: 35,
            name: "newspaper",
        },
        {
            key: 36,
            name: "assignment_ind",
        },
        {
            key: 37,
            name: "integration_instructions",
        },
        {
            key: 38,
            name: "description",
        },
        {
            key: 39,
            name: "content_copy",
        },
        {
            key: 40,
            name: "view_agenda",
        },
        {
            key: 41,
            name: "assignment_late",
        },
        {
            key: 42,
            name: "watch_later",
        },
        {
            key: 43,
            name: "all_inclusive",
        },
        {
            key: 44,
            name: "send",
        },
        {
            key: 45,
            name: "star",
        },
        {
            key: 46,
            name: "request_quote",
        },
        {
            key: 47,
            name: "anchor",
        },
        {
            key: 48,
            name: "engineering",
        },
        {
            key: 49,
            name: "water_drop",
        },
        {
            key: 50,
            name: "apps",
        },
        {
            key: 51,
            name: "domain",
        },
        {
            key: 52,
            name: "workspaces",
        },
        {
            key: 53,
            name: "",
        },
        {
            key: 54,
            name: "verified_user",
        },
        {
            key: 55,
            name: "store",
        },
        {
            key: 56,
            name: "coffee",
        },
        {
            key: 57,
            name: "lock_open",
        },
        {
            key: 58,
            name: "lock",
        },
        {
            key: 59,
            name: "phonelink_lock",
        },
        {
            key: 60,
            name: "no_encryption",
        },
        {
            key: 61,
            name: "redeem",
        },
        {
            key: 62,
            name: "content_paste",
        },
        {
            key: 63,
            name: "leaderboard",
        },
        {
            key: 64,
            name: "palette",
        },
        {
            key: 65,
            name: "attach_file",
        },
        {
            key: 66,
            name: "sell",
        },
        {
            key: 67,
            name: "mail_lock",
        },
        {
            key: 68,
            name: "",
        },
        {
            key: 69,
            name: "",
        },
        {
            key: 70,
            name: "construction",
        },
        {
            key: 71,
            name: "phone",
        },
        {
            key: 72,
            name: "",
        },
        {
            key: 73,
            name: "account_balance",
        },
        {
            key: 74,
            name: "shopping_basket",
        },
        {
            key: 75,
            name: "attach_money",
        },
        {
            key: 76,
            name: "favorite",
        },
        {
            key: 77,
            name: "monitor_heart",
        },
        {
            key: 78,
            name: "link",
        },
        {
            key: 79,
            name: "",
        },
        {
            key: 80,
            name: "",
        },
        {
            key: 81,
            name: "hourglass_top",
        },
        {
            key: 82,
            name: "park",
        },
        {
            key: 83,
            name: "home",
        },
        {
            key: 84,
            name: "forklift",
        },
        {
            key: 85,
            name: "agriculture",
        },
        {
            key: 86,
            name: "local_shipping",
        },
        {
            key: 87,
            name: "notifications",
        },
        {
            key: 88,
            name: "work",
        },
        {
            key: 89,
            name: "timeline",
        },
        {
            key: 90,
            name: "assessment",
        },
        {
            key: 91,
            name: "pie_chart",
        },
        {
            key: 92,
            name: "sports_football",
        },
        {
            key: 93,
            name: "paid",
        },
        {
            key: 94,
            name: "article",
        },
        {
            key: 95,
            name: "calendar_month",
        },
        {
            key: 96,
            name: "",
        },
        {
            key: 97,
            name: "folder_special",
        },
        {
            key: 98,
            name: "",
        },
        {
            key: 99,
            name: "local_mall",
        },
        {
            key: 100,
            name: "",
        }
    ];

    byKey: Map<number, LegacyIcon> = new Map<number, LegacyIcon>();

    constructor() {
        this.init();
    }

    init() {
        for (var i = 0; i < this.list.length; i++) {
            this.byKey.set(this.list[i].key, this.list[i]);
        }
    }
}

export interface LegacyIcon {
    key: number;
    name: string;
    value?: string;
    isFavorite?: boolean;
}
