import {
  ScheduledEmail,
  ScheduledEmailModule,
  ScheduledEmailAdditionalRoles,
} from '../../module/instance/scheduled-emails/interfaces';

export const ALL_JOBS: ScheduledEmail[] = [
  {
    id: 15,
    createdOn: '2024-03-01T22:02:45.963',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job9',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 11,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 17,
    createdOn: '2024-03-01T22:38:14.18',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job10',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 12,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 21,
    createdOn: '2024-03-01T22:45:00.24',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job13',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 15,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 23,
    createdOn: '2024-03-01T22:57:19.537',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job14',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 16,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 24,
    createdOn: '2024-03-01T22:57:44.897',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job15',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 17,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 26,
    createdOn: '2024-03-01T22:58:31.9',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job16',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 18,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 27,
    createdOn: '2024-03-04T19:00:46.913',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job17',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 19,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 31,
    createdOn: '2024-03-04T22:28:01.167',
    isEnabled: true,
    scheduleFrequency: 1,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-06',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job7',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 20,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 33,
    createdOn: '2024-03-08T16:36:03.94',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job18',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 21,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
  {
    id: 34,
    createdOn: '2024-03-08T16:46:45',
    isEnabled: true,
    scheduleFrequency: 2,
    dayOfWeek: 1,
    timeOfDay: '17:40:00',
    startDate: '2024-03-29',
    sendTo: [2, 3],
    additionalRecipients: [7, 10, 143],
    moduleIds: [-3, 20],
    jobName: 'My fancy job19',
    description: 'fancy job description',
    jobType: 1,
    jobAttributes: {
      id: 22,
      subject: 'jjj',
      emailBody: 'dd',
    },
  },
];

export const MODULES: ScheduledEmailModule[] = [
  {
    key: -4,
    value: 'Schedule of Value',
  },
  {
    key: -3,
    value: 'Progress Payment',
  },
  {
    key: -2,
    value: 'Change Order',
  },
  {
    key: 9,
    value: 'Request for Information',
  },
  {
    key: 13,
    value: 'Inspection Report',
  },
  {
    key: 16,
    value: 'RFI Tracking',
  },
  {
    key: 17,
    value: 'Proposed Change Order',
  },
  {
    key: 18,
    value: 'Submittal',
  },
  {
    key: 21,
    value: 'PCTS-Load',
  },
  {
    key: 26,
    value: 'Prelim-Stop Notice',
  },
  {
    key: 29,
    value: 'Punch List',
  },
  {
    key: 44,
    value: 'SO EN-16/Acceptance Letters',
  },
  {
    key: 47,
    value: 'Dev Proposed Change Order',
  },
  {
    key: 48,
    value: 'Recycled Water Request for Information',
  },
  {
    key: 49,
    value: 'Sync Destination Folder',
  },
  {
    key: 50,
    value: 'version',
  },
  {
    key: 51,
    value: 'Regression 1',
  },
  {
    key: 52,
    value: 'weather1',
  },
  {
    key: 61,
    value: 'npcDEV',
  },
  {
    key: 64,
    value: 'Display types',
  },
  {
    key: 65,
    value: 'Display Type multiple data',
  },
  {
    key: 66,
    value: 'Display type formulas',
  },
];

export const ADDITIONAL_ROLES: ScheduledEmailAdditionalRoles[] = [
  {
    isUsed: false,
    isDisabled: false,
    key: 1,
    value: 'Admin',
    name: 'AD',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 3,
    value: 'Assistant General Manager',
    name: 'AGM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 150,
    value: 'CAPO',
    name: 'CAPO',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 156,
    value: 'CM Consultant',
    name: 'CMC',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 162,
    value: 'colt',
    name: 'co',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 124,
    value: 'Construction Administrator',
    name: 'CA',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 123,
    value: 'Construction Administrator Representative',
    name: 'CAR',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 145,
    value: 'Construction Project Administration Manager',
    name: 'CPAM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 144,
    value: 'Construction Project Administration Supervisor',
    name: 'CPAS',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 140,
    value: 'Contact Creator',
    name: 'CCR',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 133,
    value: 'Contract / Project Creator',
    name: 'CC',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 127,
    value: 'Contractors (SYS)',
    name: 'CT1',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 161,
    value: 'Default Permissions',
    name: 'DP',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 141,
    value: 'Deputy General Manager',
    name: 'DGM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 7,
    value: 'Design Consultant',
    name: 'DC',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 143,
    value: 'Dev Admin',
    name: 'DevA',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 159,
    value: 'DGM Secretary',
    name: 'DGMS',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 132,
    value: 'Director of Engineering',
    name: 'DE',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 122,
    value: 'Director of Field Engineering',
    name: 'DFE',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 136,
    value: 'eB Testing',
    name: 'EBS',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 155,
    value: 'Electrical Inspector',
    name: 'EINS',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 146,
    value: 'Electrical Supervisor',
    name: 'ES',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 126,
    value: 'EMWD (SYS)',
    name: 'emwd',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 131,
    value: 'Engineer',
    name: 'ENG',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 158,
    value: 'Engineering Services',
    name: 'Engs',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 149,
    value: 'Environmental & Regulatory Compliance',
    name: 'ERC',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 125,
    value: 'General Contractor',
    name: 'GC',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 10,
    value: 'General Manager',
    name: 'GM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 11,
    value: 'Geo Technician',
    name: 'GEO',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 157,
    value: 'GM Board Secretary',
    name: 'GMBS',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 138,
    value: 'Inspection Manager',
    name: 'INSM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 12,
    value: 'Inspector',
    name: 'INSP',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 135,
    value: 'Inspector Supervisor',
    name: 'SUPE',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 14,
    value: 'Principal Engineer',
    name: 'PE',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 139,
    value: 'Project Manager',
    name: 'PM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 148,
    value: 'Public and Government Affairs',
    name: 'PGA',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 153,
    value: 'Recycled Water Inspector',
    name: 'RWI',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 147,
    value: 'Safety, Risk and Emergency Management',
    name: 'SREM',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 151,
    value: 'Senior CA',
    name: 'SCA',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 152,
    value: 'Submittal Coordinator',
    name: 'SC',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 43,
    value: 'Viewer',
    name: 'VW',
  },
  {
    isUsed: false,
    isDisabled: false,
    key: 154,
    value: 'Water Ops Supervisor',
    name: 'WOS',
  },
];
