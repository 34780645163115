import { Component, EventEmitter, HostListener, Input, OnInit, Output, computed, effect, signal } from '@angular/core';
import FroalaEditor from 'froala-editor';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, filter, take } from 'rxjs';

// froala plugins
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
// froala third party plugins
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/js/third_party/spell_checker.min';
// tribute
import Tribute, { TributeCollection } from 'tributejs';

import { FROALA_IMAGE_OPTIONS } from './common/consts';
import { CipoFroalaOptions, CipoTributeOptions, TributeReplaceEvent } from './interfaces';
import { FroalaConfig } from './common';
import { UserService } from '../../services/user.service';
import { KeyValueType } from 'src/app/models/common';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-froala',
  templateUrl: './froala.component.html',
  styleUrls: ['./froala.component.scss'],
})
export class FroalaComponent implements OnInit {
  froalaOptions = FroalaConfig.basic;
  tribute: Tribute<KeyValueType>;
  tributeInitiliazed = false;
  isInitialValue = true;
  froalaElement: HTMLElement;
  //   save all events in this array and apply them on froala intialization
  froalaEvents: { name: string; callback: (...args) => void | boolean }[] = [];

  /*   Signal inputs
  editMode = input(true, { alias: 'editmode' });
  label = input('');
  hint = input('', {});
  required = input(false);
  invalid = computed(() => this.required() && !this.content() && !this.isInitialValue);
  options = input<CipoFroalaOptions, CipoFroalaOptions>(FroalaConfig.basic, {
    transform: (options: CipoFroalaOptions) => this.froalaOptions = { ...FroalaConfig.basic, ...options },
  });
  content = model('');
  tributeOptions = input<CipoTributeOptions>({});
  */

  editMode = signal(true);
  label = signal('');
  hint = signal('');
  required = signal(false);
  options = signal<CipoFroalaOptions>(FroalaConfig.basic);
  tributeOptions = signal<CipoTributeOptions>({});
  invalid = computed(() => this.required() && !this.content() && !this.isInitialValue);
  content = signal('');

  @Input('editmode')
  set _editMode(value: boolean) {
    this.editMode.set(value);
  }
  @Input('label')
  set _label(value: string) {
    this.label.set(value);
  }
  @Input('hint')
  set _hint(value: string) {
    this.hint.set(value);
  }
  @Input('required')
  set _required(value: boolean) {
    this.required.set(value);
  }
  @Input('content')
  set _content(value: string) {
    this.content.set(value);
  }
  @Input('options')
  set _options(value: CipoFroalaOptions) {
    this.froalaOptions = { ...this.froalaOptions, ...value };
  }
  @Input('tributeoptions')
  set _tributeoptions(value: CipoTributeOptions) {
    this.tributeOptions.set(value);
  }

  @HostListener('document:click')
  hideTributeMenuOnOutsideClick() {
    if (this.tribute?.isActive) {
      (this.tribute as any).hideMenu();
    }
  }

  @Output() contentChange = new EventEmitter<string>(); // for angular ngModel
  @Output() contentchange = new EventEmitter<string>(); // for angularJs

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
  ) {
    this.defineCustomIconTemplates();
    this.registerCustomCommands();
    this.initImageOptions();
    toObservable(this.tributeOptions)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.tributeOptions()) {
          if (this.tributeInitiliazed) {
            this.updateTributeCollections();
          } else {
            this.addTributeFunctionality();
          }
        }
      });
    effect(() => {
      this.contentChange.emit(this.content());
      this.contentchange.emit(this.content());
    });
    toObservable(this.content)
      .pipe(
        filter(val => !!val),
        debounceTime(100),
        take(1),
      )
      .subscribe(() => {
        this.isInitialValue = false;
        if (this.froalaElement) {
          this.validateVariablesAndMentions(this.froalaElement);
        }
      });
  }

  ngOnInit(): void {
    this.addEventsInitialized();
  }

  defineCustomIconTemplates() {
    FroalaEditor.DefineIconTemplate('mat-icons', '<span class="material-icons">[NAME]</span>');
    FroalaEditor.DefineIconTemplate('mat-icons-outlined', '<span class="material-icons-outlined">[NAME]</span>');
    FroalaEditor.DefineIconTemplate('mat-symbols', '<span class="material-symbols">[NAME]</span>');
    FroalaEditor.DefineIconTemplate('mat-symbols-outlined', '<span class="material-symbols-outlined">[NAME]</span>');
  }

  registerCustomCommands() {
    const froalaShapes = {
      'circle.jpg': 'Circle',
      'square.jpg': 'Square',
      'triangle.jpg': 'Triangle',
      'torque-sequencing.jpg': 'Torque Sequencing',
    };

    FroalaEditor.DefineIcon('shapes', { template: 'mat-icons-outlined', NAME: 'extension' });
    FroalaEditor.RegisterCommand('shapes', {
      title: 'Shapes',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      options: froalaShapes,
      callback: function (_, filename) {
        const editor = this;
        const link = `${window.location.origin}/Content/images/shapes/${filename}`;
        editor.html.insert(`<img src="${link}" />`);
      },
    });

    FroalaEditor.RegisterCommand('print', {
      title: 'Print',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: function () {
        const printSection = document.getElementById('section-to-print');
        if (printSection) {
          document.body.removeChild(printSection);
        }

        const div = document.createElement('div');
        div.id = 'section-to-print';
        div.className = 'fr-view display--none';
        div.innerHTML = this.html.get();
        document.body.append(div);

        setTimeout(function () {
          window.print();
        }, 400);
      },
    });
  }

  initImageOptions() {
    this.froalaOptions = {
      requestHeaders: this.userService.getUserHeaders(),
      ...FROALA_IMAGE_OPTIONS,
      ...this.froalaOptions,
    };
  }

  validateVariablesAndMentions(targetElement?: HTMLElement) {
    if (!(this.content()?.includes('class="parse-variables') || this.content()?.includes('class="tag-user"'))) {
      return;
    }
    const { variables, mentions } = this.tributeOptions();

    if (variables && Object.keys(variables).length >= 0) {
      const variablesSpans = targetElement.querySelectorAll<HTMLElement>('.parse-variables');
      variablesSpans.forEach(span => {
        const key = span.getAttribute('id');
        if (!variables[key]) {
          span.removeAttribute('id');
          span.className = 'cipo-bg--warn-100';
          span.innerHTML = `{{ ${span.lastChild.textContent} }}`;
        } else if (variables[key] !== span.lastChild.textContent) {
          span.lastChild.textContent = variables[key];
        }
        this.handleSelectOnClick(span);
      });
    }

    if (mentions && Object.keys(mentions).length >= 0) {
      const mentionsSpans = targetElement.querySelectorAll('.tag-user');
      mentionsSpans.forEach((span: HTMLElement) => {
        const key = span.getAttribute('id');
        if (!mentions[key]) {
          span.removeAttribute('id');
          span.innerHTML = `@${span.textContent}`;
          span.className = 'cipo-bg--warn-100';
        } else if (mentions[key] !== span.textContent) {
          span.innerHTML = mentions[key];
        }
        this.handleSelectOnClick(span);
      });
    }
  }

  handleSelectOnClick(span: HTMLElement) {
    if (!span) {
      return;
    }
    span.addEventListener('click', () => {
      const range = document.createRange();
      range.selectNode(span);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    });
  }

  addTributeFunctionality() {
    const { mentions, variables } = this.tributeOptions();
    const collections: TributeCollection<KeyValueType>[] = [];

    if (variables) {
      collections.push({
        values: this.utilsService.mapObjectToArray(variables),
        trigger: '#',
        selectTemplate: ({ original: { key, value } }) =>
          // moved view span as last one, because backspace removes characters from the last inserted key/value, wich in our case was printing key.
          `<span class="parse-variables" id="${key}"><span class="print">{{${key}}}</span><span class="no-print">${value}</span></span>`,
        menuItemTemplate: item => item.original.value,
        lookup: this.customTributeSearch,
      });
    }

    if (mentions) {
      collections.push({
        values: this.utilsService.mapObjectToArray(mentions),
        trigger: '@',
        selectTemplate: item => `<span class="tag-user" id=${item.original.key}>${item.original.value}</span>`,
        menuItemTemplate: item => item.original.value,
        lookup: this.customTributeSearch,
      });
    }

    if (collections.length) {
      this.initTribute(collections);
    }
  }

  customTributeSearch(item: KeyValueType) {
    return item.value.replaceAll(' ', '');
  }

  initTribute(collection: TributeCollection<KeyValueType>[]) {
    this.tribute = new Tribute({ collection });
    this.tributeInitiliazed = true;

    this.froalaEvents.push(
      {
        name: 'keydown',
        callback: (event: KeyboardEvent) => {
          if (event.key === 'Enter' && this.tribute.isActive) {
            return false;
          }
        },
      },
      {
        name: 'contentChanged',
        callback: () => {
          this.froalaElement.querySelectorAll('.parse-variables:not(:has(.no-print))').forEach(span => {
            span.remove();
          });
          this.froalaElement.querySelectorAll('font').forEach(tag => {
            tag.remove();
          });
        },
      },
    );
  }

  updateTributeCollections() {
    const tributeCollections = (this.tribute as any).collection as TributeCollection<KeyValueType>[];
    const { mentions, variables } = this.tributeOptions();
    tributeCollections.forEach(collection => {
      if (collection.trigger === '#' && variables) {
        collection.values = this.utilsService.mapObjectToArray(variables);
      } else if (collection.trigger === '@' && mentions) {
        collection.values = this.utilsService.mapObjectToArray(mentions);
      }
    });
  }

  addEventsInitialized() {
    const thisRef = this;
    this.froalaOptions.events = {
      initialized: function () {
        thisRef.froalaElement = this.el;
        if (thisRef.tribute) {
          thisRef.tribute.attach(this.el);
          thisRef.validateVariablesAndMentions(this.el);
          this.el.addEventListener('tribute-replaced', ({ target, detail: { item } }: TributeReplaceEvent) => {
            // escape special characters from id
            const id = item.original.key.replace(/([^\w\d]|\\|:|\.|\[|\]|\(|\)|\+|>|~|,|^|\|)/g, '\\$1');
            thisRef.handleSelectOnClick(target.querySelector(`#${id}`));
          });
        }
        /* 
            populate this array with events that are needed to be triggered before others
            Documentation: first - Trigger the callback before already registered callbacks.
        */
        const firstEvents = ['keydown'];
        thisRef.froalaEvents.forEach(event => {
          const isFirstEvent = firstEvents.includes(event.name);
          this.events.on(event.name, event.callback, isFirstEvent);
        });

        this.events.on('commands.after', cmd => {
          if (cmd === 'html') {
            // when switching back from view code, validate variables and mentions
            thisRef.validateVariablesAndMentions(this.el);
          }
        });
      },
    };
  }
}
