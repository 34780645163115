import { cipo } from 'cipo';

cipo.factory("TableMod", function (Model, Screen, $q,  URI, Message) {
    var TableMod = Screen.extend(function (obj) {
        var self = this;
        self.isTable = true;
        self.options = {
            dropped: function (e) {
                self.processDrop(e);
                return true;
            }
        };
        self.properties = {};
        if (obj) {
            for (var key in obj)
                if (obj.hasOwnProperty(key))
                    self.properties[key] = obj[key];
        }
        self.set_fields();
        
    });

    TableMod.prototype.set_fields = function () {
        var self = this;
        self.get_propFields({ url: { entityId: 0 }, urltype: 'obj' }, { headers: { moduleId: self.properties.dataSource } })
            .then(function () {
                if (self.propFields && self.propFields.length) {
                    for (var i = 0; i < self.propFields.length; i++) {
                        if (!self.propFields[i].properties.width) self.propFields[i].properties.width = 1;
                    }

                    self.set_fieldsWidth();
                }
            })
    }

    TableMod.prototype.saveField = function () {
        var self = this;
        self.editFieldForm.validate();
        if (self.editFieldForm.isValid) {
            self.isBusy = true;
            self.currentField.label = self.currentField.name;
            if (!self.currentField.width) self.currentField.width = 1;
            if (!self.currentField.position) self.currentField.position = self.propFields.length+1;
            // if select
            if (self.currentField.dataSourceComposed) {
                var splits = self.currentField.dataSourceComposed.split('|');
                self.currentField.dataSource = splits[0];
                self.currentField.dataSourceType = splits[1];
            }
            var dataURL = self.currentField.fieldId ? URI.SCREENS.FIELD_UPDATE : URI.SCREENS.FIELD_CREATE;
            self[dataURL.method](dataURL, { body: self.currentField }, { headers: { moduleId: self.properties.dataSource } })
                .then(function (r) {
                    self.currentField = null;
                    self.set_fields();
                })
                .catch(function (e) {
                    console.error(e);
                    self.editFieldForm.catch(e);
                })
                .finally(function () { self.isBusy = false;})
        }
    }
    TableMod.prototype.deleteField = function (field) {
        var self = this;
        //self.isBusy = true;
        field.isDeleting = true;
        field.deletingMsg = "Deleting field...";
        var dataURL = URI.SCREENS.FIELD_DELETE;
        self[dataURL.method](dataURL, { url: { id: field.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.properties.dataSource } })
            .then(function (r) {
                self.propFields.splice(self.propFields.indexOf(field), 1);
                self.set_fields();
                self.isBusy = false;
                Message.info("Field deleted successfully");
            })
            .catch(function (e) {
                field.deletingMsg = "Deleting field failed. Try again?";
                Message.dberror(e);
            })
            .finally(function () {
                
            })
    }
    TableMod.prototype.processDrop = function (e) {
        var self = this;
        // e.source.nodeScope.$modelValue
        var fields = e.dest.nodesScope.$modelValue;
        //self.set_fieldsWidth(fields);
        self.isBusy = true;
        self.sync();
        //self.get_List();
    }

    TableMod.prototype.colSize = function (item, isIncrease) {
        var self = this;
        if (isIncrease) item.properties.width++;
        else item.properties.width--;
        self.set_fieldsWidth();
        self.sync();
    }

    TableMod.prototype.set_fieldsWidth = function () {
        var self = this;
        var parts = 0;
        // var fields = fields || self.inGrid.list;
        for (var i = 0; i < self.propFields.length; i++) {
            parts += self.propFields[i].properties.width;
        }
        for (var i = 0; i < self.propFields.length; i++) {
            self.propFields[i].widthPercentage = 100 / parts * self.propFields[i].properties.width;
        }
        // console.error('fields', self.propFields)

    }//
    TableMod.prototype.toggleDelete = function (item) {
        var self = this;
        item.deleteConfirm = !item.deleteConfirm;
        self.isBusy = !self.isBusy;
    }
    TableMod.prototype.sync = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.SCREENS.SYNC;
        self.updateMessage = "Updating form...";
        self.isBusy = true;
        var processedFields = [];
        if (self.propFields.length) {
            for (var i = 0; i < self.propFields.length; i++) {
                self.propFields[i].properties.position = i + 1;
                processedFields.push(self.propFields[i].properties);
                
            }
        }
        self[urlData.method](urlData, { body: processedFields }, { headers: { moduleId: self.properties.dataSource } })
            .then(function (result) {
                Message.info('Form updated successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject("update", e);
                Message.dberror(e);
            })
            .finally(function () {
                self.updateMessage = "";
                self.isBusy = false;
            })
        return p.promise;
    }

    return TableMod;
});
