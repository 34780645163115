<mat-card class="cipo-card">
    <mat-card-title *ngIf="showDesc">{{ desc }}</mat-card-title>
    <mat-card-content>
        <form [formGroup]="settingsForm">
            <div>
                <mat-form-field *ngIf="showLabelInput" class="cipo-input cipo-input--sm">
                    <input matInput formControlName="label" />
                    <mat-error *ngIf="labelControl.invalid">
                        {{ "MODULE_DEF_CARD.PART_DEFINITION.ERROR_LABEL_REQUIRED" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutAlign="start center">
                <mat-checkbox
                    formControlName="showColon"
                    *ngIf="showColonSetting"
                    class="cipo-bg--white part-container mat-button-toggle-group-appearance-standard"
                    [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.SHOW_COLON' | translate">
                    {{ "MODULE_DEF_CARD.PART_DEFINITION.SHOW_COLON" | translate }}
                </mat-checkbox>
                <mat-button-toggle-group *ngIf="showLabelValueAlignment" formControlName="labelValueAlignment">
                    <mat-button-toggle
                        [value]="1"
                        [attr.aria-label]="
                            'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VALUE_ALIGNMENT_HORIZONTAL_SPLIT' | translate
                        "
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VALUE_ALIGNMENT_HORIZONTAL_SPLIT' | translate">
                        <mat-icon class="material-icons-outlined" style="transform: rotate(90deg)">
                            sensor_window
                        </mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="2"
                        [attr.aria-label]="
                            'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VALUE_ALIGNMENT_HORIZONTAL_JOIN' | translate
                        "
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VALUE_ALIGNMENT_HORIZONTAL_JOIN' | translate">
                        <mat-icon>crop_landscape</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="3"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VALUE_ALIGNMENT_VERTICAL' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VALUE_ALIGNMENT_VERTICAL' | translate">
                        <mat-icon>crop_portrait</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-button-toggle-group *ngIf="showSizeSetting" formControlName="size">
                    <mat-button-toggle
                        *ngFor="let partSize of partSizes"
                        [value]="partSize"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_SIZE' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_SIZE' | translate">
                        <span style="font-weight: 400">{{ partSize }}%</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <div
                    *ngIf="showFontColor"
                    fxLayoutAlign="center center"
                    class="cipo-bg--white part-container mat-button-toggle-group-appearance-standard">
                    <input
                        matInput
                        type="color"
                        formControlName="fontColor"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_COLOR' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_COLOR' | translate" />
                </div>
            </div>
            <div>
                <mat-button-toggle-group *ngIf="showFontStyle" formControlName="fontStyle" multiple>
                    <mat-button-toggle
                        [value]="1"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_BOLD_TEXT_STYLE' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_BOLD_TEXT_STYLE' | translate">
                        <mat-icon>format_bold</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="2"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_ITALIC_TEXT_STYLE' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_ITALIC_TEXT_STYLE' | translate">
                        <mat-icon>format_italic</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="4"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_UNDERLINED_TEXT_STYLE' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_UNDERLINED_TEXT_STYLE' | translate">
                        <mat-icon>format_underlined</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-button-toggle-group *ngIf="showFontSize" formControlName="fontSize">
                    <mat-button-toggle
                        *ngFor="let textSize of textSizes"
                        [value]="textSize"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_TEXT_SIZE' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_TEXT_SIZE' | translate">
                        <span style="font-weight: 400" [style.fontSize]="getTextSizeToPercent(textSize)">
                            T{{ textSize }}
                        </span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-button-toggle-group *ngIf="showAlignHorizontal" formControlName="alignHorizontal">
                    <mat-button-toggle
                        [value]="1"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_HORIZONTALLY_ALIGN_LEFT' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_HORIZONTALLY_ALIGN_LEFT' | translate">
                        <mat-icon>format_align_left</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="2"
                        [attr.aria-label]="
                            'MODULE_DEF_CARD.PART_DEFINITION.LABEL_HORIZONTALLY_ALIGN_CENTER' | translate
                        "
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_HORIZONTALLY_ALIGN_CENTER' | translate">
                        <mat-icon>format_align_center</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="3"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_HORIZONTALLY_ALIGN_RIGHT' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_HORIZONTALLY_ALIGN_RIGHT' | translate">
                        <mat-icon>format_align_right</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-button-toggle-group *ngIf="showAlignVertical" formControlName="alignVertical">
                    <mat-button-toggle
                        [value]="1"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VERTICALLY_ALIGN_BOTTOM' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VERTICALLY_ALIGN_BOTTOM' | translate">
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="2"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VERTICALLY_ALIGN_CENTER' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VERTICALLY_ALIGN_CENTER' | translate">
                        <mat-icon>vertical_align_center</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle
                        [value]="3"
                        [attr.aria-label]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VERTICALLY_ALIGN_TOP' | translate"
                        [title]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL_VERTICALLY_ALIGN_TOP' | translate">
                        <mat-icon>vertical_align_top</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </form>
    </mat-card-content>
</mat-card>
