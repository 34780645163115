import { FroalaConfig } from 'src/app/shared/components/froala/common/froala.config';
import { CipoFroalaOptions } from '../interfaces';
import { environment } from 'src/environments/environment';

export const FROALA_TOOLBAR_BIG: CipoFroalaOptions = {
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontFamily',
        'fontSize',
        'lineHeight',
        'textColor',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
      ],
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'outdent',
        'indent',
        'quote',
      ],
    },
    moreRich: {
      buttons: ['insertLink', 'insertTable', 'fontAwesome', 'specialCharacters', 'insertHR'],
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'shapes', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html'],
    },
  },
  toolbarButtonsXS: {
    moreText: {
      buttons: ['undo', 'redo'],
    },
    moreParagraph: {
      buttons: ['bold', 'italic', 'underline'],
    },
  },
};
export const FROALA_TOOLBAR_MEDIUM: CipoFroalaOptions = {
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'outdent',
        'indent',
        'quote',
      ],
    },
    moreRich: {
      buttons: ['insertLink', 'insertTable', 'fontAwesome', 'specialCharacters', 'insertHR', 'shapes'],
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html'],
      align: 'right',
    },
  },
  toolbarButtonsXS: {
    moreText: {
      buttons: ['undo', 'redo'],
    },
    moreParagraph: {
      buttons: ['bold', 'italic', 'underline'],
    },
  },
};
export const FROALA_TOOLBAR_SMALL: CipoFroalaOptions = {
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'fontSize',
        'textColor',
        'shapes',
        'fullscreen',
        'strikeThrough',
        'fontFamily',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'outdent',
        'indent',
        'quote',
        'insertLink',
        'insertTable',
        'fontAwesome',
        'specialCharacters',
        'insertHR',
        'undo',
        'redo',
        'print',
        'spellChecker',
        'selectAll',
        'html',
      ],
      align: 'left',
      buttonsVisible: 7,
    },
  },
  toolbarButtonsXS: {
    moreText: {
      buttons: ['undo', 'redo'],
    },
    moreParagraph: {
      buttons: ['bold', 'italic', 'underline'],
    },
  },
} as const;

export const FROALA_TABLE_STYLES: typeof FroalaConfig.basic.tableStyles = {
  'fr-alternate-rows': 'Alternate rows',
  'fr-dashed-borders': 'Dashed border',
  'fr-border-solid': 'Solid border',
  'fr-thick': 'Thick border',
  'fr-thin': 'Thin border',
  'fr-border-all': 'Borders all',
  'fr-border-bottom': 'Borders bottom',
  'fr-border-top': 'Borders top',
  'fr-border-left': 'Borders left',
  'fr-border-right': 'Borders right',
};

export const FROALA_TABLE_CELL_STYLES = {
  'fr-no-borders': 'No borders',
  'fr-dashed-borders': 'Dashed border',
  'fr-border-solid': 'Solid border',
  'fr-thick': 'Thick border',
  'fr-thin': 'Thin border',
  'fr-border-bottom': 'Border bottom',
  'fr-border-top': 'Border top',
  'fr-border-left': 'Border left',
  'fr-border-right': 'Border right',
};

export const FROALA_IMAGE_OPTIONS: CipoFroalaOptions = {
  imageUploadURL: `${environment.baseApiUrl}_api/FolderFile/UploadRichtextImage`,
  imageUploadMethod: 'put',
  imageMaxSize: 5 * 1024 * 1024, //5MB,
  imageAllowedTypes: ['jpeg', 'jpg', 'png'],
};

export const FROALA_FONTS = {
  'Calibri, sans-serif': 'Calibri',
  'Roboto,sans-serif': 'Roboto',
  "'Nunito Sans',sans-serif": 'Nunito Sans',
  'Muli,sans-serif': 'Muli',
  'Poppins,sans-serif': 'Poppins',
  "'Open Sans',sans-serif": 'Open Sans',
};
