import { cipo } from 'cipo';

cipo.directive("previewtableformulas", function () {
    return {
        restrict: "E",
        scope: {
            "fieldslist": "=",
            //"percontract": "="
        },
        templateUrl: "/ng/views/directives/dynamics/wfentityinstance.html",
        controller: function ($scope, ACTIONS) {
            // $scope.list = $scope.options.listKey ? $scope.parent[$scope.options.listKey] : [];

            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $mdMenu.open(ev);
            };

        }

    }
});
