import { cipo } from 'cipo';

cipo.directive("contractslineplot", function ($window, URI, userService, $http, Message, $timeout) {
    return {
        restrict: "EA",
        scope: {
        },
        templateUrl: "/ng/views/directives/system/contractslineplot.html",
        link: function ($scope, element) {

            var margin = { top: 50, right: 50, bottom: 50, left: 50 };
            var width = 200;
            var height = 200;
            $timeout(function () {
                //def svg
                var svg = d3.select("#" + element[0].id)
                    .append("svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", `0 0 200 200`);
                var g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

                var n = 21;

                // 5. X scale will use the index of our data
                var xScale = d3.scaleLinear()
                    .domain([0, n - 1]) // input
                    .range([0, width]); // output

                // 6. Y scale will use the randomly generate number 
                var yScale = d3.scaleLinear()
                    .domain([0, 1]) // input 
                    .range([height, 0]); // output 

                // 7. d3's line generator
                var line = d3.line()
                    .x(function (d, i) { return xScale(i); }) // set the x values for the line generator
                    .y(function (d) { return yScale(d.y); }) // set the y values for the line generator 

                // 8. An array of objects of length N. Each object has key -> value pair, the key being "y" and the value is a random number
                var dataset = d3.range(n).map(function (d) { return { "y": d3.randomUniform(1)() } })
                var dataset2 = d3.range(n).map(function (d) { return { "y": d3.randomUniform(1)() } })

                // 9. Append the path, bind the data, and call the line generator 
                svg.append("path")
                    .datum(dataset) // 10. Binds data to the line 
                    .attr("class", "line") // Assign a class for styling 
                    .attr("d", line); // 11. Calls the line generator 

                svg.append("path")
                    .datum(dataset2) // 10. Binds data to the line 
                    .attr("class", "line2") // Assign a class for styling 
                    .attr("d", line); // 11. Calls the line generator 

            }, 1);
        }
    }
});
