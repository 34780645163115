import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { AddressResult } from "../models/address-result.model";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn:'root'
})
export class AddressService {

    private addressServiceUrl = `https://atlas.microsoft.com/search/address/json?&subscription-key=${environment.azureMapsSubscriptionKey}&api-version=1.0&language=en-US`;
    private reverseGeocodeUrl = `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=${environment.azureMapsSubscriptionKey}`;


    constructor(
        private httpClient: HttpClient
    ) {}

    getAddressSuggestions(query: string): Observable<AddressResult[]> {
        return this.httpClient.get<any>(`${this.addressServiceUrl}&query=${query}`).pipe(map(x => x.results))
    }

    getAddressFromCoordinates(latitude: number, longitude: number): Observable<any> {
        return this.httpClient.get<any>(`${this.reverseGeocodeUrl}&query=${latitude},${longitude}`);
    }
}