import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numKeyValue',
})
export class KeyValuePipe implements PipeTransform {
  /**
   * Gets an object of type { [key: number]: string }
   *
   * and returns an array of type { key: number, value: string }[].
   *
   * If your key contains non-numeric chars, use built-in keyvalue pipe
   *
   * @param {{ [key: number]: string }} value
   * @returns {{ key: number; value: string }[]}
   */
  transform(value: { [key: number]: string }): { key: number; value: string }[] {
    return Object.entries(value).map(([key, value]) => ({ key: parseInt(key), value }));
  }
}
