@if (row()["empty"]) {
    <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px' }"></ngx-skeleton-loader>
} @else {
    @if (row()[column().name] === null || row()[column().name] === undefined) {
        {{ noDataPlaceholder() }}
    } @else {
        @switch (column().displayFormatId) {
            @case (displayFormat.YesNo) {
                <p>{{ "general.boolValue." + row()[column().name] | translate }}</p>
            }
            @case (displayFormat.Status) {
                <p
                    class="text--ellipsed border-radius--xs text--center m-l-0"
                    [title]="row()[column().name]['label']"
                    [ngStyle]="{
                        backgroundColor: row()[column().name]['bgColor'],
                        color: row()[column().name]['color'],
                        maxWidth: '150px'
                    }">
                    {{ row()[column().name]["label"] | uppercase }}
                </p>
            }
            @case (displayFormat.Date) {
                <p
                    class="text--ellipsed"
                    [title]="row()[column().name] | itvDateTimeWithRestrictions: column().restrictions">
                    {{ row()[column().name] | itvDateTimeWithRestrictions: column().restrictions }}
                </p>
            }
            @case (displayFormat.Image) {
                @if (row()[column().name].type === "image") {
                    <img [src]="row()[column().name] | imageSource" [class]="row()[column().name].class" />
                } @else {
                    <mat-icon [class]="row()[column().name].class">{{ row()[column().name].iconKey }}</mat-icon>
                }
            }
            @case (displayFormat.Number) {}
            @default {
                <p class="text--ellipsed" [title]="row()[column().name]">
                    {{ row()[column().name] }}
                </p>
            }
        }
    }
}
