import { cipo } from 'cipo';

cipo.directive("formObject", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "formdata": "="
        },
        templateUrl: "/ng/views/directives/system/formobject.html",
        controller: function ($scope, $timeout) {
            $scope.clearFieldErrors = function (evt, field) {
                if (!field.isValid) {
                    $timeout(function () {
                        $scope.$apply(function () {
                            $scope.formdata.clearFieldErrors(field);
                        });
                    });
                }
            }
        }
    }
});
