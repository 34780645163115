import { Component, input, signal } from '@angular/core';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

import { CipoFormModule } from '../../../modules/forms.module';
import { CipoDateTimeConfig, CipoDateTimeControl } from '../common';
import { CipoTextViewComponent } from '../cipo-text-view/cipo-text-view.component';
import { DATE_TIME_FORMATS } from '../../../consts';

// this is used to get the
const DATE_TIME_TYPE_CONVERTER = {
  date: 'calendar',
  time: 'timer',
  dateTime: 'both',
} as const;

@Component({
  selector: 'cipo-date-time',
  standalone: true,
  imports: [OwlDateTimeModule, CipoFormModule, FlexLayoutModule, CipoTextViewComponent, TranslateModule],
  templateUrl: './cipo-date-time.component.html',
})
export class CipoDateTimeComponent {
  dateTimeTypeConverter = DATE_TIME_TYPE_CONVERTER;
  fieldData = signal<CipoDateTimeConfig>({});
  maxDate = signal<string>(null);
  dateTimeControl = new FormControl<string | string[]>(null);

  formControl = input<CipoDateTimeControl, CipoDateTimeControl>(null, {
    transform: control => {
      this.onFieldDataChange(control);
      return control;
    },
    alias: 'control',
  });

  formatValue(value: Date | Date[]) {
    const format = this.fieldData()?.format ?? DATE_TIME_FORMATS[this.fieldData()?.pickerType];
    if (value instanceof Array) {
      const formattedValue = value.map(v => moment(v).format(format));
      this.formControl().setValue(formattedValue);
    } else {
      const formattedValue = moment(value).format(format);
      this.formControl().setValue(formattedValue);
    }
  }

  onFieldDataChange(control: CipoDateTimeControl) {
    const fieldData = control.fieldData ?? {};
    this.fieldData.set(fieldData);
    this.maxDate.set(fieldData.maxDate);
  }

  changed(date: Date) {
    if (
      this.fieldData().selectMode !== 'range' ||
      this.fieldData().pickerType === 'time' ||
      !this.fieldData().maxRange
    ) {
      return;
    }
    const rangeEndDate = moment(date).add(this.fieldData().maxRange, 'day');
    const tempMaxDate = moment.min([rangeEndDate, moment(this.fieldData().maxDate)]);
    this.maxDate.set((this.fieldData().maxDate ? tempMaxDate : rangeEndDate).toISOString());
  }

  resetMaxDate() {
    this.maxDate.set(this.fieldData().maxDate);
  }
}
