import { Component, computed, inject, Input, signal } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { State, Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { HolidayActionsEnum, HolidayModel, HolidayRow, HolidaysService, HolidayTableOptions } from './common';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { AddEditHolidayComponent } from './add-edit-holiday/add-edit-holiday.component';
import {
  FiltersToApply,
  ActionEventType,
  DisplayFormats,
  TableColumn,
} from '../../../shared/components/data-list/common';
import { PAGE_SIZE_OPTIONS } from '../../../shared/consts';
import { BaseRestrictions } from '../../../models/module/fields/enums';
import { AppState } from '../../../../app.state';
import { Permission } from '../../../models/permission';
import { ConfirmationDialog } from '../../../shared/dialogs/confirmation-dialog.component';
import { PagedDataListComponent } from '../../../shared/components/data-list/paged-data-list/paged-data-list.component';
import { FilterOperator } from '../../../models/module/grid';
import { NotificationService } from '../../../shared/services';

@Component({
  selector: 'app-holidays',
  standalone: true,
  imports: [PagedDataListComponent, SharedComponentsModule],
  templateUrl: './holidays.component.html',
})
export class HolidaysComponent {
  dialog = inject(MatDialog);
  holidayService = inject(HolidaysService);
  translate = inject(TranslateService);
  notify = inject(NotificationService);
  store = inject<Store<AppState>>(Store);
  state = inject<State<AppState>>(State);
  permissions = signal<Permission[]>([]);
  selection = signal(new SelectionModel<HolidayRow>(true, []));
  @Input('permissions')
  set setPermissions(value: Permission[]) {
    this.permissions.set(value);
  }

  canCreate = computed(() => this.permissions()?.includes(Permission.Create));
  canDelete = computed(() => this.permissions()?.includes(Permission.Delete));
  canView = computed(() => this.permissions()?.includes(Permission.Read));
  canUpdate = computed(() => this.permissions()?.includes(Permission.Update));
  currentYear = computed(() => new Date().getFullYear());
  selectedYear = signal<number>(null);

  filters = this.holidayService.getFilters();
  holidayList: HolidayModel[] = [];

  tableOptions: HolidayTableOptions = {
    clickableRows: true,
    selectable: true,
    pagination: {
      pageSizeOptions: PAGE_SIZE_OPTIONS.default,
      pageSize: PAGE_SIZE_OPTIONS.default[1],
      pageIndex: 0,
    },
    actions: () => {
      return [
        {
          id: HolidayActionsEnum.VIEW,
          displayName: this.translate.instant('general.table.view'),
          rightIcon: 'edit',
          condition: () => this.canView() && !this.canUpdate(),
        },
        {
          id: HolidayActionsEnum.EDIT,
          displayName: this.translate.instant('general.table.edit'),
          rightIcon: 'edit',
          condition: () => this.canUpdate(),
        },
        {
          id: HolidayActionsEnum.DELETE,
          displayName: this.translate.instant('general.table.delete'),
          rightIcon: 'delete',
          condition: () => this.canDelete(),
        },
      ];
    },
  };
  tableColumns: TableColumn[] = [
    {
      id: 1,
      name: 'name',
      displayName: this.translate.instant('holidays.name'),
      width: 3,
      sortable: true,
    },
    {
      id: 2,
      name: 'date',
      displayName: this.translate.instant('holidays.date'),
      width: 2,
      displayFormatId: DisplayFormats.Date,
      restrictions: BaseRestrictions.Date,
      sortable: true,
    },
    {
      id: 3,
      name: 'isWorkDay',
      displayName: this.translate.instant('holidays.isWorkDay'),
      displayFormatId: DisplayFormats.YesNo,
      sortable: true,
    },
  ];
  tableData: HolidayRow[];
  isLoading = signal(false);

  constructor() {
    this.loadHolidays();
  }

  loadHolidays() {
    this.isLoading.set(true);
    this.holidayService.getAllHolidays().subscribe(data => {
      this.holidayList = data;
      this.tableData = data.map(holiday => ({ ...holiday }));
      this.isLoading.set(false);
    });
  }

  addEditHoliday(holiday?: HolidayRow) {
    this.dialog
      .open(AddEditHolidayComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        width: '600px',
        data: holiday,
      })
      .afterClosed()
      .pipe(filter(val => !!val))
      .subscribe(() => {
        this.notify.success(holiday ? 'holidays.updated' : 'holidays.added');
        this.loadHolidays();
      });
  }

  applyFilters(filters: FiltersToApply, fromQuickFilter = false) {
    if (!fromQuickFilter) {
      this.selectedYear.set(null);
    }
    this.selection().clear();
    this.tableData = this.holidayService.getFilteredHolidays(filters).map(holiday => ({ ...holiday }));
  }

  filterByYear(year: number) {
    if (year === this.selectedYear()) {
      this.selectedYear.set(null);
      this.applyFilters({});
      return;
    }
    this.selectedYear.set(year);
    const firstDayOfYear = new Date(`${year}-01-01T00:00:00`);
    const lastDayOfYear = new Date(`${year}-12-31T23:59:59`);
    this.applyFilters(
      {
        filters: {
          filterList: [
            {
              field: 'date',
              operator: FilterOperator.Between,
              value: [firstDayOfYear.toISOString(), lastDayOfYear.toISOString()],
            },
          ],
        },
      },
      true,
    );
  }

  deleteHolidays(id?: number) {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          title: 'holidays.deleteTitle',
          description: 'holidays.deleteMessage',
        },
      })
      .afterClosed()
      .pipe(filter(val => !!val))
      .subscribe(() => {
        if (id) {
          this.holidayService.deleteHolidays([id]).subscribe(() => {
            this.notify.success('holidays.deletedOne');
            this.loadHolidays();
          });
        } else {
          const selected = this.selection().selected.map(({ id }) => id);
          this.holidayService.deleteHolidays(selected).subscribe(() => {
            this.notify.success('holidays.deleted');
            this.loadHolidays();
            this.selection().clear();
          });
        }
      });
  }

  handleAction({ actionId, row }: ActionEventType<HolidayActionsEnum, HolidayRow>) {
    switch (actionId) {
      case HolidayActionsEnum.VIEW:
        this.addEditHoliday(row);
        break;
      case HolidayActionsEnum.EDIT:
        this.addEditHoliday(row);
        break;
      case HolidayActionsEnum.DELETE:
        this.deleteHolidays(row.id);
        break;
    }
  }
}
