import { cipo } from 'cipo';

    cipo.controller('editRoleController',
    function ($scope, $q, Role, Message, $mdDialog, MODAL, role, roleType, operations, module, $timeout) {
        $scope.modalInfo = MODAL;
        $scope.roleType = roleType || null;
        $scope.role = new Role(role, roleType);
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.role.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        $scope.filterUsers = "";
        $scope.filterAssignedUsers = "";

        $scope.syncUsers = [];
        $scope.role.tabWarning = false;
        $scope.role.message = "";

        Object.defineProperty($scope, 'newRole', {
            get: function () { return $scope.role.properties.id ? false : true; }
        });
        if (!$scope.operations.Update && !($scope.operations.Create && $scope.newRole)) {
            $scope.role.form.editMode = false;
        }
        Object.defineProperty($scope, 'formsDirty', {
            get: function () { return $scope.role.form.dirty || $scope.role.dirtyPermissions || $scope.role.dirtyUsers; }
        });

        // prevent tab change if unsaved changes
        $scope.$on('tab-change', function (event, tabEvent) {
            if (!$scope.formsDirty) {
                tabEvent.accept();
			}
			else {
                // capture the index of the tab cliked
                $scope.navToIndex = tabEvent.index;
                // set the warning params
                $scope.role.hightlight = true;
                $scope.role.tabWarning = true;

                $scope.disabledBtn = true;
                // prevent tab change
                tabEvent.reject();
            }
        });

        // warning actions
        $scope.revertTabInfo = function () {
            if (!$scope.closingModal) {
                switch ($scope.selectedIndex) {
                    case 0:
                        $scope.role.form.restore_Data();
                        break;
                    case 1:
                        $scope.role.get_modules();
                        break;
                    case 2:
                        $scope.role.usersList = angular.copy($scope.role.backupUsersList);
                        break;

                }
                $scope.role.tabWarning = false;
                $scope.role.hightlight = false;
                $scope.disabledBtn = false;
                if (!$scope.changingStatus) $scope.selectedIndex = $scope.navToIndex;
                else $scope.changeStatus();
            } else {
                $scope.close(true);
            }
            
        }
        $scope.cancelAction = function () {
            $scope.role.tabWarning = false;
            $scope.role.hightlight = false;
            $scope.disabledBtn = false;
            if ($scope.changingStatus) {
                $scope.role.disabled = !$scope.role.disabled;
                $scope.changingStatus = false;
            }
            if ($scope.closingModal) $scope.closingModal = false;
        }
        $scope.updateTabInfo = function () {
            $scope.role.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.updateRole()
                .then(function (r) {
                    $scope.role.tabWarning = false;
                    if (!$scope.closingModal && !$scope.changingStatus) {
                        $scope.selectedIndex = $scope.navToIndex;
                    } else if ($scope.changingStatus) {
                        $scope.changeStatus();
                    } else {
                        $scope.close(true);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                })
        }
        $scope.searchModule = "";
        $scope.modulesFilter = function (item) {
            var ret;
            if ($scope.role.filterId == 1) ret = true;
            if ($scope.role.filterId == 2) ret = item.perContract;
            if ($scope.role.filterId == 3) ret = !item.perContract;
            if ($scope.role.filterId == 4) ret = item.permissionsLine ? true : false;
            if ($scope.role.filterId == 5) ret = item.onMenu;
            return ret;
        }

        $scope.updateRole = function (isAdd) {
            var p = $q.defer();
            var action = "";
            switch ($scope.selectedIndex) {
                case 0:
                    action = $scope.role.save(isAdd);
                    break;
                case 1:
                    action = $scope.role.sync_permissions(isAdd);
                    break;
                case 2:
                    action = $scope.role.sync_users(isAdd);
                    break;

            }
            $scope.role.message = 'Updating role...';
            $scope.disabledBtn = true;
            action
                .then(function (r) {
                    Message.info('Role updated successfully');
                    if ($scope.selectedIndex == 0) $scope.isModified = true;
                    
                    
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.role.message = "";
                })
            return p.promise;
        }
        

        $scope.disabledBtn = false;
       

        $scope.changeStatus = function () {
            if (!$scope.formsDirty) {
                $scope.toggleDisable();
            }
            else {
                $scope.role.tabWarning = true;
                $scope.role.hightlight = true;
                $scope.changingStatus = true;
            }
            
        }

        $scope.toggleDisable = function () {
            $scope.disabledBtn = true;
            $scope.role.message = $scope.role.disabled ? "Disabling role..." : 'Enabling role...';
            var message = $scope.role.disabled ? "Role disabled successfully" : 'Role enabled successfully';
            $scope.role.toggle_disable()
                .then(function () {
                    Message.info(message);
                    //$scope.role.properties.disabled = true;
                    $scope.isModified = true;
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    //$scope.role.disabled = false;
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.role.message = "";
                })
        }

        $scope.close = function (isWarning) {
            if (!$scope.formsDirty || isWarning)
                if ($scope.isModified) $mdDialog.hide();
                else $mdDialog.cancel();
            else {
                $scope.role.hightlight = true;
                $scope.role.tabWarning = true;
                $scope.closingModal = true;
            }
        }


        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.updateRole,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction,
        }
        
    });
