import { Component, Inject } from '@angular/core';
import { ModuleParameterService } from '../module-parameter.service';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components.module';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuleParameterComponent } from '../module-parameter.component';
import { ModuleParameter } from '../module-parameter.model';

@Component({
  selector: 'app-module-parameter-dialog',
  templateUrl: './module-parameter-dialog.component.html',
  standalone: true,
  imports: [SharedComponentsModule],
  providers: [ModuleParameterService],
})
export class ModuleParameterDialogComponent {
  valueControl = new FormControl<number>(null);

  constructor(
    public dialogRef: MatDialogRef<ModuleParameterComponent>,
    @Inject(MAT_DIALOG_DATA) public moduleParameter: ModuleParameter,
  ) {
    this.valueControl.setValue(moduleParameter.value);
  }

  onSave() {
    const value = this.valueControl.value;
    this.dialogRef.close(value);
  }

  onClose() {
    this.dialogRef.close(undefined);
  }
}