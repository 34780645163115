import { cipo } from 'cipo';

cipo.factory("ListRelation", function ($q, Model, URI, Form, Message, Dictionaries, ListElements, List, $timeout) {
    var ListRelation = Model.extend(function (obj) {
        var self = this;
        self.properties = {
            parentListId: null,
            parentName: "",
            childListId: null,
            childName: null,
            isParent: true,

        };

        

        self.assignedChildListElementsIds = [];
        self.assignedChildListElementsIdsBackup = [];

        Object.defineProperty(self, 'dirtyAssignments', {
            get: function () {
                return !angular.equals(self.assignedChildListElementsIds.sort(), self.assignedChildListElementsIdsBackup.sort());
            }
        });

        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) self.properties[key] = obj[key];
            }
        }

        if (!self.properties.parentListId || !self.properties.childListId) {                
            self.createForm();
        }
        else self.properties.name = obj.parentName + ' -> ' + obj.childName;
    })

    
    ListRelation.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;

        var action = self.properties.isParent ? Dictionaries.ListChildren({ id: self.properties.parentListId }) : Dictionaries.ListParents({ id: self.properties.childListId });

        action
            .then(function (r) {

                self.listsLookup = {};
                for (var i = 0; i < r.length; i++) {
                    self.listsLookup[r[i].key] = r[i].value;
                }
                var form;

                if (self.properties.isParent) {
                    form = {
                        childListId: { label: 'Select child list', type: 'select', options: r, validation: { required: true } }
                    };
                } else {
                    form = {
                        parentListId: { label: 'Select parent list', type: 'select', options: r, validation: { required: true } }
                    };
                }                  


                self.form.set_Description(form);

                self.form.store_Data();
                self.form.initializing = false;
            })
    }

    ListRelation.prototype.save = function (isNoForm) {
        var self = this;
        var urlData = URI.LISTS.SET_RELATION;

        var p = $q.defer();
        if(self.form) self.form.validate();

        if ((self.form || {}).isValid || isNoForm) {
            (self.form || {}).loading = true;
            if (self.properties.isParent && !self.properties.childName) self.properties.childName = self.listsLookup[self.properties.childListId];
            else if(!self.properties.parentName) self.properties.parentName = self.listsLookup[self.properties.parentListId];
            self[urlData.method](urlData, { body: self.properties })
                .then(function (r) {
                    p.resolve();

                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    (self.form || {}).loading = false;
                })
        }
        else {
            p.reject();
        }
        

        return p.promise;

    }

    ListRelation.prototype.delete = function () {
        var self = this;
        var urlData = URI.LISTS.UNSET_RELATION;

        var p = $q.defer();

        self[urlData.method](urlData, { body: self.properties })
            .then(function (r) {
                p.resolve();                   

            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                
            })

        return p.promise;

    }

    

    ListRelation.prototype.revertAssignments = function () {
        var self = this;
        self.assignedChildListElementsIds = angular.copy(self.assignedChildListElementsIdsBackup);
        for (var i = 0; i < self.childElements[self.filter.filter].elements.length; i++) {
            if (self.assignedChildListElementsIds.indexOf(self.childElements[self.filter.filter].elements[i].key) != -1)
                self.childElements[self.filter.filter].elements[i].isAssigned = true;
            else self.childElements[self.filter.filter].elements[i].isAssigned = false;
        }

    }
    ListRelation.prototype.saveAssignments = function () {
        var self = this;
        var urlData = URI.LISTS.SYNC_PARENT_ELEMENTS;

        var p = $q.defer();

        self.isBusy = true;
        self.loadingChildElements = true;

        var bodyParams = {
            parentListId: self.properties.parentListId,
            parentElementId: self.selectedParentElement.id,
            childListId: self.properties.childListId,
            childElementIds: self.assignedChildListElementsIds
        }

        self[urlData.method](urlData, { body: bodyParams })
            .then(function (r) {
                p.resolve();
                self.filter.filter = 0;
                self.assignedChildListElementsIds = [];
                self.assignedChildListElementsIdsBackup = [];
                self.childListElements_init();
                self.getChildElements();
                self.get_ElementCount();

            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;


    }

    ListRelation.prototype.toggleChildElements = function (elem) {
        var self = this;
        if (self.assignedChildListElementsIds.indexOf(elem.key) != -1) {
            self.assignedChildListElementsIds.splice(self.assignedChildListElementsIds.indexOf(elem.key), 1);
            elem.isAssigned = false;
        } else {
            self.assignedChildListElementsIds.push(elem.key);
            elem.isAssigned = true;
        }
    }

    ListRelation.prototype.showTooltip = function (e) {
        var self = this;
        if (e.isShowTooltip) {
            e.isShowTooltip = false;
        } else {
            for (var i = 0; i < self.childElements[self.filter.filter].elements.length; i++) {
                self.childElements[self.filter.filter].elements[i].isShowTooltip = false;
            }
            e.isShowTooltip = true;
        }
            
    }

    ListRelation.prototype.getChildElements = function (filterNo) {
        var self = this;
        var p = $q.defer();            
        if (typeof filterNo != 'undefined') self.filter.filter = filterNo;
        else if (!self.filter.parentElementId) self.filter.filter = 3;

        if (!self.childElements[self.filter.filter].isLoaded) {
            self.loadingChildElements = true;
            var urlData = URI.LISTS.GET_ELEMENTS_FOR_PARENT;
            self[urlData.method](urlData, { url: {}, urltype: 'obj', body: self.filter })
                .then(function (r) {
                    p.resolve(r);
                    
                    self.childElements[self.filter.filter].elements = r || [];

                    if (self.filter.filter == 0) {

                        self.assignedChildListElementsIds = [];
                        for (var i = 0; i < self.childElements[self.filter.filter].elements.length; i++) {
                            self.assignedChildListElementsIds.push(self.childElements[self.filter.filter].elements[i].key);
                        }

                        self.assignedChildListElementsIdsBackup = angular.copy(self.assignedChildListElementsIds);
                    } 

                    for (var i = 0; i < self.childElements[self.filter.filter].elements.length; i++) {
                        if (self.assignedChildListElementsIds.indexOf(self.childElements[self.filter.filter].elements[i].key) != -1)
                            self.childElements[self.filter.filter].elements[i].isAssigned = true;
                    }

                    self.childElements[self.filter.filter].isLoaded = true;
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    self.loadingChildElements = false;
                })
        } else p.resolve();

        
        return p.promise;

    }

    ListRelation.prototype.changeSelectedElement = function (e) {
        var self = this;
        self.filter.parentElementId = e.id;
        self.selectedParentElement = e;
        self.childListElements_init();
        self.getChildElements(0);
        if (self.childList.newElement) self.childList.newElement.properties.parentElementId = e.id;
    }

    ListRelation.prototype.childListElements_init = function () {
        var self = this;
        // assigned / unassigned / others / all
        self.childElements = {
            0: { isLoaded: false, elements: [] },
            1: { isLoaded: false, elements: [] },
            2: { isLoaded: false, elements: [] },
            3: { isLoaded: false, elements: [] }
        };
    }

    ListRelation.prototype.get_ElementCount = function () {
        var self = this;
        var urlData = URI.LISTS.GET_ELEMENT_COUNT;

        var p = $q.defer();

        self[urlData.method](urlData, { body: self.properties })
            .then(function (r) {
                p.resolve();
                for (var i = 0; i < (r || []).length; i++) {                        
                    (self.parentElementsLookup[r[i].key] || {}).count = r[i].value;
                }

            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {

            })

        return p.promise;
        
    }

    ListRelation.prototype.get_ParentElements = function () {
        var self = this;
        self.loadingParentElements = true;
        ListElements.getParentElements(self.properties.parentListId)
            .then(function (r) {
                self.parentElements = r.elements;
                self.parentElementsLookup = r.lookup;
                self.selectedParentElement = self.parentElements[0];
                self.filter.parentElementId = (self.selectedParentElement || {}).id;
                self.getChildElements();
                self.get_ElementCount();
            }).catch(function () { }).finally(function () { self.loadingParentElements = false;})
    }


    ListRelation.prototype.init = function () {
        var self = this;

        self.addedElementToParentList = false;
        // child list filter
        self.filter = {
            listId: self.properties.childListId,
            parentListId: self.properties.parentListId,
            filter: 0,
            parentElementId: null

        };
        self.parentElements = [];
        self.childListElements_init();

        if (self.properties.isParent) {
            self.parentList = new List(ListElements.currentListDetails, true);
            var elementsList = angular.copy(ListElements.currentListElements);
            // add the code if needed
            if (ListElements.currentListDetails.useElementId) {
                for (var i = 0; i < elementsList.length; i++) {
                    elementsList[i].name = elementsList[i].elementId + ' - ' + elementsList[i].name;
                    self.parentElements.push(elementsList[i]);
                }
            } else self.parentElements = elementsList;

            self.parentElementsLookup = ListElements.setLookup(elementsList);
            if (self.parentElements.length) {
                self.selectedParentElement = self.parentElements[0];
                self.filter.parentElementId = self.selectedParentElement.id;
                self.getChildElements();
                self.get_ElementCount();
            } else {
                self.filter.filter = 3;
                self.getChildElements();
            }

            self.childList = new List({ id: self.properties.childListId });
        }
        else {
            self.parentList = new List({ id: self.properties.parentListId });

            self.childList = new List({ id: self.properties.childListId });


            self.get_ParentElements();
        }


        self.loadingChildElements = true;
    }

    ListRelation.prototype.addListElement = function (isChildList) {
        var self = this;
        self.isBusy = true;

        if (isChildList)
            self.childList.editElement({
                parentListId: self.properties.parentListId, parentElementId: (self.selectedParentElement || {}).id || null });
        else self.parentList.editElement();

        
    }

    ListRelation.prototype.saveListElement = function (isChildList) {
        var self = this;
        var action = isChildList ? self.childList.updateElement() : self.parentList.updateElement();
        action.then(function (r) {                
            self.isBusy = false;
            if (isChildList) {
                if (self.parentElements.length) {
                    self.filter.filter = 0;
                    self.assignedChildListElementsIds = [];
                    self.assignedChildListElementsIdsBackup = [];
                    self.get_ElementCount();
                }
                self.childListElements_init();
                self.getChildElements();
            }
            else {
                var newElem = angular.copy(r);
                if (self.parentList.properties.useElementId) newElem.name = newElem.elementId + ' - ' + newElem.name;
                self.parentElements.push(newElem);
                self.parentElementsLookup[newElem.id] = newElem;
                self.changeSelectedElement(newElem);

                // flag to scroll div to last element
                self.addedElementToParentList = true;
                $timeout(function () { self.addedElementToParentList = false; }, 100);
                
            }
        })
        
    }
    ListRelation.prototype.cancelListElement = function (isChildList) {
        var self = this;
        if (isChildList)
            self.childList.newElement = null;
        else self.parentList.newElement = null;

        self.isBusy = false;
    }



    return ListRelation;

})
