import { Injectable } from "@angular/core";
import { Observable, delay, map, of } from "rxjs";
import { ScreenFieldModel } from "../../../models/module/fields/main";
import { GridViewModel } from "../../../models/module/grid";
import { DataCardsViewService } from "../../../module/instance/data-card/data-cards-view/data-cards-view.service";
import { DemoCardsScreenFieldModel } from "../../data";

@Injectable()
export class DemoCardsService extends DataCardsViewService {
    getScreenFields(): Observable<GridViewModel<ScreenFieldModel[]>> {
        return of(null).pipe(map(() => DemoCardsScreenFieldModel), delay(100));
    }
}