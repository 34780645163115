import { cipo } from 'cipo';

cipo.controller('LogInModalController',
    function ($scope, Login, Message, $uibModalInstance) {
        $scope.loginObject = new Login();
        $scope.loginObject.form.fieldsList.username.setFocus();

        $scope.submitPressed = false;
        $scope.login = function () {
            $scope.submitPressed = true;
            $scope.loginObject.get_Token().then(function () {
                $uibModalInstance.close({});
            }).catch(function (serverCalled) {
                $scope.submitPressed = false;
                if (serverCalled) {
                    $scope.loginObject.form.setFieldInvalid('username', ' ');
                    $scope.loginObject.form.setFieldInvalid('password', 'Email or password incorrect');
                }
            });
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        }
    });
