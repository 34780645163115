import { Component } from '@angular/core';

import { FroalaConfig } from 'src/app/shared/components/froala/common';
import { CipoFroalaOptions, CipoTributeOptions } from '../../shared/components/froala/interfaces';
import { MockMentions, MockVariables } from 'src/app/demo/data';
import { UtilsService } from 'src/app/shared/services';

const DEMO_FROALA_CONTENT_KEY = 'froalaContent';

@Component({
  selector: 'app-demo-froala',
  templateUrl: './demo-froala.component.html',
})
export class DemoFroalaComponent {
  options: CipoFroalaOptions = {
    charCounterMax: 100,
  };
  required = false;
  hint = 'This is a hint';
  label = 'This is a label';
  initialValue = '';
  tributeOptions: CipoTributeOptions = {
    variables: MockVariables,
    mentions: this.utilsService.mapArrayToObject(MockMentions),
  };
  editMode = true;

  constructor(private utilsService: UtilsService) {
    this.initialValue = localStorage.getItem(DEMO_FROALA_CONTENT_KEY) ?? '';
  }
  updateContent(content: string) {
    localStorage.setItem(DEMO_FROALA_CONTENT_KEY, content);
  }
}
