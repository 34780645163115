import { cipo } from 'cipo';

cipo.factory("ExternalContact", function ($q, Model, Form, Functions,Message, URI, Dictionaries, Permissions) {
    var ExternalContact = Model.extend(function (obj) {
        var self = this;
        self.tabWarning = false;
        self.hightlight = false;
        self.message = "";
        self.properties = {
            orgId: obj ? obj.orgId : null,
            email: obj ? obj.email : "",
            firstName: obj ? obj.firstName : "",
            lastName: obj ? obj.lastName : "",
            middleName: obj ? obj.middleName : "",
            name: obj ? obj.name : "",
            preferredFullName: obj ? obj.preferredFullName : "",
            initials: obj ? obj.initials : "",
            id: obj ? obj.id : 0,
            address1: obj ? obj.address1 : "",
            address2: obj ? obj.address2 : "",
            jobTitle: obj ? obj.jobTitle : "",
            city: obj ? obj.city : "",
            state: obj ? obj.state : "",
            postalCode: obj ? obj.postalCode : "",
            countryCode: null,
            fax: obj ? obj.fax : "",
            mobilePhone: obj ? obj.mobilePhone : "",
            officePhone: obj ? obj.officePhone : "",
            isPrimaryContact: obj ? obj.isPrimaryContact : false,
            isPrivate: true
        };

        self.form = new Form(self.properties);
        
        self.form.initializing = true;

        var all = $q.all([Dictionaries.Organizations(), Dictionaries.CountryCodes()]);

        all
            .then(function (r) {

                var form = {
                    firstName: { label: 'First Name', type: 'text', validation: { required: true, maxChars: 80 } },
                    lastName: { label: 'Last Name', type: 'text', validation: { required: true, maxChars: 80 } },
                    middleName: { label: 'Middle Name', type: 'text', validation: { maxChars: 80 } },
                    postalCode: { label: 'Postal Code', type: 'text', validation: { maxChars: 15 } },
                    address1: { label: 'Address1', type: 'text', validation: { maxChars: 80 } },
                    address2: { label: 'Address2', type: 'text', validation: { maxChars: 80 } },
                    city: { label: 'City', type: 'text', validation: { maxChars: 80 } },
                    state: { label: 'State', type: 'text', validation: { maxChars: 80 } },
                    fax: { label: 'Fax', type: 'text', validation: { maxChars: 18 } },
                    mobilePhone: { label: 'Mobile Phone', type: 'text', validation: { maxChars: 18 } },
                    officePhone: { label: 'Office Phone', type: 'text', validation: { maxChars: 18 } },
                    preferredFullName: { label: 'Preferred Full Name', type: 'text', validation: { maxChars: 256 } },
                    initials: { label: 'Initials', type: 'text', validation: { maxChars: 3 } },
                    jobTitle: { label: 'Job Title', type: 'text', validation: { maxChars: 100 } },
                    // name: { label: 'Full Name', type: 'text' },
                    email: { label: 'Email', type: 'text', validation: { email: true, maxChars: 256 } },
                    orgId: { label: 'Select Organization', type: 'select', options: r[0], validation: { required: true } },
                    isPrimaryContact: { label: 'Primary Contact', type: 'checkbox' },
                    countryCode: { label: 'Country', type: 'select', options: r[1] },
                    isPrivate: { label: 'Private', type: 'checkbox' },
                };
                var grid;
                

                grid = [
                    { firstName: 33, lastName: 33, middleName: 33 },
                    { preferredFullName: 50, initials: 25, jobTitle: 25 },
                    { address1: 50, address2: 50 },
                    { city: 25, state: 25, postalCode: 25, countryCode: 25 },
                    { fax: 33, mobilePhone: 33, officePhone: 33 },
                    { email: 50, orgId: 50 },
                    { isPrimaryContact: 40, isPrivate: 60 }
                ];

                self.form.set_Description(form);
                self.form.setTemplate('grid', grid);
                // self.form.store_Data();
                self.form.initializing = false;
                if (obj) self.get_Data();
                else {
                    self.properties.permissions = [1, 2];
                    self.operations = {};
                    for (var j = 0; j < self.properties.permissions.length; j++) {
                        Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                    }
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                }
            })
            .catch(function (e) { })


        
    });

    ExternalContact.prototype.init = function (isAdd) {
        var self = this;
        this.properties = {
            orgId: null,
            email: "",
            firstName: "",
            lastName: "",
            middleName: "",
            name: "",
            preferredFullName: "",
            initials: "",
            id: null,
            address1: "",
            address2: "",
            jobTitle: "",
            city: "",
            state: "",
            postalCode: "",
            countryCode: null,
            fax: "",
            mobilePhone: "",
            officePhone: "",
            isPrimaryContact: false,
            permissions: [1,2]
        };

        if (isAdd) {
            this.id = null;
            self.form.set_Data(self.properties);
            self.form.store_Data();
            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }

        }
        

    }


    ExternalContact.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self[URI.CONTACT.GET.method](URI.CONTACT.GET, { id: self.properties.id })
            .then(function (result) {
                self.operations = {};
                if (result) {
                    for (var key in result) {
                        if (result.hasOwnProperty(key)) {
                            self.properties[key] = result[key];
                        }
                    }
                    if ((self.properties.permissions || []).length) {
                        for (var j = 0; j < self.properties.permissions.length; j++) {
                            Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                        }
                    }
                    if (!self.operations.Update) self.form.editMode = false;
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                    
                    // self.form.clearDirty();
                }
            }).catch(function (e) {
                Message.dberror(e);
                console.error(e);
            }).finally(function () {
                self.form.loading = false;
                p.resolve();
            });

        return p.promise;
    }

    ExternalContact.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        
        self.form.validate();

            if (self.form.isValid) {
                var dataURL = self.properties.id ? URI.CONTACT.UPDATE : URI.CONTACT.CREATE;
                var params = {
                    url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                    body: self.properties
                };
                self[dataURL.method](dataURL, params)
                    .then(function (r) {

                        if (!isAdd)
                            Message.info('Contact updated successfully');
                        else
                            Message.info('Contact added successfully');

                        self.isModified = true;
                        // if new, call for details to get permissions
                        if (!self.properties.id && !isAdd) {
                            self.properties.id = r;
                            self.get_Data(r);
                            return;
                        }
                        // is not new
                        if (!isAdd) {
                            self.form.store_Data();
                            self.form.set_Data(self.properties);

                        return;
                    }
                    // if is add new just start another
                    if (isAdd) {
                        self.init(true);
                    }
                    p.resolve(r);
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                });
        } else {
            self.form.loading = false;
            p.reject();
        }
        //console.log("obj", self);
        return p.promise;
    }

    

    return ExternalContact;
});
