import { Injectable, inject } from '@angular/core';
import { of, map } from 'rxjs';

import { DemoTableFields } from '../../data/table-field.const';
import { TableFieldService } from '../../../module/definition/table-field/common/table-field.service';

@Injectable()
export class DemoTableFieldService {
  tableFieldService = inject(TableFieldService);
  getTableFields() {
    return of(DemoTableFields).pipe(map(fields => this.tableFieldService.mapTableFields(fields)));
  }
}
