<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">Add Field</p>
    <button mat-icon-button [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <form [formGroup]="form">
        <div class="cipo-bg--primary-100 p-a m-t">
            <div fxLayout fxLayoutGap="12px">
                <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>
                <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                    <mat-label>Label</mat-label>
                    <input matInput formControlName="label" />
                </mat-form-field>
                <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                    <mat-label>Field Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let fieldType of fieldTypes" [value]="fieldType.key">
                            {{ fieldType.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="12px">
                <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                    <mat-label>Hint</mat-label>
                    <input matInput formControlName="hint" />
                </mat-form-field>
                @if (selectFieldTypes.includes(form.value.type)) {
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                        <mat-label>Default value</mat-label>
                        <mat-select formControlName="defaultValue" [multiple]="true">
                            @for (option of form.value.options; track $index) {
                                <mat-option [value]="option.replace(' ', '') + $index">
                                    {{ option }}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                } @else if (form.value.type === FIELD_TYPES_ENUM.Checkbox) {
                    <mat-checkbox formControlName="defaultValue" fxFlex fxFlexAlign="center">
                        Default value
                    </mat-checkbox>
                } @else {
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                        <mat-label>Placeholder</mat-label>
                        <input matInput formControlName="placeholder" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                        <mat-label>Default value</mat-label>
                        <input matInput formControlName="defaultValue" />
                    </mat-form-field>
                }
            </div>
            @if (!selectFieldTypes.includes(form.value.type) && form.value.type !== FIELD_TYPES_ENUM.Checkbox) {
                <div fxLayout fxLayoutGap="12px">
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex="20">
                        <mat-label>Prefix</mat-label>
                        <input matInput formControlName="prefix" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex="30">
                        <mat-label>Prefix Icon</mat-label>
                        <input matInput formControlName="prefixIcon" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex="20">
                        <mat-label>Suffix</mat-label>
                        <input matInput formControlName="suffix" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript p-0" fxFlex="30">
                        <mat-label>Suffix Icon</mat-label>
                        <input matInput formControlName="suffixIcon" />
                    </mat-form-field>
                </div>
            }
            <div fxLayout fxLayoutGap="12px">
                <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
                    <mat-label>Field Description</mat-label>
                    <textarea matInput formControlName="fieldDescription"></textarea>
                </mat-form-field>
                @if (selectFieldTypes.includes(form.value.type)) {
                    <mat-form-field fxFlex class="cipo-input hide-subscript p-0">
                        <mat-label>Options</mat-label>
                        <mat-chip-grid #chipGrid formControlName="options">
                            @for (option of form.value.options; track $index) {
                                <mat-chip-row (removed)="removeOption($index)" [editable]="false">
                                    {{ option }}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                            }
                            <input
                                #chipInput
                                [matChipInputFor]="chipGrid"
                                [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="addOption($event)" />
                        </mat-chip-grid>
                    </mat-form-field>
                }
            </div>
        </div>
        <div fxLayout class="cipo-bg--accent-50 m-t-lg">
            <div fxFlex class="m-t" fxLayout="column">
                <mat-checkbox formControlName="required">Required</mat-checkbox>
                <mat-checkbox formControlName="readonly">Readonly</mat-checkbox>
                @if (form.value.type !== FIELD_TYPES_ENUM.Checkbox) {
                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                }
            </div>
            <div fxFlex fxLayout="column">
                @switch (form.controls.type.value) {
                    @case (FIELD_TYPES_ENUM.Text) {
                        <mat-form-field class="cipo-input hide-subscript p-0">
                            <mat-label>Maximum length</mat-label>
                            <input matInput type="number" formControlName="maxLength" />
                        </mat-form-field>
                        <mat-checkbox formControlName="multiline">Multiline</mat-checkbox>
                    }
                    @case (FIELD_TYPES_ENUM.Number) {
                        <mat-form-field class="cipo-input p-0">
                            <mat-label>Decimals</mat-label>
                            <input matInput type="number" min="0" max="3" formControlName="decimals" />
                            <mat-hint>Value between 1 and 3</mat-hint>
                        </mat-form-field>
                        <div fxLayout fxLayoutGap="12px">
                            <mat-checkbox formControlName="showThousandSeparator">Show Thousand Separator</mat-checkbox>
                            <mat-checkbox formControlName="isCurrency">This is a currency field</mat-checkbox>
                        </div>
                    }
                    @case (FIELD_TYPES_ENUM.Listbox) {
                        <mat-form-field class="cipo-input p-0">
                            <mat-label>No of columns</mat-label>
                            <input matInput type="number" min="0" max="3" formControlName="columns" />
                            <mat-hint>Value between 1 and 3</mat-hint>
                        </mat-form-field>
                    }
                }
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">{{ "general.cancel" | translate }}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="!form.valid || !form.dirty" (click)="saveField()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
