import { Component, inject } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';

import { TableFieldComponent } from '../../../module/definition/table-field/table-field.component';
import { CipoFormGroup, CipoControl } from '../../../shared/components/fields/common';
import { TableFieldConfig } from '../../../module/definition/table-field/common';
import { DemoTableFieldService } from './demo-table-field.service';

@Component({
  selector: 'app-demo-table-field',
  templateUrl: './demo-table-field.component.html',
  standalone: true,
  imports: [TableFieldComponent],
  providers: [DemoTableFieldService],
})
export class DemoTableFieldComponent {
  demoTableService = inject(DemoTableFieldService);
  tableFields: TableFieldConfig[];

  form = new FormArray<CipoFormGroup>([]);
  constructor() {
    this.demoTableService.getTableFields().subscribe(fields => {
      this.tableFields = fields;
      const formGroup = new FormGroup({}) as CipoFormGroup;
      fields.forEach(field => {
        const { label, fieldDescription, required, ...props } = field;
        formGroup.addControl(
          field.name,
          new CipoControl(null, { ...props, cleanReadOnly: true }, required ? Validators.required : null),
        );
      });
      this.form = new FormArray<CipoFormGroup>([formGroup]);
    });
  }
}
