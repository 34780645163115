<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "assignments.selectUsers" | translate }}</p>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div fxLayout="column" class="p-t-md">
        <div >
            <cipo-select [control]="listControl" />
        </div>
        <div fxFlex fxLayoutGap="4px" fxLayout="start center" class="small-info-text m-b-md">
            <span class="small-info-icon">info</span>
            <span>{{ "assignments.cascadeInfo" | translate }}</span>
        </div>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()" [disabled]="saving()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="returnUsers()" [disabled]="saving()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
