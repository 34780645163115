import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { Permission } from '../../models/permission';
import { Role } from '../../models/module/main';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private http = inject(HttpService);

  getModuleUserRole(id: number, contractId?: number) {
    const params = this.http.buildHttpParams({
      id,
      permissionId: Permission.Create,
      ...(contractId ? { contractId } : {}),
    });
    return this.http.get<Role[]>('_api/ModuleDefinition/ModuleRoleUserDict', { httpOptions: { params } });
  }
}
