import { GridsterModule } from 'angular-gridster2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../shared/modules/material.module';
import { PipesModule } from '../../../shared/modules/pipes.module';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { DataCardViewPartComponent } from './data-card-view-part/data-card-view-part.component';
import { DataCardViewComponent } from './data-card-view/data-card-view.component';
import { DataCardsViewComponent } from './data-cards-view/data-cards-view.component';
import { DataCardsViewService } from './data-cards-view/data-cards-view.service';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule, FlexLayoutModule, GridsterModule, PipesModule, SharedComponentsModule],
  providers: [DataCardsViewService],
  declarations: [DataCardViewPartComponent, DataCardViewComponent, DataCardsViewComponent],
  exports: [DataCardViewPartComponent, DataCardViewComponent, DataCardsViewComponent],
})
export class DataCardViewModule { }
