import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  imports: [],
  exports: [TranslateModule, FlexLayoutModule, MatButtonModule, MatIconModule, MatDialogModule],
})
export class CipoDialogModule {}
