import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { AppendColorDirective } from 'src/app/demo/angular/demo-theme/append-color.directive';
import { DemoThemeComponent } from 'src/app/demo/angular/demo-theme/demo-theme.component';
import { DemoScheduledEmailsService } from 'src/app/demo/module-instance/demo-scheduled-emails/demo-scheduled-emails.service';
import { TableComponent } from 'src/app/demo/module-instance/table/table.component';
import { ScheduledEmailsModule } from 'src/app/module/instance/scheduled-emails/scheduled-emails.module';
import { DataCardSettingsModule } from '../module/definition/data-card/data-card-settings.module';
import { ModuleInstanceCalendarModule } from '../module/instance/calendar/calendar.module';
import { DataCardViewModule } from '../module/instance/data-card/data-card-view.module';
import { ModuleInstanceModule } from '../module/instance/module-instance.module';
import { TenantSettingsModule } from '../module/instance/tenant-settings/tenant-settings.module';
import { SharedComponentsModule } from '../shared/modules/shared-components.module';
import { ContextViewComponent } from '../system/context/view/context-view.component';
import { SystemModule } from '../system/system.module';
import { DemoAngularButtonComponent } from './angular/button/demo-angular-button.component';
import { ColorPaletteComponent } from './angular/demo-theme/color-palette/color-palette.component';
import { DemoAngularInputComponent } from './angular/input/demo-angular-input.component';
import { DemoFroalaComponent } from './demo-froala/demo-froala.component';
import { DemoDataCardSettingsComponent } from './module-definition/demo-data-card-settings/demo-data-card-settings.component';
import { DemoCalendarViewComponent } from './module-instance/calendar-view/demo-calendar-view.component';
import { DemoCardsViewComponent } from './module-instance/cards-view/demo-cards-view.component';
import { DemoAddressAutocompleteComponent } from './module-instance/demo-address-autocomplete/demo-address-autocomplete.component';
import { DemoScheduledEmailsComponent } from './module-instance/demo-scheduled-emails/demo-scheduled-emails.component';
import { DemoContextComponent } from './system/context/demo-context.component';
import { DemoLayoutComponent } from './system/layout/demo-layout.component';
import { InfiniteDatalistComponent } from '../shared/components/data-list/infinite-data-list/infinite-data-list.component';
import { PagedDataListComponent } from '../shared/components/data-list/paged-data-list/paged-data-list.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    SharedComponentsModule,
    SystemModule,
    ModuleInstanceCalendarModule,
    DataCardViewModule,
    DataCardSettingsModule,
    ScheduledEmailsModule,
    ModuleInstanceModule,
    TenantSettingsModule,
    ContextViewComponent,
    InfiniteDatalistComponent,
    PagedDataListComponent,
  ],
  declarations: [
    DemoLayoutComponent,
    DemoAngularInputComponent,
    DemoAngularButtonComponent,
    DemoCalendarViewComponent,
    DemoThemeComponent,
    DemoCardsViewComponent,
    DemoFroalaComponent,
    TableComponent,
    AppendColorDirective,
    DemoScheduledEmailsComponent,
    ColorPaletteComponent,
    DemoDataCardSettingsComponent,
    DemoAddressAutocompleteComponent,
    DemoContextComponent,
  ],
  providers: [DemoScheduledEmailsService],
})
export class DemoModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
