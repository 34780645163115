import { cipo } from "cipo";

cipo.controller("editFileDialogController", function ($scope, $mdDialog, file, entityInstanceId, rootModuleId, userService) {
  $scope.file = file ?? {};
  $scope.entityInstanceId = entityInstanceId ?? file.entityInstanceId;
  $scope.rootModuleId = rootModuleId;

  // get live operations
  Object.defineProperty($scope, 'operations', {
    get: function () { return userService.getOperationsFor('FCM'); }
  });

  $scope.canChangePermissions = $scope.operations['Permissions'] != undefined;

  $scope.onSave = function () {
    $mdDialog.hide(true);
  }

  $scope.onClose = function () {
    $mdDialog.cancel();
  };
});