import { cipo } from 'cipo';

cipo.factory("SovItem", function (Model, $q, URI, WorkflowScreen, Message, userService) {
    var SovItem = Model.extend(function (obj, contractId, sovEntityInstanceId) {
        var self = this;
        self.contractId = contractId || 0;
        self.categoriesArray = [];
        self.properties = {};
        self.get_itemDetails(obj);
        self.sovEntityInstanceId = sovEntityInstanceId || 0;
        self.properties.editable = obj.editable;
        self.properties.entity_instance_id = obj.entity_instance_id || 0;
        self.itemName = (userService.system.modules['SOV']?.name || 'SOV') + ' Item';
    });

    SovItem.prototype.init = function (obj) {
        var self = this;
        self.properties.entity_instance_id = 0;
        self.get_itemDetails();
    }

    SovItem.prototype.get_itemDetails = function (i) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOVI_GET;
        var params = { contractId: self.contractId, entityInstanceId: i && i.entity_instance_id ? i.entity_instance_id : 0 };
        self.loading = true;
        if ((self.screen || {}).form) self.screen.form.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                }
                for (var i = 0; i < self.properties.fields.length; i++) {
                    if (self.properties.fields[i].fieldName == 'category_id') {
                        self.initialCategory = self.properties.fields[i].value || 0;
                        self.catId = self.properties.fields[i].id;
                    }
                }
                
                self.screen = new WorkflowScreen();
                self.screen.data = { fields: self.properties.fields };
                self.screen.setupForm(self.properties.editable);
                self.screen.form.store_Data();
                if (self.screen.properties[self.catId]) self.categoriesArray.push(self.screen.properties[self.catId]);
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SovItem.prototype.save = function () {
        var self = this;
        var p = $q.defer();

        self.screen.form.validate();
        if (self.screen.form.isValid) {
            self.isBusy = true;
            var dataURL = URI.SOV.SOVI_SAVE;
            var params = {
                sovItemEntityInstanceId: self.properties.entity_instance_id,
                sovEntityInstanceId: self.sovEntityInstanceId,
                contractId: self.contractId
            };
            self.screen.form.loading = true;
            self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: { values: self.screen.valuesDictionary } })
                .then(function (r) {
                    if (r) {
                        self.properties.entityInstanceId = r;
                    }
                    //console.error('form', self)
                    self.finalCategory = self.screen.properties[self.catId];
                    if (self.categoriesArray.indexOf(self.screen.properties[self.catId]) == -1) self.categoriesArray.push(self.screen.properties[self.catId]);
                    self.screen.form.clearDirty();
                    self.screen.form.loading = false;
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    self.screen.form.loading = false;
                    self.screen.form.catch(e);
                    p.reject(e);

                })
                .finally(function () {

                    self.isBusy = false;
                })
        }
        else {
            p.reject();
        }


        return p.promise;
    }

    return SovItem;
});
