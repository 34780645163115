import { DisplayType, FieldOrigin, FieldTypeEnum, KeyValueFormattingModel, KeyValueRestrictionModel } from '../enums';
import { ScreenFieldFormattingModel } from './screenFieldFormattingModel';

export interface ScreenFieldModel {
  id?: number;
  customFieldId?: number;
  systemFieldName?: string;
  name: string;
  label: string;
  typeId?: FieldTypeEnum;
  displayTypeId?: DisplayType;
  expression?: string;
  formattings: KeyValueFormattingModel[];
  restrictions: KeyValueRestrictionModel[];
  x: number;
  y: number;
  cols: number;
  rows: number;
  annotation?: string;
  canRemove: boolean;
  fieldOrigin: FieldOrigin;
  isFilter: boolean;
  isContractSystemField: boolean;
  currentDateTime: boolean;
  weatherDateId?: number;
  labelFormatting: ScreenFieldFormattingModel;
  valueFormatting: ScreenFieldFormattingModel;
  isRequired?: boolean;
}
