<h2>Theme</h2>
<mat-tab-group mat-stretch-tabs="false">
    <mat-tab label="Colors">
        <div fxLayout.gt-lg="row"
             fxLayout.lt-md="column"
             fxLayoutGap.gt-lg="60px">
            <app-color-palette></app-color-palette>
            <div>
                <h3>Color classes</h3>
                <p>There are two types of classes:</p>
                <ul>
                    <li>
                        Background classes:
                        <b>cipo-bg--primary-100</b>
                        - the first part
                        <i>cipo-bg--</i>
                        is always the same, and the second part represents the color
                    </li>
                    <li>
                        Text classes:
                        <b>cipo-text--primary-900</b>
                        - analogical format
                    </li>
                </ul>
                <p>You can use these classes everywhere in the app, to get the colors that you need</p>
                <p>
                    Each theme and mode has it's own colors, you can find them inside
                    <i class="cipo-text--primary-900">src/assets/stylesheets/colors/colors.scss</i>
                </p>
                <p>List of colors (striked colors will be added by need):</p>
                <ul class="three-columns">
                    <li>black</li>
                    <li>white</li>
                    <li>primary-50</li>
                    <li>primary-100</li>
                    <li>primary-200</li>
                    <li>primary-300</li>
                    <li>primary-400</li>
                    <li>primary-500</li>
                    <li>primary-600</li>
                    <li>primary-700</li>
                    <li>primary-800</li>
                    <li>primary-900</li>
                    <li>accent-50</li>
                    <li>accent-100</li>
                    <li>accent-200</li>
                    <li>accent-300</li>
                    <li>accent-400</li>
                    <li>accent-500</li>
                    <li>accent-600</li>
                    <li>accent-700</li>
                    <li>accent-800</li>
                    <li>accent-900</li>
                    <li>warn-50</li>
                    <li>warn-100</li>
                    <li>warn-200</li>
                    <li>warn-300</li>
                    <li>warn-400</li>
                    <li>warn-500</li>
                    <li>warn-600</li>
                    <li>warn-700</li>
                    <li>warn-800</li>
                    <li>warn-900</li>
                </ul>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Spacings">
        <p>
            To avoid adding a class each time you want to set margins or paddings, there are a list of classes that will
            do the job.
        </p>
        <p>The class elements are:</p>

        <ul>
            <li>
                <p>
                    The type of space needed:
                    <b>m</b>
                    or
                    <b>p</b>
                    - margin or padding
                </p>
            </li>
            <li>
                <p>
                    Space direction:
                    <b>a</b>
                    = all;
                    <b>x</b>
                    = left & right;
                    <b>y</b>
                    = top & bottom;
                    <b>t</b>
                    = top;
                    <b>r</b>
                    = right;
                    <b>b</b>
                    = bottom;
                    <b>l</b>
                    = left;
                </p>
            </li>
            <li>
                <p>
                    The size:
                    <b>xs</b>
                    ,
                    <b>s</b>
                    ,
                    <b>m</b>
                    ,
                    <b>l</b>
                    ,
                    <b>xl</b>
                    ,
                    <b>xxl</b>
                    - corresponding to variables below
                    <br />
                    and
                    <b>auto</b>
                    &
                    <b>0</b>
                    - for auto and 0 values
                </p>
            </li>
        </ul>
        <p>
            $spacer: 1rem //16px for most browsers. Will go on with this value;
            <br />
            $spacer-xs = $spacer * 0.25 (~ 4px);
            <br />
            $spacer-s = $spacer * 0.5 (~ 8px);
            <br />
            $spacer-m = $spacer * 0.75 (~ 12px);
            <br />
            $spacer-l = $spacer * 1.25 (~ 20px);
            <br />
            $spacer-xl = $spacer * 1.5 (~ 24px);
            <br />
            $spacer-xxl = $spacer * 2 (~ 32px);
            <br />
        </p>

        <p>
            So the space classes would look like this:
            <br />
            <b>m-b-md</b>
            -
            <i>margin-bottom: $spacer-md; // ~12px;</i>
            ;
            <br />
            <b>m-a-s</b>
            -
            <i>margin: $spacer-s; // ~8px;</i>
            ;
            <br />
            <b>m-b-md</b>
            -
            <i>margin-bottom: $spacer-md; // ~12px;</i>
            ;
            <br />
        </p>
        <p>
            <b><i>If the last part (size) will not be added, the size will default to spacer</i></b>
            <br />
            Therefor,
            <b>m-l</b>
            will result in
            <i>margin-left: $spacer // ~16px</i>
        </p>
    </mat-tab>
</mat-tab-group>