import { Component, DestroyRef, Inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { debounceTime, filter, tap } from 'rxjs';

import { WeekDaysEnum } from '../../../../models/common';
import { GenericObjectType } from '../../../../models/helpers';
import { FROALA_TOOLBAR_MEDIUM, FROALA_TOOLBAR_SMALL } from '../../../../shared/components/froala/common';
import { CipoFroalaOptions, CipoTributeOptions } from '../../../../shared/components/froala/interfaces';
import { DATE_TIME_FORMATS, DEBOUNCE_TIMES } from '../../../../shared/consts';
import { CipoValidators } from '../../../../shared/services';
import { ScheduledEmailRow } from '../interfaces';
import { ScheduledEmailService } from '../scheduled-emails.service';
import {
  ScheduleFrequencyEnum,
  ScheduledEmail,
  ScheduledEmailAdditionalRoles,
  ScheduledEmailModule,
  SendMailToEnum,
} from './../interfaces/scheduled-emails-req.interface';

const DEFAULT_TIME = moment().hour(9).minute(0).toISOString();

@Component({
  selector: 'app-new-schedule-dialog',
  templateUrl: './new-schedule-dialog.component.html',
})
export class NewScheduleDialogComponent implements OnInit {
  SCHEDULE_FREQUENCY_ENUM = ScheduleFrequencyEnum;
  FREQUENCY = Object.keys(ScheduleFrequencyEnum)
    .filter(key => isNaN(Number(key)))
    .filter(key => key != 'MANUAL') // TODO - to implement on backend, then remove this line
    .map(key => ({ key, value: ScheduleFrequencyEnum[key] }));
  SEND_MAIL_TO_ENUM = SendMailToEnum;
  SEND_MAIL_TO = Object.keys(SendMailToEnum)
    .filter(key => isNaN(Number(key)))
    .map(key => ({ key, value: SendMailToEnum[key] }));
  additionalRoles: ScheduledEmailAdditionalRoles[] = [];
  modules: ScheduledEmailModule[] = [];
  modulesDict: GenericObjectType = {};
  allModulesSelected = false;

  //   0: "Sunday"
  weekDay: WeekDaysEnum = moment().weekday() === 0 ? 7 : moment().weekday();
  WEEK_DAYS = WeekDaysEnum;
  isManual = false;
  minDate = moment().startOf('day').toDate();

  froalaOptionsSubject: CipoFroalaOptions = {
    ...FROALA_TOOLBAR_SMALL,
    heightMin: 40,
    heightMax: 100,
    editorClass: 'minimal',
  };
  froalaOptionsBody: CipoFroalaOptions = {
    ...FROALA_TOOLBAR_MEDIUM,
  };
  tributeOptions = signal<CipoTributeOptions>({});

  form = this.fb.group({
    jobName: ['', Validators.required],
    description: ['', Validators.required],
    startDate: [moment().toISOString(), Validators.required],
    timeOfDay: [DEFAULT_TIME, Validators.required],
    subject: ['', Validators.required],
    emailBody: ['', Validators.required],
    scheduleFrequency: [<ScheduleFrequencyEnum>ScheduleFrequencyEnum.WEEKLY, Validators.required],
    moduleIds: [<number[]>[], CipoValidators.requireMinOne],
    sendTo: [<SendMailToEnum[]>[SendMailToEnum.BIC_ROLE], CipoValidators.requireMinOne],
    additionalRecipients: [<number[]>[]],
  });

  modulesSearchControl = new FormControl('');
  modulesFilteredOptions = signal<ScheduledEmailModule[]>([]);

  additionalRecipientsSearchControl = new FormControl('');
  additionalRecipientsFilteredOptions = signal<ScheduledEmailAdditionalRoles[]>([]);

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ScheduledEmailRow,
    private dialogRef: MatDialogRef<ScheduledEmail>,
    private destroyRef: DestroyRef,
    private scheduledEmailsService: ScheduledEmailService,
  ) {
    this.modulesSearchControl.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.short)).subscribe(value => {
      this.modulesFilteredOptions.set(this.modules.filter(o => o.value.toLowerCase().includes(value.toLowerCase())));
    });
    this.additionalRecipientsSearchControl.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.short)).subscribe(value => {
      this.additionalRecipientsFilteredOptions.set(
        this.additionalRoles.filter(o => o.value.toLowerCase().includes(value.toLowerCase())),
      );
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.initForm();
    }
    this.populateDropdownData();
    this.handleFrequency();
    this.scheduledEmailsService.getPlaceholders().subscribe(variables => {
      this.tributeOptions.set({ variables });
    });
  }

  populateDropdownData() {
    const { sendTo, additionalRecipients, moduleIds } = this.form.controls;
    this.scheduledEmailsService.getAdditionalRoles().subscribe(r => {
      this.additionalRoles = r.sort((a, b) => a.value.localeCompare(b.value));
      this.additionalRecipientsFilteredOptions.set(this.additionalRoles);
    });
    this.scheduledEmailsService.getModulesDropdownItems().subscribe(m => {
      this.modules = m.sort((a, b) => a.value.localeCompare(b.value));
      this.modulesFilteredOptions.set(this.modules);
      const moduleKeys: number[] = m.map(({ key, value }) => {
        this.modulesDict[key] = value;
        return key;
      });
      if (this.data) {
        this.allModulesSelected = this.data.moduleIds.length === m.length;
      } else {
        moduleIds.setValue(moduleKeys, { emitEvent: false });
        this.allModulesSelected = true;
      }
    });

    sendTo.valueChanges
      .pipe(
        tap(
          val =>
            !val.includes(SendMailToEnum.DOCUMENT_INITIATOR) &&
            additionalRecipients.disabled &&
            additionalRecipients.enable(),
        ),
        filter(val => val.includes(SendMailToEnum.DOCUMENT_INITIATOR)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        additionalRecipients.reset();
        additionalRecipients.disable();
      });

    moduleIds.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => (this.allModulesSelected = v.length === this.modules.length));
  }

  toggleSelectAllModules(checked: boolean) {
    this.allModulesSelected = checked;
    const arr = checked ? this.modules.map(m => m.key) : [];
    this.form.controls.moduleIds.setValue(arr, { emitEvent: false });
  }

  handleFrequency() {
    const { scheduleFrequency, startDate, timeOfDay } = this.form.controls;

    scheduleFrequency.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(frequency => {
      if (frequency === ScheduleFrequencyEnum.MANUAL) {
        this.isManual = true;
        this.form.patchValue({
          startDate: null,
          timeOfDay: null,
        });
        startDate.disable();
        timeOfDay.disable();
        this.weekDay = undefined;
        return;
      }

      if (this.isManual) {
        this.isManual = false;
        startDate.enable();
        timeOfDay.enable();
        startDate.setValue(moment().toISOString());
        timeOfDay.setValue(DEFAULT_TIME);
      }

      const d = startDate.value;
      if (frequency === ScheduleFrequencyEnum.WEEKLY && d) {
        const day = moment(d).weekday();
        this.weekDay = day === 0 ? 7 : day;
      } else {
        this.weekDay = undefined;
      }
    });

    startDate.valueChanges
      .pipe(
        filter(() => scheduleFrequency.value === ScheduleFrequencyEnum.WEEKLY),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(d => (this.weekDay = moment(d).weekday()));
  }

  initForm() {
    const {
      description,
      scheduleFrequency,
      jobName,
      additionalRecipients,
      sendTo,
      moduleIds,
      startDate,
      timeOfDay,
      jobAttributes: { emailBody, subject },
    } = this.data;
    const timeArr = timeOfDay.split(':'); // The time is saved as HH:mm:ss in BE
    this.weekDay = scheduleFrequency === ScheduleFrequencyEnum.WEEKLY ? moment(startDate).weekday() : undefined;
    this.form.patchValue({
      additionalRecipients,
      description,
      emailBody,
      jobName,
      subject,
      moduleIds,
      sendTo,
      scheduleFrequency,
      startDate: moment(startDate).toISOString(),
      timeOfDay: moment().hour(Number(timeArr[0])).minute(Number(timeArr[1])).toISOString(),
    });
    this.data.isEnabled && this.form.controls.startDate.disable();
    if (sendTo.includes(SendMailToEnum.DOCUMENT_INITIATOR)) {
      this.form.controls.additionalRecipients.disable();
    }

    this.minDate = moment(startDate).startOf('day').toDate();
  }

  saveChanges(sendNow = false) {
    const { emailBody, subject, timeOfDay, startDate, ...formProps } = this.form.value;
    let data: ScheduledEmail;
    const time = moment(timeOfDay).second(0).format(DATE_TIME_FORMATS.time);
    const date = moment(startDate).format(DATE_TIME_FORMATS.date);
    if (this.data) {
      const { frequency, ...dataProps } = this.data;
      data = {
        ...dataProps,
        ...formProps,
        startDate: date,
        timeOfDay: time,
        dayOfWeek: dataProps.dayOfWeek ?? this.weekDay,
        jobAttributes: {
          ...this.data?.jobAttributes,
          emailBody,
          subject,
        },
      };
    } else {
      const { additionalRecipients, jobName, description, moduleIds, scheduleFrequency, sendTo } = formProps;
      data = {
        ...(this.weekDay && { dayOfWeek: this.weekDay }),
        ...(sendNow && { sendNow }),
        jobName,
        description,
        additionalRecipients,
        moduleIds,
        scheduleFrequency,
        sendTo,
        startDate: date,
        timeOfDay: time,
        jobType: 1,
        jobAttributes: {
          emailBody,
          subject,
        },
      };
    }
    this.dialogRef.close(data);
  }

  updateSubject(content: string) {
    this.form.controls.subject.setValue(content);
    this.form.controls.subject.markAsDirty();
  }

  updateEmailBody(content: string) {
    this.form.controls.emailBody.setValue(content);
    this.form.controls.emailBody.markAsDirty();
  }
}
