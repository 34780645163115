import { KeyValueType } from '../../../models/common';
import { TableRow } from '../../../shared/components/data-list/common';

export enum TenantSettingsEnum {
  pp_pay_over_100 = 1,
  time_zone = 2,
  dashboard_activities_display = 3,
  percent_near_overdue = 4,
  use_only_esignatures = 5,
  load_more = 6,
  more_than_one_payments_open = 7,
  pp_pay_on_negative = 8,
  name_conv_to_7bit_ascii = 9,
  save_attachments_to_project_folder = 10,
  user_allocation = 11,
  allow_replay_notifications = 12,
  user_rate = 13,
  menu_folder_auto_collapse = 14,
  use_doc_minor = 15,
  auto_expand_adjustments_section = 16,
  measurement_system = 17,
  auto_extend_accrual_end_date = 22,
  auto_extend_recurring_end_date = 23,
}

export type TenantSettingType = 'number' | 'string' | 'rangePercent' | 'boolean' | 'select';

export type TenantSetting = {
  id: TenantSettingsEnum;
  key: string;
  name: string;
  description?: string;
  value?: TenantSettingValue;
  type?: TenantSettingType;
};

export const SETTINGS_TYPES: { [key in TenantSettingsEnum]: TenantSettingType } = {
  [TenantSettingsEnum.pp_pay_over_100]: 'boolean',
  [TenantSettingsEnum.time_zone]: 'select',
  [TenantSettingsEnum.dashboard_activities_display]: 'boolean',
  [TenantSettingsEnum.percent_near_overdue]: 'rangePercent',
  [TenantSettingsEnum.use_only_esignatures]: 'boolean',
  [TenantSettingsEnum.load_more]: 'select',
  [TenantSettingsEnum.more_than_one_payments_open]: 'boolean',
  [TenantSettingsEnum.pp_pay_on_negative]: 'boolean',
  [TenantSettingsEnum.name_conv_to_7bit_ascii]: 'boolean',
  [TenantSettingsEnum.save_attachments_to_project_folder]: 'boolean',
  [TenantSettingsEnum.user_allocation]: 'boolean',
  [TenantSettingsEnum.allow_replay_notifications]: 'boolean',
  [TenantSettingsEnum.user_rate]: 'boolean',
  [TenantSettingsEnum.menu_folder_auto_collapse]: 'boolean',
  [TenantSettingsEnum.use_doc_minor]: 'boolean',
  [TenantSettingsEnum.auto_expand_adjustments_section]: 'boolean',
  [TenantSettingsEnum.measurement_system]: 'select',
  [TenantSettingsEnum.auto_extend_accrual_end_date]: 'boolean',
  [TenantSettingsEnum.auto_extend_recurring_end_date]: 'boolean',
};

export const TenantSettingListIds: Map<TenantSettingsEnum, number> = new Map<TenantSettingsEnum, number>([
  [TenantSettingsEnum.measurement_system, -35],
]);

export const TenantSettingListValues: Map<TenantSettingsEnum, KeyValueType<number | string>[]> = new Map<
  TenantSettingsEnum,
  KeyValueType<number | string>[]
>([[TenantSettingsEnum.measurement_system, []]]);

export type TenantSettingValue = string | number | boolean;

export interface TenantSettingRow extends TableRow, TenantSetting {
  rawValue: TenantSettingValue;
}
