import { cipo } from 'cipo';

cipo.factory("HeaderOrFooter", function ($q, Model, Form, Dictionaries, URI, Message, Permissions) {

    var HeaderOrFooter = Model.extend(function (obj, isSetDefault, isHeader) {
        var self = this;
        self.isHeader = (obj || {}).isHeader || false;
        self.init(false, !(obj || {}).id?true:false);
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    self.properties[key] = obj[key];
            }
            
        }
        if (isSetDefault) {
            self.get_Templates(isHeader);
        }
        self.isSetDefault = isSetDefault || false;
        this.form = new Form(this.properties);
        this.form.initializing = true;
        if ((obj || {}).id) self.get_Data();
        Dictionaries.Placeholders()
            .then(function (r) {
                self.form.set_Description({
                    name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
                    description: { label: 'Description', type: 'textarea', validation: { maxChars: 100 } },
                    height: { label: 'Height (inches)', type: 'number', validation: { required: true, isNumber: true } },
                    content: { label: 'Content', type: 'editor', hints: r, validation: { required: true } },
                    isGlobal: { label: 'Set as default', type: 'checkbox' },
                    
                });

                self.form.setTemplate('grid', [
                    { name: 40, description: 40, height: 20 },
                    { content: 100 },
                    { isGlobal: 100 },
                ]);
            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {
                self.form.initializing = false;
            });
    });

    HeaderOrFooter.prototype.init = function (isAdd, isNew) {
        var self = this;
        this.properties = {
            id: null,
            name: "",
            description: "",
            content: "",
            height: null,
            isHeader: self.isHeader,
            isGlobal: false,
            permissions: isAdd || isNew ? [1, 2] : [2]
        };
        if (isNew || isAdd) {

            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    }

    HeaderOrFooter.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        var dataURL = URI.HEADER_FOOTER_SETTINGS.DETAILS;
        self[dataURL.method](dataURL, { id: self.properties.id })
            .then(function (result) {
                if (result) {
                    if (!result.permissions) result.permissions = [1,2,3,4,5];
                    for (var key in result) {
                        if (result.hasOwnProperty(key)) {
                            self.properties[key] = result[key];
                        }
                    }
                    

                    self.isHeader = result.isHeader || false;
                    self.form.store_Data();
                    self.form.set_Data(self.properties);
                    self.get_Templates();
                    p.resolve();

                    

                    self.operations = {};
                    if ((self.properties.permissions || []).length) {
                        for (var j = 0; j < self.properties.permissions.length; j++) {
                            Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                        }
                    }
                    if (!self.operations.Update) {
                        self.form.editMode = false;
                    }
                }
            }).catch(function (e) {
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.form.loading = false;
            });

        return p.promise;
    }

    HeaderOrFooter.prototype.get_Templates = function (isDefaultHeader) {
        var self = this;
        var p = $q.defer();
        self.isTemplatesLoaded = false;
        var dataURL = URI.HEADER_FOOTER_SETTINGS.TEMPLATES_DICT;
        var urlParams = {};
        if (self.properties.id) urlParams.id = self.properties.id;
        if (!self.properties.id) urlParams.isHeader = isDefaultHeader ? true : false;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' })
            .then(function (r) {
                self.templatesDict = r;
                self.backupTemplates = angular.copy(r);
                p.resolve();
            }).catch(function (e) {
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.isTemplatesLoaded = true;
            });

        return p.promise;
    }

    HeaderOrFooter.prototype.sync_Templates = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var dataURL = !self.properties.id ? URI.HEADER_FOOTER_SETTINGS.BULK_DEFAULT_HEADER_FOOTER : URI.HEADER_FOOTER_SETTINGS.BULK_ASSIGN_HEADER_FOOTER;
        
        if (self.properties.id) {
            var toSend = {
                templates: self.templatesDict,
                id: self.properties.id
            };
        } else toSend = self.templatesDict;
        self[dataURL.method](dataURL, { body: toSend })
            .then(function () {
                Message.info((self.isHeader ? "Header" : "Footer") + " saved successfully.");
                if (!isAdd) self.backupTemplates = angular.copy(self.templatesDict);
                else {
                    self.init(true);
                    self.form.store_Data();
                    self.form.set_Data(self.properties);
                }
                p.resolve();
            }).catch(function (e) {
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    HeaderOrFooter.prototype.checkTemplatesDirty = function () {
        var self = this;
        return !angular.equals(self.templatesDict, self.backupTemplates);
    }

    HeaderOrFooter.prototype.restoreTemplates = function () {
        var self = this;
        self.templatesDict = angular.copy(self.backupTemplates);
    }

    HeaderOrFooter.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;

        self.form.validate();
        if (self.form.isValid) {
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.properties
            };
            var dataURL = self.properties.id ? URI.HEADER_FOOTER_SETTINGS.UPDATE : URI.HEADER_FOOTER_SETTINGS.CREATE;
            self[dataURL.method](dataURL, params)
                .then(function (r) {
                    p.resolve(r);
                    Message.info((self.isHeader ? "Header" : "Footer") + " saved successfully.");
                    if (!self.properties.id) {
                        self.properties.id = r;
                        self.get_Data();
                        if (!isAdd) self.get_Templates();
                        return;
                    }
                    if (isAdd) {
                        self.init(true);
                        
                    }
                    self.form.store_Data();
                    self.form.set_Data(self.properties);
                    
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                });
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }

    return HeaderOrFooter;
});
