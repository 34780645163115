import { Component } from '@angular/core';

import { ScheduledEmailService } from '../../../module/instance/scheduled-emails/scheduled-emails.service';
import { DemoScheduledEmailsService } from './demo-scheduled-emails.service';

@Component({
  selector: 'app-demo-scheduled-emails',
  template: '<app-scheduled-emails></app-scheduled-emails>',
  providers: [{ provide: ScheduledEmailService, useClass: DemoScheduledEmailsService }],
})
export class DemoScheduledEmailsComponent {}
