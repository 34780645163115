export enum AppColors {
  primary_50 = 'primary-50',
  primary_100 = 'primary-100',
  primary_200 = 'primary-200',
  primary_300 = 'primary-300',
  primary_400 = 'primary-400',
  primary_500 = 'primary-500',
  primary_600 = 'primary-600',
  primary_700 = 'primary-700',
  primary_800 = 'primary-800',
  primary_900 = 'primary-900',
  accent_50 = 'accent-50',
  accent_100 = 'accent-100',
  accent_200 = 'accent-200',
  accent_300 = 'accent-300',
  accent_400 = 'accent-400',
  accent_500 = 'accent-500',
  accent_600 = 'accent-600',
  accent_700 = 'accent-700',
  accent_800 = 'accent-800',
  accent_900 = 'accent-900',
  warn_50 = 'warn-50',
  warn_100 = 'warn-100',
  warn_200 = 'warn-200',
  warn_300 = 'warn-300',
  warn_400 = 'warn-400',
  warn_500 = 'warn-500',
  warn_600 = 'warn-600',
  warn_700 = 'warn-700',
  warn_800 = 'warn-800',
  warn_900 = 'warn-900',
}

export const PRESET_COLOR = '#008080';
export const PRESET_BG_COLOR = '#e4f6ef';
