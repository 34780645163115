<div class="m-x-md full-height">
    <app-data-list-command [filters]="filters" (applyfilters)="search($event)">
        @if (isinfiniteScroll()) {
            <cipo-infinite-data-list
                [tableColumns]="tableColumns"
                [tableData]="tableData"
                class="data-list"
                [loading]="isLoading"
                [options]="tableOptions"
                (sortChanged)="handleSort($event)"
                (paginationUpdated)="handlePagination($event)"
                (rowClicked)="openPopup($event)" />
        } @else {
            <cipo-paged-data-list
                [tableColumns]="tableColumns"
                [tableData]="tableData"
                [loading]="isLoading"
                class="data-list"
                [options]="tableOptions"
                (sortChanged)="handleSort($event)"
                (paginationUpdated)="handlePagination($event)"
                (rowClicked)="openPopup($event)" />
        }
    </app-data-list-command>
</div>
