import { cipo } from 'cipo';

cipo.controller('duplicateModuleController',
function ($scope, $state, $http, URI, Message, ModuleN, userService, FileSaver, Blob, $mdDialog, moduleToDuplicate, exportList) {

    $scope.module = userService.system.modules[$state.current.code];
    $scope.exportList = exportList;

    var initialTabList = [
        { key: 1, value: "Details", selected: true },
        { key: 2, value: "Roles", selected: false },
        { key: 3, value: "Relations", selected: false },
        {
            key: 4, value: "Fields", selected: false,
            dependencies: [
                { key: 8, value: "Forms", selected: false },
                { key: 9, value: "Data List Settings", selected: false }]
        },
        {
            key: 5, value: "Workflow", selected: false,
            dependencies: [
                { key: 10, value: "Transition Groups", selected: false },
                { key: 11, value: "Signatures", selected: false }]
        },
        { key: 6, value: "Printouts", selected: false },
        { key: 7, value: "Notifications", selected: false }
    ];

    $scope.changeSelectState = function (isDeselect) {
        for (var i = 0; i < $scope.tabsList.length; i++) {
            if (!$scope.tabsList[i].disabled) $scope.tabsList[i].selected = isDeselect ? false : true;
            if (($scope.tabsList[i].dependencies || []).length) {
                for (var j = 0; j < $scope.tabsList[i].dependencies.length; j++) {
                    if (!$scope.tabsList[i].dependencies[j].disabled) $scope.tabsList[i].dependencies[j].selected = isDeselect ? false : true;
                }
            }
        }
        if (!isDeselect) $scope.sectionError = false;
        if ($scope.duplicateMod)
            if (isDeselect) $scope.duplicateMod.set_properties({
                name: $scope.duplicateMod.properties.name,
                abbreviation: $scope.duplicateMod.properties.abbreviation
            });
            else $scope.duplicateMod.set_properties(extractInfoFromModule($scope.sourceMod));
    }

    $scope.hide = function () {
        $mdDialog.hide();
    };

    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    var extractInfoFromModule = function (m) {
        var newMod = {};
        var excludeProps = ['id', 'name', 'abbreviation', '_name', 'selected'];
        for (var key in m) {
            if (m.hasOwnProperty(key) && excludeProps.indexOf(key) == -1) {
                newMod[key] = m[key];
            }
        }
        if ($scope.duplicateMod) {
            newMod.name = $scope.duplicateMod.properties.name;
            newMod.abbreviation = $scope.duplicateMod.properties.abbreviation;
        }
        return newMod;
    }

    $scope.checkTabDependencies = function (t) {
        if (t.selected) $scope.sectionError = false;
        if (!t.selected && (t.dependencies || []).length)
            for (var i = 0; i < t.dependencies.length; i++) {
                t.dependencies[i].selected = false;
            }
        if (t.key == 1 && $scope.duplicateMod) {
            if (t.selected) $scope.duplicateMod.set_properties(extractInfoFromModule($scope.sourceMod));
            else $scope.duplicateMod.set_properties({
                name: $scope.duplicateMod.properties.name,
                abbreviation: $scope.duplicateMod.properties.abbreviation
            });
        }
    }

    $scope.icons = userService.system.icons.list;
    $scope.setIcon = function (iconId) {
        $scope.duplicateMod.properties.iconId = iconId;
        $scope.selectedIcon = iconId;
        $scope.$apply();
    };

    var processSections = function () {
        var list = [];
        for (var i = 0; i < $scope.tabsList.length; i++) {
            if ($scope.tabsList[i].selected) list.push($scope.tabsList[i].key);
            if (($scope.tabsList[i].dependencies || []).length) {
                for (var j = 0; j < $scope.tabsList[i].dependencies.length; j++) {
                    if ($scope.tabsList[i].dependencies[j].selected)
                        list.push($scope.tabsList[i].dependencies[j].key);
                }
            }
        }
        return list;
    }

    $scope.saveDuplicate = function () {
        $scope.sectionError = false;
        $scope.duplicateMod.form.validate();
        if ($scope.duplicateMod.form.isValid && processSections().length) {
            // $scope.duplicateMod.isBusy = true;
            $scope.duplicateMod.form.loading = true;
            var dataURL = URI.MODULES.COPY;
            var urlParams = {
                roleId: $scope.duplicateMod.selectedRoleId,
                id: $scope.sourceMod.id
            };
            var bodyParams = {
                details: $scope.duplicateMod.properties,
                sections: processSections()
            }

            $scope.duplicateMod[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams })
                .then(function (r) {
                    Message.info('Module duplicated successfully');
                    $scope.hide();
                })
                .catch(function (e) {
                    $scope.duplicateMod.form.catch(e);
                    $scope.duplicateMod.form.loading = false;
                });
        } else {
            if (!processSections().length) $scope.sectionError = true;
        }
        
    }

    $scope.duplicateModule = function () {
        $scope.sourceMod = moduleToDuplicate;
        $scope.duplicateMod = new ModuleN(extractInfoFromModule(moduleToDuplicate));
        if (moduleToDuplicate?.iconId) $scope.selectedIcon = moduleToDuplicate.iconId;
        $scope.tabsList = angular.copy(initialTabList);
        if (!$scope.duplicateMod) $scope.tabsList[0].disabled = true;
        $scope.changeSelectedRole();
    };

    $scope.changeSelectedRole = function (r) {
        $scope.selectedRole = r || ($scope.module.roles || [])[0];
        ($scope.duplicateMod || {}).selectedRoleId = $scope.selectedRole.key;
    };

    $scope.saveExport = function () {
        var ids = [];

        for (var i = 0; i < $scope.exportList.length; i++) {
            if ($scope.exportList[i].selected) ids.push($scope.exportList[i].id);
        }

        if (ids.length) {
            $scope.isSavingExport = true;
            var dataURL = URI.MODULES.EXPORT;
            var url = dataURL.toString() + "?roleId=" + $scope.selectedRole.key;
            // urlParams = { roleId: $scope.selectedRole.key };
            var bodyParams = {
                ids: ids,
                sections: processSections()
            };

            $http[dataURL.method](url, bodyParams, { responseType: "arraybuffer" })
                .then(function (r) {
                    var type = r.headers('Content-Type');
                    var disposition = r.headers('Content-Disposition');
                    if (disposition) {
                        var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                        if (match[1])
                            defaultFileName = match[1];
                    }
                    defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                    var blob = new Blob([r.data], { type: type });
                    FileSaver.saveAs(blob, defaultFileName);
                    $scope.hide();
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.isSavingExport = false;
                })

        } else {
            Message.warning('Please select the item(s) to export');
        }

    }

    $scope.duplicateModule();
});
