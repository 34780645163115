import { cipo } from 'cipo';

cipo.factory("FormulasMod", function (Model, Screen, $q, URI, Message) {
    var FormulasMod = Model.extend(function (parentId, numericFieldsDict, fieldsDictSource) {
        var self = this;
        var options = {
            minCols: 12,
            maxCols: 12,
            minRows: 3,
            maxRows: 3,
            maxItemCols: 12,
            outerHeight: 200
        };
        self.isFieldsDictLoaded = fieldsDictSource ? true : false;
        self.fieldsDictSource = fieldsDictSource || [];
        self.numericFieldsDict = numericFieldsDict || [];
        var setScreens = function () {
            self.topScreen = new Screen(parentId, 1, false, self.fieldsDictSource, options);
            self.bottomScreen = new Screen(parentId, 2, false, self.fieldsDictSource, options);
        }


        if (self.isFieldsDictLoaded) {
            setScreens();
        } else {
            self.get_FieldsDict()
                .then(function () { setScreens(); })
                .catch(function () { })
        }

    });

    FormulasMod.prototype.colSize = function (item, isIncrease) {
        var self = this;
        if (isIncrease) item.properties.width++;
        else item.properties.width--;
        self.set_fieldsWidth();
        //self.sync();
    }

    FormulasMod.prototype.set_fieldsWidth = function () {
        var self = this;
        var parts = 0;
        // var fields = fields || self.inGrid.list;
        for (var i = 0; i < self.propFields.length; i++) {
            parts += self.propFields[i].properties.width;
        }
        for (var i = 0; i < self.propFields.length; i++) {
            self.propFields[i].widthPercentage = 100 / parts * self.propFields[i].properties.width;
        }
        // console.error('fields', self.propFields)

    }//

    

    return FormulasMod;
});
