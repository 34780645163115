import { KeyValueType } from 'src/app/models/common';

export interface PrimaryUser {
  roleId: number;
  roleName: string;
  userId: number;
  users: KeyValueType<number, string>[];
}

export interface SyncPrimaryUsersModel {
  contractId: number;
  primaries: PrimaryUser[];
}

export enum FilterType {
  All = 1,
  Assigned = 2,
  NotAssigned = 3,
}
