import { cipo } from 'cipo';

cipo.controller('importUploadController',
    function ($scope, MODAL, Upload, $timeout, action, $mdDialog, acceptedMimeTypes, fileService) {

        $scope.disabledBtn = false;
        $scope.message = "Upload";
        $scope.acceptedMimeTypes = acceptedMimeTypes || [];
        $scope.modalInfo = MODAL;

        //var generateFileUrl = action ? action.generateFileIdUrl : "";
        //var startUploadUrl = action ? action.startUploadUrl : "";
        //var uploadChunkUrl = action ? action.uploadChunkUrl : "";
        var saveFileInfoUrl = action ? action.saveFileInfoUrl : "";
        var uploadUrl = action ? action.uploadUrl : "";

        var otherParams = action ? action.urlParams : otherParams;
        var headerParams = action ? action.headerParams : {};

        $scope.accept = action ? action.accept : "*";
        $scope.multiple = action ? action.multiple : true;

        $scope.files = [];
        $scope.isUploadInProgress = false;
        $scope.$watch('files', function (n, o) {
            if(n!=o) $scope.upload($scope.files);
        });

        $scope.filesToUpload = [];
        $scope.fileUploaded = 0;
        var isModified = false;
        var urlParamsString = "";

        if (action.urlParams)
            for (var key in action.urlParams()) {
                if (action.urlParams().hasOwnProperty(key)) {
                    urlParamsString += '&' + key + "=" + action.urlParams()[key];
                }
            }
        else action.urlParams = function () { return {}; };

        $scope.upload = function (files, errFiles) {

            if (action.multiple) {
                $scope.filesToUpload = files;
                // loop
            } else {
                upload(files, errFiles);
                $scope.filesToUpload = [files];
            }
        }

        var upload = function (file, errFiles) {
            $scope.f = file;
            $scope.errFile = errFiles && errFiles[0];

            fileService.setMimeTypeFromExt(file);

            if (file) {
                $scope.isUploading = true;

                urlParamsString = '?mimeType=' + file.mimeTypeQueryString + urlParamsString;
                var upload = Upload.upload({
                    url: action.uploadUrl.toString() + urlParamsString,
                    method: action.uploadUrl.method.toString(),
                    data: { file: file }
                });

                upload.then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                        // fileForProcessing.result = response.data;
                        $scope.isUploading = false;
                        $scope.fileUploaded++;
                        if (action.closesOnFinish && $scope.filesToUpload.length == $scope.fileUploaded) {
                            $mdDialog.hide({ file: file });
                        }
                    });
                }, function (response) {
                    if (response.status > 0)
                        $scope.errorMsg = response.status + ': ' + response.data;
                    $scope.isUploading = false;
                }, function (evt) {
                        file.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                });
            }
        }

        $scope.closeModal = function () {
            if (isModified) $mdDialog.hide();
            else $mdDialog.cancel();
        };

        $scope.cancelUpload = function () {
            $scope.uploadObject.abort();
            $mdDialog.cancel();
        };
    });
