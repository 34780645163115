import { Component, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { CipoDateTimeComponent } from '../../../shared/components/fields/cipo-date-time/cipo-date-time.component';
import { CipoDateTimeControl } from '../../../shared/components/fields/common';
import { DemoDateTimeFields } from '../../data/demo-cipo-fields';
import { KeyValuePipe } from '@angular/common';

@Component({
  selector: 'app-demo-cipo-date-time',
  standalone: true,
  imports: [CipoDateTimeComponent, FlexLayoutModule, KeyValuePipe],
  templateUrl: './demo-cipo-date-time.component.html',
})
export class DemoCipoDateTimeComponent {
  form = signal(new FormGroup<{ [x: string]: CipoDateTimeControl }>({}));

  constructor() {
    DemoDateTimeFields.forEach(field => {
      const { name, defaultValue, ...props } = field;
      const control = new CipoDateTimeControl(defaultValue, props);
      if (props.required) {
        control.setValidators(Validators.required);
      }
      this.form().addControl(name, control);
    });
  }
}
