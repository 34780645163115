import { cipo } from 'cipo';

cipo.directive("entityfieldsdrawer", function (TABLE_ROW) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "data": "="
        },
        templateUrl: "/ng/views/directives/system/entityfieldsdrawer.html",
        controller: function ($scope) {
            $scope.basic_cellHeight = TABLE_ROW.height;
            
        }
    }
});
