import { Component, input } from '@angular/core';
import { CipoControl, CipoFieldTypes } from '../common';
import { CipoFieldsModule, SharedComponentsModule } from '../../../modules';

@Component({
  selector: 'cipo-control',
  standalone: true,
  imports: [CipoFieldsModule, SharedComponentsModule],
  templateUrl: './cipo-control.component.html',
})
export class CipoControlComponent {
  control = input<CipoControl>();
  FIELD_TYPES = CipoFieldTypes;
}
