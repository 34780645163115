<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ dialogData?.name ?? "holidays.addNew" | translate }}</p>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t">
        @if (form) {
            <cipo-text [control]="form.controls.name" />
            <div fxLayout fxLayoutGap="16px" fxLayoutAlign="center end" class="m-t-md">
                <cipo-date-time fxFlex="50" [control]="form.controls.date" />
                <cipo-checkbox fxFlex="50" [control]="form.controls.isWorkDay" />
            </div>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{ "general.cancel" | translate }}</button>
    <button mat-raised-button [disabled]="form?.invalid || !form?.dirty" color="primary" (click)="save()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
