import { cipo } from 'cipo';

cipo.controller('editDefaultHFController',
    function ($scope, Message, MODAL, HeaderOrFooter, operations, $q, $mdDialog) {

        $scope.modalInfo = MODAL;

        $scope.operations = operations || {};
        $scope.disabledBtn = false;

        $scope.actionBtnDisabled = false;
        //$scope.listdata = obj;
        $scope.header = new HeaderOrFooter({}, true, true);
        $scope.footer = new HeaderOrFooter({}, true);

        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            if (!$scope.closingModal) {
                //$scope.headerOrFooter.form.restore_Data();
                $scope.header.restoreTemplates();
                $scope.footer.restoreTemplates();

                $scope.tabWarning = false;
                $scope.hightlight = false;
                $scope.disabledBtn = false;
                $scope.selectedIndex = $scope.navToIndex;
            } else {
                $scope.close(true);
            }

        }
        $scope.$on('tab-change', function (event, tabEvent) {
            if (!$scope.header.checkTemplatesDirty() && !$scope.footer.checkTemplatesDirty()) {
                tabEvent.accept();
            } else {
                // capture the index of the tab cliked
                $scope.navToIndex = tabEvent.index;
                // set the warning params
                $scope.hightlight = true;
                $scope.tabWarning = true;

                $scope.disabledBtn = true;
                // prevent tab change
                tabEvent.reject();
            }


        });

        $scope.updateTabInfo = function () {
            $scope.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $scope.close(true);
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;
            $scope.closingModal = false;
        };
        $scope.isModified = false;

        $scope.save = function () {
            var p = $q.defer();
            $scope.disabledBtn = true;
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.message = "Saving...";
            var action = $scope.selectedIndex == 0 ? $scope.header.sync_Templates() : $scope.footer.sync_Templates();
            action
                .then(function (result) {
                    Message.info('Changes saved successfully');
                    $scope.isModified = true;
                    p.resolve();
                    $scope.disabledBtn = false;
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
            return p.promise;
        };



        $scope.close = function (isWarning) {
            if ((!$scope.header.checkTemplatesDirty() && !$scope.footer.checkTemplatesDirty()) || isWarning)
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            else {
                $scope.hightlight = true;
                $scope.tabWarning = true;
                $scope.closingModal = true;
            }
        };

    });
