import { Component, input, signal, inject, model } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { CipoAttachmentConfig, CipoAttachmentControl } from '../common';
import { CipoFormModule } from '../../../modules/forms.module';
import { AttachmentModel } from '../../../../models/module/fields/main';
import { ImageSourcePipe } from '../../../pipes/string-pipes/image-source.pipe';
import { AttachmentsDialogComponent } from '../../../../module/instance/attachments-dialog/attachments-dialog.component';
import { UserService } from '../../../services/user.service';
import { FileFactoryService, FileServiceType, IFileService } from 'src/app/module/instance/my-drive/common/services/file-factory.service';

@Component({
  selector: 'cipo-attachment',
  standalone: true,
  imports: [CipoFormModule, MatMenuModule, ImageSourcePipe, TranslateModule],
  providers: [FileFactoryService],
  templateUrl: './cipo-attachment.component.html',
})
export class CipoAttachmentComponent {
  // TODO: get these from store
  driveName = signal('My drive');
  projectFilesName = signal('Project files');

  fieldData = signal<CipoAttachmentConfig>({});

  dialog = inject(MatDialog);
  userService = inject(UserService);
  fileFactoryService = inject(FileFactoryService);

  private fileService: IFileService;

  constructor() {
    this.fileService = this.fileFactoryService.getServiceType(FileServiceType.MyDrive);
  }
  
  /** Form control that holds fieldData */
  formControl = input<CipoAttachmentControl, CipoAttachmentControl>(null, {
    transform: control => {
      if (control.fieldData) {
        this.fieldData.set(control.fieldData);
      }
      //   will use it when the form control is readonly
      this.attachments.set(control.value);
      return control;
    },
    alias: 'control',
  });

  /** For view mode, to use without formControl */
  attachments = model<AttachmentModel[]>(null);
  config = input<CipoAttachmentConfig, CipoAttachmentConfig>(null, {
    transform: config => {
      this.fieldData.set(config);
      return config;
    },
  });

  removeChip(index: number) {
    if (this.formControl()) {
      const chipsLeft = this.formControl().value.filter((_, i) => i !== index);
      this.formControl().setValue(chipsLeft);
      this.formControl().markAsTouched();
    } else {
      const chipsLeft = this.attachments().filter((_, i) => i !== index);
      this.attachments.set(chipsLeft);
    }
  }

  addAttachments() {
    this.dialog
      .open(AttachmentsDialogComponent, {
        panelClass: ['cipo-dialog', 'classic', 'full-height'],
        ...this.userService.getResponsiveDialogSize().lg,
        data: this.formControl() ? this.formControl().value : this.attachments(),
      })
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe((attachments: AttachmentModel[]) => {
        if (this.formControl()) {
          this.formControl().setValue(attachments);
          this.formControl().markAsTouched();
        }
        this.attachments.set(attachments);
      });
  }

  openNewTab(item: AttachmentModel) {
    this.fileService.getFileUrl(item.id, item.versionId, true).subscribe(url => window.open(url, '_blank'));
  }

  download(item: AttachmentModel) {
    this.fileService.getFileUrl(item.id, item.versionId).subscribe(url => {
      this.fileService.downloadFileFromUrl(url);
    });
  }

  downloadAll() {
    const attachments = this.formControl() ? this.formControl().value : this.attachments();
    if (!attachments || !attachments.length) {
      return;
    }
    this.fileService.getFilesUrl(attachments.map(f => f.versionId)).subscribe(files => {
      files.forEach(({ url }) => this.fileService.downloadFileFromUrl(url));
    });
  }

  //   To be implemented in the next task
  openAttachmentDialog(index: number) {
    console.log('open Attachment Dialog', index);
  }

  saveToDrive(item: AttachmentModel) {
    console.log('saveToDrive ' + item.name);
  }

  saveToProjectFiles(item: AttachmentModel) {
    console.log('saveToProjectFiles ' + item.name);
  }
}
