import { cipo } from 'cipo';

cipo.directive("editlist", function () {
    return {
        restrict: "E",
        scope: {
            "list": "="
        },
        templateUrl: "/ng/views/directives/system/editlist.html",
        controller: function ($scope) {
            //$scope.list = new List($scope.listdata);

        }

    }
});
