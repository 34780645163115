import { NgModule } from '@angular/core';
import { BreakPoint, BREAKPOINT } from '@ngbracket/ngx-layout';
@NgModule({
  providers: [
    {
      provide: BREAKPOINT,
      useFactory: customizeBreakPoints,
      multi: true,
    },
  ],
})
export class CustomBreakPointsModule {}

export const BREAKPOINTS = {
  xs: 400,
  sm: 660,
  md: 980,
  lg: 1280,
  max: 7680,
};

export const BREAKPOINTS_MEDIA_QUERY = {
  xs: `screen and (min-width: 0px) and (max-width: ${BREAKPOINTS.xs - 0.01}px)`,
  sm: `screen and (min-width: ${BREAKPOINTS.xs}px) and (max-width: ${BREAKPOINTS.sm - 0.01}px)`,
  md: `screen and (min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 0.01}px)`,
  lg: `screen and (min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.lg - 0.01}px)`,
  xl: `screen and (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.max - 0.01}px)`,
  lt_sm: `screen and (max-width: ${BREAKPOINTS.xs - 0.01}px)`,
  lt_md: `screen and (max-width: ${BREAKPOINTS.sm - 0.01}px)`,
  lt_lg: `screen and (max-width: ${BREAKPOINTS.md - 0.01}px)`,
  lt_xl: `screen and (max-width: ${BREAKPOINTS.lg - 0.01}px)`,
  gt_xs: `screen and (min-width: ${BREAKPOINTS.xs}px)`,
  gt_sm: `screen and (min-width: ${BREAKPOINTS.sm}px)`,
  gt_md: `screen and (min-width: ${BREAKPOINTS.md}px)`,
  gt_lg: `screen and (min-width: ${BREAKPOINTS.lg}px)`,
};

export function customizeBreakPoints(): BreakPoint[] {
  const customBreakPoints = [
    {
      alias: 'xs',
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.xs,
      priority: 1000,
    },
    {
      alias: 'sm',
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.sm,
      priority: 900,
    },
    {
      alias: 'md',
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.md,
      priority: 800,
    },
    {
      alias: 'lg',
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.lg,
      priority: 700,
    },
    {
      alias: 'xl',
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.xl,
      priority: 600,
    },
    {
      alias: 'lt-sm',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.lt_sm,
      priority: 950,
    },
    {
      alias: 'lt-md',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.lt_md,
      priority: 850,
    },
    {
      alias: 'lt-lg',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.lt_lg,
      priority: 750,
    },
    {
      alias: 'lt-xl',
      overlapping: true,
      priority: 650,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.lt_xl,
    },
    {
      alias: 'gt-xs',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.gt_xs,
      priority: -950,
    },
    {
      alias: 'gt-sm',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.gt_sm,
      priority: -850,
    },
    {
      alias: 'gt-md',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.gt_md,
      priority: -750,
    },
    {
      alias: 'gt-lg',
      overlapping: true,
      mediaQuery: BREAKPOINTS_MEDIA_QUERY.gt_lg,
      priority: -650,
    },
  ];

  return customBreakPoints;
}
