import { cipo } from 'cipo';

cipo.factory("Gridfields", function ($q, Model, URI, Message, Name, FIELDS_INFO, $timeout) {
    var Gridfields = Model.extend(function (moduleId, relationId) {
        
        var self = this;
        // self.visibleOnGrid = visibleOnGrid || false;
        self.moduleId = moduleId || null;
        self.fields_info = FIELDS_INFO;
        self.inGrid = {};
        self.offGrid = {};
        self.relationId = relationId || null;
        self.init();
    });

    Gridfields.prototype.init = function () {
        var self = this;
        //self.inGrid.list = [];
        //self.offGrid.list = [];
        self.numericFieldsDict = [];
        self.get_List(true)
            .then(function () {
                
            })
            .catch(function () {

            })
            .finally(function () {
                self.get_List();
            })
    }

    Gridfields.prototype.get_List = function (visibleOnGrid) {
        var self = this;
        var p = $q.defer();
        
        var listName = visibleOnGrid ? "inGrid" : "offGrid";
        var urlParams = {
            visibleOnGrid: visibleOnGrid || false
        };
        self[listName].isBusy = true;
        
        if (self.relationId) urlParams.relationId = self.relationId;
        var urlData = URI.MODULE_FIELDS.FIELDS;
        self[urlData.method](urlData, { url: urlParams, urltype: 'obj' }, { headers: { moduleId: self.moduleId || self.relationId } })
            .then(function (r) {
                // self.fieldsList = r;
                
                if (r && r.length && visibleOnGrid) {
                    self.defaultField = null;
                    var numericFields = [2, 16, 23];
                    self.numericFieldsDict = [];
                    for (var i = 0; i < r.length; i++) {
                        if (numericFields.indexOf(parseInt(r[i].fieldTypeId)) != '-1') {
                            self.numericFieldsDict.push({ key: r[i].name, value: r[i].label });
                        }
                        if (!r[i].width) r[i].width = 1;
                        if (r[i].isDefault) self.defaultField = r[i].id;
                        if (r[i].position == 0) r[i].position = i;
                        //set flags
                        if ((1 & r[i].priority) != 0) r[i].onPhone = 1;
                        else r[i].onPhone = 0;
                        if ((2 & r[i].priority) != 0) r[i].onTablet = 2;
                        else r[i].onTablet = 0;
                        // set width

                    }
                    self.set_fieldsWidth(r);

                } else {
                    //
                }

                self[listName].list = r;
                p.resolve(r);
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self[listName].isBusy = false;
                self[listName].isFieldsLoaded = true;
                // if (visibleOnGrid) self.get_List();
            })
        return p.promise;
    }

    Gridfields.prototype.edit_Label = function (field) {
        var self = this;
        self.editingLabel = true;
        field.labelForm = new Name({ label: field.label }, "label", "Label");            
    }

    Gridfields.prototype.cancel_edit_Label = function (field) {
        var self = this;
        self.editingLabel = !self.editingLabel;
        delete field.labelForm;
    }

    Gridfields.prototype.save_Label = function (field) {
        var self = this;
        self.editingLabel = true;
        field.label = field.labelForm.properties.label;
        // var temp = field.labelForm;
        field.labelForm.form.loading = true;
        
        self.sync()
            .then(function (r) {
                delete field.labelForm;
                self.editingLabel = false;
            })
            .catch(function (e) { field.labelForm.form.loading = false; });
    }

    Gridfields.prototype.set_fieldsWidth = function (fields) {
        var self = this;
        var parts = 0;
        var fields = fields || self.inGrid.list;
        for (var i = 0; i < fields.length; i++) {
            parts += fields[i].width;
        }
        for (var i = 0; i < fields.length; i++) {
            fields[i].widthPercentage = 100 / parts * fields[i].width;
        }
        
    }//

    Gridfields.prototype.colSize = function (item, isIncrease) {
        var self = this;
        if (isIncrease) item.width++;
        else item.width--;
        self.set_fieldsWidth();
        self.sync();
    }

    Gridfields.prototype.setDefaultField = function () {
        var self = this;
        for (var i = 0; i < self.inGrid.list.length; i++) {
            if (self.inGrid.list[i].id != self.defaultField) self.inGrid.list[i].isDefault = false;
            else self.inGrid.list[i].isDefault = true;
        }
        self.sync()
            .then(function () {
                Message.info('Default field set successfully');
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    Gridfields.prototype.set_Position = function (fields) {
        if (fields && fields.length) {
            for (var i = 0; i < fields.length; i++) {
                fields[i].position = i;
                fields[i].priority = fields[i].onPhone + fields[i].onTablet;
                
            }
        }
    }

    Gridfields.prototype.processDrop = function (e) {
        var self = this;
        // e.source.nodeScope.$modelValue
        var fields = e.dest.nodesScope.$modelValue;
        self.set_fieldsWidth(fields);
        self.isBusy = true;
        self.sync(fields);
        //self.get_List();
    }

    Gridfields.prototype.processNode = function (e) {
        var self = this;
        var fields = e.dest.nodesScope.$modelValue;
        var field = e.source.nodeScope.$modelValue;
        field.width = 1;
        field.priority = 3;
        field.onPhone = 1;
        field.onTablet = 2;
        field.printable = true;
        if (self.numericFieldsDict && self.numericFieldsDict.length) {
            for (var i = 0; i < self.numericFieldsDict.length; i++) {
                if (self.numericFieldsDict[i].key == field.name) return;
            }

        } else if (self.numericFieldsDict) { self.numericFieldsDict.push({ key: field.name, value: field.label }); }
        // self.set_fieldsWidth(fields);
        
    }

    Gridfields.prototype.removeItem = function (scope, isDefault) {
        var self = this;
        var p = $q.defer();
        scope.remove();
        if (isDefault) scope.defaultField = null;
        self.sync()
            .then(function () {
                self.get_List();
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            })
        return p.promise;
    }

    Gridfields.prototype.sync = function (fields) {
        var self = this;
        var p = $q.defer();
        var fields = fields || self.inGrid.list;
        self.inGrid.isBusy = true;
        self.isBusy = true;
        self.isModified = true;
        self.set_Position(fields);
        var urlData = URI.MODULE_FIELDS.SYNC;
        var urlParams = {};
        if (self.relationId) urlParams.relationId = self.relationId;
        self[urlData.method](urlData, { url: urlParams, urltype: 'obj', body: fields }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                Message.info("Fields updated successfully");
                self.get_List(true);
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                self.inGrid.isBusy = false;
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }

    return Gridfields;
});
