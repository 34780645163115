import { cipo } from 'cipo';

cipo.controller(
  "editMapController",
  function (
    $scope,
    Message,
    MODAL,
    mapDef,
    module,
    $mdDialog,
    $window,
    userService,
    $timeout
  ) {
    $scope.module = module;
    $scope.mapId = mapDef.id || 0;
    $scope.isInitiator = mapDef.isInitiator || false;
    $scope.modalInfo = MODAL;
  }
);
