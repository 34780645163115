import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FilterLogic, FilterOperator, FilterOperatorKey } from '../../../../models/module/grid/filterModel';
import { KeyValueType } from 'src/app/models/common';

export type DataListCommandFilterType = {
  name: string;
  fieldId: number | string;
  typeId?: number;
  type?: 'text' | 'number' | 'date';
  operators?: FilterOperatorKey[];
  selectedOperator?: FilterOperatorKey;
  values?: KeyValueType<any>[];
  selectedValue?: string;
  searchableValue?: boolean;
  multipleSelection?: boolean;
  getValues?: Observable<KeyValueType[]>;
};

export type DataListFilter = {
  field: string;
  operator: FilterOperator;
  value: string | number | string[] | number[];
};

export type FiltersToApply = {
  criteria?: string;
  filters?: LocalFilterModel;
};

export interface LocalFilterModel {
  logic?: FilterLogic;
  filterList: DataListFilter[];
}

export interface DataListFilterFormGroup {
  operator: FormControl<FilterOperatorKey | null>;
  value: FormControl<string>;
}
