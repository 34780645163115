import { cipo } from 'cipo';

var vinviteController = function ($transition$, $scope, $state, InviteProcess, Message, Login, Register, userService) {
    var invitationCode = $transition$.params().c || null;
    userService.clearData();
    $scope.isExistent = null;
    $scope.ip = new InviteProcess(invitationCode);
    $scope.anotherAccountLabel = null;
    /*$scope.show = {
        login: false,
        choose: false,
        error: false,
        register: false
    }

    var setShow = function (k) {
        for (var key in $scope.show) {
            if ($scope.show.hasOwnProperty(key)) {
                $scope.show[key] = false;
            }
        }
        $scope.show[k] = true;
    }*/

    $scope.checkingCode = true;

    var checkCode = function () {
        //authService.logout();
        $scope.ip.checkCode().then(function (r) {

            /*setShow('choose');
            if (r.userIdInSession) {
                $scope.anotherAccountLabel = 'Use another existing account';
            } else {
                $scope.anotherAccountLabel = 'Use an existing account';
            }*/
            $scope.inviteData = r;
            if (r.accountExists) {
                $scope.loginObject = new Login(r);
                $scope.loginObject.form.fieldsList.username.setFocus();
                $scope.isExistent = 1;
            }
            else {
                $scope.registerObject = new Register(r);
                $scope.registerObject.form.fieldsList.email.setFocus();
                $scope.isExistent = 2;
            }


        }).catch(function (e) {
            console.error(e.data.messages);
            $scope.validationErrors = $scope.processErrors(e.data.messages);
            console.error("$scope.validationErrors", $scope.validationErrors);
        })
            .finally(function () {
                $scope.checkingCode = false;
            });
    };

    checkCode();

    $scope.processErrors = function (e) {
        var errors = [];
        for (var i = 0; i < e.length; i++) {
            errors.push(e[i].message);
        }
        return errors;
    };

    $scope.associate = function () {
        $scope.ip.associate().then(function () {
            $state.go('login');
            //console.error('associate then')
            //$scope.tryToLogIn();
        }).catch(function (e) {
            console.error('associate catch', e);
            Message.error('Could not associate email with invitation');
        }).finally(function () {
            $scope.disabledBtn = false;
        });
    };

    $scope.tryToLogIn = function () {
        // login after register
        if ($scope.isExistent === 2) {
            try {
                var loginObject = new Login();
                loginObject.properties.username = $scope.registerObject.properties.email;
                loginObject.properties.password = $scope.registerObject.properties.password;
                loginObject.properties.rememberMe = false;

                loginObject.get_Token().then(function () {
                    $state.go("main");
                }).catch(function (serverCalled) {
                    console.error('serverCalled', serverCalled)
                    $scope.disabledBtn = false;
                    if (serverCalled) Message.error('Could not log you in from register.');
                });
            } catch (e) {
                console.error(e);
            }
        } else {
            //console.error('try to login');
            $scope.loginObject.get_Token().then(function () {
                //console.error('all good');
                $state.go("main");
            }).catch(function (serverCalled) {
                console.error('serverCalled', serverCalled);
                $scope.disabledBtn = false;
                if (serverCalled) Message.error('Could not log you in from login.');
            });
        }
    };

    $scope.register = function () {
        $scope.disabledBtn = true;
        $scope.registerObject.send_Data().then(function () {

            $scope.associate();

        }).catch(function (serverCalled) {
            if (serverCalled) Message.error('Could not register user on the system');
        }).finally(function () {
            $scope.disabledBtn = false;
        });
    };

    $scope.login = function () {
        $scope.disabledBtn = true;
        $scope.loginObject.get_Token().then(function () {
            $scope.associate();
        }).catch(function (serverCalled) {
            $scope.disabledBtn = false;
            if (serverCalled) {
                $scope.loginObject.form.setFieldInvalid('username', ' ');
                $scope.loginObject.form.setFieldInvalid('password', 'Email or password incorrect');
            }
        });
    };

    $scope.goToLogin = function () {
        $state.go('login');
    };

    $scope.goToRegister = function () {
        $state.go('register');
    };

    /* $scope.login = function () {
        var modalInstance = $uibModal.open({
            controller: 'LogInModalController',
            templateUrl: '/ng/views/system/modals/logInModal.html',
            backdrop: 'static',
            size: 'sm',
            resolve: { }
        });

        modalInstance.result.then(function (result) {
            checkCode();
        }, function () {
        });
    }

    $scope.register = function () {
        var modalInstance = $uibModal.open({
            controller: 'RegisterModalController',
            templateUrl: '/ng/views/system/modals/registerModal.html',
            backdrop: 'static',
            size: 'md',
            resolve: {}
        });

        modalInstance.result.then(function (result) {
            checkCode();
        }, function () {
        });
    } */
};

cipo.controller('vinviteController', vinviteController);
