<h2>Cipo Attachment Fields</h2>

<div fxLayout="row wrap" fxLayoutAlign="space-between start">
    @for (control of form().controls | keyvalue; track $index) {
        <div fxFlex="32" class="m-t">
            <cipo-attachment [control]="control.value" />
        </div>
    }
</div>
<h3 class="m-t-xl">Attachments without form control</h3>
<p>
    To visualize changes, I've used the same array of attachments for all three components. Becaue of two way binding,
    changes in one component will be reflected in the other two.
</p>
<div fxLayoutAlign="space-between start">
    <div fxFlex="32">
        <label>Basic input -- readonly</label>
        <cipo-attachment [(attachments)]="basicAttachment" [config]="{ readonly: true }" />
    </div>
    <div fxFlex="32">
        <label>Basic input -- editable</label>
        <cipo-attachment [(attachments)]="basicAttachment" />
    </div>
    <div fxFlex="32">
        <label>Basic input -- readonly, showMultipleSave</label>
        <cipo-attachment [(attachments)]="basicAttachment" [config]="readonlyConfig()" />
    </div>
</div>
