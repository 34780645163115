import { Component, EventEmitter, Output, input, model, signal, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

import {
  ActionEventType,
  ActionType,
  CipoTableOptions,
  ColumnPriorityType,
  DisplayFormats,
  TableColumn,
  TablePaginator,
} from './common';
import { SCREEN_SIZE } from '../../consts';

@Component({
  selector: 'cipo-data-list',
  template: '',
})
export abstract class DataListBase<T> {
  options = input<CipoTableOptions<ActionType, T>>({});
  tableColumns = input<TableColumn[]>([]);
  loading = model(true);
  tableData = input<T[]>([]);

  @Output() selectionChanged = new EventEmitter<T[]>();
  @Output() actionSelected = new EventEmitter<ActionEventType<number, T>>();
  @Output() paginationUpdated = new EventEmitter<TablePaginator>();
  @Output() sortChanged = new EventEmitter<Sort>();
  @Output() rowClicked = new EventEmitter<T>();

  selection = input(new SelectionModel<T>(true, []));
  deviceWidth = window.innerWidth;
  displayedColumns = signal<string[]>([]);
  totalWidth: number = 0;

  displayFormat = DisplayFormats;
  hasLeftIcons = signal(false);
  hasRightIcons = signal(false);

  @ViewChild(MatSort) sort: MatSort;

  protected checkLeftRightIcons(rows: T[]) {
    const actions = this.options().actions;
    if (!actions) {
      return;
    }
    (rows || []).forEach(row => {
      (actions(row) || []).forEach(({ leftIcon, rightIcon }) => {
        if (leftIcon) {
          this.hasLeftIcons.set(true);
        }
        if (rightIcon) {
          this.hasRightIcons.set(true);
        }
      });
    });
  }

  ngOnInit(): void {
    this.initialiseTableData();
  }

  abstract isAllSelected(): boolean;

  abstract toggleAllRows(): void;

  initialiseTableData() {
    const tableColumns = this.tableColumns();
    let displayedColumns: string[];

    // if at least one column has priority, filter columns by priority
    const hasPriority = tableColumns.findIndex(col => col.hasOwnProperty('priority'));

    if (hasPriority >= 0) {
      const acceptedPrios = this.getAcceptedPriorities();
      displayedColumns = tableColumns
        .map(c => {
          if (acceptedPrios.includes(c.priority)) {
            this.totalWidth += c.width ?? 0;
            return c.name;
          }
          return null;
        })
        .filter(c => !!c);
    } else {
      displayedColumns = tableColumns.map(col => {
        this.totalWidth += col.width ?? 0;
        return col.name;
      });
    }

    if (this.options()?.selectable) {
      this.totalWidth += 0.5;
      displayedColumns.unshift('select');
    }

    if (this.options()?.actions) {
      this.totalWidth += 0.5;
      displayedColumns.push('actions');
    }

    this.displayedColumns.set(displayedColumns);
  }

  getAcceptedPriorities() {
    const acceptedPriorities: ColumnPriorityType[] = [];
    if (this.deviceWidth > SCREEN_SIZE.phone && this.deviceWidth < SCREEN_SIZE.tablet) {
      acceptedPriorities.push(2, 3);
    }

    if (this.deviceWidth < SCREEN_SIZE.phone) {
      acceptedPriorities.push(1, 3);
    }

    if (this.deviceWidth > SCREEN_SIZE.tablet) {
      acceptedPriorities.push(0, 1, 2, 3);
    }

    return acceptedPriorities;
  }

  selectAction(actionId: number, row: T) {
    this.actionSelected.emit({ actionId, row });
  }

  checked() {
    this.selectionChanged.emit(this.selection().selected);
  }

  rowClick(row: T) {
    this.rowClicked.emit(row);
  }
}
