import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private overlayRef: OverlayRef = this.cdkSpinnerCreate();

  constructor(private overlay: Overlay) {}

  show() {
    if (this.overlayRef.hasAttached()) {
      return;
    }
    this.overlayRef.attach(new ComponentPortal(MatProgressSpinner));
  }

  hide() {
    if (!this.overlayRef.hasAttached()) {
      return;
    }
    this.overlayRef.detach();
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }
}
