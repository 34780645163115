<div class="tenant-account-logos"
     (click)="isOpen = !isOpen"
     cdkOverlayOrigin
     #trigger="cdkOverlayOrigin">
    <img class="tenant-nologo"
         src="./Content/images/CIPO NoLogo.png" />
    <mat-icon class="user-nologo">account_circle</mat-icon>
</div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template cdkConnectedOverlay
             cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayOpen]="isOpen"
             [cdkConnectedOverlayHasBackdrop]="true"
             (detach)="isOpen = false"
             (backdropClick)="isOpen = false">
    <div class="account-menu mat-elevation-z2 cipo-bg--primary-50"
         cdkTrapFocus
         [cdkTrapFocusAutoCapture]="true">
        <mat-form-field>
            <mat-label>{{ "LANGUAGE.TITLE" | translate }}</mat-label>
            <mat-select [value]="lang"
                        (valueChange)="changeLanguage($event)">
                <mat-option *ngFor="let language of languageTypes"
                            [value]="language">
                    {{ "LANGUAGE." + languageType[language] | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div fxLayout
             fxLayoutGap="12px">
            <mat-form-field>
                <mat-label>{{ "Theme" | translate }}</mat-label>
                <mat-select [value]="theme$()"
                            (valueChange)="changeTheme($event)">
                    <mat-option value="default">Default</mat-option>
                    <mat-option value="orange">Orange</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ "Theme Mode" | translate }}</mat-label>
                <mat-select [value]="themeMode$()"
                            (valueChange)="changeThemeMode($event)">
                    <mat-option value="light">Light</mat-option>
                    <mat-option value="dark">Dark</mat-option>
                    <mat-option value="browser">Browser</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button mat-raised-button
                color="primary"
                class="full-width m-b-md"
                (click)="openPalette()">Open color palette</button>
        <button class="sign-out"
                mat-flat-button
                (click)="signOut()">
            <span>Sign out</span>
            <mat-icon iconPositionEnd>logout</mat-icon>
        </button>
    </div>
</ng-template>