
var routeResolver = function () {

    this.$get = function () {
        return this;
    };

    this.routeConfig = function () {
        var viewsDirectory = './ng/views/',
            controllersDirectory = './ng/controllers/',

            setBaseDirectories = function (viewsDir, controllersDir) {
                viewsDirectory = viewsDir;
                controllersDirectory = controllersDir;
            },

            getViewsDirectory = function () {
                return viewsDirectory;
            },

            getControllersDirectory = function () {
                return controllersDirectory;
            };

        return {
            setBaseDirectories: setBaseDirectories,
            getControllersDirectory: getControllersDirectory,
            getViewsDirectory: getViewsDirectory
        };
    }();

    this.route = function (routeConfig) {
        var resolve = function (routeURL, module, controller, view, parameters) {
            if (!module) module = '';
            var controller = controller || 'e404';
            var view = view || controller;
            var routeParameters = parameters || {};
            var routeDef = {};

            routeDef.reloadOnSearch = false;
            // prevent caching of controllers
            routeDef.cache = false;

            for (var param in routeParameters) {
                if (routeParameters.hasOwnProperty(param))
                    routeDef[param] = routeParameters[param];
            }
            routeDef.templateUrl = routeConfig.getViewsDirectory() + module + '/' + view + '.html';

            if (!routeDef.error) {
                routeDef.url = routeURL;
                routeDef.controller = controller + 'Controller';

                // system routes resolves
                if (routeDef.system) {
                    routeDef.resolve = {
                        _code: [function () {
                            return routeDef.code;
                        }],
                        load: ['$q',
                            function ($q) {
                                return resolveDependencies($q);
                            }]
                    };
                }

                // tenant routes resolves
                else {
                    routeDef.resolve = {
                        _code: [function () {
                            return routeDef.code;
                        }],
                        operations: ['$q', 'userService', '_code',
                            function ($q, userService, _code) {
                                if (userService.tenantId == -1) return {};

                                var operations = userService.getOperationsFor(_code);
                                // $stateParams.operations = operations;
                                return operations;
                            }],
                        load: ['$q', '_isLoggedIn', 'userService', '_code', '_hasModulesLoaded', '$state', '$location', 'localStorageService',
                            function ($q, _isLoggedIn, userService, _code, _hasModulesLoaded, $state, $location, localStorageService) {
                                // check if user is disabled
                                if (typeof userService.system.userdata.disabledOnTenant != 'undefined' &&
                                    userService.system.userdata.disabledOnTenant && !$state.current.error)
                                {
                                    $state.go("tenant.disabled", { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId) }, { location: false });
                                }

                                // not logged in on a page that requires login
                                if (!_isLoggedIn) {
                                    userService.setRedirect($location.absUrl());
                                    $state.go('login');
                                    return;
                                }
                                
                                var noTenantsRoutes = ["WLC", "IDP"];

                                // separate no tenants from has tenants
                                if ((userService.tenantId == -1 && !noTenantsRoutes.includes(_code)) || (userService.tenantId != -1 && noTenantsRoutes.includes(_code)))
                                {
                                    $state.go("tenant.404", null, { location: false });
                                }

                                if (userService.system.modules[_code]) {
                                    localStorageService.set('currentModule', parseInt(userService.system.modules[_code]['moduleId']));
                                }

                                return resolveDependencies($q);
                            }]
                    };
                }
            }

            return routeDef;
        },

        resolveDependencies = function ($q) {
            var defer = $q.defer();

            defer.resolve();

            return defer.promise;
        };

        return {
            resolve: resolve
        }
    }(this.routeConfig);

};

var routes = angular.module('RoutesModule', []);

//Must be a provider since it will be injected into module.config()    
routes.provider('routeResolver', routeResolver);