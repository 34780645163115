import { cipo } from 'cipo';

cipo.factory("TransitionGroupMod", function ($q, Model, URI, Form, Message, Dictionaries, ModuleDictionaries) {
    var TransitionGroupMod = Model.extend(function (obj, workflowId) {
        this.properties = {
            id: null,
            name: "",
            transitions: [],
            conditionalTransitions: [],
            transitionIds: [],
            conditionalIds: [],
            allTransitionIds: [],
            allTransitions: [],
        };

        var self = this;
        self.sources = ["signatureTransitions"];
        self.sortableOptions = {
            handle: '> span>.listHandle',
            'ui-floating': true
        };
        self.isOrderMode = false;
        self.editable = (obj || {}).id ? false : true;
        self.transitionsDict = [];
        self.editMode = false;
        self.setCustomProperties(obj);
        self.workflowId = workflowId;

        if (!self.properties.id) self.createForm();
    });

    TransitionGroupMod.prototype.setCustomProperties = function (obj) {
        var self = this;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }

        self.properties.allTransitionIds = [...self.properties.transitionIds, ...self.properties.conditionalIds];
        self.properties.allTransitions = [...self.properties.transitions, ...self.properties.conditionalTransitions];
        self.properties.allTransitions.sort((a, b) => (a.order || 0) - (b.order || 0));
    }

    TransitionGroupMod.prototype.updateTransitionList = function () {
        var self = this;
        var tIds = angular.copy(self.properties.allTransitionIds);
        self.form.fieldsList.allTransitionIds.onClose = function () {
            var transitions = [];
            var conditionalTransitions = [];
            if (!angular.equals(self.properties.allTransitionIds, tIds)) {
                for (var i = 0; i < self.properties.allTransitionIds.length; i++) {
                    var id = self.properties.allTransitionIds[i];
                    var existing = self.properties.allTransitions.find(t => (t.transitionId ?? t.conditionalId) === id);
                    if (existing) {
                        if (id > 0) {
                            transitions.push(existing);
                        } else {
                            conditionalTransitions.push(existing);
                        }
                    }
                    else {
                        var signatureTransition = ModuleDictionaries.dataSources.signatureTransitions.lookup[id];
                        if (!signatureTransition) {
                            continue;
                        }

                        if (signatureTransition.key > 0) {
                            transitions.push({
                                transitionId: signatureTransition.key,
                                transitionName: signatureTransition.value
                            });
                        } else {
                            conditionalTransitions.push({
                                conditionalId: signatureTransition.key,
                                conditionalName: signatureTransition.value
                            });
                        }
                    }
                }
                self.properties.transitions = transitions;
                self.properties.transitionIds = transitions.map(t => t.transitionId);
                self.properties.conditionalTransitions = conditionalTransitions;
                self.properties.conditionalIds = conditionalTransitions.map(t => t.conditionalId);
                self.properties.allTransitionIds = [...self.properties.transitionIds, ...self.properties.conditionalIds];
                self.properties.allTransitions = [...self.properties.transitions, ...self.properties.conditionalTransitions];
                self.properties.allTransitions.sort((a, b) => (a.order || 0) - (b.order || 0));
                self.transitionsUpdated = true;
            }
        };
    }

    TransitionGroupMod.prototype.createForm = function () {
        var self = this;
        self.properties.allTransitionIds.sort((a, b) => a - b);
        self.form = new Form(self.properties);
        self.form.initializing = true;
        ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
        ModuleDictionaries.getDicts(self.sources)
            .then(function () {
                var form = {
                    name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
                    allTransitionIds: { label: 'Transitions', type: 'select', multiple: true, options: ModuleDictionaries.dataSources.signatureTransitions.data, validation: { required: true } },
                }
                self.form.set_Description(form);
                self.form.setTemplate('grid', [
                    { name: 50, allTransitionIds: 50 }
                ]);
                self.form.initializing = false;
                self.updateTransitionList();
                self.form.store_Data();
                self.transitionsUpdated = false;
            })
            .catch(function () {})
    }


    TransitionGroupMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = self.properties.id ? URI.TRANSITION_GROUP.EDIT : URI.TRANSITION_GROUP.ADD;

        self.form.loading = true;
        self.form.validate();

        if (self.form.isValid) {
            // used internally
            self.properties.allTransitionIds = undefined; 
            self.properties.allTransitions = undefined;
            self[dataURL.method](dataURL, { url: { workflowId: self.workflowId }, urltype: "obj", body: self.properties }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
                .then(function () {
                    Message.info('Transition saved successfully');
                    self.isModified = true;
                    self.form.clearDirty();
                    if (self.properties.id) self.get_Data();
                    ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
                    ModuleDictionaries.refreshDicts(['signatures']);
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);

                    if (self.form) self.form.catch(e); else Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    (self.form || {}).loading = false;
                });

        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    };

    TransitionGroupMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting transition group...";
        self.deleting = true;
        var dataURL = URI.TRANSITION_GROUP.DELETE;

        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: "obj" }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (result) {
                Message.info('Transition group deleted successfully');
                ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
                ModuleDictionaries.refreshDicts(['signatures']);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete transition group. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }


    // order stuff

    TransitionGroupMod.prototype.orderMode = function () {
        var self = this;
        self.isBusy = true;
        self.isOrderMode = true;
        self.form.editMode = false;
        self.elemsBackup = angular.copy(self.properties.allTransitions);
    }

    TransitionGroupMod.prototype.cancelOrder = function (preventRestore) {
        var self = this;
        self.isBusy = false;
        self.isOrderMode = false;
        self.form.editMode = true;
        if (!preventRestore) self.properties.allTransitions = angular.copy(self.elemsBackup);
        else {
            self.elemsBackup = angular.copy(self.properties.allTransitions);
            self.form.set_Data(self.properties);
            self.form.store_Data();
        }
    }

    TransitionGroupMod.prototype.updateOrder = function () {
        var self = this;
        self.isUpdatingOrder = true;
        for (var i = 0; i < self.properties.allTransitions.length; i++) {
            self.properties.allTransitions[i].order = i + 1;
        }
        var sync = {
            transitions: self.properties.allTransitions.filter(t => t.transitionId !== undefined),
            conditionalTransitions: self.properties.allTransitions.filter(t => t.conditionalId !== undefined)
        }
        var dataURL = URI.TRANSITION_GROUP.SYNC_ORDER;
        self[dataURL.method](dataURL, { body: sync }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (r) {
                // self.get_Data();
                self.cancelOrder(true);
                Message.info('Order updated successfully.');
            })
            .catch(function (e) {
                console.error(e);
                //p.reject(e);
            })
            .finally(function () {
                self.isUpdatingOrder = false;
            })
    }

    TransitionGroupMod.prototype.get_Data = function () {
        var self = this;
        self.isBusy = true;
        var dataURL = URI.TRANSITION_GROUP.GET;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (r) {
                self.setCustomProperties(r);
                self.form.set_Data(self.properties);
                self.form.store_Data();
                self.updateTransitionList();
                self.transitionsUpdated = false;
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                //p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
    }

    return TransitionGroupMod;
});
