import { Component, EventEmitter, Input, OnInit, Output, inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components.module';
import { FolderDialogService } from './folder-dialog.service';
import { CipoFieldsModule } from 'src/app/shared/modules/cipo-fields.module';
import { CipoFieldTypes, CipoListControl, CipoTextControl } from 'src/app/shared/components/fields/common';
import { GlobalTypeEnum, ProjectFolderModel } from 'src/app/models/files/project-folder.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FormGroup, Validators } from '@angular/forms';
import { PermissionsComponent } from '../permissions/permissions.component';
import { PeopleModel } from '../permissions/permissions.model';
import { PermissionsService } from '../permissions/permissions.service';
import { Permission } from 'src/app/models/permission';

const RECYCLE_BIN_FOLDEDER_ID: number = -10;

@Component({
  selector: 'app-folder-dialog',
  standalone: true,
  providers: [FolderDialogService, PermissionsService],
  imports: [SharedComponentsModule, CipoFieldsModule, PermissionsComponent],
  templateUrl: './folder-dialog.component.html',
})
export class FolderDialogComponent implements OnInit {
  translate = inject(TranslateService);
  folderDialogService = inject(FolderDialogService);
  notificate = inject(NotificationService);
  permissionsService = inject(PermissionsService);

  peoplePermissionsList: PeopleModel[];
  permissionChanged: boolean = false;
  saving = signal(false);
  newFolderAdded = signal(false);
  newFolderPath: string = '';
  @Input('rootmoduleid') rootModuleId: number;
  @Input('canchangepermissions') canChangePermissions: boolean;
  @Input('candoglobal') canDoGlobal: boolean;
  @Input('folder') folder: ProjectFolderModel;
  @Input('entityinstanceid') entityInstanceId: number;
  @Output('onsave') onSave: EventEmitter<string> = new EventEmitter<string>();
  @Output('onclose') onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  form = new FormGroup({
    nameControl: new CipoTextControl(null, { label: 'folderDialog.name', focused: true }, Validators.required),
    globalControl: new CipoListControl([], {
      label: 'folderDialog.global',
      type: CipoFieldTypes.Listbox,
      options: [
        { key: GlobalTypeEnum.NotGlobal.toString(), value: 'fileDialog.notGlobal' },
        { key: GlobalTypeEnum.Global.toString(), value: 'fileDialog.global' },
        { key: GlobalTypeEnum.GlobalPerParent.toString(), value: 'fileDialog.globalPerParent' },
      ],
    }),
  });

  ngOnInit(): void {
    // if we have global, then add new properties
    if (!this.canDoGlobal) {
      this.form.controls.globalControl.fieldData.options.forEach(
        o => (o.disabled = o.key === GlobalTypeEnum.NotGlobal.toString() ? false : true),
      );
    }

    if (
      this.folder.isLocked ||
      this.folder.isReadOnly ||
      !this.folder.permissions?.some(
        p =>
          p ===
          (this.folder.global === GlobalTypeEnum.NotGlobal ? Permission.Update : Permission.UpdateGlobal),
      )
    ) {
      this.enableDisableForm(true);
    }

    if (this.folder.parentId == RECYCLE_BIN_FOLDEDER_ID) {
      this.form.controls.globalControl.fieldData.disabled = true;
    }

    this.form.patchValue({ nameControl: this.folder?.name, globalControl: [this.folder?.global?.toString()] });
  }

  enableDisableForm(disabled: boolean) {
    if (disabled) {
      this.form.controls.nameControl.disable({ emitEvent: false });
    } else {
      this.form.controls.nameControl.enable({ emitEvent: false });
    }

    this.form.controls.globalControl.fieldData.disabled = disabled;
  }

  savingAction(status: boolean) {
    this.saving.set(status);
    this.enableDisableForm(status);
  }

  onSaveClick() {
    const { nameControl, globalControl } = this.form.value;
    const isAdd = this.folder.id === null || this.folder.id === undefined;
    let newId: number;

    // save only if we did some changes to the folder
    if (this.form.dirty) {
      this.savingAction(true);
      // we need to store folder path that comes from angularjs before saving to assign it after we retrieve folder after adding
      // we don't have folder path with get method, only from project files data list
      this.newFolderPath = this.folder.folderPath;

      var folder = { ...this.folder, name: nameControl as string, global: globalControl[0] ?? globalControl };
      var subscriber = isAdd
        ? this.folderDialogService.create(folder, this.entityInstanceId)
        : this.folderDialogService.save(folder, this.entityInstanceId);
      subscriber.subscribe({
        next: id => {
          newId = id;
          this.notificate.success(this.translate.instant('folderDialog.saveFolder'));

          // if we added a folder, then set this to true to refresh when dialog is closed
          if (isAdd) {
            this.newFolderAdded.set(true);
          }

          // emit only if we don't need to save the permissions
          if (!this.permissionChanged && !isAdd) {
            this.onSave.emit('');
          } else if (!this.permissionChanged && isAdd) {
            this.getFolderDetails(newId);
          }
        },
        error: () => {
          this.savingAction(false);
        },
      });
    }

    // save if we did some changes to the permissions
    if (this.permissionChanged) {
      this.savingAction(true);

      this.permissionsService
        .syncFolderPermissions(this.peoplePermissionsList, this.folder.id, this.entityInstanceId)
        .subscribe({
          complete: () => {
            this.notificate.success(this.translate.instant('fileFolderPermissions.savePermissions'));
            if (!isAdd) {
              this.onSave.emit('');
            } else {
              this.getFolderDetails(newId);
            }
          },
          error: () => {
            this.savingAction(false);
          },
        });
    }
  }

  getFolderDetails(newId: number) {
    this.folderDialogService.get(newId, this.entityInstanceId, true, true).subscribe({
      next: folder => {
        this.folder = folder;
        this.folder.folderPath = `${this.newFolderPath}/${this.folder.name}`;
        this.savingAction(false);
        this.onSave.emit(folder.name);
      },
      error: () => {
        this.savingAction(false);
      },
    });
  }

  onCloseClick() {
    this.onClose.emit(this.newFolderAdded());
  }

  permissionChangedEvent(list: PeopleModel[]) {
    this.peoplePermissionsList = list;
    this.permissionChanged = true;
  }
}
