export class AzureADB2C {
  constructor(private redirectUrl: string) { }

  private environments: AzureADB2CEnvironment[] = [
    {
      name: 'Dev',
      tenantId: '49334417-9bd3-471e-9a5e-ba4ff384f91c',
      clientId: 'e0215240-e2fb-40b2-ba07-8786b0b5d15d',
      authDomainName: 'cipob2c.b2clogin.com',
      aliasses: [
        'localhost:44340',
        'dev.cipo.cloud',
        'dev2.cipo.cloud',
        'dev3.cipo.cloud',
        'cipodevcore.azurewebsites.net',
        'cipodevcore-stage.azurewebsites.net',
        'cipodevcore64.azurewebsites.net',
        'cipodevcore64-stage.azurewebsites.net',
        'cipodevcore642.azurewebsites.net',
        'cipodevcore642-stage.azurewebsites.net',
        'cipodevcore643.azurewebsites.net',
        'cipodevcore643-stage.azurewebsites.net',
        'cipodev3.azurewebsites.net',
        'cipodev3-temp.azurewebsites.net',
      ],
    },
    {
      name: 'QA',
      tenantId: '52641332-a680-4ac5-aeea-85b066b84b8f',
      clientId: '84f9a179-c165-4900-a83e-37e67c3745e1',
      authDomainName: 'cipob2cusqa.b2clogin.com',
      aliasses: [
        'localhost:44350',
        'qa.cipo.cloud',
        'cipoeastusqa.azurewebsites.net'
      ],
    },
    {
      name: 'Prod',
      tenantId: '4669f9b1-f64b-46b8-a230-95ada8c2d9bf',
      clientId: 'c35ca582-1ec8-4796-8588-857465dccc5d',
      authDomainName: 'cipob2cus.b2clogin.com',
      aliasses: [
        'localhost:44360',
        'app.cipo.io',
        'app.cipo.cloud',
        'cipowestus.azurewebsites.net',
        'cipowestusc.azurewebsites.net',
        'cipowestusc64-stage.azurewebsites.net',
        'cipoeastus.azurewebsites.net',
        'cipo-cipods2.azurewebsites.net',
      ],
    },
  ];

  generateConstants(): AzureADB2CConstants {
    const constants: AzureADB2CConstants = {};
    this.environments.forEach(env => {
      env.aliasses.forEach(alias => {
        constants[alias] = this.makeItem(env);
      });
    });
    return constants;
  }

  private makeItem(env: AzureADB2CEnvironment): AzureADB2CConstant {
    return {
      TENANT_ID: env.tenantId,
      CLIENT_ID: env.clientId,
      AUTH_DOMAIN_NAME: env.authDomainName,
      AUTHORITY: `https://${env.authDomainName}/${env.tenantId}/B2C_1A_SignInOnlyKMSI`,
      REDIRECT_URI: `${env.authDomainName}/postLogin/`,
      REDIRECT_PATH: '/postLogin/',
      POLICY_URLS: {
        PASSWORD_CHANGE: `https://${env.authDomainName}/${env.tenantId}/oauth2/v2.0/authorize?p=B2C_1A_PasswordChange&client_id=${env.clientId}&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${this.redirectUrl}&scope=openid&response_type=id_token&response_mode=query`,
        PASSWORD_RESET: `https://${env.authDomainName}/${env.tenantId}/oauth2/v2.0/authorize?p=B2C_1A_PasswordReset&client_id=${env.clientId}&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${this.redirectUrl}&scope=openid&response_type=id_token&prompt=login&response_mode=query`,
        PROFILE_EDIT: `https://${env.authDomainName}/${env.tenantId}/oauth2/v2.0/authorize?p=B2C_1_ProfileEdit&client_id=${env.clientId}&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${this.redirectUrl}&scope=openid&response_type=id_token&response_mode=query`,
      },
      ERROR_CODES: {
        PASSWORD_RESET: 'AADB2C90118',
        USER_CANCELLATION: 'AADB2C90091'
      }
    };
  }
}

export interface AzureADB2CConstants {
  [key: string]: AzureADB2CConstant;
}

export interface AzureADB2CConstant {
  TENANT_ID: string;
  CLIENT_ID: string;
  AUTHORITY: string;
  AUTH_DOMAIN_NAME: string;
  REDIRECT_URI: string;
  REDIRECT_PATH: string;
  POLICY_URLS: {
    PASSWORD_CHANGE: string;
    PASSWORD_RESET: string;
    PROFILE_EDIT: string;
  };
  ERROR_CODES: {
    PASSWORD_RESET: string;
    USER_CANCELLATION: string;
  }
}

interface AzureADB2CEnvironment {
  name: string;
  tenantId: string;
  clientId: string;
  authDomainName: string;
  aliasses: string[];
}
