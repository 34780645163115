<h1>Richtext Froala</h1>
<div fxLayout class="m-b" fxLayoutGap="12px">
    <h4 class="m-y-auto">Mode:</h4>
    <mat-button-toggle-group [(value)]="editMode" name="editMode">
        <mat-button-toggle [value]="true">Edit</mat-button-toggle>
        <mat-button-toggle [value]="false">View</mat-button-toggle>
    </mat-button-toggle-group>
    <h4 class="m-y-auto">Required:</h4>
    <mat-button-toggle-group name="required" [(value)]="required">
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
    </mat-button-toggle-group>
</div>
<app-froala
    [options]="options"
    [tributeoptions]="tributeOptions"
    [editmode]="editMode"
    [required]="required"
    [hint]="hint"
    [label]="label"
    [(content)]="initialValue"
    (contentChange)="updateContent($event)"></app-froala>
