import { cipo } from 'cipo';

cipo.factory("PaymentRelationMod", function ($q, Model, RelationMod, URI, Form, Message, Dictionaries, STATES_STATUS, StatesMapping, ModuleDictionaries) {
    var PaymentRelationMod = RelationMod.extend(function (obj) {
        //console.error('params', stateId, obj)

        this.properties = {
            id: null,
            parentId: -3,
            name: "",
            childName: "",
            parentName: "",
            childId: ModuleDictionaries.moduleInfo.moduleId != -3 ? ModuleDictionaries.moduleInfo.moduleId : null,
            parentTypeId: null,
            childTypeId: null,
            childFieldId: null,
            inUse: false,
            relationType: 2,
            isParent: true

        };
        var self = this;
        self.sources = ["modules", "moduleTypes"];
        // self.moduleId = moduleId;
        self.editMode = false;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }
        if (!self.properties.id) self.createForm();

    });
    // to go
    PaymentRelationMod.prototype.setDicts = function (modulesDict, moduleTypes) {
        var self = this;
        self.modulesDict = [];
        self.parentDict = [];
        self.moduleTypes = moduleTypes;
        if (!self.properties.id) self.createForm();
        if (modulesDict && modulesDict.length) {
            for (var i = 0; i < modulesDict.length; i++) {
                if (modulesDict[i].key != -3) {
                    self.modulesDict.push(modulesDict[i]);
                } else {
                    self.parentDict.push(modulesDict[i]);
                }
            }
        }
        if (!self.properties.id) self.createForm();
    }




    PaymentRelationMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        ModuleDictionaries.getDicts(self.sources)
            .then(function () {

                var grid;
                var form = {
                    parentId: { label: 'Parent', type: 'select', options: ModuleDictionaries.dataSources.modules.data, validation: { required: true } },
                    name: { label: 'Relation name', type: 'text', validation: { required: true, maxChars: 100 } },
                    parentTypeId: { label: 'Parent Type', type: 'select', options: ModuleDictionaries.dataSources.moduleTypes.data, validation: { required: true } },
                    childId: { label: 'Child', type: 'select', options: ModuleDictionaries.dataSources.modules.data, validation: { required: true } },
                    childTypeId: { label: 'Child Type', type: 'select', options: ModuleDictionaries.moduleInfo.moduleId != -3 ? ModuleDictionaries.dataSources.moduleTypes.data : [], validation: { required: true } },
                    //to add
                    childFieldId: { label: 'Select Field', type: 'select', options: [], validation: { required: true } }
                };


                self.form.set_Description(form);


                grid = [
                    { name: 100 },
                    { parentId: 50, parentTypeId: 50 },
                    { childId: 33, childTypeId: 33, childFieldId: 33 }
                ];
                self.form.setTemplate('grid', grid);
                self.form.store_Data();
                self.form.fieldsList.parentId.editMode = false;
                var data;
                if (ModuleDictionaries.moduleInfo.moduleId != -3) {
                    if (!self.properties.childTypeId && ModuleDictionaries.dataSources.moduleTypes.data.length == 1)
                        self.properties.childTypeId = ModuleDictionaries.dataSources.moduleTypes.data[0].key;
                    self.form.fieldsList.childId.editMode = false;
                    self.form.fieldsList.parentTypeId.loading = true;
                    self.form.fieldsList.childFieldId.loading = true;

                        data = $q.all([Dictionaries.ModuleTypes(-3), Dictionaries.ModuleFieldsByType({ type: 2, multiple: 0 }, { moduleId: self.properties.childId })]);
                        
                        data
                            .then(function (r) {
                                self.form.set_Description(
                                    {
                                        parentId: self.form.fieldsList.parentId,
                                        childId: self.form.fieldsList.childId,
                                        parentTypeId: { label: 'Parent Type', type: 'select', options: r[0], validation: { required: true } },
                                        childFieldId: { label: 'Select Field', type: 'select', options: r[1], validation: { required: true } }
                                    }, true);
                            })
                            .catch(function (e) { console.error(e); Message.dberror(e) });
                    } else {
                        if (!self.properties.parentTypeId && ModuleDictionaries.dataSources.moduleTypes.data.length == 1) self.properties.parentTypeId = ModuleDictionaries.dataSources.moduleTypes.data[0].key;
                        var getFieldsOnClose = function (field) {
                            console.error('changed', self, field);

                            if (self.properties.childId && self.childIdBkp != self.properties.childId) {
                                self.childIdBkp = self.properties.childId;
                                self.form.fieldsList.childTypeId.loading = true;
                                self.form.fieldsList.childFieldId.loading = true;
                                data = $q.all([Dictionaries.ModuleTypes(self.properties.childId), Dictionaries.ModuleFieldsByType({ type: 2, multiple: 0 }, { moduleId: self.properties.childId })]);

                                data
                                    .then(function (r) {
                                        if (r[0].length == 1) self.properties.childTypeId = r[0][0].key;
                                        self.form.set_Description(
                                            {
                                                parentId: self.form.fieldsList.parentId,
                                                childId: self.form.fieldsList.childId,
                                                childTypeId: { label: 'Child Type', type: 'select', options: r[0], validation: { required: true } },
                                                childFieldId: { label: 'Select Field', type: 'select', options: r[1], validation: { required: true } },
                                            }, true);
                                        // self.form.fieldsList.childTypeId.loading = false;
                                        self.form.fieldsList.parentId.editMode = false;
                                        // self.form.fieldsList.childId.editMode = true;
                                        self.form.fieldsList.childId.onClose = getFieldsOnClose;
                                    })
                                    .catch(function (e) { console.error(e); Message.dberror(e) })

                            }
                            if (field) field.onSelectClose();
                        }


                    // we are on pp module
                    data = [Dictionaries.ModuleTypes(-3)];
                    if (self.properties.childId) data.push(Dictionaries.ModuleFieldsByType({ type: 2, multiple: 0 }, { moduleId: self.properties.childId }));

                    $q.all(data)
                        .then(function (r) {
                            var partialForm = {};
                            partialForm.parentTypeId = { label: 'Parent Type', type: 'select', options: r[0], validation: { required: true } };
                            if (r[1]) partialForm.childFieldId = { label: 'Select Field', type: 'select', options: r[1], validation: { required: true } };
                            self.form.set_Description(partialForm, true);
                            self.form.fieldsList.childId.onClose = getFieldsOnClose;
                            getFieldsOnClose();
                        })
                        .catch(function (e) { });
                }




                self.form.initializing = false;


            })
        
    }



    return PaymentRelationMod;
});
