import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Language, LanguageNames } from "../../models/system/language";

export const defaultLanguage: Language = Language.English;

@Injectable({ providedIn: 'root' })
export class LocaleService {
  locale: string;
  localeChanged = new EventEmitter<string>();

  constructor(private translate: TranslateService) {
    this.locale = translate.currentLang || LanguageNames.get(defaultLanguage);
    this.setLocale(this.locale);

    translate.onLangChange.subscribe(ev => {
      this.locale = ev.lang;
      this.localeChanged.emit(this.locale);
    });
  }

  setLocale(locale: string) {
    this.translate.use(locale);
  }

  getLocale(): string {
      return this.locale;
  }
}