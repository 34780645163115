import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.service';
import { NotificationService } from './notification.service';

type ServiceOptions = {
  useLoader?: boolean;
  useErrorNotification?: boolean;
  httpOptions?: HttpOptions;
  baseUrl?: 'baseApiUrl' | 'jobsApiUrl';
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
  ) {}

  buildHttpParams(obj: { [key: string]: any }): HttpParams {
    let params = new HttpParams();
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (value !== null && value !== undefined) {
        params = params.set(key, value);
      }
    });
    return params;
  }

  get<T>(
    partialurl: string,
    { httpOptions = {}, useLoader = true, useErrorNotification = true, baseUrl = 'baseApiUrl' }: ServiceOptions = {},
  ): Observable<T> {
    useLoader && this.loaderService.show();
    return this.http.get<T>(environment[baseUrl] + partialurl, httpOptions).pipe(
      tap(() => useLoader && this.loaderService.hide()),
      catchError(err => {
        useLoader && this.loaderService.hide();
        useErrorNotification && this.notificationService.error(err);
        return throwError(() => err);
      }),
      take(1),
    );
  }

  getText(partialurl: string, { httpOptions = {}, baseUrl = 'baseApiUrl' }: ServiceOptions = {}) {
    return this.http.get(environment[baseUrl] + partialurl, { ...httpOptions, responseType: 'text' }).pipe(
      catchError(err => {
        this.notificationService.error(err);
        return throwError(() => err);
      }),
      take(1),
    );
  }

  post<T>(
    partialurl: string,
    body: any,
    { httpOptions = {}, useLoader = true, useErrorNotification = true, baseUrl = 'baseApiUrl' }: ServiceOptions = {},
  ) {
    useLoader && this.loaderService.show();
    return this.http.post<T>(environment[baseUrl] + partialurl, body, httpOptions).pipe(
      tap(() => useLoader && this.loaderService.hide()),
      catchError(err => {
        useLoader && this.loaderService.hide();
        useErrorNotification && this.notificationService.error(err);
        return throwError(() => err);
      }),
      take(1),
    );
  }

  put<T>(
    partialurl: string,
    body: any,
    { httpOptions = {}, useLoader = true, useErrorNotification = true, baseUrl = 'baseApiUrl' }: ServiceOptions = {},
  ) {
    useLoader && this.loaderService.show();
    return this.http.put<T>(environment[baseUrl] + partialurl, body, httpOptions).pipe(
      tap(() => useLoader && this.loaderService.hide()),
      catchError(err => {
        useLoader && this.loaderService.hide();
        useErrorNotification && this.notificationService.error(err);
        return throwError(() => err);
      }),
      take(1),
    );
  }

  delete<T>(
    partialurl: string,
    { httpOptions = {}, useLoader = true, useErrorNotification = true, baseUrl = 'baseApiUrl' }: ServiceOptions = {},
  ) {
    useLoader && this.loaderService.show();
    return this.http.delete<T>(environment[baseUrl] + partialurl, httpOptions).pipe(
      tap(() => useLoader && this.loaderService.hide()),
      catchError(err => {
        useLoader && this.loaderService.hide();
        useErrorNotification && this.notificationService.error(err);
        return throwError(() => err);
      }),
      take(1),
    );
  }
}

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  transferCache?:
    | {
        includeHeaders?: string[];
      }
    | boolean;
}
