import { cipo } from 'cipo';

cipo.factory("UserProfile", function ($q, Model, Form, userService, URI, Message, Dictionaries) {
    var UserProfile = Model.extend(function () {
        var self = this;

        var obj = userService.system.userdata;

        self.properties = {
            organization: obj.organizationName,
            signature: obj.signature || null,
            loadMore: obj.loadMore || false,
        };

        self.profileForm = {
            isVisible: false,
            dataURL: URI.USER.EDIT_PROFILE,
            successMessage: "Profile updated successfully",
            buttonDefault: "Edit Profile",
            buttonSave: "Update",
            icon: "mdi-account-edit",
            properties: {
                username: obj.email,
                firstName: obj.firstName,
                lastName: obj.lastName,
                displayName: obj.displayName || "",
                phoneNumber: obj.phoneNumber,                    
                phoneNumberCountryCode: obj.phoneNumberCountryCode || ""
            }
        };

        //self.profileForm = {
        //    isVisible: false,
        //    dataURL: URI.PROFILE.EDIT,
        //    successMessage: "Profile updated successfully",
        //    buttonDefault: "Edit details",
        //    buttonSave: "Update",
        //    icon: "mdi-account-edit",
        //    properties: {
        //        firstName: obj.firstName,
        //        lastName: obj.lastName,
        //        phoneNumber: obj.phoneNumber,
        //        phoneNumberCountryCode: obj.phoneNumberCountryCode || ""
        //    }
        //};

        self.avatar = {
            src: 'data:image/png;base64,' + obj.logo
        };

        self.imageForm = new Form(self.avatar);
        self.imageForm.set_Description({
            image: { label: '', type: 'editor', editMode: false }
        });

        self.profileForm.form = new Form(self.profileForm.properties);
        Dictionaries.CountryCodes()
            .then(function (r) {
                self.profileForm.form.set_Description({
                    firstName: { label: 'First Name', type: 'text', validation: { required: true, minChars: 1, maxChars: 255 } },
                    lastName: { label: 'Last Name', type: 'text', validation: { required: true, minChars: 1, maxChars: 255 } },
                    displayName: { label: 'Display Name', type: 'text' },
                    phoneNumberCountryCode: { label: 'Phone Country', type: 'select', options: r, validation: { requiredIfFieldHasValue: 'phoneNumber' } },
                    phoneNumber: { label: 'Phone', type: 'text' }
                });
                self.profileForm.form.setTemplate('grid', [
                    { firstName: 50, lastName: 50 },
                    { displayName: 100 },
                    { phoneNumberCountryCode: 50, phoneNumber: 50 }
                ]);
            })
            .catch(function () { });

        self.profileForm.form.store_Data();

        //self.passwordForm = {
        //    isVisible: false,
        //    dataURL: URI.PROFILE.CHANGE_PASSWORD,
        //    successMessage: "Password updated successfully",
        //    buttonDefault: "Change password",
        //    buttonSave: "Update",
        //    icon: "mdi-account-key",
        //    properties: {
        //        username: obj.email,
        //        //oldPassword: "",
        //        newPassword: "",
        //        passConfirm: ""
        //    }
        //};

        //self.passwordForm.form = new Form(self.passwordForm.properties);

        //self.passwordForm.form.set_Description({
            //oldPassword: { label: 'Old Password', type: 'password', validation: { required: true, minChars: 6 } },
            //newPassword: {
            //    label: 'New Password', type: 'password', validation: {
            //        required: true, maxChars: 32, regExp: {
            //            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            //            message: 'Invalid password. The password must be at least 6 caracters long and requires at least a small letter, a capital letter, a digit and a special character'
            //        }
            //    }
            //},
            //passConfirm: { label: 'Confirm password', type: 'password', validation: { required: true, equalsField: "newPassword" } }
        //});

        //self.passwordForm.form.store_Data();

        //self.forms = [self.profileForm, self.passwordForm];
        self.forms = [self.profileForm];
    });

    UserProfile.prototype.hideForms = function () {
        for (var i = 0; i < this.forms.length; i++) {
            this.cancel(this.forms[i]);
        }
        return;
    };

    UserProfile.prototype.cancel = function (form) {
        form.isVisible = false;
        form.form.restore_Data();
    };

    UserProfile.prototype.updatePagination = function () {
        var self = this;
        var dataURL = URI.USER.TOGGLE_LOAD_MORE;
        self.savingLoadMore = true;
        self[dataURL.method](dataURL)
            .then(function (r) {
                Message.info("Pagination type updated successfully");
                userService.User();
                self.showingLoadMore = false;
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () { self.savingLoadMore = false; })
    };

    UserProfile.prototype.save = function (form) {
        var self = this;
        form.form.loading = true;
        form.form.validate();
        if (form.form.isValid) {
            self[form.dataURL.method](form.dataURL, form.properties)
                .then(function (r) {
                    Message.info(form.successMessage);
                    form.isVisible = false;
                    userService.User();
                })
                .catch(function (e) {
                    console.error(e);
                    var isGeneric = true;
                    if (e.data && e.data.messages) {
                        form.form.catch(e);
                        if (form.form.description.hasOwnProperty("newPassword")) {
                            for (var i = 0; i < e.data.messages.length; i++) {
                                if (e.data.messages[i].code === "InvalidNewPassword") {
                                    form.form.setFieldInvalid('newPassword', "Invalid password. The password must be at least 6 caracters long and requires at least a small letter, a capital letter, a digit and a special character");

                                    //The new password must contain a small letter, a capital letter, a digit and a special character");
                                    isGeneric = false;
                                }

                                else if (e.data.messages[i].code === "InvalidPassword") {
                                    form.form.setFieldInvalid('oldPassword', e.data.messages[i].message);
                                    isGeneric = false;
                                }
                            }
                        }
                        if (isGeneric) {
                            Message.dberror(e);
                        }
                    }


                })
                .finally(function () {
                    form.form.loading = false;
                });
        } else {
            form.form.loading = false;
        }

    };

    return UserProfile;
});
