import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { contextActions } from './context.actions';

@Injectable()
export class ContextEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contextActions.load),
      mergeMap(() => of(contextActions.set({ context: undefined }))),
    ),
  );

  constructor(private actions$: Actions) {}
}
