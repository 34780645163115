@switch (control().fieldData.type) {
    @case (FIELD_TYPES.Text) {
        <cipo-text [control]="$any(control())" />
    }
    @case (FIELD_TYPES.Number) {
        <cipo-number [control]="$any(control())" />
    }
    @case (FIELD_TYPES.Select) {
        <cipo-select [control]="$any(control())" />
    }
    @case (FIELD_TYPES.Checkbox) {
        <cipo-checkbox [control]="$any(control())" />
    }
    @case (FIELD_TYPES.DateTime) {
        <cipo-date-time [control]="$any(control())" />
    }
    @case (FIELD_TYPES.Attachment) {
        <cipo-attachment [control]="$any(control())" />
    }
    @case (FIELD_TYPES.Listbox) {
        <cipo-listbox [control]="$any(control())" />
    }
    @default {
        <cipo-text [control]="$any(control())" />
    }
}
