<div
    [ngClass]="config().cleanReadOnly ? '' : 'border-bottom--dashed hint-placeholder'"
    fxLayoutAlign="space-between center">
    <div>
        @if (config().label) {
            <label>{{ config().label | translate }}</label>
        }
        <p class="text--pre-line m-b-0">
            @if (config().prefixIcon) {
                <mat-icon inline>{{ config().prefixIcon }}</mat-icon>
            }

            {{ displayValue() }}

            @if (config().suffixIcon) {
                <mat-icon inline>{{ config().suffixIcon }}</mat-icon>
            }
        </p>
    </div>
    @if (config().fieldDescription) {
        <mat-icon class="material-icons-outlined cursor--help" [title]="config().fieldDescription | translate">
            info
        </mat-icon>
    }
</div>
