import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        tenantId: this.getTenantId(),
        moduleId: this.getModuleId(request.headers),
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return next.handle(request);
  }

  private getToken(): string {
    return localStorage.getItem('msal.idtoken') || '';
  }

  private getTenantId(): string {
    // this will ensure that each request from demo will have an tenantId. We need this until we'll implement Angular Routing
    const defaultTenant = window.location.href.includes('/demo') ? '417' : '';
    return sessionStorage.getItem('ls.CurrentTenant') || defaultTenant;
  }

  private getModuleId(headers: HttpHeaders): string {
    if (headers.has('moduleId')) {
      return headers.get('moduleId');
    }
    return sessionStorage.getItem('ls.currentModule') || '';
  }
}
