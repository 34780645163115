import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services';
import { ContextTree } from './context.model';

@Injectable()
export class ContextService {
  constructor(private httpService: HttpService) {}

  getContextTree(userId: number | undefined): Observable<ContextTree> {
    return this.httpService.get<ContextTree>(`_api/Contracts/GetContextTree?userId=${userId || 0}`, {
      useLoader: false,
    });
  }
}
