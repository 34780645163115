import { createAction, props } from "@ngrx/store";
import { TenantState } from "./tenant.model";
import { TenantSettingsEnum, TenantSettingValue } from "../../module/instance/tenant-settings/tenant-settings.model";

const load = createAction('[Tenant] Load');
const set = createAction('[Tenant] Set', props<{ tenant: TenantState }>());
const updateValue = createAction('[Tenant] Update Value', props<{ id: TenantSettingsEnum, value: TenantSettingValue }>());

export const tenantActions = {
    load, set, updateValue
}