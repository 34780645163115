import { cipo } from 'cipo';

cipo.controller('mapsAuthoringController',
    function ($scope, userService) { //add more services here, as needed
        userService.clearData();
        
        $scope.maps = function () {

            //examples
            alert('Hello World from Maps Controller!');
            var tenant = userService.currentTenant();
            var name = userService.getTenantIdentifierById(224);
        };

        //add your controller logic here
});
