import { cipo } from 'cipo';
import toastr from 'toastr';

cipo.factory('Message', function () {
    var Message = function () {
        this.constants = {
            NO_PROPERTY: "_NoProperty_"
        }
    };


    Message.prototype.stickyWarning = function (msg) {
        toastr.options = {
            positionClass: "toast-bottom-right",
            timeOut: 0,
            extendedTimeOut: 0,
            closeButton: true,
            closeHtml: '<button class="md-icon-button md-button md-ink-ripple md-raised" aria-label="Close"><i class="mdi mdi-check"></i></button>',
        }

        toastr.warning(msg.replace("\n", "<br>"));
    }
    
    Message.prototype.info = function (msg) {
        toastr.options = {};
        toastr.info(msg.replace("\n", "<br>"),'Info');
    }

    Message.prototype.warning = function (msg) {
        toastr.options = {};
        toastr.warning(msg.replace("\n", "<br>"), 'Warning');
    }

    Message.prototype.success = function (msg) {
        toastr.options = {};
        toastr.success(msg.replace("\n", "<br>"), 'Success');
    }

    Message.prototype.error = function (msg) {
        toastr.options = {};
        toastr.error(msg.replace("\n", "<br>"), 'Error');
    }

    Message.prototype.dberror = function (resp, object, identifier, description) {
        var resp = resp || {},
            object = object || [],
            identifier = identifier || 'id',
            description = description || 'name',
            r = resp.data ? resp.data : resp,
            lookup = {};

        if(!r) {
            return;
        }

        // building lookup
        if (object.length) {
            for (var i = 0; i < object.length; i++) {
                if (object[i][identifier] && object[i][description]) {
                    lookup[object[i][identifier].toString()] = object[i][description];
                }
            }
        }

        if (r.message) {
            // backwards compatibility
            var txt = r.message;
            if (txt.length == 0 && r.length) txt = r;
            toastr.error(txt.replace("\n", "<br>"));
        } else if (r.messages && Object.prototype.toString.call(r.messages) == '[object Array]') {
            var errors = [];

            for (var i = 0; i < r.messages.length; i++) {
                // creating error message
                var errorMessage = r.messages[i].message ?
                    r.messages[i].message : 'An unidentified error has occured';

                // pushing message
                errors.push(errorMessage);
            }

            if (errors.length) toastr.error(errors.join('<br />'));
        } else if (r.messages && Object.prototype.toString.call(r.messages) == '[object Object]') {
            var errors = [];
            var warnings = [];

            for (var key in r.messages) {
                if (r.messages.hasOwnProperty(key) && Object.prototype.toString.call(r.messages[key]) == '[object Array]') {
                    for (var i = 0; i < r.messages[key].length; i++) {
                        // trying to identify key in lookup
                        var errorIdentifier = lookup[key] ? lookup[key] : key;

                        // creating error message
                        var errorMessage = key == this.constants.NO_PROPERTY ?
                            r.messages[key][i].message : errorIdentifier + ": " + r.messages[key][i].message;

                        // pushing message
                        if (!r.messages[key][i].type) errors.push(errorMessage);
                        else warnings.push(errorMessage);
                    }
                }
            }

            if (errors.length) toastr.error(errors.join('<br />'));
            if (warnings.length) toastr.warning(warnings.join('<br />'));
        }
    }

    return new Message;
});
