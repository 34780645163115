import { Inject, Injectable } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  InteractionType,
  RedirectRequest
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { AzureADB2C, AzureADB2CConstant } from "src/environments/aadb2c";

const logoutChannel = new BroadcastChannel("logout_channel");

logoutChannel.onmessage = (event) => {
  localStorage.clear();
  location.reload();
}

@Injectable()
export class MsalAuthService {

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public tokenReceived$ = new Subject();
  private aadb2c: AzureADB2CConstant;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService
  ) {
    this.aadb2c = new AzureADB2C(window.location.host).generateConstants()[window.location.host];
    this.authService.handleRedirectObservable().subscribe(ev => {
      if (ev === null || ev === undefined) {
        return;
      }
      localStorage.setItem('msal.idtoken', ev.idToken);
      this.tokenReceived$.next('');
      this.tokenReceived$.complete();
    },
    (error: Error) => {
      if(error.message.includes(this.aadb2c.ERROR_CODES.PASSWORD_RESET)) {
        document.location.href = this.aadb2c.POLICY_URLS.PASSWORD_RESET;
      }
      if(error.message.includes(this.aadb2c.ERROR_CODES.USER_CANCELLATION)){
        document.location.href = '/'
      }
    });
  }

  async loginRedirect() {
    await this.authService.instance.initialize();
    if (this.msalGuardConfig.authRequest) {
      await this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        redirectStartPage: this.aadb2c.REDIRECT_PATH,
        redirectUri: this.aadb2c.REDIRECT_PATH
      } as RedirectRequest).toPromise()
    } else {
      await this.authService.loginRedirect();
    }
  }

  async logout() {
    logoutChannel.postMessage("");
    await this.authService.instance.initialize();
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      await this.authService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else {
      const account = this.authService.instance.getAllAccounts()[0];
      sessionStorage.clear();
      localStorage.clear();

      try {
        await this.authService.logoutRedirect({
          account: account,
          extraQueryParameters: {
            "client_id": this.aadb2c.CLIENT_ID
          },
          idTokenHint: account?.idToken,
          postLogoutRedirectUri: '/'
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    logoutChannel.close();
  }
}