import { Component, inject, signal } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { DemoCheckboxFields } from '../../data/demo-cipo-fields';
import { CipoCheckboxControl } from '../../../shared/components/fields/common';
import { CipoCheckboxComponent } from '../../../shared/components/fields/cipo-checkbox/cipo-checkbox.component';

@Component({
  selector: 'app-demo-cipo-checkbox',
  standalone: true,
  imports: [CipoCheckboxComponent, FlexLayoutModule, KeyValuePipe],
  templateUrl: './demo-cipo-checkbox.component.html',
})
export class DemoCipoCheckboxComponent {
  dialog = inject(MatDialog);

  form = signal(new FormGroup<{ [x: string]: CipoCheckboxControl }>({}));

  constructor() {
    DemoCheckboxFields.forEach(field => {
      const { name, defaultValue, ...props } = field;
      const control = new CipoCheckboxControl(defaultValue, props);
      if (props.required) {
        control.setValidators(Validators.requiredTrue);
      }
      this.form().addControl(name, control);
    });
  }
}
