@if (formControl().fieldData.readonly) {
    <cipo-text-view [config]="formControl().fieldData" [value]="formControl().value" [type]="fieldData().type" />
} @else {
    @if (!fieldData()?.multiple) {
        <mat-form-field class="full-width" appearance="outline">
            <mat-label *ngIf="fieldData().label">{{ fieldData().label | translate }}</mat-label>

            <input
                matInput
                #numberInput
                [thousandSeparator]="fieldData().showThousandSeparator"
                [decimals]="fieldData().decimals"
                [formControl]="formControl()"
                [placeholder]="fieldData().placeholder ?? '' | translate" />

            <mat-icon matPrefix *ngIf="fieldData().prefixIcon">{{ fieldData().prefixIcon }}</mat-icon>
            <span matTextPrefix *ngIf="fieldData().prefix" class="text--pre">{{ fieldData().prefix | translate }}</span>

            <span matTextSuffix *ngIf="fieldData().suffix" class="text--pre">{{ fieldData().suffix | translate }}</span>
            <mat-icon matSuffix *ngIf="fieldData().suffixIcon">{{ fieldData().suffixIcon }}</mat-icon>
            @if (canClearInput() && formControl().value) {
                <button matSuffix mat-icon-button (click)="formControl().setValue(null)">
                    <mat-icon>close</mat-icon>
                </button>
            } @else if (fieldData().fieldDescription) {
                <mat-icon
                    matSuffix
                    class="material-icons-outlined cursor--help"
                    [title]="fieldData().fieldDescription | translate">
                    info
                </mat-icon>
            }
            <mat-error>
                {{ formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate) }}
            </mat-error>
            <mat-hint *ngIf="fieldData().hint">{{ fieldData().hint | translate }}</mat-hint>
        </mat-form-field>
    } @else {
        <mat-form-field class="full-width" appearance="outline">
            <mat-label *ngIf="fieldData().label">{{ fieldData().label | translate }}</mat-label>
            <mat-chip-grid #chipGrid [formControl]="chipsField">
                @for (value of $any(formControl().value); track $index) {
                    <mat-chip-row (removed)="remove($index)" (click)="edit($index)">
                        <span>
                            @if (fieldData().prefixIcon) {
                                <mat-icon inline>{{ fieldData().prefixIcon }}</mat-icon>
                            }
                            @if (fieldData().prefix) {
                                {{ fieldData().prefix | translate }}
                            }
                            {{ value | number: formatting() }}
                            @if (fieldData().suffix) {
                                {{ fieldData().suffix | translate }}
                            }
                            @if (fieldData().suffixIcon) {
                                <mat-icon inline>{{ fieldData().suffixIcon }}</mat-icon>
                            }
                        </span>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input
                    #chipInput
                    [thousandSeparator]="fieldData().showThousandSeparator"
                    [decimals]="fieldData().decimals"
                    [matChipInputFor]="chipGrid"
                    [matChipInputAddOnBlur]="true"
                    autocomplete="off"
                    [placeholder]="fieldData().placeholder ?? '' | translate"
                    (matChipInputTokenEnd)="add($event)" />
            </mat-chip-grid>
            <mat-error>
                {{ formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate) }}
            </mat-error>
            <mat-hint *ngIf="fieldData().hint">{{ fieldData().hint | translate }}</mat-hint>
        </mat-form-field>
    }
}
