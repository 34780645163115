import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { downgradeModule } from '@angular/upgrade/static';

import { StaticProvider } from "@angular/core";
import * as angular from 'angular';
import { AppModule } from "./src/app.module";
import { get } from "scriptjs";

get("https://cdnjs.cloudflare.com/ajax/libs/d3/5.16.0/d3.js", () => {});
get(
  "https://s3.amazonaws.com/cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js",
  () => {}
);

const bootstrapFn = (extraProviders: StaticProvider[]) => {
  const platformRef = platformBrowserDynamic(extraProviders);
  return platformRef.bootstrapModule(AppModule);
};
// Use it to downgrade the Angular module to an AngularJS module.
const downgradedModule = downgradeModule(bootstrapFn);

// Use the downgraded module as a dependency to the main AngularJS module.
export const angularModule = angular.module('angularModule', [
    downgradedModule,
]);
