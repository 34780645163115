import { cipo } from 'cipo';

cipo.controller('SaveMultipleWorkflowEntityInstanceController',
    function (contractId, module, entityInstanceIdList, presetsIdList, transition, role, $scope, WorkflowEntityInstance, $mdDialog, Message, userService) {
        $scope.module = module;
        $scope.perContract = !!contractId;
        $scope.autoTrigger = false;

        var handleError = function (e) {
            Message.error(e.message);
            console.error(e);
        };

        var startTransition = function () {
            return $scope.entityInstance.startTransition(transition, role);
        };

        var prepareTransition = function (autoTrigger) {
            $scope.autoTrigger = autoTrigger;

            if (!autoTrigger) {
                // we have a form
                // the form is cleared because it was loaded with data from first instance
                $scope.entityInstance.transition.screen.data.fields.forEach(f => f.value = null);
                $scope.entityInstance.transition.screen.setupForm(true);
            }
            else {
                // auto trigger set
                $scope.triggerTransitions();
            }
        };

        var closeDialog = function () {
            $mdDialog.hide();
        };

        $scope.triggerTransitions = function () {
            // save multiple transitions
            $scope.entityInstance.triggerMultiTransitionWithDelay(closeDialog, handleError);
        }

        $scope.close = function () {
            // cancel the dialog
            $mdDialog.cancel();
        }

        // creates a workflow instance from given data
        $scope.entityInstance = new WorkflowEntityInstance({
            moduleId: module.moduleId,
            moduleCode: module.code,
            moduleName: module.name,
            entityInstanceId: entityInstanceIdList[0],
            perContract: module.perContract,
            contractId: contractId,
            entityInstanceIdList: entityInstanceIdList,
            presetsIdList: presetsIdList
        });

        Object.defineProperty($scope, 'nonBindingSignature', {
            get: function () {
                return (userService.system.userdata.signature || {}).signatureText }
        });

        $scope.$watch(function () {
            return userService.system.userdata.signature;
        },
        function (n, o) {
            // if we didn't have a signature, but now we have
            if (n && !o && !$scope.entityInstance.transition.screen.data.fields.length && !$scope.entityInstance.transition.screen.notificationForm) {
                prepareTransition(true);
            }
        });

        // initialize the instance
        $scope.entityInstance.init(false, false)
            .then(startTransition)
            .then(prepareTransition)
            .catch(handleError);
    });
