import { Component, OnInit, signal } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map, of } from 'rxjs';
import { produce } from 'immer';

import { DISPLAY_TYPES_FIELDS, DISPLAY_TYPES_RESPONSE, MOCK_FIELDS, MOCK_RESPONSE } from './mock-data';
import {
  ActionEventType,
  ActionType,
  CipoTableOptions,
  DataListField,
  DisplayFormats,
  StatusColumnType,
  TableColumn,
  TableRow,
  DataListCommandFilterType,
  FiltersToApply,
  TablePaginator,
} from 'src/app/shared/components/data-list/common';
import { UtilsService } from 'src/app/shared/services';
import { PAGE_SIZE_OPTIONS } from 'src/app/shared/consts';
import { KeyValueType } from 'src/app/models/common';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styles: `
  ::ng-deep .table-sidenav {
    padding: 60px 0;
  }
  `,
})
export class TableComponent implements OnInit {
  tableColumns: TableColumn[] = [];
  tableData: TableRow[];
  tableRows: TableRow[];

  options: CipoTableOptions = {
    noDataPlaceholder: 'x',
    serverPaginationSort: true,
    pagination: {
      pageSize: 5,
      pageIndex: 0,
      pageSizeOptions: PAGE_SIZE_OPTIONS.default,
      length: MOCK_RESPONSE.records,
    },
  };

  options2: CipoTableOptions = {
    selectable: true,
    noDataPlaceholder: '~',
    pagination: {
      pageSize: 5,
      pageIndex: 0,
      length: MOCK_RESPONSE.records,
      pageSizeOptions: PAGE_SIZE_OPTIONS.default,
    },
    actions: () => this.getActions(),
  };

  infiniteScrollOptions: CipoTableOptions = {
    selectable: true,
    serverPaginationSort: true,
    pagination: {
      pageSize: 20,
      pageIndex: 0,
      pageSizeOptions: PAGE_SIZE_OPTIONS.default,
      length: 100,
    },
  };

  fields = MOCK_FIELDS;
  totalItems = MOCK_RESPONSE.records;
  data = MOCK_RESPONSE.data;
  hasPreviewPermission = false;

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getRandomText() {
    const randomTexts = [
      'Hydrogen',
      'Helium',
      'Lithium',
      'Beryllium',
      'Boron',
      'Carbon',
      'Nitrogen',
      'Oxygen',
      'Fluorine',
      'Neon',
    ];
    return randomTexts[Math.floor(Math.random() * randomTexts.length)];
  }

  isLoading = signal(true);
  longArray = new Array(100)
    .fill({
      full_doc_num: 1,
      Description: 'Hydrogen',
      'Section_#': 1.0079,
      We_are_transmitting_the_following: 'H',
      state_name: {
        bgColor: '#FF0000',
        color: '#FFFFFF',
        label: 'AF',
      },
      daysopened: 1,
      ballincourtrole: 'TT',
    })
    .map((_, full_doc_num) => {
      return {
        full_doc_num: full_doc_num + 1,
        Description: this.getRandomText(),
        'Section_#': (Math.random() * 10).toFixed(4),
        We_are_transmitting_the_following: 'H',
        state_name: {
          bgColor: this.getRandomColor(),
          color: '#FFFFFF',
          label: 'AF',
        },
        daysopened: Math.floor(Math.random() * 10),
        ballincourtrole: 'TT',
      };
    });

  infiniteTableData: TableRow[];

  constructor(private readonly utils: UtilsService) {}

  loadMore({ pageIndex, pageSize }: TablePaginator) {
    setTimeout(() => {
      this.infiniteScrollOptions = produce(this.infiniteScrollOptions, draft => {
        draft.pagination.pageIndex = pageIndex;
      });
      this.infiniteTableData = this.longArray.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    }, 2000);
  }

  ngOnInit() {
    setTimeout(() => {
      this.infiniteTableData = this.longArray.slice(0, this.infiniteScrollOptions.pagination.pageSize);
      this.tableData = this.fetchTableData(this.data, this.tableColumns);
      if (this.options.serverPaginationSort) {
        this.updateTableRows();
      } else {
        this.tableRows = produce(this.tableData, draft => draft);
      }
    }, 2000);

    this.tableColumns = this.getColumnsMapped(this.fields);

    this.initDisplayTypesData();

    // DataList command
    this.mapFilters();
  }

  // This method should be called only if serverPaginationSort is true.
  // In fact, it mimes requesting a new set of data from BE, with needed sorting & pagination params
  updateTableRows() {
    const {
      pagination: { pageIndex, pageSize },
      sort,
    } = this.options;
    const startPosition = Math.min((pageIndex ?? 0) * pageSize, this.tableData.length);
    const endPosition = startPosition + pageSize <= this.tableData.length ? startPosition + pageSize : undefined;
    if (sort?.direction) {
      const tempData = structuredClone(this.tableData).sort((a, b) => {
        const leftParam = a[sort.active] as any;
        const rightParam = b[sort.active] as any;
        if (sort.direction === 'desc') {
          return leftParam < rightParam ? 1 : -1;
        } else {
          return rightParam > leftParam ? -1 : 1;
        }
      });
      this.tableRows = tempData.slice(startPosition, endPosition);
    } else {
      this.tableRows = produce(this.tableData, draft => draft).slice(startPosition, endPosition);
    }
  }

  fetchTableData(data: TableRow[], tableColumns: TableColumn[]) {
    return data.map(element => {
      const row: TableRow = {
        id: element.entity_instance_id,
      };
      tableColumns.forEach(column => {
        if (column.name === 'state_name') {
          row.state_name = {
            bgColor: element['state_color'] as string,
            color: element['state_text_color'] as string,
            label: element['state_name'] as string,
          };
        } else {
          row[column.name] = element[column.name] ?? element[column.id];
        }
      });
      return row;
    });
  }

  getColumnsMapped(fields: DataListField[]): TableColumn[] {
    return fields
      .filter(field => field.hasOwnProperty('position'))
      .sort(field => field.position)
      .map(field => {
        const { id, label, name, priority, typeId, width, formattings, restrictions } = field;
        return {
          id,
          name: this.utils.formatText(name),
          displayName: label ?? name,
          displayFormatId: this.getDisplayFormat(field),
          typeId,
          width,
          priority,
          sortable: priority > 0,
          formattings,
          restrictions,
        };
      });
  }

  getActions(): ActionType[] {
    const defaultActions: ActionType[] = [
      {
        id: 0,
        displayName: 'Download',
        leftIcon: 'info',
        condition: row => (row.state_name as StatusColumnType).label === 'AF',
      },
      {
        id: 1,
        displayName: 'Upload',
        rightIcon: 'call',
      },
    ];

    if (this.hasPreviewPermission) {
      defaultActions.push(
        {
          id: 2,
          displayName: 'Preview',
          rightIcon: 'visibility_on',
        },
        {
          id: 4,
          displayName: 'Preview Material',
          condition: row => row['We_are_transmitting_the_following'] === 'Material Submittal',
        },
      );
    }

    return defaultActions;
  }

  permissionsChanged(event: MatCheckboxChange) {
    this.hasPreviewPermission = event.checked;

    this.options2.actions = () => this.getActions();
    this.options2 = structuredClone(this.options2);
  }

  getDisplayFormat(field: DataListField): DisplayFormats {
    if (field.name === 'state_name') return DisplayFormats.Status;
    if (field.typeId === 4) return DisplayFormats.Date;

    return DisplayFormats.Text;
  }

  changeSelected(event: TableRow[]) {
    console.log(event);
  }

  actionSelected({ actionId, row }: ActionEventType) {
    // you can identify the action using id
    switch (actionId) {
      case 0:
        console.log('Download selected');
        break;

      case 1:
        console.log('Upload Selected');
        break;
      case 2:
        console.log('Preview selected');
        break;
      case 3:
        console.log('Preview Material selected');
      default:
        break;
    }
    console.log(`Action ${actionId} selected for row`, row);
  }

  sortChanged(event: Sort) {
    setTimeout(() => {
      this.options.sort = event;
      this.options.pagination.pageIndex = 0;

      // update ref to trigger @Input() tableOptions
      this.options = structuredClone(this.options);
      this.updateTableRows();
    }, 1000);
  }

  clicked(row: TableRow) {
    console.log(row);
  }

  paginationChanged({ pageIndex, pageSize }: TablePaginator) {
    setTimeout(() => {
      this.options.pagination.pageIndex = pageIndex;
      this.options.pagination.pageSize = pageSize;

      // update ref to trigger @Input() tableOptions
      this.options = structuredClone(this.options);
      this.updateTableRows();
    }, 1000);
  }

  //   Display types data. Following table is separated from the first two tables
  typesTableData: TableRow[] = [];
  typesTableColumns: TableColumn[] = [];
  typesFields = DISPLAY_TYPES_FIELDS as DataListField[];
  typesData = DISPLAY_TYPES_RESPONSE.data as TableRow[];

  initDisplayTypesData() {
    this.typesTableColumns = this.getColumnsMapped(this.typesFields);
    this.typesTableData = this.fetchTableData(this.typesData, this.typesTableColumns);
  }

  //   Code below is the implementation of DataListCommand

  filters: DataListCommandFilterType[] = [];
  filtersToApply: FiltersToApply;

  mapFilters() {
    const mockValues: KeyValueType<number>[] = [
      {
        key: 1,
        value: 'Example Value 1',
      },
      {
        key: 2,
        value: 'Example Value 2',
      },
      {
        key: 3,
        value: 'Example Value 3',
      },
      {
        key: 4,
        value: 'Example Value 4',
      },
      {
        key: 5,
        value: 'Example Value 5',
      },
    ];

    this.typesFields.forEach(({ id, name, label, typeId, isFilter }) => {
      if (isFilter) {
        this.filters.push({
          name: label ?? name,
          fieldId: id,
          typeId: typeId,
          type: typeId === 4 ? 'date' : 'text',
          getValues: label === 'multiline1' && of(null).pipe(map(() => [{ key: label, value: name }])),
          multipleSelection: true,
          values: typeId !== 4 ? mockValues : null,
        });
      }
    });
  }

  applyFilters(filters: FiltersToApply) {
    this.filtersToApply = filters;
    console.log(filters);
  }
}
