import { cipo } from 'cipo';

cipo.controller('checkboxDialogController',
    function ($scope, $mdDialog, data) {
        $scope.data = data.checkboxes;
        $scope.title = data.title;
        $scope.showSelectUnselect = data.showSelectUnselect;
        $scope.confirmLabel = formatLabel(data.buttonLabels.find(x => x.key == 'confirm').value);
        $scope.cancelLabel = formatLabel(data.buttonLabels.find(x => x.key == 'cancel').value);
        $scope.description = data.description;

        $scope.confirm = function () {
            $mdDialog.hide($scope.data.map(x => { return { key: x.key, value: x.value }; }));
        }

        $scope.cancel = function () {
            $mdDialog.hide(null);
        }

        $scope.selectAll = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                $scope.data[i].value = true;
            }
        }

        $scope.deselectAll = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                $scope.data[i].value = false;
            }
        }

        function formatLabel(label) {
            return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
        }
    });
