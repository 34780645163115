import { Injectable } from '@angular/core';
import { EIActivityActionInstances } from '../../../../models/module/entityInstance';
import { HttpService } from '../../../../shared/services/http.service';

@Injectable()
export class ActivityListService {
  constructor(private httpService: HttpService) {}

  getActivityList(moduleId: number, entityInstanceId: number, contractId: number) {
    const params = this.httpService.buildHttpParams({ entityInstanceId, contractId });
    return this.httpService.get<EIActivityActionInstances>('_api/ModuleInstance/GetActivityEntityInstance', {
      httpOptions: { headers: { moduleId: moduleId.toString() }, params },
    });
  }
}
