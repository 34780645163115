import { cipo } from 'cipo';

cipo.directive("explorerGrid1", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "explorer": "="
        },
        templateUrl: "/ng/views/directives/system/explorer1.2.html",
        controller: function ($scope, userService) {
            $scope.showTreeInfo = function () {
            };

            $scope.userService = userService;
            $scope.reloadExplorerInProgress = false;

            Object.defineProperty($scope, 'module', {
                get: function () { return $scope.explorer ? userService.system.modules[$scope.explorer.abbreviation] : undefined; }
            });

            if ($scope.explorer) {
                if (typeof $scope.persistentLayout != "undefined") {
                    $scope.explorer.layout = $scope.persistentLayout;
                }
            }

            $scope.$watch(function () { if ($scope.explorer) return $scope.explorer.layout; }, function (n, o) {
                if ($scope.explorer && typeof $scope.persistentLayout != "undefined") {
                    $scope.explorer.layout = $scope.persistentLayout;
                }
            });

            $scope.$watch(function () {
                return userService.isLoadingMenu;
            },
                function (n, o) {
                    if (!n && $scope.module) {
                        // reload the explorer when the menu is changing
                        // this happens even on contract selection change
                        $scope.reloadExplorer();
                    }
                });
            $scope.$watch(function () {
                return userService.isReloadGrid;
            },
                function (n, o) {
                    if (n && $scope.module) {
                        userService.isReloadGrid = false;
                        
                        // reload the explorer when the grid has to reload
                        $scope.reloadExplorer();
                    }
                });
            

            $scope.$watch(function () { if ($scope.explorer && $scope.explorer.manager) return $scope.explorer.manager.layout; }, function (n, o) {
                if ($scope.explorer && $scope.explorer.manager) {
                    $scope.explorer.layout = n;
                    $scope.persistentLayout = n;
                }
            });

            $scope.reloadExplorer = function() {
                // skip reloading when loading is in progress
                if ($scope.reloadExplorerInProgress === true) {
                    return;
                }
                
                $scope.reloadExplorerInProgress = true;

                ($scope.explorer.urlsData.tree.urlParams || {}).contractId = userService.system.userdata.contractId;
                $scope.explorer.context = {...userService.system.context};

                $scope.explorer.reinit().finally(function() { 
                    if (typeof $scope.persistentLayout != "undefined") {
                        $scope.explorer.layout = $scope.persistentLayout;
                    }
                    $scope.reloadExplorerInProgress = false;
                });
            }
        }
    }
});
