import { cipo } from 'cipo';

cipo.factory("Recover", function ($q, Form, Model, userService, URI, Login) {
    var Recover = Model.extend(function (t) {
        userService.clearData();

        this.check_Token(t);

        
    })

    Recover.prototype.check_Token = function (t) {
        var self = this;
        var p = $q.defer();
        self.isError = false;
        var dataURL = URI.PROFILE.CHECK_TOKEN_AVAILABILITY;
        self[dataURL.method](dataURL, { url: { token: t }, urltype: 'obj' })
            .then(function (r) {
                self.create_Form(r, t);
                p.resolve();
            })
            .catch(function (e) {
                self.isError = true;
                console.error(e);
                p.reject();
            })
        return p.promise;
    }

    Recover.prototype.create_Form = function (r,t) {
        this.properties = {
            token: t,
            email: r,
            newPassword: "",
            passConfirm: ""
        }
        this.form = new Form(this.properties);
        this.form.set_Description({
            email: { label: 'Your email', type: 'text', editMode: false },
            newPassword: { label: 'New password', type: 'password', validation: { required: true, minChars: 6 } },
            passConfirm: { label: 'Confirm password', type: 'password', validation: { required: true, equalsField: "newPassword" } },
        })
    }

    Recover.prototype.send_Password = function () {
        var self = this;
        var p = $q.defer();
        self.form.validate();
        self.form.loading = true;
        if (self.form.isValid) {
            var data = { token: self.properties.token, newPassword: self.properties.newPassword };
            
            var dataURL = URI.PROFILE.CHANGE_PASSWORD_RECOVERY;
            self[dataURL.method](dataURL, data)
                .then(function (r) {
                    var loginObject = new Login();
                    loginObject.properties.username = self.properties.email;
                    loginObject.properties.password = self.properties.newPassword;
                    loginObject.properties.rememberMe = false;

                    loginObject.get_Token().then(function () {
                        p.resolve();
                    }).catch(function (serverCalled) {
                        p.reject(serverCalled);
                    });
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject();
                })
                .finally(function () {
                    self.form.loading = false;
                })
        } else {
            self.form.loading = false;
            p.reject(false);
        }
        
        return p.promise;
    }


    return Recover;
});

    