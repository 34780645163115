import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app.state';

const get = (state: AppState) => state.core.context;
const getContractId = createSelector(
  get,
  context => context.contract?.id ?? context.program?.id ?? context.project?.id,
);

export const contextSelectors = {
  get,
  getContractId,
};
