import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { ModuleIconListComponent } from '../shared/components/module-icon-list/module-icon-list.component';
import { ModuleIconComponent } from '../shared/components/module-icon/module-icon.component';
import { LegacyRoutes } from './constants/routes.constant';
import { LegacyComponent } from './legacy.module';
import { DataListComponent } from '../shared/components/data-list/data-list.component';
import { FroalaComponent } from '../shared/components/froala/froala.component';

export const Ng1ModuleLegacy = angular
  .module('ng1module.legacy', [])
  .directive('appLegacy', downgradeComponent({ component: LegacyComponent }) as angular.IDirectiveFactory)
  .directive('itvModuleIcon', downgradeComponent({ component: ModuleIconComponent }) as angular.IDirectiveFactory)
  .directive(
    'itvModuleIconList',
    downgradeComponent({ component: ModuleIconListComponent }) as angular.IDirectiveFactory,
  )
  .directive('appDataList', downgradeComponent({ component: DataListComponent }) as angular.IDirectiveFactory)
  .directive('appFroala', downgradeComponent({ component: FroalaComponent }) as angular.IDirectiveFactory)
  .factory('legacyRoutes', downgradeInjectable(LegacyRoutes) as any).name;
