<form [formGroup]="form" fxLayout="column" class="full-height p-t p-x-sm" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" class="overflow-y--auto">
        <div fxLayoutAlign="space-between center" class="p-x p-b">
            <mat-button-toggle-group (change)="toggleFilterLogic($event)" class="btn-group">
                <mat-button-toggle checked [value]="0">
                    {{ "dataListCommand.filterByAll" | translate }}
                </mat-button-toggle>
                <mat-button-toggle [value]="1">{{ "dataListCommand.filterByAny" | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
            <button mat-raised-button class="toggle-all-btn" color="primary" (click)="toggleAll()">
                {{ "dataListCommand." + (accordionClosed ? "expandAll" : "collapseAll") | translate }}
                <mat-icon class="material-symbols-outlined" iconPositionEnd>
                    {{ accordionClosed ? "expand_more" : "expand_less" }}
                </mat-icon>
            </button>
        </div>
        <mat-accordion displayMode="flat" multi class="border-sm border-radius--xs m-b-sm">
            @for (filter of filters; track filter.fieldId) {
                <mat-expansion-panel
                    *ngIf="form.controls[filter.fieldId].value as filterFormValue"
                    [formGroupName]="filter.fieldId">
                    <mat-expansion-panel-header class="p-x-md">
                        <div fxLayout class="full-width" fxLayoutAlign="space-between center">
                            <p class="m-a-0 text--ellipsed">
                                {{ filter.name | translate }}
                                <span
                                    *ngIf="![null, undefined, ''].includes(filterFormValue.value)"
                                    class="cipo-text--accent-500">
                                    {{ "FILTER_OPERATORS." + filterFormValue.operator | translate }}
                                    <span class="cipo-text--warn-500">
                                        {{ filterFormValue.value | dataListFilterPipe: filter }}
                                    </span>
                                </span>
                            </p>
                            <button
                                mat-icon-button
                                *ngIf="filterFormValue.value"
                                (click)="$event.stopPropagation(); resetFilter(filter.fieldId)"
                                color="warn">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel-header>

                    <mat-form-field class="cipo-input hide-subscript m-l-sm filter-field">
                        <mat-select
                            formControlName="operator"
                            placeholder="Operator"
                            (selectionChange)="selectOperator(filter)">
                            @for (operator of filter.operators; track operator) {
                                <mat-option [value]="operator">
                                    {{ "FILTER_OPERATORS." + operator | translate }}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    @if (filter.values) {
                        <mat-form-field class="cipo-input hide-subscript m-l-sm filter-field">
                            <mat-select
                                formControlName="value"
                                [multiple]="filter.multipleSelection"
                                placeholder="Value">
                                @for (value of filter.values; track value.key) {
                                    <mat-option [value]="value.key">{{ value.value | translate }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    } @else if (["text", "number"].includes(filter.type)) {
                        <mat-form-field class="cipo-input hide-subscript m-l-sm filter-field">
                            <input matInput placeholder="Value" formControlName="value" />
                        </mat-form-field>
                    } @else if (RANGE_FILTER_KEYS.includes(filterFormValue.operator)) {
                        <mat-form-field class="cipo-input hide-subscript m-l-sm filter-field">
                            <input
                                matInput
                                readonly
                                [owlDateTime]="datePicker"
                                selectMode="range"
                                formControlName="value" />
                            <button mat-icon-button [owlDateTimeTrigger]="datePicker" matSuffix>
                                <mat-icon>calendar_month</mat-icon>
                            </button>
                            <owl-date-time #datePicker pickerType="calendar"></owl-date-time>
                        </mat-form-field>
                    } @else {
                        <mat-form-field class="cipo-input hide-subscript m-l-sm filter-field">
                            <input matInput readonly [owlDateTime]="datePicker" formControlName="value" />
                            <button mat-icon-button [owlDateTimeTrigger]="datePicker" matSuffix>
                                <mat-icon>calendar_month</mat-icon>
                            </button>
                            <owl-date-time #datePicker pickerType="calendar"></owl-date-time>
                        </mat-form-field>
                    }
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
    <div fxLayoutGap="16px">
        <button mat-raised-button (click)="resetFilter()" fxFlex>{{ "dataListCommand.clear" | translate }}</button>
        <button mat-raised-button color="accent" (click)="closeFilter()" fxFlex>
            {{ "general.close" | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="applyFilters()" fxFlex>
            {{ "dataListCommand.apply" | translate }}
        </button>
    </div>
</form>
