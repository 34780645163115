import { FilterModel } from "./filterModel";
import { SortModel } from "./sortModel";

export interface SearchViewModel {
    id?: number;
    criteria?: string;
    filter?: FilterModel;
    sort?: SortModel[];
    isCurrent?: boolean;
    page?: number;
    pagesize?: number;
    showDisabled?: boolean;
}