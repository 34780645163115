import { ContextInfo, ContextTree } from '../../system/context/context.model';

export const DemoContextInfo: ContextInfo = {
  program: {
    id: 4,
    entityInstanceId: 596,
    name: 'Waste Water',
    moduleId: -23,
    moduleAbbr: 'PRG',
    moduleColor: '#3e3e3e',
    moduleIconId: 91,
  },
  project: {
    id: 33,
    entityInstanceId: 2052,
    name: '#RP-5# Training Project',
    moduleId: -10,
    moduleAbbr: 'P',
    moduleColor: '#3e3e3e',
    moduleIconId: 90,
  },
  contract: {
    id: 52,
    entityInstanceId: 2055,
    name: '#RP-5# Testing and Training',
    no: '#RP-5#',
    moduleId: -1,
    moduleAbbr: 'CT',
    moduleColor: '#bf7070',
    moduleIconId: 13,
  },
};

export const DemoContextInfoProjectSelected: ContextInfo = {
  program: {
    id: 4,
    entityInstanceId: 596,
    name: 'Waste Water',
    moduleId: -23,
    moduleAbbr: 'PRG',
    moduleColor: '#3e3e3e',
    moduleIconId: 91,
  },
  project: {
    id: 33,
    entityInstanceId: 2052,
    name: '#RP-5# Training Project',
    moduleId: -10,
    moduleAbbr: 'P',
    moduleColor: '#3e3e3e',
    moduleIconId: 90,
  },
};

export const DemoContextInfoProgramSelected: ContextInfo = {
  program: {
    id: 4,
    entityInstanceId: 596,
    name: 'Waste Water',
    moduleId: -23,
    moduleAbbr: 'PRG',
    moduleColor: '#3e3e3e',
    moduleIconId: 91,
  },
};

export const DemoContextTree: ContextTree = {
  programs: [
    {
      id: 4,
      entityInstanceId: 596,
      moduleId: -23,
      name: 'Waste Water',
      projects: [
        {
          id: 33,
          entityInstanceId: 2052,
          moduleId: -10,
          name: '#RP-5# Training Project',
          contracts: [
            {
              id: 52,
              entityInstanceId: 2055,
              moduleId: -1,
              no: '#RP-5#',
              name: '#RP-5# Testing and Training',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 179,
              entityInstanceId: 50260,
              moduleId: -1,
              no: '14/14',
              name: 'SOV import',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 180,
              entityInstanceId: 50380,
              moduleId: -1,
              no: '15/15',
              name: 'SOV Items Regression Test',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 181,
              entityInstanceId: 51398,
              moduleId: -1,
              no: '16/16',
              name: 'SOVi & PP regression tests',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 183,
              entityInstanceId: 51474,
              moduleId: -1,
              no: '17/17',
              name: 'Import SOV from WBS',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 127,
              entityInstanceId: 34827,
              moduleId: -1,
              no: 'D23',
              name: 'Design Contract Name',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 34,
          entityInstanceId: 47317,
          moduleId: -10,
          name: 'East End Flow Meter Replacement',
          contracts: [
            {
              id: 167,
              entityInstanceId: 47078,
              moduleId: -1,
              no: '1AND',
              name: '01-Andrei',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 53,
              entityInstanceId: 2658,
              moduleId: -1,
              no: 'EN22002',
              name: 'East End Flow Meter Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 27,
          entityInstanceId: 838,
          moduleId: -10,
          name: '1158 East & West Reservoir Re-coating/painting and Upgrades',
          contracts: [
            {
              id: 162,
              entityInstanceId: 46211,
              moduleId: -1,
              no: '7/7',
              name: 'SOV-Manage Drafts',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 46,
              entityInstanceId: 839,
              moduleId: -1,
              no: 'EN21004/EN22004',
              name: '1158 East & West Reservoir Re-Coating/Painting',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 104,
          entityInstanceId: 37371,
          moduleId: -10,
          name: 'Old VFD Replacement (Wastewater)',
          contracts: [
            {
              id: 136,
              entityInstanceId: 37372,
              moduleId: -1,
              no: 'AM23001',
              name: 'Old VFD Replacement (Wastewater)',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 111,
          entityInstanceId: 38252,
          moduleId: -10,
          name: 'Agency-Wide Old VFD Upgrades (Recycle Water)',
          contracts: [
            {
              id: 143,
              entityInstanceId: 38253,
              moduleId: -1,
              no: 'AM23002',
              name: 'Agency-Wide Old VFD Upgrades (Recycle Water)',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 68,
          entityInstanceId: 26112,
          moduleId: -10,
          name: 'RP-1 Disinfection Improvements Project',
          contracts: [
            {
              id: 96,
              entityInstanceId: 26114,
              moduleId: -1,
              no: 'EN11039.00',
              name: 'RP-1 Disinfection Improvements Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 108,
          entityInstanceId: 37535,
          moduleId: -10,
          name: 'SCADA Enterprise System - (Regional Water Recycling Plant No. 1)',
          contracts: [
            {
              id: 140,
              entityInstanceId: 37536,
              moduleId: -1,
              no: 'EN13016.05',
              name: 'SCADA Enterprise System - (Regional Water Recycling Plant No. 1)',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 103,
          entityInstanceId: 37189,
          moduleId: -10,
          name: 'RP-1 TP-1 Water Piping Upgrades',
          contracts: [
            {
              id: 135,
              entityInstanceId: 37190,
              moduleId: -1,
              no: 'EN13016.06',
              name: 'RP-1 TP-1 Water Piping Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 99,
          entityInstanceId: 35565,
          moduleId: -10,
          name: 'CCWRF Asset Management and Improvements Project',
          contracts: [
            {
              id: 131,
              entityInstanceId: 35566,
              moduleId: -1,
              no: 'EN17006.00',
              name: 'CCWRF Asset Management and Improvements Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 72,
          entityInstanceId: 28694,
          moduleId: -10,
          name: 'CCWRF 12kV Backup Generator Control Circuit Improvements',
          contracts: [
            {
              id: 100,
              entityInstanceId: 28695,
              moduleId: -1,
              no: 'EN17006.01',
              name: 'CCWRF 12kV Backup Generator Control Circuit Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 45,
          entityInstanceId: 16150,
          moduleId: -10,
          name: 'Orchard Recycled Water Turnout Improvements',
          contracts: [
            {
              id: 73,
              entityInstanceId: 16151,
              moduleId: -1,
              no: 'EN17041',
              name: 'Orchard Recycled Water Turnout Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 110,
          entityInstanceId: 37796,
          moduleId: -10,
          name: 'Digester 6 and 7 Roof Repairs',
          contracts: [
            {
              id: 142,
              entityInstanceId: 37797,
              moduleId: -1,
              no: 'EN17042',
              name: 'Digester 6 & 7 Roof Repairs',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 31,
          entityInstanceId: 1205,
          moduleId: -10,
          name: 'RP-4 Process Improvement and Primary Clarifier Rehab',
          contracts: [
            {
              id: 50,
              entityInstanceId: 1206,
              moduleId: -1,
              no: 'EN17043/EN17110',
              name: 'RP-4 Process Improvement and Primary Clarifier Rehab',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 84,
          entityInstanceId: 31645,
          moduleId: -10,
          name: 'RP-1 Secondary Clarifiers 3&4 Level Signal Cable Replacement',
          contracts: [
            {
              id: 112,
              entityInstanceId: 31646,
              moduleId: -1,
              no: 'EN17082.01',
              name: 'RP-1 Secondary Clarifiers 3&4 Level Signal Cable Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 38,
          entityInstanceId: 4803,
          moduleId: -10,
          name: 'RP-4 Aeration Basin Diffusers and Wall Repair',
          contracts: [
            {
              id: 57,
              entityInstanceId: 4804,
              moduleId: -1,
              no: 'EN17110.03',
              name: 'RP-4 Aeration Basin Diffusers and Wall Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 35,
          entityInstanceId: 2876,
          moduleId: -10,
          name: 'RP-1 Flare Improvement Project',
          contracts: [
            {
              id: 54,
              entityInstanceId: 2918,
              moduleId: -1,
              no: 'EN18006',
              name: 'RP-1 Flare Improvement Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 100,
          entityInstanceId: 35745,
          moduleId: -10,
          name: 'RP-5 Off-Site Facilities Projects Design',
          contracts: [
            {
              id: 132,
              entityInstanceId: 35749,
              moduleId: -1,
              no: 'EN19001.03',
              name: 'RP-5 Off-Site Facilities Projects Design',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 37,
          entityInstanceId: 3845,
          moduleId: -10,
          name: 'RP-5 Expansion Project',
          contracts: [
            {
              id: 56,
              entityInstanceId: 3846,
              moduleId: -1,
              no: 'EN19001/EN19006',
              name: 'RP-5 Expansion Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 39,
          entityInstanceId: 5049,
          moduleId: -10,
          name: 'RP-5 Expansion:  Landscape',
          contracts: [
            {
              id: 58,
              entityInstanceId: 5050,
              moduleId: -1,
              no: 'EN19001L-EN19005L',
              name: 'RP-5 Expansion:  Landscape',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 28,
          entityInstanceId: 840,
          moduleId: -10,
          name: 'RP-4 Influent Screen Replacements',
          contracts: [
            {
              id: 47,
              entityInstanceId: 841,
              moduleId: -1,
              no: 'EN19010.00',
              name: 'RP-4 Influent Screen Replacements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 46,
          entityInstanceId: 16207,
          moduleId: -10,
          name: 'Regional System Asset Management',
          contracts: [
            {
              id: 74,
              entityInstanceId: 16208,
              moduleId: -1,
              no: 'EN19024/EN19028',
              name: 'Regional System Asset Management',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 54,
          entityInstanceId: 21841,
          moduleId: -10,
          name: 'Glen Meade Trunk Critical Repair',
          contracts: [
            {
              id: 82,
              entityInstanceId: 21842,
              moduleId: -1,
              no: 'EN19024GMT',
              name: 'Glen Meade Trunk Critical Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 48,
          entityInstanceId: 17570,
          moduleId: -10,
          name: 'Removal of Manhole Lids',
          contracts: [
            {
              id: 76,
              entityInstanceId: 17571,
              moduleId: -1,
              no: 'EN19024MH/EN19028MH',
              name: 'Removal of Manhole Lids',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 36,
          entityInstanceId: 3308,
          moduleId: -10,
          name: 'Regional Force Main',
          contracts: [
            {
              id: 55,
              entityInstanceId: 3309,
              moduleId: -1,
              no: 'EN19025',
              name: 'Regional Force Main',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 47,
          entityInstanceId: 17074,
          moduleId: -10,
          name: 'NRW Pipeline Relining Along Cucamonga Creek',
          contracts: [
            {
              id: 75,
              entityInstanceId: 17075,
              moduleId: -1,
              no: 'EN19027',
              name: 'NRW Pipeline Relining Along Cucamonga Creek',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 43,
          entityInstanceId: 11608,
          moduleId: -10,
          name: 'RP-1 Centrifuge Foul Air Line',
          contracts: [
            {
              id: 70,
              entityInstanceId: 11609,
              moduleId: -1,
              no: 'EN19043',
              name: 'RP-1 Centrifuge Foul Air Line',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 55,
          entityInstanceId: 21848,
          moduleId: -10,
          name: 'HQ Parking Lot & Driveway Improvements',
          contracts: [
            {
              id: 83,
              entityInstanceId: 21850,
              moduleId: -1,
              no: 'EN20008/EN20040',
              name: 'HQ Parking Lot & Driveway Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 25,
          entityInstanceId: 834,
          moduleId: -10,
          name: 'NRW Manhole and Collections System Upgrades',
          contracts: [
            {
              id: 44,
              entityInstanceId: 835,
              moduleId: -1,
              no: 'EN20014/EN20015',
              name: 'NRW Manhole and Collections System Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 63,
          entityInstanceId: 24568,
          moduleId: -10,
          name: '1299 Reservoir Bypass Pipeline',
          contracts: [
            {
              id: 91,
              entityInstanceId: 24569,
              moduleId: -1,
              no: 'EN20022',
              name: '1299 Reservoir Bypass Pipeline',
              isUsed: true,
              isArchived: true,
              isClosed: false
            },
          ],
        },
        {
          id: 42,
          entityInstanceId: 11607,
          moduleId: -10,
          name: 'RP-1 Tertiary Treatment Bleach Mixing System Repairs',
          contracts: [
            {
              id: 71,
              entityInstanceId: 11625,
              moduleId: -1,
              no: 'EN20041',
              name: 'RP-1 Tertiary Treatment Bleach Mixing System Repairs',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 49,
          entityInstanceId: 17583,
          moduleId: -10,
          name: 'RSS Haven Avenue Repairs',
          contracts: [
            {
              id: 77,
              entityInstanceId: 17584,
              moduleId: -1,
              no: 'EN20056.00',
              name: 'RSS Haven Avenue Repairs',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 90,
          entityInstanceId: 34155,
          moduleId: -10,
          name: 'RP-4 Process Improvements Phase II',
          contracts: [
            {
              id: 118,
              entityInstanceId: 34156,
              moduleId: -1,
              no: 'EN20057',
              name: 'RP-4 Process Improvements Phase II',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 40,
          entityInstanceId: 7917,
          moduleId: -10,
          name: 'RP-1 TP-1 Waste Wash Water Basin Pumps Replacement',
          contracts: [
            {
              id: 68,
              entityInstanceId: 7991,
              moduleId: -1,
              no: 'EN20058',
              name: 'RP-1 TP-1 Waste Wash Water Basin Pumps Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 91,
          entityInstanceId: 34312,
          moduleId: -10,
          name: 'North Sewer North Trunk Sewer Siphon Replacement',
          contracts: [
            {
              id: 119,
              entityInstanceId: 34315,
              moduleId: -1,
              no: 'EN20064.00',
              name: 'North Sewer North Trunk Sewer Siphon Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 41,
          entityInstanceId: 9990,
          moduleId: -10,
          name: 'RP-1 Solids Hot Water Loop Valves',
          contracts: [
            {
              id: 69,
              entityInstanceId: 9991,
              moduleId: -1,
              no: 'EN20065',
              name: 'RP-1 Solids Hot Water Loop Valves',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 44,
          entityInstanceId: 13754,
          moduleId: -10,
          name: 'FY20/21BSS Manhole and RSS Manhole Upgrades',
          contracts: [
            {
              id: 72,
              entityInstanceId: 13755,
              moduleId: -1,
              no: 'EN21014/EN21015',
              name: 'FY 20/21 BSS Manhole and RSS Manhole Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 60,
          entityInstanceId: 23545,
          moduleId: -10,
          name: 'Foothill Feeder CB-11B Cabinet Repair',
          contracts: [
            {
              id: 88,
              entityInstanceId: 23547,
              moduleId: -1,
              no: 'EN21036.01',
              name: 'Foothill Feeder CB-11B Cabinet Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 73,
          entityInstanceId: 30303,
          moduleId: -10,
          name: 'RP-4 Contact Basin Cover & RW Wet Well Passive Overflow Line',
          contracts: [
            {
              id: 101,
              entityInstanceId: 30304,
              moduleId: -1,
              no: 'EN21041',
              name: 'RP-4 Contact Basin Cover & RW Wet Well Passive Overflow Line',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 67,
          entityInstanceId: 26108,
          moduleId: -10,
          name: 'RP-1 East Influent Gate Replacement Project',
          contracts: [
            {
              id: 95,
              entityInstanceId: 26109,
              moduleId: -1,
              no: 'EN21042.00',
              name: 'RP-1 East Influent Gate Replacement Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 50,
          entityInstanceId: 18170,
          moduleId: -10,
          name: 'RP-1 Dewatering Centrate and Drainage Valve Improvements',
          contracts: [
            {
              id: 78,
              entityInstanceId: 18171,
              moduleId: -1,
              no: 'EN21044',
              name: 'RP-1 Dewatering Centrate and Drainage Valve Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 74,
          entityInstanceId: 30306,
          moduleId: -10,
          name: 'Montclair Force Main Improvements',
          contracts: [
            {
              id: 102,
              entityInstanceId: 30307,
              moduleId: -1,
              no: 'EN21045',
              name: 'Montclair Force Main Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 83,
          entityInstanceId: 31269,
          moduleId: -10,
          name: 'RP-1 Filter Effluent Structure #2 Rehabilitation',
          contracts: [
            {
              id: 111,
              entityInstanceId: 31270,
              moduleId: -1,
              no: 'EN21053',
              name: 'RP-1 Filter Effluent Structure #2 Rehabilitation',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 75,
          entityInstanceId: 30537,
          moduleId: -10,
          name: 'Recharge Basin Clean-up of Illegally Dumped RP-3 Trash Net',
          contracts: [
            {
              id: 103,
              entityInstanceId: 30538,
              moduleId: -1,
              no: 'EN21057.00',
              name: 'Recharge Basin Clean-up of Illegally Dumped RP-3 Trash Net',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 113,
          entityInstanceId: 38297,
          moduleId: -10,
          name: 'Trash Boom Installation at Jurupa Basin and Ely Basin 1',
          contracts: [
            {
              id: 145,
              entityInstanceId: 38301,
              moduleId: -1,
              no: 'EN21057Trash Boom',
              name: 'Trash Boom Installation at Jurupa Basin and Ely Basin 1',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 112,
          entityInstanceId: 38296,
          moduleId: -10,
          name: 'Turner Basin Grate Modification',
          contracts: [
            {
              id: 144,
              entityInstanceId: 38299,
              moduleId: -1,
              no: 'EN21057Turner Basin',
              name: 'Turner Basin Grate Modification',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 61,
          entityInstanceId: 23761,
          moduleId: -10,
          name: 'BSS Manhole Upgrades FY21/22 & RSS Manhole Upgrades FY21/22',
          contracts: [
            {
              id: 89,
              entityInstanceId: 23762,
              moduleId: -1,
              no: 'EN22014/EN22015',
              name: 'BSS Manhole Upgrades FY21/22 & RSS Manhole Upgrades FY21/22',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 95,
          entityInstanceId: 34810,
          moduleId: -10,
          name: 'RP-1 Digester Area Utility Water (UW) Line Valve Replacement',
          contracts: [
            {
              id: 123,
              entityInstanceId: 34811,
              moduleId: -1,
              no: 'EN22021',
              name: 'RP-1 Digester Area Utility Water (UW) Line Valve Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 58,
          entityInstanceId: 23485,
          moduleId: -10,
          name: 'RP-5 Emergency Generator Load Bank Installation',
          contracts: [
            {
              id: 86,
              entityInstanceId: 23486,
              moduleId: -1,
              no: 'EN22033',
              name: 'RP-5 Emergency Generator Load Bank Installation',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 70,
          entityInstanceId: 27262,
          moduleId: -10,
          name: 'RP-1 RP-4 Generator Control Panel Retrofits',
          contracts: [
            {
              id: 98,
              entityInstanceId: 27263,
              moduleId: -1,
              no: 'EN22034',
              name: 'RP-1 RP-4 Generator Control Panel Retrofits',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 87,
          entityInstanceId: 33141,
          moduleId: -10,
          name: 'RP-1 Aeration Basins Utility Water System Improvements',
          contracts: [
            {
              id: 115,
              entityInstanceId: 33142,
              moduleId: -1,
              no: 'EN22041',
              name: 'RP-1 Aeration Basins Utility Water System Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 64,
          entityInstanceId: 24977,
          moduleId: -10,
          name: 'RP-1 & RP-4 Ammonia and Free Chlorine Analyzers',
          contracts: [
            {
              id: 92,
              entityInstanceId: 25020,
              moduleId: -1,
              no: 'EN22042',
              name: 'RP-1 & RP-4 Ammonia and Free Chlorine Analyzers',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 57,
          entityInstanceId: 22649,
          moduleId: -10,
          name: 'Agency Wide Chemical Containment Area Rehabilitation Phase 2',
          contracts: [
            {
              id: 85,
              entityInstanceId: 22650,
              moduleId: -1,
              no: 'EN22043',
              name: 'Agency Wide Chemical Containment Area Rehabilitation Phase 2',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 59,
          entityInstanceId: 23487,
          moduleId: -10,
          name: 'Philadelphia Lift Station Generator Upgrades',
          contracts: [
            {
              id: 87,
              entityInstanceId: 23488,
              moduleId: -1,
              no: 'EN22048',
              name: 'Philadelphia Lift Station Generator Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 115,
          entityInstanceId: 38403,
          moduleId: -10,
          name: '1158 Pump Station EDV Piping Repair',
          contracts: [
            {
              id: 147,
              entityInstanceId: 38404,
              moduleId: -1,
              no: 'EN22053.09',
              name: '1158 Pump Station EDV Piping Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 71,
          entityInstanceId: 28692,
          moduleId: -10,
          name: 'CCWRF Fire Hydrant and Valve Replacement',
          contracts: [
            {
              id: 99,
              entityInstanceId: 28693,
              moduleId: -1,
              no: 'EN22054.01',
              name: 'CCWRF Fire Hydrant and Valve Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 69,
          entityInstanceId: 27259,
          moduleId: -10,
          name: 'CCWRF Vault Rehab and Pavement Repair',
          contracts: [
            {
              id: 97,
              entityInstanceId: 27260,
              moduleId: -1,
              no: 'EN22055.02',
              name: 'CCWRF Vault Rehab and Pavement Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 65,
          entityInstanceId: 25716,
          moduleId: -10,
          name: 'Prado Basin Assets Retrofits',
          contracts: [
            {
              id: 93,
              entityInstanceId: 25717,
              moduleId: -1,
              no: 'EN22057/EN22058/EN22059',
              name: 'Prado Basin Assets Retrofits',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 98,
          entityInstanceId: 35252,
          moduleId: -10,
          name: 'RP-1 DeviceNet Replacement',
          contracts: [
            {
              id: 130,
              entityInstanceId: 35253,
              moduleId: -1,
              no: 'EN23000.00',
              name: 'RP-1 DeviceNet Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 105,
          entityInstanceId: 37373,
          moduleId: -10,
          name: 'Central Plant Cooling Tower Replacement',
          contracts: [
            {
              id: 137,
              entityInstanceId: 37374,
              moduleId: -1,
              no: 'EN23003',
              name: 'Central Plant Cooling Tower Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 77,
          entityInstanceId: 30718,
          moduleId: -10,
          name: 'CCWRF Aeration Basins 1-6 Drain Valve Replacements',
          contracts: [
            {
              id: 105,
              entityInstanceId: 30719,
              moduleId: -1,
              no: 'EN23004',
              name: 'CCWRF Aeration Basins 1-6 Drain Valve Replacements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 78,
          entityInstanceId: 30720,
          moduleId: -10,
          name: 'CCWRF Filter Effluent Sodium Hypochlorite Modification',
          contracts: [
            {
              id: 106,
              entityInstanceId: 30721,
              moduleId: -1,
              no: 'EN23005',
              name: 'CCWRF Filter Effluent Sodium Hypochlorite Modification',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 89,
          entityInstanceId: 33785,
          moduleId: -10,
          name: 'BSS and RSS Manhole Upgrades FY22/23',
          contracts: [
            {
              id: 117,
              entityInstanceId: 33787,
              moduleId: -1,
              no: 'EN23014/EN23015',
              name: 'BSS and RSS Manhole Upgrades FY22/23',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 107,
          entityInstanceId: 37529,
          moduleId: -10,
          name: 'RP-1 TP-1 Stormwater Drainage Upgrades',
          contracts: [
            {
              id: 139,
              entityInstanceId: 37530,
              moduleId: -1,
              no: 'EN23024',
              name: 'RP-1 TP-1 Stormwater Drainage Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 82,
          entityInstanceId: 30728,
          moduleId: -10,
          name: 'CCWRF RAS Header Replacement',
          contracts: [
            {
              id: 110,
              entityInstanceId: 30729,
              moduleId: -1,
              no: 'EN23035',
              name: 'CCWRF RAS Header Replacement',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 101,
          entityInstanceId: 36112,
          moduleId: -10,
          name: 'San Bernardino Ave Lift Station Reliability Improvements',
          contracts: [
            {
              id: 133,
              entityInstanceId: 36113,
              moduleId: -1,
              no: 'EN23036',
              name: 'San Bernardino Ave Lift Station Reliability Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 79,
          entityInstanceId: 30722,
          moduleId: -10,
          name: 'CCWRF HVAC System Upgrade',
          contracts: [
            {
              id: 107,
              entityInstanceId: 30723,
              moduleId: -1,
              no: 'EN23038',
              name: 'CCWRF HVAC System Upgrade',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 86,
          entityInstanceId: 32213,
          moduleId: -10,
          name: 'Ely Monitoring Well',
          contracts: [
            {
              id: 114,
              entityInstanceId: 32214,
              moduleId: -1,
              no: 'EN23041',
              name: 'Ely Monitoring Well',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 92,
          entityInstanceId: 34327,
          moduleId: -10,
          name: 'Preservie LIft Station Improvements',
          contracts: [
            {
              id: 120,
              entityInstanceId: 34332,
              moduleId: -1,
              no: 'EN23066.00',
              name: 'Preserve Lift Station Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 109,
          entityInstanceId: 37562,
          moduleId: -10,
          name: 'Hickory Basin Replacement Monitoring Well',
          contracts: [
            {
              id: 141,
              entityInstanceId: 37566,
              moduleId: -1,
              no: 'EN23067',
              name: 'Hickory Basin Replacement Monitoring Well',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 80,
          entityInstanceId: 30724,
          moduleId: -10,
          name: 'CCWRF Influent Box Rehab at Primary Clarifiers',
          contracts: [
            {
              id: 108,
              entityInstanceId: 30725,
              moduleId: -1,
              no: 'EN23074',
              name: 'CCWRF Influent Box Rehab at Primary Clarifiers',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 106,
          entityInstanceId: 37527,
          moduleId: -10,
          name: 'RP-4 Surcharging of Manholes Assessment',
          contracts: [
            {
              id: 138,
              entityInstanceId: 37528,
              moduleId: -1,
              no: 'EN23088.01',
              name: 'RP-4 Surcharging of Manholes Assessment',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 81,
          entityInstanceId: 30726,
          moduleId: -10,
          name: 'RP-1 Primary Clarifier #1 and #10 Assessment',
          contracts: [
            {
              id: 109,
              entityInstanceId: 30727,
              moduleId: -1,
              no: 'EN23088.02',
              name: 'RP-1 Primary Clarifier #1 and #10 Assessment',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 114,
          entityInstanceId: 38397,
          moduleId: -10,
          name: 'RP-1 Headworks Biofilter Coating Project',
          contracts: [
            {
              id: 146,
              entityInstanceId: 38400,
              moduleId: -1,
              no: 'EN23088.05',
              name: 'RP-1 Headworks Biofilter Coating Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 94,
          entityInstanceId: 34612,
          moduleId: -10,
          name: 'Recycled Water System Improvements (Valves, Blow Offs)',
          contracts: [
            {
              id: 122,
              entityInstanceId: 34613,
              moduleId: -1,
              no: 'EN23090.02',
              name: 'Recycled Water System Improvements (Valves, Blow Offs)',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 93,
          entityInstanceId: 34385,
          moduleId: -10,
          name: 'RP-1 Headworks Bar Screen System Improvements',
          contracts: [
            {
              id: 121,
              entityInstanceId: 34386,
              moduleId: -1,
              no: 'EN23111.00',
              name: 'RP-1 Headworks Bar Screen System Improvements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 88,
          entityInstanceId: 33325,
          moduleId: -10,
          name: 'RW/GWR Safety Improvements for Basin Gate Actuator Access',
          contracts: [
            {
              id: 116,
              entityInstanceId: 33328,
              moduleId: -1,
              no: 'EN23113',
              name: 'RW/GRW Safety Work Improvements for Basin Gate Actuator Access',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 97,
          entityInstanceId: 35226,
          moduleId: -10,
          name: 'RP-1 Instrumentation & Controls Enhancements',
          contracts: [
            {
              id: 129,
              entityInstanceId: 35227,
              moduleId: -1,
              no: 'EN23114',
              name: 'RP-1 Instrumentation & Controls Enhancements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 96,
          entityInstanceId: 34871,
          moduleId: -10,
          name: 'RP-4 Headworks Utility Water Addition',
          contracts: [
            {
              id: 128,
              entityInstanceId: 34883,
              moduleId: -1,
              no: 'EN23115',
              name: 'RP-4 Headworks Utility Water Addition',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 102,
          entityInstanceId: 36328,
          moduleId: -10,
          name: 'Recycled Water SCADA Migration',
          contracts: [
            {
              id: 134,
              entityInstanceId: 36329,
              moduleId: -1,
              no: 'EN23119.00',
              name: 'Recycled Water SCADA Migration',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 85,
          entityInstanceId: 31919,
          moduleId: -10,
          name: 'RP-4 Outfall Valve Replacement and Blow off Upgrades',
          contracts: [
            {
              id: 113,
              entityInstanceId: 31920,
              moduleId: -1,
              no: 'EN23123',
              name: 'RP-4 Outfall Valve Replacement and Blow off Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 76,
          entityInstanceId: 30664,
          moduleId: -10,
          name: '1630 East Pump Station VFD Installation',
          contracts: [
            {
              id: 104,
              entityInstanceId: 30665,
              moduleId: -1,
              no: 'EN23124',
              name: '1630 East Pump Station VFD Installation',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 121,
          entityInstanceId: 42259,
          moduleId: -10,
          name: '930 RW Pipeline Blow Off Upgrades',
          contracts: [
            {
              id: 152,
              entityInstanceId: 42260,
              moduleId: -1,
              no: 'EN24008',
              name: '930 RW Pipeline Blow Off Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 117,
          entityInstanceId: 38669,
          moduleId: -10,
          name: 'RP-1 Centrate Line Improvement Design',
          contracts: [
            {
              id: 149,
              entityInstanceId: 38670,
              moduleId: -1,
              no: 'EN24020',
              name: 'RP-1 Centrate Line Improvement Design',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 126,
          entityInstanceId: 45702,
          moduleId: -10,
          name: 'RP-1 Centrate Line Plug Valve Replacements',
          contracts: [
            {
              id: 157,
              entityInstanceId: 45703,
              moduleId: -1,
              no: 'EN24020.03',
              name: 'RP-1 Centrate Line Plug Valve Replacements',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 125,
          entityInstanceId: 44022,
          moduleId: -10,
          name: 'RP-2 UW Valve Installations',
          contracts: [
            {
              id: 156,
              entityInstanceId: 44023,
              moduleId: -1,
              no: 'EN24026',
              name: 'RP-2 UW Valve Installation',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 119,
          entityInstanceId: 41505,
          moduleId: -10,
          name: 'RP-1 Primary Clarifier #1 Through #10 Rehabilitation',
          contracts: [
            {
              id: 151,
              entityInstanceId: 41542,
              moduleId: -1,
              no: 'EN24032',
              name: 'RP-1 Primary Clarifier #1 Through #10 Rehabilitation',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 66,
          entityInstanceId: 25719,
          moduleId: -10,
          name: 'Agency Wide Roofing Phase III',
          contracts: [
            {
              id: 94,
              entityInstanceId: 25720,
              moduleId: -1,
              no: 'FM21005.01',
              name: 'Agency Wide Roofing Phase III',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 116,
          entityInstanceId: 38613,
          moduleId: -10,
          name: 'Prado Park De-Chlorination Building Roofing Rehab',
          contracts: [
            {
              id: 148,
              entityInstanceId: 38614,
              moduleId: -1,
              no: 'FM21005.02',
              name: 'Prado Park De-Chlorination Building Roofing Rehab',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 56,
          entityInstanceId: 22170,
          moduleId: -10,
          name: 'RP-1 DCS/ISS Trailer Repairs',
          contracts: [
            {
              id: 84,
              entityInstanceId: 22171,
              moduleId: -1,
              no: 'IS21006',
              name: 'RP-1 DCS/ISS Trailer Repairs',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 53,
          entityInstanceId: 20896,
          moduleId: -10,
          name: 'RP-1 Aeration Membrane Replacement Project',
          contracts: [
            {
              id: 81,
              entityInstanceId: 20897,
              moduleId: -1,
              no: 'PA17006.03',
              name: 'RP-1 Aeration Membrane Replacement Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 62,
          entityInstanceId: 24562,
          moduleId: -10,
          name: 'Inland Empire Regional Composting Facility Column Repair',
          contracts: [
            {
              id: 90,
              entityInstanceId: 24563,
              moduleId: -1,
              no: 'RA22001',
              name: 'Inland Empire Regional Composting Facility Column Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 123,
          entityInstanceId: 42998,
          moduleId: -10,
          name: 'IERCF Column Repair Curing Row 15',
          contracts: [
            {
              id: 154,
              entityInstanceId: 42999,
              moduleId: -1,
              no: 'RA23007',
              name: 'IERCF Column Repair Curing Row 15',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 124,
          entityInstanceId: 43243,
          moduleId: -10,
          name: 'IERCF Curing Row 9 Roof Failure',
          contracts: [
            {
              id: 155,
              entityInstanceId: 43244,
              moduleId: -1,
              no: 'RA24006.00',
              name: 'IERCF Curing Row 9 Roof Failure',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 51,
          entityInstanceId: 18174,
          moduleId: -10,
          name: 'RP-3 Diversion Structure',
          contracts: [
            {
              id: 79,
              entityInstanceId: 18176,
              moduleId: -1,
              no: 'RW15003.05',
              name: 'RP-3 Diversion Structure',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 52,
          entityInstanceId: 18681,
          moduleId: -10,
          name: 'Wineville Basin /Jurupa Basin /Force Main Project',
          contracts: [
            {
              id: 80,
              entityInstanceId: 18682,
              moduleId: -1,
              no: 'RW15003.06',
              name: 'Wineville Basin /Jurupa Basin /Force Main Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 32,
          entityInstanceId: 1220,
          moduleId: -10,
          name: 'RP-1 SCADA Migration Project',
          contracts: [
            {
              id: 51,
              entityInstanceId: 1221,
              moduleId: -1,
              no: 'TRAINING EN13016.05',
              name: 'RP-1 SCADA Migration Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
      ],
    },
    {
      id: 5,
      entityInstanceId: 42271,
      moduleId: -23,
      name: 'Chino Basin Program',
      projects: [
        {
          id: 122,
          entityInstanceId: 42272,
          moduleId: -10,
          name: '#Test-Chino Basin Project',
          contracts: [
            {
              id: 153,
              entityInstanceId: 42275,
              moduleId: -1,
              no: '#T-CBP-001',
              name: '#T-Chino Basin Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 177,
              entityInstanceId: 49929,
              moduleId: -1,
              no: '0522',
              name: '0522May',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 182,
              entityInstanceId: 51447,
              moduleId: -1,
              no: '1212',
              name: '2905May',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 178,
              entityInstanceId: 50002,
              moduleId: -1,
              no: '13/13',
              name: 'SOV&PP Update',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 127,
          entityInstanceId: 45990,
          moduleId: -10,
          name: 'Wells',
          contracts: [
            {
              id: 158,
              entityInstanceId: 45992,
              moduleId: -1,
              no: 'PL19005.01',
              name: 'Exploratory Borings and Monitoring Wells Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      entityInstanceId: 584,
      moduleId: -23,
      name: 'Dev Program',
      projects: [
        {
          id: 9,
          entityInstanceId: 585,
          moduleId: -10,
          name: '007 Project',
          contracts: [
            {
              id: 161,
              entityInstanceId: 46167,
              moduleId: -1,
              no: '001',
              name: 'ioana 001',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 172,
              entityInstanceId: 48119,
              moduleId: -1,
              no: '003',
              name: 'Ioana 003- Calendar',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 176,
              entityInstanceId: 48764,
              moduleId: -1,
              no: '005',
              name: 'Contract',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 174,
              entityInstanceId: 48182,
              moduleId: -1,
              no: '11/11',
              name: 'Printing documents',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 173,
              entityInstanceId: 48179,
              moduleId: -1,
              no: '16/04 Laura',
              name: '16/04- Laura',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 166,
              entityInstanceId: 47077,
              moduleId: -1,
              no: '2525',
              name: 'Card fields test',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 160,
              entityInstanceId: 46137,
              moduleId: -1,
              no: '8/8',
              name: 'Progress Payments- Paid amount/percent values',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 159,
              entityInstanceId: 46120,
              moduleId: -1,
              no: '9/9',
              name: 'Date fields on Contract Cards',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 150,
              entityInstanceId: 40732,
              moduleId: -1,
              no: 'EN23121',
              name: '1299 Reservoir Paint/Coating Repairs and Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
      ],
    },
    {
      id: 6,
      entityInstanceId: 47320,
      moduleId: -23,
      name: 'HHHAS',
      projects: [
        {
          id: 129,
          entityInstanceId: 47321,
          moduleId: -10,
          name: 'THHA',
          contracts: [
            {
              id: 165,
              entityInstanceId: 47063,
              moduleId: -1,
              no: '002',
              name: 'ioana-002',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 168,
              entityInstanceId: 47322,
              moduleId: -1,
              no: '1221',
              name: 'THHA',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 169,
              entityInstanceId: 47324,
              moduleId: -1,
              no: '1221A',
              name: 'THHA2',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
      ],
    },
    {
      projects: [
        {
          id: 131,
          entityInstanceId: 47422,
          moduleId: -10,
          name: 'Project 01',
          contracts: [
            {
              id: 175,
              entityInstanceId: 48623,
              moduleId: -1,
              no: '004',
              name: '004 Contract variables',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 134,
          entityInstanceId: 48714,
          moduleId: -10,
          name: 'Test RON',
          contracts: [
            {
              id: 163,
              entityInstanceId: 46715,
              moduleId: -1,
              no: '01AdrTest',
              name: '01 - Adrian Test',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 164,
              entityInstanceId: 46721,
              moduleId: -1,
              no: '02',
              name: 'AdrTest',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 26,
          entityInstanceId: 836,
          moduleId: -10,
          name: 'NRW & Collection System Upgrades FY 19/20',
          contracts: [
            {
              id: 45,
              entityInstanceId: 837,
              moduleId: -1,
              no: 'DO NOT USE EN20014.00/EN20015.00',
              name: 'DO NOTE USE NRW & Collection System Upgrades FY 19/20',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 29,
          entityInstanceId: 1116,
          moduleId: -10,
          name: 'RP-5 IPS Guide Rail Repair',
          contracts: [
            {
              id: 48,
              entityInstanceId: 1117,
              moduleId: -1,
              no: 'EN20034.02',
              name: 'RP-5 IPS Guide Rail Repair',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
        {
          id: 30,
          entityInstanceId: 1140,
          moduleId: -10,
          name: 'TESTING/TRAINING PROJECT',
          contracts: [
            {
              id: 49,
              entityInstanceId: 1141,
              moduleId: -1,
              no: 'TESTING TRAINING',
              name: 'TEST-NRW Manhole and Collections System Upgrades',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
      ],
    },
    {
      id: 7,
      entityInstanceId: 47326,
      moduleId: -23,
      name: 'Document numbers on a program',
      projects: [
        {
          id: 130,
          entityInstanceId: 47327,
          moduleId: -10,
          name: 'Document numbers on a project',
          contracts: [
            {
              id: 170,
              entityInstanceId: 47328,
              moduleId: -1,
              no: '10/10',
              name: 'Document number on a contract',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
            {
              id: 171,
              entityInstanceId: 47516,
              moduleId: -1,
              no: '12/12',
              name: 'Contract Closure',
              isUsed: true,
              isArchived: true,
              isClosed: false
            },
          ],
        },
      ],
    },
  ],
  modules: [
    {
      moduleId: -1,
      abbr: 'CT',
      iconId: 13,
      color: '#bf7070',
    },
    {
      moduleId: -10,
      abbr: 'P',
      iconId: 90,
      color: '#3e3e3e',
    },
    {
      moduleId: -23,
      abbr: 'PRG',
      iconId: 91,
      color: '#3e3e3e',
    },
  ],
  currencies: [
    {
      currencyId: 457,
      symbol: '$',
      name: 'United States of America Dollars',
    },
  ],
};

export const DemoContextTreeWithOneContract: ContextTree = {
  programs: [
    {
      id: 5,
      entityInstanceId: 42271,
      moduleId: -23,
      name: 'Chino Basin Program',
      projects: [
        {
          id: 122,
          entityInstanceId: 42272,
          moduleId: -10,
          name: '#Test-Chino Basin Project',
          contracts: [
            {
              id: 153,
              entityInstanceId: 42275,
              moduleId: -1,
              no: '#T-CBP-001',
              name: '#T-Chino Basin Project',
              isUsed: true,
              isArchived: false,
              isClosed: false,
            },
          ],
        },
      ],
    },
  ],
  modules: [
    {
      moduleId: -1,
      abbr: 'CT',
      iconId: 13,
      color: '#bf7070',
    },
    {
      moduleId: -10,
      abbr: 'P',
      iconId: 90,
      color: '#3e3e3e',
    },
    {
      moduleId: -23,
      abbr: 'PRG',
      iconId: 91,
      color: '#3e3e3e',
    },
  ],
  currencies: [
    {
      currencyId: 457,
      symbol: '$',
      name: 'United States of America Dollars',
    },
  ],
};
