import { cipo } from 'cipo';

cipo.controller('editHolidayController',
    function ($scope, Message, MODAL, Holiday, operations, module, $q, id, $mdDialog, $timeout) {

        $scope.modalInfo = MODAL;
        $scope.holiday = new Holiday(id);
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.holiday.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        $scope.disabledBtn = false;

        $scope.actionBtnDisabled = false;

        

        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            $scope.close(true);
        };

        $scope.updateTabInfo = function () {
            $scope.holiday.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $scope.close(true);
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.holiday.tabWarning = false;
            $scope.holiday.hightlight = false;
            //$scope.disabledBtn = false;
            $scope.closingModal = false;
        };
        $scope.isModified = false;

        $scope.save = function (isAdd) {
            var p = $q.defer();
            //$scope.disabledBtn = true;
            $scope.holiday.tabWarning = false;
            $scope.holiday.hightlight = false;
            $scope.message = "Saving Holiday...";
            $scope.holiday.save(isAdd)
                .then(function (result) {
                    // Message.info('Holiday saved successfully');
                    $scope.isModified = true;
                    p.resolve();
                    //$scope.disabledBtn = false;
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    //$scope.disabledBtn = false;
                    $scope.message = "";
                });
            return p.promise;
        };

        

        $scope.close = function (isWarning) {
            if (!$scope.holiday.checkDirty() || isWarning)
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            else {
                $scope.holiday.hightlight = true;
                $scope.holiday.tabWarning = true;
                $scope.closingModal = true;
            }
        };
        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.save,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction,
        }
    });
