import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatestWith, filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { menuActions } from './menu.actions';
import { UserApiService } from '../../shared/services';
import { AppState } from '../../../app.state';
import { contextSelectors } from '../context';

@Injectable()
export class MenuEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<AppState>>(Store);
  private userApiService = inject(UserApiService);

  saveRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.setActiveItem),
      combineLatestWith(this.store.select(contextSelectors.getContractId)),
      filter(([{ activeItem }, contractId]) => !!(activeItem?.moduleId && contractId)),
      mergeMap(([{ activeItem }, contractId]) =>
        this.userApiService
          .getModuleUserRole(activeItem.moduleId, contractId)
          .pipe(map(roles => menuActions.setActiveModuleRoles({ roles }))),
      ),
    ),
  );
}
