import { Component, input } from '@angular/core';

@Component({
  selector: 'app-gridster-item',
  standalone: true,
  templateUrl: './gridster-item.component.html',
})
export class GridsterItemComponent {
  name = input('test');
  label = input('name');
  type = input('er');
}
