import { cipo } from 'cipo';

cipo.controller('signupController', ['$scope', '$state', 'msalAuthenticationService', 'authService',
        function ($scope, $state, msalService, authService) {

        //var id_token = $stateParams.id_token;

        //localStorage.setItem("msal.idtoken", id_token);
        
        authService.login();
}]);
