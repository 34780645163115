import { cipo } from 'cipo';

cipo.controller('archiveModulesController',
    function ($scope, MODAL, modulesList, $mdDialog) {
        $scope.modalInfo = MODAL;
        $scope.disabledBtn = false;
        $scope.cancelLabel = 'Close';
        $scope.modules = modulesList;

        $scope.closeModal = function () {
            $mdDialog.cancel();
        };
        
        $scope.openMenu = function ($mdMenu, ev) {
            $mdMenu.open(ev);
        };

    });