import { cipo } from 'cipo';

var tenantController = function ($rootScope, $state, $scope, $timeout, Upload, URI, authService, userService, Menu, Model, Message, UserProfile, $mdDialog, fileService) {

    $rootScope.visibleMenu = true;
    
    $rootScope.theme = localStorage.getItem("CIPO_THEME") ?? 'default';
    $rootScope.themeMode = localStorage.getItem("CIPO_THEME_MODE") ?? 'light';

    $scope.mainViewLoaded = false;
    $scope.mainViewLoadedTimer = undefined;

    // because the ui-view is inside an angular element the view is not loaded properly
    // therefore a second hidden ui-view has been added in the html
    // the hidden ui-view triggers $viewContentLoaded event
    // this will switch the ui-view visibility and fix the problem
    $rootScope.$on('$viewContentLoaded', function (event) {
        if (!$scope.mainViewLoaded && event.targetScope && event.targetScope.$parent && event.targetScope.$id != $scope.$id) {
            $scope.mainViewLoadedTimer && $timeout.cancel($scope.mainViewLoadedTimer);
            $scope.mainViewLoadedTimer = $timeout(function() {
                $scope.mainViewLoaded = true;
            }, 0);
        }
    });

    $scope.isMenuFolderAutoCollapse = userService.system.isMenuFolderAutoCollapse;
    $scope.userId = userService.system.userdata.id;
    $scope.context = userService.system.context;
    $scope.useOnlyContract = userService.system.contextUseOnlyContract;
    $scope.contextRefresh = userService.system.contextRefresh;
    $scope.isContextContractSelected = false;

    $scope.menuOpenedChanged = function (menuOpened) {
        $rootScope.visibleMenu = menuOpened;
        $timeout(function() {
            $rootScope.$apply();
        }, 0);
    }

    var get_modulesList = function () {
        $scope.loadingModulesList = true;
        $scope.modulesList = [];

        var dataURL = URI.NOTIFICATION.MODULES_LIST;
        var params = {
            contractId: userService.system.userdata.contractId || 0
        };

        Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                $scope.modulesList = r;
            })
            .catch(function (e) {
                Message.dberror(e);
            })
            .finally(function () {
                $scope.loadingModulesList = false;
            })
    }

    $scope.toggleNotification = function (item) {
        item.loading = true;
        var dataURL = URI.NOTIFICATION.TOGGLE_PER_MODULE;
        var params = {
            contractId: userService.system.userdata.contractId || 0,
            moduleId: item.key,
        };

        Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info('Notification updated sucessfully.');
                item.isUsed = !item.isUsed;
            })
            .catch(function (e) {
                Message.dberror(e);
            }).finally(function () {
                item.loading = false;
            })
    }

    $scope.toggleNotifications = function (isOn) {
        var dataURL = URI.NOTIFICATION.TOGGLE_GLOBAL;
        var params = {
            contractId: userService.system.userdata.contractId || 0,
            isOn: isOn ? 1 : 0,
        };

        Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info('Notifications updated sucessfully.');
                get_modulesList();
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    Object.defineProperty($scope, 'ctOps', {
        get: function () { return userService.getOperationsFor('CT') }
    });

    userService.mimeTypes();
    $scope.editNotifications = function () {
        $scope.editingNotifications = !$scope.editingNotifications;
        if ($scope.editNotifications) get_modulesList();
    }
    $scope.editContract = function (item, ev) {
        var item = {...userService.system.context.contract};
            item.isInitiator = false;
            $mdDialog.show({
                locals: { item: item, module: userService.system.modules['CT'], screenId: null },
                controller: 'editHContractController',
                templateUrl: '/ng/views/admin/modals/editHContract.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false,
                disableParentScroll: true,
                multiple: true
            })
            .then(function (result) {
                userService.isCurrentContractPeriodsChanged = true;
                userService.isReloadGrid = true;
            }, function () {
                // Get workflow from current module
                if(userService.system.oldWorkflowId)
                    userService.system.workflowId = userService.system.oldWorkflowId;
            });
    };

    $scope.uploadFiles = function (file, errFiles) {
        $scope.f = file;
        $scope.errFile = errFiles && errFiles[0];
        
        fileService.setMimeTypeFromExt(file);
        
        if (file) {
            $scope.isUploading = true;
            
            var urlParamString = '?mimeType=' + file.mimeTypeQueryString;
            file.upload = Upload.upload({
                url: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_USER_LOGO.toString() + urlParamString,
                method: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_USER_LOGO.method.toString(),
                data: { file: file }
            });

            file.upload.then(function (response) {
                $timeout(function () {
                    file.result = response.data;
                    userService.User();
                    $scope.isUploading = false;
                });
            }, function (response) {
                if (response.status > 0)
                    $scope.errorMsg = response.status + ': ' + response.data;
                $scope.isUploading = false;
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 *
                    evt.loaded / evt.total));
            });
        }
    }

    $scope.editSignature = function () {
        $scope.isEditSign = !$scope.isEditSign;
    }
    
    $scope.trapClik = function (event) {
        event.stopPropagation();
    }

    // ex top.js
    Object.defineProperty($scope, 'userService', {
        get: function () {
            return userService;
        }
    });

    Object.defineProperty($scope, 'currentRoles', {
        get: function () {
            return userService.system.userRoleNames.join(", ");
        }
    });

    Object.defineProperty($scope, 'tenantId', {
        get: function () {
            if (userService.system.userdata) {
                return userService.system.userdata.tenantId;
            } else return 0;

        }
    });

    var tenantsLookup = function () {
        var dict = {};
        if (userService.system.tenants && userService.system.tenants.length) {
            for (var i = 0; i < userService.system.tenants.length; i++) {
                dict[userService.system.tenants[i].id] = userService.system.tenants[i].description;
            }
        }
        return dict;
    };

    Object.defineProperty($scope, 'tenantName', {
        get: function () {
            if (userService.system.userdata) {
                return tenantsLookup()[userService.system.userdata.tenantId];
            } else return 0;

        }
    });

    Object.defineProperty($scope, 'currentContract', {
        get: function () {
            if (userService.system.userdata) {
                return userService.system.userdata.contractId;
            } else return 0;

        }
    });

    //emailCounts

    Object.defineProperty($scope, 'corOps', {
        get: function () { return userService.getOperationsFor("C") && userService.getOperationsFor("C").Read ? true : false; }
    });

    Object.defineProperty($scope, 'emailCounts', {
        get: function () {
            if (userService.system.userdata) {
                return userService.system.emailCounts;
            } else return 0;

        }
    });

    $scope.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);
        $timeout(function () {
            $scope.focusSearch = true;
            $timeout(function () {
                $scope.focusSearch = false;
            }, 300);
        }, 300);
    };

    var setData = function (data) {
        $scope.pageLoader = false;
        $scope.user = data.userdata;
        $scope.implist = data.impList;
        $scope.currentCompany = {};
    };
    
    setData(userService.system);

    $scope.toggleProfile = function () {
        $scope.isProfile = !$scope.isProfile;
        $scope.editingNotifications = false;
        $scope.isEditSign = false;
        $scope.isSignWithName = false;
        if (!$scope.isProfile) $scope.userProfile.hideForms();
        else $scope.userProfile = new UserProfile();
    }
    $scope.trapClik = function (event) {
        event.stopPropagation();
    }
    $scope.changeTenant = function () {
        userService.clearCurrentContract();
        $state.go("main");
    }

    $scope.logout = function () {
        $scope.toggleProfile();
        authService.logout();
    };

    $scope.goToEmail = function () {
        // selecting the Correspondence menu item
        Menu.setMenuItemByModuleCode('C');
        
        $state.go('tenant.emails');
    };

    $scope.goToDashboard = function () {
        // selecting the Dashboard menu item
        Menu.setMenuItemByModuleCode('D');
        
        $state.go('tenant.dashboard');
    };

    $scope.inputs = {
        context: userService.system.context,
    };

    $scope.$watch(function () {
        return userService.system.context;
    },
    function (newVal) {
        if (typeof newVal !== 'undefined') {
            $scope.context = newVal;
            $scope.inputs.context = newVal;
        }
    });

    $scope.changeContext = function (context) {
        userService.setContext(context).then(Menu.triggerRefresh).then(function() {
            if ($state.current.code == 'DYN-MOD') {
                var params = angular.copy($state.params);
                var module = userService.getModuleObjectById($state.params.id) || {};
                
                params.contractId = module.perContract ? context.contract.id : 0;
                $state.transitionTo($state.current.name, params, {
                    reload: true,
                    inherit: true,
                    notify: true
                });
            }
            else if ($state.current.code == 'DYN-PP') {
                var params = angular.copy($state.params);
                params.contractId = context.contract.id;
                params.periodId = 0;
                params.entityInstanceId = 0;
                $state.transitionTo($state.current.name, params, {
                    reload: true,
                    inherit: true,
                    notify: true
                });
            }
            else if ($state.current.code == 'DYN-SOV') {
                var params = angular.copy($state.params);
                params.contractId = context.contract.id;
                params.entityInstanceId = 0;
                $state.transitionTo($state.current.name, params, {
                    reload: true,
                    inherit: true,
                    notify: true
                });
            }
            else if ($state.current.code == 'DYN-MREP') {
                var params = angular.copy($state.params);
                var module = userService.getModuleObjectById($state.params.id) || {};

                params.contractId = module.perContract ? context.contract.id : 0;
                $state.transitionTo($state.current.name, params, {
                    reload: true,
                    inherit: true,
                    notify: true
                });
            }
        });
    };

    $scope.contextDataLoaded = function (contextTree) {
        userService.setContextTree(contextTree);
    };

    $scope.logout = function () {
        authService.logout();
    };

    $scope.passwordChange = function () {
        authService.passwordChange();
    };

    $scope.profileEdit = function () {
        authService.profileEdit();
    };

    // Menu

    $scope.menuItemClick = function (menuItem) {
        Menu.menuItemClick(menuItem);
    }

    $scope.$watch(function () {
        return userService.hasModulesLoaded;
    },
    function (newVal) {
        if (newVal) {
            // sets the new menu items
            $scope.menuItems = userService.system.menu;
        }
    });

    $scope.$watch(function () {
        return Menu.activeMenuItemId;
    },
    function (newVal) {
        if (newVal && $scope.activeMenuItemId != newVal) {
            // sets the active menu item
            $scope.activeMenuItemId = newVal;
        }
    });

    $scope.$watch(function () {
        return userService.isUserLoaded;
    },
    function (n) {
        if (n) {
            $scope.context = userService.system.context;
        }
    });

    $scope.$watch(function () {
        return userService.contextIsChanged;
    },
    function (n) {
        if (n) {
            userService.contextIsChanged = false;
            $scope.context = {...userService.system.context};
        }
    });

    $scope.$watch(function () {
        return userService.system.contextUseOnlyContract;
    },
    function (n) {
        $scope.useOnlyContract = n;
    });

    $scope.$watch(function () {
        return userService.system.contextRefresh;
    },
    function (n) {
        $scope.contextRefresh = n;
        if (n) {
            setTimeout(function() {
                userService.system.contextRefresh = false;
            }, 100);
        }
    });

    $scope.$watch(function () {
        return userService.system.context;
    },
    function (n) {
        if (n) {
            $scope.isContextContractSelected = n.contract && n.contract.id;
        }
    });
};

cipo.controller('tenantController', tenantController);