import { Permission } from '../permission';

export interface ProjectFolderModel {
  id: number;
  name: string;
  parentId: number;
  global: GlobalTypeEnum;
  isLocked: boolean;
  isReadOnly: boolean;
  entityInstanceId?: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  folderPath: string;
  moduleName: string;
  moduleAbbr: string;
  description?: string;
  permissions: Permission[];

  // this will be removed when Project files will be on angular
  // for now angularjs is formatting the date for displya purpose
  createdOnNotFormatted?: Date;
  updatedOnNotFormatted?: Date;
}

export enum GlobalTypeEnum {
  NotGlobal = 1,
  Global = 2,
  GlobalPerParent = 3,
}
