import { Sort } from '@angular/material/sort';
import { DisplayType, KeyValueFormattingModel, KeyValueRestrictionModel } from 'src/app/models/module/fields/enums';
import { AppColors } from 'src/app/shared/consts';

export type ColumnPriorityType = 0 | 1 | 2 | 3;

export interface TableColumn {
  id: number;
  name: string;
  displayName?: string;
  align?: 'start' | 'center' | 'end';
  displayFormatId?: DisplayFormats;
  width?: number;
  priority?: ColumnPriorityType;
  sortable?: boolean;
  formattings?: KeyValueFormattingModel[];
  restrictions?: KeyValueRestrictionModel[];
}

export interface TableRow {
  state_name?: StatusColumnType | string;
  backgroundColor?: AppColors;
  hoverColor?: AppColors;
  image?: {
    type: 'icon' | 'image';
    iconKey?: string;
    class?: string;
  };
  empty?: boolean;
  [key: string]: unknown;
}

export type StatusColumnType = {
  bgColor: string;
  color: string;
  label: string;
};

export interface DataListField {
  id: number;
  name?: string;
  label?: string;
  typeId?: number;
  displayTypeId?: DisplayType;
  position?: number;
  priority?: ColumnPriorityType;
  width?: number;
  formattings?: KeyValueFormattingModel[];
  restrictions?: KeyValueRestrictionModel[];
  isFilter?: boolean;
  [key: string]: unknown;
}

export enum DisplayFormats {
  Text = 0,
  Status = 1,
  Date = 2,
  Number = 3,
  YesNo = 4,
  Image = 5,
}

export interface CipoTableOptions<A = ActionType, T = TableRow> {
  selectable?: boolean;
  actions?: (row: T) => A[];
  clickableRows?: boolean;
  pagination?: TablePaginator;
  sort?: Sort;
  serverPaginationSort?: boolean;
  noDataPlaceholder?: string;
  emptyDataListText?: string;
}

export type TablePaginator = {
  pageSize?: number;
  pageSizeOptions?: number[];
  pageIndex?: number;
  length?: number;
};

export type ActionType<E = number, T = TableRow> = {
  id: E;
  displayName: string;
  leftIcon?: string;
  rightIcon?: string;
  condition?: (row: T) => boolean;
};

export type ActionEventType<I = number, T = TableRow> = {
  actionId: I;
  row: T;
};
