import { Component } from '@angular/core';
import { CipoFormModule } from '../../../shared/modules/forms.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilderComponent } from './form-builder/form-builder.component';

@Component({
  selector: 'app-form-builder-modal',
  templateUrl: './form-builder-modal.component.html',
  styleUrl: './form-builder-modal.component.scss',
  standalone: true,
  imports: [CipoFormModule, MatDialogModule, TranslateModule, FormBuilderComponent],
})
export class FormBuilderModalComponent {
  onSave() {
    // save form
  }
}
