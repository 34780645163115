import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Language, LanguageNames } from '../../models/system/language';
import { ColorPaletteComponent } from './../../demo/angular/demo-theme/color-palette/color-palette.component';
import { CipoTheme, CipoThemeMode, UserService } from './../../shared/services/user.service';
import { LocaleService } from '../../shared/services/locale.service';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent {
  isOpen = false;
  lang: Language = Language.English;
  theme$ = this.userService.theme$;
  themeMode$ = this.userService.themeMode$;

  constructor(
    private localeService: LocaleService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  get languageType(): typeof Language {
    return Language;
  }

  get languageTypes(): number[] {
    var t = Object.keys(Language)
      .filter(l => !isNaN(Number(l)))
      .map(l => Number(l));
    return t;
  }

  changeLanguage(language: number) {
    this.lang = language;
    const langName = LanguageNames.get(this.lang);
    this.localeService.setLocale(langName);
  }

  changeTheme(color: CipoTheme) {
    this.userService.changeTheme(color);
  }

  changeThemeMode(mode: CipoThemeMode) {
    this.userService.changeThemeMode(mode);
  }

  signOut() {
    window.location.href = '/';
  }

  openPalette() {
    this.dialog.open(ColorPaletteComponent, {
      panelClass: ['cipo-dialog', 'classic'],
    });
  }
}
