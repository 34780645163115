import { cipo } from 'cipo';

cipo.factory("Functions", function () {
    var Functions = function () { };
    Functions.prototype.selectFromObject = function (list, type) {
        var retval = [];
        var type = type || 'str';
        if (list && (Object.prototype.toString.call(list) == "[object Object]")) {
            for (var key in list) {
                if (list.hasOwnProperty(key)) {
                    var o;
                    if (type == 'int') {
                        o = { Key: parseInt(key), Val: list[key] }
                    } else if (type == 'str') {
                        o = { Key: key.toString(), Val: list[key] }
                    } else {
                        o = { Key: key, Val: list[key] }
                    }
                    retval.push(o);
                }
            }
        }
        return retval;
    }

    Functions.prototype.selectFromArray = function (list) {
        var retval = [];
        if (list && list.length && (Object.prototype.toString.call(list) == "[object Array]")) {
            for (var i = 0; i < list.length; i++) {
                var o = { Key: list[i], Val: list[i] };

                retval.push(o);
            }
        }
        return retval;
    }

    Functions.prototype.subtractArrFromArr = function (arrToSub, arr, idField) {

        var lookupObj = {};
        for (var i = 0; i < arrToSub.length; i++) {
            lookupObj[arrToSub[i][idField]] = true;
        }
        var filteredArr = [];
        for (var i = 0; i < arr.length; i++) {
            if (typeof lookupObj[arr[i][idField]] == "undefined") {
                filteredArr.push(arr[i]);
            }
        }
        return filteredArr;

    }

    Functions.prototype.lookupValueInArray = function (key, value, keyToFind, array) {
        var valueToFind = "";
        if (key && value && keyToFind && array.length) {
            for (var i = 0; i < array.length; i++) {
                if (array[i][key] == value) {
                    valueToFind = array[i][keyToFind];
                    break;
                }
            }
        } 
        return valueToFind;
    }

    Functions.prototype.base64ToArrayBuffer = function (base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return new Functions;
});
