import { cipo } from 'cipo';

cipo.controller('emailSettingsController',
function ($scope, MODAL, Message, $http, URI, customFolderList, operations, module, $mdDialog, Name, userService) {
    $scope.modalInfo = MODAL;
    $scope.operations = operations || {};
    $scope.module = module;
    $scope.disableBtns = false;
    $scope.contractId = userService.system.userdata.contractId;
    var orderedList = customFolderList.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
    });
    $scope.customFolderList = [];
    if (orderedList && orderedList.length) {
        for (var i = 0; i < orderedList.length; i++) {
            $scope.customFolderList.push(new Name(orderedList[i]));
            $scope.customFolderList[i].form.editMode = false;
        }
    }
    
    var setFolderNamesComparison = function (item) {
        var folderNames = [];
        for (var i = 0; i < $scope.customFolderList.length; i++) {
            if ($scope.customFolderList[i].properties.id != item.properties.id)
                folderNames.push($scope.customFolderList[i].properties.name.toLowerCase());
        }
        return folderNames;
    }
    

    $scope.toggleEdit = function (item) {
        item.form.editMode = !item.form.editMode;
        $scope.disableBtns = !$scope.disableBtns;
    };

    $scope.cancelEdit = function (item) {
        item.form.restore_Data();
        $scope.toggleEdit(item);
    };

    $scope.renameFolder = function (item) {
        
        item.form.validate();
        var uniqueLabel = setFolderNamesComparison(item).indexOf(item.properties.name.toLowerCase()) == -1 ? true : false;
        item.form.loading = true;
        
        if (item.form.isValid && uniqueLabel) {
            // 
            var dataURL = URI.EMAIL.RENAME_FOLDER;
            item[dataURL.method](`${dataURL}?contractId=${$scope.contractId}`, item.properties)
                .then(function (result) {
                    item.form.store_Data();
                    $scope.toggleEdit(item);
                    Message.info('Folder updated successfully');
                }).catch(function (e) {
                    console.error(e);
                    item.form.catch(e);

                })
                .finally(function () {
                    item.form.loading = false;
                })
        }
        else {
            item.form.loading = false;
            if (!uniqueLabel) {
                item.form.setFieldInvalid('name', 'This folder name already exists');
            }
        }

    };

    $scope.deleteFolder = function (item) {
        $scope.disableBtns = true;

        var tempFolder = {
            folderMoveId: -1,
            list: [
                item.properties.id
            ]
        };
        item[URI.EMAIL.DELETE_FOLDER.method](`${URI.EMAIL.DELETE_FOLDER}?contractId=${$scope.contractId}`, tempFolder)
            .then(function () {
                // console.log("folder to remove", $scope.customFolderList[index]);
                $scope.customFolderList.splice($scope.customFolderList.indexOf(item), 1);
                $scope.disableBtns = false;
                Message.info('Folder deleted successfully');
                // Close "manage folders" dialog if no folders left to manage.
                if ($scope.customFolderList.length == 0) {
                    $scope.cancel();
                }
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                $scope.disableBtns = false;
            });

    };

    

    $scope.createFolder = function (name) {
        if (name != "") {
            $scope.disableBtns = true;
            $http[URI.EMAIL.ADD_FOLDER.method](`${URI.EMAIL.ADD_FOLDER}?contractId=${$scope.contractId}`, { name: name })
                .then(function () {
                    $scope.getFolders()
                        .then(function (result) {
                            if (result) {
                                $scope.createFolderForm = false;
                                $scope.newFolderName = "";
                            } else {
                                Message.dberror("An error has occured. The folder was not created.");
                            }

                        }).catch(function (e) {

                        }).finally(function () {
                            $scope.disableBtns = false;
                        });
                }).catch(function (e) {
                    console.error(e);
                });
        }
    };

    $scope.cancel = function () {
        //$uibModalInstance.dismiss('cancel');
        $mdDialog.cancel();
    };

    
});
