<h2 mat-dialog-title class="calendar-more-title" data-testid="calendar-more-title">
    {{ datetime }}
</h2>
<button mat-icon-button mat-dialog-close class="calendar-more-close-button" data-testid="calendar-more-close-button">
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content
    class="calendar-more-content"
    data-testid="calendar-more-content"
    [style.max-height.px]="moreContentMaxHeight">
    <app-calendar-doc-mini
        *ngFor="let doc of data.tile.docs"
        [doc]="doc"
        [screenFields]="data.screenFields"
        [actions]="data.actions"></app-calendar-doc-mini>
</mat-dialog-content>
