import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScreenFieldModel } from '../../../../models/module/fields/main';
import { GridSearchEntityInstance } from '../../../../models/module/grid';
import { DataCard } from '../../../../models/module/main';
import { DataListViewAction } from '../../../../models/module/main/dataListViewAction';
import { Currency, CurrencyFn } from '../../../../models/system/currency';
import { DataCardsViewService } from './data-cards-view.service';

@Component({
  selector: 'app-data-cards-view',
  templateUrl: './data-cards-view.component.html',
  styleUrls: ['./data-cards-view.component.scss'],
})
export class DataCardsViewComponent implements OnInit, OnChanges {
  constructor(private cardsService: DataCardsViewService) {}

  @Input('screentype') screenType: number;
  @Input() actions: DataListViewAction[];
  @Input() rows: GridSearchEntityInstance[];
  @Input() currency: Currency | CurrencyFn;

  @Output('cardclick') cardClick: EventEmitter<number> = new EventEmitter<number>();

  cards: DataCard[] = [];
  fields: ScreenFieldModel[] = [];

  ngOnInit(): void {
    this.getScreenFields().subscribe(fields => {
      this.fields = fields;
      this.MakeCards();
    });
  }

  ngOnChanges() {
    this.MakeCards();
  }

  cardClickInternal(card: DataCard) {
    this.cardClick.emit(card.entity_instance_id);
  }

  private MakeCards() {
    if (!this.rows || !this.rows?.length || !this.fields || !this.fields?.length) {
      this.cards = [];
      return;
    }

    this.cards = [...this.rows].map(row => this.cardsService.getDataCard(this.fields, row, this.currency));
  }

  private getScreenFields(): Observable<ScreenFieldModel[]> {
    return this.cardsService.getScreenFields(this.screenType).pipe(map(f => f.data || []));
  }
}
