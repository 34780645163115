import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FileItem, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { catchError, EMPTY, map, switchMap, tap } from 'rxjs';

import { ProjectFilesAddNewModel } from '../../my-drive/common/models/dialog.models';
import { AddChunkedVersionParams, SaveFileInfoParams } from '../../my-drive/common';
import { CipoTextComponent } from '../../../../shared/components/fields/cipo-text/cipo-text.component';
import { CipoTextConfig, CipoTextControl } from '../../../../shared/components/fields/common';
import { MaterialModule } from '../../../../shared/modules/material.module';
import { NotificationService, UserService } from '../../../../shared/services';
import { ThumbnailsDict } from '../../../../shared/pipes/string-pipes/image-source.pipe';
import { FileChunkParams, FileItemModel } from '../../../../models/module/fields/main';
import { FILE_SIZES } from '../../../../shared/consts';
import { FileFactoryService, FileServiceType, IFileService } from '../../my-drive/common/services/file-factory.service';

@Component({
  selector: 'app-add-new',
  standalone: true,
  imports: [CipoTextComponent, MaterialModule, TranslateModule, FileUploadModule, FlexLayoutModule, CommonModule],
  providers: [FileFactoryService, NotificationService],
  templateUrl: './add-new.component.html',
  styleUrl: './add-new.component.scss',
})
export class AddNewComponent {
  dialogData = inject<ProjectFilesAddNewModel>(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<AddNewComponent>);
  translate = inject(TranslateService);
  userService = inject(UserService);
  fileFactoryService = inject(FileFactoryService);
  notificate = inject(NotificationService);

  private config: CipoTextConfig = {
    label: this.translate.instant('fileExplorer.name'),
    required: true,
    ...{ ...(this.dialogData.mimeType && { suffix: this.dialogData.mimeType }) },
  };
  name = new CipoTextControl(this.dialogData.name || '', this.config);
  valid = signal(false);
  multiple = signal(true);
  filesToUpload: FileItemModel[] = [];

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;

  private fileService: IFileService;

  constructor() {
    this.fileService = this.fileFactoryService.getServiceType(
      this.dialogData.entityInstanceId ? FileServiceType.ProjectFiles : FileServiceType.MyDrive,
    );

    if (this.dialogData.fileId) {
      this.multiple.set(false);
    }

    if (this.dialogData.modalType !== 'newFile') {
      this.name.valueChanges.subscribe(val => {
        this.valid.set(!!val);
      });
    }

    this.configureUploader();
  }

  private configureUploader() {
    const headers = this.userService.getUserHeaders();

    this.uploader = new FileUploader({
      url: '',
      headers: Object.keys(headers).map(key => ({ name: key, value: headers[key] })),
    });

    this.uploader.onAfterAddingFile = file => {
      const fileType = file.file.type;
      file.url = this.dialogData.fileId
        ? this.fileService.getFileNewVersionUrl({
            fileId: this.dialogData.fileId,
            mimeType: fileType,
            entityInstanceId: this.dialogData.entityInstanceId,
          })
        : this.fileService.getFileUploadUrl({
            parentId: this.dialogData.parentId,
            mimeType: fileType,
            entityInstanceId: this.dialogData.entityInstanceId,
          });
      const reader = new FileReader();
      reader.onload = event => {
        let preview: string | ArrayBuffer;
        if (fileType.startsWith('image/')) {
          preview = event.target.result;
        } else {
          preview = ThumbnailsDict[fileType as keyof typeof ThumbnailsDict] || ThumbnailsDict.default;
        }
        file.file['preview'] = preview;
      };
      reader.readAsDataURL(file._file);
      this.valid.set(true);
    };
    this.uploader.onCompleteItem = () => {
      this.valid.set(!!this.uploader.queue.length);
    };
  }

  fileOver(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  save() {
    if (this.dialogData.modalType === 'newFile') {
      this.uploader.response.pipe(map(res => JSON.parse(res))).subscribe(response => {
        this.filesToUpload.push(response);
      });
      this.uploader.onErrorItem = (item: FileItem, response: string) => {
        const message = JSON.parse(response);
        this.notificate.error(message?.messages[0]?.message ?? 'errors.unidentifiedError');
      };
      this.uploader.onCompleteAll = () => {
        const errorFile = this.uploader.queue.find(file => file.isError);
        const notUploaded = this.uploader.queue.find(file => !file.isUploaded);
        if (errorFile || notUploaded) {
          return;
        }
        this.dialogRef.close({ files: this.filesToUpload });
      };
      this.uploader.queue.forEach(file => {
        if (file.isError) {
          file.isError = false;
          file.isUploaded = false;
        }
      });
      this.uploader.queue.forEach(file => {
        if (file.file.size > FILE_SIZES.maxFileSize) {
          this.uploadFileInChunks(file);
        } else {
          file.upload();
        }
      });
    } else {
      this.dialogRef.close({ name: this.name.value });
    }
  }

  private uploadFileInChunks(fileItem: FileItem) {
    const file = fileItem._file;
    const chunks = Math.ceil(file.size / FILE_SIZES.chunkSize);
    let fileId: string;

    this.fileService
      .generateFileId()
      .pipe(
        tap(id => (fileId = id)),
        switchMap(() => this.fileService.uploadFileInChunks(file.name)),
        catchError(() => {
          fileItem.isError = true;
          return EMPTY;
        }),
      )
      .subscribe(() => {
        fileItem.progress = 100 / chunks / 2;
        const uploadChunk = (start: number, end: number) => {
          const uploadChunkParams: FileChunkParams = { start, end, chunks, file };
          const formData = this.fileService.getChunkData(uploadChunkParams);

          this.fileService.uploadChunk(fileId, formData).subscribe(() => {
            if (end < file.size) {
              fileItem.progress = (100 / chunks) * (end / FILE_SIZES.chunkSize);
              uploadChunk(end, Math.min(end + FILE_SIZES.chunkSize, file.size));
            } else {
              fileItem.progress = 100;
              const subscriber = this.dialogData.fileId
                ? this.saveFileVersion(fileId, chunks, fileItem)
                : this.saveFileInfo(fileId, chunks, fileItem);
              subscriber
                .pipe(
                  catchError(() => {
                    fileItem.isError = true;
                    return EMPTY;
                  }),
                )
                .subscribe(responseFile => {
                  fileItem.isUploaded = true;
                  fileItem.isSuccess = true;
                  this.filesToUpload.push(responseFile);
                  if (this.filesToUpload.length === this.uploader.queue.length) {
                    this.dialogRef.close({ files: this.filesToUpload });
                  }
                });
            }
          });
        };

        uploadChunk(0, Math.min(FILE_SIZES.chunkSize, file.size));
      });
  }

  private saveFileInfo(fileId: string, chunks: number, fileItem: FileItem) {
    const file = fileItem._file;
    const body = { fileName: file.name, bytes: file.size };
    const params: SaveFileInfoParams = {
      fileId,
      mimeType: file.type,
      totalBlocks: chunks,
      parentId: this.dialogData.parentId,
      roleId: 0,
      entityInstanceId: this.dialogData.entityInstanceId,
      isCreateVersion: true,
    };

    return this.fileService.saveFileInfo(params, body);
  }

  private saveFileVersion(fileId: string, chunks: number, fileItem: FileItem) {
    const file = fileItem._file;
    const body = { fileName: file.name, bytes: file.size };
    const params: AddChunkedVersionParams = {
      fileId: this.dialogData.fileId,
      mimeType: file.type,
      totalBlocks: chunks,
      roleId: 0,
      entityInstanceId: this.dialogData.entityInstanceId,
      physicalId: fileId,
    };

    return this.fileService.addChunkedVersion(params, body);
  }
}
