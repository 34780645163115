import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FontStyleType,
  HorizontalAlignType,
  LabelValueAlignmentType,
  ScreenFieldFormattingModel,
  VerticalAlignType,
} from '../../../../models/module/fields/main';
import { DataCardPartSizes, DataCardTextSizeToPercent } from '../../../../models/module/main';

@Component({
  selector: 'app-data-card-setting-part',
  templateUrl: './data-card-setting-part.component.html',
  styleUrls: ['./data-card-setting-part.component.scss'],
})
export class ScreenFieldFormattingModelComponent implements OnInit {
  @Input() desc = '';
  @Input() showDesc = true;
  @Input() showLabelInput = false;
  @Input() showColonSetting = false;
  @Input() showSizeSetting = false;
  @Input() showLabelValueAlignment = false;
  @Input() showFontColor = true;
  @Input() showFontStyle = true;
  @Input() showFontSize = true;
  @Input() showAlignHorizontal = true;
  @Input() showAlignVertical = true;

  @Input() settings: ScreenFieldFormattingModel;
  @Output() settingsChange: EventEmitter<ScreenFieldFormattingModel> = new EventEmitter<ScreenFieldFormattingModel>();

  textSizes = [...DataCardTextSizeToPercent.keys()];
  partSizes = DataCardPartSizes;

  settingsForm: FormGroup<{
    label: FormControl<string | undefined>;
    showColon: FormControl<boolean | undefined>;
    size: FormControl<number | undefined>;
    labelValueAlignment: FormControl<number>;
    fontStyle: FormControl<number[]>;
    fontSize: FormControl<number>;
    fontColor: FormControl<string>;
    alignHorizontal: FormControl<number>;
    alignVertical: FormControl<number>;
  }>;

  get labelControl(): FormControl<string> {
    return this.settingsForm.controls.label;
  }

  ngOnInit() {
    this.settingsForm = new FormGroup({
      label: new FormControl(this.showLabelInput ? this.settings.label : undefined, [Validators.required]),
      showColon: new FormControl(this.showColonSetting ? this.settings.showColon : undefined),
      size: new FormControl(this.showSizeSetting ? this.settings.size || 50 : undefined),
      labelValueAlignment: new FormControl(this.settings?.labelValueAlignment || LabelValueAlignmentType.Split),
      fontStyle: new FormControl(this.enumBitToFlagValues(this.settings?.fontStyle || 0)),
      fontSize: new FormControl(this.settings?.fontSize || 4),
      fontColor: new FormControl(this.settings?.fontColor || '#000'),
      alignHorizontal: new FormControl(this.settings?.alignHorizontal || HorizontalAlignType.Center),
      alignVertical: new FormControl(this.settings?.alignVertical || VerticalAlignType.Center),
    });
    this.settingsForm.valueChanges.subscribe(updatedSettings => {
      this.settingsChange.emit({
        id: this.settings?.id,
        label: updatedSettings.label,
        showColon: updatedSettings.showColon,
        size: updatedSettings.size,
        labelValueAlignment: updatedSettings.labelValueAlignment,
        fontStyle: this.enumFlagValuesToBit(updatedSettings.fontStyle),
        fontSize: updatedSettings.fontSize,
        fontColor: updatedSettings.fontColor,
        alignHorizontal: updatedSettings.alignHorizontal,
        alignVertical: updatedSettings.alignVertical,
      } as ScreenFieldFormattingModel);
    });
  }

  getTextSizeToPercent(textSize: number): string {
    return `${DataCardTextSizeToPercent.get(textSize)}%`;
  }

  private enumBitToFlagValues(bit: number): number[] {
    const bits = Object.keys(FontStyleType).map(Number).filter(Boolean);
    return bits.filter(b => (bit & b) === b);
  }

  private enumFlagValuesToBit(flags: number[]): number {
    return (flags || []).reduce((s, a) => s + a, 0);
  }
}
