import { Injectable } from '@angular/core';
import { FieldModel, ScreenFieldModel } from '../../../../models/module/fields/main';
import { GridViewModel } from '../../../../models/module/grid';
import { ScreenModel } from '../../../../models/module/screen';
import { HttpService } from '../../../../shared/services/http.service';

@Injectable()
export class DataCardSettingsService {
  constructor(private httpService: HttpService) {}

  getFields(screen: ScreenModel) {
    const params = this.httpService.buildHttpParams({ screenId: screen.id, addContractFields: true });
    return this.httpService.post<GridViewModel<FieldModel[]>>(
      `_api/FieldDefinition/Search?`,
      {},
      { httpOptions: { params } },
    );
  }

  getField(id: number) {
    return this.httpService.get<FieldModel>(`_api/FieldDefinition/Get?id=${id}`);
  }

  getScreenFields(screen: ScreenModel) {
    return this.httpService.post<GridViewModel<ScreenFieldModel[]>>(
      `_api/ScreenFields/Search?screenId=${screen.id}`,
      {},
    );
  }

  syncScreenFields(screen: ScreenModel, screenFields: ScreenFieldModel[]) {
    const params = this.httpService.buildHttpParams({ screenId: screen.id, screenType: screen.type });
    return this.httpService.put<void>('_api/ScreenFields/SyncFieldToScreen', screenFields, {
      httpOptions: { params },
    });
  }
}
