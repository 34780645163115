import { NotificationService } from './../../../../shared/services/notification.service';
import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styles: `
  .color-box {
    width: 140px;
    height: 50px;
  }

  input[type="color"] {
    background: none;
    border: none;
    height: 50px;
    width: 100px;
  }
  `,
})
export class ColorPaletteComponent {
  @ViewChildren('color') colorsRef: QueryList<ElementRef>;

  constructor(private notify: NotificationService) {}

  copyColorsJson() {
    const colors: { [key: string]: string } = {};
    this.colorsRef.forEach(({ nativeElement: { id, value } }) => (colors[id] = value));
    navigator.clipboard.writeText(JSON.stringify(colors)).then(() => this.notify.success('Colors copied'));
  }
}
