import { cipo } from 'cipo';

cipo.directive('validation', function () {

    return {
        restrict: 'A',
        link: function ($scope, el, attrs) {
            var obj = {},
                functions = {},
                internal = {};

            // internal functions
            internal.addErrorOnDomElement = function (element, message) {
                // clearing ghosts
                element.popover('destroy');

                // adding element popover
                element
                    .popover({ animation: false });

                popover = element.data('bs.popover');

                popover.options.template = '<div class="popover mmval_popover"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content mmval_popover-content"><p></p></div></div></div>';
                popover.options.placement = 'bottom';
                popover.options.content = message;

                $timeout(function () {
                    element.popover('show');
                }, 100);

                element
                    .addClass('mmval_inputerror');

            }

            internal.removeErrorFromDomElement = function (element) {
                element.removeClass('mmval_inputerror');
                element.popover('destroy');
            }

            // validation functions
            functions.notempty = function (field, condition) {
                return !(field.value.trim() == '');
            }

            functions.equals = function (field, condition) {
                return field.value == condition.value;
            }

            functions.lowerThan = function (field, condition) {
                return field.value < condition.value;
            }

            functions.betweenLowHigh = function (field, condition) {
                var low = field.object.attr("lowRange");
                var high = field.object.attr("highRange");

                return parseInt(field.value) > parseInt(low) && parseInt(field.value) < parseInt(high);
            }

            // setting form name
            obj.name = attrs.name;

            // getting form fields that have a name and are not disabled
            obj.templateFields = [];
            el.find(':input, select, textarea').each(function () {
                if ($(this).attr('name') && !$(this).attr('disabled')) {
                    obj.templateFields.push($(this).attr('name'));


                }
            });

            $scope.$watch(attrs.validation, function (settings) {
                settings = settings || {};

                // checking keypress validation fields
                obj.kpValidationFields = [];
                obj.activeWatchers = [];

                // going through fields to set watchers for keypress validation fields
                for (var fieldname in settings.fields) {
                    // checking to see if the field needs keypress validation
                    if (settings.fields[fieldname].method && settings.fields[fieldname].method == 'keypress' && $.inArray(fieldname, obj.templateFields) != -1) {
                        obj.kpValidationFields.push(fieldname);
                        // validations for this field
                        if (settings.fields[fieldname].validate) {
                            // getting all fields with this name
                            el.find('[name=' + fieldname + ']').each(function () {
                                // setting watchers for each field
                                $(this).on('keyup', function (e) {
                                    var field = settings.fields[fieldname];
                                    field.errors = 0;
                                    field.object = $(this);
                                    field.value = field.object.val();
                                    field.model = field.object.attr('ng-model');
                                });
                            });
                        }
                    }
                }

                // validate conditions for each field
                obj.validate = function () {
                    obj.errors = 0;

                    if (settings.fields) {
                        // refreshing fields list each time we validate to search for new fields added by angular
                        el.find(':input, select, textarea').each(function () {
                            if ($(this).attr('name') && !$(this).attr('disabled')) {
                                obj.templateFields.push($(this).attr('name'));
                            }
                        });

                        for(var fieldname in settings.fields) {
                            // check if field name exists between the form inputs
                            if ($.inArray(fieldname, obj.templateFields) != -1) {
                                // validations for this field
                                if (settings.fields[fieldname].validate) {
                                    // getting field(s) value
                                    el.find('[name=' + fieldname + ']').each(function () {
                                        var field = settings.fields[fieldname];
                                        field.errors = 0;
                                        field.object = $(this);
                                        field.value = field.object.val();
                                        field.model = field.object.attr('ng-model');

                                        field.object.bind('click', function () {
                                            internal.removeErrorFromDomElement($(this));
                                        });

                                        // calling validation functions
                                        for (var funcname in field.validate) {
                                            var condition = field.validate[funcname] || null;
                                            if (typeof functions[funcname] == 'function' && !functions[funcname](field, condition)) {
                                                var validate = field.validate[funcname];

                                                // if no errors on this field add error to dom
                                                if (!field.errors) {
                                                    var message = '';
                                                    if (validate.message) message = validate.message;

                                                    internal.addErrorOnDomElement(field.object, message);
                                                }

                                                field.errors++;
                                                obj.errors++;
                                            }
                                        }
                                    });
                                }
                            }
                        };
                    }
                }

                obj.isValid = function () {
                    obj.validate();

                    return obj.errors == 0;
                }

                if(!$scope.forms) $scope.forms = {};
                $scope.forms[obj.name] = obj;
            });
        }
    }
});
