import { Injectable } from '@angular/core';
import { delay, of } from 'rxjs';

import { ScheduledEmail } from '../../../module/instance/scheduled-emails/interfaces';
import { ScheduledEmailService } from '../../../module/instance/scheduled-emails/scheduled-emails.service';
import { ALL_JOBS, MODULES, ADDITIONAL_ROLES } from '../../data';

@Injectable()
export class DemoScheduledEmailsService extends ScheduledEmailService {
  private scheduledEmails = ALL_JOBS;
  private modules = MODULES;
  private additionalRoles = ADDITIONAL_ROLES;

  getScheduledEmails() {
    return of({ data: this.scheduledEmails, records: this.scheduledEmails.length }).pipe(delay(1000));
  }

  getModulesDropdownItems() {
    return of(this.modules).pipe(delay(1000));
  }

  getAdditionalRoles() {
    return of(this.additionalRoles).pipe(delay(1000));
  }

  updateScheduledEmail(data: ScheduledEmail) {
    const index = this.scheduledEmails.findIndex(item => item.id === data.id);
    this.scheduledEmails[index] = { ...data };
    return of(data).pipe(delay(1000));
  }

  deleteScheduledEmail(id: number) {
    this.scheduledEmails = this.scheduledEmails.filter(item => item.id !== id);
    return of().pipe(delay(1000));
  }
}
