import { FieldModel, ScreenFieldModel } from "../../models/module/fields/main";

export const DemoCardSettingsFieldState: FieldModel = {
    "id": -19,
    "name": "state_name",
    "label": "Status",
    "hasMultipleValues": false,
    "typeId": 1,
    "displayTypeId": 1,
    "restrictions": [],
    "formattings": [],
    "entityIds": [],
    "screenIds": [
        194,
        284
    ],
    "inUse": false,
    "canDelete": true,
    "canRemove": true,
    "fieldOrigin": 3,
    "isFilter": false,
    "useOnReport": false,
    "canHaveAggregate": false,
    "isContractSystemField": false,
    "currentDateTime": false,
    "maximumAllowedCharacters": 0,
    "isRequired": false
};

export const DemoCardSettingsFieldYesNo: FieldModel = {
    "id": -74,
    "name": "contract_due_date_using_workdays",
    "label": "Use work days for contract duration",
    "hasMultipleValues": false,
    "typeId": 3,
    "displayTypeId": 5,
    "restrictions": [],
    "formattings": [],
    "entityIds": [],
    "screenIds": [
        263,
        285
    ],
    "inUse": false,
    "canDelete": true,
    "canRemove": true,
    "fieldOrigin": 3,
    "isFilter": true,
    "useOnReport": false,
    "canHaveAggregate": false,
    "isContractSystemField": true,
    "currentDateTime": false,
    "maximumAllowedCharacters": 0,
    "isRequired": false
};

export const DemoCardSettingsFieldSelect: FieldModel = {
    "id": 233,
    "name": "Financial Schedule",
    "label": "Financial Schedule",
    "hasMultipleValues": false,
    "typeId": 1,
    "displayTypeId": 3,
    "restrictions": [],
    "formattings": [],
    "dataSourceId": -1005,
    "entityIds": [
        21
    ],
    "screenIds": [
        62,
        263,
        187,
        61
    ],
    "inUse": true,
    "defaultValue": "2",
    "canDelete": false,
    "canRemove": true,
    "fieldOrigin": 1,
    "isFilter": false,
    "useOnReport": false,
    "canHaveAggregate": false,
    "isContractSystemField": false,
    "currentDateTime": false,
    "maximumAllowedCharacters": 0,
    "isRequired": false
};

export const DemoCardSettingsFieldText: FieldModel = {
    "id": 25,
    "name": "Address",
    "label": "Address",
    "hasMultipleValues": false,
    "typeId": 1,
    "displayTypeId": 1,
    "restrictions": [],
    "formattings": [],
    "entityIds": [
        21
    ],
    "screenIds": [
        263,
        61
    ],
    "inUse": false,
    "canDelete": false,
    "canRemove": true,
    "fieldOrigin": 1,
    "isFilter": false,
    "useOnReport": false,
    "canHaveAggregate": false,
    "isContractSystemField": false,
    "currentDateTime": false,
    "maximumAllowedCharacters": 0,
    "isRequired": false
};

export const DemoCardSettingsScreenFieldText: ScreenFieldModel = {
    "id": 25,
    "name": "Address",
    "systemFieldName": "address",
    "label": "Address",
    "typeId": 1,
    "displayTypeId": 1,
    "formattings": [],
    "restrictions": [],
    "x": 0,
    "y": 8,
    "cols": 6,
    "rows": 1,
    "fieldOrigin": 1,
    "isFilter": false,
    "canRemove": true,
    "isContractSystemField": false,
    "currentDateTime": false,
    "labelFormatting": {
        "id": 217,
        "showColon": true,
        "fontStyle": 1,
        "fontSize": 4,
        "fontColor": "#000",
        "alignHorizontal": 1,
        "alignVertical": 2
    },
    "valueFormatting": {
        "id": 218,
        "showColon": false,
        "size": 50,
        "fontStyle": 0,
        "fontSize": 4,
        "fontColor": "#000",
        "alignHorizontal": 3,
        "alignVertical": 2,
        "labelValueAlignment": 1
    },
    "isRequired": false
};
