import { cipo } from 'cipo';

cipo.factory("UserRoleContract", function ($q, Model, Form, Functions, URI) {

    var UserRoleContract = Model.extend(function (main, type, obj, pivotList, sourceDict, repeatValues) {
        // can take a userId:value, a roleId:value, or a contractId:value as main
        // type is a hardcoded param: 1 - user, 2 - role, 3 - contract

        // repeatValues shows if the values can be used more than once 
        var self = this;
        
        if (type) {
            
            switch (type) {
                case 1:
                    // if main is user
                    self.syncURL = URI.USER.SYNC_ROLE_CONTRACTS;
                    self.pivotLabel = 'Select Role';
                    self.secondaryLabel = 'Select Contract(s)';
                    break;
                case 2:
                    // if main is role
                    self.syncURL = URI.ROLE.SYNC_CONTRACT_USERS;
                    self.pivotLabel = 'Select Contract';
                    self.secondaryLabel = 'Select User(s)';
                    break;
                default:
                    // if main is contract
                    self.syncURL = URI.CONTRACT.SYNC_ROLE_USERS;
                    self.pivotLabel = 'Select Role';
                    self.secondaryLabel = 'Select User(s)';
                    break;

            }
            
        }
        self.repeatValues = repeatValues || false;
        self.key = null;
        self.properties = {
            key: null,
            children: []
        }
        self.mainData = main;
        self.childrenIds = [];
        self.pivotList = [];
        if (pivotList && pivotList.length) {
            for (var i = 0; i < pivotList.length; i++) {
                //console.log('dict item params', sourceDict[i].isUsed)
                if (!pivotList[i].isUsed) {
                    self.pivotList.push(pivotList[i]);
                }
            }
        }

        self.dict = [];

        self.form = new Form(self);

        if (obj) {
            
            self.key = obj.key;
            //console.log('obj present')
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            if (self.properties.children && self.properties.children.length) {
                for (var i = 0; i < self.properties.children.length; i++) {
                    self.childrenIds.push(self.properties.children[i].key);
                }
            }
            if (!self.repeatValues) {
                if (sourceDict && sourceDict.length) {
                    for (var i = 0; i < sourceDict.length; i++) {
                        // console.log('dict item params', sourceDict[i].isUsed)
                        if (!sourceDict[i].isUsed || self.childrenIds.indexOf(sourceDict[i].key) != -1) {
                            self.dict.push(sourceDict[i]);
                        }
                    }
                }
            }
            else {
                self.dict = sourceDict;
            }
            
            // console.log('role', self);

            self.form.set_Description({
                childrenIds: { label: self.secondaryLabel, type: 'select', multiple: true, options: self.dict, validation: { required: true } }

            });

            self.form.setTemplate('grid', [
                { childrenIds: 100 }
            ]);
        }
        else {
            if (!self.repeatValues) {
                if (sourceDict && sourceDict.length) {
                    for (var i = 0; i < sourceDict.length; i++) {
                        //console.log('dict item params', sourceDict[i].isUsed)
                        if (!sourceDict[i].isUsed) {
                            self.dict.push(sourceDict[i]);
                        }
                    }
                }
            }
            else {
                self.dict = sourceDict;
            }
            
            self.form.set_Description({
                key: { label: self.pivotLabel, type: 'select', options: self.pivotList, validation: { required: true } },
                childrenIds: { label: self.secondaryLabel, type: 'select', multiple: true, options: self.dict, validation: { required: true } }

            });

            self.form.setTemplate('grid', [
                { key: 50, childrenIds: 50 }
            ]);
        }
        
        self.createBackup();
        //this.form.initializing = true;
        // self.form.store_Data();
    });

    UserRoleContract.prototype.createBackup = function () {
        var self = this;
        self.backup = {
            key: self.key,
            childrenIds: self.childrenIds
        }
    }

    UserRoleContract.prototype.restoreBackup = function () {
        var self = this;
        self.key = self.backup.key;
        self.childrenIds = self.backup.childrenIds;
        self.backup = {
            key: self.key,
            childrenIds: self.childrenIds
        }
    }

    UserRoleContract.prototype.remove = function () {
        var self = this;
        var p = $q.defer();
        self.properties.children = [];
        self.properties.key = self.key;
        self[self.syncURL.method](self.syncURL, { url: self.mainData, body: [self.properties], urltype: 'obj' })
            .then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                console.error(e);
                p.reject(e);
            }).finally(function () {

            });
        return p.promise;
    }

    UserRoleContract.prototype.sync = function () {
        var self = this;
        var p = $q.defer();
        self.form.validate();
        if (self.form.isValid) {
            self.properties.children = [];
            self.properties.key = self.key;
            if (self.childrenIds.length) {
                for (var i = 0; i < self.childrenIds.length; i++) {
                    self.properties.children.push({ key: self.childrenIds[i] })
                }
                //self.properties.children[0].isUsed = true;
            }

            self[self.syncURL.method](self.syncURL, { url: self.mainData, body: [self.properties], urltype: 'obj' })
                .then(function (result) {
                    self.createBackup();
                    p.resolve(result);
                }).catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject(e);
                }).finally(function () {
                    self.form.loading = false;

                });
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        

        return p.promise;
    }

    return UserRoleContract;

});
