import { cipo } from "cipo";

cipo.controller("editFolderDialogController", function ($scope, $mdDialog, item, entityInstanceId, rootModuleId, userService) {
  $scope.folder = item?.properties ?? {};
  $scope.title = $scope.folder?.name || 'Add Folder';
  $scope.height = !$scope.folder?.id ? 402 : $scope.folder?.moduleName ? 675 : 639;
  $scope.entityInstanceId = entityInstanceId ?? folder.entityInstanceId;
  $scope.rootModuleId = rootModuleId;

  // get live operations
  Object.defineProperty($scope, 'operations', {
    get: function () { return userService.getOperationsFor('FCM'); }
  });

  $scope.canChangePermissions = $scope.operations['Permissions'] != undefined && $scope.folder.parentId !== -10;
  $scope.canDoGlobal = $scope.folder?.id ? $scope.operations['Update Global'] != undefined : $scope.operations['Create Global'] != undefined;

  $scope.onSave = function (close) {
    if (!close) {
      $mdDialog.hide(true);
    } else {
      $scope.title = close;
      $scope.height = 639;

      if ($scope.$root && $scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
        $scope.$apply();
      }
    }
  }

  $scope.onClose = function (newFolder) {
    $mdDialog.cancel(newFolder);
  };
});