import { KeyValueFormulaFieldModel } from "src/app/models/common";
import { DisplayType, FieldOrigin, FieldTypeEnum, KeyValueFormattingModel, KeyValueRestrictionModel } from "../enums";

export interface FieldModel {
    id: number;
    name: string;
    label: string;
    hasMultipleValues: boolean;
    typeId?: FieldTypeEnum;
    displayTypeId?: DisplayType;
    dataSourceId?: number;
    expression?: string;
    expressionFields?: KeyValueFormulaFieldModel<string>[];
    pattern?: string;
    formattings: KeyValueFormattingModel[];
    restrictions: KeyValueRestrictionModel[];
    entityIds: number[];
    screenIds: number[];
    inUse: boolean;
    parentChildLinkId?: number;
    defaultValue?: any;
    multipleDefaultValue?: any[];
    canDelete: boolean;
    canRemove: boolean;
    fieldOrigin: FieldOrigin;
    complexFormulaId?: number;
    isFilter: boolean;
    useOnReport: boolean;
    aggregateId?: number;
    canHaveAggregate: boolean;
    isContractSystemField: boolean;
    currentDateTime: boolean;
    weatherDateId?: number;
    maximumAllowedCharacters?: number;
    isRequired?: boolean;
}