import { cipo } from 'cipo';

cipo.directive("modalactions", function (MODAL) {
    return {
        restrict: "E",
        scope: {
            item: '=',
            operations: '=',
            selectedindex: '=',
            options: '=',
            showonalltabs: "="
        },
        templateUrl: "/ng/views/directives/system/modalactions.html",
        controller: function ($scope) {
            $scope.modalInfo = MODAL;
            if ($scope.selectedindex) {
                Object.defineProperty($scope, 'index', {
                    get: function () { return $scope.selectedindex; }
                });
            } else $scope.selectedindex = 0;
            
            
            $scope.close = function () {
                $mdDialog.hide();
            }

            $scope.save = function (isAdd) {
                if (typeof $scope.options.save == 'function') {
                    $scope.options.save(isAdd);
                }
                else {
                    $scope.item.save(isAdd);
                }
            }

        }

    }
});
