import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("ContractAdjustment", function ($q, Model, URI, Message, Form, userService) {
    var ContractAdjustment = Model.extend(function (parentId, obj, dict) {

        var self = this;

        self.editable = false;
        self.deleteConfirm = false;
        self.parentId = parentId;

        self.name = "";
        self.typesDict = dict;
        self.properties = {
            id: null,
            amount: null,
            percentage: null,
            typeOfContractAdjustment: 1, // for the amount percentage dropdown
            typeId: null,
            typeName: "",
            name: "",
            comment: "",
            description: "",
            isRecurring: false,
            financialScheduleId: null,
            isDeduction: false,
            isAccrual: false,
            accrualFrequencyID: null,
            accrualFrequencyName: "",
            isResolvedManually: false,
            resolvedDate: null,
            categoryId: null,
            categoryName: null,
            isApplied: false,
            end: null,
            start: null
        }

        if (obj) {
            self.name = obj.name;
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            self.properties.start = self.process_incomingDate(obj.start);
            self.properties.end = self.process_incomingDate(obj.end);
            self.properties.typeOfContractAdjustment = self.properties.amount ? 1 : 2;
            self.properties.amount = self.properties.amount ? self.properties.amount : self.properties.percentage;
        }

        if (!self.properties.id) {
            if (self.typesDict.length == 1) {
                self.properties.typeId = self.typesDict[0].key;
            }
            self.createForm();
        }

    });

    ContractAdjustment.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        self.form.set_Description({
            //name: { label: 'Retention Name', type: 'text', validation: { required: true, maxChars: 100 } },
            comment: {
                label: 'Comment', type: 'editor'
            },
            amount: { label: 'Adjustment Value', type: 'number', validation: { required: true, isNumber: true } },
            typeOfContractAdjustment: { label: 'Value Type', type: 'select', options: [{ key: 1, value: "Amount" }, { key: 2, value: "Percentage" },], validation: { required: true } },
            typeId: { label: 'Adjustment Type', type: 'select', options: self.typesDict, validation: { required: true } },
            end: { label: 'Adjustment End', type: 'datepicker', options: { format: userService.formats.date }, validation: { required: true } },
            start: { label: 'Adjustment Start', type: 'datepicker', options: { format: userService.formats.date }, validation: { required: true } }
        });

        self.form.setTemplate('grid', [
            { amount: 33, typeOfContractAdjustment: 33, typeId: 33 },
            { start: 50, end: 50 },
            { comment: 100 },
        ]);
        self.form.initializing = false;
        self.form.store_Data();
    }

    ContractAdjustment.prototype.process_incomingDate = function (date) {
        if (date) {
            var momentDate = moment(date).utc();
            momentDate.set({ h: 12, m: 0 });
            //console.error('momentDate', date,  momentDate)
            return momentDate;
        }
        else return null;
    }

    ContractAdjustment.prototype.save = function () {
        var self = this;
        var p = $q.defer();

        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            var temp = angular.copy(self.properties);
            if (temp.typeOfContractAdjustment == 2) {
                temp.percentage = temp.amount;
                temp.amount = 0;
            } else {
                temp.percentage = null;
            }
            var urlData = temp.id ? URI.CONTRACT_ONE_TIME_ADJUSTMENTS.UPDATE : URI.CONTRACT_ONE_TIME_ADJUSTMENTS.CREATE;
            temp.start = temp.start ? temp.start.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;
            temp.end = temp.end ? temp.end.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;
            self[urlData.method](urlData, { url: { contractId: self.parentId }, urltype: 'obj', body: temp })
                .then(function () {
                    p.resolve();
                    Message.info('Adjustment saved successfully');
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    (self.form || {}).loading = false;
                });

        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    ContractAdjustment.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting adjustment...";
        self.deleting = true;
        var urlData = URI.CONTRACT_ONE_TIME_ADJUSTMENTS.DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (result) {
                Message.info('Adjustment deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete adjustment. Try again?";
                p.reject(e);
                Message.dberror(e);
            })
        return p.promise;
    }

    return ContractAdjustment;
});
