<div fxLayout="column" class="full-height">
    <mat-form-field class="cipo-input hide-subscript search-field" appearance="fill" fxFlex="none">
        <mat-label>Search...</mat-label>
        <input matInput #inputsearch [formControl]="searchControl" data-test="cy_sidebar-input" />
        <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        fxFlex="grow"
        class="cipo-bg--primary-50 overflow-y--auto">
        <mat-tree-node
            *matTreeNodeDef="let menu"
            class="menu-item cipo-bg--white"
            [class.cipo-bg--primary-100]="menu.active"
            (click)="menuItemClick(menu)"
            data-test="cy_sidebar-tree-node"
            matTreeNodeToggle>
            <itv-module-icon [iconid]="menu.iconId" [color]="menu.color"></itv-module-icon>
            <span>{{ menu.name }}</span>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let menu; when: hasChild" data-test="cy_sidebar-nested-tree-node">
            <div class="menu-item" matTreeNodeToggle (click)="menuFolderClick(menu)">
                <itv-module-icon
                    [iconid]="menu.iconId ? menu.iconId : treeControl.isExpanded(menu) ? 7 : 6"></itv-module-icon>
                <span>{{ menu.name }}</span>
                <mat-icon [style.color]="menu.color">
                    {{ treeControl.isExpanded(menu) ? "expand_less" : "expand_more" }}
                </mat-icon>
            </div>
            <div [class.mat-tree-invisible]="!treeControl.isExpanded(menu)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
    <app-footer fxFlex="none" />
</div>
