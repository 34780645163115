import { KeyValueModel } from 'src/app/models/common';

export interface Assignments {
  roleIds: number[];
  roles: KeyValueModel<number, string>[];
  userId: number;
  userName: string;
  userEmail: string;
  userOrgName: string;
  allocationHoursPerMonth?: number;
  userRate?: number;
  deleting: boolean;
  edited: boolean;
}

export interface SyncUsersAndRolesModel {
  id: number;
  propagateOnHierarchy: boolean;
  users: Assignments[];
}

export interface SyncRolePositionModel {
  contractId: number;
  userId: number;
  roles: KeyValueModel<number, string>[];
}

export enum ModuleIds {
  Contract = -1,
  Project = -10,
  Program = -23,
}
