import { Injectable } from "@angular/core";
import { ProjectFolderModel } from "src/app/models/files/project-folder.model";
import { HttpService } from "src/app/shared/services";

@Injectable()
export class FolderDialogService {

    constructor(private http: HttpService) { }

    save(folder: ProjectFolderModel, entityInstanceId: number) {
        const params = this.http.buildHttpParams({ entityInstanceId });
        return this.http.put<number>(`_api/ProjectFolderFile/EditFolder`, folder, { useLoader: false, httpOptions: { params } });
    }

    create(folder: ProjectFolderModel, entityInstanceId: number) {
        const params = this.http.buildHttpParams({ entityInstanceId });
        return this.http.post<number>(`_api/ProjectFolderFile/CreateFolder`, folder, { useLoader: false, httpOptions: { params } });
    }

    get(folderId: number, entityInstanceId: number, isFolders: boolean, onlyEssentialInformation: boolean) {
        const params = this.http.buildHttpParams({ folderId, entityInstanceId, isFolders, onlyEssentialInformation });
        return this.http.get<ProjectFolderModel>(`_api/ProjectFolderFile/GetFolder`, { useLoader: false, httpOptions: { params } });
    }
}