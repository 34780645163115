import { Injectable } from '@angular/core';
import { AccessLogModel } from '../../../models/module/accessLog';
import { GridViewModel, SearchViewModel } from '../../../models/module/grid';
import { HttpService } from '../../../shared/services/http.service';

@Injectable()
export class AccessLogTableService {
  constructor(private httpService: HttpService) {}

  getAccessLog(searchView: SearchViewModel, entityInstanceId: number, contractId: number) {
    const params = this.httpService.buildHttpParams({ entityInstanceId, contractId });
    return this.httpService.post<GridViewModel<AccessLogModel[]>>(
      `_api/ModuleInstance/GetAccessLogEntityInstance`,
      searchView,
      { httpOptions: { params } },
    );
  }
}
