import { Component, Input } from '@angular/core';
import { KeyValueFormattingModel, KeyValueRestrictionModel } from '../../../models/module/fields/enums';

@Component({
  selector: 'itv-number-progress-bar',
  templateUrl: './number-progress-bar.component.html',
  styleUrls: ['./number-progress-bar.component.scss'],
})
export class NumberProgressBarComponent {
  @Input() value: number | string;
  @Input() formattings: KeyValueFormattingModel[];
  @Input() restrictions: KeyValueRestrictionModel[];
  @Input() isDuration: boolean;

  get valuePercent() {
    if (!this.value) {
      return '0%';
    }
    if (typeof this.value == 'number') {
      return `${this.value}%`;
    }
    if (this.value.endsWith('%')) {
      return this.value;
    }
    return `${this.value}%`;
  }
}
