import { getLocaleFirstDayOfWeek } from "@angular/common";
import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import moment from "moment";
import { LocaleService } from "./locale.service";

@Injectable({ providedIn: 'root' })
export class LocaleDateAdapter extends NativeDateAdapter {
    constructor(protected localeService: LocaleService) {
        super(localeService.getLocale());
        this.setLocale();
        localeService.localeChanged.subscribe((locale: string) => this.setLocale(locale));
    }

    override getFirstDayOfWeek(): number {
        return getLocaleFirstDayOfWeek(this.locale);
    }

    setLocale(newLocale?: string) {
        if (newLocale) {
            this.locale = newLocale;
        }
        moment.locale(this.locale);
    }
}