import { cipo } from 'cipo';

cipo.controller('paymentsController', function ($scope, $state, userService) {
    $scope.moduleName = userService.system.mNames[$state.current.code];

    Object.defineProperty($scope, 'operations', {
        get: function () { return userService.getOperationsFor("PY"); }
    });

    var t1 = [
        {
            "A": 1.1,
            "B": "Performance Bonds and Insurance",
            "C": 1,
            "D": "53,666.00",
            "E": "LS"
        },
        {
            "A": 1.2,
            "B": "Mobilization 50% at 5% Complete",
            "C": 1,
            "D": "30,000.00",
            "E": "LS"
        },
        {
            "A": 1.3,
            "B": "Mobilization 75% at 10% Complete",
            "C": 1,
            "D": "15,000.00",
            "E": "LS"
        },
        {
            "A": 1.4,
            "B": "Mobilization 95% at 20% Complete",
            "C": 1,
            "D": "13,000.00",
            "E": "LS"
        },
        {
            "A": 1.5,
            "B": "Mobilization 100% at 50% Complete",
            "C": 1,
            "D": "10,497.00",
            "E": "LS"
        },
        {
            "A": 1.6,
            "B": "Set Up Contractors Facility",
            "C": 1,
            "D": "21,500.00",
            "E": "LS"
        },
        {
            "A": 1.7,
            "B": "Set Up Inspectors Facility",
            "C": 1,
            "D": "18,500.00",
            "E": "LS"
        },
        {
            "A": 1.8,
            "B": "Temporary Facilities",
            "C": 12,
            "D": "13,720.00",
            "E": "MO"
        },
        {
            "A": 1.9,
            "B": "Demobilization",
            "C": 1,
            "D": "28,200.00",
            "E": "LS"
        },
        {
            "A": 1.1,
            "B": "Weekly Progress Meetings",
            "C": 48,
            "D": 208.33,
            "E": "WK"
        },
        {
            "A": 1.11,
            "B": "Submit WesTech Trident Filters",
            "C": 1,
            "D": "15,000.00",
            "E": "LS"
        },
        {
            "A": 1.12,
            "B": "Submit WFI Backwash Pumps",
            "C": 1,
            "D": "10,000.00",
            "E": "LS"
        },
        {
            "A": 1.13,
            "B": "Submit Quincy Air Compressors",
            "C": 1,
            "D": "5,000.00",
            "E": "LS"
        },
        {
            "A": 1.14,
            "B": "Submit Western Fabricators FRP Covers",
            "C": 1,
            "D": "5,000.00",
            "E": "LS"
        },
        {
            "A": 1.15,
            "B": "Submit DeZurik Valves",
            "C": 1,
            "D": "5,000.00",
            "E": "LS"
        },
        {
            "A": 1.16,
            "B": "Submit MCC Buckets",
            "C": 1,
            "D": "5,000.00",
            "E": "LS"
        },
        {
            "A": 1.17,
            "B": "Submit SST Piping",
            "C": 1,
            "D": "2,500.00",
            "E": "LS"
        },
        {
            "A": 1.18,
            "B": "Submit Injection Quills",
            "C": 1,
            "D": "2,500.00",
            "E": "LS"
        },
        {
            "A": 1.19,
            "B": "Submit Pipe Supports",
            "C": 1,
            "D": "5,000.00",
            "E": "LS"
        },
        {
            "A": 1.2,
            "B": "Submit Ductile Iron Pipe",
            "C": 1,
            "D": "8,172.00",
            "E": "LS"
        },
        {
            "A": 1.21,
            "B": "Submit Baseline Schedule",
            "C": 1,
            "D": "7,820.00",
            "E": "LS"
        },
        {
            "A": 1.22,
            "B": "Submit Schedule Updates",
            "C": 12,
            "D": "1,500.00",
            "E": "MO"
        },
        {
            "A": 1.23,
            "B": "Submit Certified Payroll",
            "C": 12,
            "D": 300,
            "E": "MO"
        },
        {
            "A": 1.24,
            "B": "Submit High Performance Coatings",
            "C": 1,
            "D": "5,000.00",
            "E": "LS"
        }
    ]

    t2 = [
        {
            "A": "2.1 Secondary Clarifier Modifications Install Hose Racks",
            "B": 15,
            "C": "EA",
            "D": "1,015.00",
            "E": "15,225.00"
        },
        {
            "A": "2.2 Secondary Clarifier Modifications Clean and Wash Launders",
            "B": 3,
            "C": "EA",
            "D": "10,124.00",
            "E": "30,372.00"
        },
        {
            "A": "2.3 Secondary Clarifier Modifications Install FRP Launder Covers",
            "B": "96",
            "C": "LF",
            "D": 62,
            "E": "80,352.00"
        },
        {
            "A": "2.4 Secondary Clarifier Modifications Install Signs",
            "B": 15,
            "C": "EA",
            "D": 847,
            "E": "12,705.00"
        },
        {
            "A": "2.5 Secondary Clarifier Modifications Install Copper Piping to Hose Stations",
            "B": 900,
            "C": "LF",
            "D": 60,
            "E": "54,000.00"
        },
        {
            "A": "2.6 Secondary Clarifier Modifications Furnish Pipe Valves and Supports",
            "B": 100,
            "C": "LS",
            "D": 90,
            "E": "9,000.00"
        }
    ]    

    t3 = [
        {
            "A": "3.1 Backwash Pump Area Unload and Store Pumps",
            "B": 1,
            "C": "LS",
            "D": "4,200.00",
            "E": "4,200.00"
        },
        {
            "A": "3.2 Backwash Pump Area Set and Level Pumps",
            "B": 2,
            "C": "EA",
            "D": "10,575.00",
            "E": "21,150.00"
        },
        {
            "A": "3.3 Backwash Pump Area Start-Up and Test Pumps",
            "B": 2,
            "C": "EA",
            "D": "7,850.00",
            "E": "15,700.00"
        },
        {
            "A": "3.4 Backwash Pump Area Install 16\" Check Valves at BW Header",
            "B": 2,
            "C": "EA",
            "D": "8,056.00",
            "E": "16,112.00"
        },
        {
            "A": "3.5 Backwash Pump Area Install 20\" Butterfly Valve at BW Header",
            "B": 1,
            "C": "EA",
            "D": "9,767.00",
            "E": "9,767.00"
        },
        {
            "A": "3.6 Backwash Pump Area Replace 6\" Valves and Tie-In LWBW Piping at BW Pumps",
            "B": 21,
            "C": "LF",
            "D": 743,
            "E": "15,603.00"
        },
        {
            "A": "3.7 Backwash Pump Area Install 2\" Air Release Valve",
            "B": 20,
            "C": "LF",
            "D": 254,
            "E": "5,080.00"
        },
        {
            "A": "3.8 Backwash Pump Area Install Injection Quills",
            "B": 2,
            "C": "EA",
            "D": "2,250.00",
            "E": "4,500.00"
        },
        {
            "A": "3.9 Backwash Pump Area Demo Electrical Work",
            "B": 1,
            "C": "LS",
            "D": "7,000.00",
            "E": "7,000.00"
        },
        {
            "A": "3.10 Backwash Pump Area Install New Electrical Work",
            "B": 1,
            "C": "LS",
            "D": "18,600.00",
            "E": "18,600.00"
        }
    ]

    t4 = [
        {
            "A": "4.1 Air Compressor Area Demo Air Compressor 1",
            "B": 1,
            "C": "LS",
            "D": "4,815.00",
            "E": "4,815.00"
        },
        {
            "A": "4.2 Air Compressor Area Demo Air Compressor 2",
            "B": 1,
            "C": "LS",
            "D": "4,513.00",
            "E": "4,513.00"
        },
        {
            "A": "4.3 Air Compressor Area Demo Air Piping Influent Side",
            "B": 1,
            "C": "LS",
            "D": "4,688.00",
            "E": "4,688.00"
        },
        {
            "A": "4.4 Air Compressor Area Demo Air Piping Effluent Side",
            "B": 1,
            "C": "LS",
            "D": "4,688.00",
            "E": "4,688.00"
        },
        {
            "A": "4.5 Air Compressor Area Demo Air Piping Inside Building",
            "B": 1,
            "C": "LS",
            "D": "4,055.00",
            "E": "4,055.00"
        },
        {
            "A": "4.6 Air Compressor Area Install Air Compressors",
            "B": 2,
            "C": "EA",
            "D": "12,234.00",
            "E": "24,468.00"
        },
        {
            "A": "4.7 Air Compressor Area Install IA AC to Wet Reciever",
            "B": 20,
            "C": "LF",
            "D": 240,
            "E": "4,800.00"
        },
        {
            "A": "4.8 Air Compressor Area Install IA to Dry Reciever",
            "B": 80,
            "C": "LF",
            "D": 147,
            "E": "11,760.00"
        },
        {
            "A": "4.9 Air Compressor Area Demo Electrical Work",
            "B": 1,
            "C": "LS",
            "D": "4,300.00",
            "E": "4,300.00"
        },
        {
            "A": "4.10 Air Compressor Area Install New Electrical Work",
            "B": 1,
            "C": "LS",
            "D": "13,100.00",
            "E": "13,100.00"
        }
    ]

    t5 = [
        {
            "A": "5.1 IA From Recievers to Filters",
            "B": 320,
            "C": "LF",
            "D": 81,
            "E": "25,920.00"
        },
        {
            "A": "5.2 IA to Hydro Connection",
            "B": 40,
            "C": "LF",
            "D": 31,
            "E": "1,240.00"
        },
        {
            "A": "5.3 IA to Aeration Basin Tie-In",
            "B": 70,
            "C": "LF",
            "D": 79,
            "E": "5,530.00"
        }
    ]

    t6 = [
        {
            "A": "6.1 Filter Unit Demo & Install Units 1-2 Demo Troughs and Remove Beams/Gratin",
            "B": 2,
            "C": "EA",
            "D": "4,965.00",
            "E": "9,930.00"
        },
        {
            "A": "6.2 Filter Unit Demo & Install Units 1-2 Remove All Media",
            "B": 2,
            "C": "EA",
            "D": "8,115.00",
            "E": "16,230.00"
        },
        {
            "A": "6.3 Filter Unit Demo & Install Units 1-2 Remove Clarifier Components",
            "B": 2,
            "C": "EA",
            "D": "3,140.00",
            "E": "6,280.00"
        },
        {
            "A": "6.4 Filter Unit Demo & Install Units 1-2 Remove Trident Underdrains",
            "B": 62,
            "C": "EA",
            "D": 198,
            "E": "12,276.00"
        },
        {
            "A": "6.5 Filter Unit Demo & Install Units 1-2 Unload and Store Material",
            "B": 2,
            "C": "EA",
            "D": "2,090.00",
            "E": "4,180.00"
        },
        {
            "A": "6.6 Filter Unit Demo & Install Units 1-2 Install Lower Clarifier Components",
            "B": 2,
            "C": "EA",
            "D": "3,967.00",
            "E": "7,934.00"
        },
        {
            "A": "6.7 Filter Unit Demo & Install Units 1-2 Install Trident Seals and Underdrains",
            "B": 62,
            "C": "EA",
            "D": 233.5,
            "E": "14,477.00"
        },
        {
            "A": "6.8 Filter Unit Demo & Install Units 1-2 Install Media in Clarifiers and Filters",
            "B": 2,
            "C": "EA",
            "D": "5,672.00",
            "E": "11,344.00"
        },
        {
            "A": "6.9 Filter Unit Demo & Install Units 1-2 Install Beams and Grating",
            "B": 2,
            "C": "EA",
            "D": "5,187.00",
            "E": "10,374.00"
        },
        {
            "A": "6.10 Filter Unit Demo & Install Units 1-2 Install Wash Troughs with Gate",
            "B": 2,
            "C": "EA",
            "D": "5,634.00",
            "E": "11,268.00"
        },
        {
            "A": "6.11 Filter Unit Demo & Install Units 1-2 Start-Up and Test",
            "B": 2,
            "C": "EA",
            "D": "1,840.00",
            "E": "3,680.00"
        },
        {
            "A": "6.12 Filter Unit Demo & Install Units 1-2 Load and Haul Off Media",
            "B": 51,
            "C": "CY",
            "D": 180,
            "E": "9,180.00"
        },
        {
            "A": "6.13 Filter Unit Demo & Install Units 1-2 Replace 20\" Butterfly Valves BW Header",
            "B": 2,
            "C": "EA",
            "D": "7,957.00",
            "E": "15,914.00"
        },
        {
            "A": "6.14 Filter Unit Demo & Install Units 1-2 Replace 12\" Butterfly Valves EFF & Waste",
            "B": 4,
            "C": "EA",
            "D": "3,341.00",
            "E": "13,364.00"
        },
        {
            "A": "6.15 Filter Unit Demo & Install Units 1-2 Replace 8\" Air Scour Valves at Filter Side",
            "B": 4,
            "C": "EA",
            "D": "2,001.00",
            "E": "8,004.00"
        },
        {
            "A": "6.16 Filter Unit Demo & Install Units 1-2 Replace 3/8\" Headloss Switch",
            "B": 1,
            "C": "EA",
            "D": "3,873.00",
            "E": "3,873.00"
        },
        {
            "A": "6.17 Filter Unit Demo & Install Units 1-2 Replace 12\" Plug INF Clarifier Side",
            "B": 2,
            "C": "EA",
            "D": "4,182.00",
            "E": "8,364.00"
        },
        {
            "A": "6.18 Filter Unit Demo & Install Units 1-2 Replace 6\" AS Valves Clarifier Side",
            "B": 4,
            "C": "EA",
            "D": "2,079.00",
            "E": "8,316.00"
        },
        {
            "A": "6.19 Filter Unit Demo & Install Units 1-2 Replace 3/4\" Flush Connection at Transmitter",
            "B": 2,
            "C": "EA",
            "D": "2,880.00",
            "E": "5,760.00"
        },
        {
            "A": "6.20 Filter Unit Demo & Install Units 1-2 Replace IA to Waste Gate",
            "B": 60,
            "C": "LF",
            "D": 62,
            "E": "3,720.00"
        },
        {
            "A": "6.21 Filter Unit Demo & Install Units 1-2 Install Electrical and Instrumentation",
            "B": 2,
            "C": "EA",
            "D": "4,077.00",
            "E": "8,154.00"
        },
        {
            "A": "6.22 Filter Unit Demo & Install Units 1-2 Testing",
            "B": 2,
            "C": "EA",
            "D": "3,754.00",
            "E": "7,508.00"
        },
        {
            "A": "6.23 Filter Unit Demo & Install Units 1-2 Sandblast and Coat Metal Supports",
            "B": 2,
            "C": "EA",
            "D": "7,575.00",
            "E": "15,150.00"
        }
    ]

    $scope.t1 = [];
    for (var i = 0; i < t1.length; i++) {
        t1[i]["F"] = (t1[i]["C"] * (parseInt(t1[i]["D"].toString().replace(',', '')))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';
        $scope.t1.push(t1[i]);
    }

    $scope.t2 = [];
    for (var i = 0; i < t2.length; i++) {
        t2[i]["F"] = (t2[i]["B"] * (parseInt(t2[i]["E"].toString().replace(',', '')))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';
        $scope.t2.push({
            "A": t2[i]["A"].substring(0, 4),
            "B": t2[i]["A"],
            "C": t2[i]["B"],
            "D": t2[i]["E"],
            "E": t2[i]["C"],
            "F": t2[i]["F"]
        })
    }

    $scope.t3 = [];
    for (var i = 0; i < t3.length; i++) {
        t3[i]["F"] = (t3[i]["B"] * (parseInt(t3[i]["E"].toString().replace(',', '')))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';
        $scope.t3.push({
            "A": t3[i]["A"].substring(0, 4),
            "B": t3[i]["A"],
            "C": t3[i]["B"],
            "D": t3[i]["E"],
            "E": t3[i]["C"],
            "F": t3[i]["F"]
        })
    }

    $scope.t4 = [];
    for (var i = 0; i < t4.length; i++) {
        t4[i]["F"] = (t4[i]["B"] * (parseInt(t4[i]["E"].toString().replace(',', '')))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';
        $scope.t4.push({
            "A": t4[i]["A"].substring(0, 4),
            "B": t4[i]["A"],
            "C": t4[i]["B"],
            "D": t4[i]["E"],
            "E": t4[i]["C"],
            "F": t4[i]["F"]
        })
    }

    $scope.t5 = [];
    for (var i = 0; i < t5.length; i++) {
        t5[i]["F"] = (t5[i]["B"] * (parseInt(t5[i]["E"].toString().replace(',', '')))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';
        $scope.t5.push({
            "A": t5[i]["A"].substring(0, 4),
            "B": t5[i]["A"],
            "C": t5[i]["B"],
            "D": t5[i]["E"],
            "E": t5[i]["C"],
            "F": t5[i]["F"]
        })
    }

    $scope.t6 = [];
    for (var i = 0; i < t6.length; i++) {
        t6[i]["F"] = (t6[i]["B"] * (parseInt(t6[i]["E"].toString().replace(',', '')))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00';
        $scope.t6.push({
            "A": t6[i]["A"].substring(0, 4),
            "B": t6[i]["A"],
            "C": t6[i]["B"],
            "D": t6[i]["E"],
            "E": t6[i]["C"],
            "F": t6[i]["F"]
        })
    }
});
