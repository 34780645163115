import { cipo } from 'cipo';

cipo.factory("Screen", function ($q, Model, URI, Message, FieldMod, ListMod, STATES_STATUS, Form, Dictionaries, PropField, $timeout) {
    var Screen = Model.extend(function (moduleId, obj, isStartScreen, fieldsDict, options, relationId) {
        var self = this;
        self.moduleId = moduleId || null;
        self.editable = false;
        self.dashboard = [];
        self.fieldsDict = [];
        self.propFieldsLookup = {};
        self.loadingFieldsDict = true;
        self.loadingFields = true;
        self.loadingPropFields = true;
        self.dirtyDashboard = false;
        self.topFormulaDict = {};
        self.properties = {
            name: "",
            id: null,
            isStartScreen: isStartScreen ? true : false,

        };
        self.isModified = false;
        if (obj && Object.prototype.toString.call(obj) == '[object Object]') {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        } else if(obj) {
            self.isFormulaScreen = true;
            self.type = obj;
        }
        self.relationId = relationId || null;
        self.customListsList = [];
        self.relationsListsList = [];
        self.listsList = [];
        self.listsDict = [];
        self.options = {
            gridType: 'scrollVertical',
            compactType: 'none',
            initCallback: self.methods["gridInit"],
            destroyCallback: self.methods["gridDestroy"],
            itemChangeCallback: self.methods["itemChange"],
            itemResizeCallback: self.methods["itemResize"],
            itemInitCallback: self.methods["itemInit"],
            itemRemovedCallback: self.methods["itemRemoved"],
            margin: 10,
            outerMargin: true,
            outerHeight: 500,
            mobileBreakpoint: 300,
            minCols: 12,
            maxCols: 12,
            minRows: 20,
            maxRows: 20,
            maxItemCols: 20,
            minItemCols: 1,
            maxItemRows: 3,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedColWidth: 250,
            fixedRowHeight: 250,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            emptyCellClickCallback: self.methods["emptyCellClick"],
            emptyCellContextMenuCallback: self.methods["emptyCellClick"],
            emptyCellDropCallback: self.methods["emptyCellClick"],
            emptyCellDragCallback: self.methods["emptyCellClick"],
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            draggable: {
                delayStart: 0,
                enabled: true,
                stop: self.methods["eventStop"]
            },
            resizable: {
                delayStart: 0,
                enabled: true,
                stop: self.methods["eventStop"]
            },
            swap: true,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: 'always', //onDrag&Resize
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: true
        };
        if (options) {
            for (var key in options) {
                if (options.hasOwnProperty(key)) {
                    self.options[key] = options[key];
                }
            }
        }
        self.process_FieldsDict(fieldsDict);
        if (!self.properties.id && !self.isFormulaScreen) self.createForm();

        if (self.isFormulaScreen) self.init();
    });

    Screen.prototype.get_propFields = function (urlparams, headerparams) {
        var self = this;
        var p = $q.defer();
        var dataURL = self.isTable ? URI.SCREENS.FIELDS : URI.SCREENS.PROP_FIELDS;
        var used = [];
        if (self.propFieldsLookup) {
            for (var id in self.propFieldsLookup) {
                var fi = self.propFieldsLookup[id];
                used[id] = fi && fi.properties && fi.properties.isUsedOnScreen;
            }
        }
        self.loadingPropFields = true;
        self.isPropFieldsLoaded = false;
        
        self.propFieldsLookup = {};
        self.isBusy = true;
        var urlParams = urlparams || { url: { screenId: self.properties.id }, urltype: 'obj' };
        var headerParams = headerparams || { headers: { moduleId: self.moduleId } };
        self[dataURL.method](dataURL, urlParams, headerParams)
            .then(function (r) {
                self.propFields = [];
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {

                        r[i] = new PropField(r[i]);
                        r[i].moduleId = self.moduleId;
                        var isUsed = used[r[i].properties.fieldId];
                        r[i].properties.isUsedOnScreen |= isUsed;
                        self.propFieldsLookup[r[i].properties.fieldId] = r[i];
                    }
                }
                self.propFields = r;
                p.resolve();
            })
            .catch(function (e) {
                console.error("get prop fields", e);
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingPropFields = false;
                self.isPropFieldsLoaded = true;
                self.isBusy = false;
            })

        return p.promise;
    }

    Screen.prototype.get_numericFieldsDict = function () {
        var self = this;
        Dictionaries.NumericFields({ moduleId: self.moduleId })
            .then(function (r) {
                self.numericFieldsDict = r;
            })
            .catch(function () { });
    }

    Screen.prototype.get_topFormulaDict = function (id) {
        var self = this;
        var p = $q.defer();
        Dictionaries.TopFormulas({ moduleId: self.moduleId }, {type: id})
            .then(function (r) {
                self.topFormulaDict[id] = r;
                p.resolve();
            })
            .catch(function () { p.reject(); });
        return p.promise;
    }

    Screen.prototype.init = function () {
        var self = this;
        if (!self.isFormulaScreen) {
            self.get_numericFieldsDict();
            if (!self.isRelationsListLoaded) self.get_RelationsLists();
            self.get_Fields();
        } else {
            self.get_FormulaFields();
        }
    }

    
    Screen.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        var form = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
        }
        self.form.set_Description(form);
        self.form.store_Data();
        
        self.form.initializing = false;
    }


    Screen.prototype.methods = {
        
        emptyCellClick: function (event, item) {
            // console.log('empty cell click', event, item);
            // $scope.dashboard.push(item);
            // this.$applyAsync();
        },

        eventStop: function (item, itemComponent, event) {
            // console.log('eventStop', item, itemComponent, event);
        },

        itemChange: function (item, itemComponent) {
            // console.log('itemChanged', item, itemComponent, this, self);
            //self.dirtyDashboard = true;
        },

        itemResize: function (item, itemComponent) {
            // console.log('itemResized', item, itemComponent);
            //this.api.optionsChanged();
        },

        itemInit: function (item, itemComponent) {
            //console.log('itemInitialized', item, itemComponent);
        },

        itemRemoved: function (item, itemComponent) {
            // console.log('itemRemoved', item, itemComponent, this);
            // itemComponent.gridster.options.api.optionsChanged();
        },
        gridInit: function (grid) {
            // console.log('gridInit', grid);
        },
        gridDestroy: function (grid) {
            // console.log('gridDestroy', grid);
        }
    }

    Screen.prototype.removeItem = function (field, e) {
        //e.preventDefault();
        //e.stopPropagation();
        field.isBusy = true;

        this.dashboard.splice(this.dashboard.indexOf(field), 1);
        if (field.fieldId && (!field.isEntityProperty || this.propFieldsLookup[field.fieldId])) {
            if (this.propFieldsLookup[field.fieldId])
                this.propFieldsLookup[field.fieldId].properties.isUsedOnScreen = false;
            if (field.id)
                this.update();
        }
    }

    Screen.prototype.addField = function (field) {
        var newField = new FieldMod(angular.copy(field), this.properties.isStartScreen || this.isTable);
        newField.notInScreen = true;
        this.setFieldForm(newField);
        this.isBusy = true;
    }
    Screen.prototype.addPropFieldToDashboard = function (field, isAddToScreen) {
        var self = this;
        field.properties.label = field.properties.name;

        var newField = new FieldMod(field.properties, this.properties.isStartScreen || this.isTable);
        newField.addToForm = isAddToScreen || false;
        newField.notInScreen = true;
        if (isAddToScreen) {
            newField.notInScreen = false;
            newField.isUsedOnScreen = true;
            self.addFieldToDashboard(newField);
        } else {
            this.setFieldForm(newField);
            this.isBusy = true;
        }
        
    }
    Screen.prototype.addFieldToDashboard = function (field, isUpdate) {
        var self = this;
        var fieldToPush = angular.copy(field);
        if (field.fieldId && this.propFieldsLookup[field.fieldId])
            this.propFieldsLookup[field.fieldId].properties.isUsedOnScreen = true;
        this.dashboard.push(fieldToPush);
        if (field.fieldId) this.dashboardLookup[field.fieldId] = field;
        this.isBusy = false;
        if (isUpdate) {
            $timeout(function () {
                self.update();
            }, 0);

        }
    }

    Screen.prototype.clearDashboard = function () {
        var self = this;
        self.dashboard = [];
        self.saveDashboard();
    }

    Screen.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.validate();
        self.form.loading = true;
        var urlData = self.properties.id ? URI.SCREENS.UPDATE : URI.SCREENS.CREATE;
        if (self.form.isValid) {
            self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.moduleId } })
                .then(function (result) {
                    if (!self.properties.id) self.properties.id = result;
                    Message.info('Screen saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject();
                    // Message.dberror(e);
                })
                .finally(function () {
                    if(self.form) self.form.loading = false;
                })
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }
    Screen.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.SCREENS.DELETE;
        self.deleting = true;
        self.deletingMsg = "Deleting screen...";
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Screen deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete screen. Try again?";
            })
            .finally(function () {

            })

        return p.promise;
    }

    Screen.prototype.validate_Label = function (field) {
        var self = this;
        //if (self.dashboard.length) {
        //    var labelsList = [];
        //    console.error('field in dashboard', self.dashboard.indexOf(field));
        //    for (var i = 0; i < self.dashboard.length; i++) {
        //        if (self.dashboard.indexOf(field) != i && (!self.currentField.fieldId || self.dashboard[i].fieldId != self.currentField.fieldId))
        //            labelsList.push(self.dashboard[i].label.toLowerCase());
        //    }
        //    if (labelsList.indexOf(field.label.toLowerCase()) == -1) return true;
        //    else return false;

        //} else return true;
        return true;
        
    }

    Screen.prototype.get_FormulaFields = function () {
        var self = this;
        var p = $q.defer();
        self.dirtyDashboard = false;
        var urlParams = { type: self.type };
        if (self.relationId) urlParams.relationId = self.relationId;
        var urlData = URI.GRID_EXPRESSIONS.GET;
        var urlData2 = URI.GRID_EXPRESSIONS.FN_DICT;
        self[urlData2.method](urlData2);
        self.loadingFields = true;

        self[urlData.method](urlData, { url: urlParams, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.dashboard = [];
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {
                        result[i].fieldTypeId = result[i].fieldTypeId || 16;
                        result[i].isFormulaField = true;
                        result[i].info = "You can use MIN, MAX, SUM, COUNT, AVG. Example: SUM( {{field}} ).";
                        self.dashboard.push(new FieldMod(result[i], self.properties.isStartScreen));
                    }
                }
                self.createBackup();
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
                console.error("get formula fields", e);
            })
            .finally(function () {
                self.loadingFields = false;
                self.isFieldsLoaded = true;
            })
        return p.promise;
    }

    Screen.prototype.get_Fields = function () {
        var self = this;
        var p = $q.defer();
        self.dirtyDashboard = false;
        var urlData = URI.SCREENS.FIELDS;
        self.isBusy = true;
        self.loadingFields = true;
        
        self.get_propFields();
        self[urlData.method](urlData, { url: { screenId: self.properties.id, type: 0, all:false }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.dashboard = [];
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {
                        result[i].addToForm = true;
                        self.dashboard.push(new FieldMod(result[i], self.properties.isStartScreen));
                    }
                }
                self.createBackup();
                if (!self.isListsDictLoaded) self.get_listsDict();
                
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingFields = false;
                if(!self.currentField) self.isBusy = false;
            })
        return p.promise;
    }

    Screen.prototype.get_listsDict = function () {
        var self = this;
        var p = $q.defer();
        self.listsLookup = {};
        Dictionaries.Lists()
            .then(function (r) {
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        r[i].key = r[i].key + '|' + r[i].type;
                    }
                }
                for (var i = 0; i < r.length; i++) {
                    self.listsLookup[r[i].key] = r[i];
                }
                self.listsDict = r;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.isListsDictLoaded = true;
            })

        return p.promise;

    }

    Screen.prototype.get_RelationsLists = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.MODULE_FIELDS.DATASOURCE_DICT_RELATIONS;
        self[urlData.method](urlData, {}, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.relationsListsList = [];
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {
                        if (result[i].key != self.moduleId) {
                            result[i].key = result[i].key + '|' + result[i].type;
                            self.relationsListsList.push(result[i]);
                        }
                    }
                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.isRelationsListLoaded = true;
            })

        return p.promise;

    }

    Screen.prototype.get_Lists = function (userDefined) {
        var self = this;
        var p = $q.defer();
        var type = userDefined ? { type: 0 } : {};
        
        self.loadingLists = true;
        var urlData = URI.LISTS.DATASOURCE_DICT;
        self[urlData.method](urlData, {  }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                if (userDefined) {
                    self.customListsList = [];
                    if (result && result.length) {
                        for (var i = 0; i < result.length; i++) {
                            self.customListsList.push(new ListMod(self.moduleId, result[i]));
                        }
                        
                    }
                }
                else {
                    if (result && result.length) {
                        for (var i = 0; i < result.length; i++) {
                            result[i].key = result[i].key + '|' + result[i].type;
                        }
                    }
                    self.listsList = result;
                    // if (self.currentField) self.create_FieldForm(self.currentField);
                }
                
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.loadingLists = false;
            })

        return p.promise;

    }

    Screen.prototype.propField_Action = function (field, isDelete) {
        var self = this;
        var p = $q.defer();
        var propField;
        self.message = isDelete ? "Deleting field..." : "Saving field...";
        if (typeof field.save != 'function') {
            propField = new PropField(field);
            propField.moduleId = self.moduleId;
        } else propField = field;
        var action = isDelete ? propField.delete() : propField.save(self.properties.id);
        action
            .then(function (r) {
                if (propField.form) delete propField.form;
                self.get_propFields();
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.message = "";
            });
        return p.promise;
    }

    Screen.prototype.saveField = function () {
        var self = this;
        if (self.currentField.fieldTypeId == 17) self.currentField.fieldTypeId = null;
        self.editFieldForm.validate();

        // process field and push into dashboard
        var processScreenField = function (isUpdate) {
            if (self.currentField.notInScreen) {
                self.currentField.notInScreen = false;
                self.addFieldToDashboard(self.currentField, isUpdate);
            } else if (self.dashboardLookup[self.currentField.fieldId] && self.dashboardLookup[self.currentField.fieldId] != self.currentField) {
                for (var key in self.currentField)
                    if (self.currentField.hasOwnProperty(key) && self.currentField[key])
                        self.dashboardLookup[self.currentField.fieldId][key] = self.currentField[key];
            }
            self.isBusy = false;

            if (!self.currentField.isGridSettings) {
                self.currentField = null;
                self.addToScreenForm = null;
            }
            
        }

        if (self.addToScreenForm) self.addToScreenForm.validate();

        var uniqueLabel = true;
        if (self.currentField.isEntityProperty && self.addToScreenForm || !self.currentField.isEntityProperty) {
            uniqueLabel = self.validate_Label(self.currentField);
        }

        if (self.editFieldForm.isValid && (!self.addToScreenForm || self.addToScreenForm && self.addToScreenForm.isValid) && uniqueLabel) {

            if (self.currentField.fieldExpression)
                self.currentField.fieldExpression = self.currentField.fieldExpression.replace(/<(.|\n)*?>/g, '').replace(/\u2060/g, '').replace(/&nbsp;/g, " ");
            // check dirty on entity field properties
            var editFormDirty = self.currentField.name != self.editFieldForm.originalData.name
                // || self.currentField.defaultValue != self.editFieldForm.originalData.defaultValue
                || self.currentField.dataSource != self.editFieldForm.originalData.dataSource
                || self.currentField.dataSource != self.editFieldForm.originalData.dataSource
                || self.currentField.isInitializedForTransition != self.editFieldForm.originalData.isInitializedForTransition
                || (self.currentField.dataSourceStateTypes || []).toString() != (self.editFieldForm.originalData.dataSourceStateTypes || []).toString()
                || self.currentField.fieldExpression != self.editFieldForm.originalData.fieldExpression
                || self.currentField.dataSourceComposed != self.editFieldForm.originalData.dataSourceComposed
                || (self.currentField.defaultValue || {}).toString() != (self.editFieldForm.originalData.defaultValue || {}).toString();
            // if field  was modified or is new
            if (!self.currentField.fieldId || editFormDirty) {
                // if select
                if (self.currentField.dataSourceComposed) {
                    var splits = self.currentField.dataSourceComposed.split('|');
                    self.currentField.dataSource = splits[0];
                    self.currentField.dataSourceType = splits[1];
                }
                var fieldToSend = angular.copy(self.currentField);
                self.currentField.isBusy = true;
                // save entity field
                self.propField_Action(fieldToSend)
                    .then(function (r) {
                        var isUpdateForm = self.currentField.fieldId ? false : true;
                        //Set screen field ID in case of a form field
                        if (r.id) self.currentField.id = r.id;
                        // update new property field
                        // if relation
                        if (!self.currentField.fieldId && self.currentField.isRelation) {
                            self.currentField.relationId = r.relationId;
                            if (self.editFieldForm.fieldsList.dataSourceComposed)
                                self.editFieldForm.fieldsList.dataSourceComposed.editMode = false;
                            if (r.dataSource) self.currentField.dataSource = r.dataSource;
                            self.currentField.isGridSettings = true;
                            if (self.currentField.fieldTypeId == 11 || self.currentField.fieldTypeId == 22) self.currentField.dataSourceType = 1;
                        }
                        
                        if (!self.currentField.fieldId) self.currentField.fieldId = r.fieldId;
                        // self.editFieldForm.set_Data(self.currentField);
                        self.editFieldForm.store_Data();
                        // if field already on the screen update data
                        if (self.currentField.isUsedOnScreen) {
                            // update info on form
                            self.dashboardLookup[self.currentField.fieldId].name = self.currentField.name;
                            if (self.currentField.dataSource) {
                                self.dashboardLookup[self.currentField.fieldId].dataSource = self.currentField.dataSource;
                                self.dashboardLookup[self.currentField.fieldId].dataSourceComposed = self.currentField.dataSourceComposed;
                                if (self.currentField.isRelation) {
                                    self.dashboardLookup[self.currentField.fieldId].dataSourceStateTypes = self.currentField.dataSourceStateTypes;
                                }
                            }
                        }


                        self.currentField.isBusy = false;
                        if (!self.currentField.addToForm) {
                            if (!self.currentField.isGridSettings) {
                                self.currentField = null;
                                // self.isBusy = false;
                                return;
                            }
                        } else
                            processScreenField(isUpdateForm);
                    })
                    .catch(function (e) {
                        self.currentField.isBusy = false;
                        console.error(e);
                        self.editFieldForm.catch(e);
                    });


            }
            // if field is to be added to screen
            else if (self.currentField.addToForm) processScreenField();
            // if field is unchanged
            else {
                self.currentField = null;
                self.isBusy = false;
            }



        } else {
            if (!uniqueLabel) {
                if (self.addToScreenForm) self.addToScreenForm.setFieldInvalid('label', 'This label is already used on another field');
                else self.editFieldForm.setFieldInvalid('label', 'This label is already used on another field');
            }
        }
    }

    Screen.prototype.cancelField = function () {
        var self = this;
        self.addingField = false;
        self.editFieldForm.restore_Data();
        self.currentField = null;
        self.editFieldForm = null;
        self.addToScreenForm = null;
        self.assignFormulaForm = null;
        this.isBusy = false;
    }

    Screen.prototype.setFieldForm = function (field) {
        var self = this;
        if (field.fieldTypeId == 5 || field.fieldTypeId == 8 || field.fieldTypeId == 13) {
            if (self.isListsDictLoaded) {
                self.create_FieldForm(field);
            } else {
                self.get_listsDict()
                    .then(function () {
                        self.create_FieldForm(field);
                    })
                    .catch(function (e) {
                        console.error(e)
                    })
            }
        } else {
            self.create_FieldForm(field);
        }
        

    }

    Screen.prototype.toggle_assignFormulaForm = function () {
        var self = this;
        if (self.assignFormulaForm) self.assignFormulaForm = null;
        else {
            self.assignFormulaForm = new Form(self.currentField);
            self.assignFormulaForm.initializing = true;

            var createForm = function () {

                self.assignFormulaForm.loading = true;
                self.assignFormulaForm.set_Description({ dataSource: { label: "Select formula", type: "select", options: self.topFormulaDict[self.currentField.fieldTypeId] } });
                self.assignFormulaForm.initializing = false;
                self.assignFormulaForm.store_Data();
                self.assignFormulaForm.loading = false;
            }

            if (self.topFormulaDict[self.currentField.fieldTypeId]) {
                createForm();
            } else {
                self.get_topFormulaDict(self.currentField.fieldTypeId)
                    .then(function () {
                        createForm();
                    })
                    .catch(function () { self.assignFormulaForm.initializing = false; })
                    .finally(function () { })
            }
        }
        
    }


    Screen.prototype.create_FieldForm = function (field) {
        var self = this;
        self.currentField = field;
        // self.field = field;
        self.assignFormulaForm = null;
        self.editFieldForm = new Form(field);
        self.editFieldForm.initializing = true;
        this.isBusy = true;
        
        var setDefaults = function (isProperty) {
            var whichForm = isProperty ? propertyForm : nonPropertyForm;
            var whichGrid = isProperty ? propertyGrid : nonPropertyGrid;
            // defaults
            switch (parseInt(field.fieldTypeId)) {
                case 1:
                    // text
                    whichForm.defaultValue = { label: 'Default value', type: 'text' };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 2:
                    // number
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'text', validation: { isNumber: true }
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 3:
                    // textarea
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'textarea'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 4:
                    // editor
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'editor'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 5:
                    // select or radio
                    whichForm.defaultValue = {
                        label: 'Select default value', type: 'select'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 8:
                    // select or radio
                    whichForm.defaultValue = {
                        label: 'Select default value', type: 'select'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 6:
                    // checkbox
                    whichForm.defaultValue = {
                        label: 'Selected by default', type: 'checkbox', noErrorMsg: true
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 7:
                    // datetimepicker
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'datetimepicker'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 12:
                    // attachment
                    //whichForm.defaultValue = {
                    //    label: 'Default file(s)', type: 'attachment'
                    //};
                    //whichGrid.push({ defaultValue: 100 });
                    break;
                case 13:
                    // multiselect
                    whichForm.defaultValue = {
                        label: 'Select default value(s)', type: 'select', multiple: true
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 14:
                    // datepicker
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'datepicker'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 15:
                    // timepicker
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'timepicker'
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 16:
                    // integer
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'text', validation: { isInteger: true }
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                case 23:
                    // MONEY
                    whichForm.defaultValue = {
                        label: 'Default value', type: 'money', validation: { isNumber: true }
                    };
                    whichGrid.push({ defaultValue: 100 });
                    break;
                default:
                // code block
            }
        };

        var loadListItems = function (id, fieldType, keepDefault) {
            var p = $q.defer();
            if (!self.listsLookup[id]) {
                self.currentField.dataSourceComposed = null;
                return;
            }
            self.editFieldForm.loading = true;
            var actualId = id.split('|')[0];
            var dataURL = URI.LISTS.GET_ELEMENTS;
            self[dataURL.method](dataURL, { url: { id: actualId }, urltype: 'obj' })
                .then(function (r) {
                    field.dataSourceComposedBkp = field.dataSourceComposed;
                    var defaultField;
                    if (fieldType == 13) {
                        defaultField = {
                            defaultValue: { label: 'Select default value(s)', type: 'select', optionsKeyProperty: "id", optionsValueProperty: "name", options: r, multiple: true }
                        };
                    } else {
                        defaultField = {
                            defaultValue: { label: 'Select default value', type: 'select', optionsKeyProperty: "id", optionsValueProperty: "name", options: r }
                        };
                    }
                    self.editFieldForm.set_Description(defaultField, true);
                    self.editFieldForm.fieldsList.dataSourceComposed.onClose = function (select) {
                        if (field.dataSourceComposed && field.dataSourceComposedBkp != field.dataSourceComposed)
                            loadListItems(select._value, field.fieldTypeId);
                    };
                    if (!keepDefault) field.defaultValue = fieldType == 13 ? [] : null;
                    if (self.editFieldForm.fieldsList.addToForm) {
                        self.editFieldForm.fieldsList.addToForm.onChange = function (field) {
                            toggle_AddToForm_Form();
                        }
                    }
                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.editFieldForm.loading = false;
                })
            return p.promise;
        }

        //--- common to all fields ---//

        // property fields
        var propertyForm = {
            name: { label: 'Field Name', type: 'text', validation: { required: true, maxChars: 100 } }
        };
        var propertyGrid = [{ name: 100 }];

        // screen fields
        var nonPropertyForm = {
            label: { label: 'Field Label', type: 'text', validation: { required: true, maxChars: 100 } },
            readonly: { label: 'Readonly', type: 'checkbox', noErrorMsg: true },
            required: { label: 'Required', type: 'checkbox', noErrorMsg: true }
        };
        
        var nonPropertyGrid = [{ label: 100 }];
        var checkboxesGrid = [{ readonly: 50, required: 50 }];

        // field particulars
        var fieldExpression = {
            label: 'Field Formula',
            type: 'editor',
            info: "",
            noToolbarEditor: true,
            options: { scaytAutoload: false, spellcheck: false, heightMin: 60 },
            hints: self.numericFieldsDict,
            validation: { required: true }
        };
        var fieldTypeId = {
            label: 'Formula Type',
            type: 'select',
            options: [self.fieldDictLookup[2], self.fieldDictLookup[16],self.fieldDictLookup[23]],
            validation: { required: true }
        };
        var dataSourceComposed = {
            label: 'Select List',
            type: 'select',
            editMode: field.fieldId && field.isRelation ? false : true,
            options: field.isRelation ? self.modulesDict : self.listsDict,
            validation: { required: true }
        };
        
        var dataSourceStateTypes = {
            label: 'Select State Type',
            type: 'select',
            options: STATES_STATUS().statusSmallerDict,
            multiple: true,
            validation: { required: true }
        };
        var annotation = { label: 'Annotation Content', type: 'editor', validation: { required: true } };

        var isInitializedForTransition = { label: 'Clear value on transition form usage', type: 'checkbox' };

        // new property field on a startscreen except for annotation 
        // or add / edit existing property field from the list
        if (field.isEntityProperty) {
            //if formula field
            if (field.fieldTypeId == 17 || field.isFormula) {
                propertyForm.fieldExpression = fieldExpression;
                propertyForm.fieldTypeId = fieldTypeId;
                propertyGrid[0] = { name: 75, fieldTypeId: 25 };
                propertyGrid.push({ fieldExpression: 100 });
            }
            // if select/multiselect/radio
            if (field.isSelectType) {
                propertyForm.dataSourceComposed = dataSourceComposed;
                if (field.fieldTypeId == 20 || field.fieldTypeId == 21) {
                    propertyForm.dataSourceStateTypes = dataSourceStateTypes;
                    propertyGrid.push({ dataSourceComposed: 66, dataSourceStateTypes: 33 });
                } else {
                    propertyGrid.push({ dataSourceComposed: 100 });
                }
                
            }

            

            setDefaults(true);

            if (!field.isRelation) {
                propertyForm.isInitializedForTransition = isInitializedForTransition;
                propertyGrid.push({ isInitializedForTransition: 100 });
            }

            if (!self.isTable) propertyForm.addToForm = { label: 'Add to form', type: 'checkbox', noErrorMsg: true };
            if (!field.isUsedOnScreen) propertyGrid.push({ addToForm: 100 });
            if (field.isUsedOnScreen && field.id) field.addToForm = true;


            self.editFieldForm.set_Description(propertyForm);
            self.editFieldForm.setTemplate('grid', propertyGrid);
            self.editFieldForm.initializing = false;
            
            self.editFieldForm.set_Data(field);
            self.editFieldForm.store_Data();

            if (field.fieldTypeId == 5 || field.fieldTypeId == 8 || field.fieldTypeId == 13) {
                self.editFieldForm.fieldsList.dataSourceComposed.onClose = function (select) {
                    if (field.dataSourceComposed && field.dataSourceComposedBkp != field.dataSourceComposed)
                        loadListItems(select._value, field.fieldTypeId);
                }
            }
            if (field.fieldTypeId == 5 || field.fieldTypeId == 8 || field.fieldTypeId == 13) {
                if (field.dataSourceComposed) loadListItems(field.dataSourceComposed, field.fieldTypeId, true);
            }
            if (!self.isTable)
                self.editFieldForm.fieldsList.addToForm.onChange = function (field) {
                    toggle_AddToForm_Form();
                }

            if (field.fieldTypeId == 20 || field.fieldTypeId == 21) {
                self.editFieldForm.fieldsList.dataSourceStateTypes.editMode = false;
                self.editFieldForm.fieldsList.name.editMode = false;
            }
            
            var toggle_AddToForm_Form = function () {
                try {
                    if (!self.currentField.addToForm) { if (self.addToScreenForm) self.addToScreenForm = null; }
                    else {
                        self.currentField.label = self.currentField.label || self.currentField.name;
                        self.addToScreenForm = new Form(self.currentField);
                        if (self.currentField.fieldSystemName) nonPropertyForm.required.editMode = false;
                        self.addToScreenForm.set_Description(nonPropertyForm);
                        self.addToScreenForm.setTemplate('grid', nonPropertyGrid.concat(checkboxesGrid));
                        self.addToScreenForm.initializing = false;
                        self.addToScreenForm.store_Data();
                    }
                }
                catch (e) {
                    console.error(e);
                }
            };
            if (field.addToForm || field.isUsedOnScreen && field.id) toggle_AddToForm_Form();
            if ((field.fieldTypeId == 23 || field.fieldTypeId == 2) && field.dataSource) self.toggle_assignFormulaForm();
            

            return;
        }
        // annotation / any field on a transition screen except for select/multiselect/radio
        if (field.fieldTypeId != 5 && field.fieldTypeId != 8 && field.fieldTypeId != 13 && !self.isFormulaScreen) {
            if (field.fieldTypeId == 9) {
                nonPropertyForm.annotation = annotation;
                nonPropertyGrid.push({ annotation: 100 });
            }
            if (field.fieldTypeId == 17 || field.isFormula) {                
                nonPropertyForm.fieldExpression = fieldExpression;
                nonPropertyForm.fieldTypeId = fieldTypeId;
                nonPropertyGrid[0] = { label: 75, fieldTypeId: 25 };
                nonPropertyGrid.push({ fieldExpression: 100 });
            }
            setDefaults();

            self.editFieldForm.set_Description(nonPropertyForm);
            self.editFieldForm.setTemplate('grid', field.fieldTypeId == 9 || field.isFormula ? nonPropertyGrid : nonPropertyGrid.concat(checkboxesGrid));
            self.editFieldForm.initializing = false;
            
            self.editFieldForm.set_Data(field);
            self.editFieldForm.store_Data();


            return;
        }
        // formula fields
        if (self.isFormulaScreen) {
            if (!field.fieldExpression) field.fieldExpression = "";
            fieldExpression.info = "You can use MIN, MAX, SUM, COUNT, AVG. Example: SUM( {{field}} ).";
            nonPropertyForm.fieldExpression = fieldExpression;
            nonPropertyGrid.push({ fieldExpression: 100 });

            self.editFieldForm.set_Description(nonPropertyForm);
            self.editFieldForm.setTemplate('grid', nonPropertyGrid);
            self.editFieldForm.initializing = false;
            
            self.editFieldForm.set_Data(field);
            self.editFieldForm.store_Data();


            return;
        }
        // if select/multiselect/radio on a transition screen
        if (field.fieldTypeId == 5 || field.fieldTypeId == 8 || field.fieldTypeId == 13) {
            nonPropertyForm.dataSourceComposed = dataSourceComposed;
            nonPropertyGrid.push({ dataSourceComposed: 100 });

            setDefaults();
            self.editFieldForm.set_Description(nonPropertyForm);
            self.editFieldForm.setTemplate('grid', nonPropertyGrid.concat(checkboxesGrid));
            self.editFieldForm.initializing = false;
            
            self.editFieldForm.set_Data(field);
            self.editFieldForm.store_Data();

            self.editFieldForm.fieldsList.dataSourceComposed.onClose = function (select) {
                if (field.dataSourceComposed && field.dataSourceComposedBkp != field.dataSourceComposed)
                    loadListItems(select._value, field.fieldTypeId);
            }
            if (field.fieldTypeId == 5 || field.fieldTypeId == 8 || field.fieldTypeId == 13) {
                if (field.dataSourceComposed) loadListItems(field.dataSourceComposed, field.fieldTypeId, true);
            }

            

            return;
        }


    }

    Screen.prototype.createBackup = function () {
        var self = this;
        self.backupDashboard = angular.copy(self.dashboard);
        self.dashboardLookup = {};
        for (var i = 0; i < self.dashboard.length; i++) {
            if (self.dashboard[i].fieldId) self.dashboardLookup[self.dashboard[i].fieldId] = self.dashboard[i];
        }
    }

    Screen.prototype.checkDirty = function () {
        var self = this;
        var a = [{ prop: 1 }];
        var b = [{ prop: "1" }];
        
        if (!self.loadingFields) return !angular.equals(self.backupDashboard, self.dashboard);
        else return false;
    }

    Screen.prototype.restoreBackup = function () {
        var self = this;
        self.dashboard = angular.copy(self.backupDashboard);
    }

    Screen.prototype.process_FieldsDict = function (fieldsDict) {
        var self = this;
        var source = fieldsDict ? angular.copy(fieldsDict) : [];
        var nonRelationsDict = [];
        self.fieldTypeLookup = {};
        self.fieldDictLookup = {};
        self.relationsFieldsDict = [];
        self.fieldsDict = [];
        for (var i = 0; i < source.length; i++) {
            self.fieldTypeLookup[source[i].key] = source[i].value;
            self.fieldDictLookup[source[i].key] = source[i];
            if (source[i].isRelation) {
                self.relationsFieldsDict.push(source[i]);

            } else {
                nonRelationsDict.push(source[i]);
            }
        }
        if (!self.isFormulaScreen) self.fieldsDict = nonRelationsDict;
        else {
            self.fieldsDict.push(self.fieldDictLookup[2]);
            self.fieldsDict.push(self.fieldDictLookup[16]);
            self.fieldsDict.push(self.fieldDictLookup[23]);
        }
    }

    Screen.prototype.saveFormula = function () {
        var self = this;
        var p = $q.defer();
        var urlParams = { type: self.type };
        if (self.relationId) urlParams.relationId = self.relationId;
        var urlData = URI.GRID_EXPRESSIONS.SYNC;
        self.isBusy = true;
        var dashboard = angular.copy(self.dashboard);
        for (var i = 0; i < dashboard.length; i++) {
            if (dashboard[i].value) delete dashboard[i].value;
            dashboard[i].type = self.type;
        }
        self.message = 'Saving formulas...';
        self[urlData.method](urlData, { url: urlParams, urltype: 'obj', body: dashboard }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Screen updated successfully');
                self.createBackup();
                p.resolve();
                self.message = "";
            })
            .catch(function (e) {
                p.reject(e);
                self.message = "Save has failed. Try again?";
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }

    Screen.prototype.saveNonFormula = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.SCREENS.SYNC;
        self.updateMessage = "Updating form...";
        self.isBusy = true;
        var processedDashboard = angular.copy(self.dashboard);
        if (processedDashboard.length) {
            for (var i = 0; i < processedDashboard.length; i++) {
                if (processedDashboard[i].isSelectType && processedDashboard[i].dataSourceComposed) {
                    var splits = processedDashboard[i].dataSourceComposed.split('|');
                    processedDashboard[i].dataSource = splits[0];
                    processedDashboard[i].dataSourceType = splits[1];
                }
                //delete processedDashboard[i].value;
            }
        }
        self[urlData.method](urlData, { url: { screenId: self.properties.id }, urltype: 'obj', body: processedDashboard }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.isBusy = false;
                self.get_Fields();
                self.get_numericFieldsDict();
                Message.info('Form updated successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject("update", e);
                Message.dberror(e);
                self.isBusy = false;
            })
            .finally(function () {
                self.updateMessage = "";
                //self.isBusy = false;
            })
        return p.promise;
    }

    Screen.prototype.update = function () {
        var self = this;
        var p = $q.defer();
        var action = self.isFormulaScreen ? self.saveFormula() : self.saveNonFormula();
        action
            .then(function () {
                if (self.properties.isStartScreen || self.isFormulaScreen) self.isModified = true;
                p.resolve();
            })
            .then(function () {
                p.reject();
            })
        return p.promise;
    }

    Screen.extend = function (construct) {
        var self = this;
        var Child = construct;
        Child.prototype = angular.copy(self.prototype);
        Child.prototype.constructor = Child;

        return Child;
    }


    return Screen;
});
