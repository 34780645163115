import { AbstractControl } from '@angular/forms';

type ValidatorResponse = { [key: string]: any };

export class CipoValidators {
  static requireMinOne(control: AbstractControl): ValidatorResponse {
    if (control.value?.length) {
      return null;
    }

    return {
      requiredMinOne: true,
    };
  }
}
