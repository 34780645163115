import { cipo } from 'cipo';

cipo.controller('editListController',
    function ($scope, Message, MODAL, ACTIONS, ListManagement, operations, module, $q, obj, $mdDialog, $timeout) {

        $scope.modalInfo = MODAL;
        $scope.listManagement = new ListManagement(obj);
        
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.listManagement.list.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        $scope.disabledBtn = false;

        $scope.actionBtnDisabled = false;
        //$scope.listManagement.listdata = obj;

        $scope.$on('tab-change', function (event, tabEvent) {
            if ($scope.listManagement.list.properties.name == $scope.listManagement.list.backup.name && $scope.listManagement.list.properties.sortAlphabetically == $scope.listManagement.list.backup.sortAlphabetically) {
                tabEvent.accept();
            } else {

                // capture the index of the tab cliked
                $scope.navToIndex = tabEvent.index;

                $scope.listManagement.list.hightlight = true;
                $scope.listManagement.list.tabWarning = true;
                
                // prevent tab change
                tabEvent.reject();
            }
        });

        var relationsOptions = function () {

            var options = {
                loadingFlag: 'isRelationsLoaded',
                listKey: "relationsList",
                title: "relations",
                listItemUsed: "inUse",
                listItemUsedText: "in Use",
                hasSearch: true,
                parent: $scope.listManagement,
                
                // hasAdditionalScreens: true,
                //subtitle: {
                //    text: "Relation Type:",
                //    content: function (item) { return item.properties.typeName || '-'; },
                //    secondLine: function (item) {
                //        return '<br/> <strong class="warningBorderBox" style="font-size: 12px; padding: 6px 6px 2px; margin: 6px 0 4px;">'
                //            + item.properties.parentName
                //            + ' <i class="mdi mdi-arrow-right" style="font-size: 12px;"></i> ' + item.properties.childName + "</strong>";
                //    }
                //},
                addButton: {
                    text: "Set Relation",
                    icon: "mdi-plus",
                    condition: function (item) {
                        return true;
                    },
                    items: [
                        {
                            name: "To Parent List",
                            click: function (parent) {
                                return $scope.listManagement.new_Relation({
                                    isParent: false, childListId: parent.list.properties.id,
                                    childName: parent.list.properties.name
                                });
                            }

                        },
                        {
                            name: "To Child List",
                            click: function (parent) {
                                return $scope.listManagement.new_Relation({ parentListId: parent.list.properties.id, parentName: parent.list.properties.name });
                            }

                        }
                    ],
                    //click: $scope.module.properties.id == -3 ? function () {
                    //    return $scope.module.new_Relation(2);
                    //} : null
                },
                showEdit: function () { return false;},
                save_or_delete: "relation_Action",
                detailsIcon: 'mdi mdi-format-list-checks',
                hasDetails: true,
                type: "list",
                operations: $scope.operations
            };

            return options;

        } 
        
        $scope.relationsTab = function () {
            if (!$scope.listManagement.isRelationsLoaded) {
                $scope.relationsOptions = relationsOptions();
                $scope.listManagement.get_RelationsList();
            }
                
        }

        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            if ($scope.closingModal) $scope.close(true);
            else {
                $scope.listManagement.list.tabWarning = false;
                $scope.listManagement.list.hightlight = false;
                $scope.disabledBtn = false;

                $scope.listManagement.list.form.restore_Data();
                $scope.selectedIndex = $scope.navToIndex;

            }
        };
        
        $scope.updateTabInfo = function () {
            $scope.listManagement.list.hightlight = false;
            // $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    if ($scope.closingModal) $scope.close(true);
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.listManagement.list.tabWarning = false;
            $scope.listManagement.list.hightlight = false;
            $scope.disabledBtn = false;
            $scope.closingModal = false;
        };
        $scope.isModified = false;

        $scope.save = function (isAdd) {
            var p = $q.defer();
            $scope.disabledBtn = true;
            $scope.listManagement.list.tabWarning = false;
            $scope.listManagement.list.hightlight = false;
            $scope.message = "Saving list...";
            $scope.listManagement.list.save(isAdd)
                .then(function (result) {
                    // Message.info('List saved successfully');
                    $scope.isModified = true;
                    p.resolve();
                    $scope.disabledBtn = false;
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
            return p.promise;
        };



        $scope.close = function (isWarning) {
            if ($scope.listManagement.list.properties.name == $scope.listManagement.list.backup.name && $scope.listManagement.list.properties.sortAlphabetically == $scope.listManagement.list.backup.sortAlphabetically  || isWarning)
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            else {
                $scope.listManagement.list.hightlight = true;
                $scope.listManagement.list.tabWarning = true;
                $scope.closingModal = true;
            }
        };

        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.save,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction
        }

    });
