import { cipo } from 'cipo';

cipo.factory("EntityFieldsLayout", function (TABLE_ROW) {
    var EntityFieldsLayout = function (p_layout, p_idKey) {
        var layout = p_layout || [];
        this.idKey = p_idKey || "id";
        this.set_Data(layout);
    }

    EntityFieldsLayout.prototype.init = function () {
        this.matrix = {
            design: [],
            data: [],
            maxRows: 0
        };
        this.table = [];
    }

    EntityFieldsLayout.prototype.set_Data = function (p_data) {
        var layout = p_data || [];
        this.data = [];
        if (typeof layout == "string")
            layout = JSON.parse(layout);
        this.data = layout;
        this.get_Fields();
    }

    EntityFieldsLayout.prototype.parseMatrixObject = function (p_obj) {
        var obj = p_obj || {
            _id: null,
            x: 0,
            y: 0,
            rows: 0,
            cols: 0
        }

        if (obj[this.idKey])
            obj._id = (obj[this.idKey]);

        if (obj.rows && obj.cols) {
            var objectNo = (this.matrix.data.length) ? this.matrix.data.length : 1;

            for (var i = obj.y; i < obj.y + obj.rows; i++) {
                for (var j = obj.x; j < obj.x + obj.cols; j++) {
                    if (!this.matrix.design[i]) this.matrix.design[i] = [];
                    this.matrix.design[i][j] = (i === obj.y) ? objectNo : 0;
                }
            }

            if ((obj.y + obj.rows) > this.matrix.maxRows) {
                this.matrix.maxRows = (obj.y + obj.rows);
            }

            this.matrix.data[objectNo] = obj;
        }

        return;
    }

    EntityFieldsLayout.prototype.parseMatrix = function () {
        this.init();
        if (this.data.length) {
            for (var i = 0; i < this.data.length; i++) {
                // add object to matrix
                this.parseMatrixObject(this.data[i]);
            }
        }
    }

    EntityFieldsLayout.prototype.set_EmptyObject = function () {
        return {
            colspan: 1,
            rowspan: 1,
            object: null
        }
    }

    EntityFieldsLayout.prototype.set_Object = function (p_obj) {
        var obj = p_obj || {
            cols: 1,
            rows: 1
        }

        return {
            colspan: obj.cols,
            rowspan: obj.rows,
            object: p_obj ? p_obj : false,
            editor: p_obj && p_obj.entityFieldTypeId && p_obj.entityFieldTypeId == 7 ? { 'height': p_obj.rows * TABLE_ROW.totalHeight - TABLE_ROW.labelHeight - TABLE_ROW.editorHeadingHight } : null,
            textarea: p_obj && p_obj.entityFieldTypeId && p_obj.entityFieldTypeId == 4 ? { 'height': p_obj.rows * TABLE_ROW.totalHeight - TABLE_ROW.labelHeight } : null
        }
    }

    EntityFieldsLayout.prototype.get_Fields = function () {
        this.parseMatrix();

        if (this.matrix.maxRows) {
            var currentField = -1;

            try {
                for (var i = 0; i < this.matrix.maxRows; i++) {
                    if (!this.table[i]) this.table[i] = [];
                    if (typeof this.matrix.design[i] == 'undefined')
                        this.matrix.design[i] = [];

                    for (var j = 0; j < 12; j++) {
                        if (typeof this.matrix.design[i][j] == 'undefined') {
                            this.matrix.design[i][j] = null;
                            this.table[i].push(this.set_EmptyObject());
                        }

                        if (currentField != this.matrix.design[i][j] && this.matrix.design[i][j]) {
                            currentField = this.matrix.design[i][j];
                            this.table[i].push(this.set_Object(this.matrix.data[currentField]));
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        return this.table;
    }
    
    return EntityFieldsLayout;
});
