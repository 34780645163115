import { Component, effect, input, signal } from '@angular/core';
import { CipoCheckboxConfig, CipoCheckboxControl } from '../common';
import { TranslateModule } from '@ngx-translate/core';

import { CipoFormModule } from '../../../modules/forms.module';

@Component({
  selector: 'cipo-checkbox',
  standalone: true,
  imports: [CipoFormModule, TranslateModule],
  templateUrl: './cipo-checkbox.component.html',
})
export class CipoCheckboxComponent {
  fieldData = signal<CipoCheckboxConfig>({});
  formControl = input<CipoCheckboxControl>(null, { alias: 'control' });

  constructor() {
    effect(() => this.fieldData.set(this.formControl().fieldData ?? {}), { allowSignalWrites: true });
  }
}
