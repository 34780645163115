import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs';
import { TenantSettingsService } from '../../module/instance/tenant-settings/tenant-settings.service';
import { tenantActions } from './tenant.actions';

@Injectable()
export class TenantEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tenantActions.load),
      exhaustMap(() =>
        this.tenantSettingsService
          .getTenantSettings()
          .pipe(map(settings => tenantActions.set({ tenant: { settings } }))),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private tenantSettingsService: TenantSettingsService,
  ) {}
}
