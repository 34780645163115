import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("GanttModel", function ($window, URI, userService, $http, Message) {
    var GanttModel = function (container, ganttData) {
        this.container = container;
        this.ganttData = ganttData;
        this.ganttSeries = [];
        this.ganttDep = [];
        this.filters = [];

        this.context = this.container[0];

        this.ganttLineStartY = 0;
        this.ganttLineStartX = 0;
        this.ganttWidth = 0;
        this.ganttHeight = 0;

        this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

        this.lookup = {
            lastYforTimeline: 0,
            lastLine: 0,
            childrenRows: [],
            ganttLinesWithDep: [],
            timelineCoord: [],
            timelineYearDates: [],
            lastGanttLine: null,
            tableRows: [],
            ganttLines: [],
            dependencies: [],
            timelines: [],
            currentViewID: 0
        };
        this.tbrCount = 0;
        this.glCount = 0;
        this.id = 0;

        this.timeline = {
            type: ['day', 'week', 'month', 'year'],
            start: '1-1-2020',
            end: '12-31-2020'
        };


        //TO DO dimension matrix
        //TO DO collapse
        //TO DO remove double scroll bars from safari
    }

    //helper methods
    GanttModel.prototype.makeSVG = function (tag, attrs) {
        var el = document.createElementNS('http://www.w3.org/2000/svg', tag);
        for (var k in attrs)
            el.setAttribute(k, attrs[k]);
        return el;
    };

    GanttModel.prototype.LightenDarkenColor = function (col, amt) {

        var usePound = false;

        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        var r = (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        return r;
    };

    GanttModel.prototype.updateSvgContainerWidth = function (newWidth) {
        var svgWidth = document.getElementById("svgContainerTIME").width.baseVal.value;

        if (newWidth > svgWidth) {
            document.getElementById("svgContainerTIME").width.baseVal.value = newWidth;
            document.getElementById("svgContainerLINE").width.baseVal.value = newWidth;
            this.ganttWidth = newWidth;
        }
    };
    GanttModel.prototype.updateSvgContainerHeight = function (newHeight, container) {
        var svgHeight = container.height.baseVal.value;

        if (newHeight > svgHeight) {
            container.height.baseVal.value = newHeight;
        }
    };

    GanttModel.prototype.updateContainerHeight = function (newH, container) {
        var svgHeight = container.style.height;
        if (newH > parseInt(svgHeight)) {
            container.style.height = newH + "px";
        }
    }

    GanttModel.prototype.updateSvgContainerPosition = function (newCoord) {
        var rect = document.getElementById("ganttLineContainerBckID");
        rect.y.baseVal.value = newCoord;
    };

    GanttModel.prototype.setSvgWidth = function (width, svgContainer) {
        svgContainer.width.baseVal.value = width;
    };
    GanttModel.prototype.setSvgHeight = function (height, svgContainer) {
        svgContainer.height.baseVal.value = height;
    };

    GanttModel.prototype.addCollapse = function (obj) {
        var self = this;
        obj.children[2].addEventListener("click", function () {
            this.parentNode.classList.toggle("active");
            for (var j = 3; j < this.parentNode.children.length; j++) {
                if (this.parentNode.children[j].style.display == "" || this.parentNode.children[j].style.display == "block") {
                    this.parentNode.children[j].style.display = "none";
                } else {
                    this.parentNode.children[j].style.display = "block";
                }
            }
            //update coordinates for next siblings
            var count = 0;
            for (var i = 1; i < document.getElementById("ganttTableRows").children.length; i++) {
                if (document.getElementById("ganttTableRows").children[i] == this.parentNode.nextElementSibling) {
                    var g = this.parentNode.nextSibling;

                    if (!g.hasAttribute("transform")) {
                        g.setAttributeNS(null, "transform", "translate(0, " +
                            (self.lookup.childrenRows[count] - self.lookup.childrenRows[count + 1]) + ")");

                        return;
                    }
                    if (g.hasAttribute("transform")) {
                        g.setAttributeNS(null, "transform", "translate(0, 0)");
                        g.removeAttribute("transform");
                    }

                }
                count++;
            }

        });
    };

    GanttModel.prototype.addOnHover = function (obj, info) {
        var self = this;
        obj.addEventListener("mouseover", function () {
            var infoWindow = self.makeSVG("rect", {
                "x": (obj.firstChild.x.baseVal.value).toString(),
                "y": (obj.firstChild.y.baseVal.value - 50).toString(),
                "width": (obj.firstChild.width.baseVal.value).toString(),
                "height": "50"
            });
            infoWindow.style.fill = "grey";
            this.parentNode.appendChild(infoWindow);
            var text = self.makeSVG("text", {
                "x": (obj.children[1].x.baseVal[0].value).toString(),
                "y": (obj.children[1].y.baseVal[0].value - 50 + 10).toString(),
            });
            text.innerHTML = info;
            this.parentNode.appendChild(text);

        });
        obj.addEventListener("mouseout", function () {
            for (var i = 0; i < 2; i++) {
                this.parentNode.removeChild(this.parentNode.lastElementChild);
            }
        });
    };

    GanttModel.prototype.findOutPositionByDate = function (objStartDate, objEndDate, timeline) {
        var self = this;
        self._start = 0;
        self._end = self.ganttWidth;
        var mS = moment(objStartDate, "MM-DD-YYYY");
        var mE = moment(objEndDate, "MM-DD-YYYY");
        for (var i = 0; i < timeline.calendar.length; i++) {
            if ((moment(mS).isSame(timeline.calendar[i], 'day'))) {
                self._start = timeline.daysCoord[i];
            }
            if ((moment(mE).isSame(timeline.calendar[i], 'day'))) {
                self._end = timeline.daysCoord[i];
            }
        }
    };

    GanttModel.prototype.switchBetweenViews = function (currentViewID, nextViewID) {
        var self = this;

        var timelineElements = document.getElementById("timelineContainer");
        var tableElements = document.getElementById("ganttTableRows");
        var ganttLineElements = document.getElementById("ganttLineContainer");

        for (var i = 0; i < timelineElements.children.length; i++) {
            if (timelineElements.children[i].getAttribute("svgView") == currentViewID) {
                timelineElements.children[i].style.visibility = "hidden";
            }
            if (timelineElements.children[i].getAttribute("svgView") == nextViewID) {
                timelineElements.children[i].style.visibility = "visible";
            }
        }
        for (var i = 0; i < tableElements.children.length; i++) {
            if (tableElements.children[i].getAttribute("svgView") == currentViewID) {
                tableElements.children[i].style.visibility = "hidden";
            }
            if (tableElements.children[i].getAttribute("svgView") == nextViewID) {
                tableElements.children[i].style.visibility = "visible";
            }
        }
        for (var i = 0; i < ganttLineElements.children.length; i++) {
            if (ganttLineElements.children[i].getAttribute("svgView") == currentViewID) {
                ganttLineElements.children[i].style.visibility = "hidden";
            }
            if (ganttLineElements.children[i].getAttribute("svgView") == nextViewID) {
                ganttLineElements.children[i].style.visibility = "visible";
            }
        }
        self.lookup.currentViewID = nextViewID.toString();          
    }
    //

    GanttModel.prototype.drawGrid = function (type, daysCoord, weekEndsCoord, monthEndsCoord, yearEndsCoord, view) {
        var self = this;
        var gGrid = document.createElementNS("http://www.w3.org/2000/svg", "g");
        gGrid.setAttribute("id", "gridId");
        gGrid.setAttribute("svgView", view);

        var svg = document.getElementById("svgContainerLINE");
        var container = document.getElementById("ganttLineContainer");

        container.appendChild(gGrid);
        if (type == 'day') {
            for (var i = 0; i < daysCoord.length; i++) {
                var vertical = self.makeSVG("path", {
                    "d": "M " + (daysCoord[i]).toString() + " " +
                    (0).toString() + " L " +
                    (daysCoord[i]).toString() + " " + (svg.height.baseVal.value).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                gGrid.appendChild(vertical);
            }
        }
        if (type == 'week') {
            for (var i = 0; i < weekEndsCoord.length; i++) {
                var vertical = self.makeSVG("path", {
                    "d": "M " + (weekEndsCoord[i]).toString() + " " +
                    (0).toString() + " L " +
                    (weekEndsCoord[i]).toString() + " " + (svg.height.baseVal.value).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                gGrid.appendChild(vertical);
            }
        }
        if (type == 'month') {
            for (var i = 0; i < monthEndsCoord.length; i++) {
                var vertical = self.makeSVG("path", {
                    "d": "M " + (monthEndsCoord[i]).toString() + " " +
                    (0).toString() + " L " +
                    (monthEndsCoord[i]).toString() + " " + (svg.height.baseVal.value).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                gGrid.appendChild(vertical);
            }
        }
        if (type == 'year') {
            for (var i = 0; i < yearEndsCoord.length; i++) {
                var vertical = self.makeSVG("path", {
                    "d": "M " + (yearEndsCoord[i]).toString() + " " +
                    (0).toString() + " L " +
                    (yearEndsCoord[i]).toString() + " " + (svg.height.baseVal.value).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                gGrid.appendChild(vertical);
            }
        }
        try {
            var verticalLine = self.makeSVG('path', {
                "d": "M " + (self.currentDate.x).toString() + " " +
                (0).toString() + " L " +
                (self.currentDate.x).toString() + " " + (svg.height.baseVal.value).toString(),
                "stroke-width": "1", "stroke": "black", "stroke-dasharray": "5,5"
            });
            verticalLine.id = "currentDateIDGrid";
            gGrid.appendChild(verticalLine);
        }
        catch (e) {

        }

    };

    GanttModel.prototype.drawGanttLine = function (start, end, height, color, completionPercentage, information, isChild, g, addToLookup, timeline, depends, id, pixelUnit) {
        var self = this;

        var GanttLine = function (start, end, height, color, completionPercentage, information, isChild, g, depends, id, pixelUnit) {
            this.start = start;//x coords
            this.end = end;
            this.y = self.ganttLineStartY;
            this.height = height;
            this.color = color;
            this.originalColor = this.color;
            this.information = information;
            self.ganttLineStartY += 20;
            this.g = g;
            this.isChild = isChild;
            this.completionPercentage = completionPercentage;
            this.html = null;
            this.subGroup = null;
            this.id = id;
            this.dependency = depends;
            this.pixelUnit = pixelUnit;
            this.width = 0;
        };

        GanttLine.prototype.draw = function () {
            //add the subgroup
            var xmlns = "http://www.w3.org/2000/svg";
            this.subGroup = document.createElementNS(xmlns, "g");

            this.g.appendChild(this.subGroup);

            this.subGroup.setAttributeNS(null, "id", self.id);
            //sbg.setAttributeNS(null, "class", "parentRowSVGgantt")
            self.id++;


            //linear grandient for coloring the percentages
            var linearGrandient = self.makeSVG("linearGradient", {
                "x1": "0%",
                "y1": "0%",
                "x2": "100%",
                "y2": "0%",
                "id": "ln" + this.start
            });
            var defs = document.getElementById("defs");
            defs.appendChild(linearGrandient);

            this.color = self.LightenDarkenColor(this.color.toString(), 80);
            //first color
            var stop1 = self.makeSVG("stop", {
                "offset": this.completionPercentage.toString() + "%",
            });
            //second color
            var stop2 = self.makeSVG("stop", {
                "offset": (100 - this.completionPercentage).toString() + "%",
            });
            stop1.style.stopColor = this.originalColor;
            stop1.style.stopOpacity = 1;
            stop2.style.stopColor = this.color;
            stop2.style.stopOpacity = 1;

            linearGrandient.appendChild(stop1);
            linearGrandient.appendChild(stop2);
            var url = "url(#" + linearGrandient.id + ")"

            if (this.isChild == true) {
                if ((self._end - self._start) == 0) {
                    self._end += 1;
                }
                var result = self.makeSVG("rect", {
                    "x": (self._start).toString(),
                    "y": (this.y + self.glCount + 8).toString(),
                    "width": (self._end - self._start + this.pixelUnit).toString(),
                    "height": this.height.toString()
                });
                this.width = self._end - self._start;
                result.style.fill = url;
                try {
                    var text = self.makeSVG("text", {
                        "x": (self._start + 5).toString(),
                        "y": (this.y + self.glCount + 8 + this.height / 2).toString(),

                    });

                    text.innerHTML = this.information;
                } catch (e) {

                }
                
            }
            if (this.isChild == false) {
                if ((self._end - self._start) == 0) {
                    self._end += 1;
                }
                var result = self.makeSVG("rect", {
                    "x": (self.ganttLineStartX + 10).toString(),
                    "y": (this.y + self.glCount + 8).toString(),
                    "width": (self.ganttWidth).toString(),
                    "height": this.height.toString()
                });
                result.style.fill = url;
                this.width = self._end - self._start;
                try {
                    var text = self.makeSVG("text", {
                        "x": (self.ganttLineStartX + 10 + 5).toString(),
                        "y": (this.y + self.glCount + 8 + this.height / 2).toString(),

                    });

                    text.innerHTML = this.information;

                } catch (e) {

                }

                
            }

            text.innerHTML = this.information;

            if (this.isChild) {
                this.subGroup.className.baseVal = "contentSvgTb";

            }


            var html = this.subGroup.appendChild(result);
            this.html = this.subGroup;
            this.subGroup.appendChild(text);
            self.lookup.lastGanttLine = result;
            //cut down text
            if (isChild) {
                var newText;
                var mSt = moment(this.start, "MM-DD-YYYY");
                var mEnd = moment(this.end, "MM-DD-YYYY");
                var duration = moment.duration(mEnd.diff(mSt));
                var width = duration.asDays() * pixelUnit;
                if (width == 0) {
                    width = 5;
                }
                if (text.textLength.baseVal.value > width - 5) {
                    newText = this.information.split(" ");
                    while (text.textLength.baseVal.value > width - 5 && text.textLength.baseVal.value > 0) {
                        newText.pop();
                        text.innerHTML = newText;
                    }
                    newText.push("(..)");
                    text.innerHTML = newText.join(" ");
                };
                if (text.textLength.baseVal.value > width - 5) {
                    newText.pop();
                    newText.pop();
                    newText.push("(..)");
                    text.innerHTML = newText.join(" ");
                }
            }
            
            self.glCount += 20;

        };


        var gl = new GanttLine(start, end, height, color, completionPercentage, "", isChild, g, depends, id, pixelUnit);
        self.findOutPositionByDate(gl.start, gl.end, timeline);
        gl.draw();
        self._gtl = gl;
        if (addToLookup) {
            self.lookup.ganttLines.push(gl);
        }
        
    };

    GanttModel.prototype.drawGanttTableRow = function (fontSize, font, information, isChild, g, hasChildren, addToLookup) {
        var self = this;

        var GanttTableRow = function (fontSize, font, information, isChild, g) {
            this.fontSize = fontSize;
            this.font = font;
            this.information = information;
            this.x = 0;
            this.y = 0;
            this.isChild = isChild;
            this.g = g;
            this.hasChildren = hasChildren;
        };

        GanttTableRow.prototype.draw = function () {
            /*var horizontalLine = makeSVG("path", {
                "d": 'M 0 ' + (self.ganttLineStartY + 10) + ' L ' + self.ganttLineStartX +' ' + (self.ganttLineStartY + 10),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });

            document.getElementById("svgContainer").appendChild(horizontalLine);*/



            var text = self.makeSVG("text", {
                "x": (20).toString(),
                "y": (self.ganttLineStartY + self.tbrCount + 25).toString()
            });

            text.innerHTML = this.information;
            if (!this.isChild) {
                //this.g.appendChild(tb);
                this.g.appendChild(text);
            }
            if (this.isChild) {
                //  tb.classList.add("contentSvgTb");
                text.classList.add("contentSvgTb");
                //this.g.appendChild(tb);
                this.g.appendChild(text);
            }

            self.tbrCount += 20;

            var tb = self.makeSVG("path", {
                "d": "M 0" + " " +
                (self.ganttLineStartY + self.tbrCount + 20).toString() + " L " +
                (self.ganttWidth).toString() + " " + (self.ganttLineStartY + self.tbrCount + 20).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            this.y = self.ganttLineStartY + self.tbrCount + 20
            this.g.appendChild(tb);
        };

        GanttTableRow.prototype.drawButton = function () {
            var result = self.makeSVG("rect", {
                "x": (80).toString(),
                "y": (self.ganttLineStartY + self.tbrCount - 5).toString(),
                "fill": "black",
                "width": "10",
                "height": "10"
            });
            this.g.appendChild(result);
            result.classList.add("collapsibleSvgTb");
        };

        var gtbr = new GanttTableRow(fontSize, font, information, isChild, g);
        gtbr.draw();
        if (hasChildren) {
            // gtbr.drawButton();
        }
        if (addToLookup) {
            self.lookup.tableRows.push(gtbr);
        }
    };

    GanttModel.prototype.drawDependency = function (src, trg, hasCondition, g) {
        var self = this;

        var GanttDependency = function (src, trg, hasCondition, g) {
            this.src = src;
            this.trg = trg;
            this.hasCondition = hasCondition;
            this.g = g;
        };

        GanttDependency.prototype.draw = function () {
            var horizontalLineStart = self.makeSVG("path", {
                "d": "M " + (this.src.x.baseVal.value + this.src.width.baseVal.value).toString() + " " +
                (this.src.y.baseVal.value + this.src.height.baseVal.value / 2).toString() + " L " +
                (this.src.x.baseVal.value + this.src.width.baseVal.value + 10).toString() + " " + (this.src.y.baseVal.value + this.src.height.baseVal.value / 2).toString(),
                "stroke-width": "1.5px", "stroke": "black"
            });

            var verticalLine = self.makeSVG("path", {
                "d": "M " + (this.src.x.baseVal.value + this.src.width.baseVal.value + 10).toString() + " " +
                (this.src.y.baseVal.value + this.src.height.baseVal.value / 2).toString() + " L " +
                (this.src.x.baseVal.value + this.src.width.baseVal.value + 10).toString() + " " + (this.trg.y.baseVal.value - 2).toString(),
                "stroke-width": "1px", "stroke": "black"
            });


            var horizontalLineEnd = self.makeSVG("path", {
                "d": "M " + (this.src.x.baseVal.value + this.src.width.baseVal.value + 10).toString() + " " +
                (this.trg.y.baseVal.value - 2).toString() + " L " +
                (this.trg.x.baseVal.value - 15).toString() + " " + (this.trg.y.baseVal.value - 2).toString(),
                "stroke-width": "1.5px", "stroke": "black"
            });

            var verticalLineEnd = self.makeSVG("path", {
                "d": "M " + (this.trg.x.baseVal.value - 15).toString() + " " + (this.trg.y.baseVal.value - 2).toString()
                + " L " + (this.trg.x.baseVal.value - 15).toString() + " " + (this.trg.y.baseVal.value + this.trg.height.baseVal.value / 2).toString(),
                "stroke-width": "1px", "stroke": "black"
            });

            var lastSegment = self.makeSVG("path", {
                "d": "M " + (this.trg.x.baseVal.value - 15).toString() + " " + (this.trg.y.baseVal.value + this.trg.height.baseVal.value / 2).toString()
                + " L " + (this.trg.x.baseVal.value).toString() + ", " + (this.trg.y.baseVal.value + this.trg.height.baseVal.value / 2).toString(),
                "stroke-width": "1.5px", "stroke": "black"
            });

            var arrowHead = self.makeSVG("polygon", {
                "points": (this.trg.x.baseVal.value - 8).toString() + ", " + (this.trg.y.baseVal.value + 5).toString() + ", " +
                (this.trg.x.baseVal.value).toString() + ", " + (this.trg.y.baseVal.value + this.trg.height.baseVal.value / 2).toString() + ", " +
                (this.trg.x.baseVal.value - 8).toString() + ", " + (this.trg.y.baseVal.value - 5 + this.trg.height.baseVal.value).toString(),
                "stroke-width": "1px", "fill": "black"
            });

            this.g.appendChild(horizontalLineStart);
            this.g.appendChild(verticalLine);
            this.g.appendChild(horizontalLineEnd);
            this.g.appendChild(verticalLineEnd);
            this.g.appendChild(lastSegment);
            this.g.appendChild(arrowHead);


        };

        var gd = new GanttDependency(src, trg, hasCondition, g);
        gd.draw();
        self.lookup.dependencies.push(gd);
    };

    GanttModel.prototype.drawTimeline = function (timeline, fontSize, font, container, pixelUnit, timelineHiddenUnits, g, view) {
        var dayH = 100;
        var weekH = 100;
        var monthH = 100;
        var yearH = 100;
        var lowerHeightLimitD = 25;//def 50
        var lowerHeightLimitW = 60;
        var lowerHeightLimitM = 100;
        var lowerHeightLimitY = 100;
        var dY, wY, mY, yY;
        //removed days text
        
        var self = this;

        var Timeline = function (timelineStart, timelineEnd, font, fontSize, timelineType, container, g, view) {
            this.timelineStart = timelineStart;
            this.timelineEnd = timelineEnd;
            this.font = font;
            this.fontSize = fontSize;
            this.yPosition = timelineType.length;
            this.stackPos = 1;
            this.stackedTimelines = timelineType;
            this.quarters = [];
            this.calendar = [];
            this.weekEndsCoord = [];
            this.weekNames = [];
            this.monthEndsCoord = [];
            this.monthNames = [];
            this.yearEndsCoord = [];
            this.yearNames = [];
            this.daysCoord = [];
            this.container = container;
            this.parentSvg = document.getElementById("svgContainerTIME");
            this.g = g;
            this.lastVisibileLineY = 0;
            this.width = 0;
            this.height = 0;
            this.view = view;
        };

        Timeline.prototype.calculate = function (days) {
            var currDate = moment(this.timelineStart, "MM-DD-YYYY").startOf('day');
            var lastDate = moment(this.timelineEnd, "MM-DD-YYYY").startOf('day');
            if (days) {
                this.calendar.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    this.calendar.push(currDate.clone());
                }
            } 

            self.updateSvgContainerHeight(this.yPosition * 25, document.getElementById("svgContainerTIME"));
            self.updateContainerHeight(document.getElementById("svgContainerTIME").height.baseVal.value, document.getElementById("timeID"));  
        };

        Timeline.prototype.drawYears = function (pixelUnit) {
            var offST = 0;
            var center
            this.container.appendChild(this.g);
            yY = this.parentSvg.height.baseVal.value -  lowerHeightLimitY;
            for (var i = 0; i < this.yearEndsCoord.length; i++) {
                if (i == 0) {
                    offST = (this.yearEndsCoord[i]) / 2;
                }
                if (i > 0) {
                    offST = (this.yearEndsCoord[i] - this.yearEndsCoord[i - 1]) / 2;
                }

                var verticalLine = self.makeSVG("path", {
                    "d": "M " + (this.yearEndsCoord[i]).toString() + " " +
                    (this.parentSvg.height.baseVal.value - 200 + lowerHeightLimitY).toString() + " L " +
                    (this.yearEndsCoord[i]).toString() + " " + (mY).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                verticalLine.id = "yearID";
                this.g.appendChild(verticalLine);


                var text = self.makeSVG("text", {
                    "x": (this.yearEndsCoord[i] - offST + padding).toString(),
                    "y": (this.parentSvg.height.baseVal.value - 10 - lowerHeightLimitY).toString()
                });
                
                text.innerHTML = this.yearNames[i];
                text.style.fontSize = this.fontSize;
                if (pixelUnit * 365 > 10) {
                    this.g.appendChild(text);
                }
                

                center = text.textLength.baseVal.value;
                var padding = (pixelUnit) / 2 - center / 2;
                if (pixelUnit * 365 > 10) {
                    this.g.removeChild(this.g.lastChild);

                }


                var text = self.makeSVG("text", {
                    "x": (this.yearEndsCoord[i] - offST + padding).toString(),
                    "y": (this.parentSvg.height.baseVal.value - 10 - lowerHeightLimitY).toString()
                });
                text.id = "yearID";
                text.innerHTML = this.yearNames[i];
                text.style.fontSize = this.fontSize;
                if (pixelUnit * 365 > 10) {
                    this.g.appendChild(text);
                }
                this.lastVisibileLineY = this.parentSvg.height.baseVal.value - lowerHeightLimitY;
            }
                        

        };

        Timeline.prototype.drawMonths = function (pixelUnit) {
            var tmlSelf = this;
            var padding = (pixelUnit) / 2;
            var monthLen = 0;
            var offST = 0;
            this.container.appendChild(this.g);
            mY = this.parentSvg.height.baseVal.value - lowerHeightLimitM;
            for (var i = 0; i < this.monthEndsCoord.length; i++) {
                if (i == 0) {
                    offST = (this.monthEndsCoord[i]) / 2;
                }
                if (i > 0) {
                    offST = (this.monthEndsCoord[i] - this.monthEndsCoord[i - 1]) / 2;
                }

                var verticalLine = self.makeSVG("path", {
                    "d": "M " + (this.monthEndsCoord[i]).toString() + " " +
                    (this.parentSvg.height.baseVal.value - lowerHeightLimitM).toString() + " L " +
                    (this.monthEndsCoord[i]).toString() + " " + (wY).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                verticalLine.id = "monthID";
                this.g.appendChild(verticalLine);


                var text = self.makeSVG("text", {
                    "x": (this.monthEndsCoord[i] - offST + padding).toString(),
                    "y": (this.parentSvg.height.baseVal.value - 20 + 45 - lowerHeightLimitM).toString()
                });
                
                text.style.fontSize = this.fontSize;
                text.innerHTML = self.months[this.monthNames[i]];

                if (pixelUnit * 30 > 10) {
                    this.g.appendChild(text);
                }
                

                center = text.textLength.baseVal.value;
                var padding = (pixelUnit) / 2 - center / 2;

                if (pixelUnit * 30 > 10) {
                    this.g.removeChild(this.g.lastChild);
                }
                
                var text = self.makeSVG("text", {
                    "x": (this.monthEndsCoord[i] - offST + padding).toString(),
                    "y": (this.parentSvg.height.baseVal.value - 20 + 45 - lowerHeightLimitM).toString()
                });
                text.id = "monthID";
                text.innerHTML = self.months[this.monthNames[i]];

                if (pixelUnit * 30 > 10) {
                    this.g.appendChild(text);
                }
                
                this.lastVisibileLineY = this.parentSvg.height.baseVal.value -  lowerHeightLimitM;
            }
            //for months that are only partialy in
            try {
                if (this.monthEndsCoord.length < this.monthNames.length) {
                    var padding = (document.getElementById("svgContainerTIME").width.baseVal.value - this.monthEndsCoord[this.monthEndsCoord.length - 1]) / 2;
                    var text = self.makeSVG("text", {
                        "x": (this.monthEndsCoord[this.monthEndsCoord.length - 1] + padding).toString(),
                        "y": (this.parentSvg.height.baseVal.value - 20 + 45 - lowerHeightLimitM).toString()
                    });
                    text.id = "monthID";
                    text.innerHTML = self.months[this.monthNames[this.monthNames.length - 1]];
                    if (pixelUnit * 30 > 10) {
                        this.g.appendChild(text);
                    }
                    
                }
            } catch (e) {
                console.log(e);
            }

        };

        Timeline.prototype.drawWeeks = function (pixelUnit) {
            var tmlSelf = this;
            var center;
            var weekLen = 0;
            var offST = 0;
            this.container.appendChild(this.g);
            wY = this.parentSvg.height.baseVal.value - lowerHeightLimitW;
            for (var i = 0; i < this.weekEndsCoord.length; i++) {
                if (i == 0) {
                    offST = (this.weekEndsCoord[i]) / 1.8;
                }
                if (i > 0) {
                    offST = ((this.weekEndsCoord[i] - this.weekEndsCoord[i - 1]) / 1.8);
                }
                if (i > 8) {
                    offST = ((this.weekEndsCoord[i] - this.weekEndsCoord[i - 1]) / 1.8) + 5;
                }
                var verticalLine = self.makeSVG("path", {
                    "d": "M " + (this.weekEndsCoord[i]).toString() + " " +
                    (this.parentSvg.height.baseVal.value - lowerHeightLimitW).toString() + " L " +
                    (this.weekEndsCoord[i]).toString() + " " + (dY).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0"
                });
                verticalLine.id = "weekID";
                this.g.appendChild(verticalLine);

                try {
                    var text = self.makeSVG("text", {
                        "x": (this.weekEndsCoord[i] + (padding || 0)).toString(),
                        "y": (this.parentSvg.height.baseVal.value - 20 - lowerHeightLimitD + 10).toString()
                    });
                    text.innerHTML = this.weekNames[i];
                    text.style.fontSize = this.fontSize;
                } catch(e){}
                
                if (pixelUnit*7 > 10) {
                    this.g.appendChild(text);
                }
                

                center = text.textLength.baseVal.value;
                var padding = (pixelUnit) / 2 - center / 2;

                if (pixelUnit * 7 > 10) {
                    this.g.removeChild(this.g.lastChild);
                }
                

                if (i == 0) {
                    var text = self.makeSVG("text", {
                        "x": (this.weekEndsCoord[i] - offST).toString(),
                        "y": (this.parentSvg.height.baseVal.value - 20 - lowerHeightLimitD + 10).toString()
                    });
                }
                if (i > 0) {
                    var text = self.makeSVG("text", {
                        "x": (this.weekEndsCoord[i] - offST).toString(),
                        "y": (this.parentSvg.height.baseVal.value - 20 - lowerHeightLimitD + 10).toString()
                    });
                }
                text.id = "weekID";
                text.innerHTML = this.weekNames[i];
                this.startWeek++;
                if (pixelUnit * 7 > 10) {
                    this.g.appendChild(text);
                }
                
                this.lastVisibileLineY = this.parentSvg.height.baseVal.value - lowerHeightLimitW;
            }

            
            
        };

        Timeline.prototype.drawDays = function (pixelUnit) {
            var center;

            var dayLen = 0;
            var padding = 0;
            var currentMonth;
            var oldCurMonth = this.calendar[0].month();
            var currentYear;
            var oldYear = this.calendar[0].year();
            this.container.appendChild(this.g);
            dY = this.parentSvg.height.baseVal.value - lowerHeightLimitD;
            for (var i = 0; i < this.calendar.length; i++) {
                currentMonth = this.calendar[i].month();
                if (i == 0) {
                    this.monthNames.push(this.calendar[i].month());//to catch first month
                    this.yearNames.push(this.calendar[i].year());//to catch first year
                }
                currentYear = this.calendar[i].year();
                var verticalLine = self.makeSVG("path", {
                    "d": "M " + (0 + dayLen).toString() + " " +
                    (this.parentSvg.height.baseVal.value - lowerHeightLimitD).toString() + " L " +
                    (0 + dayLen).toString() + " " + (this.parentSvg.height.baseVal.value).toString(),
                    "stroke-width": "1", "stroke": "#e0e0e0 "
                });
                verticalLine.id = "dayID";
                this.g.appendChild(verticalLine);


                var text = self.makeSVG("text", {
                    "x": (0 + dayLen + padding).toString(),
                    "y": (this.parentSvg.height.baseVal.value - 10).toString()
                });
                text.innerHTML = this.calendar[i].day();
                text.style.fontSize = this.fontSize;
                if (pixelUnit > 10) {
                    this.g.appendChild(text);
                }


                center = text.textLength.baseVal.value;
                padding = (pixelUnit) / 2 - center / 2;

                if (pixelUnit > 10) {
                    this.g.removeChild(this.g.lastChild);
                }


                var text = self.makeSVG("text", {
                    "x": (0 + dayLen + padding).toString(),
                    "y": (this.parentSvg.height.baseVal.value - 10).toString()
                });
                text.id = "dayID";
                text.innerHTML = (parseInt(this.calendar[i].day()) + 1).toString();

                if (pixelUnit > 10) {
                    this.g.appendChild(text);
                }
                //find week coordinates
                if (this.calendar[i].day() == 6) { 
                    this.weekEndsCoord.push(dayLen + pixelUnit);
                    this.weekNames.push(this.calendar[i].week());
                }
                if (this.calendar[i].day() < 6 && i == this.calendar.length-1) { //missing last week due to days not reaching 6
                    this.weekEndsCoord.push(dayLen + pixelUnit);
                    this.weekNames.push(this.calendar[i].week());
                }
                //find month coords
                if (currentMonth != oldCurMonth) {
                    this.monthEndsCoord.push(dayLen);
                    this.monthNames.push(this.calendar[i].month());
                    oldCurMonth = currentMonth;
                }
                //find year coords
                if (currentYear != oldYear) {
                    this.yearEndsCoord.push(dayLen);
                    this.yearNames.push(this.calendar[i].year());
                    oldYear = currentYear;
                }
                this.daysCoord.push(dayLen);
                dayLen += pixelUnit;
            }

            

            this.width = dayLen;
            self.updateSvgContainerWidth(dayLen);
        };

        Timeline.prototype.hide = function (type) {
            var container = document.getElementById("timelineId");
            for (var i = 0; i < container.children.length; i++) {
                if (container.children[i].id == type) {
                    container.children[i].setAttribute("visibility", "hidden");
                }
            }
        };

        Timeline.prototype.drawUpperBorder = function (lastVisibileLineY) {
            var upperBorder = self.makeSVG('path', {
                "d": "M " + (0).toString() + " " +
                (lastVisibileLineY).toString() + " L " +
                (document.getElementById("svgContainerTIME").width.baseVal.value).toString() + " " + (lastVisibileLineY).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            this.g.appendChild(upperBorder);
        };

        Timeline.prototype.drawMiddleBorder = function (y) {
            var border = self.makeSVG('path', {
                "d": "M " + (0).toString() + " " +
                (y).toString() + " L " +
                (document.getElementById("svgContainerTIME").width.baseVal.value).toString() + " " + (y).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            this.g.appendChild(border);
        };

        var CurrentDate = function (fontSize, font, timeline) {
            this.format = 'YYYY-MM-DD';
            this.fontSize = fontSize;
            this.font = font;
            this.timeline = timeline;
        };


        CurrentDate.prototype.draw = function (pixelUnit, g) {
            var today = moment();
            var coords;
            var momentDate = moment().format('MMMM Do YYYY');

            for (var i = 0; i < this.timeline.calendar.length; i++) {
                if (moment(today, "MM-DD-YYYY").isSame(this.timeline.calendar[i], 'day')) {
                    coords = this.timeline.daysCoord[i];
                }
            }

            var text = self.makeSVG('text', {
                "x": ((coords)).toString(),
                "y": (this.timeline.parentSvg.height.baseVal.value).toString()
            });

            var verticalLine = self.makeSVG('path', {
                "d": "M " + (coords + pixelUnit/2).toString() + " " +
                (0).toString() + " L " +
                (coords + pixelUnit / 2).toString() + " " + (this.timeline.parentSvg.height.baseVal.value).toString(),
                "stroke-width": "1", "stroke": "black", "stroke-dasharray": "5,5"
            });
            text.innerHTML = momentDate;

            // g.appendChild(text);
            g.appendChild(verticalLine);
            verticalLine.id = "currentDateIDTimeline";

            self.currentDate = {
                x: coords + pixelUnit / 2,
            }
        };

        /*
        Day ->  Week  ->   Month     ->     Quarter    ->   Year
        75  ->  525   ->   2100      ->      6300      ->   25200 
        */
        //timeline
        var timeln = new Timeline(timeline.start, timeline.end, 18, 'Arial', timeline.type, container, g, view);
        timeln.calculate(true);

        try {
            timeln.drawDays(pixelUnit);
            timeln.yPosition--;
            timeln.drawMiddleBorder(timeln.parentSvg.height.baseVal.value - lowerHeightLimitD);
            if (timeln.yPosition == 0) {
                timeln.drawUpperBorder(timeln.lastVisibileLineY);
            }                
            
        }
        catch (e) {

        }
        try {
            timeln.drawWeeks(pixelUnit);
            timeln.yPosition--;
            timeln.drawMiddleBorder(timeln.parentSvg.height.baseVal.value - lowerHeightLimitW);
            if (timeln.yPosition == 0) {
                timeln.drawUpperBorder(timeln.lastVisibileLineY);
            }
        }
        catch (e) {

        }
        try {
            timeln.drawMonths(pixelUnit);
            timeln.yPosition--;
            timeln.drawMiddleBorder(timeln.parentSvg.height.baseVal.value - lowerHeightLimitM);
            if (timeln.yPosition == 0) {
                timeln.drawUpperBorder(timeln.lastVisibileLineY);
            }

        }
        catch (e) {

        }
        try {

            timeln.drawYears(pixelUnit);
            timeln.yPosition--;
            timeln.drawMiddleBorder(timeln.parentSvg.height.baseVal.value - lowerHeightLimitY);
            if (timeln.yPosition == 0) {
                timeln.drawUpperBorder(timeln.lastVisibileLineY);
            }
            
        }
        catch (e) {

        }
        var timeDiv = document.getElementById("timeID");
        for (var i = 0; i < timelineHiddenUnits.length; i++) {
            timeln.hide(timelineHiddenUnits[i]);
            timeln.parentSvg.height.baseVal.value -= 50;
            timeDiv.style.height = (parseInt(timeDiv.style.height) - 50) + "px";
        }
        timeln.height = timeln.parentSvg.height.baseVal.value;
        
        try {
            var crt = new CurrentDate(18, 'Arial', timeln);
            crt.draw(pixelUnit, timeln.g);
        }
        catch (e) {
            console.log(e);
        }
        self.lookup.timelines.push(timeln);
        
    };

    GanttModel.prototype.addFilter = function (start, end, criteria, timeline, view) {
        if (start != "Invalid date" && end != "Invalid date") {
            var self = this;
            var Filter = function (start, end, criteria, timeline) {
                this.start = start;
                this.end = end;
                this.criteria = criteria;
                this.ganttLines = [];
                this.tableRows = [];
                this.view = view;
                this.width = 0;
            };

            Filter.prototype.filterByDate = function () {
                var st = moment(this.start, "MM-DD-YYYY");
                var end = moment(this.end, "MM-DD-YYYY");
                //TO DO fix bad table row data ex undentified
                for (var i = 0; i < self.lookup.ganttLines.length; i++) {
                    var startGl = moment(self.lookup.ganttLines[i].start, "MM-DD-YYYY");
                    var endGl = moment(self.lookup.ganttLines[i].end, "MM-DD-YYYY");
                    var duplicate = false;
                    //for lines that are in between the interval
                    if (moment(startGl).isSameOrAfter(st) && moment(endGl).isSameOrBefore(end) && duplicate == false) {
                        this.ganttLines.push(self.lookup.ganttLines[i]);
                        self.lookup.ganttLines[i].type = "inBetween";
                        if (typeof self.lookup.tableRows[i] != typeof undefined) {
                            this.tableRows.push(self.lookup.tableRows[i]);
                            self.lookup.tableRows[i].type = "inBetween";
                        }
                        duplicate = true;

                    }
                    //for lines that dont start in the interval but end in it
                    if (moment(endGl).isSameOrAfter(st) && moment(startGl).isSameOrBefore(st) && moment(endGl).isSameOrBefore(end) && duplicate == false) {
                        this.ganttLines.push(self.lookup.ganttLines[i]);
                        self.lookup.ganttLines[i].type = "endIn";
                        if (typeof self.lookup.tableRows[i] != typeof undefined) {
                            this.tableRows.push(self.lookup.tableRows[i]);
                            self.lookup.tableRows[i].type = "endIn";
                        }
                        duplicate = true;
                    }
                    //for lines that start in the interval but don't end in it
                    if (moment(startGl).isSameOrAfter(st) && moment(endGl).isSameOrAfter(end) && moment(startGl).isSameOrBefore(end) && duplicate == false) {
                        this.ganttLines.push(self.lookup.ganttLines[i]);
                        self.lookup.ganttLines[i].type = "startIn";
                        if (typeof self.lookup.tableRows[i] != typeof undefined) {
                            this.tableRows.push(self.lookup.tableRows[i]);
                            self.lookup.tableRows[i].type = "startIn";
                        }
                        duplicate = true;
                    }
                    //for lines that ocupy the whole interval
                    if (moment(startGl).isSameOrBefore(st) && moment(endGl).isSameOrAfter(end) && duplicate == false) {
                        this.ganttLines.push(self.lookup.ganttLines[i]);
                        self.lookup.ganttLines[i].type = "outside";
                        if (typeof self.lookup.tableRows[i] != typeof undefined) {
                            this.tableRows.push(self.lookup.tableRows[i]);
                            self.lookup.tableRows[i].type = "outside";

                        }
                        duplicate = true;
                    }
                }
            }

            var f = new Filter(start, end, criteria, timeline);

            if (f.criteria == 'date') {
                f.filterByDate();
            }
            self.filters.push(f);
            var parent = document.getElementById("svgganttchartDirectiveId");
            var div = document.getElementById("wrapperSVGGANTT");

            var newTimeline = {
                start: f.start,
                end: f.end
            }

            ////test
            /*
            parent.insertBefore(fBtn, div);
            fBtn.innerHTML = "Add Filter";
            fBtn.addEventListener("click", function () {
                
                self.redrawGantt(f.ganttLines, f.partialGanttLinesStart, f.partialGanttLinesEnd, f.wholeGanttLines, newGanttTableRows, newTimeline, view);
                self.lookup.currentViewID = "1";


            });*/
            self.redrawGantt(f.ganttLines, f.tableRows, newTimeline, view, f.width);
        }
        else {
            Message.error("Please choose a date");
        }
    };

    GanttModel.prototype.redrawGantt = function (newGanttLines, newGanttTableRows, newTimeline, view, filterWidth) {
        var self = this;

        self.lookup.currentViewID = view;
        var hiddenUnitsOfTime = [];

        var tempArray = []//for dependecy check
        var tempGanttArray = [];

        var newY = 50;
        for (var i = 0; i < newGanttLines.length; i++) {
            newY += 40;
        }

        self.updateContainerHeight(newY, document.getElementById("lineID"));
        self.updateContainerHeight(newY, document.getElementById("tableID"));
        self.updateSvgContainerHeight(newY, document.getElementById("svgContainerLINE"));
        self.updateSvgContainerHeight(newY, document.getElementById("svgContainerTABLE"));

        this.tbrCount = 0;
        this.glCount = 0;
        this.ganttLineStartY = 0;
        this.ganttLineStartX = 0;
        self.currentDate = {
            x: null,
        }//reset current date
        var self = this;
        var timeline2 = {
            type: ['day', 'week', 'month', 'year'],
            start: newTimeline.start,
            end: newTimeline.end
        };

        var timelineCont = document.getElementById("timelineContainer");
        var tableRowCont = document.getElementById("ganttTableRows");
        var lineCont = document.getElementById("ganttLineContainer");

        //hide existing elements
        for (var i = 0; i < timelineCont.childNodes.length; i++) {
            try {
                timelineCont.children[i -1].style.visibility = "hidden";
            } catch(e){}                
        }
        for (var i = 0; i < tableRowCont.childNodes.length; i++) {
            try {
                tableRowCont.children[i - 1].style.visibility = "hidden";
            } catch (e) {
                // console.log(e);
            }
        }
        for (var i = 0; i < lineCont.childNodes.length; i++) {
            try {
                lineCont.children[i - 1].style.visibility = "hidden";
            } catch (e){ }
        }
/*          If you want to delete all html
        while (timelineCont.firstChild && !timelineCont.firstChild.remove());

        while (lineCont.firstChild && !lineCont.firstChild.remove());

        for (var i = 1; i < tableRowCont.children.length; i++) {
            while (tableRowCont.children[i].firstChild && !tableRowCont.children[i].firstChild.remove());
        }
*/
        var xmlns = "http://www.w3.org/2000/svg";


        var s = moment(newTimeline.start, "MM-DD-YYYY");
        var e = moment(newTimeline.end, "MM-DD-YYYY");
        var count = e.diff(s, 'days');
        if (count == 0) {
            count = 1;
        }
        var pxUnit = document.getElementById("svgContainerTIME").width.baseVal.value / count;
        var gridUnit = "day";

        var timeGFliter = document.createElementNS("http://www.w3.org/2000/svg", "g");
        timeGFliter.setAttributeNS(null, "id", "timelineId" + view);
        timeGFliter.setAttributeNS(null, "svgView", view);
        timelineCont.appendChild(timeGFliter);

        self.drawTimeline(timeline2, 18, 'Arial', document.getElementById("timelineContainer"), pxUnit, hiddenUnitsOfTime, timeGFliter, view);
        self.drawGrid(gridUnit, self.lookup.timelines[self.lookup.timelines.length - 1].daysCoord, self.lookup.timelines[self.lookup.timelines.length - 1].weekEndsCoord,
            self.lookup.timelines[self.lookup.timelines.length - 1].monthEndsCoord, self.lookup.timelines[self.lookup.timelines.length - 1].yearEndsCoord, view);

        var gTB = document.createElementNS(xmlns, "g");

        document.getElementById("ganttTableRows").appendChild(gTB);


        gTB.setAttributeNS(null, "id", "parentRowLineFilter");
        gTB.setAttributeNS(null, "class", "parentRowSVGgantt");
        gTB.setAttributeNS(null, "svgView", view);

        //for (var i = 0; i < newGanttTableRows.length; i++) {
        //    self.drawGanttTableRow(newGanttTableRows[i].fontSize, newGanttTableRows[i].font, newGanttTableRows[i].information, true, gTB, false, false);
        //    this.ganttLineStartY += 20;//to account for missing +20 from gantt lines
        // }
        //reset coords for lines
        this.tbrCount = 0;
        this.glCount = 0;
        this.ganttLineStartY = 0;
        this.ganttLineStartX = 0;

        var gL = document.createElementNS(xmlns, "g");

        document.getElementById("ganttLineContainer").appendChild(gL);

        gL.setAttributeNS(null, "id", "parentRowLineFilter");
        gL.setAttributeNS(null, "class", "parentRowSVGgantt");
        gL.setAttributeNS(null, "svgView", view);

        
        for (var i = 0; i < newGanttLines.length; i++) {
            if (newGanttLines[i].type == "inBetween") {
                self.drawGanttTableRow(newGanttTableRows[i].fontSize, newGanttTableRows[i].font, newGanttTableRows[i].information, true, gTB, false, false);

                self.drawGanttLine(newGanttLines[i].start, newGanttLines[i].end, newGanttLines[i].height,
                    newGanttLines[i].originalColor, newGanttLines[i].completionPercentage,
                    newGanttLines[i].information, true, gL, false, self.lookup.timelines[self.lookup.timelines.length - 1],
                    newGanttLines[i].dependency, newGanttLines[i].id, pxUnit);
                tempGanttArray.push(self._gtl);
            }
            if (newGanttLines[i].type == "endIn") {
                if ((moment(newGanttLines[i].end).isSame(moment(timeline2.start, "DD-MM-YYYY"), 'day'))) {
                    //pass
                }
                else {
                    self.drawGanttTableRow(newGanttTableRows[i].fontSize, newGanttTableRows[i].font, newGanttTableRows[i].information, true, gTB, false, false);

                    self.drawGanttLine(newGanttLines[i].start, newGanttLines[i].end, newGanttLines[i].height,
                        newGanttLines[i].originalColor, newGanttLines[i].completionPercentage,
                        newGanttLines[i].information, true, gL, false, self.lookup.timelines[self.lookup.timelines.length - 1],
                        newGanttLines[i].dependency, newGanttLines[i].id, pxUnit);
                    tempGanttArray.push(self._gtl);
                }
                
            }
            if (newGanttLines[i].type == "startIn") {

                self.drawGanttTableRow(newGanttTableRows[i].fontSize, newGanttTableRows[i].font, newGanttTableRows[i].information, true, gTB, false, false);

                self.drawGanttLine(newGanttLines[i].start, newGanttLines[i].end, newGanttLines[i].height,
                    newGanttLines[i].originalColor, newGanttLines[i].completionPercentage,
                    newGanttLines[i].information, true, gL, false, self.lookup.timelines[self.lookup.timelines.length - 1],
                    newGanttLines[i].dependency, newGanttLines[i].id, pxUnit);
                tempGanttArray.push(self._gtl);
            }
            if (newGanttLines[i].type == "outside") {

                self.drawGanttTableRow(newGanttTableRows[i].fontSize, newGanttTableRows[i].font, newGanttTableRows[i].information, true, gTB, false, false);
                self.drawGanttLine(newTimeline[i].start, newTimeline[i].end, newGanttLines[i].height,
                    newGanttLines[i].originalColor, newGanttLines[i].completionPercentage,
                    newGanttLines[i].information, true, gL, false, self.lookup.timelines[self.lookup.timelines.length - 1],
                    newGanttLines[i].dependency, newGanttLines[i].id, pxUnit);
            }
            
            
        }
        
        //draw dependencies
        for (var i = 0; i < tempGanttArray.length; i++) {
            if (tempGanttArray[i].dependency) {
                try {
                    self.drawDependency(tempGanttArray[i - 1].html.children[0], tempGanttArray[i].html.children[0], false, gL);

                } catch (e) {

                }
            }
        }

        var endBorderForTimeline = self.makeSVG('path', {
            "d": "M " + (svgContainerTIME.width.baseVal.value - 1).toString() + " " +
            (self.lookup.timelines[self.lookup.timelines.length - 1].lastVisibileLineY - 40).toString() + " L " +
            (svgContainerTIME.width.baseVal.value - 1).toString() + " " + (svgContainerTIME.height.baseVal.value).toString(),
            "stroke-width": "1", "stroke": "#e0e0e0"
        });
        var tmlcont = document.getElementById("timelineId"+view);
        tmlcont.appendChild(endBorderForTimeline);

    };

    GanttModel.prototype.createGantt = function (ganttData, timeline) {
        try {
            var self = this;

            var ganttLines = [];
            var group = {};
            var hiddenUnitsOfTime = [];
            var gridUnit = "month";
            var newY = 50;
            //calculate svg and container height
            for (var i = 0; i < ganttData.length; i++) {
                newY += 40;
                //for (var j = 0; j < ganttData.series[i].data.length; j++) {
                //    newY += 40;
                //}
            }

            self.updateContainerHeight(newY, document.getElementById("lineID"));
            self.updateContainerHeight(newY, document.getElementById("tableID"));
            self.updateSvgContainerHeight(newY, document.getElementById("svgContainerLINE"));
            self.updateSvgContainerHeight(newY, document.getElementById("svgContainerTABLE"));

            var timeG = document.createElementNS("http://www.w3.org/2000/svg", "g");
            timeG.setAttributeNS(null, "id", "timelineId");
            timeG.setAttributeNS(null, "svgView", "0");

            self.drawTimeline(timeline, 18, 'Arial', document.getElementById("timelineContainer"), 5, hiddenUnitsOfTime, timeG, "0");
            self.drawGrid(gridUnit, self.lookup.timelines[0].daysCoord, self.lookup.timelines[0].weekEndsCoord,
                self.lookup.timelines[0].monthEndsCoord, self.lookup.timelines[0].yearEndsCoord, "0");
            //tables and lines

            // var tb = self.makeSVG("path", {
            //     "d": "M 0" + " " +
            //     (self.ganttLineStartY + self.tbrCount).toString() + " L " +
            //     (self.ganttWidth).toString() + " " + (self.ganttLineStartY + self.tbrCount).toString(),
            //    "stroke-width": "1", "stroke": "#e0e0e0"
            // });

            //document.getElementById("ganttTableRows").appendChild(tb);
            for (var i = 0; i < ganttData.length; i++) {
                try {
                    //draw tables and lines
                    var xmlns = "http://www.w3.org/2000/svg";
                    var gTB = document.createElementNS(xmlns, "g");
                    var gL = document.createElementNS(xmlns, "g");

                    document.getElementById("ganttTableRows").appendChild(gTB);
                    document.getElementById("ganttLineContainer").appendChild(gL);

                    gTB.setAttributeNS(null, "id", "parentRow" + i);
                    gTB.setAttributeNS(null, "class", "parentRowSVGgantt");
                    gTB.setAttributeNS(null, "svgView", "0");

                    gL.setAttributeNS(null, "id", "parentRowLine" + i);
                    gL.setAttributeNS(null, "class", "parentRowSVGgantt");
                    gL.setAttributeNS(null, "svgView", "0");

                    //parent table
                    self.drawGanttTableRow(18, 'Arial', ganttData[i].name, false, gTB, true, true);//tbr


                    var secondPath = self.ganttLineStartY + self.tbrCount + 20 + 40;
                    var firstPath = self.ganttLineStartY + self.tbrCount + 20;
                    self.lookup.childrenRows.push(firstPath);
                    self.lookup.childrenRows.push(secondPath);//TO DO: watch for multiple children
                    //parent line
                    self.drawGanttLine(ganttData[i].start, ganttData[i].end, 25, '#4daf50', 100, ganttData[i].name, true,
                        gL, true, self.lookup.timelines[0], null, null, 5);//gl

                    //self.addOnHover(self.lookup.ganttLines[self.lookup.ganttLines.length - 1].html, ganttData.series[i].name);
                    /*for (var j = 0; j < ganttData.series[i].data.length; j++) {
                        //child table
                        self.drawGanttTableRow(18, 'Arial', ganttData.series[i].data[j].name, true, gTB, false, true);//tbrCh

                        //child line
                        self.drawGanttLine(ganttData.series[i].data[j].start, ganttData.series[i].data[j].end,
                                            25, '#ff0000', 66, ganttData.series[i].data[j].name, true, gL, true, self.lookup.timelines[0],
                                            ganttData.series[i].data[j].dependency, ganttData.series[i].data[j].id, 5);//glCh

                        self.addOnHover(self.lookup.ganttLines[self.lookup.ganttLines.length - 1].html, ganttData.series[i].data[j].name);
                        //calculate dependencies
                        if (ganttData.series[i].data[j].dependency) {
                            self.ganttDep.push(self.lookup.lastGanttLine);
                        }
                    }*/ //no children

                }
                catch (e) {
                    console.log(e);
                }
                //add collapse event on click
                group[i] = document.getElementById("parentRow" + i);

                //self.addCollapse(group[i]);
            };
            //self.drawDependencies();
        } catch (e) {
            console.error(e);
        }

    };

    GanttModel.prototype.drawGantt = function () {
        try {
            var self = this;

            self.parseData(self.ganttData);

            self.createGantt(self.ganttSeries, self.timeline);

            //pseudo-borders
            var svgContainerTABLE = document.getElementById("svgContainerTABLE");
            var svgContainerTIME = document.getElementById("svgContainerTIME");
            var svgContainerLINE = document.getElementById("svgContainerLINE");

            var endBorderForTable = self.makeSVG('path', {
                "d": "M " + (299).toString() + " " +
                (0).toString() + " L " +
                (299).toString() + " " + (650).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            svgContainerTABLE.appendChild(endBorderForTable);

            var startBorderForTable = self.makeSVG('path', {
                "d": "M " + (1).toString() + " " +
                (50).toString() + " L " +
                (1).toString() + " " + (svgContainerTABLE.height.baseVal.value).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            //svgContainerTABLE.appendChild(startBorderForTable);

            var startBorderForTimeline = self.makeSVG('path', {
                "d": "M " + (1).toString() + " " +
                (self.lookup.timelines[0].lastVisibileLineY).toString() + " L " +
                (1).toString() + " " + (svgContainerTIME.width.baseVal.value).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            svgContainerTIME.appendChild(startBorderForTimeline);

            var endBorderForTimeline = self.makeSVG('path', {
                "d": "M " + (svgContainerTIME.width.baseVal.value - 1).toString() + " " +
                (self.lookup.timelines[0].lastVisibileLineY).toString() + " L " +
                (svgContainerTIME.width.baseVal.value - 1).toString() + " " + (svgContainerTIME.height.baseVal.value).toString(),
                "stroke-width": "1", "stroke": "#e0e0e0"
            });
            var tmlcont = document.getElementById("timelineId");
            tmlcont.appendChild(endBorderForTimeline);
        } catch (e) {
            console.error(e);
        }

        
    };  

    GanttModel.prototype.drawDependencies = function () {
        var self = this;
        var tempArr = [];
        var gtlContainer = document.getElementById("ganttLineContainer");
        try {
            for (var i = 0; i < gtlContainer.children.length; i++) {
                if (gtlContainer.children[i].classList[0] == "parentRowSVGgantt") {
                    for (var j = 0; j < gtlContainer.children[i].children.length; j++) {
                        var e = gtlContainer.children[i].children[j];
                        if (e.tagName == "g" && e.className.baseVal == "contentSvgTb") {
                            tempArr.push(e);
                        }
                    }
                }

            }
        } catch (e) { console.log(e); }
        
        for (var i = 0; i < self.ganttDep.length; i++) {
            for (var j = 0; j < tempArr.length; j++) {
                if (self.ganttDep[i] == tempArr[j].firstChild) {
                    self.drawDependency(tempArr[j - 1].firstChild, self.ganttDep[i], false, self.ganttDep[i].parentElement);
                }
            }
        }
    };

    GanttModel.prototype.parseData = function (data) {
        var self = this;
        for (var i = 0; i < data.length; i++) {
            try {
                    var ganttLine = {
                name: data[i].activityName,
                start: moment(data[i].startDate).format("MM-DD-YYYY"),
                end: moment(data[i].endDate).format("MM-DD-YYYY"),
                id: i
            }
            
            self.ganttSeries.push(ganttLine);
        
        //self.timeline.start = self.ganttSeries[0].start;
        //self.timeline.end = self.ganttSeries[self.ganttSeries.length - 1].end;
            } catch (e) {
                console.log(e);
            }
        }
    }

    return GanttModel;
});
