import { cipo } from 'cipo';

cipo.directive("datalistsettings", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "module": "=",
            "operations": "=",
            "noformulas": "=",
            "hasprint": "="

            },
            templateUrl: "/ng/views/directives/tabdetails/datalistsettings.html",
            controller: function ($scope, MODAL) {
               
                $scope.modalInfo = MODAL;
                $scope.openMenu = function ($mdMenu, ev) {
                    $mdMenu.open(ev);
                };
                // data list settings

            $scope.previewDataListMode = "desktopView";

            Object.defineProperty($scope, "hasFormulasInView", {
                get: function () {
                    if ($scope.previewDataListMode != 'desktopView') {
                        var hasFormulasInView = false;
                        var visibleOnDevice = $scope.previewDataListMode == 'tabletView' ? 'visibleOnTablet' : 'visibleOnPhone';
                        for (var i = 0; i < $scope.module.dataListConfig.bottomFormulas.length; i++) {
                            //previewDataListMode=='tabletView'&& op.visibleOnTablet
                            if ($scope.module.dataListConfig.bottomFormulas[i][visibleOnDevice]) {
                                hasFormulasInView = true;
                                break;
                            }
                        }
                    } else {
                        hasFormulasInView = true;
                    }

                    return hasFormulasInView;
                    
                }
            })

            $scope.changeView = function (view) {

                $scope.previewDataListMode = view;
                

            }



        }

    }
});
