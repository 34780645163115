<mat-drawer-container class="drawer-container">
    <mat-drawer [opened]="isMenuOpened()" mode="side" class="drawer-sidenav cipo-bg--primary-50">
        <app-menu
            #appmenu
            [menuItems]="menuItems"
            [activeMenuItemId]="activeMenuItemId"
            [menuFolderAutoCollapse]="menuFolderAutoCollapse"
            (onMenuItemClick)="menuItemClick($event)"></app-menu>
    </mat-drawer>

    <mat-drawer-content fxLayout="column">
        <app-header
            fxFlex="none"
            [menuOpened]="isMenuOpened()"
            [context]="context"
            [useOnlyContract]="useOnlyContract"
            [userId]="userId"
            [refresh]="refresh"
            (onMenuClick)="toggleMenu()"
            (onLogoClick)="logoClick()"
            (contextChange)="contextChange.emit($event)"
            (contextData)="contextData.emit($event)">
            <ng-content select="[header-content]" header-content></ng-content>
        </app-header>
        <div fxFlex="grow" class="overflow-y--scroll">
            <ng-content></ng-content>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
