import { DataListViewAction } from "../../models/module/main/dataListViewAction";

export const DemoCardsActions: DataListViewAction[] = [
  {
    name: 'Delete',
    actionType: 'delete',
    matIcon: 'delete',
    alwaysOnRow: true,
    condition: () => true,
    click: () => {},
  },
  {
    name: 'Edit',
    actionType: 'edit',
    matIcon: 'edit',
    alwaysOnRow: true,
    condition: () => true,
    click: () => {},
  },
  {
    name: 'Print',
    actionType: 'print',
    matIcon: 'print',
    alwaysOnRow: true,
    condition: () => true,
    click: () => {},
  }
];