<div class="cipo-bg--primary-200 p-a-sm">
    <h4>Field name</h4>
    <div fxLayout fxLayoutAlign="space-between center">
        <p>field label</p>
        @if (true) {
            <span class="on-form">On Form</span>
        }
    </div>
    <div fxLayout fxLayoutAlign="space-between center">
        <p>field type</p>
        <div fxLayout>
            <button mat-icon-button class="cipo-button icon-btn--md">
                <mat-icon class="material-icons-outlined cipo-icon cipo-icon--md">edit</mat-icon>
            </button>
            <button mat-icon-button class="cipo-button icon-btn--md">
                <mat-icon class="material-icons-outlined cipo-icon cipo-icon--md">delete</mat-icon>
            </button>
        </div>
    </div>
</div>
