import { cipo } from 'cipo';

cipo.controller('ManagePresetsController',
    function ($scope, module, $mdDialog, MODAL, URI, $q, Model, userService, Message) {
        $scope.module = module || {};
        $scope.userService = userService;
        
        $scope.modalInfo = MODAL;
        $scope.isModified = false;
        var presetListRef = [];
        var getPresetsLst = function () {
            $scope.loading = true;
            var p = $q.defer();
            var dataURL = URI.MODULE_PRESETS.CONTRACT_ALLOCATION_DICT;
            Model.request(dataURL.method, dataURL,
                { url: { contractId: userService.system.userdata.contractId}, urltype: 'obj' },
                { headers: { moduleId: $scope.module.moduleId } })

                .then(function (r) {
                    $scope.presetList = r;
                    presetListRef = [];
                    for (var i = 0; i < r.length; i++) {
                        if (r[i].isUsed) presetListRef.push(r[i].id);
                    }


                    p.resolve();
                }).catch(function (e) {

                    p.reject(e);
                }).finally(function () {
                    $scope.loading = false;
                })

            return p.promise;
        }

        getPresetsLst()
            .then(function () {
                Object.defineProperty($scope, 'isDirty', {
                    get: function () {
                        var isDirty = false;
                        
                        for (var i = 0; i < $scope.presetList.length; i++) {
                            if ($scope.presetList[i].isDirty) {
                                isDirty = true;
                                break;
                            }
                        }

                        return isDirty;
                    }
                });
            })

        $scope.syncPresetsLst = function (isClose) {
            $scope.loading = true;
            var p = $q.defer();
            var dataURL = URI.MODULE_PRESETS.SYNC;
            Model.request(dataURL.method, dataURL,
                { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj', body: $scope.presetList },
                { headers: { moduleId: $scope.module.moduleId } })

                .then(function (r) {
                    $scope.isModified = true;
                    for (var i = 0; i < $scope.presetList.length; i++) {
                        $scope.presetList[i].isDirty = false;
                    }
                    p.resolve();

                    if (isClose) $scope.close(true);

                }).catch(function (e) {                    
                    p.reject(e);
                    Message.dberror(e);
                }).finally(function () {
                    $scope.loading = false;
                })

            return p.promise;
        }

        $scope.toggleAllPresets = function (isSelect) {
            for (var i = 0; i < $scope.presetList.length; i++) {
                if (!$scope.presetList[i].isPicked) {
                    if (isSelect) {
                        if (!$scope.presetList[i].isUsed) {
                            $scope.presetList[i].isUsed = true;
                            $scope.presetList[i].isDirty = !$scope.presetList[i].isDirty;
                        }

                    } else {
                        if ($scope.presetList[i].isUsed) {
                            $scope.presetList[i].isUsed = false;
                            $scope.presetList[i].isDirty = !$scope.presetList[i].isDirty;
                        }

                    }
                }
                
            }
        }

        $scope.cancelAction = function () {
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;

            // $scope.close(true);
        }

        $scope.close = function (isWarning) {
            if (!$scope.isDirty || isWarning) {
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();

                return;
            }
            $scope.hightlight = true;
            $scope.tabWarning = true;
            $scope.closingModal = true;
        }


    });
