import { Component, signal, inject } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

import { FormBuilderModalComponent } from '../../../module/instance/form-builder-modal/form-builder-modal.component';
import { DIALOG_SIZE } from '../../../shared/consts';
import {
  CipoControl,
  CipoFieldTypes,
  CipoForm,
  CipoFormGroup,
  FormItem,
} from '../../../shared/components/fields/common';
import { GridFormComponent } from '../../../module/definition/form/grid-form/grid-form.component';
import { DemoFormBuilderService } from './demo-form-builder.service';
import { TableFieldValueType } from '../../../models/module/fields/main';

@Component({
  selector: 'app-demo-form-builder',
  templateUrl: './demo-form-builder.component.html',
  standalone: true,
  imports: [GridFormComponent, MatButtonToggleModule, FlexLayoutModule, MatButtonModule, FormBuilderModalComponent],
})
export class DemoFormBuilderComponent {
  dialog = inject(MatDialog);
  fb = inject(FormBuilder);
  demoService = inject(DemoFormBuilderService);

  items = signal<FormItem[]>([]);
  form: CipoForm = this.fb.group({});
  editMode = signal(true);
  constructor() {
    this.demoService.getFormData().subscribe(fields =>
      fields.forEach(item => {
        const control = new CipoControl(item.value, item.fieldOptions, [
          ...(item.fieldOptions.required ? [Validators.required] : []),
        ]);
        switch (item.fieldOptions.type) {
          case CipoFieldTypes.Table:
            this.populateTable(control);
            break;
          default:
            break;
        }
        this.form.addControl(item.id, control);
      }),
    );
  }

  populateTable(control: CipoControl) {
    this.demoService.getTableFields().subscribe(fields => {
      const values = (control.value as TableFieldValueType) || [];
      control.fieldData.tableFields = fields;
      const formArray = new FormArray<CipoFormGroup>([]);
      values.forEach(val => {
        const rowGroup = this.fb.group({});
        fields.forEach(field => {
          const { label, fieldDescription, ...props } = field;
          rowGroup.addControl(field.name, new CipoControl(val[field.name], { ...props, cleanReadOnly: true }));
        });
        formArray.push(rowGroup);
      });
      control.fieldData.tableForm = formArray;
    });
  }

  logItems() {
    console.log(this.form.value);
  }

  openBuilder() {
    this.dialog.open(FormBuilderModalComponent, {
      panelClass: ['cipo-dialog', 'classic'],
      ...DIALOG_SIZE.xl,
    });
  }
}
