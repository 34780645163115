import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("PaymentPeriod", function ($q, Model, URI, Message, Form, userService) {
    var PaymentPeriod = Model.extend(function (obj, contractId) {

        var self = this;

        self.editable = false;
        self.deleteConfirm = false;
        self.userService = userService;
        self.contractId = contractId || 0;

            self.properties = {
                id: null,
                name: 'none',
                startDate: null,
                endDate: null
            }

        if (obj) {
            
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && ['color', 'textColor', 'status'].indexOf(key) == -1) {
                    self.properties[key] = obj[key];
                }
            }

                self.properties.endDate = process_incomingDate(obj.endDate);
                self.properties.startDate = process_incomingDate(obj.startDate);
                
                self.properties.name = self.properties.startDate.format(self.userService.formats.date) + ' - ' + self.properties.endDate.format(self.userService.formats.date);
                self.properties.paymentInfo = {
                    color: obj.color || '#eaeaea',
                    textColor: obj.textColor || '#444',
                    status: obj.status || (self.properties.id && self.properties.existsProgressPayments ? 'In progress' : 'No payment')
                }
            }        

            if (!self.properties.id) {
                self.createForm();
            }

    });

    var process_incomingDate = function (date) {
        if (date) {
            if (date.indexOf('Z') == -1) {
                date = date + 'Z';
            }
            var momentDate = moment(date).utc();
            momentDate.set({ h: 12, m: 0 });
            //console.error('momentDate', date,  momentDate)
            return momentDate;
        }
        else return null;
    }

    PaymentPeriod.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        self.form.set_Description({
            // startDate: { label: 'Start date', type: 'datepicker', editMode: false, options: { format: userService.formats.date },
            endDate: { label: 'End date', type: 'datepicker', options: { format: userService.formats.date }, validation: { required: true } },
        });
        // accrual deduction radio --> adj type
        // recurring / 1 time --> recurrence

        // name, value, value type, adjust type, accrualFrequencyId, recurrence,
        // 

        self.form.setTemplate('grid', [
            { endDate: 100 },
        ]);
        self.form.initializing = false;
        self.form.store_Data();
    }

    PaymentPeriod.prototype.process_incomingDate = function (date) {
        if (date) {
            if (date.slice(1, -1) != 'Z') date = date + 'Z';
            var momentDate = moment(date).utc();
            momentDate.set({ h: 12, m: 0 });
            //console.error('momentDate', date,  momentDate)
            return momentDate;
        }
        else return null;
    }

    PaymentPeriod.prototype.save = function () {
        var self = this;
        var p = $q.defer();

        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            var temp = angular.copy(self.properties);

            var urlData = temp.id ? URI.PROGRESS_PAYMENT.UPDATE_PERIOD : URI.PROGRESS_PAYMENT.CREATE_NEW_PERIOD;
            temp.endDate = temp.endDate ? temp.endDate.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;

                self[urlData.method](urlData, { url: { contractId: self.contractId }, urltype: 'obj', body: temp })
                    .then(function (r) {
                        p.resolve();
                        if (r.message) {
                            Message.warning(r.message)
                        } else {
                            Message.info('Period saved successfully');
                        }
                    })
                    .catch(function (e) {
                        console.error(e);
                        self.form.catch(e);
                        p.reject(e);
                    })
                    .finally(function () {
                        (self.form || {}).loading = false;
                    });
            }
            else {
                self.form.loading = false;
                p.reject();
            }

        return p.promise;
    }

    PaymentPeriod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting period...";
        self.deleting = true;
        var urlData = URI.PROGRESS_PAYMENT.DELETE_PERIOD;
        self[urlData.method](urlData, { url: { contractId: self.contractId }, body: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                if (r.message) {
                    Message.warning(r.message)
                } else {
                    Message.info('Period deleted successfully');
                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete period. Try again?";
                p.reject(e);
                Message.dberror(e);
            })
        return p.promise;
    }

    return PaymentPeriod;
});
