<div mat-dialog-title class="cipo-bg--primary-900 cipo-text--white" fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "SCHEDULED_EMAILS." + (data ? "editScheduledEmail" : "addScheduledEmail") | translate }}</p>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="scrollable">
    <form [formGroup]="form" fxLayoutGap="12px" class="cipo-form cipo-form--sm p-t">
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="12px">
            <mat-form-field fxFlex class="p-0">
                <mat-label>{{ "SCHEDULED_EMAILS.formField.name" | translate }}</mat-label>
                <input matInput formControlName="jobName" />
            </mat-form-field>
            <mat-form-field fxFlex class="p-0">
                <mat-label>{{ "SCHEDULED_EMAILS.formField.description" | translate }}</mat-label>
                <input matInput formControlName="description" />
            </mat-form-field>
        </div>
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="12px">
            <mat-form-field fxFlex>
                <mat-label>
                    {{ "SCHEDULED_EMAILS.formField.frequency.label" | translate }}
                </mat-label>
                <mat-select formControlName="scheduleFrequency">
                    <mat-option *ngFor="let frequency of FREQUENCY" [value]="frequency.value">
                        {{ "SCHEDULED_EMAILS.formField.frequency." + frequency.key | translate }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="weekDay" class="cipo-text--accent-900">
                    {{ "SCHEDULED_EMAILS.formField.sendEvery" | translate }}
                    {{ "WEEK_DAYS." + WEEK_DAYS[weekDay] | translate }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex [owlDateTimeTrigger]="datePicker">
                <mat-label>{{ "SCHEDULED_EMAILS.formField.startDate" | translate }}</mat-label>
                <input matInput readonly [min]="minDate" [owlDateTime]="datePicker" formControlName="startDate" />
                <button mat-icon-button matSuffix [owlDateTimeTrigger]="datePicker">
                    <mat-icon>calendar_month</mat-icon>
                </button>
                <owl-date-time #datePicker pickerType="calendar"></owl-date-time>
            </mat-form-field>
            <mat-form-field fxFlex [owlDateTimeTrigger]="dt1">
                <mat-label>{{ "SCHEDULED_EMAILS.formField.time" | translate }}</mat-label>
                <input matInput readonly [owlDateTime]="dt1" formControlName="timeOfDay" />
                <button mat-icon-button matSuffix [owlDateTimeTrigger]="dt1">
                    <mat-icon>schedule</mat-icon>
                </button>
                <owl-date-time #dt1 pickerType="timer" [hour12Timer]="true"></owl-date-time>
            </mat-form-field>
        </div>
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="12px">
            <mat-form-field fxFlex>
                <mat-label>{{ "SCHEDULED_EMAILS.formField.modules" | translate }}</mat-label>
                <mat-select formControlName="moduleIds" multiple>
                    <mat-select-trigger>
                        @if (allModulesSelected) {
                            {{ "SCHEDULED_EMAILS.formField.allModules" | translate }}
                        } @else {
                            {{ form.value.moduleIds?.length ? modulesDict[form.value.moduleIds[0]] : "" }}
                            @if ((form.value.moduleIds?.length || 0) > 1) {
                                <span class="additional-selection">
                                    (+{{ (form.value.moduleIds?.length || 0) - 1 }}
                                    {{ form.value.moduleIds?.length === 2 ? "other" : "others" }})
                                </span>
                            }
                        }
                    </mat-select-trigger>
                    <div class="search-field full-width">
                        <mat-form-field class="cipo-input hide-subscript">
                            <input
                                matInput
                                [placeholder]="'SCHEDULED_EMAILS.formField.searchModule' | translate"
                                [formControl]="modulesSearchControl"
                                (keydown)="$event.stopPropagation()" />
                            @if (modulesSearchControl.value) {
                                <button mat-icon-button matSuffix (click)="modulesSearchControl.setValue('')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </div>
                    <mat-checkbox
                        class="m-x-xs"
                        [checked]="allModulesSelected"
                        [indeterminate]="form.value.moduleIds.length > 0 && !allModulesSelected"
                        (change)="toggleSelectAllModules($event.checked)">
                        {{ "SCHEDULED_EMAILS.formField.allModules" | translate }}
                    </mat-checkbox>
                    <mat-option *ngFor="let module of modulesFilteredOptions()" [value]="module.key">
                        {{ module.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>
                    {{ "SCHEDULED_EMAILS.formField.sendEmailTo.label" | translate }}
                </mat-label>
                <mat-select formControlName="sendTo" multiple>
                    <mat-option *ngFor="let receiver of SEND_MAIL_TO" [value]="receiver.value">
                        {{ "SCHEDULED_EMAILS.formField.sendEmailTo." + receiver.key | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>{{ "SCHEDULED_EMAILS.formField.onlySendTo" | translate }}</mat-label>
                <mat-select formControlName="additionalRecipients" multiple>
                    <div class="search-field full-width">
                        <mat-form-field class="cipo-input hide-subscript">
                            <input
                                matInput
                                [placeholder]="'SCHEDULED_EMAILS.formField.searchOnlySendTo' | translate"
                                [formControl]="additionalRecipientsSearchControl"
                                (keydown)="$event.stopPropagation()" />
                            @if (additionalRecipientsSearchControl.value) {
                                <button
                                    mat-icon-button
                                    matSuffix
                                    (click)="additionalRecipientsSearchControl.setValue('')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </div>
                    <mat-option *ngFor="let role of additionalRecipientsFilteredOptions()" [value]="role.key">
                        {{ role.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="m-b">
            <h3 class="m-a-0">{{ "SCHEDULED_EMAILS.summary.title" | translate }}</h3>
            <div class="cipo-bg--primary-100 p-a-md border-radius--xs">
                @if (form.value.scheduleFrequency == SCHEDULE_FREQUENCY_ENUM.MANUAL) {
                    <p [innerHTML]="'SCHEDULED_EMAILS.summary.manual' | translate"></p>
                } @else {
                    <p>
                        {{ "SCHEDULED_EMAILS.summary.prefix" | translate }}
                        <span
                            *ngIf="weekDay"
                            translate="SCHEDULED_EMAILS.summary.weekly"
                            [translateParams]="{
                                weekDay: 'WEEK_DAYS.' + WEEK_DAYS[weekDay] | translate
                            }"></span>
                        <span *ngIf="!weekDay">
                            {{ "SCHEDULED_EMAILS.summary.daily" | translate }}
                        </span>
                        <span
                            translate="SCHEDULED_EMAILS.summary.time"
                            [translateParams]="{
                                time: (form.value.timeOfDay | date: 'shortTime')
                            }"></span>
                    </p>
                }
                <p [innerHTML]="'SCHEDULED_EMAILS.summary.rule1' | translate"></p>
            </div>
        </div>

        <app-froala
            [content]="form.value.subject"
            [options]="froalaOptionsSubject"
            [tributeoptions]="tributeOptions()"
            [required]="true"
            [label]="'SCHEDULED_EMAILS.formField.emailSubject' | translate"
            (contentChange)="updateSubject($event)"></app-froala>
        <app-froala
            [content]="form.value.emailBody"
            [options]="froalaOptionsBody"
            [tributeoptions]="tributeOptions()"
            [required]="true"
            [label]="'SCHEDULED_EMAILS.formField.emailContent' | translate"
            (contentChange)="updateEmailBody($event)"></app-froala>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ "SCHEDULED_EMAILS.actions.close" | translate }}</button>
    @if (data) {
        <button mat-flat-button color="primary" (click)="saveChanges()" [disabled]="!form.valid || !form.dirty">
            {{ "SCHEDULED_EMAILS.actions.update" | translate }}
        </button>
    } @else {
        <button mat-flat-button color="primary" (click)="saveChanges()" [disabled]="!form.valid">
            {{ "SCHEDULED_EMAILS.actions.save" | translate }}
        </button>
    }
    @if (!data && form.value.scheduleFrequency === SCHEDULE_FREQUENCY_ENUM.MANUAL) {
        <button mat-flat-button color="primary" (click)="saveChanges(true)" [disabled]="!form.valid">
            {{ "SCHEDULED_EMAILS.actions.sendNowSave" | translate }}
        </button>
    }
</mat-dialog-actions>
