import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';

var reportsController = function ($scope, Model, $state, $timeout, Message, userService) {
    $scope.config = null;

    Object.defineProperty($scope, 'operations', {
        get: function () { return userService.getOperationsFor($state.current.code); }
    });

    Model.get(environment.baseApiUrl + '_api/Main/PowerBiGetReportConfig')
        .then(function (r) {
            $scope.config = r;
        })
        .catch(function (e) {
            Message.error(e);
            console.error('faild to run reports', e);
        });

    $scope.parent = {
        printReport: function () {
            var elementToPrint = $(document).find('#reportContainer')[0];
            var reportToPrint = powerbi.get(elementToPrint);

            reportToPrint.print()
                .catch(error => { Message.error('Cannot print report'); });
        }
    };
};

cipo.controller('reportsController', reportsController);
