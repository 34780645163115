import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@Component({
  selector: 'app-field-card',
  standalone: true,
  imports: [FlexLayoutModule, MatButtonModule, MatIconModule],
  templateUrl: './field-card.component.html',
  styleUrl: './field-card.component.scss',
})
export class FieldCardComponent {}
