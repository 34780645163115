import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EITransactionInstanceModel } from '../../../../models/module/entityInstance';
import { ActivityListService } from './activity-list.service';

@Component({
  selector: 'app-entity-instance-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent {
  @Input('moduleid') moduleId: number;
  @Input('entityinstanceid') entityInstanceId: number;
  @Input('contractid') contractId: number;

  @Input() set reload(_: any) {
    this.getData();
  }

  @Output('activitiesloading') activitiesLoading = new EventEmitter<boolean>();
  @Output('activitiesloaded') activitiesLoaded = new EventEmitter<EITransactionInstanceModel[]>();

  private defaultPageSize = 30;

  isLoading = true;
  actionInstances: EITransactionInstanceModel[] = [];
  actionInstancesCount = 0;
  actionInstancesPageSize = this.defaultPageSize;

  constructor(private activityListService: ActivityListService) {}

  getData(): void {
    this.loading(true);

    this.activityListService.getActivityList(this.moduleId, this.entityInstanceId, this.contractId).subscribe(x => {
      this.actionInstances = x?.actionInstances || [];
      this.actionInstancesCount = this.actionInstances.length;
      this.page();
    });
  }

  page(ev?: PageEvent) {
    const pageIndex = ev?.pageIndex || 0;
    this.actionInstancesPageSize = ev?.pageSize || this.defaultPageSize;
    const start = pageIndex * this.actionInstancesPageSize;
    const end = start + this.actionInstancesPageSize;

    this.loading(false);
    this.activitiesLoaded.emit(this.actionInstances.slice(start, end));
  }

  loading(value: boolean) {
    this.isLoading = value;
    this.activitiesLoading.emit(value);
  }
}
