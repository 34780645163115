import { cipo } from 'cipo';

cipo.controller('linkToCorrespondenceController',
    function ($scope, $mdDialog, ModuleDictionaries, moduleId, isLinkedToCorrespondence, linkDefinition, URI, Message, Model) {

        var isModified = false;
        $scope.loadingData = true;

            $scope.isLinkedToCorrespondence = isLinkedToCorrespondence;
            $scope.linkDefinitionModel = linkDefinition || { id: 0, moduleId: moduleId, screenId: 0, stateId: 0, mapping: [], hasApprroval: false };
            $scope.moduleFieldsFilterFieldsByType = {};

            ModuleDictionaries.init({ moduleId: moduleId, screenId: $scope.linkDefinitionModel.screenId });

        ModuleDictionaries.get_dataSource("statesOpen")
            .then(function () {
                $scope.moduleStates = ModuleDictionaries.dataSources.statesOpen.data;
            })

            ModuleDictionaries.get_dataSource("startScreens")
                .then(function () {
                    $scope.screens = ModuleDictionaries.dataSources.startScreens.data;

                    // We have a defined link, so we need to get fields for current screen
                    if ($scope.isLinkedToCorrespondence) {

                        // Get fields for this screen
                        $scope.getFields($scope.linkDefinitionModel.screenId);
                    }
                    // If we have only one screen, then just return fields for this current screen
                    else if ($scope.screens.length == 1) {
                        $scope.linkDefinitionModel.screenId = $scope.screens[0].key;

                        // Get fields for this screen
                        $scope.getFields($scope.linkDefinitionModel.screenId);
                    }
                    else {
                        $scope.loadingData = false;
                    }
                })
                .catch(function () {
                    $scope.loadingData = false;
                })

        $scope.closeModal = function () {
            ModuleDictionaries = null;
            if (isModified) $mdDialog.hide();
            else $mdDialog.cancel();
        };

            $scope.filterFieldsByTypeKey = function (type, field) {
                return `${type}-${field.displayTypeId}`;
            }
            $scope.filterFieldsByType = function (type, field) {
                var key = $scope.filterFieldsByTypeKey(type, field);
                
                if ($scope.moduleFields && type) {
                    var filter = $scope.moduleFieldsFilterFieldsByType[key] || [];
                    var copyModuleFields = [];

                for (var i = 0; i < $scope.moduleFields.length; i++) {
                    // Filter by type
                    if ($scope.moduleFields[i].typeId == type && (typeof (field.displayTypeId) == 'undefined' || (field.displayTypeId == 9 && $scope.moduleFields[i].displayTypeId == field.displayTypeId) || field.displayTypeId != 9))
                        copyModuleFields.push($scope.moduleFields[i]);
                }

                    var sameContent = filter.length == copyModuleFields.length 
                        && filter.every(f => copyModuleFields.includes(f));
                    if (!sameContent) {
                        $scope.moduleFieldsFilterFieldsByType[key] = copyModuleFields;
                    }
                }
                if (!$scope.moduleFieldsFilterFieldsByType[key]) {
                    $scope.moduleFieldsFilterFieldsByType[key] = [];
                }

                return $scope.moduleFieldsFilterFieldsByType[key];
            };

            $scope.getFields = function (screenId, clearMapping) {

                // Clear old data
                $scope.moduleFields = [];
                $scope.fieldsForImport = [];
                // We need to remove mappings only if we change the screen
                if (clearMapping)
                    $scope.linkDefinitionModel.mapping = [];
                // Set loading data bit
                $scope.loadingData = true;

                ModuleDictionaries.init({ moduleId: moduleId, screenId: screenId });

                ModuleDictionaries.get_dataSource("moduleFieldsForCorrespondence")
                    .then(function () {
                        $scope.moduleFields = ModuleDictionaries.dataSources.moduleFieldsForCorrespondence.data;

                        ModuleDictionaries.get_dataSource("correspondenceFields")
                            .then(function () {
                                $scope.fieldsForImport = ModuleDictionaries.dataSources.correspondenceFields.data;

                                // Set mapping
                                for (var i = 0; i < $scope.fieldsForImport.length; i++) {
                                    // Check if we have any mappings
                                    if ($scope.linkDefinitionModel.mapping.length > 0) {
                                        // Get mapping for this correspondence field
                                        var map = $scope.linkDefinitionModel.mapping.filter(e => e.correspondenceFieldId == $scope.fieldsForImport[i].key);
                                        // Check if we have a mapping for this correspondence field
                                        if (map && map.length > 0) {
                                            // Set fieldId linked to this correspondence field
                                            $scope.fieldsForImport[i].fieldId = map[0].fieldId;
                                        }
                                    }
                                }
                            })
                            .finally(function () {
                                $scope.loadingData = false;
                            })
                    })
                    .catch(function () {
                        $scope.loadingData = false;
                    })
            }

            $scope.linkToCorrespondence = function () {

            var urlData = $scope.linkDefinitionModel.id ? URI.MODULE_LINK_CORRESPONDENCE.UPDATE : URI.MODULE_LINK_CORRESPONDENCE.CREATE;

            $scope.linkDefinitionModel.mapping = [];

            // Check if state is selected
            if (!$scope.linkDefinitionModel.stateId) {
                Message.warning("Please select in which state the document will be created.");
                return;
            }

            var isValid = false;

            for (var i = 0; i < $scope.fieldsForImport.length; i++) {
                if ($scope.fieldsForImport[i].fieldId) {
                    isValid = true;
                    if (!$scope.linkDefinitionModel.mapping.some(function (item) { return item.fieldId == $scope.fieldsForImport[i].fieldId }))
                        $scope.linkDefinitionModel.mapping.push({ correspondenceFieldId: $scope.fieldsForImport[i].key, fieldId: $scope.fieldsForImport[i].fieldId });
                    else {
                        var sameField = $scope.moduleFields.filter(e => e.key == $scope.fieldsForImport[i].fieldId);
                        Message.warning("Field: " + (sameField && sameField.length > 0 ? sameField[0].value : $scope.fieldsForImport[i].fieldId) + " is mapped in multiple E-mail fields.");
                        return;
                    }
                }
            }

            // Check if we have at least one field selected
            if (isValid) {
                Model[urlData.method](urlData, { body: $scope.linkDefinitionModel }, { headers: { moduleId: moduleId } })
                    .then(function (r) {
                        Message.info('Link with E-mail ' + ($scope.isLinkedToCorrespondence ? 'modified' : 'created') + ' successfully.');

                        isModified = true;
                        $scope.closeModal();
                    })
                    .catch(function (e) { Message.dberror(e); })
            }
            else
                Message.warning("Please select at least one field.");
        };

        var confirm = function (title, text, isMultiple) {
            return $mdDialog.confirm({
                title: title,
                textContent: text,
                ariaLabel: 'Confirm Dialogue',
                ok: 'Proceed',
                cancel: 'Cancel',
                multiple: isMultiple || false
            });
        }

        $scope.removeMapping = function () {

            $mdDialog.show(confirm('Remove Mapping', `Are you sure you want to remove mapping with E-mail ?`, true))
                .then(function () {
                    var urlData = URI.MODULE_LINK_CORRESPONDENCE.DELETE;
                    Model[urlData.method](urlData, { url: { id: $scope.linkDefinitionModel.id }, urltype: 'obj' }, { headers: { moduleId: moduleId } })
                        .then(function (r) {
                            Message.info('Link with E-mail removed successfully.');

                            isModified = true;
                            $scope.closeModal();
                        })
                        .catch(function (e) { Message.dberror(e); })
                });
        };
    });
