import { cipo } from 'cipo';

cipo.factory("WorkflowGroupMod", function (Model, Form, $q, URI, Message) {
    var WorkflowGroupMod = Model.extend(function (obj, moduleId) {
        var self = this;

        // Initialize properties
        self.properties = {
            id: null,
            name: "",
            description: "",
            rootModuleId: moduleId,
            isDefault: false
        };

        // Set properties
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }

        if (!self.properties.id)
            self.createForm();

        self.urls = {
            create: function () {
                return URI.WORKFLOW_GROUPS.CREATE
            },
            edit: function () {
                return URI.WORKFLOW_GROUPS.UPDATE
            },
            delete: function () { return URI.WORKFLOW_GROUPS.DELETE }
        };
    });

    WorkflowGroupMod.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        var formFields = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
            description: { label: 'Description', type: 'textarea' },
        }

        self.form.set_Description(formFields);
        self.form.setTemplate('grid', [
            { name: 50, description: 50 }
        ]);
        self.form.initializing = false;
        self.form.store_Data();
    }

    WorkflowGroupMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var valid = true;
        if (self.form) {
            self.form.validate();
            valid = self.form.isValid;
        }
        if (valid) {
            if (self.form) self.form.loading = true;
            var dataURL = self.properties.id ? self.urls.edit() : self.urls.create();
            var toSend = angular.copy(self.properties);
            self[dataURL.method](dataURL, { url: {}, urltype: 'obj', body: toSend })
                .then(function (r) {
                    Message.info('Workflow group saved successfully');
                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    if (self.form) {
                        self.form.loading = false;
                        self.form.catch(e);
                    }
                    else Message.dberror(e);
                })
        } else {
            p.reject();
        }

        return p.promise;
    }

    WorkflowGroupMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting item...";
        self.deleting = true;

        self[self.urls.delete(self).method](self.urls.delete(), { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function () {
                Message.info('Workflow group deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete workflow group. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }

    return WorkflowGroupMod;
});
