import { cipo } from 'cipo';

cipo.factory("List", function ($q, Model, URI, Form, Name, Message, Permissions, ListElements) {
    var List = Model.extend(function (obj, isDetailsLoaded) {

        var self = this;
        // self.isModuleConfig = isModuleConfig;
        self.isList = true;
        self.relationsList = [];
        self.init(false, obj ? false : true);
        if (obj && Object.prototype.toString.call(obj) == '[object Object]') {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        } else if (obj) {
            self.properties.id = obj;
        }
        self.backup = {
            name: self.properties.name,
            sortAlphabetically: self.properties.sortAlphabetically
        };
        self.sortableOptions = {
            handle: '> span>.listHandle',
            'ui-floating': true
        }
        self.createForm();
        if (typeof (self.properties.id) != 'undefined' && !isDetailsLoaded) self.get_Details();

    });

    List.prototype.init = function (isAdd, isNew) {
        var self = this;
        self.properties = {
            elements: [],
            name: "",
            allowModify: true,
            allowReorder: true,
            sortAlphabetically: false,
            useElementId: false,
            permissions: isAdd || isNew ? [1, 2] : [2]
        };

        if (isNew || isAdd) {

            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    }

    List.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        if (self.properties.id) self.form.loading = true;
        //create form
        var form = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
            sortAlphabetically: { label: 'Sort items alphabetically', type: 'checkbox' },
            useElementId: { label: 'Use code', type: 'checkbox' },
            //elements: { label: 'Options', type: 'chips', optionsValueProperty: 'name', optionsKeyProperty: "id", validation: { required: true } }
        };
        var grid = [
            { name: 100 }
            //{ elements: 100 }
        ];
        //if (self.isModuleConfig) {
        //    form.inUse = { label: 'Use on current field', type: 'checkbox', editMode: true };
        //    grid.push({ sortAlphabetically: 50, useElementId: 25, inUse: 25 });
        //} else {
        //    grid.push({ sortAlphabetically: 70, useElementId: 30 });
        //}

        grid.push({ sortAlphabetically: 70, useElementId: 30 });
        
        self.form.set_Description(form);

        self.form.setTemplate('grid', grid);
        self.form.fieldsList.useElementId.onChange = function (field) {
            if (self.properties.id) self.toggleElementId();
        } 
        self.form.store_Data();
        self.form.initializing = false;
    }
    List.prototype.toggleElementId = function () {
        var self = this;
        var dataURL = URI.LISTS.TOGGLE_USE_ELEMENT_ID;
        self.isBusy = true;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
            })
            .catch(function (e) {
                console.error(e);
                //p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
    }
    List.prototype.editElement = function (e) {
        var self = this;
        self.isBusy = true;
        if ((e || {}).id) {
            e.editElement = new Name(e);
            if (self.properties.useElementId) {
                e.editElement.form.set_Description({ elementId: { label: 'Code', type: 'text', validation: { required: true, maxChars: 20 } } }, true);
                e.editElement.form.setTemplate('grid', [
                    { elementId: 30, name: 70 }
                    //{ elements: 100 }
                ]);
            }
                
            e.editElement.form.onSubmitForm = function () {
                self.updateElement(e);
            }
        }
        else {
            var newElem = { name: "", elementId: "" }
            if (e) {                    
                for (var key in e) if (e.hasOwnProperty(key)) newElem[key] = e[key];
            }
            self.newElement = new Name(newElem);
            if (self.properties.useElementId) {
                self.newElement.form.set_Description({ elementId: { label: 'Code', type: 'text', validation: { required: true, maxChars: 20 } } }, true);
                self.newElement.form.setTemplate('grid', [
                    { elementId: 30, name: 70 }
                    //{ elements: 100 }
                ]);
            }
                
        } 

    }
    List.prototype.orderMode = function () {
        var self = this;
        self.isBusy = true;
        self.isOrderMode = true;
        self.elemsBackup = angular.copy(self.properties.elements);
    }
    List.prototype.cancelOrder = function () {
        var self = this;
        self.isBusy = false;
        self.isOrderMode = false;
        self.properties.elements = angular.copy(self.elemsBackup);
    }

    List.prototype.updateOrder = function () {
        var self = this;
        self.isUpdatingOrder = true;
        for (var i = 0; i < self.properties.elements.length; i++) {
            self.properties.elements[i].order = i+1;
        }
        var dataURL = URI.LISTS.SYNC_ELEMENTS;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj', body: self.properties.elements })
            .then(function (r) {
                self.getElements();
                self.isOrderMode = false;
                Message.info("Order updated successfully.");
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                //p.reject(e);
            })
            .finally(function () {
                self.isUpdatingOrder = false;
            })
    }
    List.prototype.cancelEditElement = function (e) {
        var self = this;
        self.isBusy = false;
        if (e) {
            e.editElement = null;
            e.deleting = false;
        }
        else self.newElement = null;
    }
    List.prototype.updateElement = function (e) {
        var self = this;
        var p = $q.defer();
        var form = (e || {}).id ? e.editElement.form : self.newElement.form;
        var elem = (e || {}).id ? e.editElement.properties : self.newElement.properties;
        form.validate();
        form.loading = true;
        var dataURL = (e || {}).id ? URI.LISTS.UPDATE_ELEMENT : ((elem || {}).parentElementId ? URI.LISTS.CREATE_ELEMENT_WITH_PARENT : URI.LISTS.CREATE_ELEMENT);
        var urlParams = { listId: self.properties.id };
        if (form.isValid) {
            self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: elem })
                .then(function (r) {
                    self.isBusy = false;
                    
                    if (self.newElement) {
                        elem.id = r;
                        self.properties.elements.push(elem);
                        p.resolve(elem);
                        self.newElement = null;

                    }
                    else {
                        e.name = elem.name;
                        e.elementId = elem.elementId;
                        e.editElement = null;
                        p.resolve();
                    }

                    self.getElements();
                    
                    Message.info("Item updated successfully.");
                })
                .catch(function (e) {
                    console.error(e);
                    form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    form.loading = false;
                })
        }
        else {
            form.loading = false;
            p.reject();
        }

        return p.promise;
        
    }
    List.prototype.deleteElementConfirm = function (e) {
        var self = this;
        self.isBusy = true;
        e.deleting = true;
        
    }

    List.prototype.deleteElement = function (e) {
        var self = this;
        self.isBusy = true;
        e.isDeleting = true;
        var dataURL = URI.LISTS.DELETE_ELEMENT;
        self[dataURL.method](dataURL, { url: { id: e.id, listId: self.properties.id}, urltype: 'obj' })
            .then(function (r) {
                self.isBusy = false;
                self.properties.elements.splice(self.properties.elements.indexOf(e), 1);
                self.getElements();
                Message.info("Item deleted successfully.");
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                //p.reject(e);
            })
            .finally(function () {
                e.isDeleting = false;
            })
        
    }
    List.prototype.getElements = function () {
        var self = this;
        self.isBusy = true;
        var p = $q.defer();
        var urlData = URI.LISTS.GET_ELEMENTS;
        self[urlData.method](urlData,
            { url: {}, body: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                self.elements = r || [];
                self.properties.elements = r || [];

                ListElements.currentListElements = self.elements;
                ListElements.currentListElementsLookup = ListElements.setLookup(self.elements);

                // self.elementsLookup = self.setLookup(r || []);

                self.isElementsLoaded = true;
                p.resolve(r);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    List.prototype.get_Details = function () {
        var self = this;
        var p = $q.defer();
        
        self.loadingDetails = true;
        var urlData = URI.LISTS.DETAILS;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (result) {
                for (var key in result) {
                    if (!result.permissions) result.permissions = [2];
                    if (result.hasOwnProperty(key)) {
                        self.properties[key] = result[key];
                    }
                }

                self.properties.permissions = [1, 2, 3, 4, 5];

                self.backup = {
                    name: self.properties.name,
                    sortAlphabetically: self.properties.sortAlphabetically
                };
                if (!self.properties.allowModify) self.form.editMode = false;
                self.form.set_Data(self.properties);
                self.form.fieldsList.useElementId.onChange = function (field) {
                    if (self.properties.id) self.toggleElementId();
                } 
                self.form.store_Data();

                self.operations = {};
                if ((self.properties.permissions || []).length) {
                    for (var j = 0; j < self.properties.permissions.length; j++) {
                        Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                    }
                }
                if (!self.operations.Update) {
                    self.form.editMode = false;
                }

                // ListElements.currentListElementsLookup = ListElements.setLookup(self.properties.elements);
                p.resolve(result);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingDetails = false;
                self.form.loading = false;
            })


        return p.promise;
    }

    List.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        var urlData = self.properties.id ? URI.LISTS.UPDATE : URI.LISTS.CREATE;
        self.form.validate();
        if (self.form.isValid) {
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.properties
            };
            self[urlData.method](urlData, params, { headers: { moduleId: self.moduleId } })
                .then(function (r) {
                    Message.info("List saved successfully.");
                    p.resolve(r);
                    if (!self.properties.id && !isAdd) {

                        self.properties.id = r;
                        self.get_Details();
                        return;
                    }
                    if (isAdd) self.init();
                    
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                    self.backup = {
                        name: self.properties.name,
                        sortAlphabetically: self.properties.sortAlphabetically
                    };
                    self.form.fieldsList.useElementId.onChange = function (field) {
                        if (self.properties.id) self.toggleElementId();
                    } 
                    
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                })
        }
        else {
            self.form.loading = false;
            p.reject('Form invalid');
        }



        return p.promise;
    }

    List.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.LISTS.DELETE;
        self[urlData.method](urlData, { body: [self.properties.id] })
            .then(function (result) {
                p.resolve(result);
                Message.info("List deleted successfully.");
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;

    }


    


    return List;
});
