import { on } from '@ngrx/store';
import { produce } from 'immer';
import { CoreState, StoreReducer } from '../interfaces';
import { menuActions } from './menu.actions';

export const menuReducers: StoreReducer<CoreState>[] = [
  on(menuActions.toggle, state =>
    produce(state, draft => {
      draft.menu.isOpen = !draft.menu.isOpen;
    }),
  ),
  on(menuActions.open, state =>
    produce(state, draft => {
      draft.menu.isOpen = true;
    }),
  ),
  on(menuActions.close, state =>
    produce(state, draft => {
      draft.menu.isOpen = false;
    }),
  ),
  on(menuActions.populateMenu, (state, { menuItems }) =>
    produce(state, draft => {
      draft.menu.menuItems = menuItems;
    }),
  ),
  on(menuActions.setActiveItem, (state, { activeItem }) =>
    produce(state, draft => {
      draft.menu.activeItem = activeItem;
    }),
  ),
  on(menuActions.setActiveModuleRoles, (state, { roles }) =>
    produce(state, draft => {
      draft.menu.activeModuleRoles = roles;
    }),
  ),
];
