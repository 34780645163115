import { MeasurementSystem } from '../../../models/module/fields/enums/measurementSystem';

export type MeasurementType = 'temperature' | 'speed' | 'precipitation';

export const Measurements: Record<keyof typeof MeasurementSystem, Record<MeasurementType, string>> = {
  Imperial: {
    temperature: '°F',
    speed: 'mph',
    precipitation: 'in',
  },
  Metric: {
    temperature: '°C',
    speed: 'km/h',
    precipitation: 'mm',
  },
};

/**
 * Conversion from one measurement system to another for a specific measurement type
 */
export const MeasurementsConversion: Record<
  keyof typeof MeasurementSystem,
  Partial<Record<keyof typeof MeasurementSystem, Record<MeasurementType, (source: number) => number>>>
> = {
  Metric: {
    Imperial: {
      temperature: (celsius: number) => Number(celsius) * 1.8 + 32, // return fahrenheit
      speed: (km: number) => Number(km) / 1.60934, // return miles
      precipitation: (mm: number) => Number(mm) / 25.4, // return inch
    },
  },
  Imperial: {
    Metric: {
      temperature: (fahrenheit: number) => (Number(fahrenheit) - 32) / 1.8, // return celsius
      speed: (miles: number) => Number(miles) * 1.60934, // return km
      precipitation: (inch: number) => Number(inch) * 25.4, // return mm
    },
  },
};
