export class AddressModel {
    lat: number; 
    lon: number;
    address: string;
    constructor(lat: number, lon: number, address: string) {
      this.lat = lat;
      this.lon = lon;
      this.address = address;
    }
  
    static Default() {
      return new AddressModel(0,0,'');
    }
}