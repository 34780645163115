import { cipo } from 'cipo';
import moment from 'moment';

cipo.controller('editScheduleController',
    function ($scope, Message, URI, $http, dialogs, MODAL, operations, module, $q, obj, $mdDialog, Schedule, Shift, Workday, $timeout) {
        $scope.modalInfo = MODAL;
        $scope.schedule = new Schedule(obj);
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.schedule.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        $scope.itemList = null;
        $scope.manage = function (objType) {
            $scope.isManage = true;
            $scope.itemList = null;
            //$scope.disabledBtn = true;
            if (objType) $scope.itemType = objType;
            var dataURL = $scope.itemType == 1 ? URI.SCHEDULE_DAY.SEARCH : URI.SCHEDULE_SHIFT.SEARCH;
            $scope.schedule[dataURL.method](dataURL)
                .then(function (r) {
                    var tempArr = [];
                    if (r.data && r.data.length) {
                        for (var i = 0; i < r.data.length; i++) {
                            var temp = $scope.itemType == 1 ? new Workday(r.data[i]) : new Shift(r.data[i]);
                            tempArr.push(temp);
                        }
                    }
                    $scope.itemList = tempArr;
                    
                })
                .catch(function (e) { Message.dberror(e); console.error(e); });
        }

        $scope.editItem = function (objType, obj) {
            $scope.isEdit = true;
            //$scope.disabledBtn = true;
            if (objType) $scope.itemType = objType;
            if (obj) {
                $scope.item = obj;
                $scope.item.get_data();
            } else {
                $scope.item = $scope.itemType == 1 ? new Workday() : new Shift();
            }
        }
        $scope.cancelList = function () {
            $scope.isManage = false;
            //$scope.disabledBtn = false;
        }
        $scope.cancelItem = function () {
            $scope.isEdit = false;
            //if (!$scope.isManage) $scope.disabledBtn = false;
        }
        $scope.saveItem = function () {
            $scope.item.save()
                .then(function () {
                    $scope.cancelItem();
                    if ($scope.isManage) $scope.manage();
                    if ($scope.itemType == 1) $scope.schedule.get_WorkdaysDict();
                })
                .catch(function () { })
        }

        $scope.deleteItem = function (item) {
            item.delete()
                .then(function () {
                    $scope.manage();
                    if ($scope.itemType == 1) $scope.schedule.get_WorkdaysDict();
                })
                .catch(function () { })
                .finally(function () { })
        }
        // schedule related
        

        $scope.weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

        $scope.details = function (d) {
            if (!d.details) {
                d.loading = true;
                var dataURL = URI.SCHEDULE_DAY.GET_FULL;
                $scope.schedule[dataURL.method](dataURL, { url: { id: d.scheduleDayId }, urltype: 'obj' })
                    .then(function (r) {
                        if (r.shifts && r.shifts.length) {
                            for (var i = 0; i < r.shifts.length; i++) {
                                r.shifts[i].timeStart = '1970-01-02T' + r.shifts[i].timeStart + 'Z';
                                r.shifts[i].timeStart = moment(r.shifts[i].timeStart).format("HH:mm");
                                r.shifts[i].timeEnd = '1970-01-02T' + r.shifts[i].timeEnd + 'Z';
                                r.shifts[i].timeEnd = moment(r.shifts[i].timeEnd).format("HH:mm");
                            }
                        }
                        d.details = r.shifts;

                    })
                    .catch(function (e) { Message.dberror(e); console.error(e) }).finally(function () { d.loading = false; });
            } else delete d.details;
            
        }
        $scope.changeWorkday = function (d, wId) {
            d.scheduleDayId = wId;
            $scope.schedule.lookup[d.date] = wId;
            if (!$scope.schedule.isScheduleValid) $scope.schedule.validate_period();
        }
        $scope.$watch(function () { return $scope.schedule.properties.startDate }, function (n, o) {
            if ($scope.schedule.form && $scope.schedule.properties.startDate && $scope.schedule.properties.endCycle)
                $scope.schedule.workdays();
        });

        $scope.$watch(function () { return $scope.schedule.properties.endCycle }, function (n, o) {
            if ($scope.schedule.form && $scope.schedule.properties.startDate && $scope.schedule.properties.endCycle)
                $scope.schedule.workdays();

            $scope.schedule.check_generateUntill();
        });

        $scope.$watch(function () { return $scope.schedule.properties.endDate }, function (n, o) {
            $scope.schedule.form.clearErrors();
            if ($scope.schedule.properties.endDate) {
                $scope.schedule.check_generateUntill();                    
            }
            
            
        });
        $scope.close = function () {
            if (!$scope.schedule.checkDirty())
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            else {
                $scope.schedule.hightlight = true;
                $scope.schedule.tabWarning = true;
                $scope.closingModal = true;
            }
        }
        $scope.isModified = false;
        $scope.save = function (isAdd) {
            //$scope.disabledBtn = true;
            $scope.schedule.save(isAdd)
                .then(function () {
                    $scope.isModified = true;
                })
                .catch(function () {  })
                .finally(function () {
                    //$scope.disabledBtn = false;
                })
        }
        $scope.updateTabInfo = function () {
            //$scope.actionBtnDisabled = true;
            $scope.schedule.save()
                .then(function () {
                    $scope.isModified = true;
                    $scope.close();
                })
                .catch(function () { /*$scope.actionBtnDisabled = false;*/ })
                .finally(function () { })
        }
        $scope.cancelAction = function () {
            $scope.schedule.hightlight = false;
            $scope.schedule.tabWarning = false;
            $scope.closingModal = false;
        }
        $scope.revertTabInfo = function () {
            $mdDialog.cancel();
        }

        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.save,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction,
        }

    });
