import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services';
import { ModuleParameter } from './module-parameter.model';

@Injectable()
export class ModuleParameterService {

    constructor(private http: HttpService) { }

    saveModuleParameter(moduleParameter: ModuleParameter, parameterId: number) {
        const params = this.http.buildHttpParams({ parameterId });
        return this.http.put<void>(`_api/ModuleParameter/Save`, moduleParameter, { useLoader: false, httpOptions: { params } });
    }

    getModules(parameterId: number, entityInstanceId: number): Observable<ModuleParameter[]> {
        const params = this.http.buildHttpParams({ entityInstanceId, parameterId });
        return this.http.get<ModuleParameter[]>(`_api/ModuleParameter/All`, { useLoader: false, httpOptions: { params } });
    }
}