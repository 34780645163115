import {
  TableRow,
  ActionType,
  ActionEventType,
  CipoTableOptions,
} from '../../../../shared/components/data-list/common/table.interface';
import { ScheduledEmail } from './scheduled-emails-req.interface';

export interface ScheduledEmailRow extends ScheduledEmail, TableRow {
  frequency: string;
}

export enum ScheduledEmailActionsEnum {
  EDIT = 1,
  TOGGLE = 2,
  DELETE = 3,
  SEND_NOW = 4,
}

export type ScheduledEmailAction = ActionType<ScheduledEmailActionsEnum, ScheduledEmailRow>;
export type ScheduledEmailActionEvent = ActionEventType<ScheduledEmailActionsEnum, ScheduledEmailRow>;
export type ScheduledEmailTableOptions = CipoTableOptions<ScheduledEmailAction>;
