import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("File", function ($q, Model, Functions, URI, Upload, userService, Message, $timeout, fileService) {
    var File = Model.extend(function (obj, url, params) {
        var self = this;
        self.properties = {};
        self.url = url;
        self.params = params;
        self.isModified = false;
        self.get_Data();

    });

    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }

    File.prototype.get_Data = function (versionId) {
        var self = this;
        self.f = null;
        self.isLoaded = false;
        self.isFailedLoaded = false;
        var setClass = function (mimetype) {
            return mimetype.replace(/\//g, '-').replace(/\./g, '-');
        };
        var p = $q.defer();
        if (versionId) self.params.versionId = versionId;
        self[self.url.method](self.url, { url: self.params, urltype: 'obj' })
            .then(function (r) {
                if (r.length) {
                    if (r.length < 1000000) r.length = (r.length / 1000).toFixed(2) + 'KB';
                    if (r.length >= 1000000) r.length = (r.length / 1000000).toFixed(2) + 'MB';
                } else {
                    r.length = '- ';
                }
                if (r.createdOn) r.createdOn = moment(addZ(r.createdOn)).format(userService.formats.datetime);
                if (r.updatedOn) r.updatedOn = moment(addZ(r.updatedOn)).format(userService.formats.datetime);
                if (r.versions && r.versions.length > 1) {
                    for (var i = 0; i < r.versions.length; i++) {
                        if (r.versions[i].isCurrent) self.currentVersionId = r.versions[i].id;
                        if (r.versions[i].createdOn)
                            r.versions[i].createdOn = moment(addZ(r.versions[i].createdOn)).format(userService.formats.datetime);
                    }
                }
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) self.properties[key] = r[key];
                    }
                }
                self.class = setClass(r.mimeType);
                self.isLoaded = true;
                p.resolve();
            }).catch(function (e) {
                console.error(e);
                self.isFailedLoaded = true;
                p.reject();
            });
        return p.promise;
    }

    File.prototype.unshareConfirm = function (item) {
        var self = this;
        item.deleting = true;
        self.isBusy = true;
    }

    File.prototype.cancelUnshare = function (item) {
        var self = this;
        item.deleting = false;
        self.isBusy = false;
    }

    File.prototype.unshareUser = function (item) {
        var self = this;

        var dataURL = URI.MY_CIPO_DRIVE.DELETE_SHARES;
        self[dataURL.method](dataURL, { fileId: self.properties.id, usersId: [item.userId] })
            .then(function (r) {
                self.properties.sharedUsersId.splice(self.properties.sharedUsersId.indexOf(item), 1);
                item.deleting = false;
                self.isBusy = false;
                Message.info('File unshared successfully.');
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
    }

    File.prototype.unshare = function () {
        var self = this;
        self.isBusy = true;
        var dataURL = URI.MY_CIPO_DRIVE.DELETE_SHARES_GLOBAL;
        self[dataURL.method](dataURL, { url: { parentId: self.properties.parentId }, urltype: "obj", body: { list: [self.properties.id] }})
            .then(function (r) {
                self.properties.sharedUsersId = [];
                self.isBusy = false;
                Message.info('File unshared successfully.');
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function (e) {
                self.isBusy = false;
            })
    }

    File.prototype.duplicateVersion = function () {
        var self = this;
        self.isBusy = true;
        var dataURL = self.properties.isProjectFile ? URI.PROJECT_FILE_MANAGER.DUPLICATE_VERSION_FILE : URI.MY_CIPO_DRIVE.DUPLICATE_VERSION_FILE;
        var toSend = {
            fileId: self.properties.id,
            versionId: self.properties.versionId
        }
        var urlparams = {};
        if (self.properties.isProjectFile) urlparams = { contractId: self.params.contractId };
        self[dataURL.method](dataURL, { url: urlparams, urltype: 'obj', body: toSend })
            .then(function (r) {
                self.get_Data(r);
                Message.info('Version set as current successfully.');
                self.isModified = true;
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function (e) {
                self.isBusy = false;
            })
    }

    File.prototype.download = function () {
        var self = this;
        var link = document.createElement("a");
        link.href = self.properties.url;
        link.download = self.properties.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    File.prototype.uploadFiles = function (file, errFiles) {
        var self = this;
        self.f = file;
        
        fileService.setMimeTypeFromExt(file);

        self.errFile = errFiles && errFiles[0];

        var generateFileUrl = URI.MY_CIPO_DRIVE.GENERATE_FILEID;
        var startUploadUrl = URI.MY_CIPO_DRIVE.UPLOAD_FILE_CHUNKS;

        var generateFileId = function (file) {
            Model[generateFileUrl.method](generateFileUrl)
                .then(function (r) {
                    uploadFile(file, r);
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
        }

        var uploadFile = function (file, r) {

            var physicalFileId = r.physicalFileId;
            var urlParamString = '?mimeType=' + file.mimeTypeQueryString + '&fileId=' + physicalFileId;
            var urlString = "?";

            if (self.params) {
                for (var key in self.params)
                    if (self.params.hasOwnProperty(key)) {
                        urlString += key + "=" + self.params[key] + "&";
                    }
            }
            urlString += 'mimeType=' + file.mimeTypeQueryString;

            var finalizeUpload = function (file, fileId, chunksNumber) {
                var physicalFileId = fileId;                    

                var params = {
                    mimeType: file.mimeType,
                    physicalId: physicalFileId,
                    fileId: self.properties.id,
                    totalBlocks: chunksNumber,
                    contractId: self.params.contractId,
                    roleId: 0
                };

                var addVersionUrl = (self.properties.isProjectFile ? URI.PROJECT_FILE_MANAGER.ADD_CHUNKED_VERSION_FILE : URI.MY_CIPO_DRIVE.ADD_CHUNKED_VERSION_FILE);

                var p = $q.defer();

                Model[addVersionUrl.method](addVersionUrl, { url: params, urltype: 'obj', body: { filename: file.name, bytes: file.size } })
                    .then(function (r) {
                        self.isModified = true;
                        p.resolve(r);
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                        p.reject();
                    });

                return p.promise;
            }


            /* If project file that's currently used in PDF Markup, provide markup document id so we can
                duplicate the current markup for the new version we're about to create. */
            //urlString += self.properties.isProjectFile && self.properties.markupDocumentId
            //    ? `&markupDocumentId=${self.properties.markupDocumentId}`
            //    : '';

            if (file.size <= 62914560) { // 60 MB

                file.upload = Upload.upload({
                    url: (self.properties.isProjectFile ? URI.PROJECT_FILE_MANAGER.ADD_VERSION_FILE : URI.MY_CIPO_DRIVE.ADD_VERSION_FILE).toString() + urlString,
                    method: URI.MY_CIPO_DRIVE.ADD_VERSION_FILE.method.toString(),
                    data: {
                        file: file
                    }
                });
            }
            else {
                file.upload = Upload.upload({
                    url: (self.properties.isProjectFile ? URI.PROJECT_FILE_MANAGER.UPLOAD_CHUNK : URI.MY_CIPO_DRIVE.UPLOAD_CHUNK).toString() + urlParamString,
                    method: URI.MY_CIPO_DRIVE.UPLOAD_CHUNK.method.toString(),
                    data: { file: file },
                    resumeChunkSize: '50MB',
                    resumeSizeUrl: startUploadUrl.toString() + '?name=' + encodeURIComponent(file.name),
                    resumeSizeResponseReader: function (data) { return data.size; }
                });
            }

            file.upload
                .then(function (response) {
                $timeout(function () {
                    self.isBusy = false;
                    var versionId = response.data;
                    file.result = versionId;
                    self.isModified = true;

                    if (file.size > 62914560) // 60 MB
                    {
                        //var chunksNumber = file.upload.$$state.value.data;

                        // calculate the total number of chuncks, every chunck should have 50 MB
                        var chunksNumber = Math.ceil(file.size / 52428800);
                        finalizeUpload(file, physicalFileId, chunksNumber).then(function (r) {
                            versionId = r;
                            Message.info('Version added successfully.');
                            self.get_Data(versionId);
                        });
                    }
                    else {
                        Message.info('Version added successfully.');
                        self.get_Data(versionId);
                        self.isModified = true;
                    }
                });
                }, function (response) {
                        self.isBusy = false;
                if (response.status > 0)
                    self.errorMsg = response.status + ': ' + response.data;
                    Message.dberror(response.data);
                    
            }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                    
            });
        }

        if (file) {
            self.isBusy = true;
            generateFileId(file);
        } 
    }

    return File;
});
