import { cipo } from 'cipo';

cipo.factory("Folder", function ($q, Model, URI, $http) {
    var Folder = Model.extend(function (obj) {
        this.id = obj.id || 0;
        this.properties = {
            id: obj.id || 0,
            name: obj.name || "",
            parentId: obj.parentId || 0,
            position: obj.position || 0,
            folders: obj.folders || [],
            files: obj.files || []
        }


    });

    // send conversation re/fw w/o saving
    Folder.prototype.get_content = function (id) {
        var self = this;

        var p = $q.defer();
        self[URI.FOLDER.FOLDER_DETAIL.method](URI.FOLDER.FOLDER_DETAIL, { folderId: id })
            .then(function (result) {
            p.resolve(result);
        }).catch(function (e) {
            console.error(e);
            p.reject(e);
        });
        return p.promise;
    }

    Folder.get_folders = function () {
        var p = $q.defer();
        $http[URI.FOLDER.GET_FOLDERS.method](URI.FOLDER.GET_FOLDERS.toString())
                .then(function (result) {
                    p.resolve(result.data);
                }).catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    
                });
        return p.promise;

    }



    return Folder;
});
