import { cipo } from 'cipo';

cipo.factory("SignatureMod", function ($q, Model, URI, Form, Message, Dictionaries, STATES_STATUS, StatesMapping) {
    var SignatureMod = Model.extend(function (moduleId, obj) {

        this.properties = {
            name: "",
            id: null,
            stateId: null,
            roleId: null,
            userId: null,
            isBinding: false
        };

        var self = this;
        self.moduleId = moduleId;

        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];

                }
            }
        }
    });

    SignatureMod.prototype.createForm = function () {
        var self = this;
        var usersDict = [];
        self.form = new Form(self.properties);
        self.form.initializing = true;

        //create form
        var form = {
            stateId: { label: 'Select state', type: 'select', options: self.statesDict, validation: { required: true } },
            roleId: { label: 'Select role', type: 'select', options: self.rolesDict, validation: { required: true } },
            userId: { label: 'Select user', type: 'select', options: usersDict, validation: { required: true } },
            isBinding: { label: 'Is legally binding', type: 'checkbox' }
        };

        self.form.set_Description(form);

        var getUsers = function (roleId) {

            self.form.fieldsList.userId.loading = true;
            if (roleId) {
                self.properties.userId = null;
            }
            var roleId = roleId || self.properties.roleId;
            Dictionaries.UserRolesPerModule({ id: roleId }, { moduleId: self.moduleId })
                .then(function (r) {
                    
                    self.form.set_Description({ userId: { label: 'Select user', type: 'select', options: r, validation: { required: true } } }, true);
                    self.form.fieldsList.roleId.onClose = function (field) {
                        self.form.fieldsList.userId.loading = true;
                        getUsers(field._value);

                    }
                })
                .catch(function () { })
        }

        if (self.properties.roleId) {
            getUsers();
        } else {
            self.form.fieldsList.roleId.onClose = function (field) {
                getUsers(field._value);
            }
        }

        self.form.setTemplate('grid', [
            { stateId: 33, roleId: 33, userId: 33 }
        ]);

        self.form.store_Data();
        self.form.initializing = false;
    }

    SignatureMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = self.properties.id ? URI.MODULE_EXTRA_SIGNATURES.UPDATE : URI.MODULE_EXTRA_SIGNATURES.CREATE;

        self.form.loading = true;
        self.form.validate();

        if (self.form.isValid) {
            self[dataURL.method](dataURL, { body: self.properties } )
                .then(function () {
                    Message.info('Signature saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.loading = false;
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {});
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    };

    SignatureMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting signature...";
        self.deleting = true;
        var dataURL = URI.MODULE_EXTRA_SIGNATURES.DELETE;

        self[dataURL.method](dataURL, { body: [self.properties.id] } )
            .then(function (result) {
                Message.info('Signature deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete signature. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }

    return SignatureMod;
});
