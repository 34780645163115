import { on } from '@ngrx/store';
import { CoreState, StoreReducer } from '../interfaces';
import { userActions } from './user.actions';
import { produce } from 'immer';

export const userReducers: StoreReducer<CoreState>[] = [
  on(userActions.setFormats, (state, { formats }) =>
    produce(state, draft => {
      draft.user.formats = formats;
    }),
  ),
];
