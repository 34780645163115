export enum Actions {
    Update = -1,
    SubmitApproval = -2,
    ApproveApproval = -3,
    RejectApproval = -4,
    CommentApproval = -5,
    ChangeDocumentNumber = -6,
    AssociateVirtualDrawing = -7,
    SaveInstanceFromTemplate = -8,
    SystemAction = -9,
    UndoLastAction = -10,
    SaveInstanceFromCorrespondence = -11,
    SaveNotificationReplayFromCorrespondence = -12,
}