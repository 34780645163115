import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Holiday", function (Model, Form, URI, Message, userService, $q, Permissions) {
    var Holiday = Model.extend(function (id) {
        var self = this;

        self.tabWarning = false;
        self.hightlight = false;
        self.init(false, id ? false : true);

            self.form = new Form(self.properties);
            self.form.set_Description({
                name: { label: 'Name', type: 'text', validation: { required: true } },
                date: { label: 'Date', type: 'datepicker', options: { format: userService.formats.date }, validation: { required: true } },
                isWorkDay: { label: 'Company working day', type: 'checkbox' }
            });
            if (id) {
                self.properties.id = id;
                self.get_Data();
            }
            else self.setReferenceData(self.properties);
        });

    Holiday.prototype.setReferenceData = function (data) {
        var self = this;
        self.reference = angular.copy(data);
    }

    Holiday.prototype.init = function (isAdd, isNew) {
        var self = this;
        self.properties = {
            id: null,
            date: null,
            name: "",
            isWorkDay: false,
            permissions: isAdd || isNew ? [1, 2] : [2]
        };

        if (isNew || isAdd) {

            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    }

    Holiday.prototype.get_Data = function () {
        var self = this;
        var dataURL = URI.HOLIDAY.GET;
        self.form.loading = true;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                self.operations = {};
                if (r) {
                    if (!r.permissions) r.permissions = [2];
                    self.setReferenceData(r);
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                }
                
                // self.properties.permissions = [1, 2, 3];

                self.properties.date = self.process_incomingDate(r.date);
                self.reference.date = self.properties.date ? self.properties.date.format("YYYY-MM-DDTHH:mm:ss") : null;
                self.form.set_Data(self.properties);
                self.form.store_Data();

                if ((self.properties.permissions || []).length) {
                    for (var j = 0; j < self.properties.permissions.length; j++) {
                        Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                    }
                }
                if (!self.operations.Update) {
                    self.form.editMode = false;
                    // self.periodForm.editMode = false;
                }
            })
            .catch(function (e) { Message.dberror(e) })
            .finally(function () { self.form.loading = false;})
    }

    Holiday.prototype.process_incomingDate = function (date) {
        if (date) {
            var momentDate = moment(date).set({ h: 12, m: 0 });
            // momentDate;
            //console.error('momentDate', date,  momentDate)
            return momentDate;
        }
        else return null;
    }

    Holiday.prototype.process_data = function () {
        var self = this;
        var temp = angular.copy(self.properties);
        temp.date = temp.date ? temp.date.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;
        return temp;
    }

    Holiday.prototype.checkDirty = function () {
        var self = this;
        return !angular.equals(self.process_data(), self.reference);
    }

    Holiday.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.form.validate();
        if (self.form.isValid) {
            self.form.loading = true;
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.process_data()
            };
            var dataURL = self.properties.id ? URI.HOLIDAY.UPDATE : URI.HOLIDAY.CREATE;
            self[dataURL.method](dataURL, params)
                .then(function (r) {
                    Message.info('Holiday saved successfully');
                    p.resolve();
                    if (!self.properties.id) {
                        self.properties.id = r;
                        self.get_Data();
                        return;
                    }
                    if (isAdd) {
                        self.init(isAdd);
                        
                    }
                    self.form.store_Data();
                    self.setReferenceData(self.properties);
                    self.form.set_Data(self.properties);
                    self.reference.date = self.properties.date ? self.properties.date.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;
                    
                    
                })
                .catch(function (e) { self.form.catch(e); p.reject(e); })
                .finally(function () { self.form.loading = false; })
        }
        else {
            p.reject();
        }
        return p.promise;
        
    }

    return Holiday;
});
