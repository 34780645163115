import { cipo } from 'cipo';

cipo.controller('listsmanagementController',
    function ($scope, $state, Manager, userService, rememberManagerService, $mdDialog, URI, ACTIONS, Message) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        var sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }

        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.LISTS.SEARCH,
            otherParams: {
                ShowDisabled: true
            },
            dataWrapper: 'data',
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.editList(row);
            },
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    icon: 'mdi mdi-playlist-plus',
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.editList();
                    }
                },
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editList(row);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editList(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    displayOnRow: $scope.operations.Delete ? true : false,
                    condition: function (row) {
                        // || !row.operations.Delete
                        if (row.isUsed || row.disableDelete ) return false;
                        else return true;
                    },
                    //alwaysOnTop: false,
                    click: function (rows) {
                        $scope.delete(sendList(rows));
                    }
                },
            ],
            parseData: function (data) {
                var data = data || [];

                for (var i = 0; i < data.length; i++) {
                    data[i]._isSystem = data[i].isSystem ? 'Yes' : 'No';
                    data[i]._isUsed = data[i].isUsed ? 'Yes' : 'No';
                    
                }

                return data;
            },
        });

        $scope.manager.set_Columns([
            { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 60, fieldTypeId: 1 },
            { name: '_isSystem', label: 'System', type: 'text', replacementFor: 'isSystem', onTablet: true, onPhone: true, width: 20 },
            { name: '_isUsed', label: 'Used', type: 'text', replacementFor: 'isUsed', onTablet: true, onPhone: true, width: 20 }
        ]);

        $scope.manager.loading = true;
        $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
            function() {
                $scope.manager.loading = false;
            },
            function() {
                $scope.manager.loadPage()
                .finally(function () {
                    $scope.manager.loading = false;
                });
            }
        );

        $scope.editList = function (obj, ev) {
            $mdDialog.show({
                locals: { obj: obj || null, operations: $scope.operations, module: $scope.module },
                controller: 'editListController',
                templateUrl: '/ng/views/admin/modals/editList.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function () {
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                });
        }

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.delete = function (list) {
            if (list.length) {
                $mdDialog.show(confirm('Deleting List', 'Are you sure you want to delete the selected List(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.LISTS.DELETE.method](URI.LISTS.DELETE, { body: list })
                            .then(function () {
                                Message.info('List(s) deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.page = 1;
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        // cancel pressed
                    });
            }
            else {
                Message.info('Please select the item(s) to delete');
            }

            
        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });



    });
