import { NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { LocaleDateAdapter } from '../../../shared/services/date.adapter';
import { DataCardViewModule } from '../data-card/data-card-view.module';
import { CalendarDialogDocDetailComponent } from './calendar-dialog-doc-detail/calendar-dialog-doc-detail.component';
import { CalendarDialogMoreComponent } from './calendar-dialog-more/calendar-dialog-more.component';
import { CalendarDocMiniComponent } from './calendar-doc-mini/calendar-doc-mini.component';
import { CalendarMonthViewComponent } from './calendar-month-view/calendar-month-view.component';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import { CalendarYearViewComponent } from './calendar-year-view/calendar-year-view.component';
import { CalendarService } from './calendar.service';
import { DataCardsViewService } from '../data-card/data-cards-view/data-cards-view.service';

@NgModule({
  imports: [
    SharedComponentsModule,
    TranslateModule,
    DataCardViewModule,
  ],
  declarations: [
    CalendarViewComponent,
    CalendarMonthViewComponent,
    CalendarYearViewComponent,
    CalendarDialogMoreComponent,
    CalendarDialogDocDetailComponent,
    CalendarDocMiniComponent,
  ],
  exports: [
    CalendarViewComponent,
    CalendarMonthViewComponent,
    CalendarYearViewComponent,
    CalendarDialogMoreComponent,
    CalendarDialogDocDetailComponent,
    CalendarDocMiniComponent,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: LocaleDateAdapter
    },
    CalendarService,
    DataCardsViewService,
  ]
})
export class ModuleInstanceCalendarModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}