import { cipo } from 'cipo';

cipo.controller('urlController',
function ($transition$, $scope, $state, $sce, Model, URI) {
    if (!$scope.mainViewLoaded) {
        return;
    }
    
    const params = $transition$.params();
    const moduleId = params.id || null;

    $scope.fullurl = "";

    if (!moduleId) {
        $state.go('tenant.404', null, { location: false });
    }

    var getUrl = function() {
        Model[URI.MODULES.GET.method](URI.MODULES.GET + '?id=' + moduleId)
            .then(function (result) {
                $scope.fullurl = $sce.trustAsResourceUrl(result.url);
            }).catch(function (e) {
                Message.dberror(e);
            });
    }
    
    getUrl();
});
