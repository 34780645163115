import { createAction, props } from '@ngrx/store';
import { MenuItem } from '../../system/menu/menu.component';
import { Role } from '../../models/module/main';

const toggle = createAction('[Menu] Toggle');
const open = createAction('[Menu] Open');
const close = createAction('[Menu] Close');
const populateMenu = createAction('[Menu] Populate', props<{ menuItems: MenuItem[] }>());
const setActiveItem = createAction('[Menu] Set Active Item', props<{ activeItem: MenuItem }>());
const setActiveModuleRoles = createAction('[Menu] Set Active Module Roles', props<{ roles: Role[] }>());

export const menuActions = {
  toggle,
  open,
  close,
  populateMenu,
  setActiveItem,
  setActiveModuleRoles,
};
