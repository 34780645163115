import { cipo } from 'cipo';

cipo.factory("TableFieldConfig", function (Model, Form, URI, Message, $q, FieldConfig) {
    var TableFieldConfig = Model.extend(function (obj) {
        var self = this;
        self.tableId = obj.tableId;
        self.moduleId = obj.moduleId;
        self.fieldOrigin = obj.fieldOrigin;
        self.fieldsList = [];
        self.isFieldsLoaded = false;
        self.deletedFields = [];
        self.isDeletedFieldsLoaded = false;
        self.bckpFieldsList = {};
        self.getFields();

        Object.defineProperty(self, 'hasNoFields', {
            get: function () {
                var hasNoFields = self.isFieldsLoaded && !self.fieldsList.length && self.isDeletedFieldsLoaded && !self.deletedFields.length;
                return hasNoFields;
            }
        });
        // self.getDeletedFields();

    });

    TableFieldConfig.prototype.set_fieldsWidth = function (fields) {
        var self = this;
        var parts = 0;
        var fields = fields || self.fieldsList || [];
        for (var i = 0; i < fields.length; i++) {
            parts += fields[i].width;
            //fields[i].widthPercentage = 100 / parts * fields[i].width;
        }
        for (var i = 0; i < fields.length; i++) {
            //parts += fields[i].width;
            fields[i].widthPercentage = 100 / parts * fields[i].width;
        }
        //console.error('fields', fields);
        return fields;

    }

    TableFieldConfig.prototype.removeColumn = function (field) {
        var self = this;

        self.fieldsList.splice(self.fieldsList.indexOf(field), 1);
        self.set_fieldsWidth(self.fieldsList);

        field.iffy = field.position ? true: false;
        self.deletedFields.push(field);
    }

    TableFieldConfig.prototype.addColumn = function (field) {
        var self = this;

        self.deletedFields.splice(self.deletedFields.indexOf(field), 1);
        field.iffy = true;
        field.width = field.width ? field.width : 1;
        // field.position = self.fieldsList.length + 1;

        self.fieldsList.push(field);
        self.set_fieldsWidth(self.fieldsList);
    }

    TableFieldConfig.prototype.cancelEdit = function () {
        var self = this;
        self.fieldsList = angular.copy(self.bckpFieldsList);
        self.getDeletedFields();
        self.editMode = false;
        self.isBusy = false; 
    }

    TableFieldConfig.prototype.editTable = function () {
        var self = this;
        
        self.editMode = true;
    }

    TableFieldConfig.prototype.getFields = function () {
        var self = this;
        var dataURL = URI.FIELD_DEFINITION.ASSIGNED_FIELDS;
        // self.isFieldsLoaded = false;
        self[dataURL.method](dataURL, { url: { id: self.tableId }, urltype: "obj" }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < r.length; i++) {
                    if (!r[i].width) r[i].width = 1;
                    if (r[i].position == 0) r[i].position = i;
                    
                }
                // set width
                r = self.set_fieldsWidth(r);
                self.bckpFieldsList = angular.copy(r);
                self.fieldsList = r;

                self.getDeletedFields();
                
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.isFieldsLoaded = true;
            })
    }

    TableFieldConfig.prototype.getDeletedFields = function () {
        var self = this;
        var dataURL = URI.FIELD_DEFINITION.ALL_DELETED_FIELDS;
        // self.isDeletedFieldsLoaded = false;
        // self.deletedFields = [];
        self[dataURL.method](dataURL, { url: { id: self.tableId }, urltype: "obj" }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                
                self.deletedFields = r;
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.isDeletedFieldsLoaded = true;
            })
    }


    TableFieldConfig.prototype.syncFields = function () {
        var self = this;
        var toSend = {
            id: self.tableId,
            fields: []
        }

        if ((self.fieldsList || []).length) {
            for (var i = 0; i < self.fieldsList.length; i++) {
                toSend.fields.push({
                    id: self.fieldsList[i].id,
                    width: self.fieldsList[i].width,
                    position: i+1
                })
            }
        }
        self.isBusy = true;
        var dataURL = URI.FIELD_DEFINITION.SYNC_TABLE_FIELDS;
        self[dataURL.method](dataURL, { url: { id: self.tableId }, urltype: "obj", body: toSend }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                // self.fieldsList = r;
                self.getFields();
                
                Message.info("Table updates successfully");
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
    }

    TableFieldConfig.prototype.createField = function (f, info) {
        var self = this;
        self.currentField = self.setupField(f, info);
        self.currentField.tableId = self.tableId;
        // self.isTableField = true;
    }

    TableFieldConfig.prototype.editField = function (f, info) {
        var self = this;
        var info = info || null;
        var field = f.properties ? f.properties : f;
        self.currentField = self.setupField(field, info);
        self.currentField.tableId = self.tableId;
        
    }

    TableFieldConfig.prototype.setupField = function (f, info) {
        var self = this;
        if (info) info.fieldOrigin = self.fieldOrigin;
        var field = new FieldConfig(f, info, self.module.id);
        
        return field;
    }

    TableFieldConfig.prototype.saveField = function (field, cat) {
        var self = this;
        var p = $q.defer();
        self.currentField.save()
            .then(function (r) {
                // if editing the an existing field
                
                // is add new
                if (field) {
                    self.createField(field, cat);
                } else {
                    self.currentField = null;
                } 
                p.resolve();

            })
            .catch(function (e) { console.error(e); p.reject(); })

        return p.promise;
    }

    TableFieldConfig.prototype.deleteField = function (field) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.FIELD_DEFINITION.DELETE_FIELD;
        self.isBusy = true;
        self[dataURL.method](dataURL, { url: { id: field.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.deletedFields.splice(self.deletedFields.indexOf(field), 1);
                p.resolve();

            })
            .catch(function (e) { console.error(e); p.reject(); Message.dberror(e); })
            .finally(function () { self.isBusy = false; })

        return p.promise;
    }

    return TableFieldConfig;
});
