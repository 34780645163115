import { Component, effect, input, signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import moment from 'moment';

import { CipoFieldConfig, CipoFieldTypes, CipoFieldValue } from '../common';
import { UtilsService } from '../../../services';
import { UserService } from '../../../services/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DATE_TIME_FORMATS } from '../../../consts';

@Component({
  selector: 'cipo-text-view',
  standalone: true,
  imports: [FlexLayoutModule, MatIconModule, TranslateModule, CommonModule],
  templateUrl: './cipo-text-view.component.html',
})
export class CipoTextViewComponent {
  displayValue = signal('-');
  utilsService = inject(UtilsService);
  userService = inject(UserService);
  translate = inject(TranslateService);

  value = input<CipoFieldValue>('-');
  type = input<CipoFieldTypes>(CipoFieldTypes.Text);
  config = input<CipoFieldConfig>({});

  translationsLoaded = signal(false);

  constructor() {
    this.translate.get('general.yes').subscribe(() => this.translationsLoaded.set(true));
    effect(
      () => {
        // this esures that the effect is run after the translations are loaded
        if (!this.translationsLoaded()) {
          return;
        }
        const {
          prefix = '',
          suffix = '',
          showThousandSeparator,
          multiple,
          options,
          pickerType,
          format,
          selectMode,
        } = this.config();
        const value = this.value();
        if (!value) return;

        switch (this.type()) {
          case CipoFieldTypes.Text:
            const baseVal = multiple ? (value as string[]).join(', ') : value;
            this.displayValue.set(prefix + (baseVal || '-') + suffix);
            break;

          case CipoFieldTypes.Number:
            if (multiple) {
              const numVals = value as number[];
              const formattedVals = numVals.map(numVal => {
                return showThousandSeparator ? this.utilsService.formatLocaleNumber(numVal) : numVal;
              });
              this.displayValue.set(prefix + formattedVals.join(', ') + suffix);
              break;
            }
            const numVal = this.value() as number;
            const formattedVal = showThousandSeparator ? this.utilsService.formatLocaleNumber(numVal) : numVal;
            this.displayValue.set(prefix + (formattedVal || '-') + suffix);
            break;

          case CipoFieldTypes.Select:
          case CipoFieldTypes.Listbox:
            const listboxVal = this.value() as string[];
            if (options?.length) {
              const selectedOptions = options.filter(option => listboxVal.includes(option.key));
              const displayValue = selectedOptions.map(option => option.value).join(', ');
              this.displayValue.set(displayValue || '-');
              break;
            }
            this.displayValue.set(listboxVal.join(', ') || '-');
            break;

          case CipoFieldTypes.DateTime:
            const dateFormat = format ?? DATE_TIME_FORMATS[pickerType];

            if (selectMode === 'range') {
              const dateRange = this.value() as string[];
              const displayValue = dateRange.map(date => moment(date).format(dateFormat) || '-').join(' - ');
              this.displayValue.set(displayValue);
              break;
            } else {
              const date = this.value() as string;
              this.displayValue.set(moment(date).format(dateFormat) || '-');
              break;
            }
        }
      },
      { allowSignalWrites: true },
    );
  }
}
