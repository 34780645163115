import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AddressModel } from '../address-autocomplete/models/address.model';

@Component({
  selector: 'app-multiple-address-autocomplete',
  templateUrl: './multiple-address-autocomplete.component.html',
  styleUrls: ['./multiple-address-autocomplete.component.scss']
})
export class MultipleAddressAutocompleteComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  multipleAddressForm: FormGroup

  ngOnInit(): void {
    this.multipleAddressForm = this.formBuilder.group({
      addresses: this.formBuilder.array<FormControl>([
        this.formBuilder.control(new FormControl(AddressModel.Default))
      ])
    });
  }

  get addresses(): FormArray<FormControl> {  
    return this.multipleAddressForm.get('addresses') as FormArray<FormControl>;
  }

  addAddress(): void {
    this.addresses.push(new FormControl(AddressModel.Default))
  }

  removeAddress(index: number): void {
    this.addresses.removeAt(index);
  }

  get isOnlyOneAddress() {
    return this.addresses.length === 1;
  }

  onAddressSelected(addressModel: AddressModel, idx: number) {
    this.addresses[idx].value = addressModel;
  }
 }