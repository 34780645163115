import { cipo } from 'cipo';

cipo.directive("visualworkflow", function (GraphModel, $window, $timeout, Message, STATES_STATUS, $q) {
    return {
        restrict: "E",
        scope: {
            "data": "=",
            "visual": "=",
            "workflowId": "="
        },
        templateUrl: "/ng/views/directives/visualworkflow/visualworkflow.html",
        link: function ($scope, element) {
            $scope.isChanged = false;
            $scope.isHidden = false;
            $scope.extendLoader = false;

            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $mdMenu.open(ev);
            };


            $scope.stateTypesMenu = STATES_STATUS().statusSmallerDict;
            var e = document.getElementById("visualID");

            e.addEventListener("click", $scope.switchView);
            
            var container = $(element).find('#graphContainer');
            var globalTransitions = $(element).find('#graphContainerGlobalTransitions');
            var object = {
                n: new GraphModel(container[0], globalTransitions[0], $scope, $scope.workflowId)
            }

            Object.defineProperty($scope, 'object', {
                get: function () {
                    return object;
                }
            });

            $scope.loadGraph = function () {
                try {
                    $scope.object.n.loadConditionals();
                    $scope.object.n.loadDicts();
                    $scope.data.get_StatesList().then(function () {
                        container[0].style.visibility = 'visible';
                        container.empty();
                        globalTransitions.empty();
                        $scope.object.n.setGraphProperties();
                        $scope.object.n.create($scope.data.statesList);
                        $scope.extendLoader = false;
                    })
                        .catch(function (e) { console.error(e); });
                } catch (e) {
                    console.error('directive fail', e);
                }
            };
            if ($scope.visual.prop) {
                $scope.extendLoader = true;
                $scope.loadGraph();
            }


            $scope.addState = function (stateType) {
                $scope.nS = $scope.data.new_State(stateType);
                $scope.nS.createForm();
                $scope.saveLocation(false);
            }

            $scope.loadAfterSave = function (item) {
                $scope.data.state_Action(item).then(function () {
                    $scope.loadGraph();
                }).catch(function (e) { console.log(e); })
            }


            $scope.cancelCreate = function (item) {
                if (!item.properties.id) {
                    object.n.deleteLastTransition();
                }
                delete item.form;
                item = null;
                
                //object.n.deleteLastTransition();
                // $scope.loadGraph();
                // $scope.saveLocation(false);
                
            }

            $scope.saveLocation = function (msg) {
                var p = $q.defer();
                object.n.save().then(function () {
                    $scope.isChanged = false;
                    if (msg) {
                        Message.info('Layout saved successfully');
                    }
                    p.resolve();
                }).catch(function (e) {
                    Message.dberror(e);
                    p.reject();
                    }) 
                return p.promise;
            }

            $scope.saveAs = function () {
                object.n.saveAs();
            }

            $scope.loadAlgorithm = function () {
                object.n.reloadGraphAlg();
            }

            $scope.hideTransitionLabels = function () {
                if (!$scope.isHidden) {
                    object.n.showTransitionNames(true);
                }
                else {
                    object.n.showTransitionNames(false);
                }
                $scope.isHidden = !$scope.isHidden;
                
            }

            $window.customFunctions = {
                deleteState: function (item, cells, cell) {
                    for (var i = 0; i < $scope.data.statesList.length; i++) {
                        if (item.properties.id == $scope.data.statesList[i].properties.id) {
                            $scope.data.state_Action($scope.data.statesList[i], true).then(function () {
                                object.n.deleteVisual(cells, cell, i);
                                $scope.saveLocation(false);
                            }).catch(function (e) {

                            });
                            $timeout(function () { }, 1);
                        }
                    }                       
                },
                editState: function (item) {
                    for (var i = 0; i < $scope.data.statesList.length; i++) {
                        if (item.properties.id == $scope.data.statesList[i].properties.id) {
                            $scope.item = $scope.data.statesList[i];
                            $scope.item.createForm();
                            $scope.saveLocation(false);
                            $timeout(function () { }, 1);
                            var div = $(element).find('#editId');
                            div.context.scrollIntoView();
                        }
                    }   
                },
                editTransition: function (item, src) {
                    for (var i = 0; i < $scope.data.statesList.length; i++) {
                        if (src.properties.id == $scope.data.statesList[i].properties.id) {
                            $scope.src = $scope.data.statesList[i];
                        }
                    }
                    for (var j = 0; j < $scope.src.transitions.length; j++) {
                        if ($scope.src.transitions[j].properties.id == item.properties.id) {
                            $scope.item = $scope.src.transitions[j];
                            $scope.item.createForm();
                            $scope.saveLocation(false);
                            $timeout(function () { }, 1);
                            var div = $(element).find('#editId');
                            div.context.scrollIntoView();

                        }
                    }
                },
                deleteTransition: function (item, src) {
                    for (var i = 0; i < $scope.data.statesList.length; i++) {
                        if (src.properties.id == $scope.data.statesList[i].properties.id) {
                            $scope.src = $scope.data.statesList[i];
                        }
                    }
                    for (var j = 0; j < $scope.src.transitions.length; j++) {
                        if ($scope.src.transitions[j].properties.id == item.properties.id) {
                            $scope.item = $scope.src.transitions[j];
                            $scope.saveLocation(false);
                            $scope.src.transition_Action($scope.item, true);
                            // $scope.saveLocation(false);
                            $timeout(function () {
                                $scope.$apply();
                            }); 
                        }
                    }
                },
                transitionAdded: function (src, trg) {
                    for (var i = 0; i < $scope.data.statesList.length; i++) {
                        if (src.properties.id == $scope.data.statesList[i].properties.id) {
                            $scope.src = $scope.data.statesList[i];
                        }
                    }
                    for (var i = 0; i < $scope.data.statesList.length; i++) {
                        if (trg.properties.id == $scope.data.statesList[i].properties.id) {
                            $scope.trg = $scope.data.statesList[i];
                        }
                    }
                    try {
                        $scope.item = $scope.src.new_Transition();
                        $scope.item.properties.nextStateId = $scope.trg.properties.id;
                        $scope.item.createForm();
                        $scope.saveLocation(false);
                        $timeout(function () { }, 1);
                        var div = $(element).find('#editId');
                        div.context.scrollIntoView();
                    } catch (e) {
                        console.error('transition added broke:', e);
                    }
                },
                graphChanged: function () {
                    $scope.isChanged = true;
                    $timeout(function () { }, 1);
                },
                graphModelAlert: function (msg, err) {
                    if (err) {
                        Message.error(msg);
                    }
                    else {
                        Message.info(msg);
                    }
                },

                mxReload: function () {
                    $scope.loadGraph();
                }

            }


            $scope.switchView = function () {
                try {
                    e.disabled = true;
                    $scope.saveLocation().then(function () {
                        e.disabled = false;
                    }).catch(function (e) {
                        e.disabled = false;
                        Message.dberror(e)
                    });
                }
                catch (e) {
                    //
                }
            }

            $scope.$on("$destroy", function () {
                delete $window.customFunctions;
                e.removeEventListener("click", $scope.switchView());
            });


            $scope.$watch(function () { return $scope.data.isGStateClosed }, function (n, o) {
                if ($scope.data.isGStateClosed == true) {
                    $scope.extendLoader = true;
                    $scope.saveLocation().then(function () {
                        $scope.loadGraph();
                        $scope.extendLoader = false;
                    }).catch(function (e) {
                        Message.dberror(e)
                    });
                    $scope.data.isGStateClosed = false;
                }
            });

        }


    }
});
