import { cipo } from 'cipo';

cipo.controller('changeEmailController', function ($scope, $mdDialog, $http, URI, data, Message) {
    $scope.data = data.checkboxes;
    $scope.title = data.title;            
    $scope.confirmLabel = formatLabel(data.buttonLabels.find(x => x.key == 'confirm').value);
    $scope.cancelLabel = formatLabel(data.buttonLabels.find(x => x.key == 'cancel').value);            
    $scope.userId = data.userId;
    $scope.manager = data.manager;

    $scope.confirm = function () {                

        // validation
        if ($scope.newEmail == null) {
            Message.error("The new email cannot be empty!");
            return;
        }
        else {
            var re = new RegExp('^((".+?(?<!\\\\)"@)|(([0-9a-z]((\\.(?!\\.))|[-!#\\$%&\'\\*\\+/=\\?\\^`{}|~\\w])*)(?<=[0-9a-z])@))((\\[\\d{1,3}.\\d{1,3}.\\d{1,3}.\\d{1,3}\\])|(([0-9a-z][-0-9a-z]*[0-9a-z]*.)+[a-z0-9][-a-z0-9]{0,22}[a-z0-9]))$');
            var test = re.test(String($scope.newEmail).toLowerCase());

            if (!test) {
                Message.error("Email address \"" + $scope.newEmail + "\" is not valid");
                return;
            }
        }

        $http[URI.USER.CHANGE_EMAIL.method](URI.USER.CHANGE_EMAIL.toString() + '?userId=' + $scope.userId + '&email=' + $scope.newEmail)
            .then(function () {
                Message.info('Email successfully changed');
                $mdDialog.hide(null);                                
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
    }

    $scope.cancel = function () {
        $mdDialog.hide(null);
    }            

    function formatLabel(label) {             
        return label;
    }
});