import { cipo } from 'cipo';

cipo.controller('selectDialogController',
    function ($scope, $mdDialog, data) {
        $scope.data = data.options;
        $scope.selected = data.defaultOption;
        $scope.multipleValue = [];
        $scope.title = data.title;
        $scope.multiple = data.multiple;
        $scope.confirmLabel = formatLabel(data.buttonLabels.find(x => x.key == 'confirm').value);
        $scope.cancelLabel = formatLabel(data.buttonLabels.find(x => x.key == 'cancel').value);

        // Returns the id of the selected value
        $scope.confirm = function (isValid) {
            if (isValid) {
                $mdDialog.hide($scope.multiple ? $scope.multipleValue : $scope.selected.value);
            }
        }

        $scope.cancel = function () {
            $mdDialog.hide(null);
        }

        function formatLabel (label) {
            return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
        }
    });
