import { AppState } from '../../../app.state';

const get = (state: AppState) => state.core.menu;
const getItems = (state: AppState) => state.core.menu.menuItems;
const getActiveItem = (state: AppState) => state.core.menu.activeItem;
const getRolesForActiveModule = (state: AppState) => state.core.menu.activeModuleRoles;

export const menuSelectors = {
  get,
  getItems,
  getActiveItem,
  getRolesForActiveModule,
};
