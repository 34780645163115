import { Component, effect, Inject, inject, signal } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { CipoSelectComponent } from 'src/app/shared/components/fields/cipo-select/cipo-select.component';
import { CipoDialogModule, CipoFormModule } from 'src/app/shared/modules';
import { NotificationService } from 'src/app/shared/services';
import { AssignmentsService } from '../assignments.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CipoFieldTypes, CipoListControl } from 'src/app/shared/components/fields/common';
import { FilterType, PrimaryUser, SyncPrimaryUsersModel } from './primary-user-dialog.model';
import { MatMenuModule } from '@angular/material/menu';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { DEBOUNCE_TIMES } from 'src/app/shared/consts';

@Component({
  selector: 'app-primary-users-dialog',
  standalone: true,
  imports: [MatExpansionModule, CipoSelectComponent, CipoDialogModule, MatMenuModule, CipoFormModule],
  providers: [AssignmentsService],
  templateUrl: './primary-users-dialog.component.html',
})
export class PrimaryUsersDialogComponent {
  translate = inject(TranslateService);
  assignmentsService = inject(AssignmentsService);
  notification = inject(NotificationService);

  saving = signal(false);
  searchControl = new FormControl('');
  FILTER_TYPE = FilterType;
  currentFilterType = signal(FilterType.All);
  entityInstanceId: number;
  roles: PrimaryUser[] = [];
  initialRoles: PrimaryUser[] = [];
  arrayControls: Record<number, CipoListControl> = {};

  constructor(
    public dialogRef: MatDialogRef<PrimaryUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _entityInstanceId: number,
  ) {
    effect(() => {
      const saving = this.saving();

      Object.values(this.arrayControls).forEach(control => {
        if (saving) {
          control.disable({ emitEvent: false });
        } else {
          control.enable({ emitEvent: false });
        }
      });
    });

    this.entityInstanceId = _entityInstanceId;
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIMES.default))
      .subscribe(value => this.search(value, this.currentFilterType()));

    this.loadRoles();
  }

  loadRoles() {
    this.arrayControls = [];

    this.assignmentsService.getPrimaryUsers(this.entityInstanceId).subscribe(primaryUsers => {
      this.roles = primaryUsers;
      this.initialRoles = primaryUsers;

      for (let index = 0; index < primaryUsers.length; index++) {
        const control = new CipoListControl(primaryUsers[index].userId, {
          label: 'assignments.primaryUser',
          multiple: false,
          type: CipoFieldTypes.Select,
          options: primaryUsers[index].users,
        });

        this.arrayControls[primaryUsers[index].roleId] = control;
      }
    });
  }

  search(criteria: string, filterType: FilterType) {
    this.currentFilterType.set(filterType);

    if (!criteria && filterType == FilterType.All) {
      this.roles = this.initialRoles;
      return;
    }

    // first set new chnages in userId so we filter with real data
    this.setUserId(this.initialRoles);
    this.roles = this.initialRoles.filter(
      row =>
        (!criteria || (criteria && row.roleName.toString().toLowerCase().includes(criteria.toLowerCase()))) &&
        (filterType == FilterType.All ||
          (filterType == FilterType.Assigned && row.userId !== 0) ||
          (filterType == FilterType.NotAssigned && row.userId === 0)),
    );
  }

  clearInput() {
    this.searchControl.reset();
  }

  savePrimaryUsers() {
    if (this.roles) {
      this.saving.set(true);
      this.setUserId();

      const syncModel: SyncPrimaryUsersModel = {
        contractId: this.entityInstanceId,
        primaries: this.roles,
      };

      this.assignmentsService.savePrimaryUsers(syncModel).subscribe({
        complete: () => {
          this.notification.success('assignments.successfully');
          this.saving.set(false);
          this.dialogRef.close(true);
        },
        error: () => {
          this.saving.set(false);
        },
      });
    }
  }

  setUserId(list?: PrimaryUser[]) {
    list = list ?? this.roles;
    if (list?.length) {
      for (let index = 0; index < list.length; index++) {
        list[index].userId = this.arrayControls[list[index].roleId]?.value;
      }
    }
  }
}
