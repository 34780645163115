import { cipo } from 'cipo';

cipo.directive("listrelationdetail", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "relation": "=",
            "module": "=",
            "operations": "=",
        },
        templateUrl: "/ng/views/directives/admin/listrelationdetail.html",
        controller: function ($scope) {
            
        }

    }
});
