import { Injectable } from '@angular/core';
import { GridViewModel, SearchViewModel } from '../../../models/module/grid';
import { HttpService } from '../../../shared/services/http.service';
import { Assignments, SyncRolePositionModel, SyncUsersAndRolesModel } from './assignments.model';
import { KeyValueType } from 'src/app/models/common';
import { AssignContractUserRole } from './assign-users-dialog/assign-users-dialog.model';
import { PrimaryUser, SyncPrimaryUsersModel } from './primary-users-dialog/primary-user-dialog.model';

@Injectable()
export class AssignmentsService {
  constructor(private httpService: HttpService) {}

  getAssignments(searchModel: SearchViewModel, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ entityInstanceId });
    return this.httpService.post<GridViewModel<Assignments[]>>(`_api/Role/GetUsers`, searchModel, {
      httpOptions: { params },
      useLoader: false,
    });
  }

  syncAssignments(syncModel: SyncUsersAndRolesModel) {
    return this.httpService.put<void>(`_api/Role/SyncUsersAndRolesForContract`, syncModel, { useLoader: false });
  }

  getRoles() {
    return this.httpService.get<KeyValueType<number, string>[]>(`_api/Role/Dict`, { useLoader: false });
  }

  getUsers(contractId: number, getDisabled: boolean, excludeUserIds: number[]) {
    const params = this.httpService.buildHttpParams({ contractId, getDisabled });
    return this.httpService.post<KeyValueType<number, string>[]>(`_api/User/DictWithExclude`, excludeUserIds, {
      httpOptions: { params },
      useLoader: false,
    });
  }

  asignUsers(userIds: AssignContractUserRole) {
    return this.httpService.put<void>(`_api/Role/AssignContractUserRole`, userIds, { useLoader: false });
  }

  getPrimaryUsers(contractId: number) {
    const params = this.httpService.buildHttpParams({ contractId });
    return this.httpService.get<PrimaryUser[]>(`_api/Role/GetContractPrimary`, {
      httpOptions: { params },
      useLoader: false,
    });
  }

  savePrimaryUsers(primaries: SyncPrimaryUsersModel) {
    return this.httpService.put<void>(`_api/Role/SyncContractPrimary`, primaries, { useLoader: false });
  }

  syncRolePosition(positions: SyncRolePositionModel) {
    return this.httpService.put<void>(`_api/Contracts/SyncRolePositions`, positions, { useLoader: false });
  }
}
