@if (fieldData().readonly) {
    <cipo-text-view [config]="formControl().fieldData" [type]="fieldData().type" [value]="formControl().value" />
} @else {
    <mat-form-field class="full-width" appearance="outline" [title]="selectedOptionValues() | translate">
        @if (fieldData().label) {
            <mat-label>{{ fieldData().label | translate }}</mat-label>
        }
        <mat-select
            [formControl]="formControl()"
            [multiple]="fieldData().multiple"
            (opened)="searchInput.focus()"
            [placeholder]="fieldData().placeholder | translate">
            @if (fieldData().multiple) {
                <mat-select-trigger>
                    @if (allOptionsSelected()) {
                        {{ "cipoFields.allSelected" | translate }}
                    } @else {
                        {{ formControl().value?.length ? optionsDict()[formControl().value[0]] : ("" | translate) }}
                        @if ((formControl().value?.length || 0) > 1) {
                            <span
                                translate="cipoFields.more"
                                [translateParams]="{ count: formControl().value?.length - 1 }"></span>
                        }
                    }
                </mat-select-trigger>
            }
            <div class="search-field full-width">
                <mat-form-field class="cipo-input hide-subscript">
                    <input
                        matInput
                        #searchInput
                        [placeholder]="'cipoFields.searchOption' | translate"
                        [formControl]="searchControl"
                        (keydown)="$event.stopPropagation()" />
                    @if (searchControl.value) {
                        <button mat-icon-button matSuffix (click)="searchControl.setValue('')">
                            <mat-icon>clear</mat-icon>
                        </button>
                    }
                </mat-form-field>
            </div>
            @if (fieldData().multiple) {
                <div (click)="toggleSelectAllOptions(!allOptionsSelected())" class="cursor--pointer mat-mdc-option p-0">
                    <mat-checkbox
                        class="m-x-xs"
                        [checked]="allOptionsSelected()"
                        [indeterminate]="formControl().value?.length > 0 && !allOptionsSelected()">
                        {{ "cipoFields.all" | translate }}
                    </mat-checkbox>
                </div>
            } @else if (!fieldData().required) {
                <mat-option [value]="null" [title]="'cipoFields.none' | translate">
                    {{ "cipoFields.none" | translate }}
                </mat-option>
            }
            @for (option of filteredOptions(); track $index) {
                <mat-option [value]="option.key" [title]="option.value | translate">
                    {{ option.value | translate }}
                </mat-option>
            }
        </mat-select>
        @if (fieldData().fieldDescription) {
            <mat-icon matSuffix class="material-icons-outlined" [title]="fieldData().fieldDescription">info</mat-icon>
        }
        @if (fieldData().hint) {
            <mat-hint>{{ fieldData().hint | translate }}</mat-hint>
        }
        <mat-error>
            {{ formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate) }}
        </mat-error>
    </mat-form-field>
}
