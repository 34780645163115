import { cipo } from 'cipo';

cipo.directive("listattachments", function (URI) {
    return {
        restrict: "A",
        // replace: true,
        scope: {
            "fielddata": "="
        },
        templateUrl: "/ng/views/directives/system/listattachments.html",
        controller: function ($scope) {
            if ($scope.fielddata._value && $scope.fielddata._value.length) {
                for (var i = 0; i < $scope.fielddata._value.length; i++) {
                    
                    $scope.fielddata._value[i].cssClass = $scope.fielddata._value[i].mimeType.replace(/\//g, '-').replace(/\./g, '-');
                }
            }
        }
    }
});
