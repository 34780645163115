import { FileChunkParams, FileItemModel, SearchFileModel } from '../../../../../models/module/fields/main';
import { inject, Injectable } from '@angular/core';
import {
  AddChunkedVersionParams,
  CreateFolderParams,
  DuplicateVersionBody,
  DuplicateVersionParams,
  EditFolderParams,
  GetUploadUrlParams,
  GetVersionUrlParams,
  MyDriveService,
  RenameFileParams,
  SaveFileInfoBody,
  SaveFileInfoParams,
} from '..';
import { ProjectFileService } from './project-files.service';
import { Observable } from 'rxjs';

export interface IFileService {
  getFileUrl(fileId: number, versionId: number, isPreview?: boolean): Observable<string>;

  getFilesUrl(versionIds: number[]): Observable<
    {
      versionId: number;
      url: string;
    }[]
  >;

  getFile<T>(fileId: number, entityInstanceId: number, versionId?: number): Observable<T>;

  searchFiles(props: SearchFileModel): Observable<FileItemModel[]>;

  getFileUploadUrl(body: GetUploadUrlParams): string;

  getFileNewVersionUrl(body: GetVersionUrlParams): string;

  generateFileId(): Observable<string>;

  uploadFileInChunks(fileName: string): Observable<void>;

  uploadChunk(fileId: string, formData: FormData): Observable<void>;

  saveFileInfo(params: SaveFileInfoParams, body: SaveFileInfoBody): Observable<FileItemModel>;

  addChunkedVersion(params: AddChunkedVersionParams, body: SaveFileInfoBody): Observable<FileItemModel>;

  renameFile(body: RenameFileParams): Observable<void>;

  createFolder(body: CreateFolderParams): Observable<void>;

  editFolder(body: EditFolderParams): Observable<void>;

  downloadFileFromUrl(url: string): void;

  getChunkData({ file, start, end, chunks }: FileChunkParams): FormData;

  duplicateVersion(params: DuplicateVersionParams, body: DuplicateVersionBody): Observable<number>;
}

@Injectable({ providedIn: 'root' })
export class FileFactoryService {
  private myDriveService = inject(MyDriveService);
  private projectFilesService = inject(ProjectFileService);

  public getServiceType(type: FileServiceType): IFileService {
    switch (type) {
      case FileServiceType.MyDrive:
        return this.myDriveService as IFileService;

      case FileServiceType.ProjectFiles:
        return this.projectFilesService as IFileService;
    }
  }
}

export enum FileServiceType {
  MyDrive = 1,
  ProjectFiles = 2,
}
