import { cipo } from 'cipo';

cipo.factory("Emails", function ($q, Model, Form, Functions, URI, userService) {
    var Emails = Model.extend(function () {
        var self = this;
        self.emailFolders = [];
        self.get_menu();
    });

    Emails.prototype.get_menu = function () {
        var self = this;
        var p = $q.defer();

        self[URI.EMAIL.FOLDERS.method](`${URI.EMAIL.FOLDERS}?contractId=${userService.system.userdata.contractId}`).then(function (result) {
            self.emailFolders = result.data;
        }).catch(function (e) {
            console.error(e);
        }).finally(function () {
            p.resolve();
        });

        return p.promise;
    }

    return Emails;
});
