import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { WeatherFieldModel } from '../../../models/module/fields/main/weatherField.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { TableDataSource } from './table-data-source.service';
import { WeatherTableService } from './weather-table.service';

@Component({
  selector: 'app-weather-table',
  templateUrl: './weather-table.component.html',
  styleUrls: ['./weather-table.component.scss'],
})
export class WeatherTableComponent implements OnInit, OnChanges, OnDestroy {
  displayedColumns: string[] = ['time', 'temperature', 'windSpeed', 'phrase', 'precipitation', 'delete'];
  dataSource: WeatherFieldModel[] = [];

  myDataSource: TableDataSource;

  @Input() editMode: boolean = false;
  @Input() fielddata: {
    _fieldProperty: number;
    entityInstanceId: number;
    actionInstanceId: number;
    _editMode: boolean;
    _value: any;
    contractId: number;
  };
  @Input('fieldid') fieldId: number;
  @Input() date: Date;

  @Output('onchange') onChange = new EventEmitter();

  constructor(
    private weatherService: WeatherTableService,
    private changeDetectorRef: ChangeDetectorRef,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.myDataSource = new TableDataSource(
      this.weatherService,
      this.notification,
      this.changeDetectorRef,
      this.fielddata,
      this.fieldId,
    );
    this.myDataSource.loadData();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && changes.date.currentValue !== undefined && this.myDataSource !== undefined) {
      this.myDataSource.changeDate(changes.date.currentValue as Date);
    }
    if (changes.fieldId && changes.fieldId.currentValue !== undefined && this.myDataSource !== undefined) {
      this.myDataSource.fieldId = changes.fieldId.currentValue as number;
    }
  }

  ngOnDestroy(): void {
    this.myDataSource.destroy();
  }

  createTimeFieldInstance() {
    if (!this.myDataSource.isDateValid(this.myDataSource.associatedDate)) {
      this.notification.error("Missing date value.");
      return;
    }

    const time = new Date().toTimeString().split(' ')[0];
    const hourMinute = time.split(':');
    const hm = hourMinute[0] + ':' + hourMinute[1];
    this.myDataSource.addRow(hm, this.myDataSource.associatedDate, this.fielddata.contractId)
      .subscribe({
        next: () => {
          this.myDataSource.initializeFieldDataValue();
          this.onChange.emit();
        },
        error: (error) => this.notification.error(error)
      });
  }

  getWeatherDataByTime(event, element: WeatherFieldModel) {
    let row = this.myDataSource.data.find(y => y === element);
    row.time = event.target.value;
    this.myDataSource.initializeFieldDataValue();
    this.weatherService
      .getContractWeatherDataByTime(
        this.fielddata._fieldProperty,
        this.fielddata.entityInstanceId,
        this.myDataSource.associatedDate,
        event.target.value,
        element.fieldInstanceId,
        this.fielddata.contractId,
      )
      .subscribe(x => {
        if (x !== undefined && x !== null) {
          row.phrase = x.phrase;
          row.temperature = x.temperature;
          row.windSpeed = x.windSpeed;
          row.humidity = x.humidity;
          row.precipitation = x.precipitation;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  deleteRow(element: WeatherFieldModel) {
    this.myDataSource.removeRow(element);
    this.onChange.emit();
  }

  sortData(sort: Sort) {
    this.myDataSource.sortData(sort);
  }

  trackBy(index: number, stuff) {
    return stuff.fieldInstanceId;
  }
}
