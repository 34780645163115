import { cipo } from 'cipo';

cipo.directive("entitytabs", function () {
    return {
        restrict: "E",
        scope: {
            "entityinstance": "="
        },
        templateUrl: "/ng/views/directives/dynamics/entitytabs.html",
        controller: function ($scope, userService) {

            $scope.activityExpanded = false;
            $scope.activityReload = undefined;
            $scope.activitiesLoading = true;

            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor($scope.entityinstance.moduleCode) }
            });

            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };

            $scope.documentIsClosed = function() {
                return $scope.entityinstance.generalInfo.isClosedState;
            }
            
            $scope.openpopupevent = function (element) {
                $scope.entityinstance.openPDFMarkup(element);
            };

            $scope.setActivities = function (value) {
                $scope.activities = value;
                $scope.$apply();
            }

            $scope.setActivity = function (activity, value) {
                activity.extra = value;
                $scope.entityinstance.set_transition_info(activity.extra);
                $scope.$apply();
            }

            $scope.toggleActivities = function () {
                $scope.activityExpanded = !$scope.activityExpanded;
            }

            $scope.reloadActivities = function () {
                $scope.activityReload = new Date().getTime();
            }

            $scope.loadingActivities = function (loading) {
                $scope.activitiesLoading = loading;
            }

            $scope.$watch(
                function () {
                    return $scope.entityinstance.isActivityLoaded;
                }, function (n) {
                    if (n) {
                        $scope.reloadActivities();
                    }
                }
            );
        }
    }
});