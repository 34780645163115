import { BreakpointObserver } from '@angular/cdk/layout';
import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  input,
  model,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { BREAKPOINTS_MEDIA_QUERY } from '../../../../app-breakpoints.module';
import { AppState } from '../../../../app.state';
import { DEBOUNCE_TIMES } from '../../../shared/consts';
import { MaterialModule } from '../../../shared/modules/material.module';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { contextActions } from '../../../store/context';
import { menuSelectors } from '../../../store/menu';
import {
  ContextContract,
  ContextInfo,
  ContextInfoPart,
  ContextModule,
  ContextProgram,
  ContextProject,
  ContextTree,
  ShowTypeEnum,
} from '../context.model';
import { ContextService } from '../context.service';

const PROGRAM_MODULE_ID = -23;
const PROJECT_MODULE_ID = -10;
const CONTRACT_MODULE_ID = -1;

interface ContextNode {
  context: ContextInfoPart;
  type: 'program' | 'project' | 'contract';
  selected: boolean;
  children?: ContextNode[];
}

interface ContextFlatNode {
  expandable: boolean;
  context: ContextInfoPart;
  type: 'program' | 'project' | 'contract';
  selected: boolean;
  level: number;
}

@Component({
  selector: 'cipo-context-view',
  templateUrl: './context-view.component.html',
  styleUrls: ['./context-view.component.scss'],
  imports: [CommonModule, MaterialModule, SharedComponentsModule],
  standalone: true,
})
export class ContextViewComponent implements OnInit, OnDestroy {
  SHOW_TYPE = ShowTypeEnum;
  useOnlyContract = input<boolean>(false);
  context = model<ContextInfo>(undefined);
  userId = input<number>(undefined);
  refresh = input<boolean>(false);

  @Output() contextData = new EventEmitter<ContextTree>();

  @ViewChild('contextContent', { static: true }) contextContentChild: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('contextScrollContainer') contextScrollContainer: ElementRef<HTMLElement>;
  @ViewChildren('contextNodeElement') contextNodeElements: QueryList<ElementRef<HTMLElement>>;

  showOnlyLastContextPart = signal(false);
  lastContextPart: Signal<ContextInfoPart | undefined> = computed(
    () => this.context()?.contract || this.context()?.project || this.context()?.program,
  );
  isContextOverlayOpen = signal(false);
  isLoadingTree = signal(false);
  hostWidth = signal(0);
  contextContentOrigin = signal<FlexibleConnectedPositionStrategyOrigin>({ x: 0, y: 0 });
  showType = signal<ShowTypeEnum>(ShowTypeEnum.Active);
  isMenuOpen = signal(false);
  contractsNumber = signal(0);
  canOpenOverlay = computed(() => !this.isLoadingTree() && this.context() && this.contractsNumber() > 1);
  noData = signal(false);
  isContextDefined = computed(
    () =>
      this.context() && (this.context()?.contract?.id || this.context()?.project?.id || this.context()?.program?.id),
  );

  observer: ResizeObserver;
  loadingTreeSkeletonArray = [...Array(7).keys()];
  searchControl = new FormControl('');
  scrollIntoViewTimeout = undefined;
  contextTree: ContextTree;

  treeControl = new FlatTreeControl<ContextFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    (node: ContextNode, level: number) => ({
      expandable: !!node.children && node.children.length > 0,
      context: node.context,
      type: node.type,
      selected: node.selected,
      level: level,
    }),
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, []);
  dataSourceOriginalData: ContextNode[] = [];
  hasChild = (_: number, node: ContextFlatNode) => node.expandable;
  setContextAfterLoadingTree = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private elementRef: ElementRef<HTMLElement>,
    private cdRef: ChangeDetectorRef,
    private contextService: ContextService,
  ) {
    breakpointObserver
      .observe([BREAKPOINTS_MEDIA_QUERY.lt_lg])
      .pipe(takeUntilDestroyed())
      .subscribe(result => {
        this.showOnlyLastContextPart.set(result.matches);
      });

    effect(
      () => {
        const ctx = this.context();
        this.attachMissingPartsWhenNotSet(ctx);
        this.store.dispatch(contextActions.set({ context: { ...ctx } }));
        this.updateOriginalData(ctx);
      },
      {
        allowSignalWrites: true,
      },
    );
    effect(
      () => {
        if (this.isContextOverlayOpen() || this.refresh()) {
          this.loadContextTree(this.refresh());
        }
      },
      {
        allowSignalWrites: true,
      },
    );
    effect(() => {
      if (this.isLoadingTree()) {
        this.searchControl.disable();
      } else {
        this.searchControl.enable();
      }
    });
    effect(
      () => {
        if (this.isContextOverlayOpen()) {
          const width = this.contextContentChild?.nativeElement?.clientWidth ?? 0;
          this.setContextContentWidth(width);
          this.setContextContentOrigin(this.contextContentChild);
          this.searchInput?.nativeElement?.focus();
        }
      },
      {
        allowSignalWrites: true,
      },
    );

    this.searchControl.valueChanges
      .pipe(
        takeUntilDestroyed(),
        debounceTime(DEBOUNCE_TIMES.default),
        distinctUntilChanged(),
        filter(_ => this.isContextOverlayOpen()),
      )
      .subscribe(value => this.search(value));
  }

  contextInfoPartIsEqual(part1: ContextInfoPart, part2: ContextInfoPart): boolean {
    return (
      part1.id * 1 === part2.id * 1 &&
      part1.entityInstanceId * 1 === part2.entityInstanceId * 1 &&
      part1.moduleId * 1 === part2.moduleId * 1 &&
      part1.name === part2.name &&
      part1.no === part2.no &&
      part1.isArchived === part2.isArchived &&
      part1.isClosed === part2.isClosed
    );
  }

  attachMissingPartsWhenNotSet(ctx: ContextInfo) {
    // this happens:
    //   - when user does a switch between a place when useOnlyContract is set to true then to false
    //      i.e. User is on My drive where only contracts are shown, then moves to Project Files where full hierarchy is shown
    //   - when user does a switch to a new contract, project or program but does not have the full context info, only partial info like id or entityInstanceId

    if (ctx && !this.contextTree) {
      // context has been set before loading the context, most likely due to a route change or loaded an external link
      this.setContextAfterLoadingTree = true;
      return;
    }
    if (!this.contextTree || !ctx) {
      return;
    }

    let changed = false;
    if (ctx.contract && (!ctx.program || !ctx.project)) {
      const program = (this.contextTree.programs || []).find(p =>
        (p.projects || []).some(j =>
          (j.contracts || []).some(
            c => c.entityInstanceId === ctx.contract.entityInstanceId * 1 || c.id === ctx.contract.id * 1,
          ),
        ),
      );
      const project = (program?.projects || []).find(j =>
        (j.contracts || []).some(
          c => c.entityInstanceId === ctx.contract.entityInstanceId * 1 || c.id === ctx.contract.id * 1,
        ),
      );
      const contract = (project?.contracts || []).find(
        c => c.entityInstanceId === ctx.contract.entityInstanceId * 1 || c.id === ctx.contract.id * 1,
      );
      if (!ctx.program && !this.useOnlyContract() && program?.entityInstanceId) {
        ctx.program = this.programToContextInfoPart(program, this.contextTree.modules);
        changed = true;
      }
      if (!ctx.project && !this.useOnlyContract() && project?.entityInstanceId) {
        ctx.project = this.projectToContextInfoPart(project, this.contextTree.modules);
        changed = true;
      }
      if (contract?.entityInstanceId) {
        const newContract = this.contractToContextInfoPart(contract, this.contextTree.modules);
        if (!this.contextInfoPartIsEqual(ctx.contract, newContract)) {
          ctx.contract = newContract;
          changed = true;
        }
      }
    } else if (ctx.project && !ctx.program) {
      const program = (this.contextTree.programs || []).find(p =>
        (p.projects || []).some(
          j => j.entityInstanceId === ctx.project.entityInstanceId * 1 || j.id === ctx.project.id * 1,
        ),
      );
      const project = (program?.projects || []).find(
        j => j.entityInstanceId === ctx.project.entityInstanceId * 1 || j.id === ctx.project.id * 1,
      );
      if (!ctx.program && program?.entityInstanceId) {
        ctx.program = this.programToContextInfoPart(program, this.contextTree.modules);
        changed = true;
      }
      if (project?.entityInstanceId) {
        const newProject = this.projectToContextInfoPart(project, this.contextTree.modules);
        if (!this.contextInfoPartIsEqual(ctx.project, newProject)) {
          ctx.project = newProject;
          changed = true;
        }
      }
    } else if (ctx.program) {
      const program = (this.contextTree.programs || []).find(
        p => p.entityInstanceId === ctx.program.entityInstanceId * 1 || p.id === ctx.program.id * 1,
      );
      if (program?.entityInstanceId) {
        const newProgram = this.programToContextInfoPart(program, this.contextTree.modules);
        if (!this.contextInfoPartIsEqual(ctx.program, newProgram)) {
          ctx.program = newProgram;
          changed = true;
        }
      }
    }

    if (changed) {
      this.context.set({ ...ctx });
    }
  }

  ngOnInit(): void {
    this.observer = new ResizeObserver(entries => {
      const entry = entries[0];
      this.setContextContentWidth(entry.contentRect.width);
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
    this.observer.observe(this.elementRef.nativeElement);

    this.store.pipe(select(menuSelectors.get)).subscribe(menu => {
      this.isMenuOpen.set(menu?.isOpen ?? false);
    });

    // has to be loaded first
    // first reason is because there is logice based on the number of contract, if there is only one or more contracts
    // second reason is because the list of contracts is needed later even if the overlay is never shown
    this.loadContextTree();
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  nodeClick(node: ContextFlatNode): void {
    if (!node.context.hasPermission) {
      return;
    }

    const ctx = this.getContextFromNode(node);

    // clear current selection and mark clicked node as selected
    this.treeControl.dataNodes.forEach(n => (n.selected = false));
    node.selected = true;

    this.context.set(ctx);
    this.isContextOverlayOpen.set(false);
  }

  toggleContextOverlay(): void {
    if (!this.canOpenOverlay()) {
      return;
    }
    this.isContextOverlayOpen.set(!this.isContextOverlayOpen());
  }

  setShowType(showType: ShowTypeEnum): void {
    this.showType.set(showType);
    this.loadContextTree();
  }

  private getContextFromNode(node: ContextFlatNode): ContextInfo | undefined {
    if (!node) {
      return undefined;
    }
    const ctx: ContextInfo = {};
    let program: ContextFlatNode = undefined;
    let project: ContextFlatNode = undefined;
    let contract: ContextFlatNode = undefined;

    if (node.type === 'contract') {
      contract = node;
      project = this.findParentNode(contract);
      program = this.findParentNode(project);
    } else if (node.type === 'project') {
      project = node;
      program = this.findParentNode(project);
    } else if (node.type === 'program') {
      program = node;
    }

    ctx.program = program?.context;
    ctx.project = project?.context;
    ctx.contract = contract?.context;

    return ctx;
  }

  private findParentNode(node: ContextFlatNode | undefined): ContextFlatNode | undefined {
    if (!node || node.level === 0) {
      return undefined;
    }
    const index = this.treeControl.dataNodes.indexOf(node);
    const parent = [...this.treeControl.dataNodes.filter((_, i) => i < index)]
      .reverse()
      .find(n => n.level === node.level - 1);
    return parent;
  }

  private loadContextTree(forceLoad?: boolean): void {
    this.isLoadingTree.set(true);

    if (forceLoad || !this.dataSourceOriginalData.length) {
      this.contextService.getContextTree(this.userId()).subscribe(tree => {
        this.contextTree = tree;
        this.contextData.emit(tree);
        this.setContextTree();
      });
    } else {
      this.setContextTree();
    }
  }

  private getAllProjects(tree: ContextTree): ContextProject[] {
    const projects: ContextProject[] = (tree.programs || []).reduce((acc, program) => {
      acc.push(...(program.projects || []));
      return acc;
    }, [] as ContextProject[]);
    return projects;
  }

  private getAllContracts(tree: ContextTree): ContextContract[] {
    const contracts: ContextContract[] = this.getAllProjects(tree).reduce((acc, project) => {
      acc.push(...(project.contracts || []));
      return acc;
    }, [] as ContextContract[]);
    return contracts;
  }

  private setContextTree(): void {
    if (this.useOnlyContract()) {
      // get all the contracts from all programs and projects
      const contracts = this.getAllContracts(this.contextTree);
      this.dataSourceOriginalData = this.contractsToContextNode(contracts, this.contextTree.modules);
      this.contractsNumber.set(contracts.length);
    } else {
      // the all the contexts that have a program
      const contextsWithProgram: ContextNode[] = this.programsToContextNode(
        (this.contextTree?.programs || []).filter(program => !!program.id),
        this.contextTree.modules,
      );

      // the all the contexts that do not have a program
      const contextWithoutProgram = (this.contextTree.programs || []).find(program => !program.id);
      const contextsWithoutProgram: ContextNode[] = this.projectsToContextNode(
        contextWithoutProgram?.projects,
        this.contextTree.modules,
      );

      this.dataSourceOriginalData = contextsWithProgram.concat(contextsWithoutProgram);

      this.contractsNumber.set(this.getAllContracts(this.contextTree).length);
    }

    this.noData.set(!this.dataSourceOriginalData.length);
    if (this.noData()) {
      localStorage.setItem('currentContract', '0');
    }

    if (this.setContextAfterLoadingTree) {
      this.setContextAfterLoadingTree = false;
      this.attachMissingPartsWhenNotSet(this.context());
    }

    // if current context is not found in the tree then take the first one
    if (this.isContextDefined() && this.contractsNumber() >= 1) {
      // if contract is selected, verify if it is in the tree
      if (this.context().contract?.id) {
        const contracts = this.getAllContracts(this.contextTree);
        if (!contracts.some(c => c.id === this.context().contract.id)) {
          this.setFirstContractAsCurrentContext();
        }
      }
      // if project is selected, verify if it is in the tree
      else if (this.context().project?.id) {
        const projects = this.getAllProjects(this.contextTree);
        if (!projects.some(p => p.id === this.context().project.id)) {
          this.setFirstContractAsCurrentContext();
        }
      }
      // if program is selected, verify if it is in the tree
      else if (this.context().program?.id) {
        const programs = this.contextTree.programs;
        if (!programs.some(p => p.id === this.context().program.id)) {
          this.setFirstContractAsCurrentContext();
        }
      }
    }

    // set the initial context when there is no context provided and we have at least a contract
    if (!this.isContextDefined() && this.contractsNumber() >= 1) {
      this.setFirstContractAsCurrentContext();
    }

    this.search(this.searchControl.value);
  }

  private setFirstContractAsCurrentContext() {
    const program = this.contextTree.programs.length ? this.contextTree.programs[0] : undefined;
    const project = program.projects.length ? program.projects[0] : undefined;
    const contract = project.contracts.length ? project.contracts[0] : undefined;
    if (contract) {
      this.context.set({
        program: this.programToContextInfoPart(program, this.contextTree.modules),
        project: this.projectToContextInfoPart(project, this.contextTree.modules),
        contract: this.contractToContextInfoPart(contract, this.contextTree.modules),
      });
    }
  }

  private updateOriginalData(context: ContextInfo): void {
    (this.contextTree?.programs || []).forEach(program => {
      if (program.entityInstanceId === context.program?.entityInstanceId) {
        program.currencyId = context.program?.currencyId;
        program.hasPermission = context.program?.hasPermission;
        program.name = context.program?.name;
      }
      (program.projects || []).forEach(project => {
        if (project.entityInstanceId === context.project?.entityInstanceId) {
          project.currencyId = context.project?.currencyId;
          project.hasPermission = context.project?.hasPermission;
          project.name = context.project?.name;
        }
        (project.contracts || []).forEach(contract => {
          if (contract.entityInstanceId === context.contract?.entityInstanceId) {
            contract.currencyId = context.contract?.currencyId;
            contract.hasPermission = context.contract?.hasPermission;
            contract.name = context.contract?.name;
            contract.no = context.contract?.no;
            contract.isArchived = context.contract?.isArchived;
            contract.isClosed = context.contract?.isClosed;
            contract.isUsed = context.contract?.isUsed;
          }
        });
      });
    });
  }

  private scrollIntoView(node: ContextFlatNode): void {
    const nodeElement = this.getNodeElement(node);
    if (!nodeElement) {
      return;
    }

    const scrollContainer = this.contextScrollContainer?.nativeElement ?? null;
    if (!scrollContainer) {
      return;
    }

    // Calculate the required scroll position
    const nodeHeight = nodeElement.offsetHeight;
    const index = this.treeControl.dataNodes.indexOf(node);
    const scrollPosition = Math.max(0, nodeHeight * (index - node.level));

    // Move scrolling to the calculated position
    scrollContainer.scrollTo({ top: scrollPosition, behavior: 'auto' });
  }

  private getNodeElement(node: ContextFlatNode): HTMLElement | null {
    if (this.contextNodeElements) {
      const dataSource = this.treeControl.dataNodes;
      const index = dataSource.indexOf(node);
      if (index !== -1 && this.contextNodeElements.length > index) {
        return this.contextNodeElements.get(index)?.nativeElement ?? null;
      }
    }
    return null;
  }

  private programsToContextNode(programs: ContextProgram[], modules: ContextModule[]): ContextNode[] {
    return (programs || [])
      .map(
        program =>
          ({
            context: this.programToContextInfoPart(program, modules),
            type: 'program',
            children: this.projectsToContextNode(program?.projects, modules),
          }) as ContextNode,
      )
      .filter(
        program =>
          ((this.showType() === ShowTypeEnum.OnlyClosed || this.showType() === ShowTypeEnum.OnlyArchived) &&
            program.children.length) ||
          this.showType() === ShowTypeEnum.Active ||
          this.showType() === ShowTypeEnum.All,
      )
      .sort(this.sortContextNodeAsc);
  }

  private projectsToContextNode(projects: ContextProject[], modules: ContextModule[]): ContextNode[] {
    return (projects || [])
      .map(
        project =>
          ({
            context: this.projectToContextInfoPart(project, modules),
            type: 'project',
            children: this.contractsToContextNode(project?.contracts, modules),
          }) as ContextNode,
      )
      .filter(
        project =>
          ((this.showType() === ShowTypeEnum.OnlyClosed || this.showType() === ShowTypeEnum.OnlyArchived) &&
            project.children.length) ||
          this.showType() === ShowTypeEnum.Active ||
          this.showType() === ShowTypeEnum.All,
      )
      .sort(this.sortContextNodeAsc);
  }

  private contractsToContextNode(contracts: ContextContract[], modules: ContextModule[]): ContextNode[] {
    return (contracts || [])
      .filter(
        c =>
          (!c.isArchived && !c.isClosed && this.showType() === ShowTypeEnum.Active) ||
          (c.isClosed && this.showType() === ShowTypeEnum.OnlyClosed) ||
          (c.isArchived && this.showType() === ShowTypeEnum.OnlyArchived) ||
          this.showType() === ShowTypeEnum.All,
      )
      .map(
        contract =>
          ({
            context: this.contractToContextInfoPart(contract, modules),
            type: 'contract',
          }) as ContextNode,
      )
      .sort(this.sortContextNodeAsc);
  }

  private partToContextInfoPart(
    part: ContextContract | ContextProject | ContextProgram,
    module: ContextModule,
  ): ContextInfoPart {
    return {
      id: part.id,
      name: part.name,
      no: (part as ContextContract)?.no,
      entityInstanceId: part.entityInstanceId,
      moduleId: part.moduleId,
      moduleAbbr: module.abbr,
      moduleColor: module.color,
      moduleIconId: module.iconId,
      currencyId: part.currencyId,
      hasPermission: part.hasPermission,
      isArchived: (part as ContextContract)?.isArchived,
      isClosed: (part as ContextContract)?.isClosed,
      isUsed: (part as ContextContract)?.isUsed,
    };
  }

  private contractToContextInfoPart(part: ContextContract, modules: ContextModule[]): ContextInfoPart {
    const module = (modules || []).find(module => module.moduleId === CONTRACT_MODULE_ID);
    return this.partToContextInfoPart(part, module);
  }

  private projectToContextInfoPart(part: ContextProject, modules: ContextModule[]): ContextInfoPart {
    const module = (modules || []).find(module => module.moduleId === PROJECT_MODULE_ID);
    return this.partToContextInfoPart(part, module);
  }

  private programToContextInfoPart(part: ContextProgram, modules: ContextModule[]): ContextInfoPart {
    const module = (modules || []).find(module => module.moduleId === PROGRAM_MODULE_ID);
    return this.partToContextInfoPart(part, module);
  }

  private sortContextNodeAsc(a: ContextNode, b: ContextNode): number {
    if (a?.context?.isArchived || a?.context?.isClosed) {
      return 1;
    }

    if (b?.context?.isArchived || b?.context?.isClosed) {
      return -1;
    }

    // Compare no first (ascending)
    const noComparison = (a?.context?.no || '').localeCompare(b?.context?.no || '');
    if (noComparison !== 0) {
      return noComparison;
    }

    // If no is equal, compare name (ascending)
    return (a?.context?.name || '').localeCompare(b?.context?.name || '');
  }

  private setContextContentOrigin(target: DOMRectReadOnly | ElementRef): void {
    let x = target instanceof ElementRef ? target.nativeElement.parentElement.offsetLeft : target.left;
    const y =
      target instanceof ElementRef
        ? target.nativeElement.parentElement.offsetTop + target.nativeElement.parentElement.offsetHeight
        : target.top + target.height;

    if (this.showOnlyLastContextPart()) {
      this.contextContentOrigin.set({ x: 4, y });
    } else {
      if (this.isMenuOpen()) {
        x = x + (document.querySelector('app-menu')?.clientWidth ?? 0);
      }
      this.contextContentOrigin.set({ x, y });
    }
  }

  private setContextContentWidth(width: number): void {
    if (this.showOnlyLastContextPart()) {
      this.hostWidth.set(window.innerWidth - 32);
    } else {
      this.hostWidth.set(width);
    }
  }

  private search(value: string) {
    this.isLoadingTree.set(true);

    this.dataSource.data = [...this.dataSourceOriginalData].map(d => this.filterContextNode(d, value)).filter(n => !!n);
    this.treeControl.expandAll();

    const contextEntityInstanceId =
      this.context()?.contract?.entityInstanceId ??
      this.context()?.project?.entityInstanceId ??
      this.context()?.program?.entityInstanceId;
    const selectedNode = this.treeControl.dataNodes.find(n => contextEntityInstanceId === n.context.entityInstanceId);
    if (selectedNode) {
      selectedNode.selected = true;
      this.cdRef.detectChanges();
    }

    this.isLoadingTree.set(false);

    if (selectedNode) {
      if (this.scrollIntoViewTimeout) {
        clearTimeout(this.scrollIntoViewTimeout);
      }
      this.scrollIntoViewTimeout = setTimeout(() => {
        this.scrollIntoView(selectedNode);
      }, 100);
    }
  }

  private filterContextNode(node: ContextNode, searchText: string): ContextNode | undefined {
    const newNode = { ...node };
    if (newNode.children && newNode.children.length) {
      newNode.children = newNode.children.map(n => this.filterContextNode(n, searchText)).filter(n => !!n);
      if (newNode.children.length) {
        return newNode;
      }
    }
    if (this.filterContextPart(newNode.context, searchText)) {
      return newNode;
    }
    return undefined;
  }

  private filterContextPart(ctx: ContextInfoPart, searchText: string): boolean {
    return `${ctx.no}${ctx.no ? ' - ' : ''}${ctx.name}`.toLowerCase().includes(searchText.toLowerCase());
  }
}
