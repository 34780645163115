<div class="cipo-bg--primary-100 full-height">
    <p class="m-b-0">
        <span class="label">Name</span>
        {{ "test" }}
    </p>
    <p class="m-b-0">
        <span class="label">Label</span>
        {{ "test2" }}
    </p>
    <p class="m-b-0">
        <span class="label">Type</span>
        {{ "test 3" }}
    </p>
</div>
