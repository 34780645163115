import { cipo } from 'cipo';

cipo.factory('printService', ['$timeout', 'fileService', function ($timeout, fileService) {

    var printService = {};

    printService.printPdfFileToIframe = function(file) {
        $timeout(function () {
            var iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = window.URL.createObjectURL(file);
            document.body.appendChild(iframe);

            $timeout(function () {
                iframe.contentWindow.print();
            }, 400);
        }, 0);
    }

    printService.printPdfToIframe = function(content) {
        printService.printPdfFileToIframe(fileService.createFile(content, '', 'application/pdf'));
    }

    return printService;
}]);