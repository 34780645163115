import { cipo } from 'cipo';

cipo.controller('schedulesController',
    function ($scope, $state, Manager, userService, rememberManagerService, $mdDialog, URI, ACTIONS, Message) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        var sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }

        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.SCHEDULE.SEARCH,
            otherParams: {
                ShowDisabled: true
            },
            dataWrapper: 'data',
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.editSchedule(row.id);
            },
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    icon: 'mdi mdi-calendar-plus',
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.editSchedule();
                    }
                },
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editSchedule(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editSchedule(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    // displayOnRow: $scope.operations.Delete ? true : false,
                    condition: function (row) {
                        if (row.operations.Delete)
                            return true;
                        else return false;
                    },
                    conditionOnTop: function () {
                        if ($scope.operations.Delete)
                            return true;
                        else return false;
                    },
                    // alwaysOnTop: false,
                    click: function (rows) {
                        $scope.deleteSchedule(sendList(rows));
                    }
                },
            ]
        });

        $scope.manager.set_Columns([
            { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 100, fieldTypeId: 1},
        ]);

        $scope.manager.loading = true;
        $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
            function() {
                $scope.manager.loading = false;
            },
            function() {
                $scope.manager.loadPage()
                .finally(function () {
                    $scope.manager.loading = false;
                });
            }
        );

        $scope.editSchedule = function (obj, ev) {
            $mdDialog.show({
                locals: { obj: obj, operations: $scope.operations, module: $scope.module },
                controller: 'editScheduleController',
                templateUrl: '/ng/views/admin/modals/editSchedule.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function () {
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                });
        }

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.deleteSchedule = function (list) {

            var list = list || [];
            if (list.length) {
                //var d = dialogs.confirm('Activating Roles', 'Are you sure you want to activate the selected Role(s)?');
                $mdDialog.show(confirm('Deleting Schedule', 'Are you sure you want to delete the selected Schedule?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.SCHEDULE.DELETE.method](URI.SCHEDULE.DELETE, { body: { list: list } })
                            .then(function () {
                                Message.info('Schedule deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        // cancel pressed
                    });

            } else {
                Message.info('Please select the item(s) to delete');
            }

        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });


    });
