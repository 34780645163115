<div fxLayout fxLayoutGap="12px">
    <div class="gridstack-pattern" fxFlex="80">
        @if (viewInitialized()) {
            <gridstack [options]="gridOptions" (changeCB)="onChange($event)">
                <gridstack-item [options]="{ id: '123', x: 0, y: 0, w: 3, h: 1 }">
                    <app-gridster-item></app-gridster-item>
                </gridstack-item>
                <gridstack-item [options]="{ id: '1234', x: 1, y: 1, w: 3, h: 1 }">
                    <app-gridster-item></app-gridster-item>
                </gridstack-item>
            </gridstack>
        }
    </div>
    <div fxFlex>
        <div fxLayout fxLayoutAlign="space-between center">
            <h2>Fields</h2>
            <button mat-raised-button [matMenuTriggerFor]="fieldsMenu">
                Add new
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-form-field class="cipo-input hide-subscript p-0 full-width">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="searchCriteria" />
            @if (searchCriteria()) {
                <button mat-icon-button matSuffix (click)="searchCriteria.set('')">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-checkbox class="m-t-sm" [(ngModel)]="showContractFields">Show form fields</mat-checkbox>

        <div fxLayout="column" fxLayoutGap="8px">
            @for (item of [1, 2, 3, 4, 5, 6, 7]; track $index) {
                <app-field-card />
            }
        </div>
    </div>
</div>

<!-- add field menu -->
<mat-menu #fieldsMenu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="text">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Text] }}</mat-icon>
        Text
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Number)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Number] }}</mat-icon>
        Number
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Checkbox)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Checkbox] }}</mat-icon>
        Checkbox
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.DateTime)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.DateTime] }}</mat-icon>
        Date-Time
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Attachment)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Attachment] }}</mat-icon>
        Attachment
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Table)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Table] }}</mat-icon>
        Table
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Weather)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Weather] }}</mat-icon>
        Weather
    </button>
</mat-menu>

<mat-menu #text="matMenu">
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Text)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Text] }}</mat-icon>
        Text
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Multiline)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Multiline] }}</mat-icon>
        Multiline
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Editor)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Editor] }}</mat-icon>
        Editor
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Select)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Select] }}</mat-icon>
        Select
    </button>
    <button mat-menu-item (click)="addNewField(FIELD_TYPES.Radio)">
        <mat-icon class="material-icons-outlined">{{ FIELD_ICONS[FIELD_TYPES.Radio] }}</mat-icon>
        Radio Group
    </button>
</mat-menu>
