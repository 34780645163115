import { cipo } from 'cipo';

        var registerController = function ($transition$, $scope, $state, Message, Register, $timeout) {
            var inviteId = $transition$.params().i || null;

            $scope.registerObject = new Register({ inviteId: inviteId });

            $scope.disabledBtn = false;
            $scope.register = function () {
                $scope.disabledBtn = true;
                $scope.registerObject.send_Data().then(function () {
                    // login after register
                    /*try {
                        var loginObject = new Login();
                        loginObject.properties.username = $scope.registerObject.properties.email;
                        loginObject.properties.password = $scope.registerObject.properties.password;
                        loginObject.properties.rememberMe = false;

                        loginObject.get_Token().then(function () {
                            $state.go("main");
                        }).catch(function (serverCalled) {
                            $scope.disabledBtn = false;
                            if (serverCalled) Message.error('Could not log you in.');
                        });
                    } catch (e) {
                        console.error(e);
                    }*/
                    $state.go("login");
                }).catch(function (e) {
                    Message.error(e ? e : 'Could not register user on the system');
                }).finally(function () {
                    $scope.disabledBtn = false;
                });
            }

            $scope.login = function () {
                $state.go("login");
            }

            $scope.getGoogleInfo = function () {
                $scope.registerObject.getGoogleInfo()
                    .then(() => $timeout(() => $scope.$apply(), 200));
            }


            $scope.registerObject.form.onSubmitForm = function () {
                $scope.register();
            }
        };

cipo.controller('registerController', registerController);
