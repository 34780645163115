import { Component, Input, OnInit, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

import { CipoTableOptions, TableColumn } from '../../../shared/components/data-list/common';
import { PAGE_SIZE_OPTIONS } from '../../../shared/consts';
import { NotificationService } from '../../../shared/services/notification.service';
import { ModuleParameterService } from './module-parameter.service';
import { ModuleParamaterEnum, ModuleParameter } from './module-parameter.model';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components.module';
import { ModuleParameterDialogComponent } from './module-parameter-dialog/module-parameter-dialog.component';

@Component({
  selector: 'app-module-parameter',
  templateUrl: './module-parameter.component.html',
  standalone: true,
  providers: [ModuleParameterService],
  imports: [SharedComponentsModule],
})
export class ModuleParameterComponent implements OnInit {
  translate = inject(TranslateService);
  dialog = inject(MatDialog);
  notificate = inject(NotificationService);
  moduleParameterService = inject(ModuleParameterService);
  loading = true;
  criteria = '';
  currentParameterId = ModuleParamaterEnum.agency_response_days;

  @Input('entityinstanceid') entityInstanceId: any;

  tableOptions: CipoTableOptions = {
    clickableRows: true,
    pagination: {
      pageSize: PAGE_SIZE_OPTIONS.long[3],
      pageSizeOptions: PAGE_SIZE_OPTIONS.long,
    },
    sort: {
      active: 'name',
      direction: 'asc',
    },
  };

  tableColumns: TableColumn[] = [
    {
      id: 1,
      name: 'moduleName',
      displayName: this.translate.instant('moduleParameter.module'),
      sortable: true,
      width: 5,
    },
    {
      id: 2,
      name: 'value',
      displayName: this.translate.instant('moduleParameter.value'),
      sortable: false,
      width: 2,
    },
    {
      id: 3,
      name: 'moduleValue',
      displayName: this.translate.instant('moduleParameter.moduleValue'),
      sortable: false,
      width: 2,
    },
  ];
  tableData: ModuleParameter[] = [];
  initialTableData: ModuleParameter[] = [];

  entityInstanceLocked = signal(false);
  @Input('entityinstancelocked')
  set _entityInstanceLocked(value: boolean) {
    this.entityInstanceLocked.set(value);
    this.tableOptions = { ...this.tableOptions, clickableRows: !value };
  }

  constructor() {}

  ngOnInit(): void {
    this.getMappedData();
  }

  getMappedData() {
    this.loading = true;
    this.moduleParameterService
      .getModules(this.currentParameterId, this.entityInstanceId)
      .pipe(
        tap(module => {
          this.initialTableData = module;
          this.search(this.criteria);
        }),
      )
      .subscribe(() => (this.loading = false));
  }

  search(criteria: string) {
    this.criteria = criteria;
    if (!criteria) {
      this.tableData = this.initialTableData;
      return;
    }

    this.tableData = this.initialTableData.filter(row =>
      row.moduleName.toString().toLowerCase().includes(criteria.toLowerCase()),
    );
  }

  filter(event: MatSelectChange) {
    switch (event.value) {
      case '1':
        this.tableData = this.tableData.filter(row => row.moduleOnMenu);
        break;

      default:
        this.search(this.criteria);
        break;
    }
  }

  openSettingModal(moduleParameter: ModuleParameter) {
    this.dialog
      .open(ModuleParameterDialogComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        width: '500px',
        minHeight: '200px',
        data: moduleParameter,
      })
      .afterClosed()
      .pipe(
        filter(value => value !== undefined),
        switchMap(value =>
          this.moduleParameterService.saveModuleParameter({ ...moduleParameter, value }, this.currentParameterId),
        ),
      )
      .subscribe(() => {
        this.getMappedData();
        this.notificate.success(this.translate.instant('moduleParameter.parameterUpdated'));
      });
  }
}
