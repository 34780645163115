import { Injectable, inject } from '@angular/core';
import { Observable, delay, map, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TenantSetting, TenantSettingsEnum } from '../../../module/instance/tenant-settings/tenant-settings.model';
import { KeyValueType } from '../../../models/common';
import { TableColumn } from '../../../shared/components/data-list/common';

@Injectable()
export class DemoTenantSettingsService {
  translate = inject(TranslateService);
  tenantSettings: TenantSetting[] = TENANT_SETTINGS;

  updateSetting(setting: TenantSetting) {
    const { id, value } = setting;
    const currentSetting = this.tenantSettings.find(s => s.id === id);
    if (currentSetting) {
      currentSetting.value = value;
      return of(currentSetting.value).pipe(delay(1000));
    }
    return of(false);
  }
  getTenantSettings() {
    return of(this.tenantSettings).pipe(delay(1000));
  }

  getSettingOptions(id: TenantSettingsEnum): Observable<KeyValueType<number | string>[]> {
    switch (id) {
      case TenantSettingsEnum.load_more:
        const res = [1, 2].map(key => ({ key, value: this.translate.instant('tenantSetting.loadMore.' + key) }));
        return of(res);
      case TenantSettingsEnum.time_zone:
        const response = [
          { key: 'Pacific Standard Time', value: 'Pacific Standard Time' },
          { key: 'Eastern Standard Time', value: 'Eastern Standard Time' },
          { key: 'Central Standard Time', value: 'Central Standard Time' },
          { key: 'Mountain Standard Time', value: 'Mountain Standard Time' },
          { key: 'Alaska Standard Time', value: 'Alaska Standard Time' },
          { key: 'Hawaii-Aleutian Standard Time', value: 'Hawaii-Aleutian Standard Time' },
          { key: 'Samoa Standard Time', value: 'Samoa Standard Time' },
        ];
        return of(response);
      default:
        return of([]);
    }
  }

  getColumns(): Observable<TableColumn[]> {
    return this.translate.get('general.yes').pipe(
      map(() => [
        {
          id: 1,
          name: 'id',
          displayName: '#',
          sortable: true,
          width: 1,
        },
        {
          id: 2,
          name: 'name',
          displayName: this.translate.instant('general.table.name'),
          sortable: true,
          width: 2,
        },
        {
          id: 3,
          name: 'description',
          displayName: this.translate.instant('general.table.description'),
          sortable: true,
          width: 5,
        },
        {
          id: 4,
          name: 'value',
          displayName: this.translate.instant('general.table.value'),
          width: 2,
        },
      ]),
    );
  }
}

const TENANT_SETTINGS: TenantSetting[] = [
  {
    id: 1,
    key: 'pp_pay_over_100',
    value: false,
    type: 'boolean',
    name: 'Payments over 100%',
    description: 'Allow payments to exceed 100% of each SOV line item value',
  },
  {
    id: 2,
    key: 'time_zone',
    value: 'Pacific Standard Time',
    type: 'select',
    name: 'Time Zone',
    description: '-',
  },
  {
    id: 4,
    key: 'percent_near_overdue',
    value: 80,
    type: 'rangePercent',
    name: 'Near Overdue ',
    description: "Set % of time elapsed from set Response Days after which document is maked as 'Near Overdue'",
  },
  {
    id: 6,
    key: 'load_more',
    value: 1,
    type: 'select',
    name: 'Pagination Preference',
    description: 'Set default pagination preference',
  },
  {
    id: 7,
    key: 'more_than_one_payments_open',
    value: false,
    type: 'boolean',
    name: 'Open Payments',
    description: 'Allow more than one Progress Payment to be open at a time',
  },
  {
    id: 8,
    key: 'pp_pay_on_negative',
    value: false,
    type: 'boolean',
    name: 'Negative value Payments',
    description: 'Allow negative value payments',
  },
  {
    id: 9,
    key: 'name_conv_to_7bit_ascii',
    value: false,
    type: 'boolean',
    name: 'Replace special characters',
    description: 'Replace all special characters in file names to avoid errors',
  },
  {
    id: 10,
    key: 'save_attachments_to_project_folder',
    value: false,
    type: 'boolean',
    name: 'Save attachments to project folder',
    description: '-',
  },
  {
    id: 11,
    key: 'user_allocation',
    value: false,
    type: 'boolean',
    name: 'User Allocation',
    description: "Show the user allocation field in the user's page and the Contract Assignments page",
  },
  {
    id: 12,
    key: 'allow_replay_notifications',
    value: true,
    type: 'boolean',
    name: 'Reply to notifications',
    description: 'Allow replies to be sent starting from CIPO notifications',
  },
  {
    id: 13,
    key: 'user_rate',
    value: false,
    type: 'boolean',
    name: 'User Rate',
    description: "Show the user rate field in the user's page and the Contract Assignments page",
  },
  {
    id: 14,
    key: 'menu_folder_auto_collapse',
    value: true,
    type: 'boolean',
    name: 'Menu Auto-collapse',
    description: 'Menu folders auto-collapse when switching though modules',
  },
  {
    id: 16,
    key: 'auto_expand_adjustments_section',
    value: true,
    type: 'boolean',
    name: 'Auto-expand Adjustments Section',
    description: 'Adjustments section in Payment module is expanded by default',
  },
];
