export enum DisplayType {
  None = 0,
  Text = 1,
  MultiLine = 2,
  Select = 3,
  Radio = 4,
  CheckBox = 5,
  Attachment = 6,
  User = 7,
  Table = 8,
  RichText = 9,
  Weather = 10,
  Line = 11,
  Status = 12,
  IndicatorBasedOnValue = 13,
  IndicatorUp = 14,
  IndicatorDown = 15,
  PercentDurationProgress = 16,
  PercentAmountProgress = 17,
}
