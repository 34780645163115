

export interface WeatherFieldModel {
    fieldId: number;
    fieldInstanceId: number;
    time: string;
    temperature: number;
    windSpeed: number;
    phrase: string;
    humidity: number;
    precipitation: number;
}