import { cipo } from 'cipo';
import moment from 'moment';

cipo.controller('progresspaymentController',
    function ($transition$, $scope, $state, Model, Menu, userService, currencyService, URI, ProgressPaymentSA, $q, Message, $mdDialog, Dictionaries) {
        if (!$scope.mainViewLoaded) {
            return;
        }

        const moduleCode = 'PP';
        const routeParams = {...$transition$.params()};

        $scope.module = userService.system.modules[moduleCode];
        $scope.module.baseName = $scope.module.name;

        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor(moduleCode); }
        });

        Object.defineProperty($scope, 'hasManageDrafts', {
            get: function () { return $scope.operations["Manage Drafts"]; }
        });

        currencyService.getAndSetCurrencyById($scope);
        
        Object.defineProperty($scope, 'userService', {
            get: function () {
                return userService;
            }
        });

        Object.defineProperty($scope, 'nonBindingSignature', {
            get: function () {
                return (userService.system.userdata.signature || {}).signatureText }
        });

        var moduleTypes;

        Dictionaries.ModuleTypes(-3)
            .then(function (r) {
                moduleTypes = r;
            })
            .catch(function () {})

        $scope.isPaymentRequestAllowed = function (selectedPeriodRequired) {
            if (selectedPeriodRequired && !$scope.selectedPeriod)
                return false;
            return !$scope.selectedPeriod ||
                $scope.selectedPeriod.key == $scope.currentPeriod.id ||
                ($scope.currentPeriod.allowedPastPeriodIDs || []).indexOf($scope.selectedPeriod.key) != -1
                ;
        }

            $scope.$on("$mdMenuClose", function () {
                try {
                    $scope.pp.assignments.searchUser = '';
                } catch { }
            });

            $scope.showPercentageEditable = function () {
                if ($scope.pp.generalInfo.isContractClosedOrArchived) return false;
                if ($scope.pp.generalInfo.approvalId) return false;
                if ($scope.pp.generalInfo.isDraftState && $scope.pp.isEditablePercentage) return true;
                if ($scope.pp.editMode && $scope.pp.isEditablePercentage) return true;
                return false;
            }

            $scope.showAmountEditable = function () {
                if ($scope.pp.generalInfo.isContractClosedOrArchived) return false;
                if ($scope.pp.generalInfo.approvalId) return false;
                if ($scope.pp.generalInfo.isDraftState && $scope.pp.isEditableAmount) return true;
                if ($scope.pp.editMode && $scope.pp.isEditableAmount) return true;
                return false;
            }

            $scope.showQuantityEditable = function () {
                if ($scope.pp.generalInfo.isContractClosedOrArchived) return false;
                if ($scope.pp.generalInfo.approvalId) return false;
                if ($scope.pp.generalInfo.isDraftState && $scope.pp.isEditableQuantity) return true;
                if ($scope.pp.editMode && $scope.pp.isEditableQuantity) return true;
                return false;
            }

            $scope.showEditableInput = function (item, col, module) {
                if ($scope.pp.generalInfo.isContractClosedOrArchived) return false;
                if (col.isEditableAmount && $scope.showAmountEditable() && !item.readonly && !module.isBlocked) return true;
                if (col.isEditablePercentage && $scope.showPercentageEditable() && !item.readonly && !module.isBlocked) return true;
                if (col.isEditableQuantity && $scope.showQuantityEditable() && !item.readonly && !module.isBlocked) return true;
                return false;
            }

        $scope.approve = function (isApprove) {
            $scope.pp.approve(isApprove)
                .then(function (r) {
                    if (!$scope.selectedPeriod.isMultiplePeriod && isApprove) {
                        $scope.selectedPeriod.hasMultiplePayments = false;
                    };
                    $scope.get_periods();
                })
                .catch(function () { })
        }

        $scope.goToProgressPayment = function(periodId, entityInstanceId) {
            var paramsNew = angular.copy(routeParams);
            paramsNew.periodId = periodId || 0;
            paramsNew.entityInstanceId = entityInstanceId || 0;
            $state.go('tenant.progresspayment', paramsNew, { reload: true });
        }

        $scope.loadPPDetails = function (pp, period) {
            $scope.errors = "";
            checkInitiator();
            if (period && $scope.selectedPeriod && period.key != $scope.selectedPeriod.key) {
                $scope.selectedPeriod = period;
                if (!period.hasMultiplePayments) {
                    $scope.goToProgressPayment($scope.selectedPeriod.key, $scope.selectedPeriod.entityInstanceId);
                }
                else {
                    $scope.pp = null;
                    $scope.get_ppList(period.key);
                }
            } else {
                $scope.goToProgressPayment(0, pp?.id);
            }
        }

        $scope.movePP = function (pp, period, event) {
            if (event) event.stopPropagation(); 
            var dataURL = URI.PROGRESS_PAYMENT.PP_MOVE;
            var params = {
                entityInstanceId: pp.entityInstanceId,
                periodId: period.key,
                contractId: userService.system.userdata.contractId || 0
            };

            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    if (r) Message.warning(r);

                    $scope.get_periods()
                        .then(function() {
                            $scope.loadPPDetails(null, period);
                        })
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
        }

        $scope.userIsInitiator = false;
        var checkInitiator = function () {
            var dataURL = URI.PROGRESS_PAYMENT.PP_IS_USER_INITIATOR;
            var params = {
                contractId: userService.system.userdata.contractId || 0
            };

            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    $scope.userIsInitiator = r;
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
        }
        checkInitiator();       

        $scope.get_ppList = function (periodId) {
            $scope.loadingList = true;
            $scope.pp = null;
            checkInitiator();
            var p = $q.defer();
            var period = periodId || ($scope.currentPeriod || {}).id;
            var dataURL = URI.PROGRESS_PAYMENT.PP_MULTIPLE_SEARCH;
            var params = {
                contractId: userService.system.userdata.contractId,
                paymentPeriodId: period || 0
            };
            $scope.hasDraft = false;
            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    for (var i = 0; i < r.data.length; i++) {
                        r.data[i].createdOn = r.data[i].createdOn ? moment(r.data[i].createdOn).format(userService.formats.date) : "";
                        r.data[i].updatedOn = r.data[i].updatedOn ? moment(r.data[i].updatedOn).format(userService.formats.date) : "";
                        if (r.data[i].isDraft && (!r.data[i].is_approval || r.data[i].isOwner) || (r.data[i].isRevision && !($scope.selectedPeriod || $scope.currentPeriod).isMultiplePeriod)) {
                            $scope.hasDraft = true;
                        }
                    }
                    $scope.ppList = r.data;

                    p.resolve(r);
                })
                .catch(function (e) {
                    Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.loadingList = false;
                    // self.isBusy = false;
                })

            return p.promise;
        };
        var isPeriodsLoaded = false;
        $scope.multiPPCols = [
            { name: 'paidAmount', label: "Paid amount"},
            { name: 'totalAmount', label: "Total amount" },
            { name: 'status', label: "Status", isStatus: true },
            { name: 'updatedOn', label: "Updated on"},
            { name: 'updatedBy', label: "Updated by" },
            { name: 'createdOn', label: "Created on" },
            { name: 'createdBy', label: "Created by"}
        ];

        $scope.get_currentPeriod = function () {
            var p = $q.defer();
            $scope.errors = "";
            $scope.isCurrentPeriodLoaded = false;
            $scope.isBusyPeriods = true;

            var dataURL = URI.PROGRESS_PAYMENT.PPD_CURRENT;
            Model[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj' })
                .then(function (r) {
                    if (r) {
                        r.startDate = r.startDate ? moment(r.startDate).format(userService.formats.date) : "";
                        r.endDate = r.startDate ? moment(r.endDate).format(userService.formats.date) : "";
                    }
                    $scope.currentPeriod = r;
                    $scope.isCurrentPeriodLoaded = true;
                   
                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                })
                .finally(function() {
                    if (!isPeriodsLoaded) {
                        $scope.get_periods()
                            .then(function() {
                                $scope.reloadOrGetPP();
                            });
                    }
                    else {
                        $scope.selectedPeriod = periodsLookup[$scope.currentPeriod.id];
                        $scope.reloadOrGetPP();
                    }
                });

            return p.promise;
        }

        var periodsLookup = {};

        var createLookup = function (data) {
            var ret = {};
            if (data && data.length)
                for (var i = 0; i < data.length; i++)
                    ret[data[i].key] = data[i];
            return ret;
        }


        $scope.get_periods = function (isFromWatch) {
            
            var p = $q.defer();
            $scope.isBusyPeriods = true;
            var retainLoadedPP = false;
            Dictionaries.PPPeriods({ contractId: userService.system.userdata.contractId })
                .then(function (r) {
                    if (r && r.length) {
                        if (!$scope.currentPeriod) {
                            $scope.currentPeriod = { id: 0, key: 0, name: 'Current Period' };
                            $scope.selectedPeriod = $scope.currentPeriod;
                        }
                        for (var i = 0; i < r.length; i++) {
                            r[i].startDate = r[i].startDate ? moment(r[i].startDate).format(userService.formats.date) : "";
                            r[i].endDate = r[i].startDate ? moment(r[i].endDate).format(userService.formats.date) : "";
                            if (r[i].key == $scope.currentPeriod.id) {
                                r[i].current = true;
                                
                                for (var key in r[i])
                                    if (r[i].hasOwnProperty(key))
                                        $scope.currentPeriod[key] = r[i][key];
                                if(!$scope.selectedPeriod) $scope.selectedPeriod = r[i];
                            }
                            if (r[i].key == ($scope.selectedPeriod || {}).key) {
                                retainLoadedPP = true;
                                for (var key in r[i])
                                    if (r[i].hasOwnProperty(key))
                                        $scope.selectedPeriod[key] = r[i][key];
                            }
                        }

                        if (!$scope.currentPeriod.id) 
                            r.splice(0, 0, { key: 0, value: 'No Current Period', current: true });

                        $scope.periodsDict = r;
                    } 
                    else {
                        $scope.periodsDict = [];
                    }

                    if (!retainLoadedPP && isFromWatch) {
                        $scope.loadPP(false, true);
                    }

                    periodsLookup = createLookup(r);
                    p.resolve();
                    isPeriodsLoaded = true;
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.isBusyPeriods = false;
                })

            return p.promise;
        }

        $scope.createPP = function (screenId) {
            if (!$scope.pp) 
                $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, ($scope.selectedPeriod || {}).key, true);

            $scope.pp.create(null, screenId)
                .then(function (r) {
                    $scope.get_periods();
                })
                .catch(function (e) {})
        }

        $scope.reloadOrGetPP = function() {
            var hasSelectedPeriod = $scope.selectedPeriod && ($scope.selectedPeriod.key * 1) !== 0;
            var periodId = routeParams.periodId * 1, hasPeriod = periodId !==0;
            var entityInstanceId = routeParams.entityInstanceId * 1, hasEntityInstanceId = entityInstanceId !==0;

            $scope.hasManageDrafts;
            if (!hasSelectedPeriod && hasEntityInstanceId) {
                // find period based on entityInstanceId
                $scope.selectedPeriod = ($scope.periodsDict || [])
                    .find(p => (p.entityInstances || []).some(e => 
                        // take requested entityInstanceId because the user can manage drafts
                        $scope.hasManageDrafts && e.entityInstanceId == entityInstanceId
                        // take only the entityInstanceId where the user is initiator 
                        || e.entityInstanceId == entityInstanceId && (!e.isDraft || e.iAmInitiator && e.isDraft)
                    ));

                // set the entity instance only if the period has a status
                // if there is no status it means the user does not have a draft or entity instance
                if ($scope.selectedPeriod && $scope.selectedPeriod.status) {
                    $scope.selectedPeriod.entityInstanceId = entityInstanceId;
                }
            }
            else if (!hasSelectedPeriod && hasPeriod) {
                // find period based on periodId
                $scope.selectedPeriod = ($scope.periodsDict || []).find(p => p.key == periodId);
            }

            if ($scope.selectedPeriod && $scope.selectedPeriod.entityInstanceId === undefined) {
                $scope.selectedPeriod.entityInstanceId = 
                    ($scope.selectedPeriod.entityInstances || []).length 
                    ? $scope.selectedPeriod.entityInstances.find(e => (!e.isDraft || e.iAmInitiator && e.isDraft))?.entityInstanceId 
                    : undefined;
            }

            if (!$scope.selectedPeriod && periodId) {
                // if period not found based on routing but the route has a period, take the period
                $scope.selectedPeriod = ($scope.periodsDict || []).find(p => p.key == periodId);
            }

            if (!$scope.selectedPeriod) {
                // no period found based on the routing
                $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, 0);
            }
            else if ($scope.selectedPeriod && !$scope.selectedPeriod.status) {
                // the period has no PP
                $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, $scope.selectedPeriod.key);
            }
            else if ($scope.selectedPeriod && (periodId != ($scope.selectedPeriod.key || 0) || entityInstanceId != ($scope.selectedPeriod.entityInstanceId || 0))) {
                // reload with period and entityInstanceId
                $scope.goToProgressPayment($scope.selectedPeriod.key, $scope.selectedPeriod.entityInstanceId);
            }
            else {
                if (!$scope.selectedPeriod.hasMultiplePayments) {
                    $scope.pp = new ProgressPaymentSA($scope.module, entityInstanceId, moduleTypes, $scope.selectedPeriod.key);
                }
                else {
                    $scope.get_ppList($scope.selectedPeriod.key);
                }
            }
        }
        
        $scope.loadPP = function (keepPeriods, isFromWatch) {
            $scope.pp = null;
            if (!keepPeriods) {
                $scope.currentPeriod = null;
                $scope.selectedPeriod = null;
                if(!isFromWatch) $scope.periodsDict = [];
                $scope.ppList = [];
                $scope.errors = "";
                $scope.get_currentPeriod(isFromWatch);
            } else {
                $scope.get_periods()
                    .then(function() {
                        $scope.reloadOrGetPP();
                    });
            }
        }

        $scope.delete = function () {
            $scope.pp.delete()
                .then(function () {
                    $scope.goToProgressPayment(routeParams.periodId);
                })
        }

        $scope.deleteFromList = function (id) {
            $scope.loadingList = true;
            var dataURL = URI.PROGRESS_PAYMENT.PP_DELETE;
            Model[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId, entityInstanceId: id }, urltype: 'obj' })
                .then(function () {
                    $scope.get_ppList($scope.selectedPeriod.key);
                })
                .catch(function () {
                    Message.dberror(e);
                    $scope.loadingList = false;
                })
        }

        $scope.Math = Math;

        $scope.$watch(function () {
            return userService.isLoadingMenu;
        },
            function (n, o) {
                if (!n && typeof userService.getOperationsFor(moduleCode).Read != 'undefined') {
                    isPeriodsLoaded = false;
                    var keepPeriods = (routeParams.periodId * 1) !== 0 || (routeParams.entityInstanceId * 1) !== 0;
                    $scope.loadPP(keepPeriods, true);
                } else if (!n) {
                    $state.go("tenant.404", { tenantIdentifier: routeParams.tenantIdentifier });
                }
            });

        $scope.$watch(function () {
            return userService.isCurrentContractPeriodsChanged;
        },
            function (n, o) {
                if (n) {
                    routeParams.periodId = 0;
                    routeParams.entityInstanceId = 0;
                    $scope.get_periods(true);
                    userService.isCurrentContractPeriodsChanged = false;
                }
            });

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.startTransition = function (t, role) {
            // in case we have other drafts, then we need to show a message
            if ($scope.pp.isDraft && $scope.pp.generalInfo.otherDraftsUser && $scope.pp.generalInfo.otherDraftsUser.length) {
                $mdDialog.show(confirm(`Submit ${$scope.pp.moduleName}`, `Submitting the ${$scope.pp.moduleName} will delete drafts created by other users. Continue ?`))
                .then(function () {
                    doStartTransition(t, role);
                })
                .catch(function (e) {
                    // cancel pressed
                });
            }
            else {
                doStartTransition(t, role);
            }
        };

        var doStartTransition = function(t, role) {
            $scope.pp.isBusy = true;
            if (parseInt(t.stateTypeId) !== 0 && !t.isRevise) {
                var getFields_param = t.isComment ? { actionId: t.id } : { transitionId: t.id };
                $scope.pp.get_fields(getFields_param)
                    .then(function (r) {
                        if (t.isAutoTrigger && !(r.requiresNonBindingSignature && !$scope.nonBindingSignature) 
                            || r && !r.fields.length && r.hideRolesNotify && !(r.requiresNonBindingSignature && !$scope.nonBindingSignature) 
                            || r && !r.fields.length && !r.emailsDataSource.length && !r.hideRolesNotify && !(r.requiresNonBindingSignature && !$scope.nonBindingSignature)) {
                            $scope.pp.save(t.id, role.key)
                                .then(function () {
                                    // var period = $scope.pp.period || null;
                                    $scope.pp.get_general_info();
                                    $scope.get_periods();
                                })
                                .catch(function () { });
                        }
                        else {
                            $scope.pp.isBusy = false;
                            r.rolesDict = angular.copy($scope.pp.rolesDict);
                            r.saveParams = {
                                contractId: $scope.pp.contractId,
                                roleId: role.key,
                                entityInstanceId: $scope.pp.entityInstanceId,
                                paymentPeriodId: $scope.pp.period ? $scope.pp.period.key : ($scope.pp.selectedPeriodId || 0),
                                moduleTypeId: ($scope.pp.currentType || {}).key || $scope.pp.types[0].key
                            };
                            showTransitionScreen(r, t);
                        }
                    })
                    .catch(function () { });
            } else {
                $scope.pp.save(t.id, role.key, t.isRevise)
                    .then(function () {
                        // $scope.pp.isBusy = true;
                        // var period = $scope.pp.period || null;
                        $scope.pp.get_general_info();
                        $scope.get_periods();
                    })
                    .catch(function () { });
            }
        }

        var showTransitionScreen = function (r, t) {
            var dataURL, entityInstanceId, ppPeriodId;
            dataURL = URI.PROGRESS_PAYMENT.PP_SAVE;
            if (t.isComment) dataURL = URI.MODULE.ADD_COMMENT;
            entityInstanceId = $scope.pp.entityInstanceId;
            
            $mdDialog.show({
                locals: {
                    info: r,
                    transition: t,
                    entityInstanceId: entityInstanceId,
                    dataURL: dataURL,
                    contractId: userService.system.userdata.contractId,
                    isPayment: true,
                    selectedRoleId: r.saveParams.roleId
                },
                controller: 'editTransitionScreenController',
                templateUrl: '/ng/views/admin/modals/editTransitionScreen.html',
                parent: angular.element(document.body),
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
            .then(function (r) {
                $scope.pp.entityInstanceId = r;
                // var period = $scope.pp.period || null;
                $scope.pp.get_general_info();
                $scope.get_periods();

            });
        }

        // ensure selecting the menu item
        Menu.setMenuItemByModuleCode(moduleCode);
    });
