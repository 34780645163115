import { cipo } from 'cipo';

cipo.factory("GraphModel", function ($window, $q, URI, Model, Message, Dictionaries, userService) {
    var GraphModel = Model.extend(function (container, gblContainer, scope, workflowId) {
        this.container = container;
        this.gblContainer = gblContainer;
        this.json = {};
        this.graphTimeStamp = new Date().getTime();
        this.directiveScope = scope;
        this.workflowId = workflowId;
        this.myStateStyle = "";
        //Call for ConditionalScreenFieldsDict
        //Call for saving conditionals on workflow
        this.lookup_state = {
            state: [],
            type: [],
            obj: []
        };

        this.stateDictionary;

        this.lookup_transition = {
            transition: [],
            id: [],
            obj: [],
            count: 0,
            conditionals: [],
            mxGraphCondi: []
        };

        this.lookup_rhomb = {
            rhomb: [],
            id: [],
            obj: []
        };

        this.graphGeometry = {
            states: [],
            transitions: []
        }

    });

    GraphModel.prototype.init = function () {
        var self = this;

        //init
        self.graph = new mxGraph(this.container, self.model);
        self.globalTransitionsGraph = new mxGraph(this.gblContainer);
    }

    GraphModel.prototype.loadConditionals = function () {
        var p = $q.defer();
        var self = this;
        self.lookup_transition.conditionals = [];//to avoid duplicates
        var condi = self[URI.TRANSITION.GET_ALL_CONDITIONAL_TRANSITIONS.method](URI.TRANSITION.GET_ALL_CONDITIONAL_TRANSITIONS, { url: { workflowId: self.workflowId }, urltype: "obj" }, { headers: { moduleId: self.directiveScope.data.properties.id } })
            .then(function (r) {
                for (var i = 0; i < r.length; i++) {
                    self.lookup_transition.conditionals.push(r[i]);
                }
                p.resolve();
            })
            .catch(function (e) { p.reject(); console.error(e); });

        return p.promise;
    }

    GraphModel.prototype.loadDicts = function () {
        var self = this;
        //set up dictionaries
        Dictionaries.Signatures({ moduleId: self.directiveScope.data.properties.id }, { workflowId: self.workflowId })
            .then(function (r) {
                self.assignedRolesDict = self.setTrueDicts(self.directiveScope.data.assignedRolesDict, "key");
                self.notificationsDict = self.setTrueDicts(self.directiveScope.data.notificationsDict, "key");
                self.signsDict = self.setTrueDicts(r, "key");
                self.nonBindingSignsDict = self.setTrueDicts(r, "key");
                self.signNotification = self.setTrueDicts(self.directiveScope.data.notificationsDict, "key");
                self.ballInCourtDict = self.setTrueDicts(self.directiveScope.data.assignedRolesDict, "key");

            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {

            })

        self.iconsTrueDict = self.setTrueDicts(userService.system.icons.list, "key");
        self.statesTypesDict = self.setTrueDicts(self.directiveScope.data.statesList[0].statesTypesDict, "type");
        self.screenDict = self.setTrueDicts(self.directiveScope.data.screensDict, "key");
        self.startScreenDict = self.setTrueDicts(self.directiveScope.data.startScreensDict, "key");
    }

    GraphModel.prototype.setVertexStyle = function (fontsize, strokewidth) {
        var self = this;
        var vertexStyle = [];
        //for states
        vertexStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
        vertexStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
        vertexStyle[mxConstants.STYLE_STROKECOLOR] = 'gray';
        vertexStyle[mxConstants.STYLE_ROUNDED] = false;
        vertexStyle[mxConstants.STYLE_FILLCOLOR] = '#808080';
        vertexStyle[mxConstants.STYLE_GRADIENTCOLOR] = null;
        vertexStyle[mxConstants.STYLE_FONTCOLOR] = '#774400';
        vertexStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
        vertexStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
        vertexStyle[mxConstants.STYLE_FONTSIZE] = fontsize.toString();
        vertexStyle[mxConstants.STYLE_FONTSTYLE] = 1;
        vertexStyle[mxConstants.STYLE_STROKEWIDTH] = strokewidth;
        vertexStyle[mxConstants.STYLE_ORTHOGONAL] = true;
        vertexStyle[mxConstants.STYLE_ROUNDED] = 1;
        mxConstants.VERTEX_SELECTION_COLOR = '#000000';
        mxConstants.HANDLE_FILLCOLOR = '#8da5ed';
        mxConstants.HANDLE_STROKECOLOR = '#8da5ed';

        self.graph.getStylesheet().putDefaultVertexStyle(vertexStyle);
        self.globalTransitionsGraph.getStylesheet().putDefaultVertexStyle(vertexStyle);
    }

    GraphModel.prototype.setEdgeStyle = function (fontsize, strokewidth) {
        var self = this;
        //for edges
        var edgeStyle = [];
        edgeStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
        edgeStyle[mxConstants.STYLE_STROKECOLOR] = '#686868';
        edgeStyle[mxConstants.STYLE_EDGE] = mxEdgeStyle.OrthConnector;
        mxGraph.prototype.defaultLoopStyle = mxEdgeStyle.OrthConnector;
        edgeStyle[mxConstants.STYLE_ORTHOGONAL_LOOP] = 1;
        edgeStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
        edgeStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_TOP;
        edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC;
        edgeStyle[mxConstants.STYLE_FONTSIZE] = fontsize.toString();//16
        edgeStyle[mxConstants.STYLE_FONTSTYLE] = 1;
        edgeStyle[mxConstants.STYLE_STROKEWIDTH] = strokewidth;//3
        edgeStyle[mxConstants.CONNECT_TARGET_COLOR] = '#8da5ed';
        mxConstants.EDGE_SELECTION_COLOR = '#000000';
        mxConstants.DROP_TARGET_COLOR = '#8da5ed';
        mxConstants.CONNECT_HANDLE_FILLCOLOR = '#8da5ed';
        mxConstants.OUTLINE_HANDLE_STROKECOLOR = '#8da5ed';

        self.graph.getStylesheet().putDefaultEdgeStyle(edgeStyle);
        self.globalTransitionsGraph.getStylesheet().putDefaultEdgeStyle(edgeStyle);
    }

    GraphModel.prototype.setGraphProperties = function () {
        try {
            var self = this;
            mxEvent.disableContextMenu(this.container);
            mxEvent.disableContextMenu(this.gblContainer);
            self.init();

            // Uses the shape for resize previews
            mxVertexHandler.prototype.createSelectionShape = function (bounds) {
                var key = this.state.style[mxConstants.STYLE_SHAPE];
                var stencil = mxStencilRegistry.getStencil(key);
                var shape = null;

                if (stencil != null) {
                    shape = new mxShape(stencil);
                    shape.apply(this.state);
                }
                else {
                    shape = new this.state.shape.constructor();
                }

                shape.outline = true;
                shape.bounds = bounds;
                shape.stroke = mxConstants.HANDLE_STROKECOLOR;
                shape.strokewidth = this.getSelectionStrokeWidth();
                shape.isDashed = this.isSelectionDashed();
                shape.isShadow = false;

                return shape;
            };


            var OvalShape = function () {
                mxShape.call(this);
            };
            mxUtils.extend(OvalShape, mxShape);

            OvalShape.prototype.paintBackground = function (c, x, y, w, h) {
                c.translate(x, y);
                c.roundrect(0, 0, w, h, w / 5, w / 5);
                c.fillAndStroke();
            };
            mxCellRenderer.registerShape('oval', OvalShape);

            var MessageBox = function () {
                mxShape.call(this);
            };
            mxUtils.extend(MessageBox, mxShape);

            MessageBox.prototype.paintBackground = function (c, x, y, w, h) {
                c.translate(x, y);
                c.rect(0, 0, w, h, w / 5, w / 5);
                c.text
                c.fillAndStroke();
            };
            mxCellRenderer.registerShape('messageBox', MessageBox);

            //custom label
            var cached = true;

            mxCodecRegistry.getCodec(mxCell).exclude.push('div');

            self.graph.model.setValue = function (cell, value) {
                cell.div = null;
                mxGraphModel.prototype.setValue.apply(this, arguments);
            };

            // Overrides method to provide a cell label in the display
            self.graph.convertValueToString = function (cell) {
                if (cached && cell.div != null) {
                    // Uses cached label
                    return cell.div;
                }
                else if (mxUtils.isNode(cell.value) && cell.value.nodeName.toLowerCase() == 'userobject') {
                    // Returns a DOM for the label
                    var div = document.createElement('div');
                    div.innerHTML = cell.getAttribute('label');
                    mxUtils.br(div);

                    var i = document.createElement('i');
                    i.setAttribute('class', self.mdiVariable);
                    i.setAttribute('style', 'position: relative; left: -10px')
                    div.prepend(i);

                    if (cached) {
                        // Caches label
                        cell.div = div;
                    }

                    return div;
                }

                return '';
            };

            var cellLabelChanged = self.graph.cellLabelChanged;
            self.graph.cellLabelChanged = function (cell, newValue, autoSize) {
                if (mxUtils.isNode(cell.value) && cell.value.nodeName.toLowerCase() == 'userobject') {
                    // Clones the value for correct undo/redo
                    var elt = cell.value.cloneNode(true);
                    elt.setAttribute('label', newValue);
                    newValue = elt;
                }

                cellLabelChanged.apply(this, arguments);
            };

            self.graph.setAllowDanglingEdges(false);
            var rubberband = new mxRubberband(self.graph);
            var keyHandler = new mxKeyHandler(self.graph);
            self.graph.setTooltips(false);
            self.graph.panningHandler.ignoreCell = false;
            self.graph.setPanning(true);
            self.graph.allowLoops = true;
            self.graph.setCellsEditable(false);
            self.graph.setConnectable(false);
            self.graph.setTooltips(false);
            self.graph.setHtmlLabels(true);

            //globalTransitionsGraph properties
            self.globalTransitionsGraph.setAllowDanglingEdges(false);
            self.globalTransitionsGraph.setPanning(false);
            self.globalTransitionsGraph.setCellsEditable(false);
            self.globalTransitionsGraph.setCellsMovable(false);
            self.globalTransitionsGraph.setCellsSelectable(false)

            //connection preview
            self.graph.connectionHandler.createEdgeState = function (me) {
                var edge = self.graph.createEdge(null, null, null, null, null);

                return new mxCellState(self.graph.view, edge, self.graph.getCellStyle(edge));
            };


            mxConstraintHandler.prototype.highlightColor = '#8da5ed';


            
            //for conditional transitions
            var rhombStyle = [];
            rhombStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RHOMBUS;
            rhombStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RhombusPerimeter;
            rhombStyle[mxConstants.STYLE_STROKECOLOR] = 'gray';
            rhombStyle[mxConstants.STYLE_FONTCOLOR] = 'gray';
            rhombStyle[mxConstants.STYLE_FILLCOLOR] = '#EEEEEE';
            rhombStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
            rhombStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
            rhombStyle[mxConstants.STYLE_FONTSIZE] = '8';
            rhombStyle[mxConstants.STYLE_ROUNDED] = 0;

            //for information window
            var messageBoxStyle = [];
            messageBoxStyle[mxConstants.STYLE_STROKECOLOR] = '#e0e0e0';
            messageBoxStyle[mxConstants.STYLE_FONTCOLOR] = 'gray';
            messageBoxStyle[mxConstants.STYLE_FILLCOLOR] = '#e0e0e0';
            messageBoxStyle[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_LEFT;
            messageBoxStyle[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_TOP;
            messageBoxStyle[mxConstants.STYLE_FONTSIZE] = '12';
            messageBoxStyle[mxConstants.STYLE_SHAPE] = "messageBox";

            self.graph.getStylesheet().putCellStyle('messageBoxStyle', messageBoxStyle);
            self.graph.getStylesheet().putCellStyle('rhombStyle', rhombStyle);

            self.setVertexStyle(12, 2);
            self.setEdgeStyle(12, 1.5);

            
            //self.graph.connectionHandler.marker.validColor = '#8da5ed';//for highlight
            self.graph.connectionHandler.marker.validColor = '#00FF00';

            //graph panning change cursor
            self.graph.panningHandler.addListener(mxEvent.PAN_START, function () {
                self.graph.container.style.cursor = 'pointer';
            });
            self.graph.panningHandler.addListener(mxEvent.PAN_END, function () {
                self.graph.container.style.cursor = 'default';
            });
        } catch (e) {
            console.log(e);
        }

    }

    GraphModel.prototype.parseJson = function (json, temp_state, temp_transition) {
        for (var i = 0; i < json.length; i++) {
            temp_state.push(json[i]);
            for (var j = 0; j < (json[i].transitions).length; j++) {
                temp_transition.push(json[i].transitions[j]);
            }
        }
    };

    GraphModel.prototype.saveAs = function () {
        var self = this;

        self.setVertexStyle(16, 3);
        self.setEdgeStyle(16, 3);

        for (var i = 0; i < self.lookup_transition.transition.length; i++) {
            self.graph.getView().clear(self.lookup_transition.transition[i], false, false);
            self.graph.getView().validate();
        }
        for (var i = 0; i < self.lookup_state.state.length; i++) {
            self.graph.getView().clear(self.lookup_state.state[i], false, false);
            self.graph.getView().validate();
        }
        for (var i = 0; i < self.lookup_transition.mxGraphCondi.length; i++) {
            self.graph.getView().clear(self.lookup_transition.mxGraphCondi[i], false, false);
            self.graph.getView().validate();
        }

        var preview = new mxPrintPreview(self.graph);
        //get svg element.
        var svg = self.graph.container.getElementsByTagName("svg");

        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg[0]);

        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var svgSize = svg[0].getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;

        var img = document.createElement("img");
        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(source));
        //<a style="text-decoration: none" id="img" download>
        

        img.onload = function () {
            ctx.drawImage(img, 0, 0);

            // Now is done
            var a = document.createElement("a");
            a.href = canvas.toDataURL("image/png");
            a.setAttribute('download', "workflow.png");

            a.style.display = 'none';
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
        };

        self.setVertexStyle(12, 2);
        self.setEdgeStyle(12, 1.5);

        for (var i = 0; i < self.lookup_transition.transition.length; i++) {
            self.graph.getView().clear(self.lookup_transition.transition[i], false, false);
            self.graph.getView().validate();
        }
        for (var i = 0; i < self.lookup_state.state.length; i++) {
            self.graph.getView().clear(self.lookup_state.state[i], false, false);
            self.graph.getView().validate();
        }
        for (var i = 0; i < self.lookup_transition.mxGraphCondi.length; i++) {
            self.graph.getView().clear(self.lookup_transition.mxGraphCondi[i], false, false);
            self.graph.getView().validate();
        }

    }

    GraphModel.prototype.refreshArrays = function () {
        self = this;
        //refresh arrays to avoid duplicates and invisible lines
        self.lookup_state.state = [];
        self.lookup_state.obj = [];
        self.lookup_state.type = [];

        self.lookup_transition.transition = [];
        self.lookup_transition.id = [];
        self.lookup_transition.obj = [];
        self.lookup_transition.count = 0;
        self.lookup_transition.mxGraphCondi = [];
    }

    GraphModel.prototype.drawStates = function (temp_state, lookup_state) {
        var self = this;
        ///STATE
        var State = function (obj) {
            this.properties = {
                x: null,
                y: null,
                width: 150,
                height: 50
            };
            if (Object.prototype.toString.call(obj) === "[object Object]") {
                for (var key in obj) {
                    this.properties[key] = obj[key];
                }
            }
        };

        State.prototype.draw = function (terminal) {
            // Creates a user object that stores the state for custom label
            var doc = mxUtils.createXmlDocument();
            var obj = doc.createElement('UserObject');
            if (this.properties.iconId) {
                self.mdiVariable = self.iconsTrueDict[this.properties.iconId].class;
            }
            else {
                self.mdiVariable = "";
            }
            obj.setAttribute('label', this.properties.name);

            if (terminal) {
                var v = self.graph.insertVertex(self.defaultParent, null, obj, this.properties.x, this.properties.y, this.properties.width, this.properties.height, 'shape=oval');
                v.object = this;
            }
            else {
                var v = self.graph.insertVertex(self.defaultParent, null, obj, this.properties.x, this.properties.y, this.properties.width, this.properties.height);
                v.object = this;
            }
            setColor(this.properties);
            if (terminal) {
                v.style += ";" + self.myStateStyle.toString();
            }
            else {
                v.style = self.myStateStyle.toString();
            }
            self.graph.refresh();
        };
        var setColor = function (cell) {
            if (cell.color != null) {
                self.myStateStyle = 'fillColor=' + cell.color + ';fontColor=' + cell.textColor;
            }
        };

        for (var s = 0; s < temp_state.length; s++) {
            var state = new State(temp_state[s].properties);
            if (temp_state[s].properties.type == "0") {
                state.draw(true);
            }
            else if (temp_state[s].properties.type == "101" || temp_state[s].properties.type == "102" && temp_state[s].properties.transitions.length == 0) {
                state.draw(true);
            }
            else {
                state.draw(false);
            }

            lookup_state.obj.push(state);
            lookup_state.type.push(state.properties.type);
        }
    };

    GraphModel.prototype.drawTransitions = function (temp_transition, temp_state, lookup_transition, lookup_state) {
        var self = this;
        self.isDrawFunction = true;
        self.mdiVariable = "";
        var doc = mxUtils.createXmlDocument();
        
        //GLOBAL TRANSITION
        var GlobalTransition = function (src, target, obj) {
            this.properties = {
                sourceVertex: src,
                targetVertex: target
            };
            if (Object.prototype.toString.call(obj) === "[object Object]") {
                for (var key in obj) {
                    this.properties[key] = obj[key];
                }
            }
        };
        GlobalTransition.prototype.draw = function () {
            var obj = doc.createElement('UserObject');
            obj.setAttribute('label', this.properties.name);
            var origin = self.globalTransitionsGraph.insertVertex(self.globalTransitionsParent, null, this.properties.sourceVertex.value, 10, self.y, 150, 50);
            var destination = self.globalTransitionsGraph.insertVertex(self.globalTransitionsParent, null, this.properties.targetVertex.value, 300, self.y, 150, 50);
            self.globalTransitionsGraph.insertEdge(self.globalTransitionsParent, null, obj, origin, destination);
            self.y += 90;
        };



        //TRANSITION
        var Transition = function (src, target, obj) {
            this.properties = {
                sourceVertex: src,
                targetVertex: target,
                style: {
                    exitX: null,
                    exitY: null,
                    entryX: null,
                    entryY: null
                },
                points: {},
                x: 0,
                y: 0
            };
            if (Object.prototype.toString.call(obj) === "[object Object]") {
                for (var key in obj) {
                    this.properties[key] = obj[key];
                }
            }
        };

        Transition.prototype.draw = function () {
            var obj = doc.createElement('UserObject');
            obj.setAttribute('label', this.properties.name);

            var styleString;
            if (this.properties.style.exitX != null && this.properties.style.exitY != null
                && this.properties.style.entryX != null && this.properties.style.entryY != null) {
                styleString = "style=" + "exitX=" + this.properties.style.exitX.toString() + ";" + "exitY=" + this.properties.style.exitY.toString() +
                    ";exitDx=0;exitDy=0;" + "entryX=" + this.properties.style.entryX.toString() + ";" + "entryY=" +
                    this.properties.style.entryY.toString() + ";entryDx=0;entryDy=0;";
            }
            if (this.properties.sourceVertex == this.properties.targetVertex) {
                styleString = "style=" + "exitX=" + "0.75" + ";" + "exitY=" + "0" +
                    ";exitDx=0;exitDy=0;" + "entryX=" + "0.5" + ";" + "entryY=" +
                    "0.5" + ";entryDx=0;entryDy=0;";
            }

            var e = self.graph.insertEdge(self.defaultParent, null, obj, this.properties.sourceVertex, this.properties.targetVertex, styleString);
            e.object = this;
            
            e.geometry.x = this.properties.x;
            e.geometry.y = this.properties.y;
            e.geometry.points = [];
            try {
                for (var i = 0; i < this.properties.points.length; i++) {
                    var p = new mxPoint(this.properties.points[i].x, this.properties.points[i].y)
                    e.geometry.points[i] = p;
                }
            }
            catch (e) { }
            if (this.properties.sourceVertex == this.properties.targetVertex) {
                e.isSelfTransition = true;
            }
            self.graph.orderCells(true, [e]);
        };

        //CONDITIONAL TRANSITION
        Transition.prototype.drawConditional = function (alternativeStates) {
            var obj = doc.createElement('UserObject');
            obj.setAttribute('label', this.properties.name);


            var styleString;
            if (this.properties.style.exitX != null && this.properties.style.exitY != null
                && this.properties.style.entryX != null && this.properties.style.entryY != null) {
                styleString = "style=" + "exitX=" + this.properties.style.exitX.toString() + ";" + "exitY=" + this.properties.style.exitY.toString() +
                    ";exitDx=0;exitDy=0;" + "entryX=" + this.properties.style.entryX.toString() + ";" + "entryY=" +
                    this.properties.style.entryY.toString() + ";entryDx=0;entryDy=0;";
            }

            var rhombus = self.graph.insertVertex(self.defaultParent, null, null,
                temp_transition[t].properties.decisionBoxX, temp_transition[t].properties.decisionBoxY, temp_transition[t].properties.decisionBoxWidth, temp_transition[t].properties.decisionBoxHeight, 'rhombStyle');
            var e1 = self.graph.insertEdge(self.defaultParent, null, obj, this.properties.sourceVertex, rhombus, styleString);

            e1.object = this;//main transition
            e1.mainTransition = true;
            var obj2 = doc.createElement('UserObject');
            obj2.setAttribute('label', '');

            var e2 = self.graph.insertEdge(self.defaultParent, null, null, rhombus, this.properties.targetVertex, styleString);
            e2.continuedTransition = this; //default path
            e1.condition = [];
            var cells = [rhombus, e1, e2];
            e1.condition.push(rhombus);
            e1.condition.push(e2);

            for (var i = 0; i < alternativeStates.length; i++) {
                for (var j = 0; j < self.lookup_state.obj.length; j++) {
                    if (alternativeStates[i].stateId == self.lookup_state.obj[j].properties.id) {
                        var obj3 = doc.createElement('UserObject');
                        obj3.setAttribute('label', " ");

                        var eAlt = self.graph.insertEdge(self.defaultParent, null, obj3, rhombus, self.lookup_state.state[j], styleString);
                        eAlt.object = alternativeStates[i];
                        self.lookup_transition.mxGraphCondi.push(eAlt);
                        cells.push(eAlt);
                        e1.condition.push(eAlt);
                    }
                }
            }

            for (var i = 0; i < cells.length; i++) {
                try {
                    cells[i].isCondi = true;
                    //cells[i].parentTransition = this;
                    if (cells[i].edge == true) {
                        //cells[i].geometry.x = self.lookup_transition.mxGraphCondi[i].object.x;
                        // cells[i].geometry.y = self.lookup_transition.mxGraphCondi[i].object.y;
                        cells[i].geometry.points = [];
                        //cells[i].object = this;
                        try {
                            if (cells[i].object) {
                                var cellPoints;
                                if (cells[i].mainTransition) {
                                    cellPoints = cells[i].object.properties;
                                }
                                if (!cells[i].mainTransition) {
                                    cellPoints = cells[i].object;
                                }
                                for (var j = 0; j < cellPoints.points.length; j++) {
                                    var p = new mxPoint(cellPoints.points[j].x, cellPoints.points[j].y)
                                    cells[i].geometry.points.push(p);
                                }
                            }
                        }
                        catch (e) { console.log(e); }
                    }
                    else if (cells[i].vertex == true && cells[i].geometry.width == 0 && cells[i].geometry.height == 0) {
                        if (temp_transition[t].properties.points) {
                            cells[i].geometry.x = temp_transition[t].properties.points[temp_transition[t].properties.points.length -1].x;
                            cells[i].geometry.y = temp_transition[t].properties.points[temp_transition[t].properties.points.length - 1].y + 25;
                            cells[i].geometry.width = 50;
                            cells[i].geometry.height = 50;
                        } else {
                            cells[i].geometry.x = src.geometry.x + src.geometry.width / 2;
                            cells[i].geometry.y = src.geometry.y + src.geometry.height + 50;
                            cells[i].geometry.width = 50;
                            cells[i].geometry.height = 50;
                        }
                    }
                } catch (e) { console.log(e); }
            }

            self.graph.orderCells(true, cells);
            self.lookup_transition.transition.push(e1);//add the default variant of transtion as standin for whole conditional transition

            self.lookup_transition.mxGraphCondi.push(rhombus);
            self.lookup_transition.mxGraphCondi.push(e2);
        }

        for (var t = 0; t < self.temp_transition.length; t++) {
            
            for (var s = 0; s < temp_state.length; s++) {
                if (self.temp_transition[t].properties.stateId == temp_state[s].properties.id) {
                    var src = self.lookup_state.state[s];

                }
                if (temp_transition[t].properties.nextStateId == temp_state[s].properties.id) {
                    var target = self.lookup_state.state[s];

                }
            }
            if (self.temp_transition[t].properties.isGlobal == true) {
                try {
                    var gblTransition = new GlobalTransition(src, target, self.temp_transition[t].properties);
                    gblTransition.draw();
                    self.lookup_transition.obj.push(gblTransition);
                }
                catch (e) {
                    console.error('transition fail', e);
                }

            }
            if (temp_transition[t].properties.isGlobal == false) {
                var transition = new Transition(src, target, temp_transition[t].properties);
                if (temp_transition[t].properties.hasConditionalTransitions) {
                    self.isNotConditional = false;
                    transition.drawConditional(temp_transition[t].properties.conditionalsTransition);

                }
                else {
                    self.isNotConditional = true;
                    transition.draw();
                }
                self.lookup_transition.obj.push(transition);
            }
        }

        self.isDrawFunction = false;

    };

    GraphModel.prototype.wtfPoints = function (points, edge) {
        try {

            if (points > 0) {
                for (var i = 0; i < points.length; i++) {
                    var p = new mxPoint(points[i].x, points[i].y)
                    edge.geometry.points[i] = p;
                }
            }

        }
        catch (e) {
            console.log(e)
        }
    }

    GraphModel.prototype.transitionCheck = function (source, target) {
        var draft = 0;
        var open = 2;
        var revise = 100;
        var approved = 101;
        var rejected = 102;
        var global = 103;
        if (source == draft && (target == draft || target == open || target == revise || target == approved || target == rejected)) {
            return true;
        }
        if (source == open && (target == open || target == revise || target == approved || target == rejected)) {
            return true;
        }
        if (source == revise && (target == draft || target == revise || target == approved || target == rejected)) {
            return true;
        }
        if (source == global && (target == open || target == revise || target == approved || target == rejected)) {
            return true;
        }
        else {
            return false;
        }
    };

    GraphModel.prototype.hierarchicalDynamicLayout = function (graph) {
        var self = this;

        var layout = new mxCompactTreeLayout(graph);
        mxCompactTreeLayout.prototype.moveTree = top;
        mxCompactTreeLayout.prototype.levelDistance = 80;
        mxCompactTreeLayout.prototype.nodeDistance = 80;
        mxCompactTreeLayout.prototype.resetEdges = false;
        mxCompactTreeLayout.prototype.prefHozEdgeSep = 80;
        mxCompactTreeLayout.prototype.prefVertEdgeOff = 50;
        mxCompactTreeLayout.prototype.edgeRouting = false;
        mxCompactTreeLayout.prototype.root = self.lookup_state.state[0];

        layout.horizontal = false;
        mxCompactTreeLayout.disableEdgeStyle = false;

        var l = new mxHierarchicalLayout(graph);
        mxHierarchicalLayout.prototype.deterministic = false;
        mxHierarchicalLayout.prototype.parallelEdgeSpacing = 10;
        mxHierarchicalLayout.prototype.disableEdgeStyle = false;
        mxHierarchicalLayout.prototype.roots = self.lookup_state.state;

        var compLayout = new mxCompositeLayout(graph, [layout, l], layout);
        compLayout.execute(self.defaultParent);

    };

    GraphModel.prototype.otherLayout = function (graph) {
        var dX = 0;
        var cells = Object.entries(graph.model.cells);
        var parentCells = [];
        parentCells.push(cells[0]);
        parentCells.push(cells[1]);
        cells.shift();
        cells.shift();// to remove parent cells
        for (var i = 0; i < cells.length; i++) {
            cells[i][1].geometry.x = dX;
            dX += 200;
            graph.getView().clear(cells[i][1], false, false);
            graph.getView().validate();
        }
    };

    GraphModel.prototype.saveStateGeometry = function () {
        var self = this;
        self.graphGeometry.states = [];
        for (var i = 0; i < self.lookup_state.state.length; i++) {
            var state = {
                width: parseInt(self.lookup_state.state[i].geometry.width),
                height: parseInt(self.lookup_state.state[i].geometry.height),
                x: parseInt(self.lookup_state.state[i].geometry.x),
                y: parseInt(self.lookup_state.state[i].geometry.y),
                id: self.lookup_state.obj[i].properties.id
            };
            self.graphGeometry.states.push(state);
        }
    };

    GraphModel.prototype.saveTransitionGeometry = function () {
        var self = this;
        //var transition;
        self.graphGeometry.transitions = [];
        try {
            for (var i = 0; i < self.lookup_transition.transition.length; i++) {
                if (self.lookup_transition.transition[i].style) {

                    st = self.lookup_transition.transition[i].style.split("=").join(',').split(";").join(',').split(',');

                    var transition = {
                        exitX: parseFloat(st[2]),
                        exitY: parseFloat(st[4]),
                        entryX: parseFloat(st[10]),
                        entryY: parseFloat(st[12]),
                        points: [],
                        id: self.lookup_transition.obj[i].properties.id
                    }

                }
                if (!self.lookup_transition.transition[i].style) {
                    var transition = {
                        exitX: null,
                        exitY: null,
                        entryX: null,
                        entryY: null,
                        points: [],
                        id: self.lookup_transition.obj[i].properties.id
                    }
                }
                var p = self.lookup_transition.transition[i].geometry.points;
                if (p != null) {
                    for (var j = 0; j < p.length; j++) {
                        var point = {
                            x: parseInt(p[j].x),
                            y: parseInt(p[j].y)
                        }
                        transition.points.push(point);
                    }
                }
                try {
                    if (self.lookup_transition.transition[i].mainTransition) {
                        transition.conditionalsTransition  = [];
                        try {
                            for (var j = 0; j < self.lookup_transition.transition[i].condition.length; j++) {
                                if (self.lookup_transition.transition[i].condition[j].vertex == true) {
                                    transition.decisionBoxHeight = parseInt(self.lookup_transition.transition[i].condition[j].geometry.height);
                                    transition.decisionBoxWidth = parseInt(self.lookup_transition.transition[i].condition[j].geometry.width);
                                    transition.decisionBoxX = parseInt(self.lookup_transition.transition[i].condition[j].geometry.x);
                                    transition.decisionBoxY = parseInt(self.lookup_transition.transition[i].condition[j].geometry.y);
                                }
                                if (self.lookup_transition.transition[i].condition[j].edge == true) {
                                    var save = true;//temporary until db changes allow proper saving
                                    if (self.lookup_transition.transition[i].condition[j].continuedTransition) {
                                        save = false;
                                    }
                                    if (self.lookup_transition.transition[i].condition[j].style && save) {

                                        st = self.lookup_transition.transition[i].style.split("=").join(',').split(";").join(',').split(',');

                                        var condi = {
                                            exitX: parseFloat(st[2]),
                                            exitY: parseFloat(st[4]),
                                            entryX: parseFloat(st[10]),
                                            entryY: parseFloat(st[12]),
                                            points: [],
                                            id: self.lookup_transition.transition[i].condition[j].object.id
                                        }

                                    }
                                    if (!self.lookup_transition.transition[i].condition[j].style && save) {
                                        var condi = {
                                            exitX: null,
                                            exitY: null,
                                            entryX: null,
                                            entryY: null,
                                            points: [],
                                            id: self.lookup_transition.transition[i].condition[j].object.id
                                        }
                                    }
                                    try {
                                        var p = self.lookup_transition.transition[i].condition[j].geometry.points;
                                        if (p != null && save) {
                                            for (var pIndex = 0; pIndex < p.length; pIndex++) {
                                                var point = {
                                                    x: parseInt(p[pIndex].x),
                                                    y: parseInt(p[pIndex].y)
                                                }
                                                condi.points.push(point);
                                            }
                                        }
                                        if (save) {
                                            transition.conditionalsTransition.push(condi);
                                        }
                                    } catch (e) { console.log(e); }
                                    }
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } catch (e) {
                    console.log(e);
                }

                self.graphGeometry.transitions.push(transition);
            }
        }
        catch (e) {
            //console.log(e);
        }
    }
    //save workflow geometry
    GraphModel.prototype.save = function () {
        var p = $q.defer();
        var self = this;
        self.saveTransitionGeometry();
        self.saveStateGeometry();
        var geometry = self[URI.WORKFLOW.SYNC_GEOMETRY.method](URI.WORKFLOW.SYNC_GEOMETRY.toString(), self.graphGeometry)
            .then(function () { p.resolve(); })
            .catch(function (e) { p.reject(); console.error(e); });

        return p.promise;
    }

    GraphModel.prototype.showTransitionNames = function (isHidden) {
        var self = this;
        //TO DO watch for condi branch
        if (isHidden == true) {
            for (var i = 0; i < self.lookup_transition.transition.length; i++) {
                self.lookup_transition.transition[i].div.textContent = null;

                var cells = [];
                cells.push(self.lookup_transition.transition[i]);
                self.graph.getView().clear(cells[0], false, false);
                self.graph.getView().validate();
            }
        }
        if (isHidden == false) {
            for (var i = 0; i < self.lookup_transition.transition.length; i++) {
                self.lookup_transition.transition[i].div.textContent = self.lookup_transition.obj[i].properties.name;

                var cells = [];
                cells.push(self.lookup_transition.transition[i]);
                self.graph.getView().clear(cells[0], false, false);
                self.graph.getView().validate();
            }
        }
    };

    GraphModel.prototype.reloadGraphAlg = function () {
        try {
            var self = this;
            self.hierarchicalDynamicLayout(self.graph);
            var children = self.graph.getChildCells();
            self.graph.moveCells(children, undefined, 10);
        }
        catch (e) {
            console.log(e);
        }

    };

    GraphModel.prototype.deleteVisual = function (cells, cell, index) {
        var self = this;
        cells.push(cell);
        self.graph.removeCells(cells);
        //to remove cell also from lookups
        self.lookup_state.state.splice(index, 1);
        self.lookup_state.obj.splice(index, 1);
        self.lookup_state.type.splice(index, 1);
        self.undoManager.clear();
    };

    GraphModel.prototype.setTrueDicts = function (dict, key) {
        try {
            var trueDict = (array, keyField) =>
                array.reduce((obj, item) => {
                    obj[item[keyField]] = item
                    return obj
                }, {})
            return trueDict(dict, key);
        } catch (e) {

        }
    }


    GraphModel.prototype.create = function (_json) {
        var self = this;

        self.json = _json;
        self.temp_state = [];
        self.temp_transition = [];
        self.selectedCell = null;
        self.isDrawFunction = false;
        self.isNotConditional = true;



        var isNewWorkflow = false;
        self.defaultParent = self.graph.getDefaultParent();

        self.globalTransitionsParent = self.globalTransitionsGraph.getDefaultParent();

        self.undoManager = new mxUndoManager();
        var temp_state = [];
        var temp_transition = [];
        self.y = 10;
        var keyboard = true;

        var undoListener = function (sender, evt) {
            self.undoManager.undoableEditHappened(evt.getProperty('edit'));
        };

        self.graph.getModel().addListener(mxEvent.UNDO, undoListener);

        self.graph.getView().addListener(mxEvent.UNDO, undoListener);

        self.deleteLastTransition = function () {
            self.undoManager.undo();
            self.undoManager.clear();
            if (self.lookup_transition.transition > self.lookup_transition.obj) {
                self.lookup_transition.transition.pop();
            }
        };

        var enableKeyboard = function () {
            if (keyboard == true) {

                //undo ctrl+z
                $(document).keydown(function (e) {
                    if (e.which === 90 && e.ctrlKey) {
                        self.undoManager.undo();
                    }
                });
            }
        };
        enableKeyboard();
        self.globalTransitionsGraph.addListener(mxEvent.CELLS_ADDED, function (sender, evt) {
            if (evt.properties.cells[0].edge == true) {
                var src, trg;
                var cells = [];
                self.lookup_transition.transition.push(evt.properties.cells[0]);
                if (self.lookup_transition.count >= self.temp_transition.length) {
                    for (var i = 0; i < self.lookup_state.state.length; i++) {
                        if (evt.properties.cells[0].source.id == self.lookup_state.state[i].id) {
                            src = self.lookup_state.obj[i]
                        }
                        if (evt.properties.cells[0].target.id == self.lookup_state.state[i].id) {
                            trg = self.lookup_state.obj[i]
                        }
                    }
                    if(src && trg){
                        if (self.transitionCheck(src.properties.type, trg.properties.type)) {
                            $window.customFunctions.transitionAdded(src, trg);
                        }
                        if (!self.transitionCheck(src.properties.type, trg.properties.type)) {
                            cells.push(self.lookup_transition.transition[self.lookup_transition.transition.length - 1]);
                            self.graph.removeCells(cells);
                            self.lookup_transition.transition.pop();
                            $window.customFunctions.graphModelAlert('Wrong state type', true);
                        }
                    }
                }
                self.undoManager.clear();
                self.lookup_transition.count += 1;
            }
        });

        //event for after transition or state has been added
        self.graph.addListener(mxEvent.CELLS_ADDED, function (sender, evt) {
            try {
                //(evt.properties.cells[0]);//added object
                if (evt.properties.cells[0].vertex == true && evt.properties.cells[0].style != 'rhombStyle' && evt.properties.cells[0].style != 'messageBoxStyle') {
                    self.lookup_state.state.push(evt.properties.cells[0]);

                    self.myStateStyle = undefined; //to reset vertex style
                    self.undoManager.clear();
                    //console.log(evt);
                }
                if (evt.properties.cells[0].vertex == true && evt.properties.cells[0].style == 'rhombStyle') {
                    self.lookup_rhomb.rhomb.push(evt.properties.cells[0]);

                    self.undoManager.clear();
                }
                if (evt.properties.cells[0].edge == true) {
                    var src, trg;
                    var cells = [];
                    if (self.isNotConditional) {
                        self.lookup_transition.transition.push(evt.properties.cells[0]);
                    }

                    if (self.lookup_transition.count >= self.temp_transition.length && !self.isDrawFunction) {
                        for (var i = 0; i < self.lookup_state.state.length; i++) {
                            if (evt.properties.cells[0].source.id == self.lookup_state.state[i].id) {
                                src = self.lookup_state.obj[i]
                            }
                            if (evt.properties.cells[0].target.id == self.lookup_state.state[i].id) {
                                trg = self.lookup_state.obj[i]
                            }
                        }
                        if (self.transitionCheck(src.properties.type, trg.properties.type)) {
                            $window.customFunctions.transitionAdded(src, trg);
                        }
                        if (!self.transitionCheck(src.properties.type, trg.properties.type)) {
                            cells.push(self.lookup_transition.transition[self.lookup_transition.transition.length - 1]);
                            self.graph.removeCells(cells);
                            self.lookup_transition.transition.pop();
                            $window.customFunctions.graphModelAlert('Wrong state type', true);
                        }

                    }
                    self.undoManager.clear();
                    self.lookup_transition.count += 1;
                }
            }
            catch (e) {
                console.log(e);
            }
        });

        //when cell is removed prevent undo
        self.graph.addListener(mxEvent.CELLS_REMOVED, function (sender, evt) {
            self.undoManager.clear();
        });

        // get mxgrpah info from selected cell
        self.graph.addListener(mxEvent.CLICK, function (sender, evt) {
            var cell = self.graph.getSelectionCell();
        });

        //prevent changing source or target
        // get mxgrpah info from selected cell
        self.graph.addListener(mxEvent.CLICK, function (sender, evt) {
            var cell = self.graph.getSelectionCell();
            if (cell) {
                if (cell.edge) {
                    if (self.currentCell == cell) {
                        try {
                            if (self.currentCellSource != cell.source) {
                                self.undoManager.undo();
                                // Message.error("Cannot change transition source");
                            }
                            if (self.currentCellTarget != cell.target) {
                                self.undoManager.undo();
                                // Message.error("Cannot change transition target");
                            }
                        } catch (e) { }
                    }
                    self.currentCell = cell;
                    self.currentCellSource = cell.source;
                    self.currentCellTarget = cell.target;
                }
            }
        });//Prevent edge change src

        self.graph.addListener(mxEvent.CELLS_RESIZED, function (sender, evt) {
            var resizedCell = evt.properties.cells[0];
            if (resizedCell.style != 'rhombStyle') {
                for (var i = 0; i < self.lookup_state.state.length; i++) {
                    self.lookup_state.state[i].geometry.width = resizedCell.geometry.width;
                    self.lookup_state.state[i].geometry.height = resizedCell.geometry.height;
                }
                self.graph.refresh();
            }
        });

        //mxConnectionHandler.prototype.connectImage = new mxImage('img/arr_right.svg', 16, 16);
        //overlay
        var previous = [];
        var isHigh = false;
        self.graph.addListener(mxEvent.CLICK, function (sender, evt) {
            var cell = self.graph.getSelectionCell();
            var cells = [];
            var isConditionalOverlay = false;
            if (cell) {
                previous.push(cell);
            }
            //for vertexes
            if (cell != null && cell.vertex == true && cell.style != 'rhombStyle') {
                var overlays = self.graph.getCellOverlays(cell);
                if (overlays == null) {
                    self.graph.setTooltips(true);
                    // Creates a new overlay with an image and a tooltip
                    var del_overlay = new mxCellOverlay(
                        new mxImage('img/delete_icon.svg', 16, 16),
                        'Delete');
                    del_overlay.cursor = 'pointer';
                    del_overlay.align = 'right';
                    del_overlay.verticalAlign = 'top';

                    del_overlay.offset.x += 15;
                    var edit_overlay = new mxCellOverlay(
                        new mxImage('img/edit_icon.svg', 16, 16),
                        'Edit');
                    edit_overlay.cursor = 'pointer';
                    edit_overlay.offset.x += 15;

                    var moreInfo_overlay = new mxCellOverlay(
                        new mxImage('img/moreInfo.svg', 16, 16),
                        showInfoWindow(cell));
                    moreInfo_overlay.cursor = 'pointer';
                    moreInfo_overlay.offset.y += 10;
                    moreInfo_overlay.offset.x -= cell.geometry.width + 10;

                    // Installs a handler for clicks on the overlay							
                    del_overlay.addListener(mxEvent.CLICK, function (sender, evt2) {
                        var obj, index;
                        if (!cell.edges || cell.edges.length > 0) {
                            for (var i = 0; i < self.lookup_state.obj.length; i++) {
                                if (cell == self.lookup_state.state[i]) {
                                    obj = self.lookup_state.obj[i];
                                    index = i;
                                }
                            }
                            //Confirm or cancel
                            isHigh = true; // prevent clicking events from deleting the icons
                            self.graph.setEnabled(false);



                            var confirmDel_overlay = new mxCellOverlay(
                                new mxImage('img/confirm_del.svg', 36, 36), 'Confirm');
                            confirmDel_overlay.cursor = 'pointer';
                            confirmDel_overlay.verticalAlign = 'top';
                            confirmDel_overlay.offset.y += 25;
                            confirmDel_overlay.offset.x -= 122.5;
                            self.graph.addCellOverlay(cell, confirmDel_overlay);

                            var del_overlayCancel = new mxCellOverlay(
                                new mxImage('img/delete_icon.svg', 36, 36),
                                'Cancel');
                            del_overlayCancel.cursor = 'pointer';
                            del_overlayCancel.verticalAlign = 'top';
                            del_overlayCancel.offset.y += 25;
                            del_overlayCancel.offset.x -= 27.5;

                            self.graph.addCellOverlay(cell, del_overlayCancel);

                            self.graph.removeCellOverlay(cell, del_overlay);
                            self.graph.removeCellOverlay(cell, edit_overlay);

                            //highlight
                            var oldStyle = cell.style;
                            var oldValue = cell.value;
                            var string = cell.style.split(";")
                            var str = string.slice(1, string.length);
                            var stringToBeConcat = str.join();
                            cell.style = "fillColor=#f7ad59;" + stringToBeConcat;
                            var cellsV = [];
                            cellsV.push(cell);
                            cell.value = "Delete?";
                            self.graph.getView().clear(cellsV[0], false, false);
                            self.graph.getView().validate();


                            confirmDel_overlay.addListener(mxEvent.CLICK, function () {
                                cell.value = "Deleting";
                                self.graph.getView().clear(cellsV[0], false, false);
                                self.graph.getView().validate();
                                $window.customFunctions.deleteState(obj, cells, cell);
                                self.graph.setEnabled(true);
                                isHigh = false;
                            });

                            del_overlayCancel.addListener(mxEvent.CLICK, function () {
                                self.graph.addCellOverlay(cell, del_overlay);
                                self.graph.addCellOverlay(cell, edit_overlay);
                                self.graph.removeCellOverlay(cell, del_overlayCancel);
                                self.graph.removeCellOverlay(cell, confirmDel_overlay);
                                isHigh = false;
                                self.graph.setEnabled(true);
                                //remove highlight
                                cell.style = oldStyle;
                                cell.value = oldValue;
                                self.graph.getView().clear(cellsV[0], false, false);
                                self.graph.getView().validate();
                            });

                        }
                        else {
                            // console.error('state has transitions');
                        }
                    });

                    edit_overlay.addListener(mxEvent.CLICK, function (sender, evt2) {
                        var obj;
                        for (var i = 0; i < self.lookup_state.obj.length; i++) {
                            if (cell == self.lookup_state.state[i]) {
                                obj = self.lookup_state.obj[i];
                            }
                        }
                        $window.customFunctions.editState(obj);

                    });

                    moreInfo_overlay.addListener(mxEvent.CLICK, function (sender, evt2) {
                        moreInfo_overlay.title = showInfoWindow(cell);
                    });

                    // Sets the overlay for the cell in the graph
                    self.graph.addCellOverlay(cell, del_overlay);
                    self.graph.addCellOverlay(cell, edit_overlay);
                    self.graph.addCellOverlay(cell, moreInfo_overlay);

                }
                try {
                    if (!evt.properties.cell && !isHigh) {
                        for (var i = 0; i < previous.length; i++) {
                            self.graph.removeCellOverlays(previous[i]);
                            self.graph.setTooltips(false);
                            self.graph.setEnabled(true);
                        }

                        previous = [];
                    }
                    if (previous[previous.length - 1] != previous[previous.length - 2] && previous.length >= 1 && !isHigh) {
                        self.graph.removeCellOverlays(previous[previous.length - 2]);
                        previous.shift();
                        //self.graph.setTooltips(false);
                        self.graph.setEnabled(true);
                    }
                }
                catch (e) {
                    //console.log(e);
                }

            }
            //for edges
            if (cell != null && cell.edge == true && cell.source.style != 'rhombStyle') {
                self.graph.setTooltips(true);
                var overlays = self.graph.getCellOverlays(cell);

                if (overlays == null) {
                    // Creates a new overlay with an image and a tooltip
                    var del_overlay = new mxCellOverlay(
                        new mxImage('img/delete_icon.svg', 16, 16),
                        'Delete');
                    del_overlay.offset.x += 15;
                    del_overlay.offset.y += 15;
                    del_overlay.cursor = 'pointer';
                    var edit_overlay = new mxCellOverlay(
                        new mxImage('img/edit_icon.svg', 16, 16),
                        'Edit');
                    edit_overlay.offset.x -= 15;
                    edit_overlay.offset.y += 15;
                    edit_overlay.cursor = 'pointer';

                    var moreInfo_overlay = new mxCellOverlay(
                        new mxImage('img/moreInfo.svg', 16, 16),
                        showInfoWindowTransition(cell));
                    moreInfo_overlay.cursor = 'pointer';
                    moreInfo_overlay.offset.y += 15;
                    moreInfo_overlay.offset.x -= 45;

                    // Installs a handler for clicks on the overlay							
                    del_overlay.addListener(mxEvent.CLICK, function (sender, evt2) {
                        var obj, src, index;

                        for (var i = 0; i < self.lookup_transition.obj.length; i++) {
                            if (cell == self.lookup_transition.transition[i]) {
                                obj = self.lookup_transition.obj[i];
                                index = i;
                                for (var j = 0; j < self.lookup_state.state.length; j++) {
                                    if (obj.properties.sourceVertex == self.lookup_state.state[j]) {
                                        src = self.lookup_state.obj[j];

                                    };
                                }


                                //Confirm or cancel
                                isHigh = true; // prevent clicking events from deleteing the icons
                                self.graph.setEnabled(false);

                                var edgStl = self.graph.getStylesheet().getDefaultEdgeStyle();

                                var confirmDel_overlay = new mxCellOverlay(
                                    new mxImage('img/confirm_del.svg', 36, 36), 'Confirm');
                                confirmDel_overlay.cursor = 'pointer';
                                confirmDel_overlay.verticalAlign = 'top';
                                confirmDel_overlay.offset.x -= 15;
                                confirmDel_overlay.offset.y += 15;
                                self.graph.addCellOverlay(cell, confirmDel_overlay);

                                var del_overlayCancel = new mxCellOverlay(
                                    new mxImage('img/delete_icon.svg', 36, 36),
                                    'Cancel');
                                del_overlayCancel.cursor = 'pointer';
                                del_overlayCancel.verticalAlign = 'top';
                                del_overlayCancel.offset.x += 15;
                                del_overlayCancel.offset.y += 15;

                                self.graph.addCellOverlay(cell, del_overlayCancel);

                                self.graph.removeCellOverlay(cell, del_overlay);
                                self.graph.removeCellOverlay(cell, edit_overlay);

                                //highlight
                                edgStl[mxConstants.STYLE_STROKECOLOR] = '#f7ad59';
                                mxConstants.EDGE_SELECTION_COLOR = '#f7ad59';
                                var oldValue = cell.value;
                                var cellsV = [];
                                cellsV.push(cell);
                                cell.value = "Delete?";
                                self.graph.getView().clear(cellsV[0], false, false);
                                self.graph.getView().validate();


                                confirmDel_overlay.addListener(mxEvent.CLICK, function () {
                                    cell.value = "Deleting";
                                    self.graph.getView().clear(cellsV[0], false, false);
                                    self.graph.getView().validate();

                                    $window.customFunctions.deleteTransition(obj, src);
                                    cells.push(cell);
                                    self.graph.removeCells(cells);
                                    //to remove cell also from lookups
                                    self.lookup_transition.transition.splice(index, 1);
                                    self.lookup_transition.obj.splice(index, 1);
                                    self.lookup_transition.id.splice(index, 1);
                                    self.undoManager.clear();

                                    self.graph.setEnabled(true);
                                    isHigh = false;
                                });

                                del_overlayCancel.addListener(mxEvent.CLICK, function () {
                                    self.graph.addCellOverlay(cell, del_overlay);
                                    self.graph.addCellOverlay(cell, edit_overlay);
                                    self.graph.removeCellOverlay(cell, del_overlayCancel);
                                    self.graph.removeCellOverlay(cell, confirmDel_overlay);
                                    isHigh = false;
                                    self.graph.setEnabled(true);
                                    //remove highlight
                                    edgStl[mxConstants.STYLE_STROKECOLOR] = '#686868';
                                    mxConstants.EDGE_SELECTION_COLOR = '#000000';
                                    cell.value = oldValue;
                                    self.graph.getView().clear(cellsV[0], false, false);
                                    self.graph.getView().validate();
                                });



                            }
                        }
                    });

                    edit_overlay.addListener(mxEvent.CLICK, function (sender, evt2) {
                        var obj, src;
                        for (var i = 0; i < self.lookup_transition.obj.length; i++) {
                            if (cell == self.lookup_transition.transition[i]) {
                                obj = self.lookup_transition.obj[i];
                                for (var j = 0; j < self.lookup_state.state.length; j++) {
                                    if (obj.properties.sourceVertex == self.lookup_state.state[j]) {
                                        src = self.lookup_state.obj[j];
                                    };
                                }
                                $window.customFunctions.editTransition(obj, src);
                            }
                        }

                    });

                    moreInfo_overlay.addListener(mxEvent.CLICK, function (sender, evt2) {
                        moreInfo_overlay.title = showInfoWindowTransition(cell);
                    });

                    // Sets the overlay for the cell in the graph
                    self.graph.addCellOverlay(cell, del_overlay);
                    self.graph.addCellOverlay(cell, edit_overlay);
                    self.graph.addCellOverlay(cell, moreInfo_overlay);
                }
                try {
                    if (!evt.properties.cell && !isHigh) {
                        for (var i = 0; i < previous.length; i++) {
                            self.graph.removeCellOverlays(previous[i]);
                            self.graph.setTooltips(false);
                            self.graph.setEnabled(true);
                        }

                        previous = [];
                    }
                    if (previous[previous.length - 1] != previous[previous.length - 2] && previous.length >= 1 && !isHigh) {
                        self.graph.removeCellOverlays(previous[previous.length - 2]);
                        previous.shift();
                        //self.graph.setTooltips(false);
                        self.graph.setEnabled(true);
                    }
                }
                catch (e) {
                    //console.log(e);
                }
            }
            //remove ovelays if clicked on conditional or decision box
            try {
                if (cell.source.style == 'rhombStyle') {
                    isConditionalOverlay = true;
                }
            } catch (e) {}
            try {
                if (cell.style == 'rhombStyle') {
                    isConditionalOverlay = true;
                }
            } catch (e) {}
            if (isConditionalOverlay) {
                for (var i = 0; i < previous.length; i++) {
                    self.graph.removeCellOverlays(previous[i]);
                }
                self.graph.setTooltips(false);
                self.graph.setEnabled(true);

                previous = [];
                isConditionalOverlay = false;
            }
        });

        self.refreshArrays();

        self.parseJson(self.json, self.temp_state, self.temp_transition);

        self.drawStates(self.temp_state, self.lookup_state);

        self.drawTransitions(self.temp_transition, self.temp_state, self.lookup_transition, self.lookup_state);


        //click on rhomb icons go wild
        var checkWorkflow = function () {
            var nullStateCount = 0;
            var y = 10;
            for (var i = 0; i < self.lookup_state.obj.length; i++) {
                if (self.lookup_state.obj[i].properties.x == null &&
                    self.lookup_state.obj[i].properties.y == null) {
                    nullStateCount++;
                    self.lookup_state.obj[i].properties.x = 10;
                    self.lookup_state.obj[i].properties.y = y;
                    y += 30;
                }
            }
            if (nullStateCount == self.lookup_state.obj.length) {
                isNewWorkflow = true;
            }
        };
        checkWorkflow();

        //Aditional Info on states with window on select
        var infoBox;
        var infoCells = [];
        var infoWindows = [];
        self.graph.addListener(mxEvent.TAP_AND_HOLD, function (sender, event) {
            showInfoWindow(event.properties.cell);
        });

        self.graph.addListener(mxEvent.CLICK, function (sender, event) {
            destroyInfoWindow();
        });


        var showInfoWindow = function (cell) {
            var state;
            for (var i = 0; i < self.lookup_state.state.length; i++) {
                if (cell == self.lookup_state.state[i]) {
                    state = self.lookup_state.obj[i];
                }
            }
            var notification;
            var rolesToNotify;
            var ballInCourt;
            var signatures;
            var nonBindSignatures;
            var needsToApprove;
            var hasApproved;
            var approvalRoleIds;
            if (state.properties.type != "0") {
                try {
                    notification = self.notificationsDict[state.properties.templateNotificationId].value
                } catch (e) {
                    notification = "Not set";
                }
                try {
                    rolesToNotify = self.assignedRolesDict[state.properties.roleIds[0]].value
                    for (var i = 1; i < state.properties.roleIds.length; i++) {
                        rolesToNotify += ";" + self.assignedRolesDict[state.properties.roleIds[i]].value
                    }
                } catch (e) {
                    rolesToNotify = "Not set";
                }
                try {
                    ballInCourt = self.ballInCourtDict[state.properties.ballInCourtRoleIds].value
                } catch (e) {
                    ;
                    ballInCourt = "Not set";
                }
                try {
                    signatures = self.signsDict[state.properties.signTransitionsIds[0]].value
                    for (var i = 1; i < state.properties.signTransitionsIds.length; i++) {
                        signatures += ";" + self.signsDict[state.properties.signTransitionsIds[i]].value
                    }
                } catch (e) {
                    signatures = "Not set";
                }
                try {
                    nonBindSignatures = self.nonBindingSignsDict[state.properties.nonBindingSignTransitionsIds[0]].value
                    for (var i = 1; i < state.properties.nonBindingSignTransitionsIds.length; i++) {
                        nonBindSignatures += ";" + self.nonBindingSignsDict[state.properties.nonBindingSignTransitionsIds[i]].value
                    }
                } catch (e) {
                    nonBindSignatures = "Not set";
                }
                var ballExists = state.properties.typeId == self.statesTypesDict[2].key || state.properties.typeId == self.statesTypesDict[100].key ? true : false;

                if (state.properties.typeId == self.statesTypesDict[101].key
                    || state.properties.typeId == self.statesTypesDict[100].key
                    || state.properties.typeId == self.statesTypesDict[102].key) {
                    var signExists = true;
                }
                if (ballExists && signExists) {
                    return "Notification: " + notification +
                        " <br> Roles to notify: " + rolesToNotify +
                        " <br> Ball in Court: " + ballInCourt +
                        " <br>  Signatures: " + signatures +
                        " <br> Signatures: " + nonBindSignatures;
                }
                else if (ballExists) {
                    return "Notification: " + notification +
                        " <br> Roles to notify: " + rolesToNotify +
                        " <br> Ball in Court: " + ballInCourt;
                }
                else {
                    return "Notification: " + notification +
                        " <br> Roles to notify: " + rolesToNotify +
                        " <br>  Signatures: " + signatures +
                        " <br> Signatures: " + nonBindSignatures;
                }
                
            }
            else {
                try {
                    needsToApprove = self.notificationsDict[state.properties.needsToApproveTemplateId].value
                } catch (e) {
                    needsToApprove = "Not set";
                }
                try {
                    hasApproved = self.notificationsDict[state.properties.hasApprovedTemplateId].value
                } catch (e) {
                    hasApproved = "Not set";
                }
                try {
                    hasRejected = self.notificationsDict[state.properties.hasRejectedTemplateId].value
                } catch (e) {
                    hasRejected = "Not set";
                }
                try {
                    approvalRoleIds = self.assignedRolesDict[state.properties.approvalRoleIds[0]].value
                    for (var i = 1; i < state.properties.approvalRoleIds.length; i++) {
                        approvalRoleIds += ";" + self.assignedRolesDict[state.properties.approvalRoleIds[i]].value
                    }
                } catch (e) {
                    approvalRoleIds = "Not set";
                }


                return "Roles to approve draft: " + approvalRoleIds +
                    " <br> Submit Notification: " + needsToApprove +
                    " <br> Approval Notification: " + hasApproved +
                    " <br>  Rejection Notification: " + hasRejected ;
            }
        }

        var destroyInfoWindow = function () {
            if (infoWindows.length > 0) {
                var cells = [];
                for (var i = 0; i < infoWindows.length; i++) {
                    cells.push(infoWindows[i]);
                }

                self.graph.removeCells(cells);

                self.undoManager.clear();
            }
        }

        var showInfoWindowTransition = function (cell) {
            var transition = cell.object;
            //roleIds & screenId
            var roles;
            var screen;
            var excludedRoles;
            if (cell.source.style != 'rhombStyle') {
                try {
                    roles = self.assignedRolesDict[transition.properties.roleIds[0]].value
                    for (var i = 1; i < transition.properties.roleIds.length; i++) {
                        roles += ";" + self.assignedRolesDict[transition.properties.roleIds[i]].value
                    }
                } catch (e) {
                    roles = "Not set";
                }
                try {
                    excludedRoles = self.assignedRolesDict[transition.properties.roleToExcludeIds[0]].value
                    for (var i = 1; i < transition.properties.roleToExcludeIds.length; i++) {
                        excludedRoles += ";" + self.assignedRolesDict[transition.properties.roleToExcludeIds[i]].value
                    }
                } catch (e) {
                    excludedRoles = "Not set";
                }
                try {
                    if (self.screenDict[transition.properties.screenId].value) {
                        screen = self.screenDict[transition.properties.screenId].value;
                    }
                } catch (e) { }
                try {
                    if (self.startScreenDict[transition.properties.screenId].value) {
                        screen = self.startScreenDict[transition.properties.screenId].value;
                    }
                } catch (e) { };
                if (typeof screen == "undefined") {
                    screen = "Not set"
                }

                if (cell.source.object.properties.type != "0") {
                    return "Roles: " + roles +
                        " <br> Form: " + screen +
                        " <br> Exclude Roles: " + excludedRoles;
                }
                else {
                    return "Roles: " + roles +
                        " <br> Form: " + screen;
                }
                
            }
        };

        mxRectangleShape.prototype.crisp = true;

        // Defines a new class for all icons
        var mxIconSet = function (state) {
            if (state.cell.connectable && state.cell.style != 'rhombStyle') {
                try {
                    this.images = [];
                    var graph = state.view.graph;
                    var md = (mxClient.IS_TOUCH) ? 'touchstart' : 'mousedown';

                    // Icon1
                    var img = mxUtils.createImage('img/arr_right.svg');
                    img.setAttribute('title', 'Draw transition');
                    img.style.position = 'absolute';
                    img.style.cursor = 'pointer';
                    img.style.width = '30px';
                    img.style.height = '30px';
                    img.style.left = (state.x + state.width) + 'px';
                    img.style.top = (state.y + state.height / 4) + 'px';
                    //for normal graph
                    mxEvent.addListener(img, md,
                        mxUtils.bind(this, function (evt) {
                            self.graph.setConnectable(true);
                            var pt = mxUtils.convertPoint(self.graph.container,
                                mxEvent.getClientX(evt), mxEvent.getClientY(evt));
                            self.graph.connectionHandler.start(state, pt.x, pt.y);
                            self.graph.isMouseDown = true;
                            self.graph.isMouseTrigger = mxEvent.isMouseEvent(evt);
                            mxEvent.consume(evt);
                        })
                    );


                    state.view.graph.container.appendChild(img);
                    this.images.push(img);
                }
                catch (e) {
                    console.log(e);
                }
            }
        };

        mxIconSet.prototype.destroy = function () {
            if (this.images != null) {
                for (var i = 0; i < this.images.length; i++) {
                    var img = this.images[i];
                    img.parentNode.removeChild(img);
                }
            }

            this.images = null;
            self.graph.setConnectable(false);
        };

        // Defines the tolerance before removing the icons
        var iconTolerance = 60;

        self.graph.addListener(mxEvent.CELLS_REMOVED, function () {
            try {
                self.currentIcon.destroy();
                self.currentIcon = null;
            } catch (e) {

            }
        });//to remove arrow icon after state is deleted

        // Shows icons if the mouse is over a cell
        self.graph.addMouseListener(
            {
                currentState: null,
                currentIconSet: null,
                mouseDown: function (sender, me) {
                    // Hides icons on mouse down
                    if (this.currentState != null) {
                        this.dragLeave(me.getEvent(), this.currentState);
                        this.currentState = null;
                    }
                },
                mouseMove: function (sender, me) {
                    if (this.currentState != null && (me.getState() == this.currentState ||
                        me.getState() == null)) {
                        var tol = iconTolerance;
                        var tmp = new mxRectangle(me.getGraphX() - tol,
                            me.getGraphY() - tol, 2 * tol, 2 * tol);

                        if (mxUtils.intersects(tmp, this.currentState)) {
                            return;
                        }
                    }

                    var tmp = self.graph.view.getState(me.getCell());

                    // Ignores everything but vertices
                    if (self.graph.isMouseDown || (tmp != null && !self.graph.getModel().isVertex(tmp.cell))) {
                        tmp = null;
                    }

                    if (tmp != this.currentState) {
                        if (this.currentState != null) {
                            this.dragLeave(me.getEvent(), this.currentState);
                        }

                        this.currentState = tmp;

                        if (this.currentState != null) {
                            this.dragEnter(me.getEvent(), this.currentState);
                        }
                    }
                },
                mouseUp: function (sender, me) { },
                dragEnter: function (evt, state) {
                    if (this.currentIconSet == null) {
                        this.currentIconSet = new mxIconSet(state);
                        self.currentIcon = this.currentIconSet;
                    }
                },
                dragLeave: function (evt, state) {
                    if (this.currentIconSet != null) {
                        this.currentIconSet.destroy();
                        this.currentIconSet = null;
                    }
                }
            });


        self.graph.addListener(mxEvent.CLICK, function (evt) {
            var c = self.graph.getSelectionCell();
        });

        self.graph.getModel().beginUpdate();
        try {

            if (isNewWorkflow) {
                self.hierarchicalDynamicLayout(self.graph);
                var children = self.graph.getChildCells();
                self.graph.moveCells(children, undefined, 10);


            }
            //self.otherLayout(self.graph);
            //self.hierarchicalDynamicLayout(self.graph);

            if (!isNewWorkflow) {
                var children = self.graph.getChildCells();
                self.graph.moveCells(children, undefined, 0.1);
            }
        }
        finally {
            // Updates the display
            self.graph.getModel().endUpdate();
            self.undoManager.clear();//to prevent undo of layout
        }

    }

    return GraphModel;
});
