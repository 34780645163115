import { Component, inject, signal } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { DemoListboxFields } from '../../data/demo-cipo-fields';
import { CipoListControl } from '../../../shared/components/fields/common';
import { CipoListboxComponent } from '../../../shared/components/fields/cipo-listbox/cipo-listbox.component';

@Component({
  selector: 'app-demo-cipo-listbox',
  standalone: true,
  imports: [CipoListboxComponent, FlexLayoutModule, KeyValuePipe],
  templateUrl: './demo-cipo-listbox.component.html',
})
export class DemoCipoListboxComponent {
  fb = inject(FormBuilder);
  dialog = inject(MatDialog);

  form = signal(new FormGroup<{ [x: string]: CipoListControl }>({}));

  constructor() {
    DemoListboxFields.forEach(field => {
      const { name, defaultValue, ...props } = field;
      const control = new CipoListControl(defaultValue, props);
      if (props.required) {
        control.setValidators(Validators.required);
      }
      this.form().addControl(name, control);
    });
  }
}
