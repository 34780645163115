import { cipo } from 'cipo';

cipo.controller('RegisterModalController',
    function ($scope, Login, Register, Message, $uibModalInstance) {
        $scope.registerObject = new Register();

        $scope.submitPressed = false;
        $scope.register = function () {
            if ($scope.registerObject.properties.password != $scope.registerObject.properties.confirmPassword) {
                $scope.registerObject.reset();
                Message.error('The passwords do not match');
            } else {
                $scope.submitPressed = true;
                $scope.registerObject.send_Data().then(function () {
                    // login after register
                    try {
                        var loginObject = new Login();
                        loginObject.properties.username = $scope.registerObject.properties.userName;
                        loginObject.properties.password = $scope.registerObject.properties.password;
                        loginObject.properties.rememberMe = false;

                        loginObject.get_Token().then(function () {
                            $uibModalInstance.close({});
                        }).catch(function (serverCalled) {
                            $scope.submitPressed = false;
                            if (serverCalled) Message.error('Could not log you in.');
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }).catch(function (serverCalled) {
                    if (serverCalled) Message.error('User already exists');
                }).finally(function () {
                    $scope.submitPressed = false;
                });
            }
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        }
    });
