import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../../models/system/currency';
import { ItvNumberPipe } from './itv-number.pipe';

@Pipe({ name: 'itvCurrency' })
export class ItvCurrencyPipe implements PipeTransform {
  constructor(private numberPipe: ItvNumberPipe) {}

  transform(value: number | string, currency: Currency | null | undefined = undefined, decimals: number = 0, showComma: boolean = true): string {
    const showCurrency = currency !== null && currency !== undefined;
    return this.numberPipe.transform(value, decimals, showComma, showCurrency, false, currency);
  }
}
