import { cipo } from 'cipo';

cipo.factory("FieldMod", function ($q, Model, URI, Message, FIELDS_INFO, Form) {
    var FieldMod = Model.extend(function (obj, isStartScreen, limitedEdit) {
        var self = this;
        self.id= null;
        // self.fieldId= null;
        self.addToForm = false;
        self.readonly = false;
        // self.displayOnGrid = isStartScreen ? true : false;
        self.label= "";
        self.fieldTypeId= null;
        self.required = false;
        self.name = "";
        self.defaultValue = null;
        self.configurationDataSource = null;
        self.dataSource = null;
        self.isInitializedForTransition = false;
        if (obj) {
            if (obj.id || obj.fieldId) {
                for (var key in FIELDS_INFO[obj.fieldTypeId]) {
                    if (FIELDS_INFO[obj.fieldTypeId].hasOwnProperty(key)) {
                        self[key] = FIELDS_INFO[obj.fieldTypeId][key];
                    }
                }
                for (var key in obj) {
                    if (obj.hasOwnProperty(key) && key != 'value') {
                        self[key] = obj[key];
                    }
                }
            }
            else {
                self.fieldTypeId = obj.key;
                // self.value = obj.value;
                for (var key in FIELDS_INFO[obj.key]) {
                    if (FIELDS_INFO[obj.key].hasOwnProperty(key)) {
                        self[key] = FIELDS_INFO[obj.key][key];
                    }
                }
            }
            if (self.fieldTypeId == 17|| self.isFormula) self.readonly = true;
            
            
        }
        self.type = obj.type || 1;
        var selectTypeFieldsId = [5, 8, 13, 20, 21];
        if (selectTypeFieldsId.indexOf(parseInt(self.fieldTypeId))!=-1) {
            self.dataSource = typeof obj != 'undefined' && typeof obj.dataSource != 'undefined' ? obj.dataSource : null;//dataSourceType
            self.dataSourceType = typeof (obj || {}).dataSourceType != 'undefined' ? obj.dataSourceType : 0;
            var dataSource = obj.configurationDataSource || obj.dataSource;
            self.dataSourceComposed = (obj || {}).dataSourceComposed || (typeof dataSource != 'undefined' ? dataSource + "|" + self.dataSourceType : null);
            self.isSelectType = true;
        } else if (self.fieldTypeId == 9) {
            self.annotation = obj && obj.annotation ? obj.annotation : "";
        } else if (self.fieldTypeId == 17 || self.isFormulaField) {
            self.fieldExpression = obj && obj.fieldExpression ? obj.fieldExpression : "";
        }
        if (self.fieldTypeId == 20 || self.fieldTypeId == 21 || self.fieldTypeId == 22) {
            self.dataSourceStateTypes = self.dataSourceStateTypes || [];
            self.relationId = self.relationId || null;
            self.isRelation = true;

        }
        if (self.fieldTypeId == 11) {
            self.relationId = self.relationId || null;
            self.isRelation = self.isRelation || true;
            self.dataSource = self.dataSource || null;
        }
        if (isStartScreen && self.fieldTypeId != 9) {
            self.isEntityProperty = self.isEntityProperty ? self.isEntityProperty : true;
        }
        if (!self.isEntityProperty) self.addToForm = true;
    });


    


    return FieldMod;
});
