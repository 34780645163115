import { cipo } from 'cipo';

cipo.controller('mycipodriveController',
    function ($scope, $state, $http, APP_CONTENT, URI, Explorer1, userService, FileManagerConfig) {
        if (!$scope.mainViewLoaded) {
            return;
        }
        
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        $scope.content = APP_CONTENT;
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor("MCD"); }
        });

        $scope.explorerData = FileManagerConfig.cipoDrive;

        $scope.loadExplorer = function (storageType) {
            // storageType = $scope.userStorage;

            $scope.explorer = new Explorer1({
                // operations: $scope.operations,
                abbreviation: 'MCD',
                layout: 1,
                hasShare: true,
                rootName: "...",
                treeSetup: {
                    treeOptions: {
                        allowDeselect: false,
                        nodeChildren: "folders",
                        dirSelectable: true,
                        isLeaf: function (node) { if (node.id == -2 || node.id == -10) return true; else return false; },
                        multiSelection: false,
                        injectClasses: {
                            ul: "a1",
                            li: "a2",
                            liSelected: "a7",
                            iExpanded: "a3",
                            iCollapsed: "a4",
                            iLeaf: "a5",
                            label: "a6",
                            labelSelected: "a8"

                            }
                        },
                        orderby: ""
                    },
                    urlsData: storageType,
                    managerSetup: {
                        search: true,
                        customFilters: [
                            { key: "showAllPhotos", value: false, label: "Show images only", type: "checkbox" }
                        ],
                        columns: {
                            1: [
                                { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                                { name: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 25, fieldTypeId: 1 },
                                { name: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                                { name: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                                { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 }
                            ],
                            2: [
                                { name: "_big_name", label: 'Name', type: 'checkbox' }
                            ]
                        },
                        searchColumns: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            { name: "folderPath", replacementFor: "folderPath", label: 'Folder', type: 'checkbox', onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10 } // fieldTypeId: 2
                        ],
                        globalSearchColumns: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
                            { name: "folderPath", replacementFor: "folderPath", label: 'Folder', type: 'checkbox', onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                            { name: "contractName", replacementFor: "contractName", label: 'Contract', type: 'checkbox', onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10 } // fieldTypeId: 2
                        ],
                        recycleBinColumns: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            //{ name: "contractName", replacementFor: "contractName", label: 'Contract', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                            { name: "updatedBy", replacementFor: "updatedBy", label: 'Deleted By', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 1 },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Deleted On', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "pathWhenDeleted", replacementFor: "pathWhenDeleted", label: 'Original Location', type: 'checkbox', onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
                            // { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 } // fieldTypeId: 2
                        ],
                    }
                });
            };

        $scope.loadExplorer($scope.explorerData);

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    if ($scope.explorer.manager) {
                        $scope.explorer.manager.loading = true;
                        $scope.explorer.manager.loadPage();
                    }
                    userService.isReloadGrid = false;
                }
            });
    });
