import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { finalize, forkJoin } from 'rxjs';
import { ScreenFieldModel } from '../../../../models/module/fields/main';
import { SearchViewModel } from '../../../../models/module/grid';
import { DataListViewAction } from '../../../../models/module/main/dataListViewAction';
import { ScreenTypeEnum } from '../../../../models/module/screen';
import { Currency, CurrencyFn } from '../../../../models/system/currency';
import { LocaleDateAdapter } from '../../../../shared/services/date.adapter';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DataCardsViewService } from '../../data-card/data-cards-view/data-cards-view.service';
import { CalendarDialogDocDetailPrefix } from '../calendar-dialog-doc-detail/calendar-dialog-doc-detail.component';
import { CalendarDialogMorePrefix } from '../calendar-dialog-more/calendar-dialog-more.component';
import { CalendarDate, CalendarReferenceField, CalendarViewMode } from '../calendar.model';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarViewComponent implements OnInit, OnChanges {
  @Input() actions: DataListViewAction[];
  @Input() search: SearchViewModel;
  @Input('contractid') contractId: number;
  @Input() currency: Currency | CurrencyFn;
  @Input() reloading: boolean;

  today: Date;
  calendarDate: CalendarDate;
  calendarViewMode: CalendarViewMode = CalendarViewMode.Month;
  isViewLoading: boolean;
  showWeekends: boolean = false;
  showWeekNumber: boolean = false;
  calendarDateField: CalendarReferenceField;
  calendarDescField: CalendarReferenceField;
  screenFields: ScreenFieldModel[];
  dateFields: CalendarReferenceField[];
  loadingConfig = false;

  constructor(
    private dateAdapter: LocaleDateAdapter,
    private translate: TranslateService,
    private calendarService: CalendarService,
    private dataCardsViewService: DataCardsViewService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.today = this.dateAdapter.today();
    this.setToday();
    this.loadConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloading?.currentValue) {
      // when reloading is true, reload config
      this.loadConfig();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    this.closeAllCalendarDialogs(event);
  }

  get monthName(): string {
    return moment(this.calendarDate.date).format('MMMM');
  }

  get monthNames(): string[] {
    return this.dateAdapter.getMonthNames('long');
  }

  get calendarViewModeType(): typeof CalendarViewMode {
    return CalendarViewMode;
  }

  get prevLabel(): string {
    switch (this.calendarViewMode) {
      case CalendarViewMode.Day:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_PREV_DAY');
      case CalendarViewMode.Week:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_PREV_WEEK');
      case CalendarViewMode.Month:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_PREV_MONTH');
      case CalendarViewMode.Year:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_PREV_YEAR');
      default:
        return '';
    }
  }

  get nextLabel(): string {
    switch (this.calendarViewMode) {
      case CalendarViewMode.Day:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_NEXT_DAY');
      case CalendarViewMode.Week:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_NEXT_WEEK');
      case CalendarViewMode.Month:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_NEXT_MONTH');
      case CalendarViewMode.Year:
        return this.translate.instant('MODULE_INSTANCE_CALENDAR.BUTTON_NEXT_YEAR');
      default:
        return '';
    }
  }

  get yearsSelection(): number[] {
    const year = this.today.getFullYear() + 5;
    return [...Array(16).keys()].map(n => year - n);
  }

  get monthsSelection(): number[] {
    return [...Array(12).keys()];
  }

  setToday(): void {
    this.setCalendarDate(moment(this.today));
  }

  setYear(value: number): void {
    this.setCalendarDate(moment(this.calendarDate.date).year(value));
  }

  setMonth(value: number): void {
    this.setCalendarDate(moment(this.calendarDate.date).month(value));
  }

  incrementDecrementDate(decrement: boolean): void {
    let m = moment(this.calendarDate.date);

    if (this.calendarViewMode == CalendarViewMode.Year) {
      m.add(decrement ? -1 : 1, 'y');
    }
    if (this.calendarViewMode == CalendarViewMode.Month) {
      m.add(decrement ? -1 : 1, 'M');
    }
    if (this.calendarViewMode == CalendarViewMode.Week) {
      m.add(decrement ? -1 : 1, 'w');
    }
    if (this.calendarViewMode == CalendarViewMode.Day) {
      m.add(decrement ? -1 : 1, 'd');
    }
    this.setCalendarDate(m);
  }

  viewIsLoading(value: boolean): void {
    this.isViewLoading = value;
  }

  setDateField(field: CalendarReferenceField) {
    this.calendarDateField = field;
  }

  matchDateField(field: CalendarReferenceField) {
    return (
      this.calendarDateField &&
      ((this.calendarDateField.id && field.id && this.calendarDateField.id == field.id) ||
        (this.calendarDateField.name && field.name && this.calendarDateField.name == field.name))
    );
  }

  gotoMonthView(date: CalendarDate): void {
    this.calendarViewMode = CalendarViewMode.Month;
    this.setCalendarDate(moment(date.date));
  }

  private loadConfig(): void {
    if (this.loadingConfig) {
      return;
    }

    this.loadingConfig = true;

    forkJoin({
      descReferenceFields: this.calendarService.getDescriptionReferenceFields(),
      dateReferenceFields: this.calendarService.getDateReferenceFields(),
      screenFields: this.dataCardsViewService.getScreenFields(ScreenTypeEnum.Calendar),
      screen: this.calendarService.getScreen(),
    })
      .pipe(
        finalize(() => {
          this.loadingConfig = false;
          this.ref.markForCheck();
        }),
      )
      .subscribe({
        next: ({ descReferenceFields, dateReferenceFields, screenFields, screen }) => {
          this.dateFields = dateReferenceFields.sort((a, b) => a.label.localeCompare(b.label));
          this.calendarDateField = this.getCalendarDateField(dateReferenceFields, screen?.dateFieldId);
          this.calendarDescField = this.getCalendarDescField(descReferenceFields, screen?.descriptionFieldId);
          this.screenFields = screenFields?.data || [];
        },
        error: error => this.notificationService.error(error),
      });
  }

  private getCalendarDateField(
    dateReferenceFields: CalendarReferenceField[],
    dateFieldId?: number,
  ): CalendarReferenceField {
    if (!dateReferenceFields.length) {
      return undefined;
    }

    const found = dateReferenceFields.find(f => f.id == dateFieldId);
    return found ? found : dateReferenceFields[0];
  }

  private getCalendarDescField(
    dateReferenceFields: CalendarReferenceField[],
    descFieldId?: number,
  ): CalendarReferenceField | undefined {
    if (!dateReferenceFields.length || !descFieldId) {
      return undefined;
    }

    return dateReferenceFields.find(f => f.id == descFieldId);
  }

  private setCalendarDate(dt: moment.Moment): void {
    this.calendarDate = {
      date: dt.toDate(),
      year: dt.year(),
      month: dt.month(),
      week: dt.isoWeek(),
      day: dt.date(),
    };
  }

  private closeAllCalendarDialogs(event: any) {
    const dialogMoreButtonClicked = event?.target?.closest('.more');
    const dialogDocMiniClicked = event?.target?.closest('.doc-mini');
    const dialogClicked = event?.target?.closest('.mat-mdc-dialog-container');

    // check if the target of the click is one of the calendar clickable elements that should not close the calendar dialogs
    if (dialogClicked || dialogMoreButtonClicked || dialogDocMiniClicked) {
      return;
    }

    // find and close all calendar dialogs
    const calendarDialogs = this.dialog.openDialogs.filter(
      d => d.id && (d.id.startsWith(CalendarDialogMorePrefix) || d.id.startsWith(CalendarDialogDocDetailPrefix)),
    );
    for (const dialog of calendarDialogs) {
      dialog.close();
    }
  }
}
