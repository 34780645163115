import { cipo } from 'cipo';

cipo.controller('editUrlController',
    function ($scope, Message, MODAL, URL, ReportingModule, operations, module, $q, obj, $mdDialog, simplifiedVersion, typeId, MODULE_TYPES, $timeout, userService) {

        $scope.modalInfo = MODAL;
        $scope.simplifiedVersion = simplifiedVersion || false;
        $scope.obj = typeId == MODULE_TYPES.URL
            ? new URL(obj, simplifiedVersion)
            : typeId == MODULE_TYPES.REPORTING
                ? new ReportingModule(obj, simplifiedVersion)
                : null;

        $scope.operations = operations || {};

        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.obj.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();

        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        $scope.disabledBtn = false;
        $scope.icons = userService.system.icons.list;

        $scope.actionBtnDisabled = false;

        Object.defineProperty($scope, 'canEditRoles', {
            get: function () { return userService.getOperationsFor("R").Update; }
        });

        Object.defineProperty($scope, 'newModule', {
            get: function () { return $scope.obj.properties.id ? false : true }
        });

        Object.defineProperty($scope, 'selectedIcon', {
            get: function () { return $scope.obj.properties.iconId }
        });

        $scope.setIcon = function (iconId) {
            $scope.obj.properties.iconId = iconId;
            $scope.$apply();
        };

        $scope.$on('tab-change', function (event, tabEvent) {
            if (!$scope.obj.form.dirty) {
                tabEvent.accept();
            } else {
                // capture the index of the tab cliked
                $scope.navToIndex = tabEvent.index;
                // set the warning params
                $scope.hightlight = true;
                $scope.tabWarning = true;

                $scope.disabledBtn = true;
                // prevent tab change
                tabEvent.reject();
            }
        });

        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            if (!$scope.closingModal) {
                $scope.obj.form.restore_Data();
                $scope.obj.form.set_Data($scope.obj.properties);
                $scope.tabWarning = false;
                $scope.hightlight = false;
                $scope.disabledBtn = false;
                if (!$scope.changingStatus)
                    $scope.selectedIndex = $scope.navToIndex;
                else
                    $scope.changeStatus();
            } else {
                $mdDialog.cancel();
            }
        };

        $scope.updateTabInfo = function () {
            $scope.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $scope.tabWarning = false;
                    if (!$scope.closingModal && !$scope.changingStatus) {
                        $scope.selectedIndex = $scope.navToIndex;
                    } else if ($scope.changingStatus) {
                        $scope.changeStatus();
                    } else {
                        $mdDialog.cancel();
                    }
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;
            if ($scope.changingStatus) {
                $scope.obj.disabled = !$scope.obj.disabled;
                $scope.changingStatus = false;
            }
            if ($scope.closingModal) $scope.closingModal = false;
        };

        $scope.changeStatus = function () {
            if (!$scope.obj.form.dirty) {
                if ($scope.obj.disabled) {
                    $scope.deactivateModule();
                } else {
                    $scope.activateModule();
                }
            }
            else {
                $scope.tabWarning = true;
                $scope.hightlight = true;
                $scope.changingStatus = true;
            }
        };

        $scope.deactivateModule = function () {
            $scope.disabledBtn = true;
            $scope.message = "Deactivating URL...";
            $scope.obj.disable()
                .then(function (result) {
                    Message.info('URL deactivated successfully');
                })
                .catch(function (e) {
                    $scope.obj.disabled = false;
                    console.error(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
        };

        $scope.activateModule = function () {
            $scope.disabledBtn = true;
            $scope.message = "Activating URL...";
            $scope.obj.enable()
                .then(function (result) {
                    Message.info('URL activated successfully');
                })
                .catch(function (e) {
                    console.error(e);
                    $scope.obj.disabled = true;
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
        };

        $scope.save = function () {
            var p = $q.defer();
            $scope.disabledBtn = true;
            $scope.tabWarning = false;
            $scope.hightlight = false;
            if ($scope.selectedIndex == 0) {
                $scope.message = "Saving URL...";
                $scope.obj.save()
                    .then(function () {
                        Message.info('URL saved successfully');
                        p.resolve();
                        $scope.disabledBtn = false;
                    })
                    .catch(function (e) {
                        p.reject(e);
                    })
                    .finally(function () {
                        $scope.disabledBtn = false;
                        $scope.message = "";
                    });
                return p.promise;
            }
            else if ($scope.selectedIndex == 1) {
                $scope.message = "Saving roles...";
                $scope.obj.save_assignments()
                    .then(function () {
                        Message.info('Roles saved successfully');
                        p.resolve();
                        $scope.disabledBtn = false;
                    })
                    .catch(function (e) {
                        p.reject(e);
                    })
                    .finally(function () {
                        $scope.disabledBtn = false;
                        $scope.message = "";
                    });
                return p.promise;
            }
        };

        $scope.all_permissions = function (e, isSelectAll, module) {
            if ((module.permissions || []).length) {
                module.permissionsLine = '';
                for (var j = 0; j < module.permissions.length; j++) {
                    if (module.permissions[j].isDefault) {
                        continue;
                    }
                    if (isSelectAll) {
                        module.permissions[j].isUsed = true;
                        module.permissions[j].filterId = module.permissions[j].filterId || module.permissions[j].filters[0].key;

                    } else {
                        module.permissions[j].isUsed = false;
                        module.permissions[j].filterId = module.permissions[j].filters[0].key;
                    }
                }
                module.permissionsLine = (module.permissions || []).map(p => p.isUsed ? p.name : '').filter(p => !!p).join(', ');
            }
        }

        $scope.close = function () {
            if (!$scope.obj.form.dirty)
                $mdDialog.cancel();
            else {
                $scope.hightlight = true;
                $scope.tabWarning = true;
                $scope.closingModal = true;
            }
        };
    });