export interface RenameFileParams {
  id: number;
  parentId: number;
  name: string;
  isFolder?: boolean;
  entityInstanceId?: number;
}

export interface CreateFolderParams {
  parentId: number;
  name: string;
  setOnContract?: boolean;
  entityInstanceId?: number;
}

export interface EditFolderParams {
  id: number;
  name: string;
  parentId: number;
  entityInstanceId?: number;
}

export interface GetUploadUrlParams {
  parentId: number;
  mimeType: string;
  isCreateVersion?: boolean;
  entityInstanceId?: number;
}

export interface GetVersionUrlParams {
  fileId: number;
  mimeType: string;
  entityInstanceId?: number;
}

export interface SaveFileInfoParams {
  mimeType: string;
  isCreateVersion?: boolean;
  fileId: string;
  totalBlocks: number;
  parentId: number;
  roleId?: number;
  entityInstanceId?: number;
}

export interface AddChunkedVersionParams {
  fileId: number;
  physicalId: string;
  totalBlocks: number;
  mimeType: string;
  roleId?: number;
  entityInstanceId?: number;
}

export interface DuplicateVersionParams {
  roleId?: number;
  entityInstanceId?: number;
}

export interface DuplicateVersionBody {
  fileId: number;
  versionId: number;
}

export interface SaveFileInfoBody {
  fileName: string;
  bytes: number;
}