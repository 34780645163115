export interface DateFormats {
  date: string;
  datetime: string;
  time: string;
}

export interface UserState {
  formats: DateFormats;
}

export const userInitialState: UserState = {
  formats: {
    date: 'MM/DD/YYYY',
    datetime: 'MM/DD/YYYY hh:mm A',
    time: 'hh:mm A',
  },
};
