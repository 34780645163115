import { cipo } from 'cipo';
import moment from 'moment';

cipo.directive("reportsfilters", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "fields": "=",
            "info": "="
        },
        templateUrl: "/ng/views/directives/filters/managerfilters.html",
        controller: function ($scope, FILTERS, URI, Model, Message, userService, $timeout) {
            $scope.Filters = FILTERS();
            $scope.filterscontainer = "gridContainer200Max";
            $scope.hideButtons = true;
            // $scope.cols = $scope.columns ? 12 / parseInt($scope.columns) : 12;
            // $scope.manager.filtersLookup = {};

            var createLookup = function (options) {
                var ret = {};
                for (var i = 0; i < options.length; i++) {
                    ret[options[i].key] = options[i];
                }
                return ret;
            }

            var initFilters = function () {
                $scope.filters = { overallLogic: FILTERS().logic[0].key };
                var item, filters = [];
                var columns = $scope.fields;
                for (var i = 0; i < columns.length; i++) {
                    if (columns[i].typeId || columns[i].fieldTypeId) {

                        if (!columns[i].typeId) columns[i].typeId = columns[i].fieldTypeId;

                        item = {};
                        var displayTypeId;
                        if (!columns[i].displayTypeId) {
                            if (columns[i].typeId == 3) displayTypeId = 5;
                            else if (columns[i].typeId == 10) displayTypeId = 3;
                            else displayTypeId = 1;
                        } else displayTypeId = columns[i].displayTypeId;
                        var jointId = (columns[i].typeId || columns[i].fieldTypeId).toString() + displayTypeId.toString();
                        item = {
                            jointId: jointId,
                            dataSourceId: columns[i].dataSourceId,
                            relationId: columns[i].relationId,
                            dataSource: [],
                            field: columns[i].id ? columns[i].id.toString() : columns[i].replacementFor.toString(),
                            fieldName: columns[i].label || columns[i].label,
                            options: { format: columns[i].options },
                            operator: FILTERS().operatorsDict[jointId] ? FILTERS().operatorsDict[jointId][0].key : null,
                            defaultOperator: FILTERS().operatorsDict[jointId] ? FILTERS().operatorsDict[jointId][0].key : null,
                            operators: FILTERS().operatorsDict[jointId] || [],
                            value: null,
                            defaultValue: null,
                            type: $scope.Filters.filterTypeMapping[jointId],
                            typeId: columns[i].typeId,

                        }

                        if (item.type == "select") {
                            item.value = $scope.Filters.checkboxSourceDict[0].key;
                            item.defaultValue = $scope.Filters.checkboxSourceDict[0].key;

                        }
                        if (item.type == "multiselect") {
                            item.value = [];
                            item.defaultValue = [];
                        }

                        if ($scope.info.savedFilters && $scope.info.savedFilters.filters[item.field]) {
                            for (var key in $scope.info.savedFilters.filters[item.field]) {
                                if ($scope.info.savedFilters.filters[item.field].hasOwnProperty(key)) {
                                    if (key != 'value' || key == 'value' && item.typeId != 4 && item.typeId != 3) {
                                        item[key] = item.typeId != '2'
                                            ? $scope.info.savedFilters.filters[item.field][key]
                                            : parseInt($scope.info.savedFilters.filters[item.field][key]);
                                    }
                                    else if (key == 'value' && item.typeId == 3) {
                                        item[key] = true;
                                    }
                                    else {
                                        if (Object.prototype.toString.call($scope.info.savedFilters.filters[item.field][key]) != '[object Array]')
                                            item[key] = moment.utc($scope.info.savedFilters.filters[item.field][key]);
                                        else {
                                            for (var j = 0; j < $scope.info.savedFilters.filters[item.field][key].length; j++) {
                                                $scope.info.savedFilters.filters[item.field][key][j] = moment.utc($scope.info.savedFilters.filters[item.field][key][j]);
                                            }
                                            item[key] = $scope.info.savedFilters.filters[item.field][key];
                                        }



                                    }
                                }
                            }

                            if ((item.dataSource || []).length) item.dataSourceLookup = createLookup(item.dataSource);

                        }


                        filters.push(item);
                    }


                }

                $scope.filters.filters = filters;

                $scope.info.filters = $scope.filters;
            }

            initFilters();



            $scope.resetFilter = function (f) {
                f.value = f.defaultValue;
                f.operator = f.defaultOperator;
            }

            $scope.resetFilters = function () {
                for (var i = 0; i < $scope.filters.filters.length; i++) {
                    $scope.resetFilter($scope.filters.filters[i]);
                }
                
            }

            for (var i = 0; i < $scope.filters.filters.length; i++) {
                (function (index) {
                    Object.defineProperty($scope.filters.filters[index], 'hasValue', {
                        get: function () {
                            var hasValue = false;
                            if ($scope.filters.filters[index].type == "select") {
                                hasValue = $scope.filters.filters[index].value !== 0 ? true : false;

                            } else if ($scope.filters.filters[index].type == "multiselect") {
                                hasValue = $scope.filters.filters[index].value.length ? true : false;
                            } else if ($scope.filters.filters[index].type == "datetimepicker") {
                                hasValue = false;
                                if (Object.prototype.toString.call($scope.filters.filters[index].value) == '[object Array]') {
                                    if ($scope.filters.filters[index].value[0] && $scope.filters.filters[index].value[1]) hasValue = true;
                                }
                                else if ($scope.filters.filters[index].value) hasValue = true;
                            } else {
                                hasValue = $scope.filters.filters[index].value ? true : false;
                            }

                            return hasValue;

                        }
                    });
                }(i));
            }


            $scope.dateTime_reinit = function (f) {
                f.value = null;
                if (f.operator == 40) f.value = [null, null];
            }


            // select 

                $scope.selectOpen = function (f, searchTerm) {
                    if (f.dataSourceId || f.relationId) {
                        $scope.getDataSources(f, searchTerm);
                    } else {
                        $timeout(function () {
                            f.focusSearch = true;
                            $timeout(function () {
                                f.focusSearch = false;
                            }, 300);
                        }, 300);
                    }

            }

                $scope.getDataSources = function (f, searchTerm) {
                    var field = f;
                    field.loading = true;
                    field.currentValue = field.value ? field.value.toString() : field.value;
                    var value = field.value && Object.prototype.toString.call(field.value) != '[object Array]' ? [field.value] : field.value;
                    var urlData = field.typeId == 10 ? URI.FIELD_DEFINITION.DATASOURCE_RELATION_FILTER : URI.FIELD_DEFINITION.DATASOURCE_LIST;
                    var bodyParams = {
                        selectedIds: value || [],
                        contractId: userService.system.userdata.contractId,
                        search: {
                            criteria: searchTerm || "",
                            isCurrent: false, page: 1, pagesize: 2000
                        }
                    }
                    var ghostDatasource = [];
                    if ((field.dataSource || []).length) {
                       
                        for (var i = 0; i < field.dataSource.length; i++) {
                            if (field.dataSource[i].key < -100000) {
                                ghostDatasource.push(field.dataSource[i]);
                            }
                        }
                    }

                if (field.typeId == 10) {
                    bodyParams.relationId = field.relationId;
                    bodyParams.fieldId = field.field;
                }
                else bodyParams.id = field.dataSourceId;
                Model[urlData.method](urlData, {
                    body: bodyParams
                }, { headers: { moduleId: $scope.info.moduleId } })
                    .then(function (r) {
                        //console.error("select", field, r);
                        field.isLoaded = true;
                        $timeout(function () {
                            field.focusSearch = true;
                            field.dataSource = (r || {}).data || [];
                            field.dataSourceLookup = (r || {}).data ? createLookup((r || {}).data) : {};

                            if (ghostDatasource.length) {
                                for (var i = 0; i < ghostDatasource.length; i++) {
                                    field.dataSource.unshift(ghostDatasource[i]);
                                    field.dataSourceLookup[ghostDatasource[i].key] = ghostDatasource[i];
                                }
                            }

                        }, 0);

                    }).catch(function (e) {
                        Message.dberror(e);
                    }).finally(function () {
                        $timeout(function () {
                            field.loading = false;
                            $timeout(function () {
                                field.focusSearch = false;
                            }, 300);
                        }, 1);
                    })
            }

                $scope.dataSourceSearch = function (event, f, searchTerm) {
                    var field = f;
                    event.stopPropagation();
                    if (event.keyCode != 13) {
                        if (field.timeout) $timeout.cancel(field.timeout);
                        field.timeout = $timeout(function () {
                            $scope.getDataSources(f, searchTerm);
                            $timeout.cancel(field.timeout);
                        }, 1000);
                    } else {
                        $scope.getDataSources(f, searchTerm);
                    }
                }

            // 
            Object.defineProperty($scope, 'isAnyFiltersSelected', {
                get: function () {
                    var applyDisabled = true;
                    for (var i = 0; i < $scope.filters.filters.length; i++) {
                        if ($scope.filters.filters[i].hasValue) applyDisabled = false;
                        break;

                    }
                    return applyDisabled;
                }
            });

            



        }

    }
});
