import { cipo } from 'cipo';

cipo.directive("modalactionbar", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "actions": "="

        },
        templateUrl: "/ng/views/directives/system/modalactionbar.html",
        controller: function ($scope) {
            //
        }

    }
});
