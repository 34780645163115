<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "assignments.setPrimaryUsers" | translate }}</p>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div fxLayout="column" class="p-t-md">
        <div fxLayout fxLayoutAlign="end center" fxLayoutGap="16px">
            <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="saving()">
                    <mat-icon iconPositionEnd>
                        {{ currentFilterType() == FILTER_TYPE.All ? "filter_alt" : "filter_alt_off" }}
                    </mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="search(searchControl.value, FILTER_TYPE.All)">
                        <mat-icon color="primary">done_all</mat-icon>
                        <span>{{ "assignments.all" | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="search(searchControl.value, FILTER_TYPE.Assigned)">
                        <mat-icon color="primary">checklist</mat-icon>
                        <span>{{ "assignments.assigned" | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="search(searchControl.value, FILTER_TYPE.NotAssigned)">
                        <mat-icon class="material-symbols-outlined" color="primary">event_list</mat-icon>
                        <span>{{ "assignments.notAssigned" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
            <div align="end">
                <mat-form-field class="cipo-input hide-subscript p-0">
                    <mat-label>{{ "assignments.search" | translate }}</mat-label>
                    <input [readonly]="saving()" matInput [formControl]="searchControl" />
                    @if (searchControl.value) {
                        <button
                            matSuffix
                            [title]="'fileFolderPermissions.clear' | translate"
                            mat-icon-button
                            [disabled]="saving()"
                            (click)="clearInput()">
                            <mat-icon>close</mat-icon>
                        </button>
                    } @else {
                        <button
                            matSuffix
                            [title]="'assignments.search' | translate"
                            mat-icon-button
                            [disabled]="saving()">
                            <mat-icon>search</mat-icon>
                        </button>
                    }
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" class="m-t-lg m-b-md">
            @for (role of roles; track $index) {
                <div class="m-t-lg p-r-xl">
                    <div
                        fxLayout
                        fxLayoutAlign="space-between top"
                        [ngClass]="{ 'border-bottom--solid': $index < roles.length - 1 }">
                        <div fxFlex="40" class="m-t">{{ role.roleName }}</div>
                        <cipo-select fxFlex [control]="arrayControls[role.roleId]" />
                    </div>
                </div>
            }
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close mat-raised-button [disabled]="saving()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="savePrimaryUsers()" [disabled]="saving()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
