import { TableFieldModel } from '../../module/definition/table-field/common';

export const DemoTableFields: TableFieldModel[] = [
  {
    width: 1,
    position: 1,
    id: 1363,
    name: 'name',
    label: 'name',
    hasMultipleValues: true,
    type: 1,
    displayType: 1,
    restrictions: [
      {
        restrictionValue: 20,
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    entityIds: [],
    screenIds: [],
    inUse: false,
    parentChildLinkId: 408,
    canDelete: true,
    canRemove: true,
    fieldOrigin: 1,
    isFilter: false,
    useOnReport: false,
    canHaveAggregate: false,
    isContractSystemField: false,
    isSystemField: false,
    currentDateTime: false,
    maximumAllowedCharacters: 0,
    isRequired: true,
  },
  {
    width: 1,
    position: 2,
    id: 1364,
    name: 'age',
    label: 'age',
    hasMultipleValues: true,
    type: 2,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    expression: '',
    entityIds: [],
    screenIds: [],
    inUse: false,
    parentChildLinkId: 409,
    canDelete: true,
    canRemove: true,
    fieldOrigin: 1,
    isFilter: false,
    useOnReport: false,
    canHaveAggregate: true,
    isContractSystemField: false,
    isSystemField: false,
    currentDateTime: false,
    maximumAllowedCharacters: 0,
    isRequired: false,
  },
  {
    width: 1,
    position: 3,
    aggregate: 'MAX',
    id: 1382,
    name: 'formula age',
    label: 'formula age',
    hasMultipleValues: false,
    type: 2,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    expression: '',
    entityIds: [],
    screenIds: [],
    inUse: false,
    parentChildLinkId: 417,
    canDelete: true,
    canRemove: true,
    fieldOrigin: 1,
    isFilter: false,
    useOnReport: false,
    aggregateId: 4,
    canHaveAggregate: true,
    isContractSystemField: false,
    isSystemField: false,
    currentDateTime: false,
    maximumAllowedCharacters: 0,
    isRequired: false,
  },
];
