import { cipo } from 'cipo';

cipo.directive("itvSelect", function ($timeout) {
    return {
        restrict: "E",
        scope: {
            "value": "=", /* the select value, can be single or multiple value (array) */
            "options": "<", /* the select options */
            "label": "<?", /* the select label */
            "multiple": "<?", /* true if multiple options can be selected */
            "placeholder": "<?", /* the select placeholder */
            "placeholderSearch": "<?", /* the selected search placeholder */
            "info": "<?", /* information text to be shown below the select */
            "focus": "=?", /* true to trigger the select focus */
            "focusSearch": "=?", /* true to trigger the select search focus */
            "isDisabled": "<?", /* true if disabled */
            "isRequired": "<?", /* true if required */
            "isValid": "<?", /* true if the value is valid, used for validation */
            "isLoading": "=?", /* true if loading */
            "isLoaded": "<?", /* true if loaded */
            "isSearchEnabled": "<?", /* true if search should be enabled */
            "isSystem": "<?", /* true if system component */
            "serverSearch": "<?", /* true if search is handled by the server */
            "showLoadMore": "<?", /* true to show load more, by default is handled by the component but can be overriden by the server */
            "warningMessage": "<?", /* a warning message to show below the select */
            "neutralMessage": "<?", /* a neutral message to show below the select */
            "errorMessage": "<?", /* an error message to show below the select */
            "propKey": "<?", /* the key property, default is key */
            "propValue": "<?", /* the value property, default is value */
            "propIsUsed": "<?", /* the isUsed property, default is isUsed */
            "markIsUsed": "<?", /* true to mark the option as used, the isUsed property can be true on option but won't show unless this is set to true */
            "propIsDisabled": "<?", /* the isDisabled property, default is isDisabled */
            "markIsDisabled": "<?", /* true to mark the option as disabled, the isDisabled property can be true on option but won't disable unless this is set to true */
            "labelCloseSelect": "<?", /* the label for the Close button, or default text is used */
            "labelLoadMore": "<?", /* the load more label, or default text is used */
            "labelNoResults": "<?", /* the no results label, or default text is used */
            "labelNoOptions": "<?", /* the no options label, or default text is used */
            "addNoOption": "<?", /* true to add an empty option, usually when you need a nullable select  */
            "labelNoOption": "<?", /* the label for no option, or default text is used */
            "labelNoValue": "<?", /* the label for no value, or default text is used */
            "labelInUse": "<?", /* the label for in use, or default text is used */
            "onOpen": "&", /* event triggered when the select component is opened */
            "onClose": "&", /* event triggered when the select component is closed, the old and new value will be available as variables */
            "onChange": "&", /* event triggered when the value is changed, the old and new value will be available as variables */
            "onSelectKeypress": "&", /* event triggered when key is pressed on select component, the event and searchTerm will be available as variables */
            "onSelectKeyup": "&", /* event triggered when key is up on select component, the event and searchTerm will be available as variables */
            "onSearch": "&", /* event triggered when when key is up while searching, selected page and searchTerm will be available as variables */
            "onPage": "&", /* event triggered when Load More options is pressed, selected page and searchTerm will be available as variables */
        },
        templateUrl: "/ng/views/directives/itvSelect.html",
        controller: function ($scope, MAX_NR_VISIBLE_SELECT_ITEMS) {

            var searchTimeout;
            var searchDebounceMs = 500;

            $scope.maxNrVisibleSelectItems = MAX_NR_VISIBLE_SELECT_ITEMS;
            $scope.loadMoreValue = '!LOAD!MORE!';

            $scope.oldValue = undefined;
            $scope.searchTerm = undefined;
            $scope.page = 1;
            $scope.visibleOptions = $scope.options || [];

            $scope.placeholderSearch = $scope.placeholderSearch || 'Search..';
            $scope.labelCloseSelect = $scope.labelCloseSelect || 'Close Select';
            $scope.labelLoadMore = $scope.labelLoadMore || 'Load More..';
            $scope.labelNoOption = $scope.labelNoOption || '- Please select -';
            $scope.labelNoResults = $scope.labelNoResults || 'No results';
            $scope.labelNoOptions = $scope.labelNoOptions || 'No options available';
            $scope.labelNoValue = $scope.labelNoValue || '[No value]';
            $scope.labelInUse = $scope.labelInUse || 'In use';
            $scope.propKey = $scope.propKey || 'key';
            $scope.propValue = $scope.propValue || 'value';
            $scope.propIsUsed = $scope.propIsUsed || 'isUsed';
            $scope.propIsDisabled = $scope.propIsDisabled || 'isDisabled';

            if ($scope.focusSearch === undefined) {
                $scope.focusSearch = true;
            }
            if ($scope.isSearchEnabled === undefined) {
                $scope.isSearchEnabled = true;
            }
            if ($scope.showLoadMore === undefined) {
                $scope.showLoadMore = true;
            }

            $scope.paginateOptions = function () {
                var options = [], optionsSelected = [], values = [];
                $scope.options = $scope.options || [];

                if ($scope.value && $scope.multiple) {
                    values = [...$scope.value];
                }
                else if ($scope.value) {
                    values = [$scope.value];
                }

                for (var i = 0; i < $scope.options.length; i++)
                {
                    var option = $scope.options[i];
                    var searchActive = false;

                    if ($scope.serverSearch) {
                        searchActive = true;
                        options.push(option);
                    }
                    else if ($scope.searchTerm !== undefined && $scope.searchTerm !== null) {
                        searchActive = true;
                        var found = option[$scope.propValue].toLowerCase().indexOf($scope.searchTerm.toLowerCase()) !== -1;
                        if (found) {
                            options.push(option);
                        }
                    }
                    else {
                        options.push(option);
                    }
                    
                    if (!searchActive) {
                        var isSelected = values.some(v => v == option[$scope.propKey]);
                        if (isSelected) {
                            optionsSelected.push(option);
                        }
                    }
                }

                var countOptionsBeforePagination = options.length;
                if (!$scope.serverSearch) {
                    // take only visible items
                    options = options.slice(0, $scope.maxNrVisibleSelectItems);
                }

                // add options that should be selected but are not in the first maxNrVisibleSelectItems
                // in this case the option will be added at the end of the list
                var optionsSelectedNotVisible = optionsSelected.filter(s => !options.some(o => o[$scope.propKey] == s[$scope.propKey]));
                if (optionsSelectedNotVisible.length) {
                    options = [...options, ...optionsSelectedNotVisible];
                }

                var sameContent = options.length == $scope.visibleOptions.length
                    && options.every(o => $scope.visibleOptions.includes(o));
                if (!sameContent) {
                    $scope.visibleOptions = options;
                }

                $scope.showLoadMoreOptions = $scope.showLoadMore && ($scope.serverSearch || countOptionsBeforePagination > $scope.maxNrVisibleSelectItems);
            }

            $scope.setPage = function (page) {
                $scope.page = page;
                $scope.maxNrVisibleSelectItems = page * MAX_NR_VISIBLE_SELECT_ITEMS;
                $scope.paginateOptions();
                if ($scope.onPage) {
                    $scope.onPage({ 'page': $scope.page, 'searchTerm': $scope.searchTerm });
                }
            }

            $scope.loadMore = function ($event) {
                $event.stopPropagation();
                $event.preventDefault();
                $scope.setPage($scope.page + 1);
            }

            $scope.selectChanged = function () {
                if ($scope.multiple) {
                    $scope.value = $scope.value.filter(v => v != $scope.loadMoreValue);
                }
                else if ($scope.value == $scope.loadMoreValue) {
                    $scope.value = undefined;
                }
                if ($scope.onChange) {
                    $scope.onChange({ 'oldValue': $scope.oldValue, 'value': $scope.value });
                }
                if (!$scope.multiple) {
                    $scope.searchTerm = undefined;
                }
            }

            $scope.selectOpen = function () {
                $scope.oldValue = angular.copy($scope.value);
                $scope.paginateOptions();
                if ($scope.onOpen) {
                    $scope.onOpen();
                }
            }

            $scope.selectKeyPress = function ($event) {
                if ($scope.onSelectKeypress) {
                    $scope.onSelectKeypress({ '$event': $event, 'searchTerm': $event.currentTarget.value });
                }
            }

            $scope.selectKeyUp = function ($event) {
                if ($scope.onSelectKeyup) {
                    $scope.onSelectKeyup({ '$event': $event, 'searchTerm': $event.currentTarget.value });
                }
            }

            $scope.search = function ($event) {
                if (searchTimeout) {
                    $timeout.cancel(searchTimeout);
                }
                searchTimeout = $timeout(function () {
                    if ($scope.onSearch) {
                        $scope.onSearch({ '$event': $event, 'searchTerm': $event.currentTarget.value });
                    }
                    $scope.setPage(1);
                    $timeout.cancel(searchTimeout);
                }, searchDebounceMs);
            }

            $scope.close = function () {
                if ($scope.onClose) {
                    $scope.onClose({ 'oldValue': $scope.oldValue, 'value': $scope.value });
                }
                $scope.searchTerm = undefined;
                $scope.paginateOptions();
                $('md-backdrop').trigger('click');
            }

            $scope.hasValue = function () {
                return $scope.multiple && $scope.value && Array.isArray($scope.value) && $scope.value.length > 0 
                       || ($scope.value !== null && $scope.value !== undefined);
            }

            $scope.$watch(
                function () {
                    return $scope.options
                },
                function () {
                    $scope.paginateOptions();
                }
            );

            $scope.$watch(
                function () {
                    return $scope.isLoading
                },
                function (isLoading) {
                    if (isLoading) {
                        $('.loaderOverlayWrapperGlobal').show();
                    }
                    else {
                        $('.loaderOverlayWrapperGlobal').hide();
                    }
                }
            );
        }
    };
});