import { cipo } from 'cipo';

cipo.controller('manageReportsController',
    function ($scope, module, Report, MODAL, URI, $mdDialog, Message, Model, $q, userService) {
        $scope.modalInfo = MODAL;
        $scope.module = module;

        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor(module.code) }
        });

        Object.defineProperty($scope, "disabledBtn",  {
            get: function () {
                var ret = false;
                ret = ($scope.newReport ? true : false) || $scope.loadingFields || $scope.loadingData;
                //if ($scope.newReport) ret = true;
                //if ($scope.loadingFields) ret = true;
                //if ($scope.loadingData) ret = true;
                if ($scope.list && $scope.list.length) {
                    for (var i = 0; i < $scope.list.length; i++) {
                        if ($scope.list[i].isBusy || $scope.list[i].isDeleting) {
                            ret = true;
                            break;
                        }
                    }
                }
                return ret;
            }
        })
        
        $scope.closeModal = function () {
            $mdDialog.cancel();
            //$scope.file.isModified
            //    ? $mdDialog.hide()
            //    : $mdDialog.cancel();
        };
        $scope.loadingData = true;

        var getReportsList = function () {
            var reportsUrl = URI.MODULE_REPORTS.SEARCH;
            $scope.loadingData = true;
            Model[reportsUrl.method](reportsUrl, { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj'}, {
                headers: {
                    moduleId: module.moduleId
                }
            })
                .then(function (r) {

                    for (var j = 0; j < r.data.length; j++) {
                        var widthBits = 0;
                        for (var i = 0; i < r.data[j].fields.length; i++) {
                            r.data[j].fields[i].width = r.data[j].fields[i].width || 1;
                            widthBits += parseInt(r.data[j].fields[i].width);
                        }
                        for (var i = 0; i < r.data[j].fields.length; i++) {
                            r.data[j].fields[i].widthPercentage = 100 / widthBits * r.data[j].fields[i].width;
                        }

                        r.data[j].report = new Report(angular.copy(r.data[j]), allFields);

                        r.data[j].report.tableWidth = widthBits * 140;

                    }



                    $scope.list = r.data;
                })
                .catch(function (e) { Message.dberror(e); })
                .finally(function () { $scope.loadingData = false; })
        }

        
        

        var isFieldsLoaded = false;
        var allFields;
        var getFields = function () {
            var fieldsUrl = URI.MODULE_REPORTS.FIELDS;
            var p = $q.defer();
            Model[fieldsUrl.method](fieldsUrl, { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj'}, {
                headers: {
                    moduleId: module.moduleId

                }
            })
                .then(function (r) {
                    isFieldsLoaded = true;
                    p.resolve();
                    if ((r.data || []).length) {
                        getReportsList();
                        allFields = r.data;
                        
                    } else $scope.noAvailableFields = true;
                    
                })
                .catch(function (e) { Message.dberror(e); p.reject(); });

            return p.promise;
        }
        getFields();

        $scope.addReport = function () {
            $scope.isAddReport = true;
            $scope.newReport = new Report({ moduleId: module.moduleId }, allFields);

        }

        $scope.editReport = function (item) {
            item.isBusy = true;
            
            
        }
        $scope.sortableOptions = {
            handle: '> span>.listHandle',
            'ui-floating': true
        }

        $scope.closeNewReport = function () {
            if ($scope.newReport.isModified) getReportsList();
            delete $scope.newReport;
            $scope.isAddReport = false;
        }

        $scope.closeReport = function (item) {
            if (item.report.isModified) getReportsList();
            else { // reset report
                item.report.reinit(item.fields);
                if (item.report.isModifiedFilters) 
                    item.report.get_Data()
                        .then(function () {
                            item.hasFilters = item.report.properties.hasFilters;
                        })
            }
            item.isBusy = false;
            // item.isExpandFilters = false;
        }

        $scope.deleteReport = function (item) {
            var urlData = URI.MODULE_REPORTS.DELETE;
            var item = item || scope.newReport;
            item.isDelete = true;
            item.deletingMsg = "Deleting report...";

            Model[urlData.method](urlData, { url: { id: (item || {}).id || $scope.newReport.properties.id, contractId: userService.system.userdata.contractId }, urltype: "obj" }, { headers: { moduleId: module.moduleId } })
                .then(function (r) {
                    Message.info("Report deleted successfully.");
                    if ($scope.newReport) {
                        delete $scope.newReport;
                        $scope.isAddReport = false;
                    } else {
                        getReportsList();
                    }
                })
                .catch(function (e) {
                    Message.dberror(e);
                    item.deletingMsg = "Failed to delete report. Try again?";
                })
                .finally(function (e) { self.isBusy = false; })
        }
        //$scope.openMenu = function ($mdMenu, ev) {
        //    $mdMenu.open(ev);
        //};

    });
