import { cipo } from 'cipo';

cipo.factory("Login", function ($q, Form, authService, userService) {
    var Login = function (obj) {
        userService.clearData();

        this.init(obj);
        var isReadOnly = obj ? false : true;
        this.form = new Form(this.properties);
        this.form.set_Description({
            username: { label: 'Email Address', type: 'text', hideRequired: true, editMode: isReadOnly, validation: { required: true } },
            password: { label: 'Password', type: 'password', hideRequired: true, validation: { required: true } },
            //rememberMe: { label: 'Remember this device', type: 'checkbox' }
        });
    };

    Login.prototype.init = function (obj) {
        this.properties = {
            username: obj ? obj.email : null,
            password: null,
            //rememberMe: false
        };
    };

    Login.prototype.reset_Password = function () {
        var self = this;
        var p = $q.defer();
        self.form.clearErrors();
        if (!self.properties.username) {
            self.form.setFieldInvalid('username', 'This field is required');
            p.reject();
        } else {
            self.isReset = 1;
            self.form.loading = true;
            userService.reset_Password(self.properties.username)
                .then(function (r) {
                    p.resolve(r);
                    self.isReset = 2;
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject();
                    self.isReset = 3;
                })
                .finally(function () {
                    self.form.loading = false;
                });
        }

        return p.promise;
    };

    Login.prototype.get_Token = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            //console.error('valid form')
            authService.login(self.properties)
                .then(function () {
                    p.resolve();
                })
                .catch(function (e) {
                    console.error('authService reject', e);
                    p.reject(true);
                })
                .finally(function () {
                    self.form.loading = false;
                });
        } else {
            self.form.loading = false;
            p.reject(false);
        }

        return p.promise;
    };

    return Login;
});
