<div class="grid-container">
    <div
        *ngFor="let control of form().controls | keyvalue"
        [ngStyle]="{
            'grid-column-start': control.value.fieldData.position.x + 1,
            'grid-column-end': 'span ' + control.value.fieldData.position.cols,
            'grid-row-start': control.value.fieldData.position.y + 1,
            'grid-row-end': 'span ' + control.value.fieldData.position.rows
        }">
        @switch (control.value.fieldData.type) {
            @case (FIELD_TYPES.RichText) {
                <app-froala [content]="$any(control.value.value)" [editmode]="editMode()" />
            }
            @case (FIELD_TYPES.Annotation) {
                <app-froala [content]="$any(control.value.value)" [editmode]="false" />
            }
            @case (FIELD_TYPES.Table) {
                <cipo-table-field
                    [control]="control.value"
                    [tableForm]="control.value.fieldData?.tableForm"
                    [showposition]="control.value.fieldData?.showPosition"
                    [tableFields]="control.value.fieldData?.tableFields" />
            }
            @default {
                <cipo-control [control]="control.value" />
            }
        }
    </div>
</div>
