import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { KeyValueType } from 'src/app/models/common';
import { GenericObjectType } from 'src/app/models/helpers';
import { Currency } from '../../models/system';
import { CipoFieldConfig } from '../components/fields/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  readonly localeId = inject(LOCALE_ID);
  readonly localeThousandSeparator = getLocaleNumberSymbol(this.localeId, NumberSymbol.Group);
  readonly localeDecimalSeparator = getLocaleNumberSymbol(this.localeId, NumberSymbol.Decimal);
  private currency: Currency = {
    symbol: '$',
    currencyId: 1,
    leftSide: true,
  };

  /**
   * Returns the inverted hex color (used for dark/light mode)
   * Accepts a string of 6 elements, or 7 elements if starting with 'x'
   *
   * @param {string} hexColor
   * @returns {string} If hexColor starts with 'x', returns new hex that also starts with 'x'
   */
  invertHex(hexColor: string): string {
    if (hexColor.length === 6) {
      return (Number(`0x1${hexColor}`) ^ 0xffffff).toString(16).toUpperCase();
    } else if (hexColor.startsWith('#') && hexColor.length === 7) {
      return `#${(Number(`0x1${hexColor.substring(1)}`) ^ 0xffffff).toString(16).toUpperCase()}`;
    } else {
      throw new Error('Invalid hex color');
    }
  }
  /**
   * Replace all spaces within a string with underscore
   *
   * @param {string} text string that might have spaces, ex: 'my string'
   * @returns {string} the updated string, ex: 'my_string'
   */
  formatText(text: string): string {
    return text.replace(/ /g, '_');
  }

  /**
   * Receives an object of keyValue pairs, and outputs an array of objects
   *
   * @param {{ [key: string]: string }} obj
   * @returns {{key: string, value: string}[]} KeyValueType[]
   */
  mapObjectToArray(obj: GenericObjectType): KeyValueType[] {
    return Object.keys(obj).map(key => ({ key: key, value: obj[key] }));
  }

  /**
   * Receives an array of objects, and outputs an object of keyValue pairs
   *
   * @param {{key: string, value: string}[]} array
   * @returns {{ [key: string]: string }} GenericObjectType
   */
  mapArrayToObject(array: KeyValueType[]): GenericObjectType {
    const obj: GenericObjectType = {};
    array.forEach(element => (obj[element.key] = element.value));
    return obj;
  }

  /**
   * Converts a number that was formatted with .toLocaleString() back to a number
   *
   * @param {string} value
   * @returns {number}
   */
  revertLocaleNumber(value: string): number {
    const baseValue = value.replaceAll(this.localeThousandSeparator, '').replace(this.localeDecimalSeparator, '.');
    return parseFloat(baseValue);
  }

  /**
   * Converts a number to a number.toLocaleString() with the current locale
   *
   * @param {number} value
   * @returns {string}
   */
  formatLocaleNumber(value: number): string {
    return value.toLocaleString(this.localeId);
  }

  formatCurrency({ prefix, suffix, ...fieldData }: CipoFieldConfig): CipoFieldConfig {
    const { symbol, leftSide, noSpace } = this.currency;
    const updatedData = {
      ...fieldData,
      prefix: leftSide ? `${symbol}${noSpace ? '' : ' '}` : prefix,
      suffix: !leftSide ? `${noSpace ? '' : ' '}${symbol}` : suffix,
    };
    return updatedData;
  }

  /**
   * Convert multiple types of true to boolean;
   * @param value 
   * @returns {boolean}
   */
  convertValueToBoolean(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    
    if (typeof value === 'string') {
      value = value.toLocaleLowerCase();
    }

    switch (value) {
      case true:
      case 'true':
      case 1:
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }
}
