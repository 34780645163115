<div class="months-tiles"
     data-testid="month-tiles">
    <ng-container *ngFor="let tile of tiles; index as i">
        <div #tileRef
             class="month-tile"
             data-testid="month-tile">
            <div class="month-tile-title">
                <button mat-button
                        class="month-tile-button"
                        data-testid="month-tile-name"
                        *ngIf="!tile.isToday"
                        (click)="monthClick(tile.date)">
                    {{tile.text}}
                </button>
                <button mat-raised-button
                        class="month-tile-button"
                        data-testid="month-tile-name-today"
                        color="primary"
                        *ngIf="tile.isToday"
                        (click)="monthClick(tile.date)">
                    {{tile.text}}
                </button>
            </div>
            <ng-container *ngIf="isLoading">
                <div class="month-tile-loading"
                     data-testid="month-tile-loading">
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '12px', height: '12px', margin: 0}"></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '30px', height: '12px', margin: 0}"></ngx-skeleton-loader>
                    </div>
                    <div class="desc">
                        <ngx-skeleton-loader [theme]="{height: '12px', margin: 0}"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <app-calendar-doc-mini *ngFor="let doc of showTileDocs(tile.docs)"
                                       [doc]="doc"
                                       [screenFields]="screenFields"
                                       [actions]="actions">
                </app-calendar-doc-mini>
                <div class="month-tile-more"
                     data-testid="month-tile-doc-more"
                     *ngIf="getMoreTileDocsNumber(tile.docs) as n">
                    <button mat-button
                            class="more month-tile-button"
                            data-testid="month-tile-doc-more-button"
                            #btMore
                            (click)="more(tile, btMore)">
                        {{ 'MODULE_INSTANCE_CALENDAR.N_MORE' | translate: {n:n} }}
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>