import { cipo } from 'cipo';

cipo.factory('ListElements', function ($q, Model, URI) {
    var ListElements = {
        currentListElements: [],
        currentListDetails: {}
    };



    ListElements.getParentElements = function (listId) {
        var self = ListElements;
        var ret = {};
        var p = $q.defer();
        var urlData = URI.LISTS.GET_ELEMENTS;
        Model.request(urlData.method, urlData,
            { url: {}, body: { id: listId }, urltype: 'obj' })
            .then(function (r) {
                ret.elements = r ||[];
                ret.lookup = self.setLookup(r);
                p.resolve(ret);
            }).catch(function (e) {
                p.reject(e);
            })



        return p.promise;
    }

    ListElements.setLookup = function (list, key) {
        var elementsLookup = {};
        var key = key || 'id';
        for (var i = 0; i < list.length; i++) {
            elementsLookup[list[i][key]] = list[i];
        }
        return elementsLookup;
    }

    

    return ListElements;
});
