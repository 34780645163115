export enum FieldTypeEnum {
  None = 0,
  Text = 1,
  Number = 2,
  YesNo = 3,
  Date = 4,
  Attachment = 5,
  User = 6,
  Table = 7,
  SubModule = 8,
  Formula = 9,
  Relation = 10,
  Weather = 11,
  Graphic = 12,
  Annotation = 50,
}
