<div fxLayout="column" class="p-t-md">
    <div fxLayout fxLayoutAlign="space-between none">
        <div align="start">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon iconPositionEnd>
                    {{ currentFilterType() == FILTER_TYPE.All ? "filter_alt" : "filter_alt_off" }}
                </mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changeFilter(FILTER_TYPE.All)">
                    <mat-icon color="primary">done_all</mat-icon>
                    <span>{{ "fileFolderPermissions.viewAll" | translate }}</span>
                </button>
                <button mat-menu-item (click)="changeFilter(FILTER_TYPE.Allow)">
                    <mat-icon color="primary">checklist</mat-icon>
                    <span>{{ "fileFolderPermissions.viewAllow" | translate }}</span>
                </button>
                <button mat-menu-item (click)="changeFilter(FILTER_TYPE.Deny)">
                    <mat-icon color="warn">checklist_rtl</mat-icon>
                    <span>{{ "fileFolderPermissions.viewDeny" | translate }}</span>
                </button>
            </mat-menu>
        </div>
        <div align="end">
            <button mat-icon-button class="m-r-sm" (click)="openSettingModal()">
                <mat-icon>group_add</mat-icon>
            </button>
            <button mat-icon-button (click)="refreshPeople()" class="m-r-sm">
                <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field class="cipo-input hide-subscript p-0">
                <mat-label>{{ "fileFolderPermissions.search" | translate }}</mat-label>
                <input matInput [formControl]="searchControl" />
                @if (searchControl.value) {
                    <button
                        matSuffix
                        [title]="'fileFolderPermissions.clear' | translate"
                        mat-icon-button
                        (click)="clearInput()">
                        <mat-icon>close</mat-icon>
                    </button>
                } @else {
                    <button matSuffix [title]="'fileFolderPermissions.search' | translate" mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                }
            </mat-form-field>
        </div>
    </div>
    <mat-list fxFlex>
        @if (!peopleList?.length) {
            <mat-list-item>{{ "fileFolderPermissions.withoutPeople" | translate }}</mat-list-item>
        }
        @for (item of peopleList; track $index) {
            @if (loading()) {
                <mat-list-item class="border-all small-height">
                    <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px', top: '8px' }"></ngx-skeleton-loader>
                </mat-list-item>
            } @else {
                <mat-list-item
                    class="cursor--pointer border-all small-height"
                    [ngClass]="item.id === selectedPeopleId() ? 'selected' : item.isNew ? 'new' : ''"
                    fxLayout
                    (click)="selectPeople(item)">
                    <mat-icon matListItemIcon>{{ item.isUser ? "person" : "groups" }}</mat-icon>
                    <div matListItemTitle fxLayout fxLayoutAlign="space-between center">
                        <div align="start">{{ item.name }}</div>
                        <div fxLayout fxLayoutAlign="end center" fxLayoutGap="16px">
                            <span>{{ getPeoplePermissionCode(item) }}</span>
                            <button mat-icon-button (click)="deletePermissions(item, $event)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
            }
        }
    </mat-list>
    @if (peopleList?.length) {
        <div fxFlex fxLayoutGap="4px" fxLayout="start center" class="small-info-text m-b-md">
            <span class="small-info-icon">info</span>
            <span>{{ "fileFolderPermissions.peopleInfo" | translate }}</span>
        </div>
    }
    <mat-list fxFlex="20">
        <mat-list-item class="border-all">
            <div fxLayout fxLayoutAlign="start center" class="small-height">
                <div fxFlex="60">{{ "fileFolderPermissions.permission" | translate }}</div>
                <div fxFlex="20" fxLayoutAlign="center center">
                    <span>{{ "fileFolderPermissions.allow" | translate }}</span>
                </div>
                <div fxFlex="20" fxLayoutAlign="center center">
                    <span>{{ "fileFolderPermissions.deny" | translate }}</span>
                </div>
            </div>
        </mat-list-item>
        @for (permission of permissionsList; track $index) {
            @if (loading()) {
                <mat-list-item class="border-all small-height">
                    <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px', top: '8px' }"></ngx-skeleton-loader>
                </mat-list-item>
            } @else {
                <mat-list-item class="border-all">
                    <div fxLayout fxLayoutAlign="start center" class="small-height">
                        <div fxFlex="60">{{ permission.permission }}</div>
                        <mat-checkbox
                            fxFlex="20"
                            [disabled]="notContractModule"
                            fxLayoutAlign="center center"
                            [checked]="permission.allow"
                            (change)="permissionValueChanged(permission, true, $event)"></mat-checkbox>
                        <mat-checkbox
                            fxFlex="20"
                            fxLayoutAlign="center center"
                            [checked]="permission.deny"
                            (change)="permissionValueChanged(permission, false, $event)"></mat-checkbox>
                    </div>
                </mat-list-item>
            }
        }
    </mat-list>
</div>
