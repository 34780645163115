import { cipo } from 'cipo';

cipo.directive("userrolecontractgrid", function (AssignURC) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "host": "="
        },
        templateUrl: "/ng/views/directives/userRoleContract.html",
        controller: function ($scope) {
            $scope.toggleNewAssignment = function () {
                if (!$scope.newAssignment) {
                    $scope.newAssignment = new AssignURC($scope.host.properties.id, $scope.host.type);
                    $scope.host.disabledBtn = true;
                } else {
                    $scope.newAssignment = null;
                    $scope.host.disabledBtn = false;
                }

                
            }

            $scope.toggleEdit = function (a) {
                if (!a.editable) {
                    $scope.host.disabledBtn = true;
                    a.editable = new AssignURC($scope.host.properties.id, $scope.host.type, angular.copy(a));
                } else {
                    delete a.editable;
                    $scope.host.disabledBtn = false;
                }
            }
            $scope.assign = function (a) {
                var a = a ? a.editable : $scope.newAssignment;
                $scope.host.disabledBtn = true;
                if (!a.pivot.key) {
                    a.pivot.isError = true;
                    return;
                }
                a.sync()
                    .then(function (r) {
                        $scope.newAssignment = null;
                        $scope.host.set_assignments();
                    })
                    .catch(function (e) { })   
                    .finally(function () { $scope.host.disabledBtn = false; })   
                }
            $scope.unassignDialog = function (a) {
                a.deleteConfirm = true;
            }
            $scope.unassign = function (a) {
                a.isBusy = true;
                a.deletable = new AssignURC($scope.host.properties.id, $scope.host.type, angular.copy(a));
                a.deletable.remove()
                    .then(function () {
                        $scope.host.set_assignments();
                    })
                    .catch(function (e) {
                        console.error(e);
                    })
                    .finally(function () {
                        a.isBusy = false;
                    })
            }

        }

    }
});
