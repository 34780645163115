import { cipo } from 'cipo';

cipo.factory("ListMod", function ($q, Model, URI, Form, Message) {
    var ListMod = Model.extend(function (moduleId, obj) {

        var self = this;
        self.moduleId = moduleId || null;
        self.properties = {
            key: obj ? obj.key : 0,
            value: obj ? obj.value : "",
            children: obj ? obj.children : [],
            inUse: obj && obj.inUse ? obj.inUse : false,
            type: obj && obj.type ? obj.type : 0
        }
        

    });

    ListMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        if(self.properties.key) self.form.loading = true;
        //create form
        self.form.set_Description({
            value: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
            children: { label: 'Options', type: 'chips', validation: { required: true } },
            inUse: { label: 'Use on current field', type: 'checkbox' }
        });

        self.form.setTemplate('grid', [
            { value: 100 },
            { children: 100 },
            { inUse: 100 }
        ]);

        self.form.store_Data();
        self.form.initializing = false;
    }

    ListMod.prototype.get_Details = function () {
        var self = this;
        var p = $q.defer();
        self.createForm();
        self.loadingDetails = true;
        var urlData = URI.MODULE_FIELDS.DATASOURCE_GET;
        self[urlData.method](urlData, { url: { id: self.properties.key }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                for (var key in result) {
                    if (result.hasOwnProperty(key)) {
                        self.properties[key] = result[key];
                    }
                }

                self.form.set_Data(self.properties);
                self.form.store_Data();
                p.resolve(result);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingDetails = false;
                self.form.loading = false;
            })


        return p.promise;
    }

    ListMod.prototype.sync = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        var urlData = URI.MODULE_FIELDS.DATASOURCE_SYNC;
        self.form.validate();
        if (self.form.isValid) {
            
            if (self.properties.children.length) {
                var names = [];
                var children = [];

                for (var i = 0; i < self.properties.children.length; i++) {
                    if (self.properties.children[i].key != 0) {
                        names.push(self.properties.children[i].value);
                        children.push(self.properties.children[i]);
                    }
                }


                for (var i = 0; i < self.properties.children.length; i++) {
                    if (self.properties.children[i].key == 0) {
                        if (names.indexOf(self.properties.children[i].value) == -1)
                            children.push(self.properties.children[i]);
                    }
                }

                self.properties.children = children;
            }
            self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.moduleId } })
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject(e);
                })
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        


        return p.promise;
    }

    ListMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.MODULE_FIELDS.DATASOURCE_DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.key }, urltype:'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info("List deleted successfully");
                p.resolve(result);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;

    }

    return ListMod;
});
