import { cipo } from 'cipo';

    cipo.factory("ActionMod", function (Model, Form, ActionTypes, ModuleDictionaries, Dictionaries, $q, URI, Message) {
        var ActionMod = Model.extend(function (obj, openform) {
            var self = this;

            // Initialize properties
            self.properties = {
                type: null,
                preferredFieldIds: null, //  to become []
                id: null,
                name: "",
                description: "",
                notificationTemplateId: null,
                screenId: null,
                roleIds: [],
                roleToExcludeIds: [],
                notifyAssigneeOnNotifications: true
            };

        if ((obj.preferredFieldIds || []).length) obj.preferredFieldIds = obj.preferredFieldIds[0];
        else obj.preferredFieldIds = null;

            // Set properties
            if (obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        self.properties[key] = obj[key];
                    }
                }
            } 

            if (!self.properties.id || openform) self.createForm();

            self.urls = {
                create: function () {
                    return self.properties.type == ActionTypes['Comment'] 
                                    ? URI.ACTIONS.ADD_COMMENT 
                                    : self.properties.type == ActionTypes['PreferredFieldValue'] 
                                                ? URI.ACTIONS.ADD_PREFERRED_FIELD 
                                                : self.properties.type == ActionTypes['Email'] 
                                                         ? URI.ACTIONS.ADD_EMAIL
                                                         : URI.ACTIONS.ADD_ASSIGNMENT
                },
                edit: function () {
                    return self.properties.type == ActionTypes['Comment'] 
                                    ? URI.ACTIONS.EDIT_COMMENT 
                                    : self.properties.type == ActionTypes['PreferredFieldValue'] 
                                                ? URI.ACTIONS.EDIT_PREFERRED_FIELD 
                                                : self.properties.type == ActionTypes['Email'] 
                                                        ? URI.ACTIONS.EDIT_EMAIL
                                                        : URI.ACTIONS.EDIT_ASSIGNMENT
                },
                delete: function () { return URI.ACTIONS.DELETE }
            };
        });

    ActionMod.prototype.getRoles = function () {
        return ModuleDictionaries.dataSources.allRoles.data;
    }

    ActionMod.prototype.getUsedRoles = function () {
        return (this.getRoles() || []).filter(r => r.isUsed);
    }

    ActionMod.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        var setFormInfo = function (availableFields) {

                var formInfo = {
                    formFields: {},
                    formPattern : null
                }
                
                if (self.properties.type == ActionTypes['Comment']) {
                    formInfo.formFields = {
                        name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                        description: { label: 'Description', type: 'textarea' },
                        roleIds: {
                            label: 'Roles', type: 'select', multiple: true,
                            options: self.getUsedRoles(), validation: { required: true }
                        },
                        screenId: { label: 'Form', type: 'select', options: ModuleDictionaries.dataSources.forms.data, validation: { required: true } },
                        notificationTemplateId: { label: 'Notification Template', type: 'select', options: ModuleDictionaries.dataSources.notifications.data, validation: { required: true } },
                        roleToExcludeIds: {
                            label: 'Roles to exclude', type: 'select', multiple: true,
                            options: self.getUsedRoles()
                        }
                    }
                }
                else if (self.properties.type == ActionTypes['PreferredFieldValue']) {
                    formInfo.formFields = {
                        name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                        description: { label: 'Description', type: 'textarea', validation: { required: true, maxChars: 255 } },
                        roleIds: {
                            label: 'Roles', type: 'select', multiple: true,
                            options: self.getUsedRoles(), validation: { required: true }
                        },
                        preferredFieldIds: {
                            label: 'Field', type: 'select', multiple: false,
                            options: availableFields || ModuleDictionaries.dataSources.actionFields.data,
                            validation: { required: true }
                        }
                    }
                }
                else if (self.properties.type == ActionTypes['Assignment']) {
                    formInfo.formFields = {
                        name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                        description: { label: 'Description', type: 'textarea' },
                        notificationTemplateId: { label: 'Assignment Notification Template', type: 'select', options: ModuleDictionaries.dataSources.notifications.data, validation: { required: true } },
                        notifyAssigneeOnNotifications: { label: 'Add assignee to all workflow notifications', type: 'checkbox' }
                    }
                }
                else if (self.properties.type == ActionTypes['Email']) {
                    formInfo.formFields = {
                        name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                        description: { label: 'Description', type: 'textarea' },
                        screenId: { label: 'Form', type: 'select', options: ModuleDictionaries.dataSources.forms.data, validation: { required: true } },
                        notificationTemplateId: { label: 'Notification Template', type: 'select', options: ModuleDictionaries.dataSources.notifications.data, validation: { required: true } },
                        roleIds: {
                            label: 'Roles', type: 'select', multiple: true,
                            options: self.getUsedRoles(), validation: { required: true }
                        },
                        roleToExcludeIds: {
                            label: 'Roles to exclude', type: 'select', multiple: true,
                            options: self.getUsedRoles()
                        }
                    }
                }

                if (self.properties.type == ActionTypes['Comment']) {
                    formInfo.formPattern = [
                        { name: 33, description: 33, screenId: 33 },
                        { notificationTemplateId: 33, roleIds: 33, roleToExcludeIds: 33 }
                    ]
                }
                else if (self.properties.type == ActionTypes['PreferredFieldValue']) {
                    formInfo.formPattern = [
                        { name: 50, description: 50 },
                        { roleIds: 50, preferredFieldIds: 50 }
                    ]
                }
                else if (self.properties.type == ActionTypes['Assignment']) {
                    formInfo.formPattern = [
                        { name: 40, notificationTemplateId: 60 },
                        { description: 70, notifyAssigneeOnNotifications: 30 }
                    ]
                }
                else if (self.properties.type == ActionTypes['Email']) {
                    formInfo.formPattern = [
                        { name: 50, screenId: 50 },
                        { description: 50, notificationTemplateId: 50 },
                        { roleIds: 50, roleToExcludeIds: 50 }
                    ]
                }

            return formInfo;
        }
        
        var vasile = self.properties.type == ActionTypes['PreferredFieldValue'] && self.properties.id ?
            Dictionaries.AvailableFields({ id: self.properties.id, moduleTypeId: ModuleDictionaries.moduleInfo.currentType.key }) : function () { var p = $q.defer(); p.resolve(); return p.promise }();

            vasile.then(function (r) {
                var x = setFormInfo(r);
                self.form.set_Description(x.formFields);
                if (x.formPattern) self.form.setTemplate('grid', x.formPattern);
                self.form.initializing = false;
                self.form.store_Data();
            })
        }

    ActionMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var valid = true;
        if (self.form) {
            self.form.validate();
            valid = self.form.isValid;
        }
        if (valid) {
            if (self.form) self.form.loading = true;
            var dataURL = self.properties.id ? self.urls.edit() : self.urls.create();
            var toSend = angular.copy(self.properties);
            if (toSend.preferredFieldIds) toSend.preferredFieldIds = [toSend.preferredFieldIds];
            self[dataURL.method](dataURL, { url: { moduleTypeId: ModuleDictionaries.moduleInfo.currentType.key }, urltype: 'obj', body: toSend })
                .then(function (r) {

                    Message.info('Item saved successfully');
                    ModuleDictionaries.get_dataSource("actionFields");
                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    if (self.form) {
                        self.form.loading = false;
                        self.form.catch(e);
                    }
                    else Message.dberror(e);
                })
        } else {
            p.reject();
        }


        return p.promise;
    }

    ActionMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting item...";
        self.deleting = true;

        self[self.urls.delete(self).method](self.urls.delete(), { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (result) {
                Message.info('Item deleted successfully');
                if (self.properties.type == ActionTypes['PreferredFieldValue']) ModuleDictionaries.get_dataSource("actionFields");
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete item. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }

    return ActionMod;
});
