import { cipo } from 'cipo';

cipo.controller('shareItemsController',
    function ($scope, MODAL, URI, $http, $timeout, items, urlData, $mdDialog, Message, Dictionaries, Form) {

        $scope.disabledBtn = false;
        $scope.message = "Share";
        $scope.modalInfo = MODAL;
        var urlData = urlData || {};
        $scope.toShare = items;
        $scope.loadingUsers = true;
        $scope.usersList = [];
        Dictionaries.Users()
            .then(function (r) {
                
                $scope.usersList = r;
            })
            .catch(function (e) { console.error(e); })
            .finally(function () { $scope.loadingUsers = false;})
        $scope.toggleUser = function (user) {
            user.isSelected = !user.isSelected;
        }
        $scope.shareInfo = {
            comment: "",
            sendNotification: false,
            sendFile: false
        }
        var form1 = {
            sendNotification: { label: 'Send notification', type: 'checkbox' }
        }
        var form2 = {
            comment: { label: 'Notification Text (optional)', type: 'editor' },
            sendFile: { label: 'Attach file(s) to notification', type: 'checkbox' }
        }
        $scope.form1 = new Form($scope.shareInfo);
        $scope.form2 = new Form($scope.shareInfo);
        $scope.form1.initializing = true;
        $scope.form2.initializing = true;
        $scope.form1.set_Description(form1);
        $scope.form2.set_Description(form2);
        $scope.form1.initializing = false;
        $scope.form2.initializing = false;

        $scope.share = function () {
            $scope.disabledBtn = true;
            //$scope.toShare.usersId = $scope.selectedFolder ? $scope.selectedFolder.id : -1;
            for (var i = 0; i < $scope.usersList.length; i++) {
                if ($scope.usersList[i].isUsed)
                    $scope.toShare.usersId.push($scope.usersList[i].key);
            }
            var temp = angular.copy($scope.toShare);
            delete temp.files;
            if ($scope.shareInfo.sendNotification) {
                temp.sendNotification = $scope.shareInfo.sendNotification;
                temp.sendFile = $scope.shareInfo.sendFile;
                temp.comment = $scope.shareInfo.comment;
            }
            $http[urlData.method](urlData.toString(), temp)
                .then(function (r) {
                    $mdDialog.hide();
                    Message.info("Files shared successfully");
                })
                .catch(function (e) { console.error(e); Message.dberror(e); })
                .finally(function () { $scope.disabledBtn = false; });
        }
        $scope.closeModal = function () {
            /*if (isModified) $mdDialog.hide();
            else $mdDialog.cancel(); */
            $mdDialog.cancel();
        };


    });
