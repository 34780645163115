import { cipo } from 'cipo';

cipo.controller('user_signController',
    function ($scope, $transition$, $timeout, Message, URI) {
        var token = $transition$.params().s || '0-0-0-0';

        $scope.signatureOptions = {
            options: {}
        };

        $.ajax({
            "url": URI.MODULE_SIGNATURE_ANONYMOUS.GET + "?parameters=" + token,
            "method": "GET",
            "dataType": "json"
        }).done(function (r) {
            $timeout(function () {
                $scope.signatureOptions.options.sign = true;
                $scope.signatureOptions.options = {
                    sign: true,
                    signatureId: r.SignId,
                    signatureToken: token,
                    clientId: r.clientId,
                    url: r.signatureUrl,
                    redirect: false,
                    isProd: r.isProd,
                    setSigned: function (sid, st) {
                        $.ajax({
                            "url": URI.MODULE_SIGNATURE_ANONYMOUS.SET + "?parameters=" + st,
                            "method": "PUT",
                            "dataType": "json"
                        }).done(function (r) {
                            // console.error('in done');
                        }).fail(function (e) {
                            // console.error('in fail', e,  this);
                        }).always(function () {
                            $timeout(function () {
                                $scope.isSignedCompleted = true;
                            }, 0);
                            // console.error('in always');
                        });
                    },
                    callback: function () {
                        return;
                    }
                };
            }, 200);
        }).fail(function (e) {
            Message.dberror(e.responseJSON);
        });

        /*Model.get(URI.MODULE_SIGNATURE_ANONYMOUS.GET, { parameters: s })
            .then(function (result) {
                $scope.signatureOptions = {
                    sign: true,
                    signatureId: result.SignId,
                    clientId: result.clientId,
                    url: result.signatureUrl,
                    redirect: true,
                    isProd: result.isProd,
                    setSigned: function (sid) {
                        Model.put(URI.MODULE_SIGNATURE_ANONYMOUS.SET, { url: { id: sid }, urltype: 'obj' });
                    },
                    callback: function () {
                        return;
                    }
                };
            })
            .catch(function (e) {
                Message.dberror(e);
            });*/
    });
