import { cipo } from 'cipo';

cipo.controller('renameItemController',
    function ($scope, $http, item, dataURL, $mdDialog, MODAL, Name, userService, showIcons, otherParams) {
        $scope.isCreate = item.id ? false : true;
        $scope.item = new Name(item);
        // remove extension if file
        if (item.id && !item.isFolder && typeof item.typeId == 'undefined') {
            $scope.item.properties.name = $scope.item.properties.name.substring(0, $scope.item.properties.name.lastIndexOf("."))
            var extension = item.name.substring(item.name.lastIndexOf("."));
        }
        if (showIcons) {
            $scope.icons = userService.system.icons.list;
            if ($scope.item.properties.iconId) $scope.selectedIcon = $scope.item.properties.iconId;
            $scope.setIcon = function (iconId) {
                $scope.item.properties.iconId = iconId;
                $scope.selectedIcon = iconId;
                $scope.$apply();
            }
        }
        var otherParamsString = "";
        if (otherParams) {
            otherParamsString = "?";
            for (var key in otherParams) {
                if (otherParams.hasOwnProperty(key)) {
                    otherParamsString += key + "=" + otherParams[key] + '&';
                }
            }
            otherParamsString = otherParamsString.substring(0, otherParamsString.length - 1);
        }
            

        $scope.modalInfo = MODAL;
        $scope.disableBtns = false;

        $scope.save = function () {
            $scope.item.form.validate();
            $scope.item.form.loading = true;
            $scope.disableBtns = true;
            $scope.item.form.loading = true;
            if ($scope.item.form.isValid) {
                // put back extension if file
                if ($scope.item.properties.id && !$scope.item.properties.isFolder && typeof item.typeId == "undefined")
                    $scope.item.properties.name += extension;
                $http[dataURL.method](dataURL.toString() + otherParamsString, $scope.item.properties)
                    .then(function (result) {
                        if (!$scope.item.properties.id && $scope.item.properties.breadcrumb) {
                            $scope.item.properties.breadcrumb.push(result.data);
                            // $scope.item.properties.id = result.data;
                        }
                        if (!$scope.item.properties.id) $scope.item.properties.id = result.data;
                        $mdDialog.hide($scope.item.properties);
                    }).catch(function (e) {
                        console.error(e);
                        $scope.item.form.catch(e);
                        
                    })
                    .finally(function () {
                        $scope.disableBtns = false;
                        $scope.item.form.loading = false;
                    })
            }
            else {
                $scope.item.form.loading = false;
                $scope.disableBtns = false;
            }
        };
        
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

    });