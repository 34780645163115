import { cipo } from 'cipo';

cipo.controller('editDraftController',
    function ($scope, $q, Message, $mdDialog, conv, $window, $timeout, isCorrespondencePage, senderId) {
        $scope.senderId = senderId;
        $scope.isCorrespondencePage = isCorrespondencePage;
        $scope.soloConv = conv;
        $scope.isAnyRecipients = false;

        $scope.printMe = function (conversation, contentOnly) {
            var email = conversation.emails[0];

            if ($("#section-to-print").length > -1) {
                $("#section-to-print").remove();
            }

            var div = $('<div />').appendTo('body');
            div.attr('id', 'section-to-print');

            $scope.messageToPrint = contentOnly
                ? $('#print-content-only').html(email.properties.content)
                : email;

            $timeout(function () {
                if (contentOnly) {
                    $("#section-to-print").html($("#print-content-only").html());
                } else {
                    $("#section-to-print").html($("#print-message").html());
                }

                $window.print();
            }, 400);
        }

        $scope.send = function (conv, index) {
            var isValidContractId = !isNaN(parseInt(conv.emails[0].properties.contractId)) && conv.emails[0].properties.contractId != 0;
            if (!isValidContractId) {
                Message.error('Please select a contract.');
                return;
            }

            conv.disableBtns = true;
            conv.emails[0].form.validate();

            var p = $q.defer();
            if (conv.emails[0].form.isValid) {

                var isSenderRecipient =
                    conv.emails[0].properties.to.filter(function (recipient) { return recipient.id == $scope.senderId }).length > 0 ||
                    conv.emails[0].properties.cc.filter(function (recipient) { return recipient.id == $scope.senderId }).length > 0;

                var recipients = { to: conv.emails[0].properties.to, cc: conv.emails[0].properties.cc };

                if (conv.id) {
                    conv.emails[0].send_draft()
                        .then(function (result) {
                            if (result) {
                                conv.templateWatch();

                                $mdDialog.cancel({ msg: 'send', isSenderRecipient: isSenderRecipient, recipients: recipients });
                            } else {
                                Message.dberror("An error has occured. Your message couldn't be sent.");
                            }
                            conv.disableBtns = false;
                        })
                        .catch(function () {
                            Message.dberror("An error has occured. Your message couldn't be sent.");
                        });
                } else {
                    conv.emails[0].send_orphan()
                        .then(function (result) {
                            if (result) {
                                conv.templateWatch();
                                // conv.contractWatch();
                                $mdDialog.cancel({ msg: 'send', isSenderRecipient: isSenderRecipient, recipients: recipients });
                            } else {
                                Message.dberror("An error has occured. Your message couldn't be sent.");
                            }
                            conv.disableBtns = false;
                        })
                        .catch(function () {

                        });
                }
            }
            else {
                p.reject();
                conv.disableBtns = false;
            }
        }

        $scope.buildRecipientDisplayString = function (recipients, separatingChar = ', ') {
            var result = "";
            var names = [];

            if (Array.isArray(recipients) && recipients.length > 0) {

                for (var i = 0; i < recipients.length; i++) {
                    var recipient = recipients[i];
                    var value = recipient.firstName.length > 0
                        ? `${recipient.firstName} ${recipient.lastName}`
                        : recipient.email;

                    if (value.trim().length > 0) {
                        names.push(value);
                    }
                }

                result = names.join(separatingChar);
            }

            return result;
        }

        $scope.save = function (conv, index) {
            var isValidContractId = !isNaN(parseInt(conv.emails[0].properties.contractId)) && conv.emails[0].properties.contractId != 0;
            if (!isValidContractId) {
                Message.error('Please select a contract.');
                return;
            }

            conv.disableBtns = true;
            conv.saving = true;
            if (conv.id == 0 || !conv.id) {
                conv.emails[0].save_new_draft()
                    .then(function (result) {
                        if (result) {
                            conv.saving = false;
                            conv.emails[0].properties.id == 0
                                ? result
                                : conv.emails[0].properties.id;
                            // conv.emails[0].properties.threadId = result;
                        } else {
                            Message.dberror("An error has occured. Your message couldn't be saved.");
                        }
                        conv.disableBtns = false;
                    })
                    .catch(function (e) {
                    });
            } else {
                conv.emails[0].save_existing_draft()
                    .then(function (result) {
                        conv.saving = false;
                        if (result) {
                        } else {
                            Message.dberror("An error has occured. Your message couldn't be saved.");
                        }
                        conv.disableBtns = false;
                    })
                    .catch(function () {

                    });
            }
        };

        $scope.showAsDiv = function (conv) {
            $mdDialog.hide(conv);
        }

        $scope.cancel = function (conv, index) {
            conv.templateWatch();
            // conv.contractWatch();
            $mdDialog.cancel({ msg: 'cancel' });
        }

        $scope.close = function (conv, index, event) {
            var isValidContractId = !isNaN(parseInt(conv.emails[0].properties.contractId)) && conv.emails[0].properties.contractId != 0;
            if (!isValidContractId) {
                Message.error('Please select a contract.');
                return;
            }

            conv.disableBtns = true;
            conv.emails[0].save_existing_draft(true)
                .then(function (result) {
                    if (result) {
                        conv.templateWatch();
                        // conv.contractWatch();
                        $mdDialog.cancel({ msg: 'close' });
                    } else {
                        Message.dberror("Your message can't be saved.");
                    }

                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    conv.disableBtns = false;
                })

        }
        $scope.discard = function (conv, index) {
            conv.disableBtns = true;
            conv.emails[0].discard_draft()
                .then(function (result) {
                    conv.templateWatch();
                    // conv.contractWatch();
                    if (result) {
                        $mdDialog.cancel({ msg: 'discard' });
                    } else {
                        Message.dberror("An error has occured. Your draft couldn't be deleted.");
                    }
                    conv.disableBtns = false;
                })
                .catch(function () {

                });
        }

    });
