import { cipo } from 'cipo';

cipo.factory("compProfile", function ($q, Model, Form, Functions, URI, Dictionaries) {
    var compProfile = Model.extend(function (id) {
        var self = this;
        this.id = (typeof id != 'undefined' && id == parseInt(id)) ? parseInt(id) : null;
        this.industries = [];
        this.countryCodes = [];
        this.properties = {
            id: null,
            organizationName: "",
            organizationShortName: "",
            industryId: null,
            phone: "",
            fax: "",
            email: "",
            website: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            countryCode: "",
            contracts: 0,
            users: 0,
            registered: "",
            vendorNo: "",
            logoSrc: "http://via.placeholder.com/200x45"
        };

        this.pageLoader = false;
        this.formSet1 = new Form({
            editMode: false, labelWidth: {
                md: 4,
                lg: 4
            }
        });
        this.formSet2 = new Form({
            editMode: false, labelWidth: {
                md: 4,
                lg: 4
            }
        });
        
        this.formSet1.initializing = true;
        this.formSet2.initializing = true;
        this.properties.loadingOrgName = true;

        // var getSelectsData = $q.all([this.get_Industries(), this.get_CountryCodes()]);
        var all = $q.all({
            industries: Dictionaries.Industries(),
            countryCodes: Dictionaries.CountryCodes()
        });

        /*
            getSelectsData.then(function () {
            // this.properties.ind = new createSelect(self.industries);
        }).catch(function (e) {
            console.error(e);
        }).finally(function () {

            self.formSet1.set_Fields([
                self.formSet1.newField(self.properties, 'organizationName', 'Company Name', ['text'], ['required']),
                self.formSet1.newField(self.properties, 'organizationShortName', 'Abbreviation', ['text']),
                self.formSet1.newField(self.properties, 'industryId', 'Industry', ['select', self.industries]),
                self.formSet1.newField(self.properties, 'phone', 'Phone', ['text'], ['required']),
                self.formSet1.newField(self.properties, 'fax', 'Fax', ['text']),
                self.formSet1.newField(self.properties, 'email', 'Email', ['text']),
                
            ]);
            //self.countryCodes
            self.formSet2.set_Fields([
                self.formSet2.newField(self.properties, 'address', 'Address', ['text'], ['required']),
                self.formSet2.newField(self.properties, 'city', 'City', ['text'], ['required']),
                self.formSet2.newField(self.properties, 'state', 'State', ['text'], ['required']),
                self.formSet2.newField(self.properties, 'zip', 'Zip Code', ['text'], ['required']),
                self.formSet1.newField(self.properties, 'countryCode', 'Country', ['select', self.countryCodes]),
                // self.formSet2.newField(self.properties, 'countryCode', 'Country Code', ['text'], ['required']),
                self.formSet2.newField(self.properties, 'website', 'Website', ['text'])
            ]);

            self.formSet1.initializing = false;
            self.formSet2.initializing = false;
            
            if (self.id) self.get_Data();
        });*/
        self.formSet1 = new Form(self.properties);
        self.formSet1.initializing = true;
        self.formSet2 = new Form(self.properties);
        self.formSet2.initializing = true;
        self.formSet2.editMode = false;
        self.formSet1.editMode = false;

        all
            .then(function (r) {
                self.industries = r.industries;
                self.countryCodes = r.countryCodes;

                self.formSet1.set_Description({
                    organizationName: { label: 'Company Name', type: 'text', validation: { required: true, minChars: 2, maxChars: 64 } },
                    organizationShortName: { label: 'Abbreviation', type: 'text', validation: { required: true, minChars: 2, maxChars: 30 } },
                    vendorNo: { label: 'Vendor #', type: 'text' },
                    industryId: { label: 'Industry', type: 'select', options: self.industries, validation: { required: true } },
                    phone: { label: 'Phone', type: 'text', validation: { required: true, maxChars: 15 } },
                    fax: { label: 'Fax', type: 'text', validation: { maxChars: 15 } },
                    email: { label: 'Email', type: 'text', validation: { required: true, maxChars: 64 } }
                });
                self.formSet2.set_Description({
                    address: { label: 'Address', type: 'text', validation: { required: true, maxChars:255 } },
                    city: { label: 'City', type: 'text', validation: { required: true, maxChars: 64 } },
                    state: { label: 'State', type: 'text', validation: { required: true, maxChars: 32 } },
                    zip: { label: 'Zip Code', type: 'text', validation: { required: true, maxChars: 12 } },
                    countryCode: { label: 'Country', type: 'select', options: self.countryCodes, validation: { required: true } },
                    website: { label: 'Website', type: 'text', validation: { maxChars: 64 } }
                    
                });

                self.formSet1.setTemplate('grid', [
                    { organizationName: 100 },
                    { organizationShortName: 33, vendorNo: 66 },
                    { industryId: 100 },
                    { phone: 100 },
                    { fax: 100 },
                    { email: 100 },
                    
                ]);
            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {
                self.formSet1.initializing = false;
                self.formSet2.initializing = false;
                if (self.id)
                    self.get_Data();
                
            });

    });

    compProfile.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        var p2 = $q.defer();
        self.formSet1.loading = true;
        self.formSet2.loading = true;
        
        if (self.id) {
            self[URI.COMPANY.DETAIL.method](URI.COMPANY.DETAIL)
                .then(function (result) {
                    if (result) {
                        self.originalData = {};
                        for (var key in result) {
                            if (result.hasOwnProperty(key)) {
                                self.properties[key] = result[key];
                            }
                        }
                        self.formSet1.store_Data();
                        self.formSet2.store_Data();
                    }
                }).catch(function (e) {
                    console.error(e);
                }).finally(function () {
                    self.formSet1.loading = false;
                    self.formSet2.loading = false;
                    p.resolve();
                });
            self[URI.COMPANY.INFO.method](URI.COMPANY.INFO)
                .then(function (result) {
                    for (var key in result) {
                        if (result.hasOwnProperty(key)) {
                            self.properties[key] = result[key];
                        }
                    };
                    
                }).catch(function (e) {
                    console.error(e);
                }).finally(function () {
                    p2.resolve();
                });
        } else {
            //self.form.loading = false;
            p.resolve();
            p2.resolve();
        }

        var promises = $q.all([p.promise, p2.promise]);

        promises.then(function () {
            self.properties.loadingOrgName = false;
        })

        return promises;
    }


    compProfile.prototype.save_Data = function (form) {
        var self = this;
        var p = $q.defer();
        form.loading = true;
        
        form.validate();

        if (form.isValid) {
            self.properties.industryId = parseInt(self.properties.industryId);
            self[URI.COMPANY.SAVE.method](URI.COMPANY.SAVE, self.properties)
                .then(function (result) {
                    form.editMode = false;
                
                    self.get_Data();
                    p.resolve(result);
                })
                .catch(function (e) {
                    form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    form.loading = false;
                });
        } else {
            form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    return compProfile;
});
