import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { ContextTree } from '../../../system/context/context.model';
import { ContextService } from '../../../system/context/context.service';
import { DemoContextTree, DemoContextTreeWithOneContract } from '../../data/demo-context';

@Injectable()
export class DemoContextService extends ContextService {
  getContextTree(userId: number): Observable<ContextTree> {
    return of(userId === 1 ? DemoContextTreeWithOneContract : userId === 2 ? {} as ContextTree : DemoContextTree).pipe(delay(100));
  }
}
