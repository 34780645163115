import { Injectable, inject } from '@angular/core';

import { TableFieldConfig, TableFieldModel } from './table-field.models';
import { FieldsService } from '../../../../shared/components/fields/common';

@Injectable({
  providedIn: 'root',
})
export class TableFieldService {
  fieldService = inject(FieldsService);
  mapTableFields(tableFields: TableFieldModel[]): TableFieldConfig[] {
    const updatedFields = tableFields.map(({ isRequired, ...fielsProps }) => {
      return {
        ...fielsProps,
        isMandatory: isRequired,
      };
    });
    return updatedFields.map(field => {
      const mappedField = this.fieldService.mapFormFieldModel(field);
      return {
        ...mappedField,
        width: field.width,
        name: field.id.toString(),
      };
    });
  }
}
