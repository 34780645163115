import { cipo } from 'cipo';

cipo.factory("PaymentItem", function (Model, Form, $q, URI, userService, currencyService, Message) {
    var PaymentItem = Model.extend(function (obj, lookups, parentId) {
        var self = this;
        self.contractId = userService.system.userdata.contractId;
        self.parentId = parentId || null;
        self.properties = {};
        self.lookups = lookups;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            if (!self.properties.payment_percent) self.properties.payment_percent = 0;
        }
        var percentageLeft = self.properties.total_previous_payment_percentage ? 100 - parseInt(self.properties.total_previous_payment_percentage) : 100;
        self.form = new Form(self.properties);
        self.amountForm = new Form(self.properties);
        var form = {};
        var amountForm = {};
            
        currencyService.getAndSetCurrencyById(self);

        // calls params
        self.params = {
            contractId: self.contractId,
        };

        form.payment_percent = {
            label: "", type: 'number', formattingsLookup: { 1: 3 }, validation: { isNumber: true }
        };

        amountForm.payment_amount = {
            label: "", type: 'number', formattingsLookup: { 1: 2}, validation: { isNumber: true }
        };

        self.form.set_Description(form);
        self.amountForm.set_Description(amountForm);
        self.form.store_Data();
        self.amountForm.store_Data();
        
        self.form.fieldsList.payment_percent.onChange = function (field) {
            self.save();
        };
        self.amountForm.fieldsList.payment_amount.onChange = function (field) {
            self.save(true);
        };

    });
    PaymentItem.prototype.onFocus = function (isAmount) {
        var self = this;
        self.focus = true;
        if (isAmount) {
            self.bkp = self.properties.payment_amount;
            if(!self.properties.payment_amount) self.properties.payment_amount = null;
            
        } else {
            self.bkp = self.properties.payment_percent;
            if (!self.properties.payment_percent) self.properties.payment_percent = null;
            
        }
    }
    PaymentItem.prototype.onBlur = function (isAmount, event) {
        var self = this;
        self.focus = false;
        if(event) event.target.blur();
        if (isAmount) {
            if (!self.properties.payment_amount) {
                self.properties.payment_amount = 0;
                if (self.bkp) self.save(isAmount);

            } else {
                if (self.bkp != self.properties.payment_amount) self.save(isAmount);
            }

        } else {
            if (!self.properties.payment_percent) {
                self.properties.payment_percent = 0;
                if (self.bkp) self.save();
                
            } else {
                if (self.bkp != self.properties.payment_percent) self.save();
            }
        }
    }

    PaymentItem.prototype.get_Data = function (isReadonly) {
        var self = this;
        self.loading = true;
        var p = $q.defer();
        var params = {
            progressPaymentEntityInstanceId: self.parentId,
            contractId: self.contractId,
            relationInstanceId: self.properties.relation_instance_id,
            isParentSubItem: self.properties.isParentSubItem
        };
        if (self.params.editMode) params.editMode = true;
        var dataURL = URI.PROGRESS_PAYMENT.PPI_DETAILS;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    if (isReadonly) r.readonly = true;
                    // newSelf = self.pp.parseData([r], self.fields)[0];
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            if ((self.columnsLookup[key] || {}).fieldTypeId) {
                                switch (self.columnsLookup[key].fieldTypeId) {
                                    case 5: case 8: case 13:
                                        if (typeof self.columnsLookup[key].valueLookup[r[key].toString()] != 'undefined')
                                            r[key] = self.columnsLookup[key].valueLookup[r[key].toString()];
                                        else
                                            r[key] = "";
                                        break;
                                    default:
                                }
                            }
                            

                            self.properties[key] = r[key];

                        }
                    }
                    
                }  
                self.form.store_Data();
                self.amountForm.store_Data();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.form.loading = false;
                self.amountForm.loading = false;
                self.loading = false;
            })


        return p.promise;
    }

    PaymentItem.prototype.save = function (isAmount) {
        var self = this;
        var p = $q.defer();
        var valid = true;
        
        if (valid) {
            self.loading = true;
            try {
                self.lookups.modulesLookup[(
                    self.lookups.ppiLookup[self.properties.parent_child_entity_instance_id] ||
                    self.lookups.ppiLookup[self.properties.child_entity_instance_id]
                ).relation_id].isBusy = true;
            }
            catch (e) {
                // 
            }
            var dataURL = URI.PROGRESS_PAYMENT.PPI_UPDATE;
            var toSend = {
                progressPaymentEntityInstanceId: self.parentId,
                payment_percent: self.properties.payment_percent,
                payment_amount: self.properties.payment_amount,
                child_entity_instance_id: self.properties.child_entity_instance_id,
                parent_child_entity_instance_id: self.properties.parent_child_entity_instance_id,
                relation_id: self.properties.relation_id,
                relation_instance_id: self.properties.relation_instance_id,
                isParentSubItem: self.properties.isParentSubItem
            }
            if (isAmount) {
                toSend.payment_percent = null;
                toSend.payment_amount = toSend.payment_amount ? toSend.payment_amount : 0;
                self.amountForm.loading = true;
            } else {
                toSend.payment_amount = null;
                toSend.payment_percent = toSend.payment_percent ? toSend.payment_percent : 0;
                self.form.loading = true;
            }
            
            
            self[dataURL.method](dataURL, { url: self.params, urltype: 'obj', body: toSend })
                .then(function (r) {

                    if ((r.updatedReleases || {}).items && (self.lookups.modulesLookup[0].items||[]).length) {
                        for (var key in r.updatedReleases.items) {
                            if (r.updatedReleases.items.hasOwnProperty(key))
                                if ((self.lookups.modulesLookup[0] || {}).itemsLookup[key])
                                    self.lookups.modulesLookup[0].itemsLookup[key].properties.amount = r.updatedReleases.items[key];
                        }
                    }
                    (self.lookups.modulesLookup[0] || {}).totalPayment = r.updatedReleases.totalPayment;
                    (self.lookups.modulesLookup[0] || {}).total = r.updatedReleases.total;
                    ((self.lookups.modulesLookup[0] || {}).totals || {}).amount = r.updatedReleases.totalPayment;


                    for (var i = 0; i < (r.items || []).length; i++) {
                        for (var key in r.items[i]) {
                            if (r.items[i].hasOwnProperty(key)) {
                                // self.lookups.ppiLookup[r.items[i].child_entity_instance_id][key] = r.items[i][key];
                                if (r.items[i].child_entity_instance_id == self.properties.child_entity_instance_id)
                                    self.properties[key] = r.items[i][key];
                            }
                            
                        }
                    }

                    // if submodule
                    if (self.properties.parent_child_entity_instance_id && self.lookups.itemsLookup[self.properties.parent_child_entity_instance_id]) {
                        
                        // self.lookups.itemsLookup[self.properties.parent_child_entity_instance_id].get_Data(true);

                        for (var key in r.totals) {
                            if (r.totals.hasOwnProperty(key)) {
                                self.lookups.modulesLookup[self.lookups.itemsLookup[self.properties.parent_child_entity_instance_id].properties.relation_id].totals[key] = r.totals[key];

                            }
                        }
                        self.lookups.modulesLookup[self.lookups.itemsLookup[self.properties.parent_child_entity_instance_id].properties.relation_id].totalPayment = r.totals.payment_amount;

                    }
                    // self as submodule
                    else if (self.properties.isParentSubItem) {
                        self.lookups.itemsLookup[self.properties.child_entity_instance_id].get_Data(true);

                        for (var key in r.totals) {
                            if (r.totals.hasOwnProperty(key)) {
                                self.lookups.modulesLookup[self.lookups.itemsLookup[self.properties.child_entity_instance_id].properties.relation_id].totals[key] = r.totals[key];

                            }
                        }
                        self.lookups.modulesLookup[self.lookups.itemsLookup[self.properties.child_entity_instance_id].properties.relation_id].totalPayment = r.totals.payment_amount;
                    }
                        // normal item
                    else {
                        for (var key in r.totals) {
                            if (r.totals.hasOwnProperty(key)) {
                                self.lookups.modulesLookup[self.properties.relation_id].totals[key] = r.totals[key];

                            }
                        }
                        self.lookups.modulesLookup[self.properties.relation_id].totalPayment = (r.totals || {}).payment_amount || 0;
                    }
                    for (var key in r.ppTotals) {
                        if (r.ppTotals.hasOwnProperty(key)) {
                            self.lookups.totals[key] = r.ppTotals[key];
                        }
                    }
                    
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    if (isAmount) {
                        self.amountForm.restore_Data();
                        self.amountForm.loading = false;
                        self.properties.payment_amount = self.bkp;
                        //self.amountForm.catch(e);
                    } else {
                        self.form.restore_Data();
                        self.form.loading = false;
                        self.properties.payment_percent = self.bkp;

                        //self.form.catch(e);
                    }
                    Message.dberror(e);

                    p.reject(e)
                })
                .finally(function () {
                    self.form.loading = false;
                    self.loading = false;
                    try {
                        self.lookups.modulesLookup[(
                            self.lookups.ppiLookup[self.properties.parent_child_entity_instance_id] ||
                            self.lookups.ppiLookup[self.properties.child_entity_instance_id]
                        ).relation_id].isBusy = false;
                    }
                    catch (e) {
                        // 
                    }
                })
        }
        else {
            p.reject();
        }

        return p.promise;
    }

    return PaymentItem;
});
