import { cipo } from 'cipo';

cipo.directive("reportconfig", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "report": "=",
        },
        templateUrl: "/ng/views/directives/reportConfig/reportconfig.html",
        controller: function ($scope) {

            

            $scope.set_fieldsWidth = function () {
                var parts = 0;

                for (var i = 0; i < $scope.report.selectedFields.length; i++) {
                    parts += $scope.report.selectedFields[i].width;
                }
                for (var i = 0; i < $scope.report.selectedFields.length; i++) {
                    $scope.report.selectedFields[i].widthPercentage = 100 / parts * $scope.report.selectedFields[i].width;
                }

                // $scope.report.tableWidth = parts * 140;
            }

            Object.defineProperty($scope.report, 'tableWidth', {
                get: function () {
                    var parts = 0;

                    for (var i = 0; i < $scope.report.selectedFields.length; i++) {
                        parts += $scope.report.selectedFields[i].width;
                    }
                    return parts * 140;
                }
            });

            $scope.removeItem = function (i) {
                i.selected = false;
                i.isUsed = false;
                $scope.report.selectedFields.splice($scope.report.selectedFields.indexOf(i), 1);
                $scope.set_fieldsWidth();
            }

            $scope.resetFilter = function (f) {
                f.value = f.defaultValue;
                f.operator = f.defaultOperator;
            }

            $scope.resetFilters = function () {
                for (var i = 0; i < $scope.report.filtersInfo.filters.filters.length; i++) {
                    $scope.resetFilter($scope.report.filtersInfo.filters.filters[i]);
                }

            }

            $scope.allFields = function (isAdd) {
                if (!isAdd) {
                    for (var i = 0; i < $scope.report.selectedFields.length; i++) {
                        $scope.report.selectedFields[i].selected = false;
                        $scope.report.selectedFields[i].isUsed = false;
                    }
                    $scope.report.selectedFields = [];
                } else {
                    for (var i = 0; i < $scope.report.fields.length; i++) {
                        if (!$scope.report.fields[i].selected) {
                            $scope.report.fields[i].width = 1;
                            $scope.report.selectedFields.push($scope.report.fields[i]);
                            $scope.report.fields[i].selected = true;
                        }
                    }
                    $scope.set_fieldsWidth();
                }
            }

        }
    }
});
