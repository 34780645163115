import { cipo } from 'cipo';

cipo.factory("StatesMapping", function (Model, Form, Dictionaries, URI, $q, Message) {
    var StatesMapping = Model.extend(function (mappingInfo) {
        var self = this;
        // self.parentId = mappingInfo.parent.id || null;
        // self.childId = mappingInfo.child.id || null;
        self.mappingInfo = mappingInfo || {};
        self.editMode = true;
        self.isBusy = true;
        var all = $q.all([
            Dictionaries.States({ moduleId: self.mappingInfo.parent.id }),
            Dictionaries.States({ moduleId: self.mappingInfo.child.id })
        ]);
        all
            .then(function (r) {
                self.parentStates = r[0];
                self.childStates = r[1];
                self.get_Data();  
            })
            .catch(function () {  
            })
    });

    StatesMapping.prototype.get_Data = function () {
        var self = this;
        
        var dataURL = URI.MODULE_RELATION.GET_RELATION_MAPPINGS;
        self[dataURL.method](dataURL, { url: { relationId: self.mappingInfo.relationId }, urltype: 'obj' })
            .then(function (r) {
                self.mappingsList = [];
                if (!r || !r.length) self.editMode = true;
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        self.mappingsList.push(self.createForm(r[i]));
                    }
                }
                self.isMappingLoaded = true;
            })
            .catch(function (e) {
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
    }

    StatesMapping.prototype.sync = function () {
        var self = this;
        var toSend = [];
        
        var formsValid = true;
        var dataURL = URI.MODULE_RELATION.SYNC_RELATION_MAPPINGS;
        if (self.mappingsList.length) {
            for (var i = 0; i < self.mappingsList.length; i++) {
                self.mappingsList[i].validate();
                if (!self.mappingsList[i].isValid) formsValid = false;
            }
        }
        if (formsValid) {
            self.isBusy = true;
            if (self.mappingsList.length) {
                for (var i = 0; i < self.mappingsList.length; i++) {
                    toSend.push(self.mappingsList[i].data);
                }
            }
            self[dataURL.method](dataURL, { url: { relationId: self.mappingInfo.relationId }, urltype: 'obj', body: toSend })
                .then(function (r) {
                    Message.info("Mapping updated successfully");
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
                .finally(function () { self.isBusy = false; });
        }
        
    }

    StatesMapping.prototype.addMapping = function () {
        var self = this; 
        var item = { parentStateId: null, childStateId: null };
        self.mappingsList.splice(0, 0, self.createForm(item));
        // self.mappingsList.push(self.createForm(mapping));
    }

    StatesMapping.prototype.removeMapping = function (item) {
        var self = this; 
        var mapping = { parentStateId: null, childStateId: null };
        self.mappingsList.splice(self.mappingsList.indexOf(item), 1);
    }

    StatesMapping.prototype.createForm = function (item) {
        var self = this;
        var form = new Form(item);
        //create form
        var formContent = {
            parentStateId: { label: 'Select state', type: 'select', options: self.parentStates, validation: { required: true } },
            childStateId: { label: 'Select state', type: 'select', options: self.childStates, validation: { required: true } }
        };
        Object.defineProperty(form, 'editMode', {
            get: function () { return self.editMode; }
        });
        form.set_Description(formContent);
        form.setTemplate('grid', [
            { parentStateId: 50, childStateId: 50 },
        ]);
        form.store_Data();

        return form;
    }

    return StatesMapping;
});
