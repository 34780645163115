import { cipo } from 'cipo';

cipo.factory('Dictionaries', ['$q', 'Model', 'URI',
    function ($q, Model, URI) {
        var dictionariesService = Model.extend(function () { });
        var cache = {};

        dictionariesService.prototype.Industries = function () {
            var self = this;
            var industries = [];
            var p = $q.defer();

            self[URI.MISC.INDUSTRIES.method](URI.MISC.INDUSTRIES).then(function (result) {
                industries = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(industries);
            });

            return p.promise;
        };

        dictionariesService.prototype.CountryCodes = function () {
            var self = this;
            var countryCodes = [];
            var p = $q.defer();

            self[URI.MISC.COUNTRY_CODES.method](URI.MISC.COUNTRY_CODES).then(function (result) {
                countryCodes = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(countryCodes);
            });

            return p.promise;
        };

        dictionariesService.prototype.Mimetypes = function () {
            var self = this;
            var ret = [];
            var p = $q.defer();

            self[URI.FIELD_DEFINITION.MIME_TYPES_DICT.method](URI.FIELD_DEFINITION.MIME_TYPES_DICT).then(function (result) {
                ret = result;
                ret.splice(0, 0, { key: 0, name: "All" });
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(ret);
            });

            return p.promise;
        };

        dictionariesService.prototype.ListChildren = function (urlParams) {
            var self = this;
            var ret = [];
            var p = $q.defer();
            var dataURL = URI.LISTS.CHILD_LIST_DICT;
            self[dataURL.method](dataURL, { url: urlParams || {}, urltype: 'obj' })
                .then(function (r) {
                    ret = r;
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                    p.resolve(ret);
                });

            return p.promise;
        };

        dictionariesService.prototype.ModulePropertyFields = function (urlParams, headerParams) {
            var self = this;
            var ret = [];
            var p = $q.defer();
            var dataURL = URI.FIELD_DEFINITION.ENTITY_FIELDS_DICT;
            self[dataURL.method](dataURL, { url: urlParams || {}, urltype: 'obj' }, { headers: headerParams || {} })
                .then(function (r) {
                    ret = r;
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                    p.resolve(ret);
                });

            return p.promise;
        };

        dictionariesService.prototype.PresetAvailableNumbers = function (urlParams, headerParams) {
            var self = this;
            var ret = [];
            var p = $q.defer();
            var dataURL = URI.MODULE_PRESETS.AVAILABLE_NUMBERS_DICT;
            self[dataURL.method](dataURL, { url: urlParams || {}, urltype: 'obj' }, { headers: headerParams || {} })
                .then(function (r) {
                    ret = r;
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                    p.resolve(ret);
                });

            return p.promise;
        };

        dictionariesService.prototype.ListParents = function (urlParams) {
            var self = this;
            var ret = [];
            var p = $q.defer();
            var dataURL = URI.LISTS.PARENT_LIST_DICT;
            self[dataURL.method](dataURL, { url: urlParams || {}, urltype: 'obj' }).then(function (r) {
                ret = r;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(ret);
            });

            return p.promise;
        };

        dictionariesService.prototype.ModuleFieldsByType = function (urlParams, headerParams) {
            var self = this;
            var ret = [];
            var p = $q.defer();
            var urlParams = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            var headerParams = headerParams ? { headers: headerParams } : {};
            var dataURL = URI.FIELD_DEFINITION.DICT;
            self[dataURL.method](dataURL, urlParams, headerParams)
                .then(function (result) {
                    ret = result;                        
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                    p.resolve(ret);
                });

            return p.promise;
        };

            dictionariesService.prototype.ComplexFormulaFields = function (urlParams, headerParams) {
                var self = this;
                var ret = [];
                var p = $q.defer();
                var urlParams = urlParams ? { url: urlParams, urltype: 'obj' } : {};
                var headerParams = headerParams ? { headers: headerParams } : {};
                var dataURL = URI.FIELD_DEFINITION.DATA_LIST_COMPLEX_FORMULA_DICT;
                self[dataURL.method](dataURL, urlParams, headerParams)
                    .then(function (result) {
                        ret = result;                        
                    }).catch(function (e) {
                        p.reject(e);
                    }).finally(function () {
                        p.resolve(ret);
                    });

                return p.promise;
            };

            dictionariesService.prototype.ModuleFieldsRelationByType = function (urlParams, headerParams) {
                var self = this;
                var ret = [];
                var p = $q.defer();
                var urlParams = urlParams ? { url: urlParams, urltype: 'obj' } : {};
                var headerParams = headerParams ? { headers: headerParams } : {};
                var dataURL = URI.FIELD_DEFINITION.EXPRESSION_DICT;
                self[dataURL.method](dataURL, urlParams, headerParams)
                    .then(function (result) {
                        ret = result;
                    }).catch(function (e) {
                        p.reject(e);
                    }).finally(function () {
                        p.resolve(ret);
                    });

                return p.promise;
            };

            dictionariesService.prototype.FieldScreens = function (urlParam) {
                var self = this;
                var ret = [];
                var p = $q.defer();
                var urlParam = { url: urlParam, urltype: 'obj' };
                self[URI.SCREEN_FIELDS.FIELD_SCREENS_DICT.method](URI.SCREEN_FIELDS.FIELD_SCREENS_DICT, urlParam)
                    .then(function (result) {
                        ret = result;
                        ret.splice(0, 0, { key: 0, value: "Default" });
                    }).catch(function (e) {
                        p.reject(e);
                    }).finally(function () {
                        p.resolve(ret);
                    });

            return p.promise;
        };

        dictionariesService.prototype.ModuleEntities = function (urlParams, headerParams) {
            var self = this;
            var ret = [];
            var p = $q.defer();
            var urlParams = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            var headerParams = headerParams ? { headers: headerParams } : {};
            var dataURL = URI.FIELD_DEFINITION.ENTITIES_DICT;
            self[dataURL.method](dataURL, urlParams, headerParams).then(function (result) {
                ret = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(ret);
            });

            return p.promise;
        };

        dictionariesService.prototype.Organizations = function () {
            var self = this;
            var organizations = [];
            var p = $q.defer();

            self[URI.ORGANIZATION.DICT.method](URI.ORGANIZATION.DICT).then(function (result) {
                organizations = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(organizations);
            });

            return p.promise;
        };

        dictionariesService.prototype.StateTypes = function () {
            var self = this;
            var p = $q.defer();

            self[URI.STATES.TYPES_DICT.method](URI.STATES.TYPES_DICT).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                
            });

            return p.promise;
        };

        dictionariesService.prototype.States = function (headerparams) {
            var self = this;
            var p = $q.defer();

            self[URI.STATES.DICT.method](URI.STATES.DICT, {}, { headers: headerparams})
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                
                });

            return p.promise;
        };

        dictionariesService.prototype.RoleUsers = function (urlParams) {
            var self = this;
            var p = $q.defer();
            var urlParams = urlParams || {};
            self[URI.MODULES.ROLES_DICT.method](URI.MODULES.ROLES_DICT, { url: urlParams, urltype: 'obj' })
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                
                });

            return p.promise;
        };

        dictionariesService.prototype.UserRolesPerModule = function (urlParams, headerparams) {
            var self = this;
            var p = $q.defer();
            var urlParams = urlParams || {};
            var headerparams = headerparams || {};
            self[URI.ROLES.ROLE_USERS_DICT.method](URI.ROLES.ROLE_USERS_DICT, { url: urlParams, urltype: 'obj' }, headerparams)
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                
                });

            return p.promise;
        };

        dictionariesService.prototype.ClosedStates = function (headerparams, urlParams) {
            var self = this;
            var p = $q.defer();

            self[URI.STATES.CLOSED_STATES_DICT.method](URI.STATES.CLOSED_STATES_DICT, { url: urlParams, urltype: 'obj' }, { headers: headerparams})
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                
                });

            return p.promise;
        };

        dictionariesService.prototype.RelationTypes = function (headerparams) {
            var self = this;
            var p = $q.defer();
            var headerparams = headerparams || {};
            self[URI.MODULE_RELATION.RELATION_TYPES_DICT.method](URI.MODULE_RELATION.RELATION_TYPES_DICT, {}, { headers: headerparams})
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                
                });

            return p.promise;
        };

        dictionariesService.prototype.ModuleTypes = function (moduleId) {
            var self = this;
            var p = $q.defer();
            var headerparams = moduleId ? { moduleId: moduleId } : {};
            self[URI.MODULE_TYPES.TYPES_DICT.method](URI.MODULE_TYPES.TYPES_DICT, {}, { headers: headerparams})
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                
                });

            return p.promise;
        };

        dictionariesService.prototype.ModulesDict = function (headerparams) {
            var self = this;
            var p = $q.defer();
            var headerparams = headerparams || {};
            var urlData = URI.MODULE_RELATION.DATASOURCE_DICT_RELATIONS;
            self[urlData.method](urlData, {}, { headers: headerparams })
                .then(function (result) {
                    
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.TopFormulas = function (headerparams, urlparams) {
            var self = this;
            var p = $q.defer();
            var headerparams = headerparams || {};
            var urlparams = urlparams ? { url: urlparams, urltype: "obj" } : {};
            var urlData = URI.MODULE_FIELDS.TOP_FORMULAS_DICT;
            self[urlData.method](urlData, urlparams, { headers: headerparams })
                .then(function (result) {
                    
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.Lists = function () {
            var self = this;
            var p = $q.defer();
            var urlData = URI.LISTS.DATASOURCE_DICT;
            self[urlData.method](urlData)
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.AvailableFields = function (urlParams) {
            var self = this;
            var p = $q.defer();
            var urlData = URI.ACTIONS.FIELDS_DICT;
            self[urlData.method](urlData, { url: urlParams || {}, urltype: 'obj' })
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.recurringAdjustmantTypes = function () {
            var self = this;
            var p = $q.defer();
            p.resolve([]);
            return p.promise;
            var urlData = URI.CONTRACT_RECURRING_ADJUSTMENTS.TYPE_DICT;
            self[urlData.method](urlData)
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.onetimeAdjustmentTypes = function () {
            var self = this;
            var p = $q.defer();
            p.resolve([]);
            return p.promise;
            var urlData = URI.CONTRACT_ONE_TIME_ADJUSTMENTS.TYPE_DICT;
            self[urlData.method](urlData)
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.adjustmentCategories = function () {
            var self = this;
            var p = $q.defer();
            var urlData = URI.ADJUSTMENTS.CATEGORY_DICT;
            self[urlData.method](urlData)
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.accrualFrequencies = function () {
            var self = this;
            var p = $q.defer();
            var urlData = URI.ADJUSTMENTS.ACCRUAL_FREQUENCY_DICT;
            self[urlData.method](urlData)
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {

                })

            return p.promise;

        }

        dictionariesService.prototype.Users = function (param) {
            var self = this;
            var users = [];
            var p = $q.defer();
            var parameters = param || {};

            self[URI.USER.DICT.method](URI.USER.DICT, parameters).then(function (result) {
                users = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(users);
            });

            return p.promise;
        };

        dictionariesService.prototype.Operations = function (perContract) {
            var self = this;
            var operations = [];
            var p = $q.defer();
            var url = { perContract: perContract || false };

            self[URI.PERMISSIONS.OPERATIONS_DICT.method](URI.PERMISSIONS.OPERATIONS_DICT, url).then(function (result) {
                operations = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(operations);
            });

            return p.promise;
        };

        dictionariesService.prototype.AllRoles = function (urlParams) {
            var self = this;
            var roles = [];
            var p = $q.defer();
            var url = { };
            //var system = typeof perContract == 'undefined' ? false : perContract;
            if (urlParams) {
                for (var key in urlParams) {
                    if (urlParams.hasOwnProperty(key)) {
                        url[key] = urlParams[key];
                    }
                }
            }
            self[URI.ROLES.DICT.method](URI.ROLES.DICT, { url: url, urltype: 'obj' })
                .then(function (result) {
                    roles = result;
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                    p.resolve(roles);
                });

            return p.promise;
        };

        dictionariesService.prototype.Roles = function (perContract, urlParams) {
            var self = this;
            var roles = [];
            var p = $q.defer();
            var url = { perContract: perContract ? perContract : false};
            //var system = typeof perContract == 'undefined' ? false : perContract;
            if (urlParams) {
                for (var key in urlParams) {
                    if (urlParams.hasOwnProperty(key)) {
                        url[key] = urlParams[key];
                    }
                }
            }
            self[URI.ROLE.DICT.method](URI.ROLE.DICT, { url: url, urltype: 'obj'}).then(function (result) {
                roles = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(roles);
            });

            return p.promise;
        };

        dictionariesService.prototype.ContractRoles = function (perContract, urlParams) {
            var self = this;
            var roles = [];
            var p = $q.defer();
            var url = { perContract: perContract ? perContract : false};
            //var system = typeof perContract == 'undefined' ? false : perContract;
            if (urlParams) {
                for (var key in urlParams) {
                    if (urlParams.hasOwnProperty(key)) {
                        url[key] = urlParams[key];
                    }
                }
            }
            self[URI.CT.ROLE_DICT.method](URI.CT.ROLE_DICT, { url: url, urltype: 'obj'}).then(function (result) {
                roles = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(roles);
            });

            return p.promise;
        };

        dictionariesService.prototype.ProgramProjectContract = function (urlParams) {
            var self = this;
            var list = [];
            var p = $q.defer();
            var url = {};

            if (urlParams) {
                for (var key in urlParams) {
                    if (urlParams.hasOwnProperty(key)) {
                        url[key] = urlParams[key];
                    }
                }
            }
            self[URI.DASHBOARD.PROGRAM_PROJECT_CONTRACT_DICT.method](URI.DASHBOARD.PROGRAM_PROJECT_CONTRACT_DICT, { url: url, urltype: 'obj'}).then(function (result) {
                list = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(list);
            });

            return p.promise;
        };

        dictionariesService.prototype.HeadersOrFooters = function (urlParams) {
            var self = this;
            var list = [];
            var p = $q.defer();
            var url = urlParams || {};

            self[URI.HEADER_FOOTER_SETTINGS.DICT.method](URI.HEADER_FOOTER_SETTINGS.DICT, { url: url, urltype: 'obj'}).then(function (result) {
                list = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(list);
            });

            return p.promise;
        };

        dictionariesService.prototype.AssignedRoles = function (headerParams, urlParams) {
            var self = this;
            var roles = [];
            var p = $q.defer();
            var urlParams = urlParams ? { url: urlParams, urltype: 'obj' }  : {};
            var headerParams = headerParams ? { headers: headerParams } : {};

            self[URI.PERMISSIONS.ROLES_DICT.method](URI.PERMISSIONS.ROLES_DICT, urlParams, headerParams).then(function (result) {
                roles = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(roles);
            });

            return p.promise;
        };

        dictionariesService.prototype.Contracts = function (urlParams) {
            var self = this;
            var contracts = [];
            var p = $q.defer();
            var url = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            self[URI.CT.DICT.method](URI.CT.DICT, url).then(function (result) {
                contracts = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(contracts);
            });

            return p.promise;
        };

        dictionariesService.prototype.UserContracts = function (urlParams) {
            var self = this;
            var contracts = [];
            var p = $q.defer();
            var url = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            self[URI.ROLES.CONTRACTS_DICT.method](URI.ROLES.CONTRACTS_DICT, url)
                .then(function (result) {
                    contracts = result;
                    p.resolve(contracts);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {
                    
                });

            return p.promise;
        };

        dictionariesService.prototype.Projects = function () {
            var self = this;
            var projects = [];
            var p = $q.defer();

            self[URI.PROJECT.DICT.method](URI.PROJECT.DICT).then(function (result) {
                projects = result;
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {
                p.resolve(projects);
            });

            return p.promise;
        };

        dictionariesService.prototype.RecipientsList = function (params) {
            var self = this;
            var p = $q.defer();
            var parameters = params ? params : { contractId: 0, moduleId: 0 };

            self[URI.EMAIL.RECIPIENTS_DICT.method](URI.EMAIL.RECIPIENTS_DICT, parameters).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.TypeList = function (param) {
            var self = this;
            var p = $q.defer();
            var parameters = param ? { contractId: param } : { contractId: 0 };

            self[URI.EMAIL.TYPE_DICT.method](URI.EMAIL.TYPE_DICT, parameters).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Templates = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams || {};

            self[URI.TEMPLATES.TEMPLATES_DICT.method](URI.TEMPLATES.TEMPLATES_DICT, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Screens = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams || {};

            self[URI.SCREEN_DEFINITION.DICT.method](URI.SCREEN_DEFINITION.DICT, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Forms = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams || {};

            self[URI.SCREEN_DEFINITION.DICT.method](URI.SCREEN_DEFINITION.DICT, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.NumericFields = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams || {};

            self[URI.SCREENS.NUMERIC_FIELDS_DICT.method](URI.SCREENS.NUMERIC_FIELDS_DICT, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.ConditionalFields = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams || {};
            var dataURL = URI.TRANSITION.CONDITIONAL_FIELDS_DICT;
            self[dataURL.method](dataURL, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.ConditionalOperators = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams || {};
            var dataURL = URI.TRANSITION.OPERATORS_DICT;
            self[dataURL.method](dataURL, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Fields = function (headerParam) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            self[URI.SCREENS.FIELD_TYPE_DICT.method](URI.SCREENS.FIELD_TYPE_DICT, {}, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Signatures = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            self[URI.TRANSITION.SIGN_LIST.method](URI.TRANSITION.SIGN_LIST, { url: urlParams, urltype: 'obj' }, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.SignaturesTransitions = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            self[URI.TRANSITION_GROUP.SIGN_LIST_DICT.method](URI.TRANSITION_GROUP.SIGN_LIST_DICT, { url: urlParams, urltype: 'obj' }, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        //
        dictionariesService.prototype.UserTemplates = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            
            self[URI.TEMPLATES.USER_TEMPLATES_DICT.method](URI.TEMPLATES.USER_TEMPLATES_DICT, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.TenantSettings = function () {
            var self = this;
            var p = $q.defer();
            
            self[URI.TENANT_SETTINGS.DICT.method](URI.TENANT_SETTINGS.DICT)
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                });

            return p.promise;
        };

        dictionariesService.prototype.Placeholders = function (urlParams, headerParam) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            
            self[URI.HEADER_FOOTER_SETTINGS.PLACEHOLDERS_DICT.method](URI.HEADER_FOOTER_SETTINGS.PLACEHOLDERS_DICT, url, { headers: header })
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                }).finally(function () {

                });

            return p.promise;
        };

        dictionariesService.prototype.ScheduleDays = function () {
            var self = this;
            var p = $q.defer();
            self[URI.SCHEDULE_DAY.DICT.method](URI.SCHEDULE_DAY.DICT).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.ScheduleShifts = function () {
            var self = this;
            var p = $q.defer();
            self[URI.SCHEDULE_SHIFT.DICT.method](URI.SCHEDULE_SHIFT.DICT).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Schedules = function () {
            var self = this;
            var p = $q.defer();
            self[URI.SCHEDULE.DICT.method](URI.SCHEDULE.DICT).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Intervals = function () {
            var self = this;
            var p = $q.defer();
            self[URI.FINANCIAL_SCHEDULE.INTERVAL_DICT.method](URI.FINANCIAL_SCHEDULE.INTERVAL_DICT).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Financials = function () {
            var self = this;
            var p = $q.defer();
            self[URI.FINANCIAL_SCHEDULE.DICT.method](URI.FINANCIAL_SCHEDULE.DICT).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Notifications = function (header) {
            var self = this;
            var p = $q.defer();
            self[URI.MODULE_NOTIFICATIONS.DICT.method](URI.MODULE_NOTIFICATIONS.DICT, {}, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.SOVCategories = function (headerParam, urlParams) {
            var self = this;
            var p = $q.defer();
            var header = headerParam;
            var url = urlParams ? { url: urlParams, urltype: 'obj' } : {}
            self[URI.SOV_CATEGORY.DICT.method](URI.SOV_CATEGORY.DICT, url, { headers: header }).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.PPPeriods = function (urlParams) {
            var self = this;
            var p = $q.defer();
            var url = urlParams ? { url: urlParams, urltype: 'obj' } : {};
            self[URI.PROGRESS_PAYMENT.PPD_DICT.method](URI.PROGRESS_PAYMENT.PPD_DICT, url).then(function (result) {
                p.resolve(result);
            }).catch(function (e) {
                p.reject(e);
            }).finally(function () {

            });

            return p.promise;
        };

        dictionariesService.prototype.Currencies = function () {
            var self = this;
            var p = $q.defer();

            if (cache.currencies) {
                // get data from cache
                p.resolve(cache.currencies);
                return p.promise;
            }

            if (cache.currenciesRequesting) {
                // handle if same request is made several times
                setTimeout(function () {
                    self.Currencies().then(function(r) {
                        p.resolve(r);
                    });
                }, 10);
                return p.promise;
            }
            
            cache.currenciesRequesting = true;

            self[URI.MISC.CURRENCIES.method](URI.MISC.CURRENCIES).then(function (result) {
                cache.currencies = result;
            }).catch(function () {
                // ignore
            }).finally(function () {
                cache.currenciesRequesting = false;
                p.resolve(cache.currencies || []);
            });
            return p.promise;
        };

        dictionariesService.prototype.periodStartDates = function (contractEntityInstanceId) {
            var self = this;
            var p = $q.defer();
            var url = { url: { entityInstanceId: contractEntityInstanceId, isStartDate: true, orderAscending: true }, urltype: 'obj' };
            self[URI.PROGRESS_PAYMENT.DICT_DATE_PERIODS.method](URI.PROGRESS_PAYMENT.DICT_DATE_PERIODS, url)
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                });

            return p.promise;
        };

        dictionariesService.prototype.periodEndDates = function (contractEntityInstanceId) {
            var self = this;
            var p = $q.defer();
            var url = { url: { entityInstanceId: contractEntityInstanceId, isStartDate: false, orderAscending: false }, urltype: 'obj' };
            self[URI.PROGRESS_PAYMENT.DICT_DATE_PERIODS.method](URI.PROGRESS_PAYMENT.DICT_DATE_PERIODS, url)
                .then(function (result) {
                    p.resolve(result);
                }).catch(function (e) {
                    p.reject(e);
                });

            return p.promise;
        };

        return new dictionariesService;
    }]);
