import { cipo } from 'cipo';

cipo.factory("Organization", function ($q, Model, Form, Dictionaries, URI, Message, Permissions) {

    var Organization = Model.extend(function (id) {
        var self = this;
        self.tabWarrning = false;
        self.highlight = false;
        var all = $q.all({
            industries: Dictionaries.Industries(),
            countryCodes: Dictionaries.CountryCodes()
        });
        var p = $q.defer();
        this.id = (typeof id != 'undefined' && id == parseInt(id)) ? parseInt(id) : null;
        this.process = {
            loaded: false,
            error: null
        };
        this.industries = [];
        this.countryCodes = [];
        self.loadingDetails = true;

        self.init();
        if (id) {
            this.properties.id = id;
        }   
        this.form = new Form(this.properties);
        this.form.initializing = true;

        all
            .then(function (r) {
                self.industries = r.industries;
                self.countryCodes = r.countryCodes;

                self.form.set_Description({
                    organizationName: { label: 'Name', type: 'text', validation: { required: true, minChars: 2, maxChars: 64 } },
                    details: { label: 'Details', type: 'text', validation: { maxChars: 512 } },
                    vendorNo: { label: 'Vendor #', type: 'text' },
                    industryId: { label: 'Industry', type: 'select', options: self.industries },
                    primaryContact: { label: 'Primary Contact', type: 'text', validation: { maxChars: 50 } },
                    phone: { label: 'Phone', type: 'text', validation: { maxChars: 20 } },
                    fax: { label: 'Fax', type: 'text', validation: { maxChars: 15 } },
                    email: { label: 'Email', type: 'text', validation: { email:true, maxChars: 64 } },
                    website: { label: 'Website', type: 'text', validation: { maxChars: 64 } },
                    address: { label: 'Address', type: 'text', validation: { maxChars: 255 } },
                    city: { label: 'City', type: 'text', validation: { maxChars: 64 } },
                    state: { label: 'State', type: 'text', validation: { maxChars: 32 } },
                    zip: { label: 'Zip Code', type: 'text', validation: { maxChars: 12 } },
                    countryCode: { label: 'Country', type: 'select', options: self.countryCodes }
                });

                self.form.setTemplate('grid', [
                    { organizationName: 50, vendorNo: 50 },
                    { details: 100 },
                    { industryId: 50, primaryContact: 50 },
                    { phone: 50, fax: 50 },
                    { email: 50, website: 50 },
                    { countryCode: 50, address: 50 },
                    { city: 50, state: 25, zip: 25 }
                ]);
            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {                    
                if (self.id) {
                    self.form.loading = true;
                    self.get_Data();
                }

                else {
                    self.form.initializing = false;
                }
                    
            });
    });

    Organization.prototype.init = function (isAdd) {
        var self = this;
        
        this.properties = {
            id: null,
            organizationName: null,
            details: null,
            vendorNo: null,
            industryId: null,
            primaryContact: null,
            phone: null,
            fax: null,
            email: null,
            website: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            countryCode: "US",
            active: false,
            deleted: false,
            permissions: [1, 2]
        };
        if (isAdd) {
            this.id = null;
            self.form.set_Data(self.properties);
            self.form.store_Data();
            
        }
        if (!this.id) {
            
            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
            
    }

    
    Organization.prototype.get_Data = function (id) {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.loadingDetails = true;

        if (id || self.id) {
            self[URI.ORGANIZATION.DETAIL.method](URI.ORGANIZATION.DETAIL, { orgId: id || self.id }).then(function (result) {
                if (result) {
                    self.operations = {};
                    for (var key in result) {
                        if (result.hasOwnProperty(key)) {
                            self.properties[key] = result[key];
                        }
                    }
                    if ((self.properties.permissions || []).length) {
                        for (var j = 0; j < self.properties.permissions.length; j++) {
                            Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                        }
                    }
                    if (!self.operations.Update) self.form.editMode = false;
                    self.form.store_Data();
                    self.form.set_Data(self.properties);
                }
            }).catch(function (e) {
                self.process.error = e;
                p.reject();
            }).finally(function () {
                self.process.loaded = true;
                self.form.loading = false;
                self.form.initializing = false;
                self.loadingDetails = true;
                p.resolve();
            });
        } else {
            self.form.loading = false;
            p.resolve();
        }

        return p.promise;
    }

    Organization.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;

        self.form.validate();

        if (self.form.isValid) {
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.properties
            };
            var dataURL = self.properties.id ? URI.ORGANIZATION.SAVE : URI.ORGANIZATION.ADD;
            self[dataURL.method](dataURL, params)
                .then(function (r) {
                    p.resolve(r);
                    self.isModified = true;
                    Message.info('Organization saved successfully');
                    self.form.loading = false;
                    // if new, call for details to get permissions
                    if (!self.properties.id && !isAdd) {
                        self.properties.id = r;
                        self.get_Data(r);
                        return;
                    }
                    // is not new
                    if (!isAdd) {
                        self.form.store_Data();
                        self.form.set_Data(self.properties);
                        
                        return;
                    }
                    // if is add new just start another
                    if (isAdd) {
                        self.init(true);
                    }
                    
                })
                .catch(function (e) {
                    self.form.loading = false;
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    
                });
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }

    return Organization;
});
