import { cipo } from 'cipo';

cipo.directive("tablists", function () {
    return {
        restrict: "E",
        replace: true,
        transclude: true,
        scope: {
            "parent": "=",
            "options": "="
        },
        templateUrl: "/ng/views/directives/tablists.html",
        controller: function ($scope, ACTIONS, ModuleN, Message) {

            // $scope.pageStart = 0;
            $scope.pagination = { pageStart: 0 };
            $scope.step = $scope.options.step || 100000000;
            $scope.paginationEnd = { pageEnd: $scope.pagination.pageStart + $scope.step };

            $scope.showItem = false;

            //if (typeof $scope.options.addButton.text != 'function') {
            //    var i = $scope.options.addButton.text;
            //    $scope.options.addButton.text = function () { return i };
            //}

            $scope.searchChange = function () {
                $scope.pagination.pageStart = 0;
                $scope.paginationEnd.pageEnd = $scope.pagination.pageStart + $scope.step;
            }
            $scope.get_gridSettings = function (item, isChild, isPayment) {
                item.isSecondScreen = true;
                item.isGridSettings = true;

                var who = isChild ? item.properties.childId : item.properties.parentId;
                item.screenModule = new ModuleN({ moduleId: who, relationId: isPayment ? item.properties.partialPaymentRelationId : item.properties.id }, true);
                // item.screenModule.get_GridFields();
                item.screenModule.name = isChild ? item.properties.childName : item.properties.parentName;

            }
            // one common action if we have an add button with a dropdown
            if ($scope.options.addButton && $scope.options.addButton.items && $scope.options.addButton.click) {
                for (var i = 0; i < $scope.options.addButton.items.length; i++) {
                    $scope.options.addButton.items[i].click = $scope.options.addButton.click;
                }
            }

            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };
            // console.error('options', $scope.options);
            // $scope.list = $scope.options.listKey ? $scope.parent[$scope.options.listKey] : [];
            Object.defineProperty($scope, 'list', {
                get: function () { return $scope.options.listKey ? $scope.parent[$scope.options.listKey] : []; }
            });

            $scope.$watchCollection(function () {
                return $scope.list;
            }, function (n, o) {
                // console.log($scope.options.listKey + ' changed', n, o);
                $scope.verifyPagination();
            }, true);

            Object.defineProperty($scope, 'isLoaded', {
                get: function () {
                    var loadingFlag = true;
                    if ($scope.options.loadingFlag) {
                        if (typeof $scope.parent[$scope.options.loadingFlag] != 'undefined')
                            loadingFlag = $scope.parent[$scope.options.loadingFlag];
                        else loadingFlag = $scope.options.parent[$scope.options.loadingFlag];
                    }
                    return loadingFlag;
                }
            });

            var listItemId = $scope.options.listItemId || 'id';
            $scope.options.modifiesParent = $scope.options.modifiesParent || false;
            $scope.options.maxHeight = $scope.options.maxHeight + 'px' || "auto";
            $scope.listItemUsed = $scope.options.listItemUsed || 'isUsed';
            $scope.listItemUsedText = $scope.options.listItemUsedText || 'assigned';
            $scope.listItemUsedSecond = $scope.options.listItemUsedSecond;
            $scope.listItemUsedTextSecond = $scope.options.listItemUsedTextSecond;
            $scope.expandList = function (parent) {
                // console.error('parent expanded', parent);
                if ($scope.options.isCollapsed && !$scope.options.parent.isBusy) parent.listExpanded = !parent.listExpanded;
                else return;
            }
            // main actions
            $scope.options.actions = angular.copy($scope.options.actionsBeforeDefault) || [];
            var editAction = {
                setProperties: ACTIONS.DETAILS,
                name: "Edit Details",
                condition: typeof $scope.options.showEdit != 'function' ?
                    function (item) {
                        return true;
                    } : $scope.options.showDelete,
                disabled: (typeof $scope.options.disableEditCondition != 'function') ?
                    function (item) {
                        return false;
                    } : $scope.options.disableEditCondition,
                click: function (item, parent) {
                    $scope.toggleEditMode(item, parent);
                }
            };
            if ($scope.options.editAction) {

                for (var key in $scope.options.editAction)
                    if ($scope.options.editAction.hasOwnProperty(key))
                        editAction[key] = $scope.options.editAction[key];
            }
            var deleteAction = {
                setProperties: ACTIONS.DELETE,
                condition: typeof $scope.options.showDelete != 'function' ?
                    function (item) {
                        return true;
                    } : $scope.options.showDelete,
                disabled: typeof $scope.options.disableDeleteCondition != 'function' ?
                    function (item) {
                        if (item.properties.isUsed) return true;
                        else return false;
                    } : $scope.options.disableDeleteCondition,
                click: function (item, parent) {
                    $scope.toggleDelete(item, parent);
                }
            };
            $scope.options.rowClass = typeof $scope.options.rowClass == 'function' ? $scope.options.rowClass : function () { return; };
            $scope.options.parent = typeof $scope.options.parent == 'function' ? $scope.options.parent() : $scope.options.parent;
            // additional potential actions
            var detailsAction = $scope.options.detailsAction || {
                setProperties: ACTIONS.DETAILS_VIEW,
                condition: function () {
                    return true;
                },
                click: function (item) {
                    $scope.itemDetails(item);
                }
            }
            $scope.options.actions.push(editAction);

            if ($scope.options.hasDetails) {
                if ($scope.options.detailsIcon)
                    detailsAction.icon = $scope.options.detailsIcon;
                $scope.options.actions.push(detailsAction);
            }

            var toggleAction = {};

            if ($scope.options.hasToggle) {
                for (var key in $scope.options.toggleAction) {
                    if ($scope.options.toggleAction.hasOwnProperty(key))
                        toggleAction[key] = $scope.options.toggleAction[key];
                }
                if ($scope.options.toggleIcon)
                    toggleAction.icon = $scope.options.toggleIcon;
                $scope.options.actions.push(toggleAction);
            }

            if ($scope.options.middleActions && $scope.options.middleActions.length > 0) {
                for (var i = 0; i < $scope.options.middleActions.length; i++) {
                    $scope.options.actions.push($scope.options.middleActions[i]);
                }
            }

            $scope.options.actions.push(deleteAction);

            $scope.options.formActions = [
                {
                    setProperties: ACTIONS.SAVE,
                    condition: function () {
                        return true;
                    },
                    click: function (item, parent) {
                        $scope.save_or_delete(item, parent);
                    }
                },
                {
                    setProperties: ACTIONS.CANCEL,
                    condition: function () {
                        return true;
                    },
                    click: function (item, parent) {
                        $scope.toggleEditMode(item, parent);
                    }
                }
            ];
            if ($scope.options.additionalFormAction) {
                $scope.options.formActions.splice(1, 0, $scope.options.additionalFormAction);
            }
            $scope.options.deleteActions = [
                {
                    setProperties: ACTIONS.SAVE,
                    condition: function (item) {
                        return true;
                    },
                    click: function (item, parent) {
                        $scope.save_or_delete(item, parent, true);
                    }
                },
                {
                    setProperties: ACTIONS.CANCEL,
                    condition: function () {
                        return true;
                    },
                    click: function (item, parent) {
                        $scope.toggleDelete(item, parent);
                    }
                }
            ];

            var actions = ["actions", "formActions", "deleteActions"];
            for (var z = 0; z < actions.length; z++) {
                if ($scope.options[actions[z]] && $scope.options[actions[z]].length) {
                    for (var i = 0; i < $scope.options[actions[z]].length; i++) {
                        for (var key in $scope.options[actions[z]][i].setProperties) {
                            if ($scope.options[actions[z]][i].setProperties.hasOwnProperty(key)) {
                                $scope.options[actions[z]][i][key] = $scope.options[actions[z]][i][key]
                                    ? $scope.options[actions[z]][i][key] : $scope.options[actions[z]][i].setProperties[key];
                            }
                        }
                        if ($scope.options[actions[z]][i].isSecondary == 'undefined')
                            $scope.options[actions[z]][i].isSecondary = function () { return true; };
                    }
                }
            }

            $scope.addItem = function (parent) {
                $scope.options.parent.isBusy = true;
                $scope.showItem = true;
                parent.newItem = $scope.options.addButton.click(parent);
            }

            $scope.addItem_menu = function (item, parent) {
                if (!$scope.options.keepParentBusyTillClose) $scope.options.parent.isBusy = !$scope.options.parent.isBusy;
                parent.isBusy = true;
                $scope.showItem = true;
                parent.newItem = item.click(parent, item);
            }

            $scope.itemDetails = function (item) {
                item.init();
                $scope.parent.currentItem = item;
            }

            $scope.searchTerm = { filter: "" };
            // item actions
            $scope.toggleEditMode = function (item, parent) {
                if (!$scope.options.keepParentBusyTillClose) $scope.options.parent.isBusy = false;
                parent.isBusy = false;
                if (typeof item.properties[listItemId] == 'undefined' || item.properties[listItemId] == null) {
                    delete item.form;
                    $scope.showItem = false;
                    parent.newItem = null;
                    return;
                }

                if (item.form) {
                    if (!item.isModified) {
                        item.form.restore_Data();
                    }
                    delete item.form;
                    if ($scope.options.getListAction) {
                        parent[$scope.options.getListAction]();

                    }
                    if (!$scope.options.keepParentBusyTillClose) $scope.options.parent.isBusy = false;
                } else {
                    if (!$scope.options.keepParentBusyTillClose) $scope.options.parent.isBusy = true;
                    parent.isBusy = true;
                    item.createForm();
                }
            }

            $scope.toggleDelete = function (item, parent) {
                if (!$scope.options.keepParentBusyTillClose) $scope.options.parent.isBusy = !$scope.options.parent.isBusy;
                else parent.isBusy = !parent.isBusy;

                item.deleteConfirm = !item.deleteConfirm;
                item.deletingMsg = "";
            }

            $scope.options.showContractFields = function (options) {

                typeof parent[$scope.options.show_contract_fields] != 'undefined'
                    ? parent[$scope.options.show_contract_fields](null, options.refreshWithContractFields)
                    : $scope.options.parent[$scope.options.show_contract_fields](null, options.refreshWithContractFields);
            }

            $scope.save_or_delete = function (item, parent, isDelete) {
                // console.error('parent', parent);
                if (item.properties.iffy && isDelete) {
                    Message.warning($scope.options.iffyMessage);
                    $scope.options.parent.isBusy = false;
                    item.deleteConfirm = false;
                    return;
                }
                var isNew = item.properties[listItemId] ? false : true;

                var action = typeof parent[$scope.options.save_or_delete] != 'undefined'
                    ? parent[$scope.options.save_or_delete](item, isDelete)
                    : $scope.options.parent[$scope.options.save_or_delete](item, isDelete);

                action
                    .then(function (r) {
                        if (isNew && $scope.options.hasDetails && !isDelete) {
                            $scope.itemDetails(parent.newItem);

                        }
                        $scope.showItem = false;
                        parent.newItem = null;
                        //if (!$scope.options.keepFormOnSave || !item.properties.id || isDelete) {
                        //    $scope.options.parent.isBusy = !$scope.options.parent.isBusy;

                        //}
                        if ((isNew || isDelete) && !$scope.options.keepParentBusyTillClose) $scope.options.parent.isBusy = false;
                        parent.isBusy = false;

                        parent.listExpanded = true;
                        // console.log('PARENT', parent, $scope.options.parent, $scope.options.parent())

                        if ($scope.options.modifiesParent)
                            $scope.options.parent.isModified = true;

                        $scope.verifyPagination();
                    })
                    .catch(function () { })
                    .finally(function () { })
            }

            $scope.verifyPagination = function () {
                // If we delete or update the list that will delete the last items and we are o last page and the last page contained only the deleted item, we will remain on the last page that will not contains any values
                // So we need to move to the last page that has values
                if ($scope.list && $scope.pagination && $scope.pagination.pageStart >= $scope.list.length) {
                    $scope.pagination = { pageStart: $scope.pagination.pageStart - ($scope.step * Math.floor($scope.list.length / $scope.step)) };
                    $scope.paginationEnd = { pageEnd: $scope.pagination.pageStart + $scope.step };
                }
            }

            $scope.changeOrder = function (item, parent, isDown) {
                var i = $scope.parent[$scope.options.listKey].indexOf(item);
                parent[$scope.options.listKey].splice(i, 1);
                if (!isDown) parent[$scope.options.listKey].splice(i - 1, 0, item);
                else parent[$scope.options.listKey].splice(i + 1, 0, item);
                parent[$scope.options.orderAction]()
                    .then(function () { })
                    .catch(function () {
                        parent[$scope.options.listKey].splice(parent[$scope.options.listKey].indexOf(item), 1);
                        if (!isDown) parent[$scope.options.listKey].splice(i - 1, 0, item);
                        else parent[$scope.options.listKey].splice(i + 1, 0, item);
                    })
            }
        }
    }
});