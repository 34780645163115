<div class="autocomplete-field">
  <div class="address-input">
    <mat-form-field class="form-field">
      <input #stateInput (keyup)="0" matInput placeholder="Address" aria-label="Address" [matAutocomplete]="auto"
        [formControl]="addressFormControl" (keyup)="onKey($event)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
        [displayWith]="getAddressText">
        <mat-option *ngFor="let addressResult of addressResults$ | async" [value]="addressResult">
          <span>{{ addressResult.address.freeformAddress }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-icon-button aria-label="Expand for more options, see the map" (click)="openMaps()">
      <mat-icon>location_on</mat-icon>
    </button>
  </div>
  <div *ngIf="selectedPosition" class="map-container">
    <mat-expansion-panel [expanded]="selectedPosition && mapsOpened" class="azure-map-expansion-panel" *ngIf="selectedPosition">
      <app-azure-map [initialCoordinates]="selectedPosition" (coordinatesChanged)="handleCoordinatesChanged($event)">
      </app-azure-map>
    </mat-expansion-panel>
  </div>
</div>