import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  ViewChild,
  signal,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';

import {
  ActionEventType,
  CipoTableOptions,
  ColumnPriorityType,
  DisplayFormats,
  TableColumn,
  TablePaginator,
  TableRow,
  DataListScrollStrategy,
} from './common';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, SCREEN_SIZE } from '../../../shared/consts';

const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 34;

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useClass: DataListScrollStrategy,
    },
  ],
})
export class DataListComponent<T = TableRow> implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<T> = new MatTableDataSource();
  selection = new SelectionModel<T>(true, []);
  options: CipoTableOptions = {};
  deviceWidth = window.innerWidth;
  displayedColumns = [];
  totalWidth: number = 0;
  loading = true;
  serverPagination: TablePaginator;
  hasLeftIcons = false;
  hasRightIcons = false;

  displayFormat = DisplayFormats;

  @Input('tablecolumns') tableColumns: TableColumn[] = [];
  @Input('tableoptions')
  set tableOptions(options: CipoTableOptions) {
    if (options.serverPaginationSort && options.pagination) {
      this.setServerPaginatorOptions(options.pagination);
    }
    this.options = options;
  }

  @Input('loading')
  set _loading(loading: boolean) {
    if (loading) this.populateSkeleton();
  }

  @Input('tabledata')
  set tableData(rows: T[]) {
    if (rows === undefined) return;
    if (!this.dataSource?.data) {
      this.dataSource = new MatTableDataSource(rows);
    } else {
      this.dataSource.data = rows;
    }
    this.checkLeftRightIcons(rows);
    this.loading = false;
  }

  private checkLeftRightIcons(rows: T[]) {
    if (!this.options?.actions) {
      return;
    }
    (rows || []).forEach(row => {
      (this.options.actions(row as TableRow) || []).forEach(({ leftIcon, rightIcon }) => {
        if (leftIcon) {
          this.hasLeftIcons = true;
        }
        if (rightIcon) {
          this.hasRightIcons = true;
        }
      });
    });
  }

  @Output('selectionchanged') selectionChanged = new EventEmitter<T[]>();
  @Output('actionselected') actionSelected = new EventEmitter<ActionEventType<number, T>>();
  @Output('paginationupdated') paginationUpdated = new EventEmitter<PageEvent>();
  @Output('sortchanged') sortChanged = new EventEmitter<Sort>();
  @Output('rowclicked') rowClicked = new EventEmitter<T>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('clientPaginator') paginator: MatPaginator;

  ngOnInit(): void {
    this.initialiseTableData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    if (this.options.pagination) {
      if (this.options.serverPaginationSort) {
        this.setServerPaginatorOptions(this.options.pagination);
      } else {
        const { pageSize, pageSizeOptions } = this.options.pagination;
        this.paginator.pageSize = pageSize;
        this.paginator.pageSizeOptions = pageSizeOptions;
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  setServerPaginatorOptions({ length, pageIndex, pageSize, pageSizeOptions }: TablePaginator) {
    this.serverPagination = {
      length: length ?? PAGE_SIZE_OPTIONS.default[1],
      pageIndex: pageIndex ?? 0,
      pageSize: pageSize ?? PAGE_SIZE_OPTIONS.default[1],
      pageSizeOptions: pageSizeOptions ?? PAGE_SIZE_OPTIONS.default,
    };
  }

  initialiseTableData() {
    // if at least one column has priority, filter columns by priority
    const hasPriority = this.tableColumns.findIndex(col => col.hasOwnProperty('priority'));

    if (hasPriority >= 0) {
      const acceptedPrios = this.getAcceptedPriorities();

      this.displayedColumns = this.tableColumns
        .map(c => {
          if (acceptedPrios.includes(c.priority)) {
            this.totalWidth += c.width ?? 0;
            return c.name;
          }
          return null;
        })
        .filter(c => !!c);
    } else {
      this.displayedColumns = this.tableColumns.map(col => {
        this.totalWidth += col.width ?? 1;
        return col.name;
      });
    }

    if (this.options?.selectable) {
      this.totalWidth += 0.5;
      this.displayedColumns.unshift('select');
    }

    if (this.options?.actions) {
      this.totalWidth += 0.5;
      this.displayedColumns.push('actions');
    }
  }

  getAcceptedPriorities() {
    const acceptedPriorities: ColumnPriorityType[] = [];
    if (this.deviceWidth > SCREEN_SIZE.phone && this.deviceWidth < SCREEN_SIZE.tablet) {
      acceptedPriorities.push(2, 3);
    }

    if (this.deviceWidth < SCREEN_SIZE.phone) {
      acceptedPriorities.push(1, 3);
    }

    if (this.deviceWidth > SCREEN_SIZE.tablet) {
      acceptedPriorities.push(0, 1, 2, 3);
    }

    return acceptedPriorities;
  }

  selectAction(actionId: number, row: T) {
    this.actionSelected.emit({ actionId, row });
  }

  checked() {
    this.selectionChanged.emit(this.selection.selected);
  }

  rowClick(row: T) {
    this.rowClicked.emit(row);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  tableSortChanged(event: Sort) {
    if (this.options?.serverPaginationSort) {
      this.loading = true;
      this.populateSkeleton();
      this.sortChanged.emit(event);
    }
  }

  paginationChange(event: PageEvent) {
    this.loading = true;
    this.populateSkeleton();
    this.paginationUpdated.emit(event);
  }

  populateSkeleton() {
    if (this.dataSource.data.length) {
      this.dataSource.data = this.dataSource.data.map(row => {
        row['empty'] = true;
        return row;
      });
    } else {
      const pageSize = this.options?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE;
      const data: T[] = [];
      for (let i = 0; i < pageSize; i++) {
        data.push({ empty: true } as T);
      }
      this.dataSource.data = data;
    }
  }
}
