import { KeyValueType } from 'src/app/models/common';
import { GenericObjectType } from 'src/app/models/helpers';

export const MockMentions: KeyValueType[] = [
  { key: 'firstUser', value: 'First User' },
  { key: 'firstUse', value: 'First Use' },
  { key: 'secondUser', value: 'Second User' },
  { key: 'thirdUser', value: 'Third User' },
  { key: 'fourthUser', value: 'Fourth User' },
];

export const MockVariables: GenericObjectType = {
  mockdef: 'asd',
  SubmitId: 'Submit ? Id',
  SubmitName: 'Submit - Name',
  SubmitUserName: 'Submit - User Name',
  SubmitUserRoleName: 'Submit - User Role Name',
  SubmitUserRoleAbbreviation: 'Submit - User Role Abbreviation',
  SubmitUserOrganizationName: 'Submit - User Organization Name',
  SubmitUserOrganizationVendorNo: 'Submit - User Organization Vendor No',
  SubmitDate: 'Submit - Date',
  SubmitDateTime: 'Submit - Date Time',
  ApproverName: 'Approver Name',
  ApproverComment: 'Approver Comment',
  ModuleName: 'Module - Name',
  ModuleDescription: 'Module - Description',
  ModuleAbbreviation: 'Module - Abbreviation',
  ModuleDocumentNumber: 'Module - Document Number',
  ContractContractor: 'Contract - Contractor',
  ContractContractorVendorNo: 'Contract - Contractor Vendor No',
  ContractorAddress: 'Contractor Address',
  ContractId: 'Contract - Id',
  ContractName: 'Contract - Name',
  ContractNumber: 'Contract - Number',
  ContractCostCenter: 'Contract - Cost Center',
  ContractWorkOrder: 'Contract - Work Order',
  ContractStatus: 'Contract - Status',
  ProjectId: 'Project - Id',
  ProjectName: 'Project - Name',
  ProjectNumber: 'Project - Number',
  CurrentDate: 'Current Date',
  CountAttachmentsStartScreen: 'Count Of Attachments In The Start Screen',
  CurrentStateId: 'Current State - Id',
  CurrentStateName: 'Current State - Name',
  CurrentStateType: 'Current State - Type',
  CurrentStateUserName: 'Current State - Initiator',
  CurrentStateInCourtOfName: 'Current State - In court of - Name',
  CurrentStateInCourtOfAbbr: 'Current State - In court of - Abbreviation',
  CurrentTransitionName: 'Current Transition - Name',
  CurrentTransitionCreatedBy: 'Current Transition - Created By',
  CurrentTransitionCreatedOn: 'Current Transition - Created On',
  TemplateDescription: 'Template Description',
  TemplateName: 'Template Name',
  DocumentUrl: 'Document Url',
  DocumentUrlHere: 'Document Url Here',
  DocumentInitiator: 'Document Initiator',
  TenantName: 'Tenant Name',
  TenantAddress: 'Tenant Address',
  TenantLogo: 'Tenant Logo',
  DueDate: 'Due Date',
  BindingGroup3SignerSignature: 'Binding Group CA Approval Signer - Signature',
  BindingGroup3SignerName: 'Binding Group CA Approval Signer - Name',
  Group3SignerSignature: 'Group CA Approval Signer - Signature',
  Group3SignerName: 'Group CA Approval Signer - Name',
  Group3SignerDate: 'Group CA Approval Signer - Date',
  'Role-2Role Name': 'Role CIPO Admin - Role Name',
  'Role-2Role Abbreviation': 'Role CIPO Admin - Role Abbreviation',
  'Role-2PrimaryUserName': 'Role CIPO Admin - Primary User Name',
  'Role-2PrimaryUserOrganization': 'Role CIPO Admin - Primary User Organization',
  'Role-2PrimaryUserOrganizationAddress': 'Role CIPO Admin - Primary User Organization Address',
  'Role1Role Name': 'Role Admin - Role Name',
  'Role1Role Abbreviation': 'Role Admin - Role Abbreviation',
  Role1PrimaryUserName: 'Role Admin - Primary User Name',
  Role1PrimaryUserOrganization: 'Role Admin - Primary User Organization',
  Role1PrimaryUserOrganizationAddress: 'Role Admin - Primary User Organization Address',
  'Role3Role Name': 'Role Assistant General Manager - Role Name',
  'Role3Role Abbreviation': 'Role Assistant General Manager - Role Abbreviation',
  Role3PrimaryUserName: 'Role Assistant General Manager - Primary User Name',
  Role3PrimaryUserOrganization: 'Role Assistant General Manager - Primary User Organization',
  Role3PrimaryUserOrganizationAddress: 'Role Assistant General Manager - Primary User Organization Address',
  'Role10Role Name': 'Role General Manager - Role Name',
  'Role10Role Abbreviation': 'Role General Manager - Role Abbreviation',
  Role10PrimaryUserName: 'Role General Manager - Primary User Name',
  Role10PrimaryUserOrganization: 'Role General Manager - Primary User Organization',
  Role10PrimaryUserOrganizationAddress: 'Role General Manager - Primary User Organization Address',
  'Role12Role Name': 'Role Inspector - Role Name',
  'Role12Role Abbreviation': 'Role Inspector - Role Abbreviation',
  Role12PrimaryUserName: 'Role Inspector - Primary User Name',
  Role12PrimaryUserOrganization: 'Role Inspector - Primary User Organization',
  Role12PrimaryUserOrganizationAddress: 'Role Inspector - Primary User Organization Address',
  'Role14Role Name': 'Role Principal Engineer - Role Name',
  'Role14Role Abbreviation': 'Role Principal Engineer - Role Abbreviation',
  Role14PrimaryUserName: 'Role Principal Engineer - Primary User Name',
  Role14PrimaryUserOrganization: 'Role Principal Engineer - Primary User Organization',
  Role14PrimaryUserOrganizationAddress: 'Role Principal Engineer - Primary User Organization Address',
  'Role43Role Name': 'Role Viewer - Role Name',
  'Role43Role Abbreviation': 'Role Viewer - Role Abbreviation',
  Role43PrimaryUserName: 'Role Viewer - Primary User Name',
  Role43PrimaryUserOrganization: 'Role Viewer - Primary User Organization',
  Role43PrimaryUserOrganizationAddress: 'Role Viewer - Primary User Organization Address',
  'Role122Role Name': 'Role Director of Field Engineering - Role Name',
  'Role122Role Abbreviation': 'Role Director of Field Engineering - Role Abbreviation',
  Role122PrimaryUserName: 'Role Director of Field Engineering - Primary User Name',
  Role122PrimaryUserOrganization: 'Role Director of Field Engineering - Primary User Organization',
  Role122PrimaryUserOrganizationAddress: 'Role Director of Field Engineering - Primary User Organization Address',
  'Role123Role Name': 'Role Construction Administrator Representative - Role Name',
  'Role123Role Abbreviation': 'Role Construction Administrator Representative - Role Abbreviation',
  Role123PrimaryUserName: 'Role Construction Administrator Representative - Primary User Name',
  Role123PrimaryUserOrganization: 'Role Construction Administrator Representative - Primary User Organization',
  Role123PrimaryUserOrganizationAddress:
    'Role Construction Administrator Representative - Primary User Organization Address',
  'Role124Role Name': 'Role Construction Administrator - Role Name',
  'Role124Role Abbreviation': 'Role Construction Administrator - Role Abbreviation',
  Role124PrimaryUserName: 'Role Construction Administrator - Primary User Name',
  Role124PrimaryUserOrganization: 'Role Construction Administrator - Primary User Organization',
  Role124PrimaryUserOrganizationAddress: 'Role Construction Administrator - Primary User Organization Address',
  'Role125Role Name': 'Role General Contractor - Role Name',
  'Role125Role Abbreviation': 'Role General Contractor - Role Abbreviation',
  Role125PrimaryUserName: 'Role General Contractor - Primary User Name',
  Role125PrimaryUserOrganization: 'Role General Contractor - Primary User Organization',
  Role125PrimaryUserOrganizationAddress: 'Role General Contractor - Primary User Organization Address',
  'Role127Role Name': 'Role Contractors (SYS) - Role Name',
  'Role127Role Abbreviation': 'Role Contractors (SYS) - Role Abbreviation',
  Role127PrimaryUserName: 'Role Contractors (SYS) - Primary User Name',
  Role127PrimaryUserOrganization: 'Role Contractors (SYS) - Primary User Organization',
  Role127PrimaryUserOrganizationAddress: 'Role Contractors (SYS) - Primary User Organization Address',
  'Role131Role Name': 'Role Engineer - Role Name',
  'Role131Role Abbreviation': 'Role Engineer - Role Abbreviation',
  Role131PrimaryUserName: 'Role Engineer - Primary User Name',
  Role131PrimaryUserOrganization: 'Role Engineer - Primary User Organization',
  Role131PrimaryUserOrganizationAddress: 'Role Engineer - Primary User Organization Address',
  'Role132Role Name': 'Role Director of Engineering - Role Name',
  'Role132Role Abbreviation': 'Role Director of Engineering - Role Abbreviation',
  Role132PrimaryUserName: 'Role Director of Engineering - Primary User Name',
  Role132PrimaryUserOrganization: 'Role Director of Engineering - Primary User Organization',
  Role132PrimaryUserOrganizationAddress: 'Role Director of Engineering - Primary User Organization Address',
  'Role135Role Name': 'Role Inspector Supervisor - Role Name',
  'Role135Role Abbreviation': 'Role Inspector Supervisor - Role Abbreviation',
  Role135PrimaryUserName: 'Role Inspector Supervisor - Primary User Name',
  Role135PrimaryUserOrganization: 'Role Inspector Supervisor - Primary User Organization',
  Role135PrimaryUserOrganizationAddress: 'Role Inspector Supervisor - Primary User Organization Address',
  'Role138Role Name': 'Role Inspection Manager - Role Name',
  'Role138Role Abbreviation': 'Role Inspection Manager - Role Abbreviation',
  Role138PrimaryUserName: 'Role Inspection Manager - Primary User Name',
  Role138PrimaryUserOrganization: 'Role Inspection Manager - Primary User Organization',
  Role138PrimaryUserOrganizationAddress: 'Role Inspection Manager - Primary User Organization Address',
  'Role139Role Name': 'Role Project Manager - Role Name',
  'Role139Role Abbreviation': 'Role Project Manager - Role Abbreviation',
  Role139PrimaryUserName: 'Role Project Manager - Primary User Name',
  Role139PrimaryUserOrganization: 'Role Project Manager - Primary User Organization',
  Role139PrimaryUserOrganizationAddress: 'Role Project Manager - Primary User Organization Address',
  'Role143Role Name': 'Role Dev Admin - Role Name',
  'Role143Role Abbreviation': 'Role Dev Admin - Role Abbreviation',
  Role143PrimaryUserName: 'Role Dev Admin - Primary User Name',
  Role143PrimaryUserOrganization: 'Role Dev Admin - Primary User Organization',
  Role143PrimaryUserOrganizationAddress: 'Role Dev Admin - Primary User Organization Address',
  'Role144Role Name': 'Role Construction Project Administration Supervisor - Role Name',
  'Role144Role Abbreviation': 'Role Construction Project Administration Supervisor - Role Abbreviation',
  Role144PrimaryUserName: 'Role Construction Project Administration Supervisor - Primary User Name',
  Role144PrimaryUserOrganization: 'Role Construction Project Administration Supervisor - Primary User Organization',
  Role144PrimaryUserOrganizationAddress:
    'Role Construction Project Administration Supervisor - Primary User Organization Address',
  'Role145Role Name': 'Role Construction Project Administration Manager - Role Name',
  'Role145Role Abbreviation': 'Role Construction Project Administration Manager - Role Abbreviation',
  Role145PrimaryUserName: 'Role Construction Project Administration Manager - Primary User Name',
  Role145PrimaryUserOrganization: 'Role Construction Project Administration Manager - Primary User Organization',
  Role145PrimaryUserOrganizationAddress:
    'Role Construction Project Administration Manager - Primary User Organization Address',
  'Role146Role Name': 'Role Electrical Supervisor - Role Name',
  'Role146Role Abbreviation': 'Role Electrical Supervisor - Role Abbreviation',
  Role146PrimaryUserName: 'Role Electrical Supervisor - Primary User Name',
  Role146PrimaryUserOrganization: 'Role Electrical Supervisor - Primary User Organization',
  Role146PrimaryUserOrganizationAddress: 'Role Electrical Supervisor - Primary User Organization Address',
  'Role147Role Name': 'Role Safety, Risk and Emergency Management - Role Name',
  'Role147Role Abbreviation': 'Role Safety, Risk and Emergency Management - Role Abbreviation',
  Role147PrimaryUserName: 'Role Safety, Risk and Emergency Management - Primary User Name',
  Role147PrimaryUserOrganization: 'Role Safety, Risk and Emergency Management - Primary User Organization',
};
