import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { SystemFieldsEnum } from '../../../models/module/fields/enums';
import { FieldModel, ScreenFieldModel } from '../../../models/module/fields/main';
import { GridViewModel } from '../../../models/module/grid';
import { ScreenModel } from '../../../models/module/screen';
import {
  DemoCardSettingsFieldSelect,
  DemoCardSettingsFieldState,
  DemoCardSettingsFieldText,
  DemoCardSettingsFieldYesNo,
} from '../../data/demoCardSettings';

@Injectable()
export class DemoDataCardSettingsService {
  getFields(screen: ScreenModel): Observable<GridViewModel<FieldModel[]>> {
    return of({
      records: 1,
      data: [DemoCardSettingsFieldText, DemoCardSettingsFieldYesNo, DemoCardSettingsFieldSelect],
    }).pipe(delay(100));
  }

  getField(id: number): Observable<FieldModel> {
    const field = id == SystemFieldsEnum.state_name ? DemoCardSettingsFieldState : undefined;
    return of(field).pipe(delay(100));
  }

  getScreenFields(screen: ScreenModel): Observable<GridViewModel<ScreenFieldModel[]>> {
    return of({
      records: 0,
      data: [],
    }).pipe(delay(100));
  }

  syncScreenFields(screen: ScreenModel, screenFields: ScreenFieldModel[]): Observable<void> {
    return of();
  }
}
