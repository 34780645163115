import { cipo } from 'cipo';

cipo.factory("PropField", function (Model, Form, URI, $q, Message) {
    var PropField = Model.extend(function (obj, isTable) {
        var self = this;
        self.isTable = isTable || false;
        self.properties = {name: ""};
        // self.numericFieldsDict = numericFieldsDict || [];
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }

        
        // console.error('rename form', self)
    });

    PropField.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        var grid = [];
        var form = {};
        self.form.initializing = true;
        form.name = { label: 'Field Default Label', type: 'text', validation: { required: true, maxChars: 100 } };
        grid.push({ name: 100 });
        if (self.properties.fieldTypeId == 17) {
            
            form.fieldExpression = {
                label: 'Field Formula', type: 'editor', noToolbarEditor: true, options: { scaytAutoload: false, spellcheck: false, heightMin: 60 }, hints: self.numericFieldsDict
            };
            grid.push({ fieldExpression: 100 });
        }
        self.form.set_Description(form);
        self.form.setTemplate('grid', grid);
        self.form.initializing = false;
        self.form.store_Data();
    }

    PropField.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = self.isTable ? URI.SCREENS.FIELD_DELETE : URI.SCREENS.PROP_FIELDS_DELETE;
        self.deleting = true;
        self.deletingMsg = "Deleting field...";
        self[dataURL.method](dataURL, { url: { id: self.properties.fieldId }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function () {
                Message.info((self.properties.isEntityProperty ? 'Module' : 'Form') + ' field deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
                self.deletingMsg = "Failed to delete field. Try again?";
                self.deleting = false;
                Message.dberror(e);
            })
            .finally(function () { });
        return p.promise;
    }
    PropField.prototype.save = function (screenId) {
        var self = this;
        var p = $q.defer();
        var dataURL;
        var params;
        if (self.isTable || !self.properties.isEntityProperty) {
            dataURL = !self.properties.fieldId ? URI.SCREENS.FIELD_CREATE : URI.SCREENS.FIELD_UPDATE;
            params = { url: { screenId: screenId }, urltype: 'obj', body: self.properties };
        } else {
            dataURL = !self.properties.fieldId ? URI.SCREENS.PROP_FIELDS_CREATE : URI.SCREENS.PROP_FIELDS_UPDATE;
        }

        self[dataURL.method](dataURL, params || self.properties, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                p.resolve(r);
                Message.info((self.properties.isEntityProperty ? 'Module' : 'Form') + ' field saved successfully');
            })
            .catch(function (e) {
                console.error(e);
                //Message.dberror(e);
                p.reject(e);

            })
            .finally(function () { });
        return p.promise;
    }

    return PropField;
});
