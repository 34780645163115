<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "Edit Form Structure" }}</p>
    <button mat-icon-button [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <h2>Title here</h2>
    <p>Description</p>
    <app-form-builder />
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">{{ "general.cancel" | translate }}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="false" (click)="onSave()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
