import { createReducer } from '@ngrx/store';
import { ContextEffects } from './context';
import { contextStateInitial } from './context/context.model';
import { contextReducers } from './context/context.reducers';
import { CoreState } from './interfaces';
import { MenuEffects, menuReducers, menuStateInitial } from './menu';
import { userInitialState, userReducers } from './user';
import { tenantReducers, tenantStateInitial } from './tenant';
import { TenantEffects } from './tenant/tenant.effects';

export const coreStateInitial: CoreState = {
  context: contextStateInitial,
  user: userInitialState,
  menu: menuStateInitial,
  tenant: tenantStateInitial,
};

export const CoreReducers = {
  core: createReducer(coreStateInitial, ...contextReducers, ...menuReducers, ...userReducers, ...tenantReducers),
};

export const CoreEffects = [ContextEffects, TenantEffects, MenuEffects];
