@if (control()) {
    <label [class.cipo-text--warn-900]="control().dirty && control().invalid">
        {{ control().fieldData.label }}
        @if (control().fieldData.required) {
            <span>*</span>
        }
    </label>
}
@if (tableColumns().length) {
    <table
        #table
        mat-table
        [dataSource]="tableForm().controls"
        class="cipo-table"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        cdkDropListData="dataSource">
        @for (column of tableColumns(); track $index) {
            @switch (column) {
                @case ("position") {
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef [class.counter]="showposition()">
                            {{ showposition() ? "No." : "" }}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="4px">
                                <mat-icon class="cursor--move">drag_indicator</mat-icon>
                                @if (showposition()) {
                                    <span>{{ i + 1 }}</span>
                                }
                            </div>
                        </td>
                    </ng-container>
                }
                @case ("action") {
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            @if (tableForm().controls.length <= 1) {
                                <button mat-icon-button color="primary" (click)="insertRow()">
                                    <mat-icon (click)="insertRow()">add</mat-icon>
                                </button>
                            }
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <div fxLayout fxLayoutAlign="end center">
                                <button mat-icon-button (click)="editRow(i)">
                                    <mat-icon>edit</mat-icon>
                                </button>

                                @if (i === tableForm().controls.length - 1 && i !== 0) {
                                    <button mat-icon-button color="primary" (click)="insertRow()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                } @else {
                                    <button mat-icon-button color="warn" (click)="deleteRow(i)">
                                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                    </button>
                                }
                            </div>
                        </td>
                    </ng-container>
                }
                @default {
                    <ng-container [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef>
                            <div fxLayout fxLayoutAlign="space-between center">
                                {{ columnsDictionary()[column] }}
                                @if (tableFields()[$index - 1].fieldDescription) {
                                    <mat-icon
                                        [title]="tableFields()[$index - 1].fieldDescription"
                                        class="material-symbols-outlined">
                                        info
                                    </mat-icon>
                                }
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <div class="m-t-md">
                                <cipo-control [control]="$any(tableForm().controls[i].controls[column])" />
                            </div>
                        </td>
                    </ng-container>
                }
            }
        }

        <tr mat-header-row *matHeaderRowDef="tableColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns()" cdkDrag [cdkDragData]="row"></tr>
    </table>
}
