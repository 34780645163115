import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridsterConfig } from 'angular-gridster2';
import { DataCard } from '../../../../models/module/main/dataCardView';
import { DataListViewAction } from '../../../../models/module/main/dataListViewAction';

@Component({
  selector: 'app-data-card-view',
  templateUrl: './data-card-view.component.html',
  styleUrls: ['./data-card-view.component.scss'],
})
export class DataCardViewComponent implements OnInit, OnChanges {
  constructor(private ref: ChangeDetectorRef) {}

  @Input() card: DataCard;
  @Input() actions: DataListViewAction[];

  actionsFiltered: DataListViewAction[];

  ngOnInit(): void {
    this.ref.markForCheck();
  }

  ngOnChanges(_: SimpleChanges): void {
    if ((this.actions || []).length && this.card !== undefined) {
      this.actionsFiltered = (this.actions || []).filter(
        a => a.alwaysOnRow === true && a.condition !== undefined && a.condition(this.card),
      );
    } else {
      this.actionsFiltered = [];
    }
  }

  cardActionsMenuClick($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  cardActionClick(action: DataListViewAction) {
    action.click && action.click(this.card);
  }

  gristerOptions: GridsterConfig = {
    gridType: 'verticalFixed',
    fixedRowHeight: 24,
    displayGrid: 'none',
    outerMargin: false,
    margin: 4,
    mobileBreakpoint: 1,
    setGridSize: true,
  };
}
