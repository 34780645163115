import { Component, Input, OnInit, Output, EventEmitter, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { Position } from '../address-autocomplete/models/position.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-azure-map',
  templateUrl: './azure-map.component.html',
  styleUrls: ['./azure-map.component.scss']
})
export class AzureMapComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() initialCoordinates: Position;
  @Output() coordinatesChanged = new EventEmitter<atlas.data.Position>();

  private map: atlas.Map;
  private pin: atlas.HtmlMarker;
  
  mapId: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.mapId = this.uniqueId();
  }

  ngAfterViewInit(): void {
    this.map = new atlas.Map(this.mapId.toString(), {
      center: [this.initialCoordinates.lon, this.initialCoordinates.lat] || [ 45.26126,  22.28432],
      zoom: 12,
      showFeedbackLink: false,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapsSubscriptionKey
      },
    });

    this.map.events.add('ready', () => {
      this.map.markers.add(this.pin);
    })

    this.pin = new atlas.HtmlMarker({
      position: [this.initialCoordinates.lon, this.initialCoordinates.lat],
      color: 'DodgerBlue',
      draggable: true,
      anchor: 'center'
    });

    this.pin._addEventListener('dragend', (e) => {
      const newPosition = this.pin.getOptions().position;
      // Emit an event with the new position
      this.coordinatesChanged.emit(newPosition);
      this.initialCoordinates = new Position(newPosition[1], newPosition[0]);
      this.updateMapCenter();
    }, false);
  }

  updateMapCenter() {
    const cameraOptions = {
      center: [this.initialCoordinates.lon,this.initialCoordinates.lat], 
    } as atlas.CameraOptions
    this.map.setCamera(cameraOptions)
  }

  ngOnChanges() {
    if(this.pin) {
      this.updateMapCenter();
      this.pin.setOptions({
        position: new atlas.data.Position(this.initialCoordinates.lon, this.initialCoordinates.lat),
        color: 'DodgerBlue',
        draggable: true,
        anchor: 'center'
      })
    }
  }

  uniqueId()  {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  };
}