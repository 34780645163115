import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipInputEvent } from '@angular/material/chips';

import { CipoFormModule } from '../../../shared/modules/forms.module';
import { CipoFieldConfig, CipoFieldTypes, CipoFieldValue } from '../../../shared/components/fields/common';

@Component({
  selector: 'app-add-field',
  templateUrl: './add-field.component.html',
  standalone: true,
  imports: [MatDialogModule, CipoFormModule, TranslateModule],
})
export class AddFieldComponent {
  fb = inject(FormBuilder);
  data = inject<string>(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<AddFieldComponent>);

  FIELD_TYPES_ENUM = CipoFieldTypes;
  fieldTypes = Object.keys(CipoFieldTypes)
    .filter(key => !Number.isNaN(Number(key)))
    .map(key => ({ key: Number(key), value: CipoFieldTypes[key] }));

  selectFieldTypes = [CipoFieldTypes.Listbox, CipoFieldTypes.Select];

  form = this.fb.group({
    name: ['', Validators.required],
    label: [''],
    type: [CipoFieldTypes.Text, Validators.required],
    hint: [''],
    placeholder: [''],
    defaultValue: new FormControl<CipoFieldValue>(null),
    fieldDescription: [''],
    prefix: [''],
    suffix: [''],
    prefixIcon: [''],
    suffixIcon: [''],
    required: [false],
    readonly: [false],
    multiple: [false],
    showThousandSeparator: [false],
    decimals: [<number>null],
    isCurrency: [false],
    multiline: [false],
    maxLength: [<number>null],
    options: new FormControl<string[]>([]),
    columns: [<number>null],
  });

  saveField() {
    const { options, ...props } = this.form.value;
    const { suffix, prefix } = this.form.getRawValue();
    const field: CipoFieldConfig = {
      ...props,
      prefix,
      suffix,
      options: options.map((value, index) => ({ key: value.replace(' ', '') + index, value })),
    };
    this.dialogRef.close(field);
  }

  removeOption(index: number) {
    const list = this.form.value.options;
    list.splice(index, 1);
    this.form.patchValue({ options: list });
  }

  addOption({ chipInput, value }: MatChipInputEvent) {
    if (!value) {
      return;
    }
    const list = this.form.value.options;
    list.push(value);
    chipInput.clear();
    this.form.patchValue({ options: list });
  }
}
