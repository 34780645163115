import { SearchViewModel } from '../../../../models/module/grid';
import {
  ActionEventType,
  ActionType,
  CipoTableOptions,
  TableRow,
} from '../../../../shared/components/data-list/common';
import { CipoCheckboxControl, CipoDateTimeControl, CipoTextControl } from '../../../../shared/components/fields/common';

export interface HolidayModel {
  id: number;
  name: string;
  date: string;
  isWorkDay: boolean;
  permissions: number[];
}

export type HolidayRequestBody = Pick<SearchViewModel, 'criteria' | 'filter' | 'page' | 'pagesize' | 'sort'>;

export type HolidayRow = TableRow & HolidayModel;

export enum HolidayActionsEnum {
  VIEW = 0,
  EDIT = 1,
  DELETE = 2,
}

export type HolidayAction = ActionType<HolidayActionsEnum, HolidayRow>;
export type HolidayActionEvent = ActionEventType<HolidayActionsEnum, HolidayRow>;
export type HolidayTableOptions = CipoTableOptions<HolidayAction>;

export interface HolidayFormGroup {
  name: CipoTextControl;
  date: CipoDateTimeControl;
  isWorkDay: CipoCheckboxControl;
}
