import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AppState } from '../../../../app.state';
import { MeasurementSystem } from '../../../models/module/fields/enums/measurementSystem';
import { TenantSettingsEnum, TenantSettingValue } from '../../../module/instance/tenant-settings/tenant-settings.model';
import { tenantSelectors } from '../../../store/tenant';
import { Measurements, MeasurementsConversion, MeasurementType } from './measurement.model';

@Pipe({
  name: 'measurement',
})
export class MeasurementPipe implements PipeTransform {
  store = inject(Store<AppState>);

  transform(
    value: number | string,
    measurementType: MeasurementType,
    convertFromMeasurementSystem?: keyof typeof MeasurementSystem,
  ): Observable<string> {
    return this.store.select(tenantSelectors.getSettingValue(TenantSettingsEnum.measurement_system)).pipe(
      map((measurementSystemAsTenantSettingValue: TenantSettingValue) => {
        let result = '';
        let measurement = '';
        if (
          typeof measurementSystemAsTenantSettingValue === 'string' ||
          typeof measurementSystemAsTenantSettingValue === 'number'
        ) {
          const measurementSystem =
            typeof measurementSystemAsTenantSettingValue === 'string'
              ? (measurementSystemAsTenantSettingValue as any as keyof typeof MeasurementSystem)
              : (MeasurementSystem[measurementSystemAsTenantSettingValue] as any as keyof typeof MeasurementSystem);
          measurement = Measurements[measurementSystem][measurementType] ?? '';
          if (convertFromMeasurementSystem) {
            value = this.convertValue(value, measurementType, convertFromMeasurementSystem, measurementSystem);
          }
        }
        if (value !== undefined && measurement) {
          result = `${value} ${measurement}`;
        }
        return result;
      }),
    );
  }

  private convertValue(
    value: number | string,
    measurementType: MeasurementType,
    convertFromMeasurementSystem: keyof typeof MeasurementSystem,
    convertToMeasurementSystem: keyof typeof MeasurementSystem,
  ): number | undefined {
    if (Number.isNaN(Number(value))) {
      return undefined;
    }
    value = Number(value);
    if (convertFromMeasurementSystem !== convertToMeasurementSystem) {
      // convert the value to the target measurement system
      value = MeasurementsConversion[convertFromMeasurementSystem][convertToMeasurementSystem][measurementType](value);
    }
    // round the value to 1 decimal place
    return +value.toFixed(1);
  }
}
