<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "fileFolderPermissions.selectPeople" | translate }}</p>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t-md">
        <mat-button-toggle-group [(value)]="allow" (change)="loadAvailablePeople($event.value)" aria-label="Permissions">
            <mat-button-toggle [value]="true">{{ "fileFolderPermissions.allow" | translate }}</mat-button-toggle>
            <mat-button-toggle [value]="false">{{ "fileFolderPermissions.deny" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="p-t-md">
            <cipo-select [control]="listControl" />
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="returnPeople()">{{ "general.save" | translate }}</button>
</mat-dialog-actions>
