import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PdfMarkupModel } from '../../../models/module/pdfMarkup';

@Component({
  selector: 'app-pdf-markup-table',
  templateUrl: './pdf-markup-table.component.html',
  styleUrls: ['./pdf-markup-table.component.scss']
})
export class PdfMarkupTableComponent implements OnInit {

  pdfMarkups: PdfMarkupModel[] = [];
  @Input('entityinstance') entityInstance: any;
  @Output('openpopupevent') openPopupEvent: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns = ["name", "createdBy", "createdOn"];

  ngOnInit(): void {
    this.pdfMarkups = this.entityInstance.pdf_markups;
  }

  openPopup(element: any) {
    this.openPopupEvent.emit(element);
  }

  sortData(sort: Sort) {
    const data = this.pdfMarkups.slice();
    if (!sort.active || sort.direction === '') {
      return;
    }

    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'createdOn':
          return this.compare(a.createdOn.toLocaleString(), b.createdOn.toLocaleString(), isAsc);
        case 'createdBy':
          return this.compare(a.createdBy, b.createdBy, isAsc);
        default:
          return 0;
      }
    });

    this.pdfMarkups = sortedData;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}