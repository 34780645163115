<mat-toolbar class="header-wrapper cipo-bg--primary-100" [class.menu-open]="menuOpened">
    <button
        mat-icon-button
        [class.menu-button-close]="menuOpened"
        aria-label="Menu"
        (click)="menuClick()"
        data-test="cy_sidebar-toggle">
        <mat-icon>{{ menuOpened ? "close" : "menu" }}</mat-icon>
    </button>
    <span class="logo-wrapper icon-Cipo_logo_TOP" (click)="logoClick()">
        <span class="icon-Cipo_logo_BOTTOM"></span>
    </span>
    <div class="context-container">
        <cipo-context-view
            [userId]="userId"
            [context]="context"
            [useOnlyContract]="useOnlyContract"
            [refresh]="refresh"
            (contextChange)="contextChange.emit($event)"
            (contextData)="contextData.emit($event)"></cipo-context-view>
    </div>
    <ng-content select="[header-content]"></ng-content>
</mat-toolbar>
