import { cipo } from 'cipo';
    
cipo.directive("createfielddialog", function () {
    return {
        restrict: "E",
        scope: {
            "screen": "=",
            "operations": "=",
        },
        templateUrl: "/ng/views/directives/tabdetails/createfielddialog.html",
        controller: function ($scope, ACTIONS, Message, List, ListMod, ModuleN, TableMod, StatesMapping, STATES_INFO) {
            //$scope.list = new List($scope.listdata);
            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };


            // lists

            $scope.editList = function (list) {
                $scope.editingList = true;
                $scope.managingLists = false;
                if (list) {
                    if (list.properties.key == $scope.screen.currentField.dataSource) list.properties.inUse = true;
                    //list.get_Details();
                    $scope.currentList = new List({ id: list.properties.key, inUse: list.properties.inUse || false }, true);
                }
                else {
                    $scope.currentList = new List({ inUse: false }, true);
                    //$scope.currentList.createForm();
                }
            }
            $scope.changeSelectedList = function (list) {
                $scope.screen.currentField.dataSourceComposed = list.properties.key + '|' + 0;
                $scope.screen.currentField.dataSource = list.properties.key;
            }
            $scope.saveList = function (list, isStartScreen) {
                if (!list.properties.id) {
                    list.isNew = true;
                }
                var who = $scope.screen;
                var toDoWithTheList = function (list) {
                    Message.info('List saved successfully');

                    //if (list.properties.inUse)
                    if (list.isNew) {
                        //list.properties.id = r;


                    } else {
                        $scope.managingLists = true;
                        $scope.editingList = false;
                        $scope.currentList = null;
                    }
                    who.get_listsDict()
                        .then(function () {
                            //var formupdate = {};
                            //formupdate.dataSourceComposed = { label: 'Select List', type: 'select', options: $scope.screen.listsList, validation: { required: true } };
                            //$scope.screen.editFieldForm.set_Description(formupdate, true);
                            $scope.screen.create_FieldForm($scope.screen.currentField);
                        })
                        .catch(function (e) { })
                        .finally(function () { who.get_Lists(true); })
                    if (list.properties.inUse) {
                        who.currentField.dataSourceComposed = list.properties.id + '|' + 0;
                        who.currentField.dataSource = list.properties.id;
                    } else {
                        if (who.currentField.dataSource == list.properties.id) {
                            who.currentField.dataSource = null;
                            who.currentField.dataSourceComposed = null;

                        }
                    }
                }
                // simulate save for system lists, in order to save the use on current field setting
                if (list.properties.allowModify) {
                    list.save()
                        .then(function (r) {
                            
                            toDoWithTheList(list);

                        })
                        .catch(function (e) {
                            // Message.dberror(e);
                        })
                } else {
                    toDoWithTheList(list);
                }
                
            }
            $scope.manageLists = function (isStartScreen) {
                $scope.managingLists = true;
                var who = $scope.screen;
                who.get_Lists(true);

            }
            $scope.cancelList = function () {
                $scope.editingList = false;
                if ($scope.currentList) $scope.currentList.form.restore_Data();
                if (!$scope.currentList || !$scope.currentList.properties.key) $scope.managingLists = false;
                else $scope.managingLists = true;
                $scope.currentList = null;
            }
            $scope.deleteList = function (list, isStartScreen) {
                var who = $scope.screen;
                list.delete()
                    .then(function () {
                        who.get_Lists(true);
                        who.get_listsDict()
                            .then(function () {
                                //var formupdate = {};
                                //formupdate.dataSourceComposed = { label: 'Select List', type: 'select', options: $scope.screen.listsList, validation: { required: true } };
                                //$scope.screen.editFieldForm.set_Description(formupdate, true);
                                $scope.screen.create_FieldForm($scope.screen.currentField);
                            })
                            .catch(function (e) { });
                    })
                    .catch(function (e) { });
            }


            // mapping

            $scope.workflowMapping = function () {
                $scope.statesMapping = new StatesMapping($scope.screen.moduleId, $scope.screen.currentField.dataSource, $scope.screen.currentField.relationId);
                $scope.screen.currentField.isMapping = true;
            }
            $scope.cancelMapping = function () {
                $scope.screen.currentField.isMapping = false;
            }
            $scope.get_gridSettings = function () {
                $scope.screen.currentField.isGridSettings = true;

            }
            var get_gridSettings = function () {
                if ($scope.screen.currentField.isRelation && $scope.screen.currentField.fieldTypeId != 11) {
                    $scope.screenModule = new ModuleN({ moduleId: $scope.screen.moduleId, relationId: $scope.screen.currentField.relationId }, true);
                    $scope.screenModule.get_GridFields();
                } else {
                    $scope.screenTable = new TableMod($scope.screen.currentField);
                }

                //$scope.screenModule.set_FormulaScreens();
            }
            $scope.closeGridSettings = function () {
                delete $scope.screen.currentField.isGridSettings;
                $scope.screenModule = null;
                $scope.screenTable = null;
            }
            $scope.$watch(function () {
                return $scope.screen.currentField;
            },
                function (n, o) {

                    if ((n || {}).isGridSettings && n.isGridSettings != (o || {}).isGridSettings) {
                        if ($scope.screen.currentField.fieldTypeId != 22) {
                            get_gridSettings();
                            return;
                        }
                        else $scope.submoduleSettings();
                    }
                }, true);

            $scope.submoduleSettings = function () {
                $scope.screen.submodule = new ModuleN({ id: $scope.screen.currentField.dataSource, relationId: $scope.screen.currentField.relationId,  name: $scope.screen.currentField.name }, false, true);
                $scope.screen.currentField.isGridSettings = true;
                $scope.permissionsOptions = {
                    loadingFlag: 'isPermissionsLoaded',
                    listKey: "permissionsList",
                    title: "permissions",
                    hasSearch: true,
                    parent: $scope.screen.submodule,
                    subtitle: {
                        text: "Operations: ",
                        content: function (item) { return item.properties.operationNames; }
                    },
                    addButton: {
                        text: "Add permission",
                        icon: "mdi-eye-plus-outline",
                        condition: function (item) {
                            return true;
                        },
                        click: function () {
                            return $scope.screen.submodule.new_Permission();
                        }
                    },
                    save_or_delete: "permission_Action",
                    operations: $scope.operations
                }
                $scope.screensOptions = {
                    loadingFlag: 'isScreensLoaded',
                    listKey: "screensList",
                    listItemUsed: "inUse",
                    title: "forms",
                    hasSearch: true,
                    parent: $scope.screen.submodule,
                    detailsShowActions: true,
                    disableDeleteCondition: function (item) {
                        if (item.properties.inUse) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    },
                    subtitle: { text: "Type: ", content: function (item) { return "Transition form"; } },
                    addButton: {
                        text: "Add form",
                        icon: "mdi-table-plus",
                        condition: function (item) {
                            return true;
                        },
                        click: function () {
                            return $scope.screen.submodule.new_Screen();
                        }
                    },
                    save_or_delete: "screen_Action",
                    detailsIcon: 'mdi mdi-table-edit',
                    hasDetails: true,
                    type: "screen",
                    operations: $scope.operations

                }
                $scope.fieldsOptions = {
                    loadingFlag: 'isScreensLoaded',
                    listKey: "startscreensList",
                    listItemUsed: "inUse",
                    title: "fields",
                    hasSearch: true,
                    parent: $scope.screen.submodule,
                    detailsShowActions: true,
                    disableDeleteCondition: function (item) { if (item.properties.inUse) return true; else return false; },
                    subtitle: { text: "Type: ", content: function (item) { return "Start Form"; } },
                    addButton: {
                        text: "Add form",
                        icon: "mdi-table-plus",
                        condition: function (item) {
                            return true;
                        },
                        click: function () {
                            return $scope.screen.submodule.new_Screen(true);
                        }
                    },
                    save_or_delete: "screen_Action",
                    detailsIcon: 'mdi mdi-table-edit',
                    hasDetails: true,
                    type: "screen",
                    operations: $scope.operations
                }
                $scope.workflowOptions = {
                    loadingFlag: 'isStatesLoaded',
                    listKey: "statesList",
                    title: "states",
                    hasSearch: true,
                    parent: $scope.screen.submodule,
                    disableDeleteCondition: function (item) { if (item.properties.default) return true; else return false; },
                    block: "statesBlocked",
                    hasOrder: true,
                    orderAction: "sync_States",
                    warningMsg: "No roles are assigned to this module. Please add a permission to be able to continue.",
                    subtitle: {
                        text: "Type: ",
                        content: function (item) { return STATES_INFO().statusNamesLookup[item.properties.type]; }
                    },
                    addButton: {
                        text: "Add state",
                        icon: "mdi-shape-square-plus",
                        condition: function (item) {
                            return true;
                        },
                        click: function (parent) {
                            return parent.new_State();
                        }
                    },
                    save_or_delete: "state_Action",

                    hasChildren: true,
                    children: {
                        isCollapsed: true,
                        hasCounts: true,
                        keepSmallBtn: true,
                        addButton: {
                            text: "Add transition",
                            icon: "mdi mdi-library-plus",
                            condition: function (item) {
                                if (STATES_INFO().haveTransitions.indexOf(item.properties.type.toString()) == -1)
                                    return false;
                                else return true;
                            },
                            click: function (parent) {
                                return parent.new_Transition();
                            }
                        },
                        listKey: "transitions",
                        title: "transitions",
                        hasOrder: true,
                        orderAction: "sync_Transitions",
                        parent: $scope.screen.submodule,
                        disableDeleteCondition: function (item) { if (item.properties.default) return true; else return false; },
                        subtitle: {
                            text: "To state: ",
                            content: function (item) {
                                if ($scope.screen.submodule.lookupStates[item.properties.nextStateId])
                                    return $scope.screen.submodule.lookupStates[item.properties.nextStateId].properties.name;
                                else return "-";
                            }
                        },
                        save_or_delete: "transition_Action",
                        operations: $scope.operations
                    },
                    operations: $scope.operations

                }
                $scope.permissionsTab = function () {
                    if (!$scope.screen.submodule.isPermissionsLoaded && $scope.currentTab != 'permissions')
                        $scope.screen.submodule.permissions_init();

                    $scope.currentTab = 'permissions';
                }

                $scope.workflowTab = function () {
                    $scope.currentTab = 'workflow';
                    if (!$scope.screen.submodule.isStatesLoaded)
                        $scope.screen.submodule.states_init();
                    if (!$scope.screen.submodule.isWorkflowLoaded)
                        $scope.screen.submodule.workflow();

                }
                $scope.startScreenTab = function () {
                    $scope.currentTab = 'startscreen';
                    $scope.screen.submodule.get_ScreensList();
                }
                $scope.screensTab = function () {
                    $scope.currentTab = 'screens';
                    $scope.screen.submodule.get_ScreensList();
                }
                $scope.gridfieldsTab = function () {
                    $scope.currentTab = 'gridfields';
                    if (!$scope.screen.submodule.isGridfieldsLoaded) {
                        $scope.screen.submodule.get_GridFields();
                        $scope.screen.submodule.set_FormulaScreens();
                    }

                }
                $scope.permissionsTab();
            }
        }

    }
});
