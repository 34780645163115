export enum ActionTypeEnum {
    None = 0,
    WorkflowTransitions = 1,
    DraftTransition = 2,
    ReviseTransition = 3,
    UpdateDocument = 4,
    Comment = 5,
    Approvals = 6,
    ChangeDocumentNumber = 7,
    AssociateVirtualDrawing = 8,
    SaveInstanceFromTemplate = 9,
    PreferredFieldValue = 10,
    RollbackTransition = 11,
    SaveInstanceFromCorrespondence = 12,
    SaveNotificationReplayFromCorrespondence = 13,
    Assignment = 14,
    Email = 15,
}