@if (fieldData().readonly) {
    <cipo-text-view [config]="formControl().fieldData" [value]="formControl().value" [type]="fieldData().type" />
} @else {
    <div class="full-width">
        @if (!fieldData()?.multiple) {
            <mat-form-field class="full-width" appearance="outline">
                <mat-label *ngIf="fieldData().label">{{ fieldData().label | translate }}</mat-label>

                @if (fieldData().multiline) {
                    <textarea
                        matInput
                        #textInput
                        [formControl]="formControl()"
                        [maxlength]="fieldData().maxLength ?? null"
                        [placeholder]="fieldData().placeholder ?? '' | translate"
                        [cdkTextareaAutosize]="true"></textarea>
                } @else {
                    <input
                        matInput
                        #textInput
                        [formControl]="formControl()"
                        [maxlength]="fieldData().maxLength ?? null"
                        [placeholder]="fieldData().placeholder ?? '' | translate" />
                }

                <mat-icon matPrefix *ngIf="fieldData().prefixIcon">{{ fieldData().prefixIcon }}</mat-icon>
                <span matTextPrefix *ngIf="fieldData().prefix">{{ fieldData().prefix | translate }}</span>

                <mat-icon matSuffix *ngIf="fieldData().suffixIcon">{{ fieldData().suffixIcon }}</mat-icon>
                <span matTextSuffix *ngIf="fieldData().suffix">{{ fieldData().suffix | translate }}</span>
                @if (canClearInput() && formControl().value && !formControl().disabled) {
                    <button matSuffix mat-icon-button (click)="formControl().setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>
                } @else if (fieldData().fieldDescription) {
                    <mat-icon
                        matSuffix
                        class="material-icons-outlined cursor--help"
                        [title]="fieldData().fieldDescription | translate">
                        info
                    </mat-icon>
                }
                <mat-error>
                    {{
                        formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate)
                    }}
                </mat-error>
                <mat-hint *ngIf="fieldData().hint">{{ fieldData().hint | translate }}</mat-hint>
            </mat-form-field>
        } @else {
            <mat-form-field class="full-width" appearance="outline">
                <mat-label *ngIf="fieldData().label">{{ fieldData().label | translate }}</mat-label>
                <mat-chip-grid #chipGrid [formControl]="formControl()">
                    @for (value of formControl().value; track $index) {
                        <mat-chip-row (removed)="remove($index)" [editable]="false" (click)="edit($index)">
                            {{ value | translate }}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    }
                    <input
                        #chipInput
                        [matChipInputFor]="chipGrid"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="add($event)" />
                </mat-chip-grid>
                <mat-error>
                    {{
                        formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate)
                    }}
                </mat-error>
                <mat-hint *ngIf="fieldData().hint">{{ fieldData().hint | translate }}</mat-hint>
            </mat-form-field>
        }
    </div>
}
