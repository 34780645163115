import { Component, signal, inject } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { CipoFieldTypes, CipoListControl } from '../../../shared/components/fields/common';
import { DemoSelectFields } from '../../data/demo-cipo-fields';
import { CipoSelectComponent } from '../../../shared/components/fields/cipo-select/cipo-select.component';
import { KeyValueType } from '../../../models/common';

@Component({
  standalone: true,
  imports: [CipoSelectComponent, FlexLayoutModule, KeyValuePipe],
  templateUrl: './demo-cipo-select.component.html',
})
export class DemoCipoSelectComponent {
  fb = inject(FormBuilder);
  dialog = inject(MatDialog);

  form = signal(new FormGroup<{ [x: string]: CipoListControl }>({}));
  FIELD_TYPES = CipoFieldTypes;

  constructor() {
    DemoSelectFields.forEach(field => {
      const { name, defaultValue, ...props } = field;
      const control = new CipoListControl(defaultValue, props);
      if (props.required) {
        control.setValidators(Validators.required);
      }
      this.form().addControl(name, control);
    });

    const options: KeyValueType[] = Array.from({ length: 500 }, (_, index) => ({
      key: `key${index}`,
      value: `Value ${index}`,
    }));

    const fieldData = { label: 'Big select', options, type: CipoFieldTypes.Select };

    const bigControl = new CipoListControl('', fieldData);
    this.form().addControl('bigSelect', bigControl);

    const multiFieldData = {
      label: 'Multi big select (required)',
      options,
      multiple: true,
      required: true,
      type: CipoFieldTypes.Select,
    };
    const multiBigControl = new CipoListControl([], multiFieldData, Validators.required);
    this.form().addControl('multiBigSelect', multiBigControl);
  }
}
