export interface PeopleModel {
  id: number;
  name: string;
  isUser: boolean;
  permissions: PermissionsModel[];
  isNew: boolean;
}

export interface PermissionsModel {
  permissionId: number;
  permission: string;
  allow: boolean;
  deny: boolean;
}

export interface FolderFilePermissionListModel {
  id: number;
  name: string;
}

export enum FilterType {
  All = 1,
  Allow = 2,
  Deny = 3
}
