import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';
import * as Msal from 'msal';

cipo.factory("Register", function ($q, Form, userService, $timeout) {
        var Register = function (obj) {
            var self = this;
            this.canRegister = false;
            this.hasError = true;
            this.error = null;
            this.micButtonDisabled = false;
            this.googButtonDisabled = false;
            this.micButtonBackground = 'background-color: white;';
            this.googButtonBackground = 'background-color: white;';
            this.properties = {
                userName: null,
                password: null,
                confirmPassword: null,
                email: obj ? obj.email : null,
                inviteId: obj.inviteId || '',
                firstName: null,
                lastName: null,
                termsText: "",
                agreeToTerms: false
            }
            //Set to true for the moment since all the other options are removed
            this.EmailUser = true;
            this.MicrosoftUser = {
                config: {
                    auth: {
                        clientId: "0",
                        authority: "https://login.microsoftonline.com/common/",
                        redirectUri: window.location.origin
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: false, // set to true for IE 11
                    }
                },
                isAuthenticated: false,
                isError: false,
                errorMessage: "",
                auth: null,
                profile: null
            }
            this.GoogleUser = {
                clientId: "0",
                auth: null,
                isAuthenticated: false,
                isError: false,
                errorMessage: "",
                profile: null,
                iss: "google.com"
            }

            self.form = new Form(this.properties);
            self.form.loading = true;

            userService.clearData();

            this.init(obj)
                .then(function () { self.hasError = false; })
                .catch(function () { })
                .finally(function () {
                    var isReadOnly = obj ? false : true;
                    self.form.set_Description({
                        // userName: { label: 'Username', type: 'text', validation: { required: true, minChars: 2, maxChars: 32 } },
                        email: { label: 'Email', type: 'text', editMode: isReadOnly, disabled: true },
                        password: {
                            label: 'Password', type: 'password', validation: {
                                required: true, maxChars: 32
                            }
                        },
                        confirmPassword: { label: 'Confirm Password', type: 'password', validation: { required: true, equalsField: 'password' } },

                        firstName: { label: 'First Name', type: 'text', validation: { required: true, minChars: 2, maxChars: 32 } },
                        lastName: { label: 'Last Name', type: 'text', validation: { required: true, minChars: 2, maxChars: 32 } },
                        termsText: { label: 'Annotation', annotation: 'In order to create a CIPO® Account, please review our <a href="https://www.cipocloud.com/terms" target="_blank">Terms of Service</a> and <a href="https://www.cipocloud.com/privacy" target="_blank">Privacy Policy</a>.', type: 'annotation' },
                        agreeToTerms: { label: 'Do you agree with the Terms of Service and our Privacy Policy?', type: 'checkbox', validation: { required: 'You must accept the Terms of Service in order to register.' } }
                    });
                    self.form.setTemplate('grid', [
                        { email: 100 },
                        { password: 50, confirmPassword: 50 },
                        { firstName: 50, lastName: 50 },
                        { termsText: 100 },
                        { agreeToTerms: 100 }
                    ]);

                    self.form.loading = false;
                });
        }

        Register.prototype.init = function () {
            var self = this;
            var p = $q.defer();

            $.ajax({
                "url": environment.baseApiUrl + "_api/Register/RegisterEmail?inviteId=" + self.properties.inviteId,
                "dataType": "json"
            }).done(function (r) {
                self.properties.email = r.email;
                self.MicrosoftUser.config.auth.clientId = r.clientId;
                self.GoogleUser.clientId = r.googleClientId;
                self.canRegister = true;

                if (r.email != null && r.email.endsWith("cipocloud.com")) {
                    self.googButtonDisabled = true;
                    self.googButtonBackground = 'background-color: lightgrey;';
                }
                else if (r.email != null && (r.email.endsWith("gmail.com") || r.email.endsWith("itvizion.com"))) {
                    self.micButtonDisabled = true;
                    self.micButtonBackground = 'background-color: lightgrey;';
                }

                p.resolve();
            }).fail(function (e) {
                console.log(e);
                self.hasError = true;
                self.error = e.responseJSON.messages[0].message;
                p.reject();
            });

            return p.promise;
        }

    Register.prototype.reset = function () {
        this.properties.password = null;
        this.properties.confirmPassword = null;
    }

        Register.prototype.send_Data = function () {
            var self = this;
            var p = $q.defer();
            var registerDataToSend = false;
            var dataIsValid = true;

            var params = {
                azureUserId: "",
                email: self.properties.email,
                registrationType: 0
            };

            params.identities = [];

            // Register with Google Account
            if (self.GoogleUser.isAuthenticated) {
                if (typeof self.GoogleUser.profile.sub != 'undefined') {
                    params.identities.push({
                        issuer: self.GoogleUser.iss,
                        issuerAssignedId: self.GoogleUser.profile.sub
                    });

                    params.firstName = typeof self.GoogleUser.profile.given_name != 'undefined' ?
                        self.GoogleUser.profile.given_name : "";
                    params.lastName = typeof self.GoogleUser.profile.family_name != 'undefined' ?
                        self.GoogleUser.profile.family_name : "";

                    registerDataToSend = true;
                }
            }

            // Register with Microsoft Account
            if (self.MicrosoftUser.isAuthenticated) {
                if (typeof self.MicrosoftUser.auth.stsAuthority.iss != 'undefined' && typeof self.MicrosoftUser.auth.stsAuthority.xms_st.sub != 'undefined') {
                    params.identities.push({
                        issuer: self.MicrosoftUser.auth.stsAuthority.iss,
                        issuerAssignedId: self.MicrosoftUser.auth.stsAuthority.xms_st.sub
                    });

                    params.firstName = typeof self.MicrosoftUser.auth.stsAuthority.given_name != 'undefined' ?
                        self.MicrosoftUser.auth.stsAuthority.given_name : "";
                    params.lastName = typeof self.MicrosoftUser.auth.stsAuthority.family_name != 'undefined' ?
                        self.MicrosoftUser.auth.stsAuthority.family_name : "";

                    registerDataToSend = true;
                }
            }

            // if register with email has been chosen
            if (self.EmailUser) {
                registerDataToSend = true;
                self.form.validate();
                dataIsValid = self.form.isValid;

                if (dataIsValid) {
                    params.firstName = self.properties.firstName;
                    params.lastName = self.properties.lastName;
                    params.password = self.properties.password;
                }
            }

            if (registerDataToSend) {
                if (dataIsValid) {
                    if (self.EmailUser && params.identities.length) params.registrationType = 2;
                    else if (params.identities.length) params.registrationType = 1;

                    $.ajax({
                        "type": "PUT",
                        "url": environment.baseApiUrl + "_api/Register/RegisterUser?inviteId=" + self.properties.inviteId,
                        "contentType": "application/json",
                        "dataType": "json",
                        "data": JSON.stringify(params)
                    }).done(function (r) {
                        p.resolve(r);
                    }).fail(function (e) {
                        p.reject(e);
                    });
                }
                else {
                    p.reject();
                }
            }
            else {
                p.reject("Please choose at least one register option before pressing Register");
            }

            return p.promise;
        }

        Register.prototype.getEmailInfo = function () {
            var self = this;

            self.EmailUser = !self.EmailUser;
            self.form.fieldsList.password.setFocus();

            return;
        }

        Register.prototype.getGoogleInfo = function () {
            var self = this;
            var p = $q.defer();
            self.GoogleUser.isError = false;
            self.GoogleUser.isAuthenticated = false;
            self.GoogleUser.errorMessage = '';

            gapi.load('auth2', function () {
                var client = gapi.auth2.init({
                    client_id: self.GoogleUser.clientId
                });

                $timeout(function () {
                    client.signIn()
                        .then(function () {
                            // get current user
                            var currentUser = client.currentUser.get();
                            var currentUserId = currentUser.getId();
                            var currentUserProfile = currentUser.getBasicProfile();
                            var currentUserAuthResponse = currentUser.getAuthResponse();

                            // set authentication params
                            self.GoogleUser.auth = currentUserAuthResponse;

                            // parse token
                            self.GoogleUser.profile = self.parseJwt(self.GoogleUser.auth.id_token);

                            // error if email doesn't match, success if email matches
                            if (self.properties.email == currentUserProfile.getEmail()) {
                                self.GoogleUser.isAuthenticated = true;
                            } else {
                                self.GoogleUser.isError = true;
                                self.GoogleUser.errorMessage = 'The Google account you are logged into does not match your CIPO invitation.';
                            }

                            p.resolve();
                        })
                        .catch(function (e) {
                            self.GoogleUser.isError = true;
                            self.GoogleUser.errorMessage = 'There was a problem signing in the user';

                            console.error(e);
                            p.reject();
                        });
                });
                
            });

            return p.promise;
        }

        Register.prototype.getMicrosoftInfo = function (_s) {
            var self = this;
            self.MicrosoftUser.isError = false;
            self.MicrosoftUser.isAuthenticated = false;
            self.MicrosoftUser.errorMessage = '';

            self._getMicrosoftInfo(_s)
                .then(r => {
                    if (r.account.userName == self.properties.email) {
                        self.MicrosoftUser.auth = r;
                        self.MicrosoftUser.auth.stsAuthority = self.parseJwt(r.accessToken);
                        self._getMicrosoftProfile()
                            .then(r => {
                                self.MicrosoftUser.profile = r;
                                self.MicrosoftUser.isAuthenticated = true;
                            })
                            .catch(e => {
                                self.MicrosoftUser.isError = true;
                                self.MicrosoftUser.errorMessage = e;
                            })
                    } else {
                        self.MicrosoftUser.isError = true;
                        self.MicrosoftUser.errorMessage = 'The Microsoft account you are logged into does not match your CIPO invitation.';
                    }
                })
                .finally(() => {});
        }

        Register.prototype._getMicrosoftInfo = function(_s) {
            var self = this;
            var p = $q.defer();

            var client = new Msal.UserAgentApplication(self.MicrosoftUser.config);
            var request = {
                scopes: ['https://graph.microsoft.com/.default']
            };

            if (_s) {
                self.switchAccount()
                    .then(r => p.resolve(r))
                    .catch(e => p.reject(e));
            } else {
                client.acquireTokenSilent(request)
                    .then(r => {
                        p.resolve(r);
                    })
                    .catch(err => {
                        self.switchAccount(client)
                            .then(lr => {
                                p.resolve(lr);
                            })
                            .catch(e => { p.reject(e); });
                    })
                    .finally(() => {
                        client = null;
                    });
            }

            return p.promise;
        }

        Register.prototype._getMicrosoftProfile = function () {
            var self = this;
            var p = $q.defer();

            fetch("https://graph.microsoft.com/v1.0/me", {
                headers: {
                    'Authorization': 'Bearer ' + self.MicrosoftUser.auth.accessToken
                }
            })
                .then(r => p.resolve(r.json()))
                .catch(e => p.reject(e));

            return p.promise;
        }

        Register.prototype.switchAccount = function (agent) {
            var self = this;
            var p = $q.defer();
            self.client = agent || new Msal.UserAgentApplication(self.MicrosoftUser.config);
            var request = {
                scopes: ['https://graph.microsoft.com/.default'],
                prompt: 'select_account',
                login_hint: ""
            };

            var silentRequest = {
                scopes: ['https://graph.microsoft.com/.default'],
            }

            self.client.loginPopup(request)
                .then(_r => {
                    self.client.acquireTokenSilent(silentRequest)
                        .then(rs => p.resolve(rs))
                        .catch(e => p.reject(e));
                        // .finally(() => window.client = null);
                })
                .catch(e => { console.log('loginpopup e', e); p.reject(e); });
                

            return p.promise;
        }

        Register.prototype.cancelMicrosoftRegistration = function() {
            this.MicrosoftUser.isAuthenticated = false;
            this.MicrosoftUser.isError = false;
            this.MicrosoftUser.errorMessage = '';
            this.MicrosoftUser.auth = null;
            this.MicrosoftUser.profile = null;

            return;
        }

        Register.prototype.cancelGoogleRegistration = function () {
            this.GoogleUser.isAuthenticated = false;
            this.GoogleUser.isError = false;
            this.GoogleUser.errorMessage = '';
            this.GoogleUser.auth = null;
            this.GoogleUser.profile = null;

            return;
        }

        Register.prototype.parseJwt = function(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };

        return Register;
    });
