<h2 mat-dialog-title class="calendar-doc-detail-title" data-testid="calendar-doc-detail-title">
    {{ datetime }}
</h2>
<div class="calendar-doc-detail-actions" data-testid="calendar-doc-detail-actions">
    <ng-container [ngTemplateOutlet]="actionsTpl" *ngIf="actionsFiltered && actionsFiltered.length"></ng-container>
    <button
        mat-icon-button
        mat-dialog-close
        data-testid="calendar-doc-detail-close-button"
        [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.BUTTON_CLOSE' | translate">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content
    class="calendar-doc-detail-content"
    data-testid="calendar-doc-detail-content"
    [style.max-height.px]="detailContentMaxHeight">
    <app-data-card-view [card]="card" (click)="cardViewClick()"></app-data-card-view>
</mat-dialog-content>

<ng-template #actionsTpl>
    <button
        mat-icon-button
        data-testid="action-button"
        [matMenuTriggerFor]="actions"
        [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.BUTTON_ACTIONS' | translate"
        (click)="actionsMenuClick($event)">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu class="menu-actions" #actions>
        <button
            mat-menu-item
            data-testid="menu-item-action"
            class="mat-button"
            *ngFor="let action of actionsFiltered"
            [attr.aria-label]="action.name"
            (click)="actionClick(action)">
            <mat-icon>{{ action.matIcon || "crop_din" }}</mat-icon>
            {{ action.name }}
        </button>
    </mat-menu>
</ng-template>
