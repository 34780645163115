import { cipo } from 'cipo';

cipo.controller('fmFileController',
    function ($scope, $http, MODAL, $mdDialog, currentFile, dataURL, Message) {
        $scope.modalInfo = MODAL;
        $scope.fileBodyName = currentFile.name.substring(0, currentFile.name.lastIndexOf("."));
        $scope.fileExtension = '.' + currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1);

        $scope.renameFile = function () {
            if (currentFile.isFolder !== true) {
                currentFile.name = $scope.fileBodyName + $scope.fileExtension;
                $http[dataURL.method](dataURL.toString(), currentFile)
                    .then(function (result) {
                        $mdDialog.hide(result.data);
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                        console.error(e);
                    });
            }
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    });
