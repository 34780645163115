import { Component, computed, effect, input, signal, viewChild, inject } from '@angular/core';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { debounceTime, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { CipoControl, CipoFormGroup } from '../../../shared/components/fields/common';
import { TableFieldConfig } from './common';
import { CipoControlComponent } from '../../../shared/components/fields/cipo-control/cipo-control.component';
import { ConfirmationDialog } from '../../../shared/dialogs/confirmation-dialog.component';
import { DEBOUNCE_TIMES } from '../../../shared/consts';

@Component({
  selector: 'cipo-table-field',
  standalone: true,
  imports: [
    MatTableModule,
    DragDropModule,
    MatIconModule,
    CipoControlComponent,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
  ],
  templateUrl: './table-field.component.html',
  styleUrl: './table-field.component.scss',
})
export class TableFieldComponent {
  tableForm = input<FormArray<CipoFormGroup>>();
  tableFields = input<TableFieldConfig[]>();
  control = input<CipoControl>();
  showposition = input(false);
  dialog = inject(MatDialog);

  table = viewChild(MatTable);
  columnsDictionary = computed(() => {
    const columns = {};
    this.tableFields().forEach(field => {
      columns[field.name] = field.label ?? field.name;
    });
    return columns;
  });
  tableColumns = computed(() => ['position', ...this.tableFields().map(field => field.name), 'action']);
  activeRowIndex = signal<number | null>(null);
  arrSubscription: Subscription;

  constructor() {
    effect(() => {
      const formArray = this.tableForm();
      this.arrSubscription?.unsubscribe();
      this.arrSubscription = formArray.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.default)).subscribe(() => {
        this.control()?.markAsDirty();
        this.control()?.setValue(formArray.getRawValue() as any);
      });
      formArray.controls.forEach(formGroup => {
        const controls = formGroup.controls;
        for (const control in controls) {
          controls[control].fieldData.readonly = true;
        }
      });
    });
  }

  drop(event: CdkDragDrop<string>) {
    moveItemInArray(this.tableForm().controls, event.previousIndex, event.currentIndex);
    const currentActiveIndex = this.activeRowIndex();

    if (currentActiveIndex !== null) {
      if (event.previousIndex === currentActiveIndex) {
        this.activeRowIndex.set(event.currentIndex);
      } else if (event.previousIndex < currentActiveIndex && event.currentIndex >= currentActiveIndex) {
        this.activeRowIndex.set(currentActiveIndex - 1);
      } else if (event.previousIndex > currentActiveIndex && event.currentIndex <= currentActiveIndex) {
        this.activeRowIndex.set(currentActiveIndex + 1);
      }
    }
    this.table().renderRows();
  }

  deleteRow(index: number) {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          title: 'cipoFields.deleteRow',
          description: 'cipoFields.deleteMessage',
        },
      })
      .afterClosed()
      .pipe(filter(val => !!val))
      .subscribe(() => {
        this.tableForm().removeAt(index);
        this.table().renderRows();
        this.activeRowIndex.update(i => {
          if (i < index) return i;
          if (i > index) return i - 1;
          return null;
        });
      });
  }

  insertRow() {
    const formGroup = new FormGroup({}) as CipoFormGroup;
    this.tableFields().forEach(field => {
      const { label, fieldDescription, ...props } = field;
      formGroup.addControl(
        field.name,
        new CipoControl(
          null,
          { ...props, readonly: true, cleanReadOnly: true },
          field.required ? Validators.required : null,
        ),
      );
    });
    this.tableForm().push(formGroup);
    this.table().renderRows();
  }

  editRow(index: number) {
    const formArray = this.tableForm();
    const activeRowIndex = this.activeRowIndex();
    if (activeRowIndex !== null) {
      const activeForm = formArray.at(activeRowIndex);
      activeForm.markAllAsTouched();
      activeForm.updateValueAndValidity();
      if (!activeForm.valid) return;
      for (const control in activeForm.controls) {
        activeForm.controls[control].fieldData.readonly = true;
      }
    }

    const newForm = this.tableForm().at(index);
    for (const control in newForm.controls) {
      newForm.controls[control].fieldData.readonly = false;
    }
    this.activeRowIndex.set(index);
  }
}
