import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';

cipo.controller('stntController',
    function ($scope, $state, Manager, $uibModal, dialogs, Message, URI, ACTIONS) {
        
        $scope.sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }

        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.TENANT.LIST,
            otherParams: {
                ShowDisabled: true
            },
            options: {
                multiselect: true
            },
            rowOnDblClick: function (row) {
                $scope.saveTenant(row.id);
            },
            actions: [
                {
                    setProperties: ACTIONS.CREATE,
                    alwaysOnTop: true,
                    click: function (rows) {
                        $scope.saveTenant();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    click: function (row) {
                        $scope.saveTenant(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    
                    click: function (rows) {
                        $scope.deleteOrganization($scope.sendList(rows));
                    }
                }
            ]
        });

        $scope.manager.set_Columns([
            { name: 'name', label: 'Name', type: 'text' },
            { name: 'description', label: 'Description', type: 'text' },
        ]);

        $scope.manager.loadPage();

        $scope.saveTenant = function (id) {
            var id = (typeof id != 'undefined') ? parseInt(id) : 0;
            var modalInstance = $uibModal.open({
                controller: 'SaveTenantController',
                templateUrl: '/ng/views/super/modals/saveTenant.html',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    "id": function () {
                        return id;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.manager.page = 1;
                $scope.manager.loadPage();
            }, function () {
            });
        }

        $scope.deleteTenant = function (list) {
            var list = list || [];

            if (list.length) {
                var d = dialogs.confirm('Removing Tenant', 'Are you sure you want to delete the selected Tenant(s)?');
                d.result
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager.put(environment.baseApiUrl + '_api/Tenants/Delete', { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Tenant(s) deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e);
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    });
            } else {
                Message.info('Please select an item to delete first');
            }
        }
    });
