import { cipo } from 'cipo';

cipo.controller('editFinancialController',
    function ($scope, Message, MODAL, Financial, operations, module, $q, id, $mdDialog, $timeout) {

        $scope.modalInfo = MODAL;
        $scope.financial = new Financial(id);
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.disabledBtn = false;

        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.financial.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            $scope.close(true);
        };

        $scope.updateTabInfo = function () {
            $scope.financial.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $scope.close(true);
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.financial.tabWarning = false;
            $scope.financial.hightlight = false;
            $scope.disabledBtn = false;
            $scope.closingModal = false;
        };


        $scope.save = function (isAdd) {
            var p = $q.defer();
            $scope.disabledBtn = true;
            $scope.financial.tabWarning = false;
            $scope.financial.hightlight = false;
            $scope.message = "Saving financial...";
            $scope.financial.save(isAdd)
                .then(function (result) {
                    Message.info('Financial saved successfully');
                    $scope.isModified = true;
                    
                    p.resolve();
                    $scope.disabledBtn = false;
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
            return p.promise;
        };

        $scope.$watch(function () {
            return $scope.financial.properties.intervalKey;
        },
            function (n, o) {
                //console.error('intervalKey', n, o);
                if (typeof n != 'undefined' && n != o) {
                    if(o) $scope.financial.properties.periodIntervalLastDay = null;
                    $scope.financial.set_subForm(parseInt($scope.financial.properties.intervalKey));
                }
            });

        $scope.$watch(function () {
            return $scope.financial.properties.allPeriods;
        },
            function (n, o) {
                //console.error('intervalKey', n, o);
                if (typeof n != 'undefined' && n != o) {
                    if (n) {
                        $scope.financial.form.fieldsList.pastPeriodsPaymentAllowedOn.editMode = false;
                        $scope.financial.properties.pastPeriodsPaymentAllowedOn = 0;
                    }
                    else $scope.financial.form.fieldsList.pastPeriodsPaymentAllowedOn.editMode = true;
                    
                }
            });

        $scope.$watch(function () {
            return $scope.financial.properties.peiwNumeric;
        },
            function (n, o) {
                if (typeof n != 'undefined' && n != o) {
                    if (n == 0) {
                        $scope.financial.properties.periodIntervalLastDay = null;
                        if ($scope.financial.periodForm && $scope.financial.periodForm.fieldsList.length) {
                            $scope.financial.periodForm.fieldsList.periodIntervalLastDay._clearErrors();
                            $scope.financial.periodForm.fieldsList.periodIntervalLastDay.editMode = false;
                        }
                        
                    } else $scope.financial.periodForm.fieldsList.periodIntervalLastDay.editMode = true;
                }
            });

        $scope.$watch(function () {
            return $scope.financial.properties.intervalsPerPeriod;
        },
            function (n, o) {
                //console.error('intervalsPerPeriod', n, o);
                if (typeof n != 'undefined' && n != o && $scope.financial.properties.intervalKey) {
                    $scope.financial.validateInterval();
                }
            });
        $scope.isModified = false;
        $scope.close = function (isWarning) {
            if (!$scope.financial.form.dirty || isWarning)
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide();
            else {
                $scope.financial.hightlight = true;
                $scope.financial.tabWarning = true;
                $scope.closingModal = true;
            }
        };

        $scope.actionsOptions = {
            close: $scope.close,
            save: $scope.save,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction,
        }

    });
