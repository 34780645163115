import { Injectable, computed, signal } from '@angular/core';
import { AUTH_TOKEN, CIPO_THEME, CIPO_THEME_MODE, DIALOG_SIZE, TENANT } from 'src/app/shared/consts';
import { BREAKPOINTS } from '../../../app-breakpoints.module';

export type CipoTheme = 'default' | 'orange';
export type CipoThemeMode = 'dark' | 'light' | 'browser';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _themeMode$ = signal<CipoThemeMode>('light');
  private _theme$ = signal<CipoTheme>('default');
  themeMode$ = computed(() => this._themeMode$());
  theme$ = computed(() => this._theme$());

  formats = {
    date: 'MM/DD/YYYY',
    datetime: 'MM/DD/YYYY hh:mm A',
    time: 'hh:mm A',
  };

  constructor() {
    this.applySavedThemeAndMode();
  }

  private applySavedThemeAndMode() {
    const savedMode = localStorage.getItem(CIPO_THEME_MODE) as CipoThemeMode;
    const savedTheme = localStorage.getItem(CIPO_THEME) as CipoTheme;

    const mode = savedMode === 'browser' ? this.getBrowserThemeMode() : savedMode ?? 'light';
    const theme = savedTheme ?? 'default';

    theme !== this.theme$() && this._theme$.set(theme);
    mode !== this.themeMode$() && this._themeMode$.set(mode);
  }

  appendClassesToBody({ theme, mode }: { theme: CipoTheme; mode: CipoThemeMode }) {
    const classesToRemove = Array.from(document.body.classList);

    // Remove existent classes
    classesToRemove.forEach(className => {
      if (className.endsWith('-theme') || className.endsWith('-mode')) {
        document.body.classList.remove(className);
      }
    });
    document.body.classList.add(theme + '-theme', mode + '-mode');
  }

  private getBrowserThemeMode(): CipoThemeMode {
    const darkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    return darkModeOn ? 'dark' : 'light';
  }

  changeThemeMode(mode: CipoThemeMode) {
    localStorage.setItem(CIPO_THEME_MODE, mode);
    this._themeMode$.set(mode === 'browser' ? this.getBrowserThemeMode() : mode);
  }

  changeTheme(theme: CipoTheme) {
    localStorage.setItem(CIPO_THEME, theme);
    this._theme$.set(theme);
  }

  getUserHeaders() {
    return {
      Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN),
      TenantId: sessionStorage.getItem(TENANT),
    };
  }

  getResponsiveDialogSize(): typeof DIALOG_SIZE {
    const windowWidth = window.innerWidth;
    const { full, lg, md, sm, xl } = DIALOG_SIZE;
    if (windowWidth < BREAKPOINTS.sm) {
      return { full, xl, lg: xl, md: xl, sm: xl };
    }
    if (windowWidth < BREAKPOINTS.md) {
      return { full, xl, lg: xl, md: lg, sm: md };
    }
    return DIALOG_SIZE;
  }
}
