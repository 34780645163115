import { Injectable } from '@angular/core';
import moment from 'moment';
import { map } from 'rxjs';
import { KeyValueModel } from '../../../models/common';
import { FieldTypeEnum } from '../../../models/module/fields/enums';
import { DataListFieldPropertiesModel, DataListType } from '../../../models/module/fields/main';
import {
  FilterLogic,
  FilterModel,
  FilterOperator,
  GridSearchEntityInstance,
  GridViewModel,
  SearchViewModel,
} from '../../../models/module/grid';
import { ScreenModel, ScreenTypeEnum } from '../../../models/module/screen';
import { HttpService } from '../../../shared/services/http.service';
import { UserService } from '../../../shared/services/user.service';

@Injectable()
export class CalendarService {
  constructor(
    private httpService: HttpService,
    private userService: UserService,
  ) {}

  getData(contractId: number, field: string, date1: Date, date2: Date, search?: SearchViewModel) {
    const params = this.httpService.buildHttpParams({ dataListType: DataListType.Calendar, contractId });
    const body: SearchViewModel = {
      page: 1,
      pagesize: 100,
      isCurrent: search?.isCurrent ?? true,
      criteria: search?.criteria,
      filter: { ...this.makeFilter(search?.filter, field, date1, date2) },
    };
    return this.httpService.post<GridViewModel<GridSearchEntityInstance[]>>('_api/ModuleGrid/GridSearch', body, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  getDataListFields() {
    return this.httpService.post<GridViewModel<DataListFieldPropertiesModel[]>>(
      `_api/DataList/Search?dataListType=${DataListType.Calendar}`,
      {},
      { useLoader: false },
    );
  }

  getDescriptionReferenceFields() {
    return this.httpService
      .get<KeyValueModel<number, string>[]>(`_api/FieldDefinition/EntityFieldsDict`, { useLoader: false })
      .pipe(map(d => d.map(e => ({ id: e.key, name: e.value, label: e.description }))));
  }

  getDateReferenceFields() {
    const params = this.httpService.buildHttpParams({
      type: FieldTypeEnum.Date,
      addContractFields: false,
      includeSystemFields: true,
      includeSpecialFields: true,
    });
    return this.httpService
      .get<KeyValueModel<number, string>[]>(`_api/FieldDefinition/Dict`, { useLoader: false, httpOptions: { params } })
      .pipe(map(d => d.map(e => ({ id: e.key, name: e.description, label: e.value }))));
  }

  getScreen() {
    return this.httpService
      .post<GridViewModel<ScreenModel[]>>(
        `_api/Screen/Search?type=${ScreenTypeEnum.Calendar}`,
        {},
        { useLoader: false },
      )
      .pipe(map(s => (s?.data && s.data.length ? s.data[0] : null)));
  }

  private makeFilter(filter: FilterModel, field: string, date1: Date, date2: Date): FilterModel {
    if (!filter) {
      return this.pushFilter(this.pushFilter(this.makeFilterBetweenDates(field, date1, date2)));
    }

    let found = false;
    filter = { ...filter };
    filter.filters = filter.filters || [];
    if (filter.filters.length) {
      for (const f of filter.filters) {
        if (f.filters && Array.isArray(f.filters)) {
          // try to find a filter for this field
          let sf = f.filters.find(s => s.field && s.field == field);
          if (sf) {
            // replace the filter
            sf = this.makeFilterBetweenDates(field, date1, date2);
            found = true;
          }
        }
      }
    }
    if (!found) {
      // no filter found on this field, add the filter
      filter.filters.push(this.pushFilter(this.makeFilterBetweenDates(field, date1, date2)));
    }
    return filter;
  }

  private makeFilterBetweenDates(field: string, date1: Date, date2: Date): FilterModel {
    const format = this.userService.formats.date;
    return {
      field: field,
      fieldTypeId: FieldTypeEnum.Date,
      operator: FilterOperator.Between,
      options: format,
      value: [moment(date1).format(format), moment(date2).format(format)],
    } as FilterModel;
  }

  private pushFilter(filter: FilterModel): FilterModel {
    return {
      logic: FilterLogic.AND,
      filters: [filter],
    } as FilterModel;
  }
}
