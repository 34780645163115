import { cipo } from 'cipo';

var recoverController = function ($transition$, $scope, $state, Recover, Message) {
    var t = $transition$.params().t;
    $scope.resetObj = new Recover(t);
    $scope.send = function () {
        $scope.resetObj.send_Password()
            .then(function () {
                $state.go("main");
            })
            .catch(function () { })
    }
}

cipo.controller('recoverController', recoverController);