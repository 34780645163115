<form [formGroup]="multipleAddressForm" class="multiple-address-autocomplete">
    <div *ngFor="let item of addresses.controls; let idx = index" class="address-autocomplete">
            <app-address-autocomplete class="address-autocomplete" (onAddressSelected)="onAddressSelected($event, idx)"></app-address-autocomplete>
        <button mat-icon-button aria-label="Expand for more options, see the map" (click)="addAddress()">
            <mat-icon>add</mat-icon>
        </button>
        <button *ngIf="!isOnlyOneAddress" mat-icon-button aria-label="Expand for more options, see the map" (click)="removeAddress(idx)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</form>