import { Component, signal, inject } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { DemoNumberFields } from '../../data/demo-cipo-fields';
import { CipoNumberControl } from '../../../shared/components/fields/common';
import { CipoNumberComponent } from '../../../shared/components/fields/cipo-number/cipo-number.component';

@Component({
  selector: 'app-demo-cipo-number',
  standalone: true,
  imports: [CipoNumberComponent, FlexLayoutModule, KeyValuePipe],
  templateUrl: './demo-cipo-number.component.html',
})
export class DemoCipoNumberComponent {
  dialog = inject(MatDialog);

  form = signal(new FormGroup<{ [x: string]: CipoNumberControl }>({}));

  constructor() {
    DemoNumberFields.forEach(field => {
      const { name, defaultValue, type, ...props } = field;
      const control = new CipoNumberControl(defaultValue, props);
      if (props.required) {
        control.setValidators(Validators.required);
      }
      this.form().addControl(name, control);
    });
  }
}
