import { cipo } from 'cipo';

cipo.controller('pdfHandlerOptionsDialogController',
    function ($scope, $mdDialog, data) {
        $scope.options = data.options;
        $scope.showTemplates = data.showTemplates;
        $scope.templates = data.templates;
        $scope.template = data.templates.find(t => t.value == data.defaultTemplate.value);
        $scope.title = data.title;
        $scope.confirmLabel = formatLabel(data.buttonLabels.find(x => x.key == 'confirm').value);
        $scope.cancelLabel = formatLabel(data.buttonLabels.find(x => x.key == 'cancel').value);

        $scope.confirm = function () {
            var ret = $scope.options.map(x => { return { key: x.key, value: x.value }; });
            ret.push({ key: 'templateId', value: $scope.template.value });
            $mdDialog.hide(ret);
        }

        $scope.cancel = function () {
            $mdDialog.hide(null);
        }

        function formatLabel(label) {
            return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
        }
    });
