import { ComponentType } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import { DemoThemeComponent } from 'src/app/demo/angular/demo-theme/demo-theme.component';
import { DemoFroalaComponent } from 'src/app/demo/demo-froala/demo-froala.component';
import { DemoScheduledEmailsComponent } from 'src/app/demo/module-instance/demo-scheduled-emails/demo-scheduled-emails.component';
import { TableComponent } from 'src/app/demo/module-instance/table/table.component';
import { MenuItem } from '../system/menu/menu.component';
import { DemoAngularButtonComponent } from './angular/button/demo-angular-button.component';
import { DemoAngularInputComponent } from './angular/input/demo-angular-input.component';
import { DemoDataCardSettingsComponent } from './module-definition/demo-data-card-settings/demo-data-card-settings.component';
import { DemoCalendarViewComponent } from './module-instance/calendar-view/demo-calendar-view.component';
import { DemoCardsViewComponent } from './module-instance/cards-view/demo-cards-view.component';
import { DemoAddressAutocompleteComponent } from './module-instance/demo-address-autocomplete/demo-address-autocomplete.component';
import { DemoFormBuilderComponent } from './module-definition/demo-form-builder/demo-form-builder.component';
import { DemoTenantSettingsComponent } from './module-instance/demo-tenant-settings/demo-tenant-settings.component';
import { DemoCipoFieldsComponent } from './demo-cipo-fields/demo-cipo-fields.component';
import { DemoCipoTextComponent } from './demo-cipo-fields/demo-cipo-text/demo-cipo-text.component';
import { DemoCipoNumberComponent } from './demo-cipo-fields/demo-cipo-number/demo-cipo-number.component';
import { DemoCipoListboxComponent } from './demo-cipo-fields/demo-cipo-listbox/demo-cipo-listbox.component';
import { DemoCipoCheckboxComponent } from './demo-cipo-fields/demo-cipo-checkbox/demo-cipo-checkbox.component';
import { DemoCipoAttachmentComponent } from './demo-cipo-fields/demo-cipo-attachment/demo-cipo-attachment.component';
import { DemoCipoSelectComponent } from './demo-cipo-fields/demo-cipo-select/demo-cipo-select.component';
import { DemoContextComponent } from './system/context/demo-context.component';
import { DemoCipoDateTimeComponent } from './demo-cipo-fields/demo-cipo-date-time/demo-cipo-date-time.component';
import { DemoTableFieldComponent } from './demo-cipo-fields/demo-table-field/demo-table-field.component';

export interface DemoMenuItem extends MenuItem {
  component?: ComponentType<any>;
  children?: DemoMenuItem[];
}

@Component({
  selector: 'app-demo-empty',
  template: '',
})
export class DemoEmptyComponent {}

export const DemoMenuItems: DemoMenuItem[] = [
  {
    id: 100,
    name: 'Angular',
    children: [
      { id: 101, name: 'Button', iconId: 40, component: DemoAngularButtonComponent },
      { id: 102, name: 'Input', iconId: 21, component: DemoAngularInputComponent },
      { id: 112, name: 'Cipo Fields', iconId: 21, component: DemoCipoFieldsComponent },
      { id: 103, name: 'Theme', iconId: 24, component: DemoThemeComponent },
      { id: 104, name: 'Froala', iconId: 46, component: DemoFroalaComponent },
      { id: 105, name: 'Address', iconId: 21, component: DemoAddressAutocompleteComponent },
    ],
  },
  {
    id: 150,
    name: 'Fields',
    children: [
      { id: 151, name: 'Custom form', iconId: 22, component: DemoCipoFieldsComponent },
      { id: 152, name: 'Cipo text', iconId: 23, component: DemoCipoTextComponent },
      { id: 153, name: 'Cipo number', iconId: 24, component: DemoCipoNumberComponent },
      { id: 154, name: 'Cipo listbox', iconId: 25, component: DemoCipoListboxComponent },
      { id: 155, name: 'Cipo checkbox', iconId: 26, component: DemoCipoCheckboxComponent },
      { id: 157, name: 'Cipo attachment', iconId: 28, component: DemoCipoAttachmentComponent },
      { id: 156, name: 'Cipo select', iconId: 27, component: DemoCipoSelectComponent },
      { id: 158, name: 'Cipo DateTime', iconId: 28, component: DemoCipoDateTimeComponent },
      { id: 159, name: 'Cipo Table field', iconId: 29, component: DemoTableFieldComponent },
    ],
  },
  {
    id: 200,
    name: 'Module Definition',
    children: [
      { id: 201, name: 'Card settings', iconId: 65, component: DemoDataCardSettingsComponent },
      { id: 203, name: 'Form Builder', iconId: 13, component: DemoFormBuilderComponent },
    ],
  },
  {
    id: 300,
    name: 'Module Instance',
    children: [
      { id: 301, name: 'Calendar view', iconId: 95, component: DemoCalendarViewComponent },
      { id: 302, name: 'Cards view', iconId: 13, component: DemoCardsViewComponent },
      { id: 303, name: 'Example table', iconId: 13, component: TableComponent },
      { id: 304, name: 'Scheduled emails', iconId: 66, component: DemoScheduledEmailsComponent },
      { id: 305, name: 'Tenant Settings', iconId: 67, component: DemoTenantSettingsComponent },
    ],
  },
  {
    id: 400,
    name: 'System',
    children: [{ id: 401, name: 'Context', iconId: 73, component: DemoContextComponent }],
  },
  {
    id: 1000,
    name: 'Test 1st level',
    children: [
      { id: 1001, name: 'Test 2nd level m1', component: DemoEmptyComponent },
      { id: 1002, name: 'Test 2nd level m2', component: DemoEmptyComponent },
      {
        id: 1003,
        name: 'Test 2nd level m3',
        children: [
          { id: 1101, name: 'Test 3nd level m3.1', component: DemoEmptyComponent },
          { id: 1102, name: 'Test 3nd level m3.2', component: DemoEmptyComponent },
        ],
      },
      {
        id: 1004,
        name: 'Test 2nd level m4',
        children: [
          { id: 1201, name: 'Test 3nd level m4.1', component: DemoEmptyComponent },
          { id: 1202, name: 'Test 3nd level m4.2', component: DemoEmptyComponent },
        ],
      },
    ],
  },
];
