import { Component, ElementRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { ScreenFieldModel } from '../../../../models/module/fields/main';
import { DataListViewAction } from '../../../../models/module/main/dataListViewAction';
import {
  CalendarDialogDocDetailComponent,
  CalendarDialogDocDetailOuterHeight,
  CalendarDialogDocDetailOuterWidth,
  CalendarDialogDocDetailPrefix,
} from '../calendar-dialog-doc-detail/calendar-dialog-doc-detail.component';
import { CalendarDialogMorePrefix } from '../calendar-dialog-more/calendar-dialog-more.component';
import { CalendarTileDoc } from '../calendar.model';

@Component({
  selector: 'app-calendar-doc-mini',
  templateUrl: './calendar-doc-mini.component.html',
  styleUrls: ['./calendar-doc-mini.component.scss'],
})
export class CalendarDocMiniComponent {
  @Input() doc: CalendarTileDoc;
  @Input() screenFields: ScreenFieldModel[];
  @Input() actions: DataListViewAction[];

  constructor(
    private dialog: MatDialog,
    private ref: ElementRef,
  ) {}

  openDoc(event: any) {
    // check if this doc mini is inside a dialog
    const dialogMoreId = event?.target?.closest('.mat-dialog-container')?.id;

    // do not open the same dialog again
    const dialogDocDetailId = this.getDialogId();
    const sameDocDetailDialog = this.dialog.openDialogs.find(d => d.id && d.id == dialogDocDetailId);
    if (sameDocDetailDialog) {
      return;
    }

    // find and close all doc detail dialogs, we will keep only one doc detail dialog open
    const differentDocDetailDialogs = this.dialog.openDialogs.filter(
      d => d.id && d.id.startsWith(CalendarDialogDocDetailPrefix),
    );
    for (const dialog of differentDocDetailDialogs) {
      dialog.close();
    }

    // find and close all more dialogs different than the parent dialog
    const differentMoreDialogs = this.dialog.openDialogs.filter(
      d => d.id && d.id.startsWith(CalendarDialogMorePrefix) && d.id != dialogMoreId,
    );
    for (const dialog of differentMoreDialogs) {
      dialog.close();
    }

    // open this doc detail dialog
    this.openDialog();
  }

  getDialogId() {
    return `${CalendarDialogDocDetailPrefix}-${moment(this.doc.ref.date).format('YYYY-MM-DD')}-${
      this.doc.ref.full_doc_num
    }`;
  }

  openDialog() {
    const docRect: DOMRect = this.ref.nativeElement.getBoundingClientRect();
    const parentRect: DOMRect = document.body.getBoundingClientRect();

    const width = CalendarDialogDocDetailOuterWidth,
      height = CalendarDialogDocDetailOuterHeight,
      margin = 8;
    let top = docRect.top;
    let left = docRect.left - width - margin;

    if (top + height > parentRect.height) {
      top = parentRect.bottom - height - margin;
    }
    if (left < parentRect.left) {
      left = docRect.right + margin;
    }
    if (left + CalendarDialogDocDetailOuterWidth > parentRect.right) {
      left = parentRect.right - CalendarDialogDocDetailOuterWidth - margin;
    }
    if (left < 0) {
      left = margin;
    }

    this.dialog.open(CalendarDialogDocDetailComponent, {
      data: {
        doc: this.doc,
        screenFields: this.screenFields,
        actions: this.actions,
      },
      hasBackdrop: false,
      autoFocus: false,
      id: this.getDialogId(),
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
      width: `${width}px`,
      maxWidth: `calc(100vw - ${margin * 2}px)`,
    });
  }
}
