import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, delay, map, of } from 'rxjs';
import { FieldTypeEnum } from '../../../models/module/fields/enums';
import { DataListFieldPropertiesModel } from '../../../models/module/fields/main';
import { GridSearchEntityInstance, GridViewModel } from '../../../models/module/grid';
import { ScreenModel } from '../../../models/module/screen';
import { DemoCardsEntityInstances } from '../../data';
import { CalendarReferenceField } from '../../../module/instance/calendar/calendar.model';

@Injectable()
export class DemoCalendarService {
  colors = ['rgb(230, 113, 60)', 'rgb(92, 147, 209)', 'rgb(186, 33, 167)', 'rgb(31, 143, 160)'];
  states = ['Draft', 'Open', 'In Review', 'Closed'];

  getData(
    contractId: number,
    field: string,
    date1: Date,
    date2: Date,
  ): Observable<GridViewModel<GridSearchEntityInstance[]>> {
    const monthsNumber = Math.floor(moment.duration(moment(date2).diff(moment(date1))).asMonths());
    const isYearView = monthsNumber > 1;
    const data: GridSearchEntityInstance[] = [];
    const demoInstances = DemoCardsEntityInstances;
    let dt = moment.utc(date1).hours(0).minutes(0).seconds(0);
    if (isYearView) {
      dt = dt.date(1);
    }
    for (let i = 0; i < 40; i++) {
      for (let j = 0; j < i + 1; j++) {
        const demodemoInstance = { ...demoInstances[this.random(demoInstances.length - 1)] };
        demodemoInstance.created_on = dt.format(`YYYY-MM-DD[T]HH:mm:ss[Z]`);
        demodemoInstance.updated_on = dt.clone().add(1, 'day').format(`YYYY-MM-DD[T]HH:mm:ss[Z]`);
        data.push(demodemoInstance);
        if (isYearView) {
          dt.add(this.random(3), 'day');
        }
      }
      if (isYearView) {
        dt.add(1, 'month');
      } else {
        dt.add(1, 'day');
      }
    }

    return of(null).pipe(
      map(
        () =>
          ({
            records: data.length,
            data: data,
          }) as GridViewModel<GridSearchEntityInstance[]>,
      ),
      delay(100),
    );
  }

  getDataListFields(): Observable<GridViewModel<DataListFieldPropertiesModel[]>> {
    return of({
      records: 2,
      data: [
        { id: 1, name: 'created_on', label: 'Created on', typeId: FieldTypeEnum.Date },
        { id: 2, name: 'updated_on', label: 'Updated on', typeId: FieldTypeEnum.Date },
      ],
    } as GridViewModel<DataListFieldPropertiesModel[]>).pipe(delay(100));
  }

  getDescriptionReferenceFields(): Observable<CalendarReferenceField[]> {
    return of([]).pipe(delay(100));
  }

  getDateReferenceFields(): Observable<CalendarReferenceField[]> {
    return of([{
      id: 1,
      name: 'created_on',
      label: 'Created on',
    } as CalendarReferenceField]).pipe(delay(100));
  }

  getScreen(): Observable<ScreenModel> {
    return of({
      dateFieldId: 1,
    } as ScreenModel).pipe(delay(100));
  }

  private random(max: number): number {
    return Math.floor(Math.random() * (max - 1));
  }
}
