import { cipo } from 'cipo';
import { MenuType } from 'src/app/models/menu';

cipo.factory('Menu', function ($rootScope, $location, $window, legacyRoutes, userService, MODULE_TYPES) {
  var Menu = {
    activeMenuItemId: undefined,
  };

  Menu.triggerRefresh = function () {
    return userService.triggerMenuRefresh();
  }

  Menu.setMenuItemByModuleCode = function (code) {
    if (userService.hasModulesLoaded && userService.system.modules[code]) {
      this.activeMenuItemId = userService.system.modules[code].id;
    }
  }

  Menu.itemExists = function (moduleId) {
    if (userService.hasModulesLoaded) {
      return userService.getModuleIdentifierById(moduleId);
    }
    return undefined;
  }

  Menu.menuItemClick = function (menuItem) {
    var tenantId = userService.currentTenant();
    var tenantIdentifier = tenantId ? userService.getTenantIdentifierById(tenantId) : undefined;
    if (!tenantIdentifier) {
      return;
    }

    this.activeMenuItemId = menuItem.id;

    var contractId = userService.system.userdata.contractId;
    var routeByCode = legacyRoutes.byCode.get(menuItem.code);
    var route = '';

    if (routeByCode) {
      route = `${tenantIdentifier}${routeByCode.route}`;
    } else {
      if (menuItem.typeId == MenuType.Url) {
        route = menuItem.openInNewTab ? menuItem.url : `${tenantIdentifier}/url/${menuItem.moduleId}`;
      }
      else if (menuItem.moduleTypeId == MODULE_TYPES.REPORTING) {
        var cRoute = menuItem.perContract ? '{contractId}' : '0';
        route = `${tenantIdentifier}/managementreports/${cRoute}/${menuItem.moduleId}`;
      } else if (menuItem.moduleId) {
        var cRoute = menuItem.perContract ? '{contractId}' : '0';
        if (menuItem.code == 'PP') {
          route = `${tenantIdentifier}/progresspayment/${cRoute}/0/0`;
        }
        else if (menuItem.code == 'SOV') {
          route = `${tenantIdentifier}/sov/${cRoute}/0`;
        } else {
          route = `${tenantIdentifier}/dm/${cRoute}/${menuItem.moduleId}`;
        }
      }
    }

    if (menuItem.typeId == MenuType.Url && menuItem.openInNewTab) {
      var url = (route.startsWith('http') ? '' : 'http://') + route;
      $window.open(url, menuItem.name);
    } else if (menuItem.moduleId) {
      $window.scrollTo(0, 0);
      var elemRoute = route.toString().replace('{contractId}', (contractId || 0).toString());
      var qmark = elemRoute.indexOf('?');
      var url = qmark === -1 ? elemRoute : elemRoute.substring(0, qmark);

      $location.url(url);
      $rootScope.$apply();
    }
  }

  return Menu;
});
