import { cipo } from 'cipo';
    
cipo.controller('postLoginController', function ($scope, $injector, $state, $location) {
    
    $scope.errorDesc = '';

    // store received error
    var searchErrorDesc = $location.search()?.error_description || '';
    var hashErrorDesc = new URLSearchParams($location.hash() || '').get('error_description') || '';
    localStorage.setItem('postLoginError', searchErrorDesc || hashErrorDesc);

    // wait for msal response
    let angularMsalService = $injector.get('msalAuthService');
    try {
        angularMsalService.tokenReceived$.toPromise().then(_ => {
            // success
            localStorage.removeItem('postLoginError');
            $state.go('main');
        }).catch(function (error) { 
            // this error message is the result of a promise so it may come at a later time
            $scope.errorDesc = error.message;
        })
    }
    catch(error) {
        $scope.errorDesc = error.message;
    }

    // wait
    setTimeout(function() {
        // show last stored error message
        // or show an error when redirect fails
        $scope.errorDesc = localStorage.getItem('postLoginError') || 'Redirect did not complete correctly.';
        $scope.$apply();
    }, 5000);

    $scope.backToLogin = function () {
        localStorage.removeItem('postLoginError');
        window.location.href = '/';
    }
});