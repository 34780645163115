import { cipo } from 'cipo';

cipo.controller('aorgController', function ($transition$,$scope, $state, $location, rememberManagerService, Manager, $uibModal, dialogs, Message, URI, ACTIONS, userService, $mdDialog, operations) {
    const params = $transition$.params();
    $scope.moduleName = userService.system.mNames[$state.current.code];
    $scope.module = userService.system.modules[$state.current.code];
    Object.defineProperty($scope, 'operations', {
        get: function () { return userService.getOperationsFor($state.current.code); }
    });
        

        $scope.sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
    }


        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.ORGANIZATION.SEARCH,
            otherParams: {
                ShowDisabled: true
            },
            dataWrapper: 'data',
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.saveOrganization(row.id);
            },
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    icon: 'mdi mdi-account-multiple-plus',
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.saveOrganization(-1);
                    }
                },
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.saveOrganization(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.saveOrganization(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.ACTIVATE,
                    alwaysOnTop: false,
                    conditionOnTop: function () {
                        if ($scope.operations.Update) return true;
                        else return false;
                    },
                    displayOnRow: $scope.operations.Update ? true : false,
                    condition: function (row) {
                        if (row.disabled && row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.enableOrganization($scope.sendList(rows));
                    }
                },
                
                {
                    setProperties: ACTIONS.DEACTIVATE,
                    alwaysOnTop: false,
                    displayOnRow: $scope.operations.Update ? true : false,
                    condition: function (row) {
                        if (!row.disabled && row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.disableOrganization($scope.sendList(rows));
                    }
                },
                {
                    setProperties: ACTIONS.MORE,
                    conditionOnTop: function () {
                        if ($scope.operations.Update) return true;
                        else return false;
                    },
                    actions: [
                        {
                            setProperties: ACTIONS.ACTIVATE,
                            click: function (rows) {
                                $scope.enableOrganization($scope.sendList(rows));
                            }
                        },
                        {
                            setProperties: ACTIONS.DEACTIVATE,
                            click: function (rows) {
                                $scope.disableOrganization($scope.sendList(rows));
                            }
                        }

                    ]
                }
            ],
            
        });

        var statusLookup = {
            false: { name: 'Active', class: 'basic' },
            true: { name: 'Disabled', class: 'deactivated' }
        }

        $scope.manager.set_Columns([
            { name: 'name', label: 'Organization', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
            { name: 'email', label: 'Email', type: 'text', onTablet: true, onPhone: false, width: 30, fieldTypeId: 1 },
            { name: 'members', label: 'Members', type: 'text', onTablet: true, onPhone: false, width: 30, fieldTypeId: 2 },
            { name: 'disabled', label: 'Status', type: 'status', lookupArr: statusLookup, onTablet: true, onPhone: true, width: 30 }
        ]);

        $scope.manager.loading = true;
        $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
            function() {
                $scope.manager.loading = false;
            },
            function() {
                $scope.manager.loadPage()
                .finally(function () {
                    $scope.manager.loading = false;
                });
            }
        );

        $scope.enableOrganization = function (list) {
            var list = list || [];

            if (list.length) {
                $scope.manager.loading = true;
                $scope.manager[URI.ORGANIZATION.ENABLE.method](URI.ORGANIZATION.ENABLE, { body: { list: list } })
                    .then(function () {
                        Message.info('Organization(s) activated successfully');
                    })
                    .catch(function (e) {
                        $scope.manager.loading = false;
                        Message.dberror(e, $scope.manager.rows, 'id', 'name');
                    })
                    .finally(function () {
                        $scope.manager.loadPage();
                    });
            } else {
                Message.info('Please select the item(s) to activate');
            }
        }

        $scope.disableOrganization = function (list) {
            var list = list || [];

            if (list.length) {
                $scope.manager.loading = true;
                $scope.manager[URI.ORGANIZATION.DISABLE.method](URI.ORGANIZATION.DISABLE, { body: { list: list } })
                    .then(function () {
                        Message.info('Organization(s) disabled successfully');
                    })
                    .catch(function (e) {
                        $scope.manager.loading = false;
                        Message.dberror(e, $scope.manager.rows, 'id', 'name');
                    })
                    .finally(function () {
                        $scope.manager.loadPage();
                    });
            } else {
                Message.info('Please select the item(s) to deactivate');
            }
        }

    var saving = false;
    $scope.saveOrganization = function (id, fromurl) {
        var fromurl = fromurl || false;
        if (!saving) {
            saving = true;
            if (!fromurl) $location.search({ sid: id });
            $mdDialog.show({
                locals: { id: id, operations: $scope.operations, module: $scope.module },
                controller: 'SaveOrganizationController',
                templateUrl: '/ng/views/admin/modals/saveOrganization.html',
                parent: angular.element(document.body),
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                    $location.search({ sid: null });
                    saving = false;
                }, function (result) {
                    // $scope.manager.loadPage();
                    $location.search({ sid: null });
                    saving = false;
                });
        } 
    };

    if (params.sid) {
        $scope.saveOrganization(params.sid, true);
    }

    $scope.$watch(function () {
        return userService.isReloadGrid;
    },
        function (n, o) {
            if (n) {
                $scope.manager.loadPage();
                userService.isReloadGrid = false;

            }
        });
});
