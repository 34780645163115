import { cipo } from 'cipo';

cipo.directive("list", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "list": "=",
            "hidesummary": "=?",
            "model": "=",
            "hidesearch": "=?",
            "oneline": "=?",
            "hasorder": "=?",
            "value": "=?",
            "key": "=?",
            "multiple": "=",
            "hascheckbox": "=",
            "therecanbeonlyone": "=",
            "columns": "@",
            "listheight": "@",
            "filterItems": "=?"
        },
        templateUrl: "/ng/views/directives/list.html",
        controller: function ($scope) {
            $scope.listheight = $scope.listheight || 150;
            $scope.value = $scope.value || 'value';
            $scope.key = $scope.key || 'key';
            $scope.list = angular.copy($scope.list);
            $scope.cols = $scope.columns ? 12 / parseInt($scope.columns) : 12;
            $scope.filter = { text: $scope.filterItems || '' };

            $scope.set_fieldsWidth = function () {
                var parts = 0;
                for (var i = 0; i < $scope.model.length; i++) {
                    parts += $scope.model[i].width;
                }
                for (var i = 0; i < $scope.model.length; i++) {
                    $scope.model[i].widthPercentage = 100 / parts * $scope.model[i].width;
                }
            }

            // Loop through list and set value if doesn't exists for the filter to work
            // We use  <div ng-repeat="i in list | filter:{value:filter.text}" and we filter after value, but some lists may not contain value, so just set it
            if ($scope.list && $scope.list.length > 0) {
                for (var i = 0; i < $scope.list.length; i++) {
                    if (!$scope.list[i].hasOwnProperty('value') && $scope.list[i].hasOwnProperty($scope.value)) {
                        $scope.list[i].value = $scope.list[i][$scope.value];
                    }
                }
            }

            //if no model will assume the list carries the model info
            if ($scope.model) {
                if (Object.prototype.toString.call($scope.model) == '[object Object]') {
                    // the model is an object
                    // this implies single selection allowed
                    $scope.isSingleSelection = true;
                    for (var i = 0; i < $scope.list.length; i++) {
                        if ($scope.list[i][$scope.key] == $scope.model[$scope.key]) {
                            $scope.list[i].selected = true;
                            $scope.model = $scope.list[i];
                            break;
                        }
                    }
                }
                // the model is an array of objects
                else {
                    var lookup = {}, primaryIds = [];
                    for (var i = 0; i < $scope.model.length; i++) {
                        lookup[$scope.model[i][$scope.key]] = $scope.model[i];
                        if ($scope.model[i].isPrimary) primaryIds.push($scope.model[i][$scope.key]);
                    }
                    $scope.model = [];
                    // change model into a collection of referenced objects
                    for (var i = 0; i < $scope.list.length; i++) {
                        if (primaryIds.indexOf($scope.list[i][$scope.key]) != -1) {
                            $scope.list[i].isPrimary = true;
                        } else $scope.list[i].isPrimary = false;
                        if (lookup[$scope.list[i][$scope.key]]) {
                            $scope.list[i].selected = true;
                            $scope.model.push($scope.list[i]);
                        } else $scope.list[i].selected = false;
                        lookup[$scope.list[i][$scope.key]] = $scope.list[i];
                    }
                }
            }

            if ($scope.hasorder) {
                if (($scope.model || []).length) {
                    $scope.model.sort(function (a, b) {
                        return a.position - b.position;
                    })
                }
            }

            $scope.toggleSelect = function (i) {
                if ($scope.oneline) i.width = 1;
                if ($scope.model) {
                    if (i.selected) {
                        i.selected = false;
                        if ($scope.multiple) {
                            $scope.model.splice($scope.model.indexOf(i), 1);

                            if (i.isPrimary) {
                                i.isPrimary = false;
                                primaryIds.splice(primaryIds.indexOf(i[$scope.key]), 1);
                            }
                        } else $scope.model = {};
                        return;
                    }
                    i.selected = true;
                    if (!$scope.multiple) {
                        if ($scope.model) $scope.model.selected = false;
                        $scope.model = i;
                    } else {
                        $scope.model.push(i);
                    }
                } else {
                    i.isUsed = !i.isUsed;
                }

                if ($scope.oneline) $scope.set_fieldsWidth();

            }
            $scope.setPrimary = function (i) {
                if (!i.selected) $scope.toggleSelect(i);
                if (i.isPrimary) {
                    if ($scope.therecanbeonlyone) {
                        if (primaryIds.length) lookup[primaryIds[0]].isPrimary = false;
                        primaryIds = [i[$scope.key]];

                    }
                    else {
                        primaryIds.push(i[$scope.key]);
                    }
                } else {
                    primaryIds.splice(primaryIds.indexOf(i[$scope.key]), 1);
                }
            }
        }
    }
});
