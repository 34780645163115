import { cipo } from 'cipo';

cipo.factory('FileManagerConfig', function (URI, userService) {
    var FileManagerConfig = {
        projectFiles: {
            tree: {
                url: URI.PROJECT_FILE_MANAGER.SEARCH,
                urlParams: { entityInstanceId: userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId }
            },
            manager: {
                wrappers: ['folders', 'files'],
                url: URI.PROJECT_FILE_MANAGER.SEARCH,
            },
            folders: {
                addFolderURL: URI.PROJECT_FILE_MANAGER.CREATE_FOLDER,
                editFolderURL: URI.PROJECT_FILE_MANAGER.EDIT_FOLDER,
                deleteURL: URI.PROJECT_FILE_MANAGER.DELETE,
                permanentlyDeleteURL: URI.PROJECT_FILE_MANAGER.PERMANENTLY_DELETE,
                restoreURL: URI.PROJECT_FILE_MANAGER.RESTORE,
                moveURL: URI.PROJECT_FILE_MANAGER.MOVE
            },
            files: {
                getThumbs: URI.PROJECT_FILE_MANAGER.GET_ALL_FILES_WITH_THUMBNAILS,
                generateFileIdURL: URI.PROJECT_FILE_MANAGER.GENERATE_FILEID,
                startUploadURL: URI.PROJECT_FILE_MANAGER.UPLOAD_FILE_CHUNKS,
                uploadChunkURL: URI.PROJECT_FILE_MANAGER.UPLOAD_CHUNK,
                saveFileInfoURL: URI.PROJECT_FILE_MANAGER.SAVE_FILE_INFO,
                uploadFileURL: URI.PROJECT_FILE_MANAGER.UPLOAD_FILE,
                shareFileURL: URI.PROJECT_FILE_MANAGER.SHARE_FILE,
                downloadFileURL: URI.PROJECT_FILE_MANAGER.DOWNLOAD,
                deleteFileURL: URI.PROJECT_FILE_MANAGER.DELETE_FILE,
                renameFileURL: URI.PROJECT_FILE_MANAGER.RENAME_FILE,
                fileDetailsURL: URI.PROJECT_FILE_MANAGER.GET_FILE,
                globalFileURL: URI.PROJECT_FILE_MANAGER.SET_FILE_GLOBAL,
                unsetGlobalFileURL: URI.PROJECT_FILE_MANAGER.UNSET_FILE_GLOBAL,
                downloadZipFile: URI.PROJECT_FILE_MANAGER.DOWNLOAD_ZIP_FILE
            },
            itemParams: { entityInstanceId: userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId }
        },
        cipoDrive: {
            tree: {
                url: URI.MY_CIPO_DRIVE.SEARCH,
            },
            manager: {
                url: URI.MY_CIPO_DRIVE.SEARCH,
                searchUrl: URI.MY_CIPO_DRIVE.SEARCH_FILES_FOLDERS,
                urlParams: { folderId: 'id' }
            },
            folders: {
                addFolderURL: URI.MY_CIPO_DRIVE.CREATE_FOLDER,
                editFolderURL: URI.MY_CIPO_DRIVE.EDIT_FOLDER,
                deleteURL: URI.MY_CIPO_DRIVE.DELETE,
                permanentlyDeleteURL: URI.MY_CIPO_DRIVE.PERMANENTLY_DELETE,
                restoreURL: URI.MY_CIPO_DRIVE.RESTORE,
                moveURL: URI.MY_CIPO_DRIVE.MOVE
            },
            files: {
                getThumbs: URI.MY_CIPO_DRIVE.GET_ALL_FILES_WITH_THUMBNAILS,
                generateFileIdURL: URI.MY_CIPO_DRIVE.GENERATE_FILEID,
                startUploadURL: URI.MY_CIPO_DRIVE.UPLOAD_FILE_CHUNKS,
                uploadChunkURL: URI.MY_CIPO_DRIVE.UPLOAD_CHUNK,
                saveFileInfoURL: URI.MY_CIPO_DRIVE.SAVE_FILE_INFO,
                uploadFileURL: URI.MY_CIPO_DRIVE.UPLOAD_FILE,
                shareFileURL: URI.MY_CIPO_DRIVE.SHARE_FILE,
                unshareFileURL: URI.MY_CIPO_DRIVE.DELETE_SHARES_GLOBAL,
                downloadFileURL: URI.MY_CIPO_DRIVE.DOWNLOAD,
                deleteFileURL: URI.MY_CIPO_DRIVE.DELETE_FILE,
                renameFileURL: URI.MY_CIPO_DRIVE.RENAME_FILE,
                fileDetailsURL: URI.MY_CIPO_DRIVE.GET_FILE,
                setAsUserAvatar: URI.MY_CIPO_DRIVE.SET_FILE_AS_USER_LOGO,
                setAsCompanyLogo: URI.MY_CIPO_DRIVE.SET_FILE_AS_COMPANY_LOGO,
                downloadZipFile: URI.MY_CIPO_DRIVE.DOWNLOAD_ZIP_FILE
            }
        }
    };

    return FileManagerConfig;
});
