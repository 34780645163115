<div class="full-height">
    <mat-tab-group mat-stretch-tabs="false" class="full-height">
        <mat-tab label="Client">
            <div fxLayout="column" class="full-height">
                <div fxFlex="none">
                    <mat-checkbox (change)="permissionsChanged($event)">Grant preview permission</mat-checkbox>
                    <p class="m-x-lg">
                        The pagination & sorting are built in the table component.
                        <br />
                        Contains Selectable and Actions columns. noDataPlaceholder: '~'
                    </p>
                </div>
                <cipo-paged-data-list
                    fxFlex="grow"
                    [tableColumns]="tableColumns"
                    [options]="options2"
                    [tableData]="tableData"
                    (selectionChanged)="changeSelected($event)"
                    (actionSelected)="actionSelected($event)"
                    (rowClicked)="clicked($event)"></cipo-paged-data-list>
            </div>
        </mat-tab>
        <mat-tab label="Server">
            <div fxLayout="column" class="full-height">
                <div fxFlex="none">
                    <p class="m-x-lg">
                        The pagination & sorting are implemented in the parent component.
                        <br />
                        noDataPlaceholder: 'x'
                    </p>
                </div>
                <cipo-paged-data-list
                    fxFlex="grow"
                    [options]="options"
                    [tableColumns]="tableColumns"
                    [tableData]="tableRows"
                    (sortChanged)="sortChanged($event)"
                    (paginationUpdated)="paginationChanged($event)"></cipo-paged-data-list>
            </div>
        </mat-tab>
        <mat-tab label="Infinite scroll">
            <div class="full-height">
                <app-data-list-command
                    [filters]="filters"
                    sidenavclass="table-sidenav"
                    (applyfilters)="applyFilters($event)">
                    example of content in the left side of the command component
                    <div fxLayout fxLayoutAlign="start center">
                        <button mat-raised-button color="primary">Click</button>
                        <button mat-icon-button color="warn">
                            <mat-icon>menu</mat-icon>
                        </button>
                    </div>
                    <cipo-infinite-data-list
                        class="data-list"
                        [tableColumns]="tableColumns"
                        [options]="infiniteScrollOptions"
                        [loading]="isLoading()"
                        [tableData]="infiniteTableData"
                        (sortChanged)="sortChanged($event)"
                        (paginationUpdated)="loadMore($event)" />
                </app-data-list-command>
            </div>
        </mat-tab>
        <mat-tab label="Types">
            <app-data-list-command
                [filters]="filters"
                sidenavclass="table-sidenav"
                (applyfilters)="applyFilters($event)">
                example of content in the left side of the command component
                <div fxLayout fxLayoutAlign="start center">
                    <button mat-raised-button color="primary">Click</button>
                    <button mat-icon-button color="warn">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
                <cipo-paged-data-list
                    class="data-list"
                    [tableColumns]="typesTableColumns"
                    [tableData]="typesTableData"></cipo-paged-data-list>
            </app-data-list-command>
        </mat-tab>
        <mat-tab label="Info">
            This component has the following input and output attributes:
            <ul>
                <li>
                    <i>Input()</i>
                    <b>tableColumns</b>
                    - mapped fields to correnspond to TableColumn[] interface. This includes:
                    <b>id, name, displayName, align, displayTypeId, width, priority, sortable</b>
                    <br />
                    <i class="cipo-text--warn-500">
                        Note: Column sorting must be done inside your component. The data inside table will be displayed
                        according to column array items
                    </i>
                </li>
                <li>
                    <i>Input()</i>
                    <b>tableData</b>
                    - mapped table elements, of type TableRow. This type contains some default properties (optional),
                    and custom:
                    <br />
                    <b>state_name, backgroundColor & hoverColor</b>
                    and [key: string]: unknown, for other properties
                </li>
                <li>
                    <i>Input()</i>
                    <b>tableOptions</b>
                    <br />
                    Contains some of the following options:
                    <b>selectable, actions, pagination, sort, serverPaginationSort, noDataPlaceholder</b>
                </li>
                <li>
                    <i>Output()</i>
                    <b>selectionChanged</b>
                    - Event triggered when selection has changed. Should be used along with tableOptions.selectable:
                    true
                </li>
                <li>
                    <i>Output()</i>
                    <b>actionSelected</b>
                    <br />
                    Event triggered when an action was selected. Should be used when tableOptions.actions receives an
                    array of actions.
                    <br />
                    Each action must contain at least an id and displayName. There also can be sent leftIcon and/or
                    rightIcon.
                    <br />
                    For proper alignment, if at least one action has icons (left/right/both) all actions should receive
                    those icons.
                    <br />
                    If some action don't need icons, there should be provided invalid iconNames, this way no icon will
                    be displayed (ex: leftIcon: 'call', rightIcon: 'x')
                </li>
                <li>
                    <i>Output()</i>
                    <b>sortChanged</b>
                    <br />
                    Event triggered when the sort was changed, if tableOptions.serverPaginationSort: true.
                    <br />
                    On this event, a new request that will fetch ordered data should be sent to BE.
                </li>
                <li>
                    <i>Output()</i>
                    <b>paginationUpdated</b>
                    - Same as sortChanged
                    <br />
                    If
                </li>
            </ul>
        </mat-tab>
    </mat-tab-group>
</div>
