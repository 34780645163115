import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("AdjustmentRelease", function ($q, Model, URI, Message, Form, userService) {
    var AdjustmentRelease = Model.extend(function (parent, obj) {

        var self = this;

        self.editable = false;
        self.deleteConfirm = false;
        self.parent = parent;
        self.contractId = parent.properties.contractId;
        self.parentId = parent.properties.id;
        self.adjustmentId = parent.properties.id;

        self.properties = {
            id: null,
            adjustmentId: obj.adjustmentId,
            entityInstanceId: obj.entityInstanceId,

            valueType: 1, // for the amount percentage dropdown
            amount: null,
            percentage: null,

            comment: null,
            lastUpdatedDate: null,
            paid: false,
        }

        if (obj.id) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && obj[key]) {
                    self.properties[key] = obj[key];
                }
            }
            self.properties.id = obj.id || obj.releaseId;
            self.properties.valueType = self.properties.amount ? 1 : 2;
            self.properties.amount = self.properties.amount ? self.properties.amount : self.properties.percentage;
            if (self.properties.lastUpdatedDate) self.properties.lastUpdatedDate = self.process_incomingDate(self.properties.lastUpdatedDate);
        }

        if (!self.properties.id) {
            self.createForm();
        }

    });

    AdjustmentRelease.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        self.form.set_Description({
            comment: { label: 'Comment', type: 'text' },
            amount: { label: 'Release Value', type: 'number', validation: { required: true, isNumber: true } },
            valueType: { label: '', type: 'radio', options: [{ key: 1, value: "Amount" }, { key: 2, value: "Percentage" },], validation: { required: true } },
        });

        self.form.setTemplate('grid', [
            { amount: 50, valueType: 50, },
            { comment: 100 },
        ]);
        self.form.initializing = false;
        self.form.store_Data();
    }

    AdjustmentRelease.prototype.process_incomingDate = function (date) {
        if (date) {
            var momentDate = moment(date).utc();
            momentDate.set({ h: 12, m: 0 });
            //console.error('momentDate', date,  momentDate)
            return momentDate;
        }
        else return null;
    }

    AdjustmentRelease.prototype.save = function () {
        var self = this;
        var p = $q.defer();

        self.form.validate();
        self.form.loading = true;
        if (self.form.isValid) {
            var temp = angular.copy(self.properties);
            if (temp.valueType == 2) {
                temp.percentage = temp.amount;
                temp.amount = 0;
            } else {
                temp.percentage = null;
            }

            var urlData = temp.id ? URI.ADJUSTMENTS.UPDATE_RELEASE : URI.ADJUSTMENTS.CREATE_RELEASE;
            self[urlData.method](urlData, { url: { entityInstanceId: self.properties.entityInstanceId }, urltype: 'obj', body: temp })
                .then(function () {
                    p.resolve();
                    Message.info('Release saved successfully');
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    if(self.form) self.form.loading = false;
                });

        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    AdjustmentRelease.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting release...";
        self.deleting = true;
        var urlData = URI.ADJUSTMENTS.DELETE_RELEASE;
        self[urlData.method](urlData, { url: { id: self.properties.id, entityInstanceId: self.properties.contractId }, urltype: 'obj' })
            .then(function (result) {
                Message.info('Release deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete release. Try again?";
                p.reject(e);
                Message.dberror(e);
            })
        return p.promise;
    }

    return AdjustmentRelease;
});
