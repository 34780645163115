import { cipo } from 'cipo';

cipo.factory('officeService', function ($http, $q, $location, userService, URI) {

    var officeService = {};

    officeService.load = function(discoData, searchData) {
        document.title = searchData?.fileName || 'CIPO® Software';
        
        officeService.replaceFavIcon(discoData?.favIconUrl);

        var frame = document.createElement('iframe');
        frame.name = 'office_frame';
        frame.id ='office_frame';
        frame.title = 'CIPO® Software - Office Frame';
        frame.setAttribute('allowfullscreen', 'true');
        frame.setAttribute('sandbox', 'allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox');
        
        document.body.appendChild(frame);
        document.getElementById('office_form').submit();
    }

    officeService.discovery = function(data) {
        var deferred = $q.defer();
        $http.get(`${URI.WOPI.DISCOVERY}/${data.fileId}?ext=${data.ext}&action=${data.action}&readOnly=${data.readOnly}`)
            .then(function (res) {
                deferred.resolve(res.data);
            })
            .catch(function (err) {
                deferred.reject(err);
            });
        return deferred.promise;
    }

    officeService.open = function(row, readOnly) {
        const data = {
            fileId: row.versionId,
            fileName: row.name,
            ext: row.name.substring(row.name.lastIndexOf(".") + 1),
            action: readOnly ? 'view' : 'edit',
            readOnly: readOnly,
        };

        var tenantIdentifier = userService.getTenantIdentifierById(userService.tenantId);
        var baseUrl = $location.absUrl().replace($location.url(), '');
        var url = baseUrl + '/' + tenantIdentifier + '/office?f=' + officeService.officeEncodeData(data);
        window.open(url, "_blank");
    }

    officeService.replaceFavIcon = function(favicon) {
        if (!favicon) {
            return;
        }

        var links = document.querySelectorAll("link[rel~='icon']");
        (links || []).forEach((link) => {
            document.head.removeChild(link);
        });
        
        var link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon;
        document.head.appendChild(link);
    }

    officeService.encodeText = function(text) {
        const bytes = new TextEncoder().encode(text);
        const binString = String.fromCodePoint(...bytes);
        return btoa(binString);
    }

    officeService.decodeText = function(base64) {
        const binString = atob(base64);
        const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
        return new TextDecoder().decode(bytes);
    }

    officeService.encodeData = function(data) {
        const text = JSON.stringify(data);
        return officeService.encodeText(text);
    }

    officeService.decodeData = function(base64) {
        var text = officeService.decodeText(base64);
        return JSON.parse(text);
    }

    officeService.officeEncodeData = function(data) {
        const ed = officeService.encodeData(data).replaceAll('=', '');
        return officeService.encodeText(`eY5CUm${ed}oFBQ70`).replaceAll('=', '');
    }
  
    officeService.officeDecodeData = function(encoded) {
        const ed = officeService.decodeText(encoded).slice(6, -6);
        return officeService.decodeData(ed);
    }

    return officeService;
});