<div class="m-a-md">
    <app-data-list-command (applyfilters)="search($event.criteria)" [hidefilter]="true" [hideReload]="true">
        <div fxLayoutAlign="space-between center">
            <h5 class="m-a-0">{{ "moduleParameter.responseDays" | translate }}</h5>
            <div fxLayout fxLayoutAlign="center center">
                <button mat-icon-button (click)="getMappedData()" class="m-r-sm">
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-form-field class="cipo-input hide-subscript p-0 m-r">
                    <mat-select (selectionChange)="filter($event)" value="0">
                        <mat-option value="0">
                            {{ "moduleParameter.all" | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ "moduleParameter.onMenu" | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        @if (tableColumns) {
            <app-data-list
                class="data-list"
                [tabledata]="tableData"
                [loading]="loading"
                (rowclicked)="openSettingModal($event)"
                [tablecolumns]="tableColumns"
                [tableoptions]="tableOptions"></app-data-list>
        }
    </app-data-list-command>
</div>
