import { cipo } from 'cipo';

cipo.controller('tenantsettingsController',
    function ($scope, $state, rememberManagerService, Manager, URI, ACTIONS, userService, $mdDialog, Message) {

        $scope.isDevEnv = window.location.href.includes('localhost') || window.location.href.includes('dev.cipo.cloud');

        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        $scope.operations = userService.getOperationsFor($state.current.code);
        $scope.permissionsId = userService.getPermissionsIdList($state.current.code);

        var sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }
        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.HEADER_FOOTER_SETTINGS.SEARCH,
            dataWrapper: 'data',
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.editItem(row);
            },
            parseData: function (data, columns) {
                var data = data || [], retval = [];
                for (var i = 0; i < data.length; i++) {
                    data[i]._isGlobal = data[i].isGlobal ? 'Yes' : "No";
                    data[i]._isHeader = data[i].isHeader ? 'Header' : "Footer";
                    retval.push(data[i]);
                }

                return retval;
            },
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    isGroup: true,
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    actions: [
                        {
                            setProperties: ACTIONS.ADD_HEADER,
                            click: function (rows) {
                                $scope.editItem({isHeader: true});
                            }
                        },
                        {
                            setProperties: ACTIONS.ADD_FOOTER,
                            click: function (rows) {
                                $scope.editItem();
                            }
                        }

                    ]
                }
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.SET_DEFAULT,
                    name: "Set Defaults",
                    conditionOnTop: function () {
                        if ($scope.operations.Update)
                            return true;
                        else return false;
                    },
                    noLabel: false,
                    click: function () {
                        $scope.setDefaults();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editItem(row);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editItem(row);;
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    displayOnRow: $scope.operations.Delete ? true : false,
                    condition: function (row) {
                        if (row.isUsed || row.disableDelete || !row.operations.Delete) return false;
                        else return true;
                    },
                    conditionOnTop: function () {
                        if ($scope.operations.Delete) return true;
                        else return false;
                    },
                    //alwaysOnTop: false,
                    click: function (rows) {
                        $scope.delete(sendList(rows));
                    }
                },
            ]
        });
        $scope.manager.set_Columns([
            { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
            { name: 'description', label: 'Description', type: 'text', onTablet: true, onPhone: false, width: 30, fieldTypeId: 1 },
            { name: "_isGlobal", label: 'Default', type: 'checkbox', replacementFor: "isGlobal", onTablet: true, onPhone: true, width: 20 },
            { name: "_isHeader", label: 'Type', type: 'checkbox', replacementFor: "isHeader", onTablet: true, onPhone: true, width: 20 }
            
        ]);

        $scope.manager.loading = true;
        $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
            function() {
                $scope.manager.loading = false;
            },
            function() {
                $scope.manager.loadPage()
                .finally(function () {
                    $scope.manager.loading = false;
                });
            }
        );

        $scope.editItem = function (obj, ev) {
            $mdDialog.show({
                locals: { obj: obj || null, operations: $scope.operations, module: $scope.module },
                controller: 'editHeaderFooterController',
                templateUrl: '/ng/views/core/modals/editHeaderFooter.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function () {
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                });
        }

        $scope.setDefaults = function (ev) {
            $mdDialog.show({
                locals: { operations: $scope.operations },
                controller: 'editDefaultHFController',
                templateUrl: '/ng/views/core/modals/editDefaultHF.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            });
        }

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.delete = function (list) {
            if (list.length) {
                $mdDialog.show(confirm('Deleting Item(s)', 'Are you sure you want to delete the selected item(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.HEADER_FOOTER_SETTINGS.DELETE.method](URI.HEADER_FOOTER_SETTINGS.DELETE, { body: list })
                            .then(function () {
                                Message.info('Item(s) deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.page = 1;
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        // cancel pressed
                    });
            }
            else {
                Message.info('Please select the item(s) to delete');
            }


        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    userService.isReloadGrid = false;
                    $scope.operations = {...userService.getOperationsFor($state.current.code)};
                    $scope.permissionsId = [...userService.getPermissionsIdList($state.current.code)];
                    $scope.manager.loadPage();
                }
            });
    });
